import { Ref, toRef } from 'vue';

import { useMainStore } from '@/editor/stores/store';
import Page from '@/page/classes/Page';
import { usePage } from '@/page/composables/usePage';

export const useActivePage = () => {
	const store = useMainStore();
	const activePage = toRef(store, 'activePage');
	const usePageInstance = usePage(activePage as Ref<Page>);

	return usePageInstance;
};
