import { Position } from '@/Types/types';

class MathTools {
	static angleToRadians(angle: number) {
		return (angle * Math.PI) / 180;
	}

	static interpolate(from: number, to: number, percentage: number) {
		return from * (1 - percentage) + to * percentage;
	}

	static radiandsToAngle(radians: number) {
		return (radians * 180) / Math.PI;
	}

	/**
	 * Gets the chord length of an arc.
	 *
	 * @see {@link https://en.wikipedia.org/wiki/Chord_(geometry)}
	 *
	 * @param {number} radius The radius of the arc.
	 * @param {number} θ The angle in degrees of the arc.
	 *
	 * @return {number} The chord of the provided arc.
	 */
	static chord(radius: number, angle: number) {
		return 2 * radius * Math.sin(MathTools.angleToRadians(angle / 2));
	}

	/**
	 * Compares two numbers with a tolerance margin.
	 */
	static compareWithTolerance(value1: number, value2: number, toleranceMargin = 1) {
		return Math.abs(value1 - value2) < toleranceMargin;
	}

	/**
	 * Gets the sagitta of an arc. The sagitta of an arc is the distance from the
	 * center of the arc to the center of its base.
	 *
	 * @see {@link https://en.wikipedia.org/wiki/Sagitta_(geometry)}
	 *
	 * @param {number} radius The radius of the arc.
	 * @param {number} angle The angle in degrees of the arc.
	 *
	 * @return {number} The sagitta of the provided arc.
	 */
	static sagitta(radius: number, angle: number) {
		return radius * (1 - Math.cos(MathTools.angleToRadians(angle / 2)));
	}

	static clamp(num: number, min: number, max: number): number {
		return Math.min(Math.max(num, min), max);
	}

	static formatBytes(bytes: number, decimals: number): string {
		if (bytes === 0) return '0 Bytes';

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

	static getDistanceBetween2Points(x1: number, y1: number, x2: number, y2: number) {
		return Math.hypot(y2 - y1, x2 - x1);
	}

	static getAngle(x1: number, y1: number, x2: number, y2: number) {
		const dy = y2 - y1;
		const dx = x2 - x1;
		let theta = Math.atan2(dy, dx);

		theta *= 180 / Math.PI; // -180, 180
		if (theta < 0) theta = 360 + theta; // 0, 360

		return theta;
	}

	static randomBetween(min: number, max: number) {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}

	static range(from: number, to: number, step = 1): number[] {
		const len = Math.floor((to - from) / step) + 1;
		return Array(len)
			.fill(0)
			.map((_, idx) => from + idx * step);
	}

	static rotatePoint(cx: number, cy: number, x: number, y: number, angle: number): Position {
		if (angle === 0) return { x, y };

		const radians = MathTools.angleToRadians(angle);
		const cos = Math.cos(radians);
		const sin = Math.sin(radians);

		return {
			x: cos * (x - cx) - sin * (y - cy) + cx,
			y: sin * (x - cx) + cos * (y - cy) + cy,
		};
	}
	/**
	 *  A --- B
	 *  C --- X
	 *
	 * 	x = (b * c) / a
	 * @param a
	 * @param b
	 * @param c
	 * @returns x
	 */
	static ruleOfThree(a: number, b: number, c: number): number {
		return (b * c) / a;
	}

	static toFixedOrInt(num: number, decimals = 1) {
		const fixed = num.toFixed(decimals);
		if (fixed.endsWith('.0')) return parseInt(fixed);
		return parseFloat(fixed);
	}

	static sin(angle: number) {
		return Math.sin((angle / 180) * Math.PI);
	}

	static cos(angle: number) {
		return Math.cos((angle / 180) * Math.PI);
	}

	static getVectorLength(x: number, y: number, width: number, height: number) {
		const center = {
			x: x + width / 2,
			y: y + height / 2,
		};
		const vector = {
			x: x - center.x,
			y: y - center.y,
		};
		return Math.sqrt(vector.x * vector.x + vector.y * vector.y);
	}

	static getRotatedTopLeftCornerOfRect(x: number, y: number, width: number, height: number, angle: number) {
		const center = {
			x: x + width / 2,
			y: y + height / 2,
		};
		const vector = {
			x: x - center.x,
			y: y - center.y,
		};
		const rotationMatrix = [
			[MathTools.cos(angle), -MathTools.sin(angle)],
			[MathTools.sin(angle), MathTools.cos(angle)],
		];
		const rotatedVector = {
			x: vector.x * rotationMatrix[0][0] + vector.y * rotationMatrix[0][1],
			y: vector.x * rotationMatrix[1][0] + vector.y * rotationMatrix[1][1],
		};
		return {
			x: center.x + rotatedVector.x,
			y: center.y + rotatedVector.y,
		};
	}

	static getAngleForNextCorner(anc: number, vectorLength: number) {
		const alpha = Math.acos(anc / vectorLength) * (180 / Math.PI);
		return 180 - alpha * 2;
	}
}

export default MathTools;
