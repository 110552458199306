import ImageTools from '@/elements/medias/images/image/utils/ImageTools';
import { VideoTools } from '@/elements/medias/video/utils/VideoTools';
import { Size } from '@/Types/types';

export class TransformTools {
	static getSizeKeepingAspectRatioByHeight = (size: Size, height: number): Size => {
		const ratio = size.width / size.height;
		return {
			width: ratio * height > 1 ? ratio * height : 1,
			height: ratio * height > 1 ? height : 1 / ratio,
		};
	};

	static getSizeKeepingAspectRatioByWidth = (size: Size, width: number): Size => {
		const ratio = size.height === 0 || size.width === 0 ? 1 : size.height / size.width;

		return {
			width: ratio * width > 1 ? width : 1 / ratio,
			height: ratio * width > 1 ? ratio * width : 1,
		};
	};

	static async getRealSizeFrom(url: string, type: string): Promise<Size> {
		if (type === 'video') {
			return await VideoTools.getRealSize(url);
		}
		return await ImageTools.getRealImageSize(url);
	}

	/**
	 *
	 * @param destinySize Objeto con width y height del destino
	 * @param originSize Objeto con width y height del origen
	 * @returns valor de la escala final
	 */
	static getFactorToFitInNewRatio = (
		destinySize: { width: number; height: number },
		originSize: { width: number; height: number }
	) => {
		// Calculamos la escala en el ancho y en el alto para saber cuanto debemos mover y escalar los elementos
		const scaleWidth = destinySize.width / originSize.width;
		const scaleHeight = destinySize.height / originSize.height;

		const oldRatio = originSize.width / originSize.height;
		const newRatio = destinySize.width / destinySize.height;

		// Esquema de casos de uso
		// https://user-images.githubusercontent.com/95227125/201906500-f0c0604b-b9f0-4566-b64a-e02e4a5518b3.png
		if (oldRatio > 1) {
			// Casos: 6 | 4, 10, 5, 11, 12
			return newRatio > 1 && newRatio > oldRatio ? scaleHeight : scaleWidth;
		} else if (oldRatio < 1) {
			// Casos: 2 | 1, 13, 3, 15, 14
			return newRatio < 1 && newRatio < oldRatio ? scaleWidth : scaleHeight;
		}

		// Casos: 7, 8 | 9
		return newRatio <= 1 ? scaleWidth : scaleHeight;
	};
}
