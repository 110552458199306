import { createEventHook, createSharedComposable, useIntervalFn } from '@vueuse/core';
import { computed, ref } from 'vue';

import { TimeTools } from '@/common/utils/TimeTools';
import { useProject } from '@/project/composables/useProject';
import { useProjectStore } from '@/project/stores/project';

export const useProjectControls = createSharedComposable(() => {
	const { isAiPresentation, projectIsPresentation } = useProject();

	const updateTimeManuallyHook = createEventHook();
	const updateTime = (time: number) => {
		currentTime.value = time;
		updateTimeManuallyHook.trigger(time);
	};
	const project = useProjectStore();
	// Duración de cada frame del navegador en milisegundos
	const TIMER_UPDATE_FREQUENCY = 16.7;
	// Tiempo máximo de descarga en milisegundos, se ha establecido un máximo de 3 minutos (3 * 6000), añadimos 500 milisegundos para evitar errores de redondeo en el límite
	// ya que de cara al render no supone gran diferencia
	const LIMIT_TIME_DOWNLOAD = 180500;
	const durationInMMSSFormat = computed(() => TimeTools.formatMillisecondsToMMSS(duration.value));
	const currentTimeInMMSSFormat = computed(() => TimeTools.formatMillisecondsToMMSS(currentTime.value));

	const currentTime = ref(0);
	const currentTimePercentage = computed(() => (currentTime.value / duration.value) * 100);

	const {
		pause,
		resume: play,
		isActive: isPlaying,
	} = useIntervalFn(() => {
		currentTime.value += TIMER_UPDATE_FREQUENCY;
	}, TIMER_UPDATE_FREQUENCY);

	pause();

	const containVideo = computed(() => {
		return project.pages.some((page) => page.containsVideo());
	});

	const duration = computed(() => {
		if (!containVideo.value) {
			return 0;
		}
		return project.pages.reduce((total, page) => total + page.duration(), 0);
	});

	const overLimitToDownload = computed(() => duration.value > LIMIT_TIME_DOWNLOAD);

	/**
	 * Array con la duración de cada una de las páginas del proyecto
	 */
	const pagesDuration = computed(() => project.pages.map((p) => p.duration()));
	const isVideoPlayer = computed(() => containVideo.value && !isAiPresentation.value && !projectIsPresentation.value);

	return {
		containVideo,
		duration,
		durationInMMSSFormat,
		pagesDuration,
		currentTime,
		currentTimePercentage,
		currentTimeInMMSSFormat,
		pause,
		play,
		isPlaying,
		overLimitToDownload,
		LIMIT_TIME_DOWNLOAD,
		updateTime,
		onUpdateTimeManually: updateTimeManuallyHook.on,
		isVideoPlayer,
	};
});
