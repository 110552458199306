import {CompositingSize, MockupManifest} from "@/apps/mockup/schemas";
import {useCompositionMockup} from "@/apps/mockup/composable/useCompositionMockup";
import {useMockupStore} from "@/apps/mockup/mockupStore";
import {DataStructureToJsonAdapter} from "@/apps/mockup/adapters/DataStructureToJsonAdapter";
import {versioning} from "@/apps/mockup/facades/versioningChanges";
import {useBackupMockup} from "@/apps/mockup/composable/useBackupMockup";
import {CanvasUtils} from "@/apps/mockup/classes/CanvasUtils";
import {DownloadFormat, Size} from "@/Types/types";
import {generateDownloadFile} from "@/apps/mockup/utils/DownloadTools";
import GAnalytics from "@/analytics/ganalytics/utils/GAnalytics";

interface drawOptions {
	compositionSize?: CompositingSize;
	notSaveBackup?: boolean;
}

interface downloadOptions {
	size?: Size;
	format?: DownloadFormat;
}

export class DrawMockup {
	public static isForcedDraw = false;
	public static requiresPageUpdate: Set<string> = new Set();
	public static forceUpdateColorPicker: CallableFunction = () => {};

	/**
	 * Draw the mockup
	 */
	public static async draw(registerChange = true, options?: drawOptions){

		if (registerChange) {
			const data = new DataStructureToJsonAdapter().convertToJSON();
			versioning().registerChange(data)
		}

		const {composeTexturesBySize} = useCompositionMockup();
		const mockup = useMockupStore();
		const {saveBackup} = useBackupMockup();

		// Getting the composition size
		const mode: CompositingSize = options?.compositionSize ? options.compositionSize : mockup.isOnPreviewMode() ? CompositingSize.PREVIEW : CompositingSize.POSTER;

		for(const pageId of DrawMockup.requiresPageUpdate){
			mockup.newTextureChange(pageId);
		}
		await composeTexturesBySize(mode);
		const manifest = mockup.manifest;
		if (!manifest) return;

		DrawMockup.clearRequiresPageUpdate();
		/**
		 * Render the mockup by manifest using the mode
		 */
		const canvasResponse = mockup.renderMockupByManifest(
			manifest,
			mode,
			false
		)
		DrawMockup.isForcedDraw = false;

		const dictionaryCanvas = {
			[CompositingSize.PREVIEW]: 'previewCanvas',
			[CompositingSize.POSTER]: 'posterCanvas',
			[CompositingSize.DOWNLOAD]: 'downloadCanvas',
		};

		canvasResponse.id = 'canvas-response-draw-mockup';
		mockup[dictionaryCanvas[mode]] = CanvasUtils.cloneCanvas(canvasResponse);
		CanvasUtils.freedomMethod(canvasResponse);
		if (!options?.notSaveBackup) saveBackup();
	}


	public static forceDraw(){
		DrawMockup.isForcedDraw = true;
		DrawMockup.forceUpdateColorPicker();
		DrawMockup.draw(false).then();
	}

	public static setForceUpdateColorPicker(func: CallableFunction){
		DrawMockup.forceUpdateColorPicker = func;
	}

	public static setRequiresPageUpdate(pageId: string){
		DrawMockup.requiresPageUpdate.add(pageId);
	}

	private static clearRequiresPageUpdate(){
		DrawMockup.requiresPageUpdate.clear();
	}

	public static download(options?: downloadOptions) {
		let size = options?.size;
		const mockup = useMockupStore();
		if (!size) size = mockup.size;
		let downloadFormat = DownloadFormat.png;
		if (options?.format) downloadFormat = options.format;

		const startRendering = performance.now();

		mockup.downloading = true;
		const canvas = CanvasUtils.createCanvas(size.width, size.height);
		const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
		const canvasVisible = document.getElementById('generated-canvas-mockup');
		ctx.drawImage(canvasVisible as HTMLCanvasElement, 0, 0, size.width, size.height);
		generateDownloadFile(canvas, downloadFormat, mockup.title ?? 'mockup');
		mockup.downloading = false;

		console.info('Time of generate download => ', (performance.now() - startRendering) / 1000, ' seg');

		GAnalytics.trackGA4('mockup_download', {
			category: 'mockups',
			file_extension: downloadFormat,
			resolution: `${size.width}x${size.height}`,
			time: performance.now() - startRendering,
		});
	}
}
