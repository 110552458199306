import { v4 as uuidv4 } from 'uuid';

import { ApiClient } from '@/api/utils';
import { useEnvSettings } from '@/common/composables/useEnvSettings';
import { TrackedJobResponse } from '@/Types/types';

type SocialNetwork = 'instagram' | 'facebook' | 'pinterest' | 'twitter';

class UserApiClient extends ApiClient {
	static async exportProjectPages(body: BodyInit | any): Promise<TrackedJobResponse> {
		const { APP_API_PATH } = useEnvSettings();
		const url = `${APP_API_PATH}v2/vector/export?uuid=${uuidv4()}`;

		const result = await this.request(url, {
			method: 'POST',
			body,
			headers: {
				Accept: 'application/json',
			},
		});

		return result;
	}

	static async sendToSocialNetwork(socialNetwork: SocialNetwork, body: BodyInit | any): Promise<Response> {
		const { APP_API_PATH } = useEnvSettings();
		const url = `${APP_API_PATH}share/${socialNetwork}?uuid=${uuidv4()}`;

		const response = await this.request(
			url,
			{
				method: 'POST',
				body,
				headers: {
					Accept: 'application/json',
				},
			},
			true
		);

		if (!response.ok) {
			throw new Error('Share project pages | Something went wrong!');
		}

		return response;
	}

	static async sendToEmails(body: BodyInit | any): Promise<Response> {
		const { APP_API_PATH } = useEnvSettings();
		const url = `${APP_API_PATH}share/email?uuid=${uuidv4()}`;

		const response = await this.request(
			url,
			{
				method: 'POST',
				body,
				headers: {
					Accept: 'application/json',
				},
			},
			true
		);

		if (!response.ok) {
			throw new Error('Share project pages | Something went wrong!');
		}

		return response;
	}
}

export default UserApiClient;
