import { Ref } from 'vue';

import { createOrchestrator } from '@/composables/createOrchestrator';
import { Box } from '@/elements/box/classes/Box';
import { useBoxTransform } from '@/elements/box/composables/useBoxTransform';
import Element from '@/elements/element/classes/Element';
import { useElementTransform } from '@/elements/element/composables/useElementTransform';
import Line from '@/elements/line/classes/Line';
import { useLineTransform } from '@/elements/line/composables/useLineTransform';
import { useCroppeableTransform } from '@/elements/medias/crop/composables/useCroppeableTransform';
import Image from '@/elements/medias/images/image/classes/Image';
import { useImageTransform } from '@/elements/medias/images/image/composables/useImageTransform';
import { Video } from '@/elements/medias/video/classes/Video';
import { Text } from '@/elements/texts/text/classes/Text';
import { useTextTransform } from '@/elements/texts/text/composables/useTextTransform';

export const useElementTransformOrchestrator = (element: Ref<Element>) => {
	return createOrchestrator(() => {
		if (element.value instanceof Image) {
			return useImageTransform(element as Ref<Image>);
		}

		if (element.value instanceof Line) {
			return useLineTransform(element as Ref<Line>);
		}

		if (element.value instanceof Video) {
			return useCroppeableTransform(element as Ref<Video>);
		}

		if (element.value instanceof Text) {
			return useTextTransform(element as Ref<Text>);
		}

		if (element.value instanceof Box) {
			return useBoxTransform(element as Ref<Box>);
		}

		return useElementTransform(element);
	});
};
