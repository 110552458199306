import { SolidColor } from '@/color/classes/SolidColor';
import { TextShadowProperties } from '@/Types/types';

// Presets must be functions in order to avoid editing objects by reference
export const TEXT_EFFECTS_PRESETS = {
	ECHO: (color: SolidColor): TextShadowProperties[] => [
		{
			angle: 45,
			blur: 0,
			color,
			distance: 0.07,
			opacity: 0.5,
			unit: 'em',
		},
		{
			angle: 45,
			blur: 0,
			color,
			distance: 0.14,
			opacity: 0.25,
			unit: 'em',
		},
	],

	GLITCH: () => [
		{
			angle: 110,
			blur: 0,
			color: new SolidColor(6, 245, 253, 1),
			distance: -0.03,
			opacity: 1,
			unit: 'em',
		},
		{
			angle: -70,
			blur: 0,
			color: new SolidColor(255, 14, 246, 1),
			distance: -0.03,
			opacity: 1,
			unit: 'em',
		},
	],

	NEON: (color: SolidColor) => [
		{
			angle: 0,
			blur: 0.03,
			color: new SolidColor(255, 255, 255, 1),
			distance: 0,
			opacity: 0.5,
			unit: 'em',
		},
		{
			angle: -135,
			blur: 0.1,
			color,
			distance: 0.03,
			opacity: 0.4,
			unit: 'em',
		},
		{
			angle: 0,
			blur: 0.1,
			color,
			distance: 0,
			opacity: 0.25,
			unit: 'em',
		},
		{
			angle: 0,
			blur: 0.2,
			color,
			distance: 0,
			opacity: 1,
			unit: 'em',
		},
		{
			angle: 0,
			blur: 0.5,
			color,
			distance: 0,
			opacity: 1,
			unit: 'em',
		},
		{
			angle: 0,
			blur: 0.05,
			color,
			distance: 0,
			opacity: 1,
			unit: 'em',
		},
	],

	STICKER: () => ({
		SHADOWS: [
			{
				angle: 45,
				blur: 0.12,
				color: SolidColor.black(),
				distance: 0.165,
				opacity: 0.25,
				unit: 'em',
			},
		],
		OUTLINE: {
			color: SolidColor.white(),
			width: 0.2,
			unit: 'em',
		},
	}),
};
