import { DownloadFormat } from '@/Types/types';

export interface DownloadOption {
	format: DownloadFormat;
	label: string;
	icon: string;
	description: string;
}

export const downloadFormatsHandler = (formats: DownloadFormat[]): DownloadOption[] => {
	const options: DownloadOption[] = [];
	for (const option of formats) {
		if (option == DownloadFormat.png) {
			options.push({
				format: option,
				description: 'To keep high quality in complex images and illustrations',
				icon: 'png',
				label: 'PNG',
			});
		}

		if (option == DownloadFormat.jpg) {
			options.push({
				format: option,
				description: 'Ideal for digital sharing and space-saving',
				icon: 'jpg',
				label: 'JPEG',
			});
		}
	}

	return options;
};

const getMimeType = (format: DownloadFormat): string => {
	if (format == DownloadFormat.jpg) return 'image/jpeg';
	return 'image/png';
};

const getDownloadExtension = (format: DownloadFormat): string => {
	if (format == DownloadFormat.jpg) return 'jpeg';
	return 'png';
};

export const generateDownloadFile = (canvas: HTMLCanvasElement, format: DownloadFormat, title: string): void => {
	const a = document.createElement('a');
	a.href = canvas.toDataURL(getMimeType(format));
	a.download = `${title}.${getDownloadExtension(format)}`;
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
	return;
};
