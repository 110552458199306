import { computed, Ref } from 'vue';

import Element from '@/elements/element/classes/Element';
import { useIsBackground } from '@/elements/element/composables/useIsBackground';
import { usePageElement } from '@/elements/element/composables/usePageElement';
import { BackgroundableElement } from '@/elements/medias/crop/types/croppeable.type';
import { useInteractions } from '@/interactions/composables/useInteractions';

export const useCollisionBackgroundableInfo = (element: Ref<BackgroundableElement>) => {
	const { isDragging } = useInteractions();
	const { page } = usePageElement(element);
	const { isBackground } = useIsBackground(element);
	const elementIndex = computed(() => element.value.index);
	const firstPosition = computed(() => (page.value.backgroundImageId ? 1 : 0));
	const lastPosition = computed(() => page.value.elements.size - 1);

	const canGoDown = computed(() => !!collisioningSortedElementsUnder.value.length && !isBackground.value);
	const canGoUp = computed(() => !!collisioningSortedElementsOver.value.length && !isBackground.value);

	const collisioningElements = computed(() =>
		page.value.elementsAsArray().filter((el) => {
			return (
				!isDragging.value &&
				el.id !== element.value.id &&
				el.id !== page.value.backgroundImageId &&
				el.isCollided(element.value)
			);
		})
	);
	const collisioningSortedElementsOver = computed(() =>
		collisioningElements.value.filter((el: Element) => {
			return el.index > element.value.index;
		})
	);

	// Array ordenado por cercanía al elemento, no por orden de renderizado
	const collisioningSortedElementsUnder = computed(() =>
		collisioningElements.value
			.filter((el: Element) => {
				return el.index < element.value.index;
			})
			.reverse()
	);

	const collisionFilterCb = (el: Element) =>
		el.id !== element.value.id && el.id !== page.value.backgroundImageId && el.isCollided(element.value);

	return {
		elementIndex,
		canGoUp,
		canGoDown,
		collisioningSortedElementsOver,
		collisioningSortedElementsUnder,
		firstPosition,
		lastPosition,
		page,
		collisionFilterCb,
	};
};
