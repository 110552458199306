import { createSharedComposable, promiseTimeout } from '@vueuse/core';
import { nextTick, ref } from 'vue';

import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { Box } from '@/elements/box/classes/Box';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import Image from '@/elements/medias/images/image/classes/Image';
import { useLayersImage } from '@/elements/medias/images/image/composables/useLayersImage';
import { Video } from '@/elements/medias/video/classes/Video';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import { Text } from '@/elements/texts/text/classes/Text';
import { EditPanels } from '@/Types/types';

/**
 * Gestiona los paneles dependiendo del tipo de elemento y disponsitivo
 */
export const usePanelManagement = createSharedComposable(() => {
	const store = useMainStore();
	const { isMockupMode } = useEditorMode();
	const temporalRef = ref(Image.create());

	const { hasForeground } = useLayersImage(temporalRef);

	const { isMobile } = useDeviceInfo();

	/**
	 * gestiona el cierre de paneles
	 * si es mobile, aplicamos el panel principal, si es desktop, el panel se establece como null
	 * @param  {Text | Shape | Image | ForegroundImage | Video} element - detectamos el tipo de elemento
	 * @returns void
	 */
	const closePanel = (element?: Text | Shape | Image | ForegroundImage | Video | Box) => {
		if (!isMobile.value) {
			store.editPanel = null;
			return;
		}
		let panelToUse;
		if (element instanceof Image || element instanceof ForegroundImage) {
			temporalRef.value = element as Image;
			panelToUse =
				hasForeground.value || element instanceof ForegroundImage ? EditPanels.ImageLayerSelector : EditPanels.Image;
		}

		if (element instanceof Text) {
			panelToUse = EditPanels.Text;
		}

		if (element instanceof Video) {
			panelToUse = EditPanels.Video;
		}

		if (element instanceof Box) {
			panelToUse = EditPanels.Box;
		}
		if (!panelToUse) return;
		store.editPanel = panelToUse;
	};

	/**
	 * gestiona los paneles
	 * si pulsamos otra vez en el mismo botón, cerramos el panel
	 * en mobile al cerrar el panel se establece como panel activo el principal
	 * @param element
	 * @param  {Text | Shape | Image | ForegroundImage | Video} newPanel - nuevo panel a establecer
	 * @returns void
	 */
	const togglePanel = (newPanel: EditPanels, element?: Text | Shape | Image | ForegroundImage | Video | Box) => {
		if (store.editPanel === newPanel) {
			closePanel(element);
			return;
		}

		if (isMockupMode.value) store.activePanel = null;
		store.editPanel = newPanel;
	};

	/**
	 * refrescamos el panel para la versión responsive
	 * se necesita para recalcular el desplazamiento del page y que se lance el watcher desde useMovePageOnEditPanelOverFocused
	 * @returns void
	 */
	const refreshMobilePanel = async () => {
		if (!isMobile.value || !store.editPanel) return;

		const currentPanel = store.editPanel;

		store.editPanel = null;
		await nextTick();
		store.editPanel = currentPanel;
	};

	return { closePanel, togglePanel, refreshMobilePanel };
});
