import { computed, Ref } from 'vue';

import { useCroppeableTransform } from '@/elements/medias/crop/composables/useCroppeableTransform';
import BaseImage from '@/elements/medias/images/base-image/classes/BaseImage';
import { usePhotoMode } from '@/elements/medias/images/image/composables/usePhotoMode';
import { Anchor, Size } from '@/Types/types';

export const useImageTransform = (image: Ref<BaseImage>) => {
	const { checkIsPhotoModeImage } = usePhotoMode();
	const usingCroppeableTransform = useCroppeableTransform(image);

	const isPhotoModeImage = computed(() => checkIsPhotoModeImage(image.value));

	const adjustTo = (side: keyof Size, val: number) => {
		if (!isPhotoModeImage.value && image.value.locked) return;

		usingCroppeableTransform.adjustTo(side, val);
	};

	const align = (position: Anchor): void => {
		if (!isPhotoModeImage.value && image.value.locked) return;
		usingCroppeableTransform.align(position);
	};

	const move = (x: number, y: number, relative = true): void => {
		if (!isPhotoModeImage.value && image.value.locked) return;
		usingCroppeableTransform.move(x, y, relative);
	};

	const resize = (width: number, height: number) => {
		if (!isPhotoModeImage.value && image.value.locked) return;
		usingCroppeableTransform.resize(width, height);
	};

	const rotate = (angle: number, relative = true) => {
		if (!isPhotoModeImage.value && image.value.locked) return;

		if (relative) {
			image.value.rotation += angle;

			if (isPhotoModeImage.value) {
				move(usingCroppeableTransform.left.value, usingCroppeableTransform.top.value, false);
			}
			return;
		}

		image.value.setRotation(angle);
	};

	const scale = (scale: number) => {
		if (image.value.locked) return;
		usingCroppeableTransform.scale(scale);
	};

	return {
		...usingCroppeableTransform,
		adjustTo,
		align,
		move,
		resize,
		rotate,
		scale,
	};
};
