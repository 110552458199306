import { Ref, ref } from 'vue';

import { useEnvSettings } from '@/common/composables/useEnvSettings';

export const useSetNextPageInURL = <T>(url: Ref<string>, chunk: T[], clean = true) => {
	const { APP_API_PATH } = useEnvSettings();
	const nextUrlRef = ref('');
	if (!chunk.length) return nextUrlRef;
	const fullUrl = new URL(APP_API_PATH + url.value);
	const limit = fullUrl.searchParams.get('limit') || '';
	if (chunk.length < parseInt(limit)) return nextUrlRef;
	const currentPage = fullUrl.searchParams.get('page') || '1';
	const nextPage = currentPage ? parseInt(currentPage) + 1 : 2;
	fullUrl.searchParams.set('page', nextPage.toString());
	const nextUrl = clean ? fullUrl.toString().replace(APP_API_PATH, '') : fullUrl.toString();
	nextUrlRef.value = nextUrl;
	return nextUrlRef;
};
