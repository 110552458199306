import { BlendRange, Color, LayerType, Resource, Size } from '@/apps/mockup/schemas/renderSchema';
import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';


export type Layer = LayerCanvasScheme | GroupLayerScheme | SmartObjectLayerScheme | ColorLayerScheme;
export interface LayerCanvasScheme {
	id: string;
	kind: LayerType;
	name: string;
	blendMode: string;
	opacity: string;
	canvas: HTMLCanvasElement;
	size: Size;
	visible: boolean;
	mask?: Resource;
	layerCached?: HTMLImageElement | HTMLCanvasElement | null;
	maskCached?: HTMLImageElement | null;
	hadChanges?: boolean | null; //This key is to inform to father Group if layer had changes
	lastChangeIdComposed?: string | undefined; //This key is used to validate when a SmartObject not is necesary recompose and should be used their image cached
	meta: any;
	blendIf: BlendRange[];
	isClippingMask: boolean;

	createMask(uri: string): HTMLImageElement;

	drawImage(ctx: CanvasRenderingContext2D, resource: CanvasImageSource): void;

	getContext(): CanvasRenderingContext2D;
}

export interface ColorLayerScheme extends LayerCanvasScheme {
	color: SolidColor | Color;

	buildColor(): HTMLCanvasElement;

	rgbColor(): string;

	buildGradientWith(gradient: GradientColor, toCtx: CanvasRenderingContext2D): CanvasGradient;
}

export interface GroupLayerScheme extends LayerCanvasScheme {
	children: Array<LayerCanvasScheme>;
	processDecision: CallableFunction;

	buildGroup(): HTMLCanvasElement;
}

export interface ImageLayerScheme extends LayerCanvasScheme {
	image: Resource;

	buildImage(): HTMLCanvasElement;
}

export interface SmartObjectLayerScheme extends LayerCanvasScheme {
	smartObject: Resource;
	path: string;
	imageData?: HTMLCanvasElement;
	resolution?: number;

	smartObjectDocumentId?:string;

	buildSmartObject(): HTMLCanvasElement;
}

export enum ColorKeysType {
	rgb = 'rgb',
	rgba = 'rgba',
	hex = 'hex',
}
