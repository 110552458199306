import { createSharedComposable } from '@vueuse/core';
import { v4 as uuidv4 } from 'uuid';

export const useSessionInfo = createSharedComposable(() => {
	const uuid = uuidv4();
	console.info('Editor Session ID', uuid);
	return {
		uuid,
	};
});
