import Bugsnag from '@bugsnag/js';
import { reactive, UnwrapNestedRefs } from 'vue';

// @ts-ignore
import type { ModalError } from '@/apps/mockup/components/ModalError.vue';
import { ErrorParams, MockupErrorFlag, MockupErrorType } from '@/apps/mockup/Types/errorTypes';

export const mockupError = reactive({
	title: 'Returning to landing page',
	description: 'Your progress wisll be lost, do you still want to continue?',
	type: MockupErrorFlag.error,
	visible: false,
	successButton: 'Return to landing',
	discardButton: 'cancel',
});

export let fire: UnwrapNestedRefs<ModalError> = reactive({});

export const useErrorMockup = () => {
	/**
	 * Method to register error in global level
	 * @param {MockupErrorType} errorType Error in Mockup editor
	 */
	const registerMockupError = (errorType: MockupErrorType): void => {
		mockupError.title = 'Error in the matrix';
		mockupError.description = 'We are sorry but there has been an error (4XX) when loading your mockup.';
		mockupError.type = MockupErrorFlag.error;
		mockupError.successButton = 'Return to search';
		mockupError.discardButton = 'Refresh page';

		console.warn('Error in mockup', errorType);
		Bugsnag.notify(errorType);
		console.warn('Alert in mockup hidden');

		fire.show();
	};

	const registerMockupAlert = (params: ErrorParams): Promise<{ success: boolean }> => {
		mockupError.title = params.title;
		mockupError.description = params.description;
		mockupError.type = MockupErrorFlag.warning;
		mockupError.discardButton = params.discardButton ?? mockupError.discardButton;
		mockupError.successButton = params.successButton ?? mockupError.successButton;
		console.warn('Alert in mockup hidden');
		// return fire.show();
	};

	const setFireElement = (callable: ModalError) => {
		fire = reactive(callable);
	};

	return {
		registerMockupAlert,
		registerMockupError,
		setFireElement,
	};
};
