import { promiseTimeout } from '@vueuse/core';
import { Ref } from 'vue';

import TextTools from '@/elements/texts/text/utils/TextTools';
import {
	PreviousSelection,
	RangeContainersIndex,
	RestoreSelectionParams,
	SelectionDirection,
	SelectionResult,
} from '@/Types/types';

class TextSelectionTools {
	/**
	 * Retorna true si se ha seleccionado el texto al completo
	 * @param range
	 */
	static detectFullRange(selection: Selection, domNode: HTMLElement): boolean {
		if (!selection && domNode) return true;

		// si el selection no tiene ningun nodo, selección completa
		if (!selection.focusNode) {
			return true;
		}

		const nodes = TextTools.getSelectedNodes();
		const selectionLength = Math.abs(selection.anchorOffset - selection.focusOffset);

		// si no hay nada en selection
		if (!nodes.length) {
			return true;
		}

		// si solo hay un elemento y la selección es de tipo caret, el parent
		if (
			nodes.length === 1 &&
			selectionLength === 0 &&
			selection.type.toLowerCase() === 'caret' &&
			!domNode.children.length
		) {
			return true;
		}

		// Comprobamos si no tenemos nodos hijos y que la longitud del nodo sea la misma que la de la selección
		if (nodes.length === 1 && domNode?.textContent?.length === selectionLength) {
			return true;
		}

		// Comprobamos si no tenemos nodos hijos y si hemos seleccionado el texto completo
		if (
			nodes.length === 1 &&
			domNode?.textContent &&
			domNode.childNodes.length === 1 &&
			domNode.firstChild?.nodeType === 3 &&
			this.isWholeTextSelected(domNode)
		) {
			return true;
		}

		// Comprobamos si no tenemos algún nodo hijo y en caso de tenerlo si hemos seleccionado en algún de los dos sentidos
		if (nodes.length > 1 && domNode?.textContent && this.isWholeTextSelected(domNode)) {
			return true;
		}

		// Comprobamos si solo tenemos un nodo seleccionado y es el nodo editable
		if (nodes.length === 1 && nodes[0] instanceof HTMLElement && nodes[0].id.startsWith('editable-')) {
			return true;
		}

		return false;
	}

	/**
	 * This function receives selection params and a domNode and generate a new selection with these params
	 * @param anchorNode selection anchorNode
	 * @param anchorOffset selection anchorOffset
	 * @param focusNode selection focusNode
	 * @param focusOffset selection focusOffset
	 * @param isCollapsed selection collapsed
	 * @param domNode Text domNode
	 * @param containersIndex index of anchorNode and focusNode
	 */
	static restoreSelection({
		anchorNode,
		anchorOffset,
		focusNode,
		focusOffset,
		isCollapsed,
		domNode,
		containersIndex,
		selection,
	}: RestoreSelectionParams) {
		selection?.selection?.removeAllRanges();

		if (selection) {
			selection = null;
		}

		let rangeIsSet = false;
		let aNode: Node | undefined;
		let fNode: Node | undefined;

		if (anchorNode && domNode) {
			const range = document.createRange();

			const children = TextTools.getNodesFromRootNode(domNode);
			const htmlChildren = domNode.querySelectorAll('*');

			if (children?.length) {
				Array.from(children).forEach((child) => {
					const index = TextSelectionTools.findIndexOfSelectedNode(domNode, child);

					// En caso de que los nodos seleccionados no coincidan
					if (
						anchorNode !== focusNode &&
						domNode?.textContent &&
						Math.abs(anchorOffset + focusOffset) <= domNode?.textContent?.length
					) {
						// En caso de ser un hijo de tipo text añadimos text, si no buscamos el text de dicho span, puesto que el rango necesita al nodo Text
						if (!aNode && child.textContent === anchorNode.textContent && index === containersIndex?.anchorNodeIndex) {
							aNode = child;
						}
						if (!fNode && child.textContent === focusNode.textContent && index === containersIndex?.focusNodeIndex) {
							fNode = child;
						}
					} else {
						if (
							!rangeIsSet &&
							anchorNode.textContent === child.textContent &&
							index === containersIndex?.anchorNodeIndex
						) {
							// Si es de tipo caret
							if (isCollapsed) {
								// En caso de ser un hijo de tipo text añadimos text, si no buscamos el text de dicho span puesto que el rango necesita al nodo Text
								if (child.nodeType === 3) {
									range.setStart(child, anchorOffset);
								} else {
									range.setStart(child.childNodes[0], anchorOffset);
								}

								// Hacemos que sea collapse para que sea de tipo caret
								range.collapse(true);
								rangeIsSet = true;
							} else {
								range.selectNodeContents(child);
								rangeIsSet = true;
							}
						}
					}

					// En caso de que los nodos (anchorNode y focusNode) seleccionados no coincidan
					if (!rangeIsSet && aNode && fNode && aNode.textContent && fNode.textContent && domNode?.textContent) {
						// Asignamos el rango final
						if (this.getSelectionDirection(aNode, fNode) === 'before') {
							range.setStart(aNode, anchorOffset);
							range.setEnd(fNode, focusOffset);

							rangeIsSet = true;
						} else {
							range.setStart(fNode, focusOffset);
							range.setEnd(aNode, anchorOffset);

							rangeIsSet = true;
						}
					}
				});
			}

			// En caso de que los nodos seleccionados no coincidan y no se haya asignado el rango,
			// buscamos los nodos hijos que sean HTMLElements para asignar el rango
			if (!aNode || !fNode) {
				if (anchorNode.nodeType === 1 || focusNode.nodeType === 1) {
					htmlChildren.forEach((child) => {
						const index = TextSelectionTools.findIndexOfSelectedNode(domNode, child);

						if (aNode && fNode) return;

						if (!aNode && index === containersIndex?.anchorNodeIndex) {
							aNode = child;
						}
						if (!fNode && index === containersIndex?.focusNodeIndex) {
							fNode = child;
						}
					});
				}

				if (aNode && fNode) {
					if (this.getSelectionDirection(aNode, fNode) === 'before') {
						range.setStart(aNode, anchorOffset);
						range.setEnd(fNode, focusOffset);
					} else {
						range.setStart(fNode, focusOffset);
						range.setEnd(aNode, anchorOffset);
					}
				}
			}

			document.getSelection()?.addRange(range);

			if (selection) {
				selection = { text: document.getSelection()?.toString(), selection: document.getSelection() };
			}
		}
	}

	// Corrige una selección de texto que pueda contener nodos vacíos
	static fixTextSelection(domNode: Ref<HTMLElement | null>): void {
		const temporalSel = document.getSelection();

		// Si la selección tiene contenido, no corregimos la selección
		if (temporalSel?.anchorNode?.textContent?.length && temporalSel?.focusNode?.textContent?.length) return;

		if (temporalSel) {
			const { anchorNode, anchorOffset, focusNode, focusOffset, isCollapsed } = temporalSel;

			if (anchorNode && focusNode && (anchorNode?.textContent?.length || focusNode?.textContent?.length)) {
				let finalAnchorNode: Node | null = anchorNode;
				let finalFocusNode: Node | null = focusNode;
				let finalAnchorOffset = anchorOffset;
				let finalFocusOffset = focusOffset;

				const direction = this.getSelectionDirection(anchorNode, focusNode);

				if (anchorNode.textContent === '') {
					finalAnchorNode = this.compareAndFindNodes(anchorNode, focusNode);

					if (finalAnchorNode && this.getSelectionDirection(anchorNode, finalAnchorNode) === 'before') {
						// Si anchorNode está antes de finalAnchorNode, el nodo final será el último nodo de texto
						finalAnchorNode = TextTools.getFirstTextChild(finalAnchorNode);
					} else if (finalAnchorNode && this.getSelectionDirection(anchorNode, finalAnchorNode) === 'after') {
						// Si anchorNode está detrás de finalAnchorNode, el nodo final será el primer nodo de texto
						finalAnchorNode = TextTools.getLastTextChild(finalAnchorNode);
					}

					// Si la selección es de izquierda a derecha y el nodo final tiene contenido, el offset será la longitud del nodo
					// Si la selección es de derecha a izquierda, el offset será 0
					if (direction === 'before' && finalAnchorNode?.textContent) {
						finalAnchorOffset = finalAnchorNode.textContent.length;
					} else if (direction === 'after') {
						finalAnchorOffset = 0;
					}
				}

				if (focusNode.textContent === '') {
					finalFocusNode = this.compareAndFindNodes(focusNode, anchorNode);

					if (finalFocusNode && this.getSelectionDirection(focusNode, finalFocusNode) === 'before') {
						finalFocusNode = TextTools.getFirstTextChild(finalFocusNode);
					} else if (finalFocusNode && this.getSelectionDirection(focusNode, finalFocusNode) === 'after') {
						finalFocusNode = TextTools.getLastTextChild(finalFocusNode);
					}

					// Si la selección es de izquierda a derecha, el offset será 0
					// Si la selección es de derecha a izquierda y el nodo final tiene contenido, el offset será la longitud del nodo
					if (direction === 'before') {
						finalFocusOffset = 0;
					} else if (direction === 'after' && finalFocusNode?.textContent) {
						finalFocusOffset = finalFocusNode.textContent.length;
					}
				}

				// Buscamos el orden de la selección porque siempre debe crearse la selección de izquierda a derecha
				const rangeAnchorNode = finalAnchorNode ? finalAnchorNode : anchorNode;
				const rangeFocusNode = finalFocusNode ? finalFocusNode : focusNode;

				const rangeDirection = this.getSelectionDirection(rangeAnchorNode, rangeFocusNode);

				// Eliminamos la selección actual
				document.getSelection()?.removeAllRanges();

				// Creamos la nueva selección
				this.createRange(
					{
						anchorNode: rangeDirection === 'before' ? rangeAnchorNode : rangeFocusNode,
						anchorOffset: rangeDirection === 'before' ? finalAnchorOffset : finalFocusOffset,
						focusNode: rangeDirection === 'before' ? rangeFocusNode : rangeAnchorNode,
						focusOffset: rangeDirection === 'before' ? finalFocusOffset : finalAnchorOffset,
						isCollapsed,
					},
					domNode
				);
			}

			// Si no hay nodos con contenido, se elimina la selección
			if (!anchorNode?.textContent?.length || !focusNode?.textContent?.length) return;

			this.sanitizeEmptySelection();
		}
	}

	/**
	 * Esta función recibe los parámetros para una selección y crea un nuevo rango con dichos parámetros
	 * @param sel
	 * @param text
	 * @param domNode
	 */
	static createRange(sel: PreviousSelection, domNode: Ref<HTMLElement | null>) {
		document.getSelection()?.removeAllRanges();

		const { anchorNode, anchorOffset, focusNode, focusOffset, isCollapsed, wholeTextSelected } = sel;
		const range = new Range();
		let isSetRange = range.startContainer !== document;

		// En caso de que sea una selección completa del texto
		if (anchorNode && focusNode && wholeTextSelected && !isSetRange) {
			range.selectNodeContents(anchorNode);

			isSetRange = true;
		}

		// En caso de ser una selección de tipo caret
		if (
			anchorNode.textContent &&
			domNode.value?.textContent &&
			domNode.value?.textContent?.indexOf(anchorNode.textContent) >= 0 &&
			isCollapsed &&
			!isSetRange
		) {
			range.setStart(focusNode, focusOffset);
			range.collapse(true);
			isSetRange = true;
		}

		const isAnchorFirst = this.getSelectionDirection(anchorNode, focusNode, sel) === 'before';

		// En caso de ser una selección de tipo range
		if (anchorNode && focusNode && !isSetRange) {
			range.setStart(isAnchorFirst ? anchorNode : focusNode, isAnchorFirst ? anchorOffset : focusOffset);
			range.setEnd(isAnchorFirst ? focusNode : anchorNode, isAnchorFirst ? focusOffset : anchorOffset);

			isSetRange = true;
		}

		document.getSelection()?.addRange(range);
	}

	static compareAndFindNodes(startNode: Node | HTMLElement, endNode: Node | HTMLElement) {
		// Compara la posición de los nodos
		const direction = this.getSelectionDirection(startNode, endNode);

		if (direction === 'before') {
			// El nodo de inicio va antes que el nodo de finalización

			// Buscar el nodo anterior al nodo de inicio con contenido
			let previousNodeWithContent = this.findPreviousNodeWithContent(startNode);

			if (!previousNodeWithContent) {
				// Si no se encontró un nodo anterior con contenido, busca el siguiente nodo con contenido
				previousNodeWithContent = this.findNextNodeWithContent(startNode);
			}

			return previousNodeWithContent;
		} else {
			// El nodo de inicio no va antes que el nodo de finalización
			return null;
		}
	}

	static findPreviousNodeWithContent(node: Node | HTMLElement): Node | HTMLElement | null {
		let previousNode = node.previousSibling;

		while (previousNode) {
			if (previousNode.textContent?.trim() !== '') {
				return previousNode;
			}

			previousNode = previousNode.previousSibling;
		}

		if (node.parentNode) {
			return this.findPreviousNodeWithContent(node.parentNode);
		}

		return null;
	}

	static findNextNodeWithContent(node: Node | HTMLElement): Node | HTMLElement | null {
		let nextNode = node.nextSibling;

		while (nextNode) {
			if (nextNode.textContent?.trim() !== '') {
				return nextNode;
			}

			nextNode = nextNode.nextSibling;
		}

		if (node.parentNode) {
			return this.findNextNodeWithContent(node.parentNode);
		}

		return null;
	}

	/**
	 *  NOTA: Para los ejemplos el carácter '|' sería el caret de la selección
	 *
	 * 	Si existe un rango de selección y los nodos de inicio y fin no son el mismo,
	 *	comprobamos si se ha seleccionado el final de una posible palabra para iniciar la seleccion [Ex: " hola |"]
	 *	o si ha terminado en el principio de una posible palabra para finalizar la selección, [Ex: "| hola "]
	 *	para evitar que se seleccione el nodo commpleto sin tener contenido seleccionado
	 *
	 *	[Ex: En este caso si se cumple alguna de estas dos opciones se aplicarían los estilos al nodo " hola " completo]
	 *
	 * 	En caso de que se cumpla alguna de estas condiciones, se elimina el rango de selección y se crea uno nuevo
	 * 	que comienza en el nodo siguiente al nodo de inicio o finaliza en el nodo anterior al nodo de finalización
	 */
	static sanitizeEmptySelection(): void {
		const temporalSelection = document.getSelection();

		// Comprobamos si existe un rango de selección
		if (temporalSelection?.rangeCount && temporalSelection?.rangeCount > 0) {
			const range = temporalSelection?.getRangeAt(0);
			const startNode = range?.startContainer;
			const endNode = range?.endContainer;

			if (startNode.textContent !== '' && endNode.textContent !== '') return;

			let rangeIsSet = false;

			// Comprobamos si el nodo de inicio es un nodo de texto y si el texto está vacío
			if (startNode.nodeType === 1 && startNode instanceof HTMLElement && startNode.textContent === '' && !rangeIsSet) {
				if (this.getSelectionDirection(startNode, endNode) === 'before') {
					if (startNode.nextElementSibling?.firstChild) {
						range.setStartBefore(startNode.nextElementSibling?.firstChild);
					} else {
						range.setStartAfter(startNode);
					}
					rangeIsSet = true;
				} else {
					if (startNode.previousElementSibling?.lastChild) {
						range.setStartAfter(startNode.previousElementSibling?.lastChild);
					} else {
						range.setStartBefore(startNode);
					}
					rangeIsSet = true;
				}
			}

			// Comprobamos si el nodo de finalización es un nodo de texto y si el texto está vacío
			if (endNode.nodeType === 1 && endNode instanceof HTMLElement && endNode.textContent === '' && !rangeIsSet) {
				if (this.getSelectionDirection(startNode, endNode) === 'before') {
					if (endNode.previousElementSibling?.lastChild) {
						range.setEndAfter(endNode.previousElementSibling?.lastChild);
					} else {
						range.setEndBefore(endNode);
					}
					rangeIsSet = true;
				} else {
					if (endNode.nextElementSibling?.firstChild) {
						range.setStartBefore(endNode.nextElementSibling?.firstChild);
					} else {
						range.setStartAfter(endNode);
					}
					rangeIsSet = true;
				}
			}

			if (range && startNode && endNode && startNode !== endNode) {
				// Si el nodo de inicio es un nodo de texto y el offset es igual a la longitud del nodo de inicio ["Ex: " hola |"]
				// Buscamos el nodo siguiente al nodo de inicio y si existe, lo seleccionamos
				if (
					startNode === temporalSelection?.anchorNode &&
					temporalSelection?.anchorOffset === temporalSelection?.anchorNode?.textContent?.length &&
					!rangeIsSet
				) {
					if (!startNode.nextSibling && startNode.parentElement?.nextElementSibling instanceof HTMLElement) {
						range.setStartBefore(TextTools.getFirstTextChild(startNode.parentElement.nextElementSibling));
					}
				}

				// Si el nodo final es un nodo de texto y el offset es 0 ["Ex: "| hola "]
				// Buscamos el nodo siguiente al nodo final y si existe, lo seleccionamos
				if (endNode === temporalSelection?.focusNode && temporalSelection?.focusOffset === 0 && !rangeIsSet) {
					if (!endNode.nextSibling && endNode.parentElement?.nextElementSibling instanceof HTMLElement) {
						range.setStartBefore(TextTools.getFirstTextChild(endNode.parentElement.nextElementSibling));
					}
				}

				document.getSelection()?.removeAllRanges();
				document.getSelection()?.addRange(range);
			}
		}
	}

	private static isWholeTextSelected(domNode: HTMLElement) {
		const selection = document.getSelection();

		const firstChild = TextTools.getFirstTextChild(domNode);
		const lastChild = TextTools.getLastTextChild(domNode);

		const firstChildCondition =
			(firstChild === selection?.anchorNode && selection?.anchorOffset === 0) ||
			(firstChild === selection?.focusNode && selection?.focusOffset === 0);

		const lastChildCondition =
			(lastChild === selection?.anchorNode && selection?.anchorOffset === lastChild?.textContent?.length) ||
			(lastChild === selection?.focusNode && selection?.focusOffset === lastChild?.textContent?.length);

		return selection && firstChildCondition && lastChildCondition && !selection.isCollapsed;
	}

	/**
	 * Esta función recibe un nodo y un id del elemento que contiene el nodo, y devuelve el color que aplicaría al nodo
	 * @param node current node
	 * @param mainNodeId Id del elemento en el que queremos parar de obtener un posible color
	 * @returns color
	 */
	static getColorFromNode(
		node: HTMLElement | Node,
		mainNodeId: string,
		originalNode: HTMLElement | Node
	): string | undefined {
		let nodeColor;

		if (node.nodeType === 3 && node.parentElement) {
			return this.getColorFromNode(node.parentElement, mainNodeId, originalNode);
		}

		if (node instanceof HTMLElement) {
			const nodeColorId = Object.values(node.style)
				.find((style) => style.includes('--color'))
				?.split('--')[1]
				.split(')')[0];

			// Si el Nodo que estamos recorriendo es un Div y todos sus hijos son nodos Text
			const isDivChildWithoutChilds =
				node.nodeName.toLowerCase() === 'div' && Object.values(node.childNodes).every((n) => n.nodeType === 3);

			// Si el nodo que estamos recorriendo tiene como hijo al Text original
			const isCurrentNodeParentOfOriginalNode = Object.values(node.childNodes).find((el) => el === originalNode);

			// Si el nodo es un div y no tiene color y sigue tienendo un padre
			const nodeHaveNotColor =
				node.nodeName.toLocaleLowerCase() === 'div' && node.parentElement && !node.style.color.startsWith('--color');

			if (
				!nodeColorId &&
				node.id !== mainNodeId &&
				node.parentElement &&
				(isDivChildWithoutChilds || isCurrentNodeParentOfOriginalNode || nodeHaveNotColor)
			) {
				return this.getColorFromNode(node.parentElement, mainNodeId, originalNode);
			}

			nodeColor = nodeColorId;
		}

		return nodeColor;
	}

	/**
	 * Esta función recibe un nodo devuelve todos los nodos #text de dicho nodo y sus hijos
	 * @param node current node
	 * @returns textNodes array
	 */
	static getDeepTextNodes(node: HTMLElement): (Node | undefined)[] {
		return Array.from(node.childNodes)
			.map((htmlNode: HTMLElement | Node) => {
				if (htmlNode.nodeType === 1 && htmlNode instanceof HTMLElement) {
					return this.getDeepTextNodes(htmlNode).flat(1);
				}
				if (htmlNode.nodeType === 3 && htmlNode.nodeName === '#text') {
					return htmlNode;
				}
			})
			.flat(1);
	}

	/**
	 * Esta función recibe un nodo y devuelve el índice del nodo seleccionado
	 * @param text Texto
	 * @param range Rango de selección
	 * @returns Object with { anchorNodeIndex: number; focusNodeIndex: number } donde -1 es que el nodo no ha sido encontrado
	 */
	static getRangeContainersIndex = (domNode: Ref<HTMLElement | null>): RangeContainersIndex => {
		const temporalSelection = document.getSelection() as Selection;
		const range = temporalSelection.getRangeAt(0);

		const { anchorNode, focusNode } = temporalSelection;

		let startContainerIndex = -1;
		let endContainerIndex = -1;

		const commonAncestorContainer = domNode.value;

		if (range && commonAncestorContainer) {
			startContainerIndex = TextSelectionTools.findIndexOfSelectedNode(commonAncestorContainer, range.startContainer);
			endContainerIndex = TextSelectionTools.findIndexOfSelectedNode(commonAncestorContainer, range.endContainer);
		}

		if (anchorNode && focusNode && this.getSelectionDirection(anchorNode, focusNode) === 'before') {
			return { anchorNodeIndex: startContainerIndex, focusNodeIndex: endContainerIndex };
		}

		return { anchorNodeIndex: endContainerIndex, focusNodeIndex: startContainerIndex };
	};

	/**
	 * Esta función recibe un nodo y devuelve el índice del nodo seleccionado
	 * @param node
	 * @param selectionNode
	 * @returns
	 */
	static findIndexOfSelectedNode(node: Node | HTMLElement, selectionNode: Node | HTMLElement): number {
		let index = 0;
		for (let i = 0; i < node.childNodes.length; i++) {
			const childNode = node.childNodes[i];
			if (childNode === selectionNode) {
				return index;
			}
			if (childNode.nodeType === Node.ELEMENT_NODE) {
				const result = this.findIndexOfSelectedNode(childNode, selectionNode);
				if (result !== -1) {
					index += result;
					return index;
				} else {
					index += 1; // Count this node and its descendants
				}
			} else if (childNode.nodeType === Node.TEXT_NODE && childNode.textContent) {
				index += childNode.textContent.length;
			}
		}
		return -1; // Node not found
	}

	static getSelectionDirection(
		node1: Node | HTMLElement,
		node2: Node | HTMLElement,
		sel?: PreviousSelection
	): SelectionDirection {
		// Si se trata del mismo nodo en la selección, comparamos sus offsets para determinar su dirección
		if (node1 === node2 && sel) {
			const isBeforeDirection = sel.anchorOffset < sel.focusOffset;
			return isBeforeDirection ? 'before' : 'after';
		}
		if (node1.compareDocumentPosition(node2) === Node.DOCUMENT_POSITION_FOLLOWING) {
			return 'before';
		} else if (node1.compareDocumentPosition(node2) === Node.DOCUMENT_POSITION_PRECEDING) {
			return 'after';
		}

		return 'nodes not found';
	}

	/**
	 * Corregimos el array de nodos finales en caso de que alguno de ellos no tenga ningún texto seleccionado
	 * @param finalNodes
	 */
	static fixFinalnodesWhereHasEmptyNodes = (finalNodes: Node[], selection: Ref<SelectionResult | null>) => {
		if (selection.value?.selection?.anchorNode && selection.value?.selection.focusNode) {
			const selectionDirection = TextSelectionTools.getSelectionDirection(
				selection.value?.selection.anchorNode,
				selection.value?.selection.focusNode
			);

			if (
				selection.value?.selection.anchorOffset === selection.value?.selection.anchorNode.textContent?.length &&
				selectionDirection === 'before'
			) {
				const anchorIndex = finalNodes.findIndex((node) => node === selection.value?.selection?.anchorNode);

				if (anchorIndex >= 0) finalNodes.splice(anchorIndex, 1);
			}

			if (
				selection.value?.selection?.focusOffset === selection.value?.selection.focusNode.textContent?.length &&
				selectionDirection === 'after'
			) {
				const focusIndex = finalNodes.findIndex((node) => node === selection.value?.selection?.focusNode);

				if (focusIndex && focusIndex >= 0) finalNodes.splice(focusIndex, 1);
			}
		}
	};

	static selectAllText = async (domNode: Ref<HTMLElement | null>) => {
		if (domNode.value) {
			const range = document.createRange();
			range.selectNodeContents(domNode.value);
			document.getSelection()?.removeAllRanges();
			document.getSelection()?.addRange(range);
		}

		// Esperamos 1ms para que se actualice la selección mediante el evento del navegador
		await promiseTimeout(1);
	};

	/**
	 * 	Genera un nuevo rango que contiene el elemento sobre el que está el puntero (caret)
	 * @returns Retorna undefined o un objeto con el nodo y la posición del caret
	 */
	static generateRangefromCaret = (domNode: Ref<HTMLElement | null>, selection: Ref<SelectionResult | null>) => {
		// Si tenemos algún hijo y la selección es de tipo caret sobre el padre debemos generar un nuevo span en la parte correspondiente al puntero
		// Ej: <div>Hola <span>mu</span>nd|o</div> --- > | representa a la selección de tipo caret
		let oldRange: undefined | { anchorNode: Node; anchorOffset: number };

		if (
			domNode.value &&
			selection.value &&
			selection.value.selection?.isCollapsed &&
			((selection.value.selection?.anchorNode?.nodeType === 1 &&
				selection.value.selection?.anchorNode === domNode.value) ||
				(selection.value.selection?.anchorNode?.nodeType === 3 &&
					selection.value.selection?.anchorNode.parentElement?.closest(`#${domNode.value.id}`))) &&
			domNode.value.querySelectorAll('*').length
		) {
			// Clonamos la selección para restaurarla después de generar el Span
			oldRange = {
				anchorNode: selection.value.selection?.anchorNode,
				anchorOffset: selection.value.selection?.anchorOffset,
			};

			const newRange = new Range();

			newRange.selectNodeContents(selection.value.selection.anchorNode);

			selection.value?.selection?.removeAllRanges();
			selection.value?.selection?.addRange(newRange);
		}

		return oldRange;
	};

	/**
	 * Elimina todos los rangos de selección del documento
	 */
	static clearSelectionText = () => {
		document.getSelection()?.removeAllRanges();
	};
}

export default TextSelectionTools;
