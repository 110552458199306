import { useFetch, UseFetchOptions, UseFetchReturn } from '@vueuse/core';
import { Ref, ref } from 'vue';

import { concatenatedStorysetsResponseMapper, splittedStorysetsResponseMapper } from '@/api/ClientApiMapper';
import { useEmptyDataOnUrlParamChange } from '@/api/composables/useEmptyDataOnUrlParamChange';
import { MappedApiResponse, StorysetApi } from '@/Types/apiClient';

export const getConcatenatedStorysets = (
	url: Ref<string>,
	options: UseFetchOptions = {}
): UseFetchReturn<MappedApiResponse<StorysetApi[]>> => {
	const concatenatedData = ref<StorysetApi[]>([]);

	useEmptyDataOnUrlParamChange(url, concatenatedData);

	return useFetch(url, {
		...options,
		afterFetch: (ctx) => concatenatedStorysetsResponseMapper(ctx, concatenatedData, url),
	})
		.get()
		.json();
};

export const getSplittedStorysets = (
	url: Ref<string>,
	options: UseFetchOptions = {}
): UseFetchReturn<MappedApiResponse<StorysetApi[]>> => {
	return useFetch(url, {
		...options,
		afterFetch: (ctx) => splittedStorysetsResponseMapper(ctx, url),
	})
		.get()
		.json();
};
