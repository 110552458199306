import Bugsnag from '@bugsnag/js';
import { createFetch } from '@vueuse/core';

import { useAuth } from '@/auth/composables/useAuth';
import { useEnvMockupSettings } from '@/apps/mockup/composable/useEnvMockupSettings';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { useI18n } from '@/i18n/useI18n';
const { APP_API_MOCKUPS_PATH } = useEnvMockupSettings();

export const useMockupApiFetch = createFetch({
	baseUrl: APP_API_MOCKUPS_PATH,
	options: {
		timeout: 60000,
		beforeFetch(ctx) {
			const { options, url } = ctx;
			const customHeaders = options.headers ? new Headers(options.headers) : new Headers();

			customHeaders.append('Accept', 'application/json');
			let newUrl = url;

			if(TextTools.isValidUrl(url)) {
				const { locale } = useI18n();
				const urlParser = new URL(url);

				urlParser.searchParams.set('locale', locale.value);
				urlParser.searchParams.set('filters[content_type]', 'psd');
				urlParser.searchParams.set('filters[quick-edit]', '0');

				newUrl = urlParser.toString();
			}

			options.headers = customHeaders;

			return {
				...ctx,
				options,
				url: newUrl.toString(),
			};
		},
		onFetchError(ctx) {
			if (ctx.response?.status !== 401) {
				Bugsnag.leaveBreadcrumb('API Error', ctx);
			}

			const { checkLoginStatus } = useAuth();
			const inIgnoredUrls = ['auth/verify', 'check-api-key'].find((url) => ctx.response?.url.includes(url));

			if ([401, 419].includes(ctx.response?.status || 0) && !inIgnoredUrls) {
				checkLoginStatus();
			}

			ctx.error.status = ctx.response?.status;
			if (!(ctx.error instanceof DOMException && ctx.error.name === 'AbortError')) {
				ctx.error.data = ctx.data;
			}

			if (ctx.response && ctx.response.status === 426 && !window.reloadingInProgress) {
				window.reloadingInProgress = true;
				window.location.reload();
			}

			return ctx;
		},
	},
});
