import Bugsnag from '@bugsnag/js';
import { createSharedComposable } from '@vueuse/core';
import { cloneDeep, findIndex } from 'lodash-es';
import { computed, nextTick, Ref, ref } from 'vue';

import { useMainStore } from '@/editor/stores/store';
import Element from '@/elements/element/classes/Element';
import ElementTools from '@/elements/element/utils/ElementTools';
import { TransformTools } from '@/elements/element/utils/TransformTools';
import Page from '@/page/classes/Page';
import { usePage } from '@/page/composables/usePage';
import { useArtboard } from '@/project/composables/useArtboard';
import { useProjectStore } from '@/project/stores/project';
import { Color } from '@/Types/colorsTypes';
import { Artboard, Position } from '@/Types/types';

export const useProjectPages = createSharedComposable(() => {
	const project = useProjectStore();
	const store = useMainStore();
	const temporalRefPage = ref(Page.createDefault());
	const { addElement, adjustContent } = usePage(temporalRefPage as Ref<Page>);
	const { convertMmToPx, convertPxToMm, MM_TO_PX } = useArtboard();
	const MAX_PAGES = 85;
	const activePageisFirstPage = computed(() => project.pages.indexOf(store.activePage as Page) === 0);
	const activePageisLastPage = computed(
		() => project.pages.indexOf(store.activePage as Page) === project.pages.length - 1
	);
	const activePageIsEmpty = computed(() => store.activePage?.elements.size === 0);
	const totalPages = computed(() => {
		return project.pages.length;
	});

	const pages = computed(() => project.pages.filter(Boolean) as Page[]);

	const canAddPages = computed(() => totalPages.value < MAX_PAGES);

	const canMoveDown = (page: Page) => {
		const position = findIndex(project.pages, page);
		return project.pages.length > 1 && position < project.pages.length - 1;
	};

	const canMoveUp = (page: Page) => {
		const position = findIndex(project.pages, page);
		return project.pages.length > 1 && position > 0;
	};

	const findPosition = (page: Page) => {
		return findIndex(project.pages, page);
	};

	const addPage = (page: Page, after?: Page, newColor?: Color) => {
		if (after) {
			const indexOfReference = project.pages.indexOf(after);
			project.pages.splice(indexOfReference + 1, 0, page);
			if (newColor) page.updateBackgroundColor(newColor);
			return;
		}

		project.pages.push(page);
	};

	const addPages = (pages: Page[], after?: Page) => {
		project.$patch(() => {
			pages.forEach((page) => addPage(page, after));
		});
	};

	const addEmptyPage = async (after?: Page) => {
		const emptyPage = Page.create();

		after ? addPage(emptyPage, after, after?.background) : addPage(emptyPage, after);

		await nextTick();

		store.setActivePage(emptyPage, true);

		Bugsnag.leaveBreadcrumb(`Add new page : ${emptyPage.id}`);
	};

	const movePage = async (page: Page, position: number) => {
		const currentIndex = findIndex(project.pages, page);

		const prevValue = project.pages[position];

		project.$patch(() => {
			project.pages[position] = page;
			project.pages[currentIndex] = prevValue;

			// Ya que es  un cambio de posición no esta siendo detectado
			// forzamos guardado
			project.saveState?.();
		});

		Bugsnag.leaveBreadcrumb(`Move page ${page.id} from ${currentIndex} to ${position}`);
	};

	const duplicatePage = (page: Page) => {
		const { name, background, sourceId, backgroundImageId } = page;
		const newPage = Page.create({ name: `${name} copy`, background: background.copy(), sourceId, backgroundImageId });

		newPage.preview = page.preview;
		temporalRefPage.value = newPage;

		const newElements = ElementTools.fixRepeatedElementIds(project.allElements, page.elementsAsArray());

		if (page.backgroundImageId?.length) {
			const backgroundImageIndex = page.elementsAsArray().findIndex((el) => el.id === page.backgroundImageId);

			if (backgroundImageIndex >= 0) {
				newPage.backgroundImageId = newElements[backgroundImageIndex].id;
			}
		}

		newElements.forEach((el) => {
			addElement(el);
		});

		return newPage;
	};

	const copyPage = async (page: Page) => {
		const newPage = duplicatePage(page);

		addPage(newPage, page);

		await nextTick();

		store.setActivePage(newPage);

		Bugsnag.leaveBreadcrumb(`Add page copy : ${newPage.id}`);
	};

	const copyPages = async (pages: Page[], currentSelection: Page[]) => {
		const duplicatedPages = pages.map((selectedPage) => duplicatePage(selectedPage)).reverse();

		await nextTick();

		const activePageIsCurrentSelection = currentSelection.length === 1 && currentSelection[0].id === store.activePageId;
		const indexOfLastPage = project.pages.indexOf(pages[pages.length - 1]);
		const afterToPage = activePageIsCurrentSelection ? store.activePage : (project.pages[indexOfLastPage] as Page);

		if (afterToPage) addPages(duplicatedPages, afterToPage);

		Bugsnag.leaveBreadcrumb(`Add page copies : ${duplicatedPages.map((p) => `${p.id}; `)}`);
	};

	const removePage = (page: Page) => {
		if (project.pages.length === 1) return;
		const indexRemovingPage = findIndex(project.pages, page);
		project.pages = project.pages.filter((toRemove) => toRemove.id !== page.id);

		if (page.id === store.activePageId) {
			store.setActivePage(
				project.pages[indexRemovingPage !== project.pages.length ? indexRemovingPage : project.pages.length - 1] as Page
			);
		}
		Bugsnag.leaveBreadcrumb(`Remove page: ${page.id}`);
	};

	const removePages = (pages: Page[]) => {
		pages.forEach((selectedPage) => removePage(selectedPage));
	};

	const removeAllPages = () => (project.pages = []);

	const replacePage = (newPageData: Page, currentPage: Page) => {
		const index = findIndex(project.pages, currentPage);
		const newPage = duplicatePage(Page.create(newPageData as Page));
		project.pages.splice(index, 1, newPage);
		store.setActivePage(newPage);
	};

	const getPageFromElement = (element: Element) => {
		const page = project.pages.find((p) => p.elementsAsArray().some((el) => el.id === element.id));
		// if (!page) throw new Error('Page instance not found from Element');
		return page as Page;
	};

	const getPageFromDom = (canvas: HTMLElement) => {
		const id = canvas.id.substring(7);
		const page = project.pages.find((page) => page.id === id);
		if (!page) throw new Error('Page instance not found from HTML node');
		return page as Page;
	};

	const getElementFromDom = (element: HTMLElement): Element | undefined => {
		return project.pages.flatMap((t) => Object.values(t.elementsAsArray())).find((e) => element.id.includes(e.id));
	};

	const getPageElementUnderMouse = (screenPosition: Position): Page | null => {
		const found = document.elementFromPoint(screenPosition.x, screenPosition.y);
		if (!found) return null;

		const pageElementContainer = found.hasAttribute('data-elements-container')
			? found
			: found.closest('[data-elements-container]');
		if (!pageElementContainer) return null;

		const pageElement = pageElementContainer.closest('[id^="interactive-canvas-"');
		if (!pageElement) return null;

		const pageUnderMouse = project.pages.find((p) => pageElement.id.endsWith(p.id)) as Page;
		if (!pageUnderMouse) return null;

		return pageUnderMouse;
	};

	const rescalePage = (page: Page, selectedTemplateSize: Partial<Artboard>, dpi?: number) => {
		let sizeToAdjustTemplate = project.size;

		const clonedPage = cloneDeep(page);
		temporalRefPage.value = clonedPage;

		// Necesitamos obtener el size del proyecto en la unidad que use el template seleccionado
		// para poder ajustar posteriormente el contenido de la página nueva a añadir.
		if (project.unit === 'mm' && selectedTemplateSize.unit === 'px')
			sizeToAdjustTemplate = convertMmToPx(project.size.width, project.size.height);

		if (project.unit === 'px' && selectedTemplateSize.unit === 'mm')
			sizeToAdjustTemplate = convertPxToMm(project.size.width, project.size.height);

		const sizeTemplate = {
			width: selectedTemplateSize.width || 0,
			height: selectedTemplateSize.height || 0,
		};

		// Se ajusta el tamaño de los elementos de la página para ajustarse al proyecto
		const scaleElements = TransformTools.getFactorToFitInNewRatio(sizeToAdjustTemplate, sizeTemplate);
		temporalRefPage.value.elements.forEach((el) => el.scaleBy(scaleElements));

		// Si la plantilla esta en mm y 300DPI se le aplica el fix para ajustar el contenido adecuadamente
		if (selectedTemplateSize.unit === 'mm' && dpi !== MM_TO_PX) {
			sizeToAdjustTemplate =
				project.unit === 'mm' ? convertMmToPx(sizeTemplate.width, sizeTemplate.height) : sizeTemplate;

			adjustContent(sizeToAdjustTemplate, {
				width: Math.round(sizeTemplate.width * (dpi || 1)),
				height: Math.round(sizeTemplate.height * (dpi || 1)),
			});
		}

		return temporalRefPage.value;
	};

	return {
		pages,
		canAddPages,
		canMoveDown,
		canMoveUp,
		activePageisFirstPage,
		activePageisLastPage,
		activePageIsEmpty,
		findPosition,
		addPage,
		addEmptyPage,
		addPages,
		movePage,
		duplicatePage,
		totalPages,
		MAX_PAGES,
		copyPage,
		copyPages,
		removePage,
		removePages,
		removeAllPages,
		replacePage,
		rescalePage,
		getPageFromElement,
		getPageFromDom,
		getElementFromDom,
		getPageElementUnderMouse,
	};
});
