import { createSharedComposable } from '@vueuse/core';
import Moveable, { Able, MoveableManagerInterface, OnDrag, OnResize, Renderer } from 'moveable';
import { computed, ComputedRef, nextTick, ref } from 'vue';

import { useBugsnag } from '@/analytics/bugsnag/composables/useBugsnag';
import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { useToast } from '@/common/composables/useToast';
import { useZoom } from '@/editor/composables/useZoom';
import { useMainStore } from '@/editor/stores/store';
import Image from '@/elements/medias/images/image/classes/Image';
import { useImageTransform } from '@/elements/medias/images/image/composables/useImageTransform';
import { usePhotoMode } from '@/elements/medias/images/image/composables/usePhotoMode';
import { useArtboard } from '@/project/composables/useArtboard';
import { useProjectStore } from '@/project/stores/project';
import { Anchor, InteractionAction, Position, Size } from '@/Types/types';

const initialSize = ref<Size>({ width: 0, height: 0 });
const initialPosition = ref<Position>({ x: 0, y: 0 });
const initialCropSize = ref<Size>({ width: 0, height: 0 });
const initialCropPosition = ref<Position>({ x: 0, y: 0 });
const fromApplyCrop = ref(false);
const moveable = ref<Moveable | null>();
const action = ref<InteractionAction>(InteractionAction.Idle);
const { bugsnagMsgWithDebounce } = useBugsnag();

export const useCropPhotoMode = createSharedComposable(() => {
	const store = useMainStore();
	const project = useProjectStore();

	const { photoModeImage } = usePhotoMode();
	const { setArtboardSize } = useArtboard();
	const { fitZoomScale } = useZoom();
	const toast = useToast();

	const image = computed(() => photoModeImage.value || Image.create()) as ComputedRef<Image>;
	const { align, widthWithRotation, heightWithRotation } = useImageTransform(image);

	const isCropPhotoModeReady = computed(() => !!moveable.value);

	const createMoveable = () => {
		const canvas = document.querySelector('[id^="canvas-"]') as HTMLElement;
		const moveableContainer = document.getElementById('moveable-container');

		if (!canvas || !moveableContainer) {
			console.error('Canvas or moveable container not found!');
			return;
		}

		moveable.value = new Moveable(moveableContainer, {
			target: image.value.domNode(),
			renderDirections: ['nw', 'n', 'ne', 'w', 'e', 'sw', 's', 'se'],
			snappable: false,
			hideDefaultLines: false,
			originDraggable: false,
			draggable: true,
			dragArea: true,
			resizable: true,
			rotatable: false,
			roundable: false,
			pinchable: true,
			origin: true,
			keepRatio: false,
			edge: false,
			ables: [ElementsToolbar],
			props: {
				toolbar: true,
			},
			className: 'moveable-photo-image',
			container: canvas,
			useResizeObserver: true,
			useMutationObserver: true,
		});
	};

	const initCrop = async () => {
		project.pauseAutoSave?.();

		// Guardamos los datos iniciales para restaurarlos en caso de cancelar el crop
		initialSize.value = {
			width: image.value.size.width,
			height: image.value.size.height,
		};

		initialPosition.value = {
			x: image.value.position.x,
			y: image.value.position.y,
		};

		initialCropSize.value = {
			width: image.value.crop.size.width,
			height: image.value.crop.size.height,
		};

		initialCropPosition.value = {
			x: image.value.crop.position.x,
			y: image.value.crop.position.y,
		};

		// Si no tiene crop le seteamos el tamaño de crop al original
		if (!image.value.hasCrop()) {
			image.value.crop.size = {
				width: image.value.size.width,
				height: image.value.size.height,
			};
		}

		createMoveable();
		registerEvents();
		GAnalytics.track('click', 'Button', `crop`, null);
	};

	const stopCrop = () => {
		store.croppingId = null;
		moveable.value?.destroy();
		moveable.value = null;
		project.resumeAutoSave?.();
	};

	const cancelCrop = () => {
		// Ignoramos el autosave al cancelar el crop, ya que recuperamos su estado previo
		project.ignoreAutoSave?.(() => {
			image.value.size = {
				width: initialSize.value.width,
				height: initialSize.value.height,
			};

			image.value.position = {
				x: initialPosition.value.x,
				y: initialPosition.value.y,
			};

			image.value.crop.size = {
				width: initialCropSize.value.width,
				height: initialCropSize.value.height,
			};

			image.value.crop.position = {
				x: initialCropPosition.value.x,
				y: initialCropPosition.value.y,
			};
		});

		stopCrop();
		bugsnagMsgWithDebounce('Cancel crop');
	};

	const applyCrop = async () => {
		if (!image.value) throw new Error('No image to set crop!');
		fromApplyCrop.value = true;

		try {
			setArtboardSize(widthWithRotation.value, heightWithRotation.value, 'px');
		} catch (error) {
			cancelCrop();
			toast.error('Proposed size and unit are invalid.');
			return;
		}
		align(Anchor.topLeft);
		fitZoomScale();

		await nextTick();
		stopCrop();
		project.saveState?.();
		bugsnagMsgWithDebounce('Apply crop');
	};

	const registerEvents = () => {
		if (!isCropPhotoModeReady.value) return;
		moveable.value?.on('drag', dragHandler).on('resize', resizeHandler);
	};

	const dragHandler = (ev: OnDrag) => {
		action.value = InteractionAction.Drag;

		let angle = image.value.rotation;
		while (angle < 0) angle += 360;
		angle = angle >= 360 ? angle % 360 : angle;

		const pos = { x: ev.delta[0], y: ev.delta[1] };

		if (angle === 90) {
			pos.x = ev.delta[1];
			pos.y = -ev.delta[0];
		}

		if (angle === 180) {
			pos.x = -ev.delta[0];
			pos.y = -ev.delta[1];
		}

		if (angle === 270) {
			pos.x = -ev.delta[1];
			pos.y = ev.delta[0];
		}

		store.$patch(() => {
			image.value.position.x += ev.delta[0];
			image.value.position.y += ev.delta[1];

			image.value.crop.position.x -= pos.x;
			image.value.crop.position.y -= pos.y;
		});

		ev.target!.style.transform = `translate(${ev.beforeTranslate[0]}px, ${ev.beforeTranslate[1]}px) rotate(${image.value.rotation}deg)`;
	};

	const resizeHandler = (ev: OnResize) => {
		action.value = InteractionAction.Resize;

		const { width, height } = ev;
		if (width <= 0 || height <= 0) return;

		const { direction, drag, target, delta } = ev;
		const { translate } = drag;

		image.value.crop.position.x += direction[0] === -1 ? delta[0] : 0;
		image.value.crop.position.y += direction[1] === -1 ? delta[1] : 0;

		store.$patch(() => {
			image.value.size = { width, height };
			image.value.position = {
				x: translate[0],
				y: translate[1],
			};
		});

		// Es necesario para evitar flickeo al redimensionar
		target!.style.width = `${width}px`;
		target!.style.height = `${height}px`;
		target!.style.transform = `translate(${translate[0]}px, ${translate[1]}px) rotate(${image.value.rotation}deg)`;
	};

	return {
		initCrop,
		applyCrop,
		cancelCrop,
		isCropPhotoModeReady,
		fromApplyCrop,
	};
});

export const useCropPhotoModeMoveable = createSharedComposable(() => ({ cropPhotoModeMoveable: moveable }));

// Floating menu elements
const ElementsToolbar: Able = {
	name: 'toolbar',
	render(moveable: MoveableManagerInterface<any, any>, { createElement }: Renderer) {
		const rect = moveable.getRect();
		return createElement('div', {
			key: 'toolbar',
			className: 'toolbar',
			style: {
				right: `-${rect.width}px`,
				top: `-42px`,
				position: 'absolute',
			},
		});
	},
};
