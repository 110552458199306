import { useEnvSettings } from '@/common/composables/useEnvSettings';

import freepikLibraryLinks from './links/freepikLibraryLinks.json';
import freepikToolsLinks from './links/freepikToolsLinks.json';
import freepikUserLinks from './links/freepikUserLinks.json';
import landingCategoriesLinks from './links/landingCategoriesLinks.json';
import pricingLinks from './links/pricingLinks.json';
import profileLinks from './links/profileLinks.json';
import projectsLinks from './links/projectsLinks.json';
import supportLinks from './links/supportLinks.json';

const { APP_BASE } = useEnvSettings();

export const linkList = {
	brandkitLink: {
		es: '/es/kits-de-marca/',
		en: '/brand-kits/',
	},
	home: {
		freepik: {
			en: `${window.location.origin}`,
			es: `${window.location.origin}`,
		},
		wepik: {
			en: `${window.location.origin}`,
			es: `${window.location.origin}/es`,
		},
		slidesgo: {
			en: `${window.location.origin}`,
			es: `${window.location.origin}/es`,
		},
	},
	copy: {
		es: `${APP_BASE}es/copy/`,
		en: `${APP_BASE}copy/`,
	},

	...projectsLinks,
	...supportLinks,
	...profileLinks,
	...pricingLinks,
	...landingCategoriesLinks,
	...freepikLibraryLinks,
	...freepikToolsLinks,
	...freepikUserLinks,
};
