import { cloneDeep } from 'lodash-es';

import { PurifyUnserialize } from '@/elements/element/utils/PurifyUnserialize';
import { Filter } from '@/elements/medias/filter/classes/Filter';
import BaseImage from '@/elements/medias/images/base-image/classes/BaseImage';
import Image, { LegacyImageValues } from '@/elements/medias/images/image/classes/Image';
import ImageTools from '@/elements/medias/images/image/utils/ImageTools';
import { ForegroundImageDTO } from '@/Types/elements';
import { SerializedClass } from '@/Types/types';
import MathTools from '@/utils/classes/MathTools';

class ForegroundImage extends BaseImage {
	type: 'foregroundImage' = 'foregroundImage';
	image: string;

	protected constructor(foregroundImageDTO: ForegroundImageDTO) {
		super(foregroundImageDTO);

		this.image = foregroundImageDTO.image;
	}

	public static defaults(): ForegroundImageDTO {
		return {
			...BaseImage.defaults(),
			locked: true,
			image: '',
		};
	}

	public static async fromImage(image: Image) {
		const cloned = cloneDeep(image);

		if (cloned.index) {
			cloned.index += 1;
		}

		const foregroundImageDTO = {
			...ForegroundImage.defaults(),
			...cloned,
			image: cloned.id,
		};

		const foregroundImage = new ForegroundImage(foregroundImageDTO);

		foregroundImage.locked = true;
		foregroundImage.filter = null;

		const corners = await ImageTools.findTransparentCorners(image.urlBackgroundRemoved as string);
		// Calculamos cuanto tenemos que recortar por cada lado(valores sobre tamaño real de la imagen)
		const cropByLeft = corners.width - corners.left;
		const cropByRight = corners.width - corners.right;
		const cropByTop = corners.height - corners.top;
		const cropByBottom = corners.height - corners.bottom;
		const cropX = cropByLeft - cropByRight;
		const cropY = cropByTop - cropByBottom;

		// Calculamos el crop en el tamaño de nuestra imagen, nuestra referencia siempre va a ser el tamaño de la imagen completa
		// por lo que si tiene crop cogemos de ahí el dato
		const cropWidth = MathTools.ruleOfThree(
			corners.width,
			cropX,
			image.hasCrop() ? image.crop.size.width : image.size.width
		);
		const cropHeight = MathTools.ruleOfThree(
			corners.height,
			cropY,
			image.hasCrop() ? image.crop.size.height : image.size.height
		);

		// Guardamos el tamaño de la imagen sin crop
		foregroundImage.crop.size.width = image.hasCrop() ? image.crop.size.width : image.size.width;
		foregroundImage.crop.size.height = image.hasCrop() ? image.crop.size.height : image.size.height;
		// Aplicamos crop a la imagen
		foregroundImage.size.width = cropWidth;
		foregroundImage.size.height = cropHeight;

		// Si la imagen original tiene crop tenemos que sumarle a la posición cuanto está desplazada respecto a la original
		// para tener nuestro clone en el mismo x,y que la original
		if (image.hasCrop()) {
			foregroundImage.position.x += image.crop.position.x;
			foregroundImage.position.y += image.crop.position.y;
		}

		// colocamos el elemento visible del clone dentro de nuestro crop
		foregroundImage.crop.position.x = -MathTools.ruleOfThree(
			corners.width,
			image.flip.x ? corners.width - corners.right : corners.left,
			foregroundImage.crop.size.width
		);
		foregroundImage.crop.position.y = -MathTools.ruleOfThree(
			corners.height,
			image.flip.y ? corners.height - corners.bottom : corners.top,
			foregroundImage.crop.size.height
		);
		// movemos la imagen lo mismo que la hemos desplazado para que visualmente encaje con la imagen que estamos copiando
		foregroundImage.position.x += foregroundImage.crop.position.x * -1;
		foregroundImage.position.y += foregroundImage.crop.position.y * -1;

		return foregroundImage;
	}

	static create(config: Partial<ForegroundImageDTO> = {}): ForegroundImage {
		const foregroundImageDTO = {
			...ForegroundImage.defaults(),
			...config,
		};

		return new ForegroundImage(foregroundImageDTO);
	}

	@PurifyUnserialize()
	static unserialize(data: SerializedClass<ForegroundImage> & LegacyImageValues): ForegroundImage {
		const foregroundImageDTO = {
			...ForegroundImage.defaults(),
			...data,
		} as ForegroundImageDTO;

		if (!data.image) {
			throw new Error('Foreground need a image reference');
		}

		foregroundImageDTO.filter = data.filter ? Filter.unserialize(data.filter) : null;

		const elem = new ForegroundImage(foregroundImageDTO);

		if (data.id) {
			elem.id = data.id;
		}

		if (!(elem.subElements instanceof Map)) {
			elem.subElements = new Map();
		}

		return elem;
	}
}

export default ForegroundImage;
