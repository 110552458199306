import { computed, Ref, watch } from 'vue';

export const useEmptyDataOnUrlParamChange = (url: Ref<string>, concatenatedData: Ref<any[]>) => {
	const computedUrlParams = computed(() => {
		const params = new URLSearchParams(url.value.split('?')[1]);

		return {
			q: params.get('q') || null,
			query: params.get('query') || null,
			style: params.get('style') || null,
			term: params.get('term') || null,
			tagsId: params.get('tagsId') || null,
			color: params.get('color') || null,
		};
	});

	watch(computedUrlParams, (newVal, oldVal) => {
		// Reset data on modify search query param
		if (
			newVal.q !== oldVal.q ||
			newVal.query !== oldVal.query ||
			newVal.term !== oldVal.term ||
			newVal.style !== oldVal.style ||
			newVal.tagsId !== oldVal.tagsId ||
			newVal.color !== oldVal.color
		) {
			concatenatedData.value = [];
		}
	});
};
