export const skeletonUI = (el: HTMLElement, binding: any) => {
	const { value: dataLoaded } = binding;

	const style = el.style;
	if (dataLoaded) {
		// Show UI skeleton container
		style.backgroundColor = '#E5E5E5';
		style.display = 'flex';
		style.pointerEvents = 'none';
		style.border = 'none';
		style.color = 'transparent';
		style.borderRadius = '3px';
		el.classList.add('animate-pulse');

		// Can use data-element to make the customize skeleton UI
		if (el.getAttribute('data-element') === 'rounded') style.borderRadius = '100px';

		el.querySelectorAll('*').forEach((child) => {
			(child as HTMLElement).style.visibility = 'hidden';
		});
	} else {
		// Show initial container
		style.backgroundColor = '';
		style.display = '';
		style.pointerEvents = 'auto';
		style.color = '';
		style.border = '';
		style.borderRadius = '';
		el.classList.remove('animate-pulse');

		el.querySelectorAll('*').forEach((child) => {
			(child as HTMLElement).style.visibility = 'visible';
		});
	}
};
