export const clickEffect = (el: HTMLElement) => {
	const style = window.getComputedStyle(el, null).getPropertyValue('font-size');
	const fontSize = parseFloat(style);
	el.style.fontSize = `${fontSize}px`;
	el.onclick = function () {
		el.focus();
		el.style.transitionDuration = '200ms';
		el.style.fontSize = `${parseFloat(el.style.fontSize) + 2}px`;
		setTimeout(() => {
			el.style.fontSize = `${parseFloat(el.style.fontSize) - 2}px`;
		}, 100);
	};
};
