import { computed, Ref } from 'vue';

import Element from '@/elements/element/classes/Element';
import { useIsBackground } from '@/elements/element/composables/useIsBackground';
import { usePageElement } from '@/elements/element/composables/usePageElement';
import Image from '@/elements/medias/images/image/classes/Image';
import { useLayersImage } from '@/elements/medias/images/image/composables/useLayersImage';
import { useInteractions } from '@/interactions/composables/useInteractions';

export const useCollisionImageInfo = (element: Ref<Image>) => {
	const { isDragging } = useInteractions();
	const { page } = usePageElement(element);
	const { foreground } = useLayersImage(element);
	const { isBackground } = useIsBackground(element);
	const elementIndex = computed(() => element.value?.index);
	const firstPosition = computed(() => (page.value.backgroundImageId ? 1 : 0));
	const lastPosition = computed(() => page.value.elements.size - 1);

	const canGoDown = computed(() => !!collisioningSortedElementsUnder.value.length && !isBackground.value);
	const canGoUp = computed(() => !!collisioningSortedElementsOver.value.length && !isBackground.value);

	const collisioningElements = computed(() =>
		isDragging.value ? [] : page.value.elementsAsArray().filter(collisionFilterCb)
	);

	const collisioningSortedElementsOver = computed(() =>
		collisioningElements.value.filter((el: Element) => {
			return el.index > element.value.index;
		})
	);

	// Array ordenado por cercanía al elemento, no por orden de renderizado
	const collisioningSortedElementsUnder = computed(() =>
		collisioningElements.value.filter((el: Element) => el.index < element.value.index).reverse()
	);

	const collisionFilterCb = (el: Element) =>
		el.id !== foreground.value?.id &&
		el.id !== element.value.id &&
		el.id !== page.value.backgroundImageId &&
		el.isCollided(element.value);

	return {
		canGoDown,
		canGoUp,
		collisioningSortedElementsOver,
		collisioningSortedElementsUnder,
		elementIndex,
		firstPosition,
		lastPosition,
		page,
		collisionFilterCb,
	};
};
