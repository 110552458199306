import {Ref} from 'vue';

import {ColorLayer} from '@/apps/mockup/classes/Layers/ColorLayer';
import {GroupLayer} from '@/apps/mockup/classes/Layers/GroupLayer';
import {ImageLayer} from '@/apps/mockup/classes/Layers/ImageLayer';
import {LayerCanvas} from '@/apps/mockup/classes/Layers/Layer';
import {SmartObjectLayer} from '@/apps/mockup/classes/Layers/SmartObjectLayer';
import {useMockupStore} from '@/apps/mockup/mockupStore';
import {CompositingSize, MockupElement, MockupElementColor, Size} from '@/apps/mockup/schemas';
import {ColorData} from '@/apps/mockup/Types/basicTypes';
import {ColorDataHandler} from '@/apps/mockup/utils/CompositionTools';
import {SolidColor} from '@/color/classes/SolidColor';
import {CanvasUtils} from "@/apps/mockup/classes/CanvasUtils";
import {MockupResources} from "@/apps/mockup/classes/MockupResources";

export const LayersComposition = (
	actualCompositionSize: Ref<CompositingSize | undefined>,
	layer: LayerCanvas,
	contextMockup: CanvasRenderingContext2D,
	reportChange = false,
	SizeComposition: Ref<Size>,
	processDecision: CallableFunction
) => {
	const Mockup = useMockupStore();
	const Customizables = Mockup.mockup.elements;

	/**
	 * Color Layer Process
	 * @returns {void}
	 */
	const ColorLayerComposition = (): void => {
		//Crear color solid que viene desde PSD siempre viene en escala 0-1 Ej:(0.9, 0.8, 0.1)
		const colorToSolid = {...layer} as any;
		const solidColor = ColorDataHandler(colorToSolid.color as ColorData);

		const LayerColor = new ColorLayer(layer as LayerCanvas, solidColor as SolidColor);

		let layerCached = MockupResources.getCanvas(layer.id)?.domElement;

		const MockupElementColor =
			Customizables?.find((item: MockupElement) => item.relatedLayerId.includes(LayerColor?.id)); //Validate if color is in Custom Elements

		//Validates if this element already build in this resolution
		if (MockupElementColor && !MockupElementColor.appliedChanges[actualCompositionSize.value as CompositingSize]) {
			LayerColor.color = (MockupElementColor as MockupElementColor).color as SolidColor; //Assign new color to Layer
			MockupElementColor.appliedChanges[actualCompositionSize.value as CompositingSize] = true; //Already assign color in this resolution

			layerCached = LayerColor.buildColor(); //Build color if not exist in Custom Elements
			MockupResources.setCanvas(layer.id, {
				domElement: layerCached,
				type: 'layer',
			});
			validReportChange();
		}

		if (!layerCached) {
			layerCached = LayerColor.buildColor();
			MockupResources.setCanvas(layer.id, {
				domElement: layerCached,
				type: 'layer',
			});
			validReportChange();
		}

		LayerColor.drawImage(contextMockup, layerCached);
	};

	/**
	 * Image Layer process
	 * @returns {void}
	 */
	const ImageLayerComposition = (): void => {
		if (layer.name == '⚠️ HELP — Show me') return; //Validate case Help Badge to ignore

		const image: ImageLayer = layer as ImageLayer;
		const LayerImage = new ImageLayer(layer as LayerCanvas, image.image);

		let layerCached = MockupResources.getCanvas(layer.id)?.domElement;

		if (layerCached) MockupResources.deleteImage(LayerImage.image.id)

		if (!layerCached) {
			layerCached = LayerImage.buildImage();
			MockupResources.setCanvas(layer.id, {
				domElement: layerCached,
				type: 'layer',
			});
			validReportChange();
		}

		LayerImage.drawImage(contextMockup, layerCached);
		return;
	};

	/**
	 * Group Layer process
	 * @returns {void}
	 */
	const GroupLayerComposition = (): void => {
		const children: GroupLayer = layer as GroupLayer;
		const LayerGroup = new GroupLayer(layer as LayerCanvas, children.children, processDecision);

		const layerBuilt = LayerGroup.buildGroup();

		LayerGroup.drawImage(contextMockup, layerBuilt);
		CanvasUtils.freedomMethod(layerBuilt);
		validReportChange();
		return;
	};

	/**
	 * Smart Object Layer process
	 * @returns {void}
	 */
	const SmartObjectLayerComposition = (): void => {
		const smartObject: SmartObjectLayer = layer as SmartObjectLayer;
		const LayerSmartObject = new SmartObjectLayer(layer as LayerCanvas, smartObject.smartObject, smartObject.path, smartObject.smartObjectDocumentId);
		const mockupElementTexture = Mockup.getTextureElements.find(
			(item: MockupElement) => item.relatedLayerId.includes(LayerSmartObject.id)
		);

		if (!mockupElementTexture || !mockupElementTexture.renderImages[SizeComposition.value.width]) return; //Validating if is Mockup and don't have render
		let layerCached = MockupResources.getCanvas(layer.id)?.domElement;

		//Validates if this element already composed
		if (mockupElementTexture?.appliedChanges.changeId && mockupElementTexture.appliedChanges.changeId != layer.lastChangeIdComposed) {
			LayerSmartObject.imageData = mockupElementTexture.renderImages[SizeComposition.value.width];
			layerCached = LayerSmartObject.buildSmartObject();
			layer.lastChangeIdComposed = mockupElementTexture?.appliedChanges.changeId;

			MockupResources.setCanvas(layer.id, {
				domElement: layerCached,
				type: 'layer',
			});
			validReportChange();

			// Because MockupElementTexture can have multiple layers, we need to check if the last layer is the same as the current layer
			// This validation is necessary to free the memory of the canvas
			const lastRelatedLayerId = mockupElementTexture.relatedLayerId.at(-1);
			if (lastRelatedLayerId === layer.id)
				CanvasUtils.freedomMethod(LayerSmartObject.imageData as HTMLCanvasElement);
		}

		if (!layerCached) {
			console.warn(`Layer SO:${layer.name} not have cached`);
			return;
		}

		LayerSmartObject.drawImage(contextMockup, layerCached);
	};

	const validReportChange = () => {
		if (reportChange) layer.hadChanges = true;
	}

	return {
		ColorLayerComposition,
		ImageLayerComposition,
		GroupLayerComposition,
		SmartObjectLayerComposition,
	};
};
