import {createSharedComposable, until} from "@vueuse/core";
import {useMockupStore} from "@/apps/mockup/mockupStore";
import {DataStructureToJsonAdapter} from "@/apps/mockup/adapters/DataStructureToJsonAdapter";
import {computed, ref} from "vue";
import {DrawMockup} from "@/apps/mockup/facades/drawMockup";
import {MockupStoreSettings} from "@/apps/mockup/schemas";
import {useProjectStore} from "@/project/stores/project";
import {ImagesLoader, ImagesToLoad} from "@/apps/mockup/utils/ImagesLoader";

export const useBackupMockup = createSharedComposable(() => {
	const mockup = useMockupStore();

	const isBackupAvailable = computed(() => !!localStorage.getItem(`mockup-${mockup.sku}`));
	const avaliableBackup = ref<unknown>(null);


	const extractBackup = () => {
		const backup = localStorage.getItem(`mockup-${mockup.sku}`)
		if (!backup) return;

		avaliableBackup.value = JSON.parse(backup)
	}

	/**
	 * Recover the backup of the mockup
	 */
	const recoverBackup = async () => {
		extractBackup();
		new DataStructureToJsonAdapter().convertFromJSON(avaliableBackup.value, {
			registerChange: true,
			drawMockup: false,
		});
		fetchPageImages();

		await until(ImagesToLoad).toBe(0);
	};

	/**
	 * Delete the backup of the mockup
	 */
	const deleteBackup = () => {
		localStorage.removeItem(`mockup-${mockup.sku}`);
	}

	/**
	 * Save the backup of the mockup
	 */
	const saveBackup = () => {
		if (mockup.getSetting(MockupStoreSettings.ViewEvents)) console.log('Saving backup');
		const data = new DataStructureToJsonAdapter().convertToJSON();
		localStorage.setItem(`mockup-${mockup.sku}`, JSON.stringify(data));
	}

	const fetchPageImages = () => {
		const project = useProjectStore();
		project.pages.forEach((page) => {
			page.elements.forEach((element) => {
				if (element.type === 'image') {
					ImagesLoader(element.url);
				}
			});
		});
	}

	return {
		deleteBackup,
		saveBackup,
		recoverBackup,
		isBackupAvailable,
	};
});
