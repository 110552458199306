import { LayerCanvas } from '@/apps/mockup/classes/Layers/Layer';
import { ColorLayerScheme } from '@/apps/mockup/schemas';
import { buildLinearGradientWith, buildRadialGradientWith } from '@/apps/mockup/utils/CompositionTools';
import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';

export class ColorLayer extends LayerCanvas implements ColorLayerScheme {
	color: SolidColor;

	constructor(Layer: LayerCanvas, color: SolidColor) {
		super( Layer );
		this.color = color;
	}

	/**
	 * Create color with her settings
	 * @returns URL Blob of color
	 */
	buildColor(): HTMLCanvasElement {
		this.canvas.height = this.size.height;
		this.canvas.width = this.size.width;

		const ctxLocal: CanvasRenderingContext2D = this.canvas.getContext('2d') as CanvasRenderingContext2D;
		ctxLocal.globalAlpha = this.getAlpha();
		ctxLocal.fillStyle =
			this.color instanceof GradientColor ? this.buildGradientWith(this.color, ctxLocal) : this.rgbColor();

		ctxLocal.fillRect(0, 0, this.canvas.width, this.canvas.height);

		if (this.mask) {
			const canvasMask = this.createMask();
			ctxLocal.globalCompositeOperation = 'destination-in';
			ctxLocal.drawImage(canvasMask, 0, 0, this.size.width, this.size.height); // fff
		}

		return this.canvas;
	}

	rgbColor(): string {
		return `rgb(${this.color.r},${this.color.g},${this.color.b},${this.color.a} )`;
	}

	buildGradientWith(gradientColor: GradientColor, toCtx: CanvasRenderingContext2D): CanvasGradient {
		switch (gradientColor.type) {
			case 'linear':
				return buildLinearGradientWith(gradientColor, toCtx);
			case 'radial':
				return buildRadialGradientWith(gradientColor, toCtx);
		}
	}
}
