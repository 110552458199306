import { LayerCanvas } from '@/apps/mockup/classes/Layers/Layer';
import transformSmartObject from '@/apps/mockup/composition/transformSmartObject';
import { Resource, Size, SmartObjectLayerScheme } from '@/apps/mockup/schemas';

export class SmartObjectLayer extends LayerCanvas implements SmartObjectLayerScheme {
	smartObject: Resource;
	path: string;
	imageData?: HTMLCanvasElement;
	smartObjectDocumentId?: string;
	buildDistort: CallableFunction = () => {
		return;
	};

	constructor(Layer: LayerCanvas, smartObject: Resource, path: string, smartObjectDocumentId?: string) {
		super(Layer);
		this.smartObject = smartObject;
		this.path = path;
		this.smartObjectDocumentId = smartObjectDocumentId;

		this.setDistortInfo();
	}

	private setDistortInfo() {
		const points = this.extractPoints();

		if (!this.meta || !this.meta.extra || !points) return;

		this.buildDistort = transformSmartObject({
			width: this.size.width,
			height: this.size.height,
			points,
			alpha: this.meta.extra.fillOpacity / 255,
		});
	}

	buildSmartObject(): HTMLCanvasElement {
		if (!this.imageData) {
			console.warn(`Smart Object ${this.name} dont have ImageData`);
			throw new Error(`Smart Object ${this.name} dont have ImageData`);
		}
		this.canvas.height = this.size.height;
		this.canvas.width = this.size.width;
		const ctxLocal: CanvasRenderingContext2D = this.canvas.getContext('2d') as CanvasRenderingContext2D;
		ctxLocal.globalAlpha = this.getAlpha();

		// const imageResize = this.getSmartObjectSize();
		const imageBitmap = this.imageData;

		this.buildAndDrawSmartObject(imageBitmap);

		// If smart Object have a mask, draw it
		if (this.mask) {
			const canvasMask = this.createMask();
			ctxLocal.globalCompositeOperation = 'destination-in';
			ctxLocal.drawImage(canvasMask, 0, 0, this.size.width, this.size.height); // fff
		}
		return this.canvas;
	}

	/**
	 * This function set the size of the smart Object
	 * Only works if the smart Object have a meta property and this property have a smartObjectMore property
	 * @private
	 */
	private getSmartObjectSize(): Size {
		if (!this.meta) return this.size;

		const smartObjectMore = this.meta.extra.smartObjectMore;
		return smartObjectMore.size;
	}

	private buildAndDrawSmartObject(imageBitmap: HTMLCanvasElement) {
		if (!this.meta) {
			this.getContext().drawImage(imageBitmap, 0, 0, this.size.width, this.size.height);
			return;
		}

		const transformedCanvas = this.buildWithRotation(imageBitmap);
		this.getContext().drawImage(transformedCanvas, 0, 0, this.size.width, this.size.height);
	}

	private buildWithRotation(imageBitmap: HTMLCanvasElement): HTMLCanvasElement {
		const canvasTest = document.createElement('canvas');
		canvasTest.width = imageBitmap.width;
		canvasTest.height = imageBitmap.height;
		const ctx = canvasTest.getContext('2d') as CanvasRenderingContext2D;
		ctx.drawImage(imageBitmap, 0, 0, imageBitmap.width, imageBitmap.height);

		return this.buildDistort(canvasTest);
	}

	/**
	 * This function extract the points of the smart Object
	 * @private
	 */
	private extractPoints() {
		if (!this.meta || !this.meta.extra || !this.meta.extra.smartObjectMore) {
			return;
		}
		const transformPoints = [...this.meta.extra.smartObjectMore.nonAffineTransform] as number[];

		// Normalize points, first to percent and then to webgl space
		const normalizePoints = transformPoints.map((point, index) => {
			const percentPoint =
				index % 2 === 0 ? point / this.meta.documentSize.width : 1 - point / this.meta.documentSize.height;

			return percentPoint * 2 - 1; // Normalize to webgl space
		});

		return {
			topLeft: {
				x: parseFloat(normalizePoints[0].toFixed(5)),
				y: parseFloat(normalizePoints[1].toFixed(5)),
			},
			topRight: {
				x: parseFloat(normalizePoints[2].toFixed(5)),
				y: parseFloat(normalizePoints[3].toFixed(5)),
			},
			bottomRight: {
				x: parseFloat(normalizePoints[4].toFixed(5)),
				y: parseFloat(normalizePoints[5].toFixed(5)),
			},
			bottomLeft: {
				x: parseFloat(normalizePoints[6].toFixed(5)),
				y: parseFloat(normalizePoints[7].toFixed(5)),
			},
		};
	}
}
