import { UseFetchOptions, UseFetchReturn } from '@vueuse/core';
import { computed, Ref, ref, watch } from 'vue';

import { concatenatedUserCreationsResponseMapper } from '@/api/ClientApiMapper';
import { useEditorApiFetch } from '@/api/composables/useEditorApiFetch';
import { useEditorWebFetch } from '@/api/composables/useEditorWebFetch';
import { useEmptyDataOnUrlParamChange } from '@/api/composables/useEmptyDataOnUrlParamChange';
import { useEnvSettings } from '@/common/composables/useEnvSettings';
import {
	AIWriterAnswer,
	MappedApiResponse,
	SaveProjectResponse,
	ServicesProviders,
	SyncProjectResponse,
	TemplateApi,
	TemplateApiData,
	UserApi,
	UserFoldersApi,
} from '@/Types/apiClient';

export const saveProject = (body: BodyInit | any): UseFetchReturn<SaveProjectResponse> => {
	return useEditorApiFetch('user/vector', {
		headers: {
			Accept: 'application/json',
		},
	})
		.post({ ...body })
		.json();
};

export const syncProject = (
	body: BodyInit | any,
	timeout: number,
	fullSyncForced = false
): UseFetchReturn<SyncProjectResponse[]> => {
	return useEditorApiFetch(`user/vector/sync?fullSyncForced=${fullSyncForced ? 1 : 0}`, {
		headers: {
			Accept: 'application/json',
		},
		timeout,
	} as UseFetchOptions)
		.post({ ...body })
		.json();
};

export const saveInterests = (
	body: BodyInit | any,
	options: UseFetchOptions = {}
): UseFetchReturn<SyncProjectResponse[]> => {
	return useEditorApiFetch(`user/interests`, options)
		.post({ ...body })
		.json();
};

export const checkProvider = (provider: ServicesProviders, options: UseFetchOptions = {}): UseFetchReturn<any> => {
	return useEditorApiFetch(`provider/check?provider=${provider}`, { immediate: false, ...options })
		.get()
		.json();
};

export const getUserUploads = (data: Ref = ref(), options: UseFetchOptions = {}) => {
	const { APP_API_PATH } = useEnvSettings();
	const url = ref('user-uploads');

	// Si estamos haciendo paginación/infinite loading vamos a estar cambiando la url por la que nos da el data
	// esta contiene el path completo nosotros solo queremos la parte del endpoint por lo que si es la url completa
	// le quitamos la parte de la url que no nos interesa
	const endpoint = computed(() => url.value.toString().replace(APP_API_PATH, ''));

	const fetch = useEditorApiFetch(endpoint, {
		...options,
		afterFetch: (ctx) => {
			data.value = [...data.value, ...ctx.data.data];

			ctx.data = {
				...ctx.data,
				data: data.value,
			};

			return ctx;
		},
	})
		.get()
		.json();

	return { ...fetch, url };
};

export const getUserGooglePhotos = (page: Ref<string>, options: UseFetchOptions = {}, concatData: Ref<boolean>) => {
	const data = ref([]);
	const url = computed(() => {
		return `images/photos?page=${page.value}`;
	});

	return useEditorApiFetch(url, {
		...options,
		afterFetch: (ctx) => {
			data.value = concatData.value ? ([...data.value, ...ctx.data.images] as any) : ([...ctx.data.images] as any);

			ctx.data = {
				data: data.value,
				links: {
					next: ctx.data.nextPageId,
				},
			};

			return ctx;
		},
	})
		.get()
		.json();
};

// TODO

export const getCsrfCookie = async (options: UseFetchOptions = {}): Promise<UseFetchReturn<void>> => {
	return useEditorWebFetch('sanctum/csrf-cookie', options);
};

export const getUserData = (options: UseFetchOptions = {}): UseFetchReturn<UserApi> => {
	return useEditorApiFetch(`auth/verify`, {
		beforeFetch: async (ctx) => {
			ctx.options.headers = {
				...ctx.options.headers,
				Accept: 'application/json',
			};
		},
		...options,
	}).json();
};

export const getUserAiWriters = (options: UseFetchOptions = {}): UseFetchReturn<AIWriterAnswer> => {
	return useEditorApiFetch(`writer-ai/history`, options).get().json();
};

export const removeAIWriterAnswer = (id: string, options: UseFetchOptions = {}): UseFetchReturn<string> => {
	return useEditorApiFetch(`writer-ai/${id}`, options).delete().json();
};

export const logout = (options: UseFetchOptions = {}): UseFetchReturn<void> => {
	return useEditorWebFetch('auth/logout', {
		headers: {
			Accept: 'application/json',
		},
		...options,
	}).post() as UseFetchReturn<void>;
};

export const mockupsLogout = (options: UseFetchOptions = {}): UseFetchReturn<void> => {
	return useEditorWebFetch('api/mockup/auth/logout', {
		headers: {
			Accept: 'application/json',
		},
		...options,
	}).post() as UseFetchReturn<void>;
};

export const getMyCreations = (
	url: Ref<string>,
	options: UseFetchOptions = {},
	infiniteLoading = true
): UseFetchReturn<TemplateApi> => {
	const concatenatedData = ref<any[]>([]);

	const endpoint = computed(() => url.value.split('?')[0]);

	watch(endpoint, (newVal, oldVal) => {
		if (newVal !== oldVal) {
			concatenatedData.value = [];
		}
	});

	useEmptyDataOnUrlParamChange(url, concatenatedData);

	return useEditorApiFetch(url, {
		...options,
		afterFetch: (ctx) => (infiniteLoading ? concatenatedUserCreationsResponseMapper(ctx, concatenatedData) : ctx),
	}).json();
};

export const getUserFolders = (options: UseFetchOptions = {}): UseFetchReturn<UserFoldersApi[]> => {
	return useEditorApiFetch('user/folders', { ...options })
		.get()
		.json();
};

export const getBrandKits = (options: UseFetchOptions = {}): UseFetchReturn<any> => {
	return useEditorApiFetch('brand-kits', { ...options })
		.get()
		.json();
};

export const getBrandKitCollections = (
	url: Ref<string>,
	options: UseFetchOptions = {}
): UseFetchReturn<MappedApiResponse<TemplateApiData[]>> => {
	const concatenatedData = ref<TemplateApiData[]>([]);

	const endpoint = computed(() => url.value.split('?')[0]);

	watch(endpoint, (newVal, oldVal) => {
		if (newVal !== oldVal) {
			concatenatedData.value = [];
		}
	});

	useEmptyDataOnUrlParamChange(url, concatenatedData);

	return useEditorApiFetch(url, {
		...options,
		afterFetch: (ctx) => concatenatedUserCreationsResponseMapper(ctx, concatenatedData),
	}).json();
};

export const createVectorFolder = (body: BodyInit | any, options: UseFetchOptions = {}) => {
	return useEditorApiFetch('user/folder', options)
		.post({ ...body })
		.json();
};

export const duplicateVectorFolder = (slug: string, options: UseFetchOptions = {}) => {
	return useEditorApiFetch(`user/creation/${slug}/duplicate`, options).post();
};

export const addUserVectorToUserFolder = (slug: string, body: BodyInit | any, options: UseFetchOptions = {}) => {
	return useEditorApiFetch(`user/creation/${slug}/folder`, options)
		.post({ ...body })
		.json();
};

export const getUrlToBigUpload = (options: UseFetchOptions = {}) => {
	return useEditorApiFetch('user-uploads/big', options).json();
};

export const uploadBigFile = async (url: string, file: File) => {
	await fetch(url, {
		method: 'PUT',
		body: file,
	});
};
