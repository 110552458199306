import {computed} from "vue";

import { useMockupMode } from '@/apps/mockup/composable/useMockupMode';
import { useFeatureFlag, useQueryParamsMethod } from '@/apps/mockup/strategies/FeatureFlag';

export const useFeatureFlagEnabled = () => {
	const { isMigrationMode } = useMockupMode();

	const isPublishInMigration = computed(
		() => useFeatureFlag(useQueryParamsMethod()).isActive('published') && isMigrationMode.value
	);

	return {
		isPublishInMigration,
	};
};
