import { ref } from 'vue';

const invertView = ref(false);
const elementChange = ref<HTMLCanvasElement | null>();

export const useChangePreview = () => {
	const handleInvertView = async (canvasPrev: HTMLCanvasElement) => {
		invertView.value = !invertView.value;
		if (invertView.value) {
			elementChange.value = canvasPrev;
			const canvas: HTMLCanvasElement | null = document.querySelector('#canvasPrev');
			const ctx = canvas?.getContext('2d');
			canvas!.width = canvasPrev.width;
			canvas!.height = canvasPrev.height;

			if (ctx) {
				ctx.drawImage(canvasPrev, 0, 0);
			}
		} else {
			elementChange.value = null;
		}
	};
	return {
		invertView,
		elementChange,
		handleInvertView,
	};
};
