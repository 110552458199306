import domtoimage from 'dom-to-image-more';
import html2canvas from 'html2canvas';

import { Size } from '@/apps/mockup/schemas';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import Element from '@/elements/element/classes/Element';
import Page from '@/page/classes/Page';
import { CanvasPreviewName } from '@/Types/types';

type DrawImageParams = {
	sx: number;
	sy: number;
	sw: number;
	sh: number;
	dx: number;
	dy: number;
	dw: number;
	dh: number;
};
/**
 * Singleton class for handling SAM model operations.
 */
class RenderPage {
	private static instance: RenderPage;
	private _page?: Page;
	private _size?: Size;
	private _scale = 1;

	/**
	 * Gets the singleton instance of the SAMModel class.
	 * @returns The singleton instance of SAMModel.
	 */
	public static getInstance(): RenderPage {
		if (!RenderPage.instance) {
			RenderPage.instance = new RenderPage();
		}
		return RenderPage.instance;
	}

	/**
	 * Sets the page to be rendered.
	 * @param page The page to be rendered.
	 */
	public setPage(page: Page) {
		this._page = page;
		return this;
	}

	public setScale(scale: number) {
		this._scale = scale;
		return this;
	}

	public setSize = (width: number, height: number) => {
		this._size = { width, height };
		return this;
	};

	public async useDomToImage() {
		const { isSafari } = useDeviceInfo();
		const node = document.querySelector(
			`#canvas-${this._page?.id}-preview-${CanvasPreviewName.MockupOffScreen}`
		) as HTMLElement;

		this.toogleStyleRule(true);
		await domtoimage.toCanvas(node, {
			width: this._size?.width,
			height: this._size?.height,
		});

		const dataCanvas = !isSafari
			? await domtoimage.toCanvas(node, {
					width: this._size.width * this._scale,
					height: this._size.height * this._scale,
					style: {
						transform: `scale(${this._scale})`,
						transformOrigin: 'top left',
					},
			  })
			: await html2canvas(node, {
					scale: this._scale,
					backgroundColor: null,
					useCORS: true,
			  });
		this.toogleStyleRule(false);
		return dataCanvas;
	}

	private toogleStyleRule(action: boolean) {
		const css = window.document.styleSheets[0];
		if (action) {
			css.insertRule(`.domtoimage-exception div * { border: none; }`);
		} else {
			css.deleteRule(0);
		}
	}

	public FuckDomToImage(): HTMLCanvasElement {
		const init = performance.now()
		const canvas = document.createElement('canvas');
		if (!this._size || !this._page) {
			console.warn(`Need to set Size or Page Before,
			PageValue: ${this._page}
			SizeValue: ${this._size}`);
			return canvas;
		}
		canvas.width = this._size.width * this._scale;
		canvas.height = this._size.height * this._scale;
		const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

		this.buildFillColor(ctx);
		this.drawImages(this._page.elements, ctx);

		console.log('Time fuckDomToImage => ', performance.now() - init);
		return canvas;
	}

	private drawImages = (elements: Map<string, Element>, ctx: CanvasRenderingContext2D) => {
		for (const element of elements.values()) {
			console.log(element);
			const { position, size, rotation } = element;
			const { x, y } = position;
			const { width, height } = size;
			const source = `img[src='${element.url}']`;
			const imageElement = document.querySelector(source) as HTMLImageElement;
			if (!imageElement) continue; // Skip element

			console.log(imageElement.width);
			this.writeImage(
				ctx,
				imageElement,
				{
					sx: 0,
					sy: 0,
					sw: width,
					sh: height,
					dx: x * this._scale,
					dy: y * this._scale,
					dw: width * this._scale,
					dh: height * this._scale,
				},
				rotation
			);
		}
	};

	private writeImage = (
		ctx: CanvasRenderingContext2D,
		img: HTMLImageElement,
		frame: DrawImageParams,
		rotation: number
	) => {
		const { sx, sy, sh, sw, dy, dx, dw, dh } = frame;
		if (rotation != 0) {
			ctx.save();
			const radiansAngle = (rotation * Math.PI) / 180;
			const centerX = dx + dw / 2;
			const centerY = dy + dh / 2;

			ctx.translate(centerX, centerY);
			ctx.rotate(radiansAngle);
			ctx.drawImage(img, -sx / 2, -sy / 2, sw, sh, -dw / 2, -dh / 2, dw, dh);
			ctx.restore();
			return;
		}
		console.log(frame);
		ctx.drawImage(img, sx, sy, sw, sh, dx, dy, dw, dh);
		return;
	};

	private buildFillColor = (ctx: CanvasRenderingContext2D) => {
		//Solid Color case
		if (this._page?.background.isSolid()) {
			//Solid Color case
			const colorObject = this._page?.background.toObject() as { r: number; g: number; b: number; a: number };

			if (colorObject.a == 0) return;

			console.log(ctx);

			ctx.fillStyle = `rgba(${colorObject.r},${colorObject.g},${colorObject.b},${colorObject.a})`;
			ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
		} else {
			//Gradient Color Case
			console.warn('Gradient color Fill dont support yet');
		}
	};
}

export const RenderPageInstance = RenderPage.getInstance();
