// Bugsnag
import Bugsnag from '@bugsnag/js';
import { computed, Ref, ref } from 'vue';

import Element from '@/elements/element/classes/Element';
import { TransformRepository } from '@/elements/element/TransformRepository';
import { useMoveable } from '@/interactions/composables/useMoveable';
import { useArtboard } from '@/project/composables/useArtboard';
import { Anchor, InteractionAction } from '@/Types/types';

export const useGroupTransform = (group: Ref<Element[]>) => {
	const { artboardSizeInPx: sizeInPx } = useArtboard();
	const { action } = useMoveable();
	const left = computed(() => {
		const firstElement = group.value.find(
			(el) => el.position.x === Math.min(...group.value.map((el) => el.position.x))
		);
		if (!firstElement) return 0;
		const firstElementRef = ref(firstElement);
		const repository = new TransformRepository(firstElementRef);
		return repository.calculateOffsetBetweenBoxes().x;
	});
	const top = computed(() => {
		const firstElement = group.value.find(
			(el) => el.position.y === Math.min(...group.value.map((el) => el.position.y))
		);
		if (!firstElement) return 0;
		const firstElementRef = ref(firstElement);
		const repository = new TransformRepository(firstElementRef);
		return repository.calculateOffsetBetweenBoxes().y;
	});
	const centerX = computed(() => sizeInPx.value.width / 2 - sizeWithRotation.value.width / 2 + left.value);
	const centerY = computed(() => sizeInPx.value.height / 2 - sizeWithRotation.value.height / 2 + top.value);
	const right = computed(() => sizeInPx.value.width - sizeWithRotation.value.width + left.value);
	const bottom = computed(() => sizeInPx.value.height - sizeWithRotation.value.height + top.value);

	const move = async (x: number, y: number) => {
		const positionGroup = position.value;

		const promises = group.value.map((el) => {
			return new Promise((resolve) => {
				el.position.x = el.position.x - positionGroup.x + x;
				el.position.y = el.position.y - positionGroup.y + y;
				resolve(true);
			});
		});

		await Promise.all(promises);
	};

	const align = async (anchor: Anchor): Promise<void> => {
		// POSITION X
		if ([Anchor.left, Anchor.topLeft, Anchor.leftCenter, Anchor.bottomLeft].includes(anchor)) {
			await move(left.value, position.value.y);
		}

		if ([Anchor.centerX, Anchor.topCenter, Anchor.center, Anchor.bottomCenter].includes(anchor)) {
			await move(centerX.value, position.value.y);
		}

		if ([Anchor.right, Anchor.topRight, Anchor.rightCenter, Anchor.bottomRight].includes(anchor)) {
			await move(right.value, position.value.y);
		}

		// POSITION Y
		if ([Anchor.bottom, Anchor.bottomLeft, Anchor.bottomCenter, Anchor.bottomRight].includes(anchor)) {
			await move(position.value.x, bottom.value);
		}

		if ([Anchor.top, Anchor.topLeft, Anchor.topCenter, Anchor.topRight].includes(anchor)) {
			await move(position.value.x, top.value);
		}

		if ([Anchor.centerY, Anchor.leftCenter, Anchor.center, Anchor.topCenter].includes(anchor)) {
			await move(position.value.x, centerY.value);
		}

		Bugsnag.leaveBreadcrumb(`Align group ${group.value[0].group} to ${anchor}`);
	};
	const size = computed(() => {
		return {
			width:
				Math.max(...group.value.map((e) => e.size.width + e.position.x)) -
				Math.min(...group.value.map((e) => e.position.x)),
			height:
				Math.max(...group.value.map((e) => e.size.height + e.position.y)) -
				Math.min(...group.value.map((e) => e.position.y)),
		};
	});

	const sizeWithRotation = computed(() => {
		return {
			width:
				Math.max(
					...group.value.map((e) => {
						const repository = new TransformRepository(ref(e));
						return repository.widthWithRotation + repository.boxWithRotation.left;
					})
				) -
				Math.min(
					...group.value.map((e) => {
						const repository = new TransformRepository(ref(e));
						return repository.boxWithRotation.left;
					})
				),
			height:
				Math.max(
					...group.value.map((e) => {
						const repository = new TransformRepository(ref(e));
						return repository.heightWithRotation + repository.boxWithRotation.top;
					})
				) -
				Math.min(
					...group.value.map((e) => {
						const repository = new TransformRepository(ref(e));
						return repository.boxWithRotation.top;
					})
				),
		};
	});

	const position = computed(() => {
		return {
			x: Math.min(...group.value.map((e) => e.position.x)),
			y: Math.min(...group.value.map((e) => e.position.y)),
		};
	});

	const isAlignLeft = computed(() => Math.abs(position.value.x - left.value) <= 1);
	const isAlignCenter = computed(() => Math.abs(position.value.x - centerX.value) < 1);
	const isAlignRight = computed(() => Math.abs(position.value.x - right.value) < 1);

	const isAlignTop = computed(() => Math.abs(position.value.y - top.value) < 1);
	const isAlignMiddle = computed(() => Math.abs(position.value.y - centerY.value) < 1);
	const isAlignBottom = computed(() => Math.abs(position.value.y - bottom.value) < 1);

	const isOutsidePage = computed(() => {
		const isOutsideLeft = position.value.x + sizeWithRotation.value.width < left.value;
		const isOutsideRight = position.value.x - sizeWithRotation.value.width > right.value;
		const isOutsideX = isOutsideLeft || isOutsideRight;

		const isOutsideTop = position.value.y + sizeWithRotation.value.height < top.value;
		const isOutsideBottom = position.value.y - sizeWithRotation.value.height > bottom.value;
		const isOutsideY = isOutsideTop || isOutsideBottom;

		return isOutsideX || isOutsideY;
	});

	const groupIsPartiallyOutside = computed(() => {
		const partiallyOutsideBottom = position.value.y > bottom.value;
		const partiallyOutsideRight = position.value.x > right.value;
		return partiallyOutsideBottom || partiallyOutsideRight;
	});

	const resize = async (width: number) => {
		// store.watchTextHeight = false;
		action.value = InteractionAction.Resize;

		const currentSizeWidth = sizeWithRotation.value.width;
		const currentSizeHeight = sizeWithRotation.value.height;
		const changeRatio = width / currentSizeWidth;
		const repository = new TransformRepository(ref(group.value[0]));
		const currentPositionX = position.value.x;
		const currentPositionY = position.value.y;

		const positions = new Map();
		group.value.forEach((el) => {
			repository.setElement(el);
			// Guardamos las posiciones de los elementos en porcentaje respecto a la caja del grupo, para después poder colocarlas donde estaban
			positions.set(el.id, {
				x: (repository.boxWithRotation.left - currentPositionX) / currentSizeWidth,
				y: (repository.boxWithRotation.top - currentPositionY) / currentSizeHeight,
			});

			el.scaleBy(changeRatio);
		});

		group.value.forEach((el) => {
			repository.setElement(el);
			const offsets = repository.calculateOffsetBetweenBoxes();

			el.position.x = currentPositionX + currentSizeWidth * changeRatio * positions.get(el.id).x + offsets.x;
			el.position.y = currentPositionY + currentSizeHeight * changeRatio * positions.get(el.id).y + offsets.y;
		});

		action.value = InteractionAction.Idle;
	};

	return {
		position,
		size,
		sizeWithRotation,
		left,
		isAlignLeft,
		isAlignCenter,
		isAlignRight,
		isAlignTop,
		isAlignMiddle,
		groupIsPartiallyOutside,
		isAlignBottom,
		isOutsidePage,
		align,
		move,
		resize,
	};
};
