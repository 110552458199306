import { useEnvSettings } from '@/common/composables/useEnvSettings';
import TextTools from '@/elements/texts/text/utils/TextTools';

const { APP_API_VERSION } = useEnvSettings();
class ApiClient {
	/**
	 * Para hacer peticiones a la API.
	 * @param {string} url - URL de la petición.
	 * @param {RequestInit} init - Opciones de fetch.
	 * @param {boolean} [rawResponse=false] - Para retonar la respuesta sin procesar.
	 * @returns Promesa con la respuesta de la petición.
	 */
	static async request(url: string, init: RequestInit = {}, rawResponse = false): Promise<any> {
		if (!url) {
			return Promise.reject(new Error('request requires at least url parameter.'));
		}

		const customHeaders = init.headers ? new Headers(init.headers) : new Headers();

		if (!init.method) {
			init.method = 'GET';
		}

		if (init.body) {
			// Si es GET usamos el body para los params
			if (init.method === 'GET') {
				const encoded = this.joinParamsAsString(init.body);
				url += `?${encoded}`;

				customHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
			} else {
				init.body = JSON.stringify(init.body);

				customHeaders.append('Content-Type', 'application/json');
			}
		} else {
			customHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
		}

		// Para comprobar si vamos a consultar la API de wepik o una externa
		const isApiRequest = () => url.indexOf('/api/') >= 0 && url.indexOf('stories.freepiklabs.com') === -1;

		if (isApiRequest()) {
			customHeaders.append('X-Requested-With', APP_API_VERSION);

			if (TextTools.isValidUrl(url)) {
				const newUrl = new URL(url);
				newUrl.searchParams.set('lang', document.documentElement.lang || 'en');
				url = newUrl.toString();
			}

			const xsrf = decodeURIComponent(`; ${document.cookie}`.split(`; XSRF-TOKEN=`).pop()?.split(';')[0] || '');
			// Session Token
			if (xsrf) {
				customHeaders.append('X-XSRF-TOKEN', xsrf);
			}
		}

		init.headers = customHeaders;

		return new Promise((resolve, reject) => {
			fetch(url, init)
				.then((response) => {
					const contentType = response.headers.get('content-type');

					if (rawResponse) {
						return response;
					}

					if (response.status >= 400) {
						reject(response);
						return;
					}

					if (contentType === 'application/json') {
						return response.json();
					}

					return response.text();
				})
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});
	}

	/**
	 * * Concatena los parametros para generar la url de la petición
	 * @param {string | {[id: string]: string[]} | any} params - Parámetros a concatenar
	 * @returns string con los parámetros concatenados.
	 */
	private static joinParamsAsString(params: string | { [id: string]: string[] } | any): string {
		if (typeof params !== 'object') {
			return params;
		}

		let body = '';
		let i = 0;

		// Vamos concatenando los distintos params
		Object.keys(params).forEach((key: string) => {
			const value = params[key] ? params[key] : '';
			body += `${i > 0 ? '&' : ''}${key}=${value}`;

			i++;
		});

		return body;
	}
}

export default ApiClient;
