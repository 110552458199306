export default () => {
	if ('performance' in window && 'memory' in window.performance) {
		const memory = window.performance.memory;
		const mb = memory.usedJSHeapSize / 1048576;
		console.log('Memory used: ', mb, 'MB');

		if (mb > 500) {
			console.error('Memory limit exceeded');
		}
		return mb;
	}
};
