import {createSharedComposable, useBrowserLocation} from '@vueuse/core';
import { computed, reactive, UnwrapNestedRefs } from 'vue';
import { useRoute } from 'vue-router';

import { useErrorMockup } from '@/apps/mockup/composable/useErrorMockup';
import { MockupErrorType } from '@/apps/mockup/Types/errorTypes';

export enum WorkspaceMode {
	shared = 'SharedMockup',
	draft = 'DraftMockup',
	edition = 'EditableMockup',
	migration = 'MigrationMockup',
	invalid = 'InvalidWorkspaceMode',
	download = 'DownloadMockup',
}

export interface contextWorkspaceMode {
	mode: WorkspaceMode;
}

export const useContextWorkspaceMode: UnwrapNestedRefs<contextWorkspaceMode> = reactive({
	mode: WorkspaceMode.edition,
});

export const validateContextWorkspaceMode = () => {
	const { registerMockupError } = useErrorMockup();

	const url = new URLSearchParams(window.location.search);

	const location = useBrowserLocation().value.pathname;
	const keys = new Set();

	if (location?.includes('migration')) {
		useContextWorkspaceMode.mode = WorkspaceMode.migration;
		return;
	}

	if (url.has('sku')) {
		useContextWorkspaceMode.mode = WorkspaceMode.edition;
		keys.add('sku');
	}
	if (url.has('draft')) {
		useContextWorkspaceMode.mode = WorkspaceMode.draft;
		keys.add('draft');
	}
	if (url.has('shared')) {
		useContextWorkspaceMode.mode = WorkspaceMode.shared;
		keys.add('shared');
	}
	if (url.size == 0) {
		registerMockupError(MockupErrorType.notWorkspaceMode);
	}

	if (keys.size > 1) {
		registerMockupError(MockupErrorType.notWorkspaceValidURL);
		useContextWorkspaceMode.mode = WorkspaceMode.invalid;
	}
};

export const isShared = computed(() => {
	return useContextWorkspaceMode.mode == WorkspaceMode.shared;
});
export const isMigration = computed(() => {
	return useContextWorkspaceMode.mode == WorkspaceMode.migration;
});

export const useMockupMode = createSharedComposable(() => {
	const route = useRoute();

	const isMigrationMode = computed(() => route.name === 'migration');
	const isDraftMode = computed(() => route.name === 'draft');
	const isEditionMode = computed(() => route.name === 'mockups');
	const isSearchMode = computed(() => route.name === 'search');
	const isDevMode = computed(() => location.hostname === 'localhost');
	const isDownloadMode = computed(() => route.query.download == 'true' && isEditionMode.value);

	const isMigrationStatsMode = computed(() => route.name === 'migration-stats');

	return {
		isMigrationMode,
		isEditionMode,
		isDraftMode,
		isMigrationStatsMode,
		isSearchMode,
		isDevMode,
		isDownloadMode,
	};
});
