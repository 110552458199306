import { computed, Ref } from 'vue';

import Element from '@/elements/element/classes/Element';
import { Text } from '@/elements/texts/text/classes/Text';
import Page from '@/page/classes/Page';
import { useProjectStore } from '@/project/stores/project';

export const usePageElement = (element: Ref<Element>) => {
	const project = useProjectStore();

	const page = computed(() => {
		// Si es un texto dentro de un elemento Box, tenemos que buscar la página a partir del elemento Box
		const isTextInsideBox = element.value instanceof Text && !!element.value.parentBox();
		return project.pages.find((page) =>
			page.elements.has(isTextInsideBox ? element.value.parentId : element.value.id)
		) as Page;
	});

	return { page };
};
