import Bugsnag from '@bugsnag/browser';
import { createSharedComposable, until, useEventListener, useIntervalFn, useUrlSearchParams } from '@vueuse/core';
import { AnyFn } from '@vueuse/core';
import { useCookies } from '@vueuse/integrations/useCookies';
import { computed, ref } from 'vue';
import { useToast } from 'vue-toastification';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { getCsrfCookie, getUserData, logout as apiLogout, mockupsLogout } from '@/api/UserApiClient';
import { useRouterMockup } from '@/apps/mockup/composable/useRouterMockup';
import Settings from '@/apps/mockup/utils/Settings';
import { useManageLinks } from '@/common/composables/link/useManageLinks';
import { useEnvSettings } from '@/common/composables/useEnvSettings';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { useI18n } from '@/i18n/useI18n';
import EventTools from '@/interactions/classes/EventTools';
import { Lang, User } from '@/Types/types';

const notClosable = ref(false);
const userFetched = ref(false);

export const useAuth = createSharedComposable(() => {
	const authRequired = ref(false);
	const { remove } = useCookies();
	const { locale } = useI18n();
	const { links } = useManageLinks();
	const store = useMainStore();
	const { isPhotoMode, mode } = useEditorMode();
	const force = ref(false);
	const { data, error, statusCode, onFetchResponse, onFetchFinally, execute } = getUserData({
		immediate: false,
		beforeFetch(ctx) {
			if (force.value) ctx.url += `&force=1`;
			return ctx;
		},
	});

	onFetchFinally(() => (userFetched.value = true));

	const onFetchLogin = (callback: AnyFn) => {
		if (userFetched.value) return callback();
		onFetchFinally(callback);
	};

	const isLogged = computed<boolean>(() => !!store.user);
	const user = computed<User | null>(() => store.user);
	const toast = useToast();
	const limits = ref<{
		aiImages: {
			maxDailyAttempts: number;
			remaining: number;
			credits: number | null;
			isAbleToSeeNsfwImages: boolean;
		};
		pikaso: {
			enabled: boolean;
			waitList: boolean;
			limit: number;
			remaining: number;
		};
		avatar: {
			maxDailyAttempts: number;
			remaining: number;
		};
	} | null>();

	const refreshCsrfToken = async () => {
		await getCsrfCookie();
	};

	const refreshCsrfTokenInterval = useIntervalFn(refreshCsrfToken, 1000 * 60 * 30);

	refreshCsrfTokenInterval.pause();

	const checkLoginStatus = async (forceRecheck = false, attemptNumber = 0) => {
		await refreshCsrfToken();

		force.value = forceRecheck;

		try {
			await execute();

			if (statusCode.value && statusCode.value > 401) {
				await EventTools.sleep(attemptNumber * 1000);
				checkLoginStatus(forceRecheck, attemptNumber + 1);
				return;
			}

			if (!data.value || error.value) throw new Error('User data is empty');

			GAnalytics.trackUser(
				data.value.userData.fpId,
				data.value.userData.id,
				'logged',
				data.value.userData.isAdmin ? 'internal-staff' : 'registered'
			);

			GAnalytics.setUserDimension(data.value.userData.isAdmin ? 'internal-staff' : 'registered');

			limits.value = data.value.limits;

			store.user = data.value?.userData;

			refreshCsrfTokenInterval.resume();
		} catch (error) {
			if (store.user && !isPhotoMode.value) {
				toast.warning("You've been logged out. Please log in again to save your changes.");
			}

			store.user = null;
			delete window.localStorage.logged;

			GAnalytics.trackUser('', '', 'not-logged', 'anonymous');

			GAnalytics.setUserDimension('anonymous');
		}
	};

	const logout = async () => {
		if (mode.value === 'mockup') {
			await mockupsLogout();
		} else {
			await apiLogout();
		}
		delete window.localStorage.logged;
		const { isFreepikContext } = useEditorMode();
		const { nativeRedirect } = useRouterMockup();

		remove('wepik_session');
		remove('wepik_session_v2');
		remove('GR_REFRESH');
		remove('GR_TOKEN');
		checkLoginStatus();
		Bugsnag.leaveBreadcrumb(`Logout`);

		if (mode.value === 'pikaso') {
			// do nothing
		} else if (mode.value === 'mockup') {
			// Validate if we are in freepik context and redirect to landing page
			isFreepikContext.value ? nativeRedirect(Settings.landingPage) : window.location.reload();
		} else if (!isPhotoMode.value) {
			window.location.href = links.home[locale.value as Lang];
		}
	};

	const requireAuth = () => {
		notClosable.value = mode.value === 'editor' || mode.value === 'pikaso' || mode.value === 'mockup';
		if (user.value) {
			authRequired.value = false;
			return false;
		}

		authRequired.value = true;

		const interval = useIntervalFn(() => {
			if (!window.localStorage.logged) return;
			interval.pause();
			checkLoginStatus();
			until(isLogged)
				.toBe(true)
				.then(() => {
					authRequired.value = false;
				});
		}, 1000);

		return () => {
			authRequired.value = false;
		};
	};

	const isAdmin = computed(() => {
		return user.value?.isAdmin || false;
	});

	const isSlidesgoPremium = computed(() => {
		return user.value?.slidesgoPremium || false;
	});
	const isFreepikPremium = computed(() => {
		return user.value?.freepikPremium || false;
	});

	const urlParams = useUrlSearchParams<{
		close?: string;
	}>();

	const recoverFreepikSession = () => {
		// En cuanto estemos volviendo de la autenticación de freepik, se guarda en el localstorage
		// y retornamos true para mostrar la modal de que ya puedes cerrar esta ventana
		if (urlParams.close) {
			window.localStorage.setItem('auth_done', '1');
			return true;
		}

		// En cambio, si no estamos con el parametro close, esperamos a que este el auth done de la otra
		// ventana en storage
		const interval = useIntervalFn(() => {
			if (!window.localStorage.getItem('auth_done')) return;
			window.localStorage.removeItem('auth_done');
			interval.pause();
			checkLoginStatus();
			remove('GR_LGURI');

			until(isLogged)
				.toBe(true)
				.then(() => {
					authRequired.value = false;
				});
		}, 1000);

		return false;
	};

	const watchFocusForAuth = () => {
		useEventListener(document, 'visibilitychange', () => {
			if (!document.hidden && !isLogged.value) {
				checkLoginStatus();
			}
		});
	};

	const isFreepikUser = computed(() => {
		return user.value?.email.endsWith('@freepik.com') || false;
	});

	const pikasoLogin = async () => {
		const { set, remove } = useCookies();
		const { APP_BASE } = useEnvSettings();
		const { isFreepikContext } = useEditorMode();

		GAnalytics.trackGA4('login_popup', { link_text: 'login' });
		remove('GR_LGURI');

		window.posthog.capture('user login', { referrer_screen: 'Sketch' });

		let loginUrl = isFreepikContext.value
			? 'https://id.freepikcompany.com/v2/log-in?client_id=freepik'
			: APP_BASE + 'login?editor=1';

		try {
			const ga = window.document.cookie.match('(?:^|;)\\s*_ga\\s*=\\s*([^;]+)')?.pop();
			const link = window.google_tag_data.glBridge.generate({
				_ga: ga,
			});
			loginUrl += `&_gl=${link}`;
		} catch (error) {
			console.error(error);
		}

		if (isFreepikContext.value) {
			set('GR_LGURI', window.location.href, { path: '/', domain: '.freepik.com' });
		}

		window.location.href = loginUrl;
		remove('GR_LGURI');
	};

	const loginMockups = async () => {
		const { set, remove } = useCookies();
		const { APP_BASE } = useEnvSettings();
		const { isFreepikContext } = useEditorMode();

		// Validate context for login url
		let loginUrl = isFreepikContext.value
			? 'https://id.freepikcompany.com/v2/log-in?client_id=freepik'
			: APP_BASE + 'login?editor=1';

		try {
			const _ga = window.document.cookie.match('(?:^|;)\\s*_ga\\s*=\\s*([^;]+)')?.pop() || '';
			const fp_ga = window.document.cookie.match('(?:^|;)\\s*fp_ga\\s*=\\s*([^;]+)')?.pop() || '';
			const link = window.google_tag_data.glBridge.generate({
				_ga,
				fp_ga,
			});
			loginUrl += `&_gl=${link}&_ga=${_ga.split('GA1.')[1]}&fp_ga=${fp_ga.split('GA1.')[1]}`;
		} catch (error) {
			console.error(error);
		}

		// Set the GR_LGURI cookie to redirect to the current page after login
		if (isFreepikContext.value) {
			set('GR_LGURI', window.location.href, { path: '/', domain: '.freepik.com' });
		} else {
			set('GR_LGURI', { path: '/', domain: '.wepik.com' });
		}

		window.location.href = loginUrl;
		remove('GR_LGURI');
	};

	return {
		isLogged,
		notClosable,
		user,
		checkLoginStatus,
		logout,
		requireAuth,
		onSuccessLogin: onFetchResponse,
		onFetchLogin,
		limits,
		authRequired,
		isAdmin,
		watchFocusForAuth,
		isFreepikUser,
		recoverFreepikSession,
		refreshCsrfToken,
		isSlidesgoPremium,
		isFreepikPremium,
		fetchUserIsDone: userFetched,
		pikasoLogin,
		loginMockups,
	};
});
