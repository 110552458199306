import { createSharedComposable, onStartTyping, useEventListener } from '@vueuse/core';
import { ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { useEditorClipboard } from '@/editor/composables/useEditorClipboard';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useZoom } from '@/editor/composables/useZoom';
import { useMainStore } from '@/editor/stores/store';
import { Box } from '@/elements/box/classes/Box';
import { useTextInsideBox } from '@/elements/box/composables/useTextInsideBox';
import Element from '@/elements/element/classes/Element';
import { useUserImageProvider } from '@/elements/medias/images/image/composables/useUserImageProvider';
import { Text } from '@/elements/texts/text/classes/Text';
import { useTextCurate } from '@/elements/texts/text/composables/useTextCurate';
import { useTextEditing } from '@/elements/texts/text/composables/useTextEditing';
import { useHistoryStore } from '@/history/stores/history';
import EventTools from '@/interactions/classes/EventTools';
import { useInteractions } from '@/interactions/composables/useInteractions';
import { useOrderedKeyboardListener } from '@/interactions/composables/useOrderedKeyboardListener';
import { useSelection } from '@/interactions/composables/useSelection';
import { useBottomBarVisibility } from '@/layout/composables/useBottomBarVisibility';
import { useCanvasNavigation } from '@/layout/composables/useCanvasNavigation';
import Page from '@/page/classes/Page';
import { useProjectPages } from '@/project/composables/useProjectPages';
import { useProjectStore } from '@/project/stores/project';

export const useEditorKeyboardEvents = createSharedComposable(() => {
	const store = useMainStore();
	const { selection, clearSelection } = useSelection();

	const project = useProjectStore();
	const history = useHistoryStore();

	const { text, copy, copySelection, pasteSelection, pasteNewText, pasteSVG, hasContent } = useEditorClipboard();
	const { listen } = useOrderedKeyboardListener();
	const { activePageisLastPage } = useProjectPages();
	const { decreaseZoom, increaseZoom } = useZoom();
	const { getClipboardFilesAndUploadImages } = useUserImageProvider();
	const { selectAll } = useInteractions();
	const { textEditing } = useTextEditing();
	const { setupTextInsideBox } = useTextInsideBox();
	const { isDisneyMode } = useEditorMode();
	const { selectedPages, menuOpened: menuPagesOpened, canMoveLeft, canMoveRight } = useCanvasNavigation();

	const { canvasNavigationIsOpen } = useBottomBarVisibility();
	const temporalText = ref(Text.create());

	const { removeUnnecessarySpan } = useTextCurate(temporalText);

	const isTextInputEvent = (e: KeyboardEvent | ClipboardEvent) => {
		const target = <HTMLInputElement>e.target;
		return (
			(target.tagName === 'INPUT' && target?.type !== 'range') ||
			target?.tagName === 'TEXTAREA' ||
			target?.isContentEditable
		);
	};

	listen('ArrowLeft', (e) => {
		if (isTextInputEvent(e) || textEditing.value || project.pages.length === 1) {
			return;
		}

		e.preventDefault();

		menuPagesOpened.value = '';

		if (!canMoveLeft.value) {
			if (selectedPages.value.length > 1 && !e.shiftKey) selectedPages.value = [store.activePage as Page];
			return;
		}

		const newIndex = Math.max(0, project.pages.findIndex((page) => page.id === store.activePageId) - 1);

		const page = project.pages[newIndex] as Page;
		if (!page) return;

		store.setActivePage(page);
		page.domPreviewNode()?.scrollIntoView({ behavior: 'smooth', block: 'end' });

		if (!canvasNavigationIsOpen.value) return;

		// ? comprobamos si la página activa ya estaba en la selección
		const activePageWasSelected = selectedPages.value.includes(page);

		// ? comprobamos si la página anterior a la activa está en la selección
		const pageBeforeActivePageIsSelected = selectedPages.value.includes(
			project.pages[project.pages.indexOf(page) - 1] as Page
		);

		//  En caso de tener una selección, si la página ya estaba seleccionada o la página anterior a la activa
		//  ya está seleccionada, sacamos la última asignada de la selección
		//  ? (esta reasignación se realiza por que venimos de una selección contraria, es decir, hacia la derecha)
		if (selectedPages.value.length >= 1 && e.shiftKey && (pageBeforeActivePageIsSelected || activePageWasSelected)) {
			selectedPages.value.pop();
			return;
		}

		//  en caso de no tener presionado shift, limpiamos la selección
		if (!e.shiftKey) {
			selectedPages.value = [page];
		}

		// añadimos a la selección las páginas nuevas
		if (!selectedPages.value.includes(page)) {
			selectedPages.value = e.shiftKey ? [...selectedPages.value, page] : [page];
		}
	});

	listen('ArrowRight', (e) => {
		if (isTextInputEvent(e) || textEditing.value || project.pages.length === 1) {
			return;
		}

		e.preventDefault();

		menuPagesOpened.value = '';

		// En el caso de que no podamos movernos hacia la derecha, cortamos la ejecución,
		//  pero comprobando si tenemos una selección ,y si no tenemos presionado shift
		//  limpiamos la selección
		if (!canMoveRight.value) {
			if (selectedPages.value.length > 1 && !e.shiftKey) selectedPages.value = [store.activePage as Page];
			return;
		}

		const newIndex = Math.min(
			project.pages.length - 1,
			project.pages.findIndex((page) => page.id === store.activePageId) + 1
		);

		const page = project.pages[newIndex] as Page;
		if (!page) return;

		store.setActivePage(page);
		page.domPreviewNode()?.scrollIntoView({ behavior: 'smooth', block: 'end' });

		if (!canvasNavigationIsOpen.value) return;

		// ? comprobamos si la página activa ya estaba en la selección
		const activePageWasSelected = selectedPages.value.includes(page);

		// ? comprobamos si la página siguiente a la activa está en la selección
		const pageAfterActivePageIsSelected = selectedPages.value.includes(
			project.pages[project.pages.indexOf(page) + 1] as Page
		);

		//  En caso de tener una selección, si la página ya estaba seleccionada o la página siguiente a la activa
		//  ya está seleccionada, sacamos la última asignada de la selección
		//  ? (esta reasignación se realiza por que venimos de una selección contraria, es decir, hacia la izquierda)
		if (selectedPages.value.length >= 1 && e.shiftKey && (pageAfterActivePageIsSelected || activePageWasSelected)) {
			selectedPages.value.pop();
			return;
		}

		//  en caso de no tener presionado shift, limpiamos la selección
		if (!e.shiftKey) {
			selectedPages.value = [page];
		}
		// añadimos a la selección las páginas nuevas
		if (!selectedPages.value.includes(page || activePageisLastPage.value)) {
			selectedPages.value = e.shiftKey ? [...selectedPages.value, page] : [page];
		}
	});

	listen('Escape', (e) => {
		if (isTextInputEvent(e)) {
			return;
		}

		clearSelection();
		store.illustratorSelection.clear();
	});

	listen(['z', 'Z'], (e) => {
		// @ts-ignore
		if (isTextInputEvent(e)) {
			return;
		}

		// Mac: command + shift + Z -> rehace cambios
		if (e.metaKey && e.shiftKey) {
			e.preventDefault();
			history.forward();
			return;
		}

		if (e.ctrlKey || e.metaKey) {
			e.preventDefault();
			history.rollback();
		}
	});

	listen(['y', 'Y'], (e) => {
		// @ts-ignore
		if (isTextInputEvent(e)) {
			return;
		}
		if (e.ctrlKey) {
			e.preventDefault();
			history.forward();
		}
	});

	listen(['a', 'A'], (e: KeyboardEvent) => {
		if (isTextInputEvent(e) || textEditing.value) {
			return;
		}

		if (e.metaKey || e.ctrlKey) {
			e.preventDefault();
			selectAll();
		}
	});

	// Ctrl+c
	useEventListener(document.body, 'copy', async (e: ClipboardEvent) => {
		// Si es un INPUT, permitimos que copie y salimos
		if (isTextInputEvent(e)) {
			return;
		}

		if (isDisneyMode.value) {
			return;
		}

		e.preventDefault();

		// Si no existe ninguna selección, salimos
		if (!selection.value.length) {
			return;
		}

		GAnalytics.track('click', 'Template', 'copy', null);
		await copySelection();
	});

	// Ctrl+v
	useEventListener(document.body, 'paste', async (e: ClipboardEvent) => {
		// Si es un INPUT, analizamos el texto para evitar insertar elementos y actualizamos el input, luego salimos

		// @ts-ignore
		if (e.target?.tagName === 'INPUT' || e.target?.tagName === 'TEXTAREA') {
			const text = e.clipboardData?.getData('Text');

			// Prevenimos el comportamiento en caso de que empiece por 'wepik|'
			if (text && text.startsWith('wepik|')) {
				e.preventDefault();
			}

			return;
		}

		e.preventDefault();

		if (isDisneyMode.value) {
			return;
		}

		// Obtenemos el data del clipboard
		let data = await e.clipboardData?.getData('text/plain');

		if (data) {
			data = EventTools.purifyClipboard(data);
		}

		const isNewPaste = text.value !== data;
		const isWepikData = data?.startsWith('wepik|') || false;
		const isSVG = data?.startsWith('<svg') || false;

		// Si hay archivos para pegar priorizamos sobre elementos copiados
		if (e.clipboardData?.files.length) {
			await getClipboardFilesAndUploadImages(e);
			return;
		}

		// Si tenemos algún elemento copiado y no estamos editando texto
		if (hasContent.value && !isNewPaste) {
			GAnalytics.track('click', 'Template', 'paste', null);
			pasteSelection();
			return;
		}

		// Si estamos pegando desde otro archivo de wepik,
		// comprobamos que el data sea más largo y empiece por wepik|
		if (data?.length && isNewPaste && isWepikData) {
			await copy(data);
			pasteSelection();
			return;
		}

		// Para poder pegar SVGs desde el clipboard, por ejemplo desde figma
		if (data?.length && isNewPaste && isSVG) {
			pasteSVG(data);
			return;
		}

		// Si no tenemos un elemento seleccionado o es un texto pero no está en modo edición y estamos intentando pegar un texto
		if (
			(!selection.value.length || (selection.value[0] instanceof Text && !textEditing.value)) &&
			data?.length &&
			isNewPaste
		) {
			pasteNewText(data, selection.value[0] instanceof Text && !textEditing.value);
			return;
		}
	});

	// Control +|- to zoom in|out
	listen(['-'], (e) => {
		if (isTextInputEvent(e)) {
			return;
		}

		if (!(e.metaKey || e.ctrlKey)) {
			return;
		}

		e.preventDefault();
		decreaseZoom(undefined, 10);
	});

	listen(['+'], (e) => {
		if (isTextInputEvent(e)) {
			return;
		}

		if (!(e.metaKey || e.ctrlKey)) {
			return;
		}

		e.preventDefault();
		increaseZoom(undefined, 10);
	});

	// When you're trying to write on a non writable element
	onStartTyping(async () => {
		const sel = (selection.value.length ? selection.value[0] : false) as Element | false;
		if (!sel || sel.locked || textEditing.value) return;

		// If the selected element is a text and we aren't editing, we will start to edit this
		if (sel instanceof Text) textEditing.value = sel;
		if (sel instanceof Box) await setupTextInsideBox(sel.id);

		// Si el elemento seleccionado es un texto, curamos el texto evitando spans innecesarios y añadimos el caracter introducido
		if (sel instanceof Text || sel.firstSubElement instanceof Text) {
			const finalText = sel instanceof Text ? sel : (sel.firstSubElement as Text);

			// Si es un Box y el texto está vacío, añadimos el caracter
			if (sel instanceof Box || sel instanceof Text) {
				if (finalText.content.includes('-webkit-text-fill-color')) {
					removeUnnecessarySpan();
				}
			}
		}
	});
});
