import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import { useMainStore } from '@/editor/stores/store';
import { Box } from '@/elements/box/classes/Box';
import Element from '@/elements/element/classes/Element';
import { PurifyUnserialize } from '@/elements/element/utils/PurifyUnserialize';
import TextTools from '@/elements/texts/text/utils/TextTools';
import Page from '@/page/classes/Page';
import { Color } from '@/Types/colorsTypes';
import {
	FontStyle,
	FontWeight,
	LegacyTextValues,
	ListStyle,
	TextAlign,
	TextDTO,
	TextTransform,
	VerticalTextAlign,
} from '@/Types/elements';
import {
	CurvedProperties,
	PrimaryElementTypes,
	SerializedClass,
	Size,
	TextOutlineProperties,
	TextShadowProperties,
} from '@/Types/types';

export class Text extends Element {
	type: PrimaryElementTypes = 'text';
	content: string;
	fontFamily: string;
	fontWeight: FontWeight;
	fontStyle: FontStyle;
	fontSize: number;
	lineHeight: number;
	letterSpacing: number;
	textAlign: TextAlign;
	verticalTextAlign: VerticalTextAlign;
	outline: TextOutlineProperties;
	colors: Color[];
	textTransform: TextTransform;
	scale: number;
	textShadow: TextShadowProperties[];
	listStyle: ListStyle;
	curvedProperties: CurvedProperties;

	protected constructor(textDTO: TextDTO) {
		super(textDTO);

		this.content = textDTO.content;
		this.fontFamily = textDTO.fontFamily;
		this.fontWeight = textDTO.fontWeight;
		this.fontStyle = textDTO.fontStyle;
		this.fontSize = textDTO.fontSize;
		this.lineHeight = textDTO.lineHeight;
		this.letterSpacing = textDTO.letterSpacing;
		this.textAlign = textDTO.textAlign;
		this.outline = textDTO.outline;
		this.colors = textDTO.colors;
		this.textTransform = textDTO.textTransform;
		this.scale = textDTO.scale;
		this.textShadow = textDTO.textShadow;
		this.listStyle = textDTO.listStyle;
		this.curvedProperties = textDTO.curvedProperties;
		this.verticalTextAlign = textDTO.verticalTextAlign;
	}

	static defaults(): TextDTO {
		const blackColor = SolidColor.black();

		return {
			// Element
			...Element.defaults(),
			type: 'text',
			// Text
			content: 'Text',
			fontFamily: 'Montserrat',
			fontWeight: 400 as FontWeight,
			fontStyle: 'normal' as FontStyle,
			fontSize: 16,
			lineHeight: 1.2,
			letterSpacing: 0,
			textAlign: 'center' as TextAlign,
			verticalTextAlign: null as VerticalTextAlign,
			outline: {
				color: SolidColor.gray(),
				width: 0,
			},
			colors: [blackColor],
			textTransform: '' as TextTransform,
			scale: 1,
			textShadow: [] as TextShadowProperties[],
			listStyle: '' as ListStyle,
			curvedProperties: {
				arc: null,
				minArc: 0,
				transformCurve: 0,
			},
			color: blackColor,
		};
	}

	static create(config: Partial<TextDTO> = {}): Text {
		const textDTO = {
			...Text.defaults(),
			...config,
		};

		return new Text(textDTO);
	}

	@PurifyUnserialize()
	static unserialize(data: SerializedClass<Text> & LegacyTextValues): Text {
		const textDTO = {
			...Text.defaults(),
			...data,
		} as TextDTO;

		// Parsed data.subElements when needed
		textDTO.subElements = new Map<string, Element>();

		// El valor inicial del outline.width será el fontSize/em al aplicar el efecto
		if (data.outline?.width && data.outline.unit === 'em' && data.fontSize) {
			const em = 16;
			const factor = data.fontSize / em;
			textDTO.outline.unit = 'px';
			textDTO.outline.width *= em * factor;
			textDTO.outline.width = parseInt(data.outline.width.toString());
		}

		if (data.colors) {
			textDTO.colors = Array.isArray(data.colors)
				? data.colors.map((c) => ('stops' in c ? GradientColor.unserialize(c) : SolidColor.unserialize(c)))
				: Object.values<Color>(data.colors).map((c) =>
						'stops' in c ? GradientColor.unserialize(c) : SolidColor.unserialize(c)
				  );
		}

		// Parseamos el contenido del texto y convertimos los colores rgb a variable css
		// además solucionamos un problema con el tag <font> que nos da problemas con el
		// texto multi-estilo (issue #7917)
		textDTO.content = TextTools.fixFontTagInTextContent(TextTools.parseTextColorsToCssVars(textDTO));

		// Eliminamos ataques de XSS mediante meta tags ya que no hay CSP que lo bloquee
		textDTO.content = TextTools.removeMetaTags(TextTools.parseTextColorsToCssVars(textDTO));

		const fixedTextTransform = Text.unserializeTextTransform(data.textTransform);

		if (fixedTextTransform) {
			textDTO.textTransform = fixedTextTransform;
		}

		// Gradients have id (and more) but Solids only have color channels (r,g,b,a)
		textDTO.outline = Text.unserializeOutline(data);

		textDTO.textShadow = Text.unserializeTextShadow(data);

		// durante un día se almacenaron las fuentes con comillas
		if (data.fontFamily && data.fontFamily.startsWith('"')) {
			textDTO.fontFamily = data.fontFamily.replaceAll('"', '');
		}

		if (data.size && !data.size.height) {
			// Hay textos que no tienen alto por un bug en el editor
			textDTO.size = {
				width: data.size.width,
				height: data.fontSize || 30,
			};
		}

		const elem = new Text(textDTO);

		if (data.id) {
			elem.id = data.id;
		}

		return elem;
	}

	protected static unserializeTextTransform(textTransform: TextTransform | undefined) {
		return ['', 'lowercase', 'uppercase', 'capitalize'].includes(textTransform as string) ? textTransform : '';
	}

	protected static unserializeOutline(data: SerializedClass<Text> & LegacyTextValues): TextOutlineProperties {
		const { color, width } = Text.defaults().outline;
		const borderWidth = data.borderWidth || data.outline?.width || width;

		let fixedOutlineColor = color;

		// Support legacy data structure
		if (data.borderColor) {
			fixedOutlineColor = SolidColor.unserialize(data.borderColor);
		}

		// Support new data structure
		if (data.outline?.color) {
			fixedOutlineColor = SolidColor.unserialize(data.outline.color);
		}

		if (!borderWidth) {
			fixedOutlineColor = Text.defaults().outline.color;
		}

		return {
			color: fixedOutlineColor,
			width: borderWidth,
			unit: data.outline?.unit || 'px',
		};
	}

	protected static unserializeTextShadow(data: SerializedClass<Text> & LegacyTextValues) {
		let fixedTextShadow = Text.defaults().textShadow;

		// en algun caso se almacena como objeto
		if (typeof data.textShadow === 'object') {
			data.textShadow = Object.values(data.textShadow as object);
		}

		// Fix por un error al parsear los textos nativos en el template loader
		data.textShadow = data.textShadow?.filter((ts) => !Array.isArray(ts));

		// Support legacy data structure
		if (Object.keys(data).includes('shadowAngle')) {
			fixedTextShadow = [
				{
					angle: typeof data.shadowAngle === 'string' ? parseFloat(data.shadowAngle) : data.shadowAngle,
					blur: typeof data.shadowBlur === 'string' ? parseFloat(data.shadowBlur) : data.shadowBlur,
					color: SolidColor.unserialize(data.shadowColor),
					distance: typeof data.shadowDistance === 'string' ? parseFloat(data.shadowDistance) : data.shadowDistance,
					opacity: typeof data.shadowOpacity === 'string' ? parseFloat(data.shadowOpacity) : data.shadowOpacity,
				},
			];
		}

		if (data.textShadow && data.textShadow.length) {
			const needConverToEmUnits = data.textShadow.some((shadow) => !shadow.unit || shadow.unit !== 'em');
			fixedTextShadow = needConverToEmUnits
				? data.textShadow.map((ts) => TextTools.convertShadowToEm(data.fontSize || 0, ts))
				: data.textShadow;
		}

		// Support new data structure
		if (fixedTextShadow.length) {
			fixedTextShadow = Array.isArray(data.textShadow)
				? fixedTextShadow.map((ts) => ({ ...ts, color: SolidColor.unserialize(ts.color) }))
				: Object.values<TextShadowProperties>(fixedTextShadow).map((ts) => ({
						...ts,
						color: SolidColor.unserialize(ts.color),
				  }));
		}

		return fixedTextShadow;
	}

	domNode(): HTMLElement | null {
		if (this.parentBox()) {
			return document.querySelector(`[data-text-inside-box-id="${this.id}"]`);
		}

		return super.domNode();
	}

	setScale(scale: number) {
		this.scale = scale;
	}

	updateColor(newColor: Color) {
		this.colors[0] = newColor;
	}

	htmlInstance() {
		const parser = new DOMParser();
		return parser.parseFromString(`<div>${this.content}</div>`, 'text/html');
	}

	scaleBy(scale: number) {
		super.scaleBy(scale);

		this.fontSize *= scale * this.scale;
		this.outline.width *= scale * this.scale;
		this.letterSpacing *= scale * this.scale;

		const temporalDiv = document.createElement('div');

		temporalDiv.innerHTML = this.content;

		temporalDiv
			.querySelectorAll<HTMLElement>('[style*="font-size"]')
			.forEach((e) => (e.style.fontSize = `${parseInt(e.style.fontSize) * scale * this.scale}px`));

		temporalDiv
			.querySelectorAll<HTMLElement>('[style*="letter-spacing"]')
			.forEach((e) => (e.style.letterSpacing = `${parseInt(e.style.letterSpacing) * scale * this.scale}px`));

		temporalDiv.querySelectorAll<HTMLOListElement | HTMLUListElement>('ol, ul').forEach((e) => {
			e.style.marginLeft = `${parseFloat(e.style.marginLeft) * scale * this.scale}px`;
		});

		//? fixeamos el -webkitBackgroundClip ya que innerHTML en ocasiones lo modifica a backgroundClip
		this.content = TextTools.fixBackgroundClip(temporalDiv.innerHTML);

		if (this.curvedProperties.arc) {
			this.curvedProperties.minArc *= scale * this.scale;
			this.curvedProperties.arc *= scale * this.scale;
			this.curvedProperties.transformCurve *= scale;
		}

		this.scale = 1;
	}

	resetScaleProperties(scale: number) {
		this.fontSize *= scale;
		this.outline.width *= scale;
		this.letterSpacing *= scale;

		const temporalDiv = document.createElement('div');

		temporalDiv.innerHTML = this.content;

		temporalDiv
			.querySelectorAll<HTMLElement>('[style*="font-size"]')
			.forEach((e) => (e.style.fontSize = `${parseInt(e.style.fontSize) * scale}px`));

		temporalDiv
			.querySelectorAll<HTMLElement>('[style*="letter-spacing"]')
			.forEach((e) => (e.style.letterSpacing = `${parseInt(e.style.letterSpacing) * scale}px`));

		temporalDiv.querySelectorAll<HTMLOListElement | HTMLUListElement>('ol, ul').forEach((e) => {
			e.style.marginLeft = `${parseFloat(e.style.marginLeft) * scale}px`;
		});

		this.content = temporalDiv.innerHTML;

		if (this.curvedProperties.arc) {
			this.curvedProperties.minArc *= scale;
			this.curvedProperties.arc *= scale;
			this.curvedProperties.transformCurve *= scale;
		}

		this.scale = 1;
	}

	updateContent(content: string) {
		this.content = this.fixTextStyles(content);
	}

	textFinalNode(): HTMLElement | null {
		const domNode = this.domNode();
		return domNode && domNode.querySelector<HTMLElement>('.text-element-final:not([id^="editable-"])');
	}

	private fixTextStyles(content: string) {
		// De forma nativa al añadir un salto de linea en un texto con outline,
		// y eliminar el salto de linea se le aplica nativamente un -webkit-text-stroke-color,
		// este tiene que ser eliminado
		let fixed = TextTools.fixTextStrokeColorStyles(content);

		// Arreglamos los estilos de los textos con gradientes,
		// ya que al aplicar nuevos estilos se pierde el prefijo -webkit del background-clip
		fixed = TextTools.fixBackgroundClip(fixed);

		return fixed;
	}

	oneCharMinWidth(data?: Partial<Box>): number {
		return (
			(this.fontSize + this.letterSpacing) * this.scale * 1.05 +
			((data?.border?.size || 0) + (data?.padding?.x || 0)) * 2
		);
	}

	minBoxWidth(data?: Partial<Box>): number {
		const hasParent = !!this.parentBox();
		const scale = hasParent ? this.scale * 1.05 : this.scale;
		return (this.fontSize + this.letterSpacing) * scale + ((data?.border?.size || 0) + (data?.padding?.x || 0)) * 2;
	}

	minBoxHeight(data?: Partial<Box>) {
		const hasParent = !!this.parentBox();
		const scale = hasParent ? this.scale * 1.05 : this.scale;
		return this.fontSize * this.lineHeight * scale + ((data?.border?.size || 0) + (data?.padding?.y || 0)) * 2;
	}

	get color() {
		return this.colors[0];
	}

	set color(color: Color) {
		this.colors[0] = color;
	}

	getFinalSize(): Size {
		const finalText = this.domNode()?.querySelector('.text-element-final') as HTMLElement;
		if (finalText) {
			const { width, height } = getComputedStyle(finalText);
			return {
				width: parseFloat(width.includes('px') ? width : '0'),
				height: parseFloat(height.includes('px') ? height : '0'),
			};
		}
		return { width: 0, height: 0 };
	}

	parentBox(page?: Page): Box | null {
		if (!this.parentId) return null;
		if (page) return page.elements.get(this.parentId) as Box | null;
		const { activePage } = useMainStore();
		return activePage?.elements.get(this.parentId) as Box | null;
	}

	getTextPadding() {
		const metrics = TextTools.getFontAscentAndDescent(this.fontFamily, this.fontWeight, this.fontSize, this.content);

		const fontBoundingSize = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
		const originalTextHeight = this.fontSize * this.lineHeight;

		return Math.abs(originalTextHeight - fontBoundingSize) / 2 || 0;
	}

	clone(): this {
		const element = super.clone();

		const colorsOriginal = element.colors.map((color) => cloneDeep(color));

		// Modificamos los id a los colores de la copia
		element.colors.forEach((color) => {
			color.id = 'color-' + uuidv4();
		});

		// Actualizamos las referencias de los colores del content
		colorsOriginal.forEach((color, i) => {
			element.content = element.content.replaceAll(color.id, `${element.colors[i].id}`);
		});

		return element;
	}
}
