import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';

import { DataStructureToJsonAdapter } from '@/apps/mockup/adapters/DataStructureToJsonAdapter';
import { Settings } from '@/apps/mockup/classes/Settings';
import { useComposite25D } from '@/apps/mockup/composable/useComposite25D';
import { useLoadMockup } from '@/apps/mockup/composable/UseLoadMockup';
import { Customizable2D } from '@/apps/mockup/facades/Customizable2D';
import { versioning } from '@/apps/mockup/facades/versioningChanges';
import {
	CompositingSize,
	CompositionScaleBySize,
	Layer,
	MockupElement,
	MockupElementColor,
	MockupElementTextures,
	MockupManifest,
	MockupState,
	MockupStoreSettings,
	Size,
} from '@/apps/mockup/schemas';
import { Color } from '@/Types/colorsTypes';

import { EditingMode } from './Types/basicTypes';

export const useMockupStore = defineStore('mockup', {
	state: (): MockupState => ({
		activeElement: null,
		activeColor: null,
		title: null,
		manifests: null,
		manifest: null,
		loading: false,
		downloading: false,
		error: false,
		mockup: {
			image: '',
			elements: [],
			originalSize: { width: 0, height: 0 },
		},
		percentageColladaLoaded: 0,
		customization: null,

		previewCanvas: null,
		posterCanvas: null,
		downloadCanvas: null,

		source: {
			images: [],
		},
		sku: null,
		mockupType: null,
		pagePreviousStatus: null,
		editingMode: EditingMode.standard,
		settings: new Settings([
			{ key: MockupStoreSettings.ReferencePoints, value: false },
			{ key: MockupStoreSettings.SelectCustomizableInitialLoad, value: false },
			{ key: MockupStoreSettings.IsFirstCustomizableSelectInitialLoading, value: false },
			{ key: MockupStoreSettings.OpenCustomInFirstEdition, value: true },
			{ key: MockupStoreSettings.SetBgOpacityForFirstColorChange, value: true },
			{ key: MockupStoreSettings.DebugMode, value: false },
			{ key: MockupStoreSettings.UseDomToImage, value: true },
			{ key: MockupStoreSettings.ViewMemory, value: true },
			{ key: MockupStoreSettings.ViewEvents, value: false },
		]),
		sharedLink: '*',
		size: { height: 3333, width: 5000 },
		editor: {
			firstEntry: false,
			recentColors: [],
		},
		isPremium: null,
		searching: {
			premium: false,
		},
	}),
	getters: {
		getColorElements(state): MockupElementColor[] {
			return state.mockup.elements.filter(
				(el: MockupElement) => el.kind == 'color' && el.visibilityMode?.includes(state.editingMode)
			) as MockupElementColor[];
		},
		getTextureElements(state): MockupElementTextures[] {
			return state.mockup.elements.filter(
				(el: MockupElement) =>
					(el.kind == 'texture' || el.kind == '2dTexture') && el.visibilityMode?.includes(state.editingMode)
			) as MockupElementTextures[];
		},

		/**
		 * Get all elements according actual editing Mode
		 * @param {MockupState} state
		 * @returns {MockupElement[]}
		 */
		getElements(state): MockupElement[] {
			return state.mockup.elements.filter((el: MockupElement) =>
				el.visibilityMode?.includes(state.editingMode)
			) as MockupElementTextures[];
		},

		/**
		 * Get all elements without have in account current editing Mode
		 * @param {MockupState} state
		 * @returns {MockupElement[]}
		 */
		getAllElements(state): MockupElement[] {
			return state.mockup.elements as MockupElementTextures[];
		},

		/**
		 * Get if Store have a design elements
		 * @param {MockupState} state
		 * @returns {boolean}
		 */
		haveDesingElements(state): boolean {
			return (
				state.mockup.elements.filter((element) => element.visibilityMode?.includes(EditingMode.design)).length !== 0
			);
		},

		/**
		 * Return if Mockup Mode is in StandardMode
		 * @returns {boolean}
		 */
		getIsStandardMode(state): boolean {
			return state.editingMode == EditingMode.standard;
		},

		getAspectRatio(state): number {
			const ar = (state.size.width ?? 0) / (state.size.height ?? 1);
			return parseFloat(ar.toFixed(2));
		},

		/**
		 * Return if Mockup Mode have a Elements ind DesingMode
		 * @returns {boolean}
		 */
		haveDesingMode(state): boolean {
			return (
				state.mockup.elements.filter((el: MockupElement) => el.visibilityMode?.includes(EditingMode.design)).length != 0
			);
		},

		getRecentUsedColors(state): Color[] {
			return state.editor.recentColors as Color[];
		},

		getLayers(state): Layer[] {
			return state.manifest?.children ?? [];
		},

		isPortrait(state): boolean {
			return state.size.width < state.size.height;
		},

		getIsPortrait(state): boolean {
			return state.size.height > state.size.width;
		},

		getFirstElement(state): MockupElementTextures {
			return state.mockup.elements[0] as MockupElementTextures;
		},
	},

	actions: {
		findElementById(uuid: string): MockupElement {
			return this.mockup.elements.find((el: MockupElement) => el.id == uuid) as MockupElement;
		},

		findElementTextureById(uuid: string): MockupElementTextures {
			return this.getTextureElements.find((el: MockupElement) => el.id == uuid) as MockupElementTextures;
		},
		updateMockupColor(oldColor: Color, newColor: Color) {
			this.setRecentColor(newColor.copy());
			this.getColorElements.forEach((el: MockupElementColor) => {
				const mockupColor = el.color as Color;
				if (mockupColor.id === oldColor.id) {
					newColor.id = mockupColor.id;
					el.color = newColor;
					this.activeColor = el;
					//Reset build values in this color
					el.appliedChanges = {
						[CompositingSize.PREVIEW]: false,
						[CompositingSize.POSTER]: false,
						[CompositingSize.DOWNLOAD]: false,
					};
				}
			});
		},

		/**
		 * Set new Color to recent used ColorsMockups
		 * @param {Color} newColor
		 */
		setRecentColor(newColor: Color) {
			const recentsColors = this.editor.recentColors;

			const haveDuplicate = recentsColors.find((c) => c.toCssString() === newColor.toCssString());
			if (haveDuplicate) return;

			recentsColors.unshift(newColor);
			if (recentsColors.length == 7) {
				recentsColors.splice(6, 1);
			}
		},
		findNameColor(uuidColor: string): string {
			const findedColor = this.getColorElements.find((el: MockupElementColor) => {
				const color = el.color as Color;
				if (color.id === uuidColor) return el;
			});
			return findedColor!.name;
		},
		updateActiveElement(id: string) {
			const nextActiveElement = this.mockup.elements.find((el: MockupElement) => el.id === id);
			if (nextActiveElement) this.activeElement = nextActiveElement;
		},

		deleteActivElement() {
			this.activeElement = null;
		},

		newTextureChange(id: string) {
			this.findElementTextureById(id).appliedChanges = {
				changeId: uuidv4(),
				[CompositingSize.PREVIEW]: false,
				[CompositingSize.POSTER]: false,
				[CompositingSize.DOWNLOAD]: false,
			};
		},

		renderMockupByManifest(manifest: MockupManifest, compositionSize: CompositingSize, registerChange = true) {
			const { CreateMockup } = useComposite25D();
			const mockup = CreateMockup(manifest, compositionSize);
			if (registerChange) {
				const data = new DataStructureToJsonAdapter().convertToJSON();
				versioning().registerChange(data);
			}

			return mockup;
		},

		changeMode(mode: EditingMode) {
			this.editingMode = mode;
			document.body.style.setProperty('--mockup-controls-color', '#FF7D6A');
			const { changeModeMockup } = useLoadMockup();
			changeModeMockup();
		},

		isOnPreviewMode(): boolean {
			return this.activeElement != null;
		},

		getSetting(key: MockupStoreSettings): boolean {
			return this.settings.get(key);
		},

		/**
		 * Get the size of a mockup element texture
		 *
		 * @description We implement this change because 2DMockups have a Smart Object with high dimension
		 * and we need to format the size of the element according to the scale of the composition Preview
		 * because when we render the texture and scale to 5K or 2K the texture is very big and DOM don't render it
		 *
		 * @param id
		 */
		formatSizeMockupElementTexturesById(id: string): Size {
			const element = this.findElementTextureById(id);
			if (!element.width || !element.height) {
				console.warn(`Element ${element.id} has no size`);
				return { width: 0, height: 0 };
			}
			const size: Size = { width: element.width, height: element.height };
			if (element.kind === 'texture') return size;

			const scale = CompositionScaleBySize[CompositingSize.PREVIEW];
			return Customizable2D.formatSizeAccordingToScale(size, scale);
		},

		/**
		 * Add Render Mockup Element to MockupElementTexture
		 * @param {HTMLCanvasElement} renderData - Rendered data
		 * @param {number} width - Width of the render
		 * @param {string} mockupElementId - Id of the MockupElementTexture
		 */
		addRenderMockupElement(renderData: HTMLCanvasElement, width: number, mockupElementId: string) {
			const element = this.findElementTextureById(mockupElementId)
			if (!element) console.warn('Element not found in Add Render mockup Element', mockupElementId)
			element.renderImages[Math.round(width)] = renderData;
		},
	},
});
