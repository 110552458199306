import Bugsnag from '@bugsnag/js';
import { nextTick, Ref, ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { getSvg } from '@/api/DataApiClient';
import { useAuth } from '@/auth/composables/useAuth';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useToast } from '@/common/composables/useToast';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { Box } from '@/elements/box/classes/Box';
import Element from '@/elements/element/classes/Element';
import { useElementTransformOrchestrator } from '@/elements/element/composables/useElementTransformOrchestrator';
import ElementTools from '@/elements/element/utils/ElementTools';
import Line from '@/elements/line/classes/Line';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import Image from '@/elements/medias/images/image/classes/Image';
import Mask from '@/elements/medias/mask/classes/Mask';
import { Video } from '@/elements/medias/video/classes/Video';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import Storyset from '@/elements/storyset/classes/Storyset';
import { Text } from '@/elements/texts/text/classes/Text';
import { useI18n } from '@/i18n/useI18n';
import { useAddInsertableText } from '@/interactions/composables/useAddInsertableText';
import { useInsertablePosition } from '@/interactions/composables/useInsertablePosition';
import { useSelection } from '@/interactions/composables/useSelection';
import { useActivePage } from '@/page/composables/useActivePage';
import {
	BasicShapeApi,
	FlaticonElementApi,
	ImageApi,
	LinesAndArrowsApi,
	MaskApi,
	MediaApi,
	StickersElementApi,
	StorysetApi,
	UploadApi,
	VideoApi,
} from '@/Types/apiClient';
import { InsertableApiType } from '@/Types/types';

// Pass this optional previewElement param if element is Text, to apply the curved properties to the element
export const useAddInsertableElement = (previewElement?: Ref<Text>) => {
	const store = useMainStore();
	const { setSelection } = useSelection();
	const { isLogged, requireAuth, user, isSlidesgoPremium } = useAuth();
	const { isPhotoMode, isDisneyMode, isSlidesgoMode } = useEditorMode();

	const { trans } = useI18n();
	const { runOnMobile } = useDeviceInfo();
	const toast = useToast();
	const temporalRef = ref<Image | Shape | Storyset | Text | Line | Video | Box>(Shape.create());

	const { addInsertableText, addInsertablePasteText, addInsertablePredefinedText, addInsertableAIText } =
		useAddInsertableText(temporalRef as Ref<Text>, previewElement as Ref<Text>);
	const usingTransformOrchestrator = useElementTransformOrchestrator(temporalRef);
	const { addElement } = useActivePage();
	const { resetLastPosition, offsetInsertedElement } = useInsertablePosition(temporalRef);

	const setupElementInPage = async (el: Element) => {
		if (!el) return;
		resetLastPosition();

		if (el instanceof Image) {
			usingTransformOrchestrator.value.setupInPage();
			usingTransformOrchestrator.value.centerInVisibleZone(el);

			if (el instanceof Image && el.urlBackgroundRemoved) {
				const foreground = await ForegroundImage.fromImage(el);
				temporalRef.value = el;
				addElement(foreground);
			}
		} else {
			usingTransformOrchestrator.value.setupInPage();
			usingTransformOrchestrator.value.centerInVisibleZone(el);
		}

		offsetInsertedElement();
	};

	const addInsertableShape = async (svg: string) => {
		const element = ElementTools.isBox(svg) ? Box.fromSvg(svg) : Shape.fromSvg(svg);
		temporalRef.value = element;
		addElement(element);
		setupElementInPage(element);

		usingTransformOrchestrator.value.fitSizeToPixelGrid(element.size);

		setSelection(element, false);

		return element;
	};

	const addInsertableShapeFromUrl = async (url: string) => {
		const { data } = await getSvg(url);
		if (!data.value || !store.activePage) {
			toast.error(trans('Oops! Basic shape loading error, please try again'));
			return;
		}

		return addInsertableShape(data.value);
	};

	const addInsertableFlaticon = async (data: FlaticonElementApi) => {
		const flaticon = await addInsertableShapeFromUrl(data.svg);
		if (!flaticon) return;

		flaticon.metadata.dataApi = { ...data, origin: 'flaticon' };

		GAnalytics.track('click', 'Button', 'add-icon', null);
		GAnalytics.trackGA4('add_to_canvas', { category: 'icon' });

		Bugsnag.leaveBreadcrumb(`Add flaticon to canvas; ${flaticon.type}-${flaticon.id}`);
		return flaticon;
	};

	const addInsertableSticker = async (data: StickersElementApi) => {
		const sticker = await addInsertableShapeFromUrl(data.svg);
		if (!sticker) return;

		sticker.metadata.dataApi = { ...data, origin: 'flaticon' };

		GAnalytics.track('click', 'Button', 'add-icon', null);
		GAnalytics.trackGA4('add_to_canvas', { category: 'icon' });

		Bugsnag.leaveBreadcrumb(`Add Sticker to canvas: ${sticker.type}-${sticker.id}`);
		return sticker;
	};

	const addInsertableStoryset = async (url: string) => {
		const { data } = await getSvg(url);
		if (!data.value || !store.activePage) {
			toast.error(trans('Oops! Illustration loading error, please try again'));
			return;
		}

		const storyset = Storyset.fromSvg(data.value);
		temporalRef.value = storyset;

		addElement(storyset);

		setupElementInPage(storyset);

		usingTransformOrchestrator.value.fitSizeToPixelGrid(storyset.size);

		setSelection(storyset, false);

		GAnalytics.track('click', 'Button', 'add-icon', null);
		GAnalytics.trackGA4('add_to_canvas', { category: 'illustration' });

		Bugsnag.leaveBreadcrumb(`Add Story to canvas: ${storyset.type}-${storyset.id}`);
		return storyset;
	};

	const addInsertableImageMask = async (maskApi: MaskApi) => {
		const mask = await Mask.fromApi(maskApi);
		if (!mask) return;

		const image = await Image.fromUrl('https://wepik.com/svg/mask-placeholder.svg');
		image.setMask(mask);
		// Used to apply placeholdered mask on drag over image
		image.metadata.maskApi = maskApi;
		temporalRef.value = image;

		addElement(image);

		setupElementInPage(image);

		usingTransformOrchestrator.value.fitSizeToPixelGrid(image.size);

		setSelection(image, false);

		GAnalytics.track('click', 'Button', 'add-icon', null);
		GAnalytics.trackGA4('add_to_canvas', { category: 'images_mask' });

		Bugsnag.leaveBreadcrumb(`Add mask to canvas, ${image.type}-${image.id}; Mask api id: ${mask.id}`);
		return image;
	};

	const addInsertableBasicShape = async (url: string) => {
		const basicShape = await addInsertableShapeFromUrl(url);
		if (!basicShape) return;
		basicShape.keepProportions = basicShape instanceof Shape;

		GAnalytics.track('click', 'Button', 'add-icon', null);
		GAnalytics.trackGA4('add_to_canvas', { category: 'basic_shape' });

		Bugsnag.leaveBreadcrumb(`Add basic shape to canvas: shape-${basicShape.id}`);
		return basicShape;
	};

	const addInsertableImage = async (imageApi: ImageApi | UploadApi) => {
		const image = await Image.fromApiImage(imageApi);
		// Used to replace placeholder image on drag over mask

		image.metadata.imageApi = { ...imageApi, data: [], links: [] };
		delete image.metadata.imageApi.metadata;

		temporalRef.value = image;
		addElement(image);

		setupElementInPage(image);

		usingTransformOrchestrator.value.fitSizeToPixelGrid(image.size);
		setSelection(image, false);

		if (imageApi.origin) {
			Bugsnag.leaveBreadcrumb(
				`Add image to canvas from ${imageApi.origin}; idProvider: ${imageApi.id}; image-${image.id}}`
			);

			GAnalytics.track('click', 'add-image', `${imageApi.origin}`, null);
			GAnalytics.trackGA4('add_to_canvas', { category: 'image' });
		}

		return image;
	};

	const addInsertableLine = async (url: string) => {
		const { data } = await getSvg(url);

		if (!data.value || !store.activePage) {
			toast.error(trans('Oops! Line loading error, please try again'));
			return;
		}

		const line = Line.fromSvg(data.value);
		temporalRef.value = line;
		addElement(line);

		setupElementInPage(line);

		setSelection(line, false);

		GAnalytics.track('click', 'Button', 'add-line', null);
		GAnalytics.trackGA4('add_to_canvas', { category: 'line' });

		Bugsnag.leaveBreadcrumb(`Add line to canvas; ${line.type}-${line.id}`);

		return line;
	};

	const addInsertableElement = async (
		item:
			| BasicShapeApi
			| FlaticonElementApi
			| ImageApi
			| VideoApi
			| LinesAndArrowsApi
			| MaskApi
			| StickersElementApi
			| StorysetApi
			| UploadApi
			| MediaApi
	) => {
		let inserted;

		if (item.type === InsertableApiType.BasicShape) {
			inserted = await addInsertableBasicShape(item.svg);
		}

		if (item.type === InsertableApiType.SVG) {
			inserted = await addInsertableBasicShape(item.url);
		}

		if (item.type === InsertableApiType.LineAndArrow) {
			inserted = await addInsertableLine(item.svg);
		}

		if (item.type === InsertableApiType.Flaticon) {
			inserted = await addInsertableFlaticon(item);
		}

		if (item.type === InsertableApiType.ImageMask) {
			inserted = await addInsertableImageMask(item);
		}

		if (item.type === InsertableApiType.Sticker) {
			inserted = await addInsertableSticker(item);
		}

		if (item.type === InsertableApiType.Storyset) {
			inserted = await addInsertableStoryset(item.src);
		}

		if (item.type === InsertableApiType.Image) {
			inserted = await addInsertableImage(item);
		}

		if (item.type === InsertableApiType.Video) {
			inserted = await addInsertableVideo(item);
		}

		runOnMobile(() => (store.activePanel = null));

		return inserted;
	};

	const addInsertableVideo = async (apiData: VideoApi | UploadApi) => {
		// Puede ser que no tengamos el archivo real aún en el servidor disponible y lo tengamos como blob en la store,
		// así que si lo tenemos en la store vamos a pasar el blob para crear el video correctamente y después vamos a restaurar la original
		let originalUrl;
		if (apiData) {
			originalUrl = apiData.url;
			apiData.url = store.uploads.get(apiData.id)?.url || apiData.url;
		}
		const video = await Video.fromApi(apiData);
		video.url = originalUrl || video.url;

		// Used to replace placeholder image on drag over mask
		video.metadata.imageApi = { ...apiData, data: [], links: [] };
		delete video.metadata.imageApi.metadata;
		temporalRef.value = video;
		addElement(video);

		setupElementInPage(video);

		usingTransformOrchestrator.value.fitSizeToPixelGrid(video.size);

		setSelection(video, false);
		await nextTick();
		// Bugsnag.leaveBreadcrumb(`Add image to canvas from ${provider}; idProvider: ${apiData.id}; video-${video.id}}`);
		GAnalytics.track('click', 'add-image', `${apiData.origin ? apiData.origin : 'unknown provider'}`, null);
		GAnalytics.trackGA4('add_to_canvas', { category: 'video' });

		return video;
	};

	const allowInsertPremiumResource = (mediaApi: ImageApi | VideoApi | UploadApi): boolean => {
		const isPremiumResource = mediaApi.isPremium;

		const isPremiumUser = isSlidesgoMode.value
			? !!(isSlidesgoPremium.value || user.value?.freepikPremium)
			: !!user.value?.freepikPremium;

		return !isPremiumResource || (isPremiumUser && isPremiumResource);
	};

	return {
		addInsertableShape,
		addInsertableFlaticon,
		addInsertableSticker,
		addInsertableStoryset,
		addInsertableImageMask,
		addInsertableBasicShape,
		addInsertableImage,
		addInsertableLine,
		addInsertableText,
		allowInsertPremiumResource,
		addInsertablePredefinedText,
		addInsertableAIText,
		addInsertableElement,
		addInsertableVideo,
		setupElementInPage,
		addInsertablePasteText,
	};
};
