import {
	breakpointsTailwind,
	createSharedComposable,
	useBreakpoints,
	useEventListener,
	useMediaQuery,
	useWindowSize,
} from '@vueuse/core';
import { computed, ref } from 'vue';

// Constants
const BROWSERS_SUPPORTED_VERSIONS = {
	CHROME: 90,
	FIREFOX: 90,
	SAFARI: 14,
};

export const useDeviceInfo = createSharedComposable(() => {
	let watchingKeyboard = false;
	const initialHeight = ref(window.visualViewport?.height || 0);
	const currentHeight = ref(window.visualViewport?.height || 0);

	const breakpoints = useBreakpoints(breakpointsTailwind);

	const { width } = useWindowSize();

	const isMobile = breakpoints.smaller('lg');
	const isTouch = useMediaQuery('(pointer: coarse)');
	const isXL = computed(() => width.value >= 1920);
	const isIOS = computed(
		() =>
			['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
			['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].some((agent) =>
				navigator.userAgent.includes(agent)
			)
	);
	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	const isMac = computed(() => navigator.userAgent.includes('Macintosh'));
	const isWebview = computed(() => {
		const searchParams = new URLSearchParams(window.location.search);
		const { userAgent } = window.navigator;

		return (
			searchParams.get('webview') !== null || userAgent.includes('WepikApp/Webview') || userAgent.includes('FreepikApp')
		);
	});

	const isFreepikApp = computed(() => {
		const { userAgent } = window.navigator;
		return userAgent.includes('FreepikApp');
	});

	const webviewVersion = computed(() => {
		const { userAgent } = window.navigator;
		const version =
			userAgent
				.split(' ')
				.find((word: string) => word.includes('WepikApp'))
				?.replace('WepikApp/Webview', '')
				.replace('-', '') || '0';

		return Number(version);
	});
	const isOldApp = computed(() => {
		return isWebview.value && webviewVersion.value === 0 && !isFreepikApp.value;
	});
	const isFirefox = navigator.userAgent.indexOf('Firefox') > 0;
	const isAndroid = navigator.userAgent.indexOf('Android') > 0;

	const runOnMobile = (callback: () => void) => {
		if (!isMobile.value) return;

		callback();
	};

	const isOldBrowser = computed(() => {
		if (process.env.NODE_ENV === 'development') return false;
		const { userAgent } = navigator;
		let version;

		if (userAgent.includes('Chrome/')) {
			version = parseFloat(userAgent.match(/Chrome\/(\d+)/)?.[1] ?? '0');
			if (version <= BROWSERS_SUPPORTED_VERSIONS.CHROME) {
				return true;
			}
		} else if (userAgent.includes('CriOS/')) {
			version = parseFloat(userAgent.match(/CriOS\/(\d+)/)?.[1] ?? '0');
			if (version <= BROWSERS_SUPPORTED_VERSIONS.CHROME) {
				return true;
			}
		} else if (userAgent.includes('Safari/')) {
			version = parseFloat(userAgent.match(/Version\/(\d+)/)?.[1] ?? '0');
			if (version <= BROWSERS_SUPPORTED_VERSIONS.SAFARI) {
				return true;
			}
		} else if (userAgent.includes('Firefox/')) {
			version = parseFloat(userAgent.match(/Firefox\/(\d+)/)?.[1] ?? '0');
			if (version <= BROWSERS_SUPPORTED_VERSIONS.FIREFOX) {
				return true;
			}
		}
		return false;
	});

	const isKeyboardOpen = computed(() => {
		if (!isMobile.value || !window.visualViewport) {
			return false;
		}

		if (!watchingKeyboard) {
			watchingKeyboard = true;

			window.visualViewport.addEventListener('resize', function () {
				// Se le aplica un timeout para que le dé tiempo a la animación nativa del dispositivo
				// a mostrar el teclado para posteriormente recalcular el alto
				setTimeout(() => {
					currentHeight.value = window.visualViewport?.height || 0;
				}, 100);
			});

			useEventListener('resize', () => {
				if (!window.visualViewport) return;
				initialHeight.value = window.visualViewport.height;
				currentHeight.value = window.visualViewport.height;
			});
		}

		return initialHeight.value !== currentHeight.value;
	});

	const keyboardHeight = computed(() => {
		if (!isKeyboardOpen.value || !window.visualViewport) return 0;

		return Math.abs(initialHeight.value - currentHeight.value - window.visualViewport.offsetTop);
	});

	const heightWithoutKeyboard = computed(() => {
		return currentHeight.value;
	});

	return {
		isMobile,
		isTouch,
		isXL,
		isIOS,
		isWebview,
		webviewVersion,
		isOldApp,
		isOldBrowser,
		isFirefox,
		runOnMobile,
		isKeyboardOpen,
		keyboardHeight,
		heightWithoutKeyboard,
		isSafari,
		isAndroid,
		isMac,
		isFreepikApp,
	};
});
