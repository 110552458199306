import { createEventHook, createSharedComposable, until, useDebounceFn } from '@vueuse/core';
import { computed, ref, shallowRef, watch } from 'vue';
import { POSITION } from 'vue-toastification';

import { getUserAiWriters, removeAIWriterAnswer } from '@/api/UserApiClient';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useEnvSettings } from '@/common/composables/useEnvSettings';
import { useToast } from '@/common/composables/useToast';
import { useMainStore } from '@/editor/stores/store';
import { Text } from '@/elements/texts/text/classes/Text';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { useI18n } from '@/i18n/useI18n';
import { useSelection } from '@/interactions/composables/useSelection';
import { useProjectStore } from '@/project/stores/project';
import { AIWriterAnswer } from '@/Types/apiClient';
import { EditPanels } from '@/Types/types';

export const useAiWriter = createSharedComposable(() => {
	const store = useMainStore();
	const { selection, clearSelection } = useSelection();

	const project = useProjectStore();
	const toast = useToast();
	const { trans } = useI18n();
	const { APP_API_PATH } = useEnvSettings();
	const { isMobile } = useDeviceInfo();
	const textAI = shallowRef<Text>();
	const discardText = createEventHook();
	const content = ref('');
	const prevContent = ref('');
	const prompt = ref('');
	const warning = ref('');
	const error = ref(false);

	watch(content, (value) => {
		if ((!value.includes('[ERROR]') && !value.includes('[WARNING]')) || error.value) return;
		error.value = true;
		generatingContent.value = false;

		content.value = prevContent.value || 'No content';
		toast.error(`AI text generation failed.`, {
			position: (isMobile.value ? 'top-center' : 'bottom-right') as POSITION,
		});
	});

	const generatingContent = ref(false);
	const hasAIResponse = computed(() => textAI.value?.content !== content.value);

	const generateContent = (visible = false) => {
		if (!textAI.value) return;
		generatingContent.value = true;
		warning.value = '';

		let originalText = '';
		if (!hasAIResponse.value) {
			const originalContent = document.createElement('div');
			originalContent.innerHTML = textAI.value.content;
			originalText = originalContent.innerText;
			originalContent.remove();
		}

		const eventSource = new EventSource(
			`${APP_API_PATH}writer-ai?prompt=${prompt.value}&content=${
				hasAIResponse.value ? content.value : originalText
			}&user_vector_id=${project.id}&visible=${visible}`
		);

		eventSource.onopen = () => {
			prevContent.value = content.value;
			content.value = '';
		};

		const currentMessageId = ref(0);
		const closeEventSource = () => {
			eventSource.close();
			currentMessageId.value = 0;
			if (error.value) {
				error.value = false;
			}
		};
		const finishGenerate = useDebounceFn(() => {
			generatingContent.value = false;
			content.value = TextTools.removeMetaTags(content.value);
			warning.value = TextTools.removeMetaTags(warning.value);

			if (error.value) {
				return;
			}
			if (window.localStorage.getItem('first-ai-copy')) {
				return;
			}

			window.localStorage.setItem('first-ai-copy', 'true');
			toast.success(trans('Congratulations! You now have AI-generated copy.'), {
				position: (isMobile.value ? 'top-center' : 'bottom-right') as POSITION,
			});
		}, 300);

		eventSource.addEventListener('message', async (event) => {
			// Esperamos a que se escriban todos los mensajes anteriores para añadirlos en orden
			await until(currentMessageId).toBe(parseInt(event.lastEventId));
			const data = event.data.replaceAll('[LINE-BREAK]', '<div><br/></div>');
			if (!error.value) {
				content.value += data;
				content.value = TextTools.removeMetaTags(content.value);
			} else {
				warning.value += data;
				warning.value = TextTools.removeMetaTags(warning.value);
			}
			currentMessageId.value++;

			finishGenerate();
		});

		eventSource.addEventListener('finish', closeEventSource);
		eventSource.addEventListener('error', () => {
			error.value = true;
			closeEventSource();
			generatingContent.value = false;
			toast.error(trans('Sorry, but AI generation has failed. Please try again.'), {
				position: (isMobile.value ? 'top-center' : 'bottom-right') as POSITION,
			});
		});
	};

	const confirmChange = () => {
		if (!textAI.value) return;
		textAI.value.content = content.value;
	};

	const openAIWriter = (text: Text) => {
		textAI.value = text;
		warning.value = '';
		content.value = text.content;
		document.body.classList.add('ai-writer-open');
		if (isMobile.value) {
			store.editPanel = EditPanels.WriterAI;
		}
	};

	const closeAIWriter = (clickOutside = false) => {
		textAI.value = undefined;
		content.value = '';
		prompt.value = '';
		document.body.classList.remove('ai-writer-open');

		const [firstElementSelected] = selection.value;

		if (isMobile.value && firstElementSelected instanceof Text) {
			if (clickOutside) {
				clearSelection();
				return;
			}
			store.editPanel = EditPanels.Text;
		}
	};

	/**
	 * hace una petición para borrar el texto IA generado por el usuario
	 * @param aiWiterID Id del texto IA generado por el usuario
	 * @returns
	 */
	const removeUserAiWriterText = async (aiWiterID: string) => {
		if (!aiWiterID) return;

		const data = await getAnswers();
		if (!data) return;
		try {
			const { statusCode, error } = await removeAIWriterAnswer(aiWiterID);

			if (statusCode.value === 404) {
				return;
			}

			if (statusCode.value === 200) {
				discardText.trigger(data);
				toast.success(trans('Your AI-generated response has been successfully deleted'));
			}

			if (error.value) throw new Error();
		} catch (e) {
			toast.error(trans('Your AI-generated could not be deleted'));
		}
	};
	const onDiscardText = discardText.on;
	const getAnswers = async (): Promise<AIWriterAnswer | undefined> => {
		try {
			const { data, error } = await getUserAiWriters();

			if (error.value || !data.value) {
				throw new Error();
			}

			return data.value;
		} catch (e) {
			console.error('Could not get AI-generated answers');
		}
	};

	watch(selection, (selection, prevSelection) => {
		if (!prevSelection.length || !textAI.value) {
			return;
		}

		closeAIWriter();
	});

	const enabled = computed(() => {
		return true;
	});

	return {
		removeUserAiWriterText,
		error,
		textAI,
		content,
		prompt,
		hasAIResponse,
		getAnswers,
		generatingContent,
		confirmChange,
		onDiscardText,
		generateContent,
		openAIWriter,
		closeAIWriter,
		warning,
		enabled,
	};
});
