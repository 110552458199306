type Seconds = number;
type Milliseconds = number;
export class TimeTools {
	static formatMillisecondsToMMSS = (milliseconds: Milliseconds) =>
		new Date(milliseconds).toISOString().substring(14, 19);
	static formatSecondsToMMSS = (seconds: Seconds) => TimeTools.formatMillisecondsToMMSS(TimeTools.toMS(seconds));
	static toMS = (seconds: Seconds) => seconds * 1000;
	static toSeconds = (milliseconds: Milliseconds) => milliseconds / 1000;
	static toMinutes = (milliseconds: Milliseconds) => TimeTools.toSeconds(milliseconds) / 60;

	static MMSSFormatToMs = (mmss: string) => {
		const [hours, minutes, seconds] = mmss.split(':').map(Number);
		const totalMilliseconds = TimeTools.toMS(hours * 3600 + minutes * 60 + seconds);
		return totalMilliseconds;
	};
}
