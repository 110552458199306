/* eslint-disable no-async-promise-executor */
/* eslint-disable no-case-declarations */
import {v4 as uuidv4} from 'uuid';
import {type Ref, ref} from 'vue';

import {CanvasUtils} from '@/apps/mockup/classes/CanvasUtils';
import {GroupLayer} from '@/apps/mockup/classes/Layers/GroupLayer';
import {LayerCanvas} from '@/apps/mockup/classes/Layers/Layer';
import {useErrorMockup} from '@/apps/mockup/composable/useErrorMockup';
import compose from '@/apps/mockup/composition/compose';
import {useMockupStore} from '@/apps/mockup/mockupStore';
import {
	CompositingSize,
	LayerType,
	MockupElement,
	MockupElementTexture,
	MockupManifest,
	MockupStoreSettings,
} from '@/apps/mockup/schemas';
import {MockupErrorType} from '@/apps/mockup/Types/errorTypes';
import {BLEEDING_VALUE} from '@/apps/mockup/utils/CompositionTools';
import {LayersComposition} from '@/apps/mockup/utils/LayersComposition';
import MemoryUssage from "@/apps/mockup/facades/MemoryUssage";

export const SizeComposition = ref({
	width: 0,
	height: 0,
});

export const useComposite25D = () => {
	const {registerMockupError} = useErrorMockup();

	const actualCompositionSize: Ref<CompositingSize | undefined> = ref(undefined);
	const MockupStore = useMockupStore();

	/**
	 * Init process to compositing a Mockup
	 * @param {MockupManifest} Mockup
	 * @returns {URL} Blob from composition
	 */
	const CreateMockup = (Mockup: MockupManifest, compositionSize: CompositingSize): HTMLCanvasElement => {
		actualCompositionSize.value = compositionSize;
		MockupStore.loading = true;
		const start = performance.now();
		const result = buildCanvas(Mockup);
		const end = performance.now();
		console.info('Render Time', end - start, 'ms');
		if (MockupStore.settings.get(MockupStoreSettings.ViewMemory)) MemoryUssage()
		MockupStore.loading = false;
		return result;
	};

	/**
	 * This function create Canvas to build composition of multiple Layers in Mockup
	 * @param Mockup Manifest mockup
	 * @returns Composition url Blob
	 */
	const buildCanvas = (Mockup: MockupManifest): HTMLCanvasElement => {
		SizeComposition.value = {
			width: Math.round(Mockup.size.width),
			height: Math.round(Mockup.size.height),
		};
		const canvasMockup: HTMLCanvasElement = document.createElement('canvas') as HTMLCanvasElement;
		canvasMockup.width = SizeComposition.value.width;
		canvasMockup.height = SizeComposition.value.height;
		const contextMockup: CanvasRenderingContext2D = canvasMockup.getContext('2d') as CanvasRenderingContext2D;

		if (MockupStore.settings.get(MockupStoreSettings.DebugMode))
			console.log(`%cInit render composición ${Mockup.size.width}`, 'color: #0000ff; font-size: 14px');

		compose().clear();
		compose().setSize({width: SizeComposition.value.width, height: SizeComposition.value.height});

		for (const [index, child] of Mockup.children.entries()) {
			if (child.blendMode == 'passThrough') {
				const childToIterate: GroupLayer = child as GroupLayer;
				passThroughVirtual(childToIterate, Mockup.children, index);
			} else {
				processDecision(child as any, contextMockup);
			}
		}
		const result = compose().getResult();
		compose().release()
		return result;
	};

	/**
	 * Function generate composition in Canvas Context to some Layer according to LayerKind is Layer Type
	 *
	 * @param {LayerCanvas} layer
	 * @param {CanvasRenderingContext2D} contextMockup
	 * @param {boolean} reportChange Flag to alert to father if your child has change, used to Group Layer
	 * @returns {ColorLayer | GroupLayer | ImageLayer | SmartObjectLayer}
	 */
	const processDecision = (
		layer: LayerCanvas,
		contextMockup: CanvasRenderingContext2D,
		reportChange = false
	) => {
		try {
			if (!layer.visible || layer.opacity == 0) return;

			if (layer.meta && layer.meta.extra.fillOpacity == 0) {
				console.warn('Layer fillOpacity is 0', layer.name);
				return;
			} // If fillOpacity is 0, we don't draw the layer

			if (!layerInCurrentMode(layer)) return;

			const {ColorLayerComposition, ImageLayerComposition, GroupLayerComposition, SmartObjectLayerComposition} =
				LayersComposition(actualCompositionSize, layer, contextMockup, reportChange, SizeComposition, processDecision);

			const dictionaryProcess: { [key in LayerType]: CallableFunction } = {
				[LayerType.solidColor]: ColorLayerComposition,
				[LayerType.image]: ImageLayerComposition,
				[LayerType.group]: GroupLayerComposition,
				[LayerType.smartObject]: SmartObjectLayerComposition,
			};
			if (dictionaryProcess[layer.kind] === undefined) {
				console.warn('Layer kind not found', layer.kind);
				return;
			}
			dictionaryProcess[layer.kind]();
			if (MockupStore.settings.get(MockupStoreSettings.DebugMode)) {
				console.log(`Layer ${layer.name} [${layer.kind}] [${layer.blendMode}]=>`);
				console.log(compose().counter, CanvasUtils.canvasToBlob(compose().getResult()));
			}
		} catch (error) {
			console.error('Error in processDecission', error);
			registerMockupError(MockupErrorType.compositionError);
		}
	};

	/**
	 * Function to validate if Layer is linked to a MockupElement
	 * And if this MockupElement is in currentMode
	 *
	 * @param {LayerCanvas} layer
	 * @returns {boolean}
	 */
	const layerInCurrentMode = (layer: LayerCanvas): boolean => {
		const element = MockupStore.getAllElements.find((el) => el.relatedLayerId.includes(layer.id));
		if (!element) return true;

		return element.visibilityMode!.includes(MockupStore.editingMode);
	};

	/**
	 * We implement to replicate blendmode Pass Through of Photoshop
	 * This function validates if a GroupLayer if contains LayerMasks to apply this maks to their children
	 * In case if their child have a mask, we make a new Group virtual to apply this mask of father to new Group
	 * @param {GroupLayer} child
	 * @param {Array<LayerCanvas | []>} father
	 * @param index Actual iteration index
	 */
	const passThroughVirtual = (child: GroupLayer, father: Array<any>, index: number) => {
		if (child.children.length !== 0) {
			const mask = child.mask ?? undefined;
			child.children.reverse();
			for (const grandChild of child.children) {
				if (mask) {
					if (grandChild.mask) {
						//In this case ass new Group Layer to can apply self maks and father mask in passThrough Group
						const baseLayer = {...grandChild} as LayerCanvas;
						const newChildLayer = grandChild;
						newChildLayer.blendMode = 'normal';
						newChildLayer.opacity = '1';
						const newGroup = new GroupLayer(baseLayer, [newChildLayer], processDecision);
						newGroup.id = 'Temporal-' + uuidv4();
						newGroup.name = `Temporal Group of ${newChildLayer.name}`;
						newGroup.kind = LayerType.group;
						mask ? (newGroup.mask = mask) : null;
						father.splice(index + 1, 0, newGroup);
					} else {
						grandChild.mask = mask;
						father.splice(index + 1, 0, grandChild);
					}
				} else {
					father.splice(index + 1, 0, grandChild);
				}
			}
			child.children = [];
		}
	};

	/**
	 * In this function we build Canvas to put in image preview in a specific COORDS and sizes and return Canvas
	 * @param {HTMLCanvasElement} canvas
	 * @param {MockupElement} mockupElement
	 * @param {number} resolution expected resolution
	 * @returns {HTMLCanvasElement}
	 */
	const generateTextureCanvas = async (
		canvas: HTMLCanvasElement,
		mockupElement: MockupElementTexture | null = null,
		resolution = 1000
	): Promise<HTMLCanvasElement> => {
		return new Promise(async (resolve) => {
			const mockup = useMockupStore();
			let activeElement: MockupElementTexture;

			if (mockupElement) {
				activeElement = mockupElement;
			} else {
				activeElement = mockup.activeElement as MockupElementTexture;
			}

			const canvasWithoutBleed = removeBleeding(canvas);

			//New canvas
			const canvasTexture = document.createElement('canvas') as HTMLCanvasElement;
			canvasTexture.width = resolution;
			canvasTexture.height = resolution;
			const ctxCanvas = canvasTexture.getContext('2d');

			ctxCanvas?.drawImage(
				canvasWithoutBleed,
				(activeElement?.frame?.x as number) * resolution,
				(activeElement?.frame?.y as number) * resolution
			);
			resolve(canvasTexture);
		});
	};

	const removeBleeding = (sourceCanvas: HTMLCanvasElement): HTMLCanvasElement => {
		if (MockupStore.getIsStandardMode) {
			return sourceCanvas;
		}
		const canvasWithoutBleed = document.createElement('canvas') as HTMLCanvasElement;
		canvasWithoutBleed.width = sourceCanvas.width - BLEEDING_VALUE;
		canvasWithoutBleed.height = sourceCanvas.height - BLEEDING_VALUE;
		const ctxCanvas2 = canvasWithoutBleed.getContext('2d');

		//Calculate bleeding values according relation with Width and Height dimension
		const maxDimension = Math.max(sourceCanvas.width, sourceCanvas.height);
		const bleedingWidth = (sourceCanvas.width / maxDimension) * (BLEEDING_VALUE / 2);
		const bleedingHeight = (sourceCanvas.height / maxDimension) * (BLEEDING_VALUE / 2);

		ctxCanvas2?.drawImage(
			sourceCanvas,
			bleedingWidth,
			bleedingHeight,
			canvasWithoutBleed.width,
			canvasWithoutBleed.height,
			0,
			0,
			canvasWithoutBleed.width,
			canvasWithoutBleed.height
		);

		return canvasWithoutBleed;
	};

	return {
		CreateMockup,
		buildCanvas,
		processDecission: processDecision,
		passThroughVirtual,
		generateTextureCanvas,
	};
};
