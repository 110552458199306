import { createSharedComposable } from '@vueuse/core';

import { ApiClient } from '@/api/utils';
import { useEcho } from '@/common/composables/useEcho';
import { useEnvSettings } from '@/common/composables/useEnvSettings';
import { TrackedImageJob, TrackedJobId, UseTrackJobResponse } from '@/Types/types';

export const useTracksJob = createSharedComposable(() => {
	const { onTrackedJobReady } = useEcho();
	const { APP_API_PATH } = useEnvSettings();

	const trackJob = async (
		uuid: TrackedJobId,
		timeoutInMs: number,
		httpCheckInterval: number
	): Promise<UseTrackJobResponse<TrackedImageJob>> => {
		let interval: any = null;
		let timeout: any = null;

		const rejectByTimeout = new Promise<object>((resolve, reject) => {
			timeout = setTimeout(
				() => {
					reject();
					interval && clearInterval(interval);
				},
				timeoutInMs,
				'timeout'
			);
		});

		const readyByHttp = new Promise<object>((resolve, reject) => {
			interval = setInterval(() => {
				ApiClient.request(`${APP_API_PATH}job/${uuid}/status`, {
					headers: {
						Accept: 'application/json',
					},
				})
					.then((response) => {
						if (response.status === 'finished') {
							resolve(response.data);
							clearInterval(interval);
						}

						if (response.status === 'failed') {
							reject({ backendError: response.error });
							clearInterval(interval);
						}
					})
					.catch(reject);
			}, httpCheckInterval);
		});

		const readyBySocketEvent = new Promise<object>((resolve, reject) => {
			onTrackedJobReady(uuid)
				.then(resolve)
				.catch((error) => {
					// En el caso de un error de tracked job
					if (error && error.failed) {
						reject({ backendError: error.error });
						return;
					}

					// En caso de un error de conexión
					if (error && error.message && error.code) {
						return;
					}

					reject(error);
				});
		});

		// Tenemos tres opciones:
		// 1. Que el job esté listo por el websocket
		// 2. Que el job esté listo por el http check
		// 3. Que el job no esté listo y se termine el timeout
		// El promise que primero se complete será el que resuelva el promise que devolvemos.
		// En cualquiera de los casos al terminar limpiamos los intervalos y timeouts antes de devolver el resultado
		return await Promise.race<object>([readyBySocketEvent, readyByHttp, rejectByTimeout]).finally(() => {
			interval && clearInterval(interval);
			timeout && clearTimeout(timeout);
		});
	};
	return {
		trackJob,
	};
});
