import Bugsnag from '@bugsnag/js';
import { createFetch } from '@vueuse/core';
import { v4 as uuidv4 } from 'uuid';

import { useAuth } from '@/auth/composables/useAuth';
import { useEnvSettings } from '@/common/composables/useEnvSettings';
import { useSessionInfo } from '@/common/composables/useSessionInfo';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { useI18n } from '@/i18n/useI18n';
const { APP_API_PATH, APP_API_VERSION } = useEnvSettings();

export const useEditorApiFetch = createFetch({
	baseUrl: APP_API_PATH,
	options: {
		timeout: 60000,
		beforeFetch(ctx) {
			const { options, url } = ctx;
			const customHeaders = options.headers ? new Headers(options.headers) : new Headers();

			customHeaders.append('X-Requested-With', APP_API_VERSION);

			const xsrf = decodeURIComponent(`; ${document.cookie}`.split(`; XSRF-TOKEN=`).pop()?.split(';')[0] || '');

			// Session Token
			if (xsrf) {
				customHeaders.append('X-XSRF-TOKEN', xsrf);
			}

			customHeaders.append('Accept', 'application/json');
			let newUrl = url;

			if (TextTools.isValidUrl(url)) {
				const { locale } = useI18n();
				const urlParser = new URL(url);

				urlParser.searchParams.set('lang', locale.value);
				urlParser.searchParams.set('cacheBuster', '6');

				const monitoredUrls = ['api/user/vector', 'v2/vector/export'];

				if (monitoredUrls.some((monitoredUrl) => url.includes(monitoredUrl))) {
					urlParser.searchParams.set('editorSessionId', useSessionInfo().uuid);
					urlParser.searchParams.set('requestUuid', uuidv4());
				}

				newUrl = urlParser.toString();
			}

			options.headers = customHeaders;
			options.credentials = 'include';

			return {
				...ctx,
				options,
				url: newUrl.toString(),
			};
		},
		onFetchError(ctx) {
			if (ctx.response?.status !== 401) {
				Bugsnag.leaveBreadcrumb('API Error', ctx);
			}

			const { checkLoginStatus } = useAuth();
			const inIgnoredUrls = ['auth/verify', 'check-api-key'].find((url) => ctx.response?.url.includes(url));

			if ([401, 419].includes(ctx.response?.status || 0) && !inIgnoredUrls) {
				checkLoginStatus();
			}

			ctx.error.status = ctx.response?.status;
			if (!(ctx.error instanceof DOMException && ctx.error.name === 'AbortError')) {
				ctx.error.data = ctx.data;
			}

			if (ctx.response && ctx.response.status === 426 && !window.reloadingInProgress) {
				window.reloadingInProgress = true;
				window.location.reload();
			}

			return ctx;
		},
	},
});
