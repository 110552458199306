import Bugsnag from '@bugsnag/js';
import { getIndexBetween } from '@tldraw/indices';
import { Ref, ref } from 'vue';

import { useCollisionInfoOrchestator } from '@/collision/composables/useCollisionInfoOrchestator';
import Element from '@/elements/element/classes/Element';
import { useGroup } from '@/elements/group/composables/useGroup';
import { Shape } from '@/elements/shapes/shape/classes/Shape';

export const useElementOrder = (element: Ref<Element>) => {
	const temporalRef = ref<Element>(Shape.create());
	const { group } = useGroup(temporalRef);

	const {
		canGoUp,
		canGoDown,
		collisioningSortedElementsOver,
		collisioningSortedElementsUnder,
		elementIndex,
		firstPosition,
		lastPosition,
		page,
	} = useCollisionInfoOrchestator(element).value;

	const moveElementBetweenIndex = (below: Element | undefined, above: Element | undefined) => {
		// la firma del método getIndexBetween(librería) viene como (below: string, above: string) pero en realidad el método que llama por debajo su firma
		// es (below: string | undefined, above: string | undefined) y nos permite colocarlo en la posición 0 o en la última posición de la página, de ahí el as string
		const indexToMove = getIndexBetween(below?.index as string, above?.index);

		if (indexToMove === element.value.index) return;
		if (indexToMove < element.value.index && !canGoDown.value && !element.value.group) return;
		if (indexToMove > element.value.index && !canGoUp.value && !element.value.group) return;

		element.value.index = indexToMove;
	};

	const moveUp = () => {
		if (!canGoUp.value) return;
		Bugsnag.leaveBreadcrumb(`move ${element.value.type}-${element.value.id} Forward`);

		const elements = page.value.elementsAsArray();

		if (collisioningSortedElementsOver.value[0].group) {
			temporalRef.value = collisioningSortedElementsOver.value[0];
			const index = elements.findIndex((el) => el.id === group.value[group.value.length - 1].id);
			moveElementBetweenIndex(elements[index], elements[index + 1]);
			return;
		}
		const index = elements.findIndex((el) => el.id === collisioningSortedElementsOver.value[0].id);
		moveElementBetweenIndex(elements[index], elements[index + 1]);
	};

	const moveDown = () => {
		if (!canGoDown.value) return;
		Bugsnag.leaveBreadcrumb(`move ${element.value.type}-${element.value.id} Backward`);

		const elements = page.value.elementsAsArray();
		if (collisioningSortedElementsUnder.value[collisioningSortedElementsUnder.value.length - 1].group) {
			temporalRef.value = collisioningSortedElementsUnder.value[collisioningSortedElementsUnder.value.length - 1];

			const index = elements.findIndex((el) => el.id === group.value[0].id);
			moveElementBetweenIndex(elements[index - 1], elements[index]);
			return;
		}
		const index = elements.findIndex((el) => el.id === collisioningSortedElementsUnder.value[0].id);
		moveElementBetweenIndex(elements[index - 1], elements[index]);
	};

	const moveToBack = () => {
		const elements = page.value.elementsAsArray();
		// Si tiene firstPosition es que tiene un elemento como background la page, por lo que queremos moverlo
		// entre ese background y el siguiente elemento y no entre ningún elemento y el primero.
		moveElementBetweenIndex(firstPosition.value ? elements[0] : undefined, elements[firstPosition.value]);
		Bugsnag.leaveBreadcrumb(`move ${element.value.type}-${element.value.id} To Back`);
	};

	const moveToFront = () => {
		moveElementBetweenIndex(page.value.elementsAsArray()[lastPosition.value], undefined);
		Bugsnag.leaveBreadcrumb(`move ${element.value.type}-${element.value.id} To Front`);
	};

	return {
		canGoUp,
		canGoDown,
		elementIndex,
		firstPosition,
		lastPosition,
		moveElementBetweenIndex,
		moveUp,
		moveDown,
		moveToBack,
		moveToFront,
	};
};
