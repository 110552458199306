import { useDebounceFn } from '@vueuse/shared';
import { cloneDeep, groupBy } from 'lodash-es';
import { computed, Ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import { useVariantsColors } from '@/color/composables/useVariantsColors';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import { Color } from '@/Types/colorsTypes';

export const useShapeColors = (shapeRef: Ref<Shape>) => {
	const colors = computed(() => shapeRef.value.colors);
	const { isAdminMode } = useEditorMode();

	const { isApplyingVariantColors } = useVariantsColors();

	const solidColors = computed(() => colors.value.filter((color) => color instanceof SolidColor));
	const gradientColors = computed(() => colors.value.filter((color) => color instanceof GradientColor));

	const trackAnalyticsDebounced = useDebounceFn((color1: Color, color2: Color) => {
		trackChangeColor(color1, color2);
	}, 300);
	const trackChangeColor = (color1: Color, color2: Color) => {
		// En caso de cambio de solido a solido
		if (color1 instanceof SolidColor && color2 instanceof SolidColor) {
			if (color1.r === color2.r && color1.g === color2.g && color1.b === color2.b && color1.a !== color2.a) {
				GAnalytics.track('click', 'Button', 'change-element-opacity', null);
			} else if (color1.r !== color2.r || color1.g !== color2.g || color1.b !== color2.b) {
				GAnalytics.track('click', 'Button', 'change-element-color', null);
			}
		}

		// En caso de cambio de solido a Gradiente
		if (
			(color1 instanceof SolidColor && color2 instanceof GradientColor) ||
			(color1 instanceof GradientColor && color2 instanceof SolidColor)
		) {
			GAnalytics.track('click', 'Button', 'change-element-color', null);
		}

		// En caso de cambio de gradiente a Gradiente
		if (color1 instanceof GradientColor && color2 instanceof GradientColor) {
			const oldColors = color1.stops.map((c) => {
				return { r: c.r, g: c.g, b: c.b, a: c.a, offset: c.offset };
			});

			const newColors = color2.stops.map((c) => {
				return { r: c.r, g: c.g, b: c.b, a: c.a, offset: c.offset };
			});

			// En caso de tener menos colores
			if (oldColors.length < newColors.length) {
				GAnalytics.track('click', 'Button', 'change-element-color', null);
			} else {
				// En otro caso si se ha modificado cualquiera de los colores de las paradas del gradiente
				oldColors.forEach((c) => {
					newColors.forEach((nc) => {
						if (nc.r === c.r && nc.g === c.g && nc.b === c.b && nc.a !== c.a && nc.offset === c.offset) {
							GAnalytics.track('click', 'Button', 'change-element-opacity', null);
						} else if ((nc.r !== c.r || nc.g !== c.g || nc.b !== c.b) && nc.offset === c.offset) {
							GAnalytics.track('click', 'Button', 'change-element-color', null);
						}
					});
				});
			}
		}
	};

	const updateColor = (oldColor: Color, newColor: Color): Shape => {
		const cloneNewColor = cloneDeep(newColor);
		cloneNewColor.id = oldColor.id;

		// Si no se está actualizando el la variante de color del template trackeamos el color
		if (!isApplyingVariantColors.value) {
			trackAnalyticsDebounced(oldColor, newColor);
		}

		const isUpdatingGradient =
			oldColor instanceof GradientColor &&
			cloneNewColor instanceof GradientColor &&
			oldColor.id === cloneNewColor.id &&
			oldColor.rotation === cloneNewColor.rotation;

		if (isUpdatingGradient) {
			const gradientToChange = shapeRef.value.colors.find(
				(gradient) => gradient.toCssString() === oldColor.toCssString()
			) as GradientColor | null;

			if (gradientToChange && newColor instanceof GradientColor) {
				(gradientToChange as GradientColor).stops = [...cloneNewColor.stops];
				gradientToChange.type = newColor.type;
			}
		} else {
			const indexColor = colors.value.findIndex(
				(c) => c.toCssString() === oldColor.toCssString() && c.id == oldColor.id
			);

			shapeRef.value.colors[indexColor] = cloneNewColor;
		}

		// Si estamos en modo admin mergeamos los colores que son iguales
		if (isAdminMode.value) {
			const tempHtmlInstance = shapeRef.value.htmlInstance();
			const groupColors = groupBy(shapeRef.value.colors, (c) => c.toCssString());

			shapeRef.value.colors = Object.values(groupColors).map((cGroup: Color[]) => {
				const colors = cGroup;
				const firstColor = colors.shift() as Color;

				if (colors.length > 0) {
					const colorsId = colors.map((c) => c.id);
					const selector = colors.map((c) => `[style*="var(--${c.id})"]`).join(',');

					// Sustituimos los ids para que usen el mismo color y no salgan repetidos
					tempHtmlInstance.querySelectorAll<HTMLElement>(selector).forEach((el) => {
						const strokeId = (el.style.stroke || '').replace('var(--', '').replace(')', '');
						const fillId = (el.style.fill || '').replace('var(--', '').replace(')', '');

						if (colorsId.includes(strokeId)) {
							el.style.stroke = `var(--${firstColor.id})`;
						}

						if (colorsId.includes(fillId)) {
							el.style.fill = `var(--${firstColor.id})`;
						}
					});
				}

				return firstColor;
			});
			shapeRef.value.content = (tempHtmlInstance.body.firstElementChild as HTMLElement).innerHTML.toString();
		}
		return shapeRef.value;
	};

	return {
		colors,
		solidColors,
		gradientColors,
		updateColor,
	};
};
