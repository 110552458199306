import { createSharedComposable } from '@vueuse/core';
import { computed, ComputedRef, nextTick, ref } from 'vue';

import { useMainStore } from '@/editor/stores/store';
import Page from '@/page/classes/Page';
import { useProjectPages } from '@/project/composables/useProjectPages';
import { useProjectStore } from '@/project/stores/project';

export const useCanvasNavigation = createSharedComposable(() => {
	const selectedPages = ref<Page[]>([]);
	const copiedPages = ref<Page[]>([]);
	const menuOpened = ref<string>('');
	const project = useProjectStore();
	const { canMoveDown, canMoveUp, movePage, findPosition, copyPages, MAX_PAGES } = useProjectPages();
	const store = useMainStore();
	const copiedPagesFromProject = computed(() =>
		project.pages.filter((p) => copiedPages.value.map((copy) => copy.id).includes(p.id))
	) as ComputedRef<Page[]>;
	const copiedPagesexceedLimit = computed(() => project.pages.length + copiedPages.value.length > MAX_PAGES);
	const selectedPagesexceedLimit = computed(() => project.pages.length + selectedPages.value.length > MAX_PAGES);

	const activePageIsCurrentSelection = computed(
		() => store.activePage && selectedPages.value.length === 1 && selectedPages.value[0].id === store.activePageId
	);

	const canMoveRight = computed(() => store.activePage && canMoveDown(store.activePage));
	const canMoveLeft = computed(() => store.activePage && canMoveUp(store.activePage));

	const hasPreviouslySelection = computed(() => selectedPages.value.length > 1);

	const move = (page: Page, direction: 'up' | 'down') => {
		const newIndex = findPosition(page) + (direction === 'down' ? 1 : -1);

		movePage(page, newIndex);
	};

	const moveLeft = () => {
		if (!canMoveLeft.value || !store.activePage) return;

		move(store.activePage, 'up');
	};

	const moveRight = () => {
		if (!canMoveRight.value || !store.activePage) return;

		move(store.activePage, 'down');
	};

	/**
	 * Función que controla el duplicado de páginas, primero duplicamos las páginas y después establecemos la selección de las
	 * nuevas páginas
	 * @param {boolean} fromClipboard Distinguimos si venimos de copiar desde el portapapeles o no
	 */
	const duplicate = async (fromClipboard = false) => {
		await duplicateSelection(fromClipboard);
		await handleSelectionAfterDuplicatePages(fromClipboard);
	};

	/**
	 * Duplicamos las páginas que vengan de una selección o de un copiado
	 */
	const duplicateSelection = async (fromClipboard = false) => {
		const newPages = fromClipboard ? copiedPagesFromProject.value : selectedPages.value;
		await copyPages(newPages, selectedPages.value);
	};

	/**
	 * Función que establece la selección y página activa después de un duplicado
	 *
	 * @returns
	 */
	const handleSelectionAfterDuplicatePages = async (fromClipboard = false) => {
		if (!store.activePage) return;
		// necesitamos un tick para que se agreguen las nuevas páginas copiadas al project
		await nextTick();

		const indexNextToActivePage = project.pages.indexOf(store.activePage) + 1;
		const referencePages = fromClipboard ? copiedPagesFromProject.value : selectedPages.value;

		const lastIndexCopiedPage = activePageIsCurrentSelection.value
			? project.pages.indexOf(store.activePage) + referencePages.length
			: project.pages.indexOf(referencePages[referencePages.length - 1]);

		// Tomamos las páginas de referencia para la próxima selección
		// En el caso de que la selección sea la página activa, tomamos como referencia de inicio la siguiente página
		//  a la página activa, en caso de tener una selección múltiple, esta será la página siguiente a la última de la selección.
		const firstPageReferenceForNewSelection = activePageIsCurrentSelection.value
			? project.pages[indexNextToActivePage]
			: project.pages[lastIndexCopiedPage + 1];

		// en el caso de que la selección sea la página activa, la ultima página como referencia para la nueva selección
		// será la página que se encuentre a una longitud igual al length de la selección a partir de la página activa
		// en caso de que tengamos selección múltiple, tomamos como referencia el indice de la última página copiada mas la longitud
		// de la copiada.
		const lastPageReferenceForNewSelection = activePageIsCurrentSelection.value
			? project.pages[lastIndexCopiedPage]
			: project.pages[lastIndexCopiedPage + referencePages.length];

		// asignamos los indices que corresponden al rango de la selección
		const [firstIndexOfNewPages, lastIndexOfNewPages] = [
			project.pages.indexOf(firstPageReferenceForNewSelection),
			project.pages.indexOf(lastPageReferenceForNewSelection),
		];

		// establecemos la nueva selección y la página activa, que será la última de la selección.
		const newSelection = project.pages.slice(firstIndexOfNewPages, lastIndexOfNewPages + 1) as Page[];
		const nextActivePage = newSelection[newSelection.length - 1];

		selectedPages.value = newSelection;
		store.setActivePage(nextActivePage);

		// Si el usuario realiza múltiples copias,
		copiedPages.value = newSelection;
	};

	/**
	 *
	 * devuelve la selección correspondiente desde la página activa hasta la página seleccionada
	 * @param {Page} selectedPage: Página seleccionada desde la que tomamos referencia
	 * @returns {Page[]}
	 */

	const getPageSelection = (selectedPage: Page): Page[] => {
		const indexOfSelectedPage = project.pages.indexOf(selectedPage);
		const indexActivePage = project.pages.indexOf(store.activePage as Page);

		const isUpwardSelection = indexOfSelectedPage > indexActivePage;

		const selectionRange = getPageRangeSelection(selectedPage, isUpwardSelection);
		const selection = (project.pages as Page[]).slice(selectionRange.initialPosition, selectionRange.lastPosition);

		return selection;
	};

	/**
	 *	Controla la selección dependiendo de la dirección en la que se haga (ascendente o descendente).
	 * @param {boolean} isUpwardSelection: Indica si la selección es ascendente o no
	 * @param {Page} selectedPage: Página seleccionada desde la que tomamos referencia
	 *
	 * @returns { {initialPosition, lastPosition} }
	 */
	const getPageRangeSelection = (
		selectedPage: Page,
		isUpwardSelection: boolean
	): { initialPosition: number; lastPosition: number } => {
		let initialPosition = 0;
		let lastPosition = 0;

		// ? upward
		if (isUpwardSelection && !hasPreviouslySelection.value) {
			initialPosition = project.pages.indexOf(store.activePage as Page);
			lastPosition = project.pages.indexOf(selectedPage) + 1;
		}

		if (isUpwardSelection && hasPreviouslySelection.value) {
			initialPosition = project.pages.indexOf(selectedPages.value[0]);
			lastPosition = project.pages.indexOf(selectedPage) + 1;
		}

		// ? downward
		if (!isUpwardSelection && !hasPreviouslySelection.value) {
			initialPosition = project.pages.indexOf(selectedPage);
			lastPosition = project.pages.indexOf(store.activePage as Page) + 1;
		}

		if (!isUpwardSelection && hasPreviouslySelection.value) {
			initialPosition = project.pages.indexOf(selectedPage);
			lastPosition = project.pages.indexOf(selectedPages.value[selectedPages.value.length - 1]) + 1;
		}

		return { initialPosition, lastPosition };
	};

	const clearSelectedPagesAndCloseMenu = () => {
		if (menuOpened.value) menuOpened.value = '';
		if (store.activePage) selectedPages.value = [store.activePage];
	};

	return {
		selectedPages,
		copiedPages,
		menuOpened,
		move,
		moveLeft,
		moveRight,
		duplicate,
		copiedPagesexceedLimit,
		selectedPagesexceedLimit,
		activePageIsCurrentSelection,
		duplicateSelection,
		handleSelectionAfterDuplicatePages,
		canMoveRight,
		canMoveLeft,
		clearSelectedPagesAndCloseMenu,
		getPageSelection,
	};
});
