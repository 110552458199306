import { Element as SvgElement, LinkedHTMLElement, SVG } from '@svgdotjs/svg.js';
import Normalize from 'color-normalize';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import Element from '@/elements/element/classes/Element';
import ElementTools from '@/elements/element/utils/ElementTools';
import { PurifyUnserialize } from '@/elements/element/utils/PurifyUnserialize';
import { MediaApi } from '@/Types/apiClient';
import { Color } from '@/Types/colorsTypes';
import { ShapeDTO } from '@/Types/elements';
import { Position, SerializedClass, ViewBox } from '@/Types/types';

export class Shape extends Element {
	type: 'shape' = 'shape';
	viewbox: string;
	content: string;
	colors: Color[];

	protected constructor(shapeDTO: ShapeDTO) {
		super(shapeDTO);

		this.viewbox = shapeDTO.viewbox;
		this.content = shapeDTO.content;
		this.colors = shapeDTO.colors;
	}

	public get viewboxObject(): ViewBox {
		const viewboxData = this.viewbox.split(' ').map(Number);

		return {
			x: viewboxData[0],
			y: viewboxData[1],
			width: viewboxData[2],
			height: viewboxData[3],
		};
	}

	static defaults(): ShapeDTO {
		return {
			// Element
			...Element.defaults(),
			type: 'shape',
			// Shape
			viewbox: '0 0 100 100',
			content: '',
			colors: [] as Color[],
		};
	}

	static create(config: Partial<ShapeDTO> = {}): Shape {
		const shapeDTO = {
			...Shape.defaults(),
			...config,
		};

		return new Shape(shapeDTO);
	}

	@PurifyUnserialize()
	static unserialize(data: SerializedClass<Shape>): Shape {
		const shapeDTO = {
			...Shape.defaults(),
			...data,
		} as ShapeDTO;

		// Parsed data.subElements when needed
		shapeDTO.subElements = new Map<string, Element>();

		const fixedArrayColors: Color[] | undefined =
			Array.isArray(data.colors) || !data.colors ? data.colors : Object.values(data.colors as any);

		if (fixedArrayColors) {
			shapeDTO.colors = fixedArrayColors.map((c) =>
				'stops' in c ? GradientColor.unserialize(c) : SolidColor.unserialize(c)
			);
		}

		const elem = new Shape(shapeDTO);

		if (data.id) {
			elem.id = data.id;
		}

		return elem;
	}

	static moveGTagColorToChildren(mainG: SvgElement) {
		const gColors =
			'g[fill], g[fill-color], g[fill-opacity], g[stroke], g[stroke-color], g[stroke-opacity], g[stroke-width], g[style*="fill"], g[style*="stroke"], g[style*="opacity"]';
		let gWithColors = mainG.find(gColors);
		const validAttrs = [
			'fill',
			'fill-color',
			'fill-opacity',
			'stroke',
			'stroke-color',
			'stroke-opacity',
			'stroke-width',
			'opacity',
		];

		while (gWithColors.length) {
			gWithColors.forEach((elG) => {
				const hasTransparentStroke = elG.attr('stroke') === 'transparent' || elG.attr('stroke-color') === 'transparent';

				elG.children().forEach((elChild) => {
					validAttrs.forEach((attr) => {
						// Mergeamos attrs
						if (elG.node.hasAttribute(attr)) {
							if (attr.includes('stroke') && hasTransparentStroke) return;

							// Evitamos pisar los colores existentes por none
							if (elG.attr(attr) !== 'none' && elChild.attr(attr) !== elG.attr(attr)) {
								elChild.attr(attr, elG.attr(attr));
							}
						}

						// Mergeamos styles
						const fillCss = elG.css('fill');
						const strokeCss = elG.css('stroke');
						const opacityCss = elG.css('opacity');

						if (fillCss) {
							elChild.css('fill', fillCss);
						}

						if (strokeCss) {
							elChild.css('stroke', strokeCss);
						}

						if (opacityCss) {
							elChild.css('opacity', opacityCss);
						}
					});
				});

				// Eliminamos los attr y css ya mergeados
				validAttrs.forEach((attr) => {
					elG.attr(attr, null);
					elG.css(attr as CSSStyleName, '');
				});
			});

			gWithColors = mainG.find(gColors);
		}
	}

	static removeInvalidTags(svg: SvgElement) {
		// Movemos el contenido al padre, ya que no soportamos switch
		svg.find('switch').forEach((el) => {
			el.children().forEach((child) => {
				child.toParent(el.parent() as SvgElement);
			});
			el.remove();
		});

		// Si el fObj está vacío lo eliminamos
		svg.find('foreignObject').forEach((el) => {
			if (!el.children().length) el.remove();
		});
	}

	static moveClassStylesToStyles(svg: SvgElement, mainG: SvgElement) {
		mainG.find('[class]').forEach((elWithClass) => {
			const stylesTag = svg
				.find('style')
				.map((elStyle) => elStyle.node.innerHTML)
				.join('')
				.replaceAll('\n', '')
				.replaceAll('\t', '');

			ElementTools.getClassStyles(stylesTag, elWithClass.attr('class')).forEach((classProps) => {
				elWithClass.css(classProps.key, classProps.value);
			});

			elWithClass.attr('class', null);
		});

		mainG.find('style').forEach((styleTag) => styleTag.remove());
	}

	static balanceOutTransforms(mainG: SvgElement, offset: Position) {
		mainG.find('*:not(g)').forEach((elChild) => {
			if (ElementTools.checkIfIsDefsElement(elChild)) return;

			const invalidTags = ['DIV', 'SPAN', 'metadata', 'title', 'desc'];
			let parent = elChild.parent() as SvgElement;

			// Vamos aplicando los transforms según buscamos el g base que contiene el
			// clip-path container
			while (parent !== null && parent.type !== 'svg') {
				if (invalidTags.includes(elChild.type)) {
					elChild.remove();
					return;
				}

				elChild.transform(parent.transform(), true);

				parent = parent.parent() as SvgElement;
			}

			// Eliminamos los data-* de los hijos
			Object.keys(elChild.node.dataset).forEach((dataKey) => delete elChild.node.dataset[dataKey]);

			// Anulamos la posición dada por el bbox del elemento, usamos dmove en vez de transform para
			// que al arreglar los clipPath no se vean afectados por la posición global
			try {
				const hasClipPath = elChild.node.style.clipPath || elChild.node.hasAttribute('clip-path');
				const hasMask = elChild.node.style.mask || elChild.node.hasAttribute('mask');

				// Si no tiene clipPath ni mask no usamos dmove
				if (!hasClipPath && !hasMask) {
					throw new Error('No clip-path or mask');
				}

				elChild.dmove(-offset.x, -offset.y);
			} catch (error) {
				// Hay path que no son válidos y dan error al aplicar el dmove
				elChild.transform({ translateX: -offset.x, translateY: -offset.y }, true);
			}
		});
	}

	static balanceOutClipPaths(svg: SvgElement, mainG: SvgElement, offset: Position) {
		mainG.find('[style*="clip-path"], [clip-path], [style*="mask"], [mask]').forEach((elChild) => {
			if (ElementTools.checkIfIsDefsElement(elChild)) return;

			let parent = elChild.parent() as SvgElement;
			const clipPathUrl =
				elChild.css('clip-path').toString() ||
				elChild.attr('clip-path') ||
				elChild.css('mask').toString() ||
				elChild.attr('mask');
			const clipPathId = ElementTools.getIdFromUrl(clipPathUrl);
			const clipPath = svg.defs().findOne(clipPathId);

			if (!clipPath) {
				console.warn('Clip-path undefined');
				return;
			}

			// Trabajamos con un nuevo id para evitar conflictos
			const newId = `clip-path-${uuidv4()}`;
			const clipPathClone = clipPath.clone();

			elChild.node.style.clipPath = `url(#${newId})`;
			clipPathClone.id(newId);
			svg.defs().add(clipPathClone as SvgElement);

			const clipPathElement = clipPathClone.children()[0];

			while (!!clipPathElement.transform && !!parent && parent.type !== 'svg') {
				clipPathElement.transform(parent.transform(), true);
				parent = parent.parent() as SvgElement;
			}

			// Nota: usamos dmove en vez de transform para modificar la posición local y no la global ya
			// que los clipPath no tienen en cuentra la global a la hora de posicionarse respecto al elemento
			try {
				clipPathElement.dmove(-offset.x, -offset.y);
			} catch (error) {
				// Hay path que no son válidos y dan error al aplicar el dmove
				clipPathElement.transform({ translateX: -offset.x, translateY: -offset.y }, true);
			}
		});
	}

	static removeUnlessAttributesAndDatas(mainG: SvgElement) {
		mainG.find('g').forEach((elChild) => {
			const attrs = elChild.attr();

			Object.keys(attrs).forEach((attr: any) => {
				// wepik: ClipPath definidos como clip-path-xx
				// slidesgo: ClipPath definidos clip_path_xx
				if (
					!attrs[attr].toString().includes('clip-path') &&
					!attrs[attr].toString().includes('clip_path') &&
					!attrs[attr].toString().includes('mask')
				) {
					elChild.attr(attr, null);
				}
			});
		});

		Object.keys(mainG.node.dataset).forEach((dataKey) => delete mainG.node.dataset[dataKey]);
	}

	static normalizeColorsAsRGBA(svg: SvgElement, mainG: SvgElement, colors: Color[]) {
		// Should be added to metadata
		let hasInvalidPattern = false;
		mainG.find('*:not(g)').forEach((elChild) => {
			if (ElementTools.checkIfIsDefsElement(elChild)) return;

			const isInvalidFill =
				!elChild.node.hasAttribute('style') ||
				!elChild.attr('style').includes('fill') ||
				elChild.css('fill') === 'none';

			const isInvalidStroke =
				!elChild.node.hasAttribute('style') ||
				!elChild.attr('style').includes('stroke') ||
				elChild.css('stroke') === 'none';

			// Transformamos el fill
			if (!isInvalidFill) {
				let color: GradientColor | SolidColor | undefined;

				if (elChild.css('fill').includes('url')) {
					const idGradient = ElementTools.getIdFromUrl(elChild.css('fill'));

					const gradient = svg.defs().findOne(idGradient) as SvgElement;
					color = ElementTools.svgGradientToObject(gradient);

					if (gradient.type === 'pattern') {
						hasInvalidPattern = true;
						color = GradientColor.defaultColor();
					}
				} else {
					const [r, g, b, a] = Normalize(elChild.css('fill'));
					const opacity = parseFloat(elChild.css('opacity')) || parseFloat(elChild.node.style.fillOpacity) || a;

					elChild.css('fill', '');
					elChild.css('opacity', '');
					elChild.node.style.fillOpacity = '';

					const rgba = `rgba(${r * 255}, ${g * 255}, ${b * 255}, ${opacity})`;

					if (!isNaN(r)) {
						color = SolidColor.fromString(rgba);
					}
				}

				const colorExist = color && colors.find((c) => color && c.toCssString() === color.toCssString());

				if (!colorExist && color !== undefined) {
					colors.push(color);
				}

				// nos aseguramos de que el fill está vacío
				elChild.node.style.fill = '';

				// Lo asignamos desde attr para evitar que transforme el rgba a rgb al tener opacidad 1
				const style = elChild.attr('style') || '';

				if (color) elChild.attr('style', style + ` fill: var(--${colorExist ? colorExist.id : color.id});`);
			}

			// Transformamos el stroke
			if (!isInvalidStroke) {
				let color: GradientColor | SolidColor | undefined;

				if (elChild.css('stroke').includes('url')) {
					const idGradient = ElementTools.getIdFromUrl(elChild.css('stroke'));

					const gradient = svg.defs().findOne(idGradient) as SvgElement;

					color = ElementTools.svgGradientToObject(gradient);

					if (gradient.type === 'pattern') {
						hasInvalidPattern = true;
						color = GradientColor.defaultColor();
					}
				} else {
					const [r, g, b, a] = Normalize(elChild.css('stroke'));
					const opacity = parseFloat(elChild.node.style.fillOpacity) || a;

					elChild.css('stroke', '');
					elChild.node.style.strokeOpacity = '';

					const rgba = `rgba(${r * 255}, ${g * 255}, ${b * 255}, ${opacity})`;

					if (!isNaN(r)) color = SolidColor.fromString(rgba);
				}

				const colorExist = color && colors.find((c) => color && c.toCssString() === color.toCssString());

				if (!colorExist && color !== undefined) {
					colors.push(color);
				}

				// nos aseguramos de que el stroke está vacío
				elChild.node.style.stroke = '';

				// Lo asignamos desde attr para evitar que transforme el rgba a rgb al tener opacidad 1
				const style = elChild.attr('style') || '';

				if (color) elChild.attr('style', style + ` stroke: var(--${colorExist ? colorExist.id : color.id});`);
			}
		});
		return { colors, hasInvalidPattern };
	}

	static moveInlineStylesToStyle(svg: SvgElement, mainG: SvgElement, colors: Color[], metadata: object) {
		mainG
			.find('[fill], [fill-color], [fill-opacity], [stroke], [stroke-color], [stroke-opacity], [stroke-width]')
			.forEach((elChild) => {
				if (ElementTools.checkIfIsDefsElement(elChild)) return;

				// Normalizamos fill-color y fill-opacity
				const fillColor = elChild.node.getAttribute('fill-color') || elChild.node.getAttribute('fill');
				const fillOpacity = elChild.attr('fill-opacity') !== undefined ? parseFloat(elChild.attr('fill-opacity')) : 1;

				if (fillColor && fillColor !== 'transparent' && fillColor !== 'none') {
					let color: GradientColor | SolidColor;

					if (fillColor.includes('url')) {
						const idGradient = ElementTools.getIdFromUrl(fillColor);

						const gradient = svg.findOne(idGradient) as SvgElement;

						color = ElementTools.svgGradientToObject(gradient);

						if (gradient?.type === 'pattern') {
							metadata = {
								...metadata,
								hasInvalidPattern: true,
							};
							color = GradientColor.defaultColor();
						}
					} else {
						const [r, g, b] = Normalize(fillColor);
						const rgba = `rgba(${r * 255}, ${g * 255}, ${b * 255}, ${fillOpacity})`;
						color = SolidColor.fromString(rgba);
					}

					elChild.attr('fill-color', null);
					elChild.attr('fill', null);

					if (fillOpacity !== 0) {
						elChild.attr('fill-opacity', null);
					}

					const style = elChild.attr('style') || '';
					const colorExist = colors.find((c) => c.toCssString() === color.toCssString());

					if (!colorExist && color !== undefined) {
						colors.push(color);
					}

					elChild.attr('style', style + ` fill: var(--${colorExist ? colorExist.id : color.id});`);
				}

				// Normalizamos el stroke-color y stroke-opacity
				const strokeColor = elChild.node.getAttribute('stroke-color') || elChild.node.getAttribute('stroke');
				const strokeOpacity =
					elChild.attr('stroke-opacity') !== undefined ? parseFloat(elChild.attr('stroke-opacity')) : 1;

				if (strokeColor && strokeColor !== 'transparent' && strokeColor !== 'none') {
					let color: GradientColor | SolidColor;

					if (strokeColor.includes('url')) {
						const idGradient = ElementTools.getIdFromUrl(strokeColor);

						const gradient = svg.findOne(idGradient) as SvgElement;
						color = ElementTools.svgGradientToObject(gradient);
					} else {
						const [r, g, b] = Normalize(strokeColor);
						const rgba = `rgba(${r * 255}, ${g * 255}, ${b * 255}, ${strokeOpacity})`;
						color = SolidColor.fromString(rgba);
					}

					elChild.attr('stroke-color', null);
					elChild.attr('stroke', null);

					if (strokeOpacity !== 0) {
						elChild.attr('stroke-opacity', null);
					}

					const style = elChild.attr('style') || '';
					const colorExist = colors.find((c) => c.toCssString() === color.toCssString());

					if (!colorExist) {
						colors.push(color);
					}

					elChild.attr('style', style + ` stroke: var(--${colorExist ? colorExist.id : color.id});`);
				}

				// Normalizamos stroke-width
				const strokeWidth = elChild.attr('stroke-width');

				if (strokeWidth) {
					elChild.attr('stroke-width', null);
					// @ts-ignore
					elChild.css('stroke-width', strokeWidth);
				}
			});
		return colors;
	}

	static sanitizeUncoloredElements(mainG: SvgElement, colors: Color[]) {
		mainG.find('*:not(g):not([style])').forEach((elChild) => {
			if (ElementTools.checkIfIsDefsElement(elChild)) return;

			const style = elChild.attr('style') || '';
			const color = SolidColor.black();
			const colorExist = colors.find((c) => c.toCssString() === color.toCssString());

			if (!colorExist) {
				colors.push(color);
			}

			elChild.attr('style', style + ` fill: var(--${colorExist ? colorExist.id : color.id});`);
		});

		return colors;
	}

	static cancelClipPathInsideClipPath(svg: SvgElement, mainG: SvgElement) {
		// Si hay un clip-path dentro de otro lo anulamos
		mainG
			.find('[style*="clip-path"]')
			.filter((el) => {
				let parentWithClipPath = false;
				let parent = el.parent();

				while (parent && parent.type !== 'svg' && !parentWithClipPath) {
					parentWithClipPath = parent.node.style.clipPath.length > 0;
					parent = parent.parent();
				}

				return parentWithClipPath;
			})
			.forEach((el) => {
				const clipPathId = ElementTools.getIdFromUrl(el.css('clip-path').toString());
				const clipPathElement = svg.defs().findOne(clipPathId)?.first() as SvgElement;

				let parent = el.parent();

				// Tenemos que ir aplicando el transform del clip-path a los
				// demás para poder anularlos
				while (parent && parent.type !== 'svg') {
					if (parent.node.style.clipPath.length > 0) {
						const parentClipPathId = ElementTools.getIdFromUrl(el.css('clip-path').toString());
						const parentClipPathElement = svg.defs().findOne(parentClipPathId)?.first() as SvgElement;

						clipPathElement.transform(parentClipPathElement.transform(), true);
					}

					parent = parent.parent();
				}
			});

		// Si el elemento tiene un clipPath en el g que lo contiene lo eliminamos, ya que este lo añaden desde
		// illustrator para recortar el contenido en base al tamaño de la plantilla, en nuestro caso no es
		// necesario ya que queremos el elemento completo
		const content = mainG.children();
		const gContent = content.filter((el) => el.type === 'g');
		const isGWithClipPath = content.every((el) => ['g', 'defs'].includes(el.type)) && gContent.length === 1;

		if (isGWithClipPath) {
			gContent[0].node.style.clipPath = '';
		}
	}

	static sanitizeStrokedWithoutFill(mainG: SvgElement) {
		mainG.find('path').forEach((path) => {
			const hasFill = path.css('fill');
			const hasStroke = path.css('stroke');

			if (!hasFill && hasStroke) {
				path.css('fill', 'none');
			}
		});
	}

	static fromSvg(rawSvg: string): Shape {
		rawSvg = rawSvg.substring(rawSvg.indexOf('<svg'));

		const shapeSvg = SVG(rawSvg);

		// Añadimos un <g> temporal para obtener info del contenido
		shapeSvg.node.innerHTML = `<g>${shapeSvg.node.innerHTML}</g>`;

		let metadata = Shape.defaults().metadata;
		const mainG = shapeSvg.first();
		let { x, y, height, width } = mainG.bbox();

		ElementTools.fixDefsPosition(shapeSvg);
		ElementTools.changeGradientsReferencesByCloneStops(shapeSvg);

		// Si tenemos un attr de colores en un <g> la movemos a los hijos directos
		this.moveGTagColorToChildren(mainG);

		// Pasamos los estilos aplicados mediante clases a style
		this.moveClassStylesToStyles(shapeSvg, mainG);

		this.removeInvalidTags(shapeSvg);

		this.cancelClipPathInsideClipPath(shapeSvg, mainG);

		// Anulamos todos los transforms
		this.balanceOutTransforms(mainG, { x, y });

		// Aplicamos la lógica anterior a los clipPath del elemento
		this.balanceOutClipPaths(shapeSvg, mainG, { x, y });

		// Los transform ya no valen para nada, además eliminamos el resto de attrs y data
		this.removeUnlessAttributesAndDatas(mainG);

		// Normalizamos los colores, queremos que siempre sean rgba y comprueba si hay un patrón
		// establecido como color y si es el caso lo guardamos en el metadata para mostrarlo
		// como warning en el modo admin
		let shapeColors: Color[] = [];
		const { colors, hasInvalidPattern } = this.normalizeColorsAsRGBA(shapeSvg, mainG, shapeColors);

		shapeColors = colors;

		if (hasInvalidPattern) {
			metadata = {
				...metadata,
				hasInvalidPattern,
			};
		}

		// Movemos los attrs a styles
		shapeColors = this.moveInlineStylesToStyle(shapeSvg, mainG, colors, metadata);

		// Si no hay style añadimos el color por defecto que pone el navegador para poder soportarlo
		shapeColors = this.sanitizeUncoloredElements(mainG, colors);

		// Si el path no tiene fill pero sí stroke le ponemos fill none para evitar que el contenido se vea negro
		this.sanitizeStrokedWithoutFill(mainG);

		// Extraemos los clip-path que pueda tener el elemento
		const clipPathIdsInAttributes = shapeSvg
			.find('[clip-path]')
			.map((el) => ElementTools.getIdFromUrl(el.attr('clip-path').toString()));
		const clipPathIdsInStyles = shapeSvg
			.find('[style*="clip-path"]')
			.map((el) => ElementTools.getIdFromUrl(el.css('clip-path').toString()));
		const clipPathIds = [...clipPathIdsInAttributes, ...clipPathIdsInStyles];

		// Extraemos las masks que pueda tener el elemento
		const maskIdsInAttributes = shapeSvg
			.find('[mask]')
			.map((el) => ElementTools.getIdFromUrl(el.attr('mask').toString()));
		const maskIdsInStyles = shapeSvg
			.find('[style*="mask"]')
			.map((el) => ElementTools.getIdFromUrl(el.css('mask').toString()));
		const maskIds = [...maskIdsInAttributes, ...maskIdsInStyles];

		// Mantenemos solo los defs que nos interesan
		shapeSvg
			.defs()
			.children()
			.each((def) => !clipPathIds.includes(`#${def.id()}`) && !maskIds.includes(`#${def.id()}`) && def.remove());

		// Buscamos los elementos a mover (sólo los <rect/>)
		const elements = mainG
			.find('*:not(g)')
			.filter((el) => !el.node.closest('defs') && el.node.tagName === 'rect')
			.map((el) => {
				const gWithClipPath = el.node.closest('g[style*="clip-path"]');
				const element = gWithClipPath ? (gWithClipPath as LinkedHTMLElement).instance : el;
				const parent = element.parent() as SvgElement;
				const index = parent.index(el);

				return {
					element,
					index,
				};
			});

		// Usamos toParent y la posición de este para respetar el orden de los elementos
		Array.from(new Set(elements))
			.sort((a, b) => a.index - b.index)
			.forEach((elData) => {
				// @ts-ignore
				elData.element.addTo(elData.element.parent(), elData.index);
			});

		// Eliminamos los g vacíos
		let groups = mainG.find('g').filter((g) => g.children().length === 0);

		while (groups.length) {
			groups.forEach((g) => g.remove());
			groups = mainG.find('g').filter((g) => g.children().length === 0);
		}

		// Si MainG está vacío lo eliminamos
		if (!mainG.children().length) {
			mainG.remove();
		}

		const {
			xViewbox,
			yViewbox,
			x: newX,
			y: newY,
			width: newWidth,
			height: newHeight,
		} = this.getViewBoxWithStrokeSize(shapeSvg, x, y, width, height);

		x = newX;
		y = newY;
		width = newWidth;
		height = newHeight;

		const viewbox = `${xViewbox} ${yViewbox} ${width} ${height}`;
		const content = shapeSvg.node.innerHTML.toString();

		const size = {
			width: parseFloat(width.toString()) * parseFloat(mainG.transform('scaleX').toString()),
			height: parseFloat(height.toString()) * parseFloat(mainG.transform('scaleY').toString()),
		};

		const newShape = Shape.create({
			viewbox,
			content,
			colors: shapeColors,
			position: {
				x,
				y,
			},
			size,
			metadata,
		});

		return newShape;
	}

	/**
	 * Borra los estilos que no son válidos para los nodos que contengan los svgs (clip-path, mask)
	 * @returns {void}
	 */
	removeInvalidSvgStyles() {
		const svg = this.svgInstance();
		const invalidStylesFound = ElementTools.invalidShapeSvgStyles(this);

		if (!invalidStylesFound.length) return;

		const invalidStylesIds = invalidStylesFound
			.map((style) => {
				if (typeof style === 'string') {
					return ElementTools.getIdFromUrl(style);
				}
				return '';
			})
			.filter((val) => !!val);

		invalidStylesIds.forEach((idStyle) => {
			svg.find(`[style*="${idStyle}"]`).forEach((el) => {
				el.node.style.removeProperty('clip-path');
				el.node.style.removeProperty('mask');

				if (el.node.style.length === 0) {
					el.attr('style', null);
				}
			});

			svg.findOne(idStyle)?.remove();
		});

		this.content = svg.node.innerHTML.toString();
	}

	static getViewBoxWithStrokeSize(shapeSvg: SvgElement, x: number, y: number, width: number, height: number) {
		// Buscamos los elementos con stroke-width y añadimos un rect para calcular correctamente
		// el bbox del elemento, ya que no se tiene en cuenta el stroke-width
		shapeSvg.find('[style*="stroke-width"]').forEach((el) => {
			const strokeWidth = parseFloat(el.css('stroke-width').toString() || '0');

			if (!strokeWidth) return;

			const tempRect = SVG().rect(
				parseFloat(el.width().toString()) + strokeWidth,
				parseFloat(el.height().toString()) + strokeWidth
			);
			tempRect.data('temp-rect', 'true');
			tempRect.fill('#000000');
			tempRect.transform(el.transform());
			tempRect.attr('x', parseFloat(el.x().toString()) - strokeWidth / 2);
			tempRect.attr('y', parseFloat(el.y().toString()) - strokeWidth / 2);
			tempRect.insertBefore(el);
		});

		const xViewbox = shapeSvg.first().bbox().x;
		const yViewbox = shapeSvg.first().bbox().y;
		height = shapeSvg.first().bbox().height;
		width = shapeSvg.first().bbox().width;
		x += shapeSvg.first().bbox().x;
		y += shapeSvg.first().bbox().y;

		// Eliminamos los rect temporales
		shapeSvg.find('[data-temp-rect]').forEach((rect) => rect.remove());

		return { xViewbox, yViewbox, x, y, width, height };
	}

	static async fromApiImage(img: Pick<MediaApi, 'type' | 'url'>): Promise<Shape> {
		if (img.type !== 'svg') {
			return Shape.create();
		}

		const svgNode = await (await fetch(img.url)).text();

		return this.fromSvg(svgNode);
	}

	htmlInstance() {
		const parser = new DOMParser();

		return parser.parseFromString(
			`<svg viewBox="${this.viewbox}" width="100%" height="100%" preserveAspectRatio="none">${this.content}</svg>`,
			'text/html'
		);
	}

	svgInstance() {
		return SVG(
			`<svg viewBox="${this.viewbox}" width="100%" height="100%" preserveAspectRatio="none">${this.content}</svg>`
		);
	}

	clone(): this {
		const element = super.clone();

		const colorsOriginal = element.colors.map((color) => cloneDeep(color));

		// Modificamos los id a los colores de la copia
		element.colors.forEach((color) => {
			color.id = 'color-' + uuidv4();
		});

		// Actualizamos las referencias de los colores originales a los de la copia
		colorsOriginal.forEach((color, i) => {
			element.content = element.content.replaceAll(color.id, element.colors[i].id);
		});

		return element;
	}
}
