import {
	InfoPagination,
	MockupsMigrationInfo, SKUMigrationInfo,
	UserMigrationInfo,
	UserMigrationInfoDetail
} from "@/apps/mockup/migration/Types/migrationInfo";
import {User} from "@/apps/mockup/migration/Types/user";
import {ProviderInfo} from "@/apps/mockup/migration/Types/store";

export class NormalizeMigrationResponses {
	public static userMigrationInfo = (data: any): UserMigrationInfo[] => {
		const mockups = data.mockups as MockupsMigrationInfo[]
		const users = data.users as User[]

		const userMigrationInfo: UserMigrationInfo[] = []

		for (const user of users) {
			userMigrationInfo.push({
				user: user ,
				published: mockups.find(mockup => mockup.updated_by === user.id && mockup.status === 'Publish')?.amount ?? 0,
				rejected: mockups.find(mockup => mockup.updated_by === user.id && mockup.status === 'Rejected')?.amount ?? 0
			})
		}
		return userMigrationInfo
	}

	public static userMigrationlInfoDetail = (data: any): {data: UserMigrationInfoDetail[], infoPage: InfoPagination} => {
		const userMigrationInfoDetail: UserMigrationInfoDetail[] = []

		const options = {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
		};

		for (const mockup of data.data) {
			userMigrationInfoDetail.push({
				id: mockup.id,
				name: mockup.name,
				sku: mockup.sku,
				createdAt: new Date(mockup.created_at).toLocaleDateString('es-ES',options),
				createdBy: mockup.created_by,
				status: mockup.status,
				type: mockup.type,
				updatedAt: new Date(mockup.updated_at).toLocaleDateString('es-ES', options)
			})
		}

		const infoPage: InfoPagination = {
			currentPage: data.current_page,
			prevPageUrl: data.prev_page_url,
			nextPageUrl: data.next_page_url,
			lastPage: data.last_page
		}
		return {
			data: userMigrationInfoDetail,
			infoPage:infoPage
		}
	}

	public static providerInfo = (data: any): ProviderInfo => {
		return {
			id: data.id,
			name: data.name,
			authorId: data.author.id,
			authorName: data.author.name
		}
	}

	public static skuMigrationInfo = (data: any): SKUMigrationInfo => {
		const mockup  = data.mockup
		return {
			sku: mockup.sku,
			id: mockup.id,
			status: mockup.status,
			user: {
				id: mockup.user_id,
				name: mockup.user_name,
				email: mockup.user_email,
				role: mockup.user_rol
			},
			updatedAt: mockup.updated_at
		}
	}
}
