import Bugsnag from '@bugsnag/js';
import { computed, Ref, watch } from 'vue';

import { useMainStore } from '@/editor/stores/store';
import Element from '@/elements/element/classes/Element';
import { TransformRepository } from '@/elements/element/TransformRepository';
import { TransformTools } from '@/elements/element/utils/TransformTools';
import { Text } from '@/elements/texts/text/classes/Text';
import { useArtboard } from '@/project/composables/useArtboard';
import { Anchor, Position, RectBox, Size } from '@/Types/types';

/**
 * Base set of computed properties and methods to transform an element
 * @param element - Ref<Element>
 * @returns An object containing methods and computed properties for transforming the element
 */
export const useTransform = (element: Ref<Element>) => {
	const store = useMainStore();
	const { artboardSizeInPx } = useArtboard();

	const repository = new TransformRepository(element);

	watch(
		() => element.value?.id,
		() => {
			repository.setElement(element.value);
		}
	);

	const left = computed(() => repository.calculateOffsetBetweenBoxes().x);
	const top = computed(() => repository.calculateOffsetBetweenBoxes().y);
	const centerX = computed(
		() =>
			artboardSizeInPx.value.width / 2 - repository.widthWithRotation / 2 + repository.calculateOffsetBetweenBoxes().x
	);
	const centerY = computed(
		() =>
			artboardSizeInPx.value.height / 2 - repository.heightWithRotation / 2 + repository.calculateOffsetBetweenBoxes().y
	);
	const right = computed(
		() => artboardSizeInPx.value.width - repository.widthWithRotation + repository.calculateOffsetBetweenBoxes().x
	);
	const bottom = computed(
		() => artboardSizeInPx.value.height - repository.heightWithRotation + repository.calculateOffsetBetweenBoxes().y
	);

	const widthWithRotation = computed(() => repository.widthWithRotation);

	const heightWithRotation = computed(() => repository.heightWithRotation);

	const elementBoxWithRotation = computed<RectBox>(() => repository.boxWithRotation);

	const isAlignLeft = computed(() => Math.abs(element.value.position.x - left.value) <= 1);
	const isAlignCenter = computed(() => Math.abs(element.value.position.x - centerX.value) < 1);
	const isAlignRight = computed(() => Math.abs(element.value.position.x - right.value) < 1);

	const isAlignTop = computed(() => Math.abs(element.value.position.y - top.value) < 1);
	const isAlignMiddle = computed(() => Math.abs(element.value.position.y - centerY.value) < 1);
	const isAlignBottom = computed(() => Math.abs(element.value.position.y - bottom.value) < 1);

	const minElementSize = computed(() => {
		return Math.min(artboardSizeInPx.value.width, artboardSizeInPx.value.height) * 0.02;
	});

	const isValidSize = computed(() => {
		return element.value.size.width >= minElementSize.value && element.value.size.height >= minElementSize.value;
	});

	/**
	 * It flips the axis of the element
	 * @param {'x' | 'y'} axis - 'x' | 'y'
	 */
	const flipAxis = (axis: 'x' | 'y'): void => {
		element.value.flip[axis] = !element.value.flip[axis];
		Bugsnag.leaveBreadcrumb(`flip ${axis} axis to ${element.value.type}-${element.value.id}`);
	};

	/**
	 * Move referenced element to the given position or relative to its current position
	 * @param {number} x - X position
	 * @param {number} y - Y position
	 * @param {boolean} relative - If true, the position will be relative to the current position
	 */
	const move = (x: number, y: number, relative = true): void => {
		if (relative) {
			element.value.position.x += x;
			element.value.position.y += y;
			return;
		}

		element.value.setPosition(x, y);
	};

	/**
	 * It scales the element
	 * @param {number} scale - Scale factor
	 */
	const scale = (scale: number) => {
		if (element.value.type === 'text') {
			(element.value as Text).scale *= scale;
		}

		element.value.size = {
			width: element.value.size.width * scale,
			height: element.value.size.height * scale,
		};
	};

	/**
	 * It resizes the element
	 * @param {number} width
	 * @param {number} height
	 */
	const resize = (width: number, height: number) => {
		element.value.setSize(width, height);
	};

	/**
	 * Rotate referenced element to the given angle or relative to its current rotation
	 * @param {number} angle
	 * @param {boolean} relative - If true, the rotation will be relative to the current rotation
	 */
	const rotate = (angle: number, relative = true) => {
		if (relative) {
			element.value.setRotation(element.value.rotation + angle);
			return;
		}

		element.value.setRotation(angle);
	};

	/**
	 * Align referenced element to a certain position inside the canvas
	 * @param {Anchor} position
	 */
	const align = (position: Anchor): void => {
		// Hay que tener en cuenta que el element.value puede estar rotado, asi que su punto 0,0 no es realmente el 0,0
		// sino que es la diferencia entre su tamaño con la rotación aplicada y su tamaño sin ella dividido entre 2
		// ya que son x pixeles por cada lado

		// POSITION X
		if ([Anchor.left, Anchor.topLeft, Anchor.leftCenter, Anchor.bottomLeft].includes(position)) {
			element.value.position.x = left.value;
		}

		if ([Anchor.centerX, Anchor.topCenter, Anchor.center, Anchor.bottomCenter].includes(position)) {
			element.value.position.x = centerX.value;
		}

		if ([Anchor.right, Anchor.topRight, Anchor.rightCenter, Anchor.bottomRight].includes(position)) {
			element.value.position.x = right.value;
		}

		// POSITION Y
		if ([Anchor.bottom, Anchor.bottomLeft, Anchor.bottomCenter, Anchor.bottomRight].includes(position)) {
			element.value.position.y = bottom.value;
		}

		if ([Anchor.top, Anchor.topLeft, Anchor.topCenter, Anchor.topRight].includes(position)) {
			element.value.position.y = top.value;
		}

		if ([Anchor.centerY, Anchor.leftCenter, Anchor.center, Anchor.topCenter].includes(position)) {
			element.value.position.y = centerY.value;
		}

		Bugsnag.leaveBreadcrumb(`Align ${element.value.type}-${element.value.id} to ${position}`);
	};

	/**
	 * It adjusts the element size given a new height
	 * @param {number} height
	 */
	const adjustToHeight = (height: number): void => {
		const { width, height: h } = TransformTools.getSizeKeepingAspectRatioByHeight(element.value.size, height);
		element.value.setSize(width, h);
	};

	/**
	 * It adjusts the element size given a new width
	 * @param {number} width
	 */
	const adjustToWidth = (width: number): void => {
		const { width: w, height } = TransformTools.getSizeKeepingAspectRatioByWidth(element.value.size, width);
		element.value.setSize(w, height);
	};

	/**
	 * Shorthand to adjust the element size to a new width or height
	 * @param {keyof Size} side
	 * @param {number} val
	 */
	const adjustTo = (side: keyof Size, val: number) => {
		if (side === 'width') {
			adjustToWidth(val);
		}

		if (side === 'height') {
			adjustToHeight(val);
		}
	};

	/**
	 * Adjust position and size of the element to fit the artboard
	 * @param {Position} zeroPosition
	 * @param {Position} centeredPosition
	 * @param {number} scale
	 */
	const adjustToNewArtboard = (zeroPosition: Position, centeredPosition: Position, scale: number) => {
		// Para que al calcular la posición del contenido sea 0
		move(-zeroPosition.x, -zeroPosition.y);

		// Dado que hacemos un reescalado falso en prod y el viewbox de los svgs no coincide con el tamaño
		// del artboard, hay que hacer una corrección usando la escala que se genera al comparar los tamaños
		// Aplicamos la escala del canva al elemento
		element.value.scaleBy(scale);

		fitSizeToPixelGrid(element.value.size);

		// Centramos el contenido respecto al canva
		move(centeredPosition.x, centeredPosition.y);
	};

	/**
	 * Adjust referenced element to fit the canvas and locate it in the center or in the mouse position
	 * @param {Position} mousePosition
	 */
	const setupInPage = (mousePosition?: Position) => {
		adjustTo('width', artboardSizeInPx.value.width / 2);

		if (mousePosition) {
			move(mousePosition.x - element.value.size.width / 2, mousePosition.y - element.value.size.height / 2, false);
		} else {
			align(Anchor.center);
		}
	};

	/**
	 * It toggles the keepProportions flag and updates the middle handlers
	 */
	const toggleKeepProportions = () => {
		element.value.keepProportions = !element.value.keepProportions;

		if (element.value.group) return;

		Bugsnag.leaveBreadcrumb(
			`${element.value.keepProportions ? 'Keep' : 'Unkeep'} proportion: ${element.value.type}-${element.value.id}`
		);
	};

	/**
	 * Solución temporal para alinear el tamaño de los elementos con el de la caja del moveable cuando
	 * los elementos tienen decimales.
	 * Ejemplo: Al cambiar de un artboard A4 a uno pequeño en px el factor de redimensión es muy bajo y
	 * es relativamente fácil que se den width y/o height que vayan entre 0 y 1. En esos casos
	 * evitamos el redondeo.
	 * @param {Size} size
	 */
	const fitSizeToPixelGrid = (size: Size) => {
		const width = size.width;
		if (width % 1 !== 0 && width >= 1) {
			size.width = Math.round(width);
		}
		const height = size.height;
		if (height % 1 !== 0 && height >= 1) {
			size.height = Math.round(height);
		}
	};

	/**
	 * Función para posicionar en el centro un elemento que se acaba de añadir y hay parte del canvas fuera de la pantalla
	 * @param {Element} element
	 */
	const centerInVisibleZone = (element: Element) => {
		const canvasNode = store.activePage?.domNode()?.getBoundingClientRect() as DOMRect;
		const scrollArea = document.querySelector('#scroll-area') as HTMLElement;
		const isCanvasOutside =
			window.innerWidth - (scrollArea?.scrollLeft + canvasNode?.left) <= canvasNode?.width ||
			window.innerHeight - (scrollArea?.scrollTop + canvasNode?.top) <= canvasNode?.height;

		if (!isCanvasOutside) {
			align(Anchor.center);
			return;
		}
		const canvasVisibleX = (window.innerWidth - canvasNode.left + scrollArea.scrollLeft) / 2;
		const canvasVisibleY = (window.innerHeight - canvasNode.top + scrollArea.scrollTop) / 2;
		const elementVisibleX = (element.size.width * store.scale) / 2;
		const elementVisibleY = (element.size.height * store.scale) / 2;
		const axisX = (canvasVisibleX - elementVisibleX) / store.scale;
		const axisY = (canvasVisibleY - elementVisibleY) / store.scale;
		move(axisX, axisY, false);
	};

	return {
		flipAxis,
		centerInVisibleZone,
		move,
		scale,
		resize,
		rotate,
		adjustTo,
		align,
		toggleKeepProportions,
		adjustToNewArtboard,
		fitSizeToPixelGrid,
		setupInPage,
		isAlignLeft,
		isAlignCenter,
		isAlignRight,
		isAlignTop,
		isAlignMiddle,
		isAlignBottom,
		left,
		top,
		right,
		bottom,
		heightWithRotation,
		widthWithRotation,
		elementBoxWithRotation,
		isValidSize,
		minElementSize,
	};
};
