import { cloneDeep } from 'lodash';
import { computed, Ref } from 'vue';

import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import { useMainStore } from '@/editor/stores/store';
import { useCircleTypeInfo } from '@/elements/texts/curved/composables/useCircleTypeInfo';
import { Text } from '@/elements/texts/text/classes/Text';
import { useTextCurate } from '@/elements/texts/text/composables/useTextCurate';
import { useTextEditing } from '@/elements/texts/text/composables/useTextEditing';
import { useTextEffects } from '@/elements/texts/text/composables/useTextEffects';
import { useTextLink } from '@/elements/texts/text/composables/useTextLink';
import { useTextSelection } from '@/elements/texts/text/composables/useTextSelection';
import TextSelectionTools from '@/elements/texts/text/utils/TextSelectionTools';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { Color } from '@/Types/colorsTypes';
import { StyleProperties } from '@/Types/elements.d';
import { TextEffects } from '@/Types/types';

/**
 * Hook para la interacción con texto, permite la obtención reactiva
 * de estilos de un texto y la manipulación en base a la selección
 * @param text
 */
export const useTextColor = (text: Ref<Text>) => {
	const store = useMainStore();
	const { updateLinkUnderline } = useTextLink(text);
	const { removeUnusedColors } = useTextCurate(text);

	const scale = computed(() => store.scale);
	const { isCircleText } = useCircleTypeInfo(text, scale);
	const { hasEcho, hasNeon, refreshEffect } = useTextEffects(text);
	const { textEditing, textEditingContent } = useTextEditing();
	const { domNode, selection, selectedNodes } = useTextSelection();

	const selectedElements = computed(() => {
		textEditingContent.value;

		if (!selectedNodes.value || !selectedNodes.value[0]) return [text.value.domNode() as HTMLElement];
		return selectedNodes.value
			.map((el) => (el.nodeType !== Node.TEXT_NODE ? el : el.parentElement))
			.filter((el) => !!el && el.nodeName !== '#comment') as HTMLElement[];
	});

	const colors = computed(() => {
		selectedElements.value;
		textEditingContent.value;

		return text.value.colors;
	});

	const selectedColor = computed(() => {
		textEditingContent.value;

		return getCurrentColor();
	});

	const fixChildrenGradients = () => {
		if (!domNode.value) return;

		domNode.value.querySelectorAll('[style*="background-image"]').forEach((el) => {
			const style = el.getAttribute('style');

			if (style) {
				el.setAttribute('style', `${style} -webkit-background-clip: text;`);
			}
		});
	};

	/**
	 * Se encarga de la gestión completa de la asignación de un estilo al texto
	 * @param value Valor para la propiedad CSS
	 */
	const updateColor = (newColor: Color) => {
		TextSelectionTools.fixTextSelection(domNode);

		let effectToRefresh;

		// Algunos efectos dependen del color del texto y deben actualizarse
		if (hasEcho.value) effectToRefresh = TextEffects.Echo;
		if (hasNeon.value) effectToRefresh = TextEffects.Neon;

		if (applyColorToRootElement(newColor)) {
			if (effectToRefresh) refreshEffect(effectToRefresh);
			removeUnusedColors();

			// Si el domNode es el nodo original, no el editable, actualizamos el contenido del texto
			if (domNode.value && !domNode.value.closest('[id^="editable-"]')) {
				text.value.content = domNode.value.innerHTML;
			}

			return;
		}

		if (!domNode.value) {
			console.warn('Element not found');
			return;
		}

		// En caso de tener una selección de tipo Caret, generamos un rango con el elemento correspondiente
		const oldRange = TextSelectionTools.generateRangefromCaret(domNode, selection);

		// Generamos los Span de forma nativa
		TextTools.generateChildrenSpan();

		// Eliminamos el background de la selección
		TextTools.removeChildrenBackground(domNode);

		// Recuperamos el nodo seleccionado de tipo caret
		if (oldRange && selection.value?.selection && selection.value.selection.anchorNode) {
			oldRange.anchorNode = selection.value?.selection?.anchorNode;
		}

		applyColorFinalNodesStyle(newColor);
		updateLinkUnderline();

		if (effectToRefresh) refreshEffect(effectToRefresh);

		removeUnusedColors();
		fixChildrenGradients();
	};

	/**
	 * Aplicamos el fontFamily al root del texto y eliminamos los estilos de sus hijos si está seleccionado al completo
	 * @param value Valor para la propiedad CSS
	 * @returns Boolean en función de si se ha seleccionado el texto completo
	 */
	const applyColorToRootElement = (value: Color): boolean => {
		// si no hemos seleccionado nada, actualizamos los valores raiz
		// quitamos los estilos de los hijos que coincidan
		if (
			!textEditing.value ||
			(selection.value?.selection &&
				domNode.value &&
				TextSelectionTools.detectFullRange(selection.value.selection, domNode.value)) ||
			(text.value && !selection.value) //SE HA AÑADIDO PARA CONTROLAR CUANDO SE HACE CLICK EN EL ELEMENTO PERO SIN HACER SELECCIÓN
		) {
			// ? si el color a aplicar es un gradiente, hacemos un clone
			const finalValue = value instanceof GradientColor ? value.clone() : value;

			const foundColor = text.value.colors.find((c) => {
				return c.toCssString() === finalValue.toCssString();
			});

			if (domNode.value) {
				if (!foundColor) {
					// Eliminamos los colores que pudiese tener anteriormente
					Object.values(domNode.value.style).forEach((style) => {
						if (style.startsWith('--color-')) {
							domNode.value?.style.removeProperty(style);
						}
					});

					// ? Añadimos el nuevo color y copiamos el anterior id para mantener la referencia si es un solidColor
					if (value instanceof SolidColor) {
						const oldColor = cloneDeep(text.value.colors[0]);
						finalValue.id = oldColor.id;
					}

					text.value.updateColor(finalValue);
				} else {
					if (foundColor instanceof GradientColor && finalValue instanceof GradientColor) {
						foundColor.stops = finalValue.stops;
						foundColor.type = finalValue.type;
						foundColor.rotation = finalValue.rotation;
					} else if (foundColor instanceof SolidColor && finalValue instanceof SolidColor) {
						foundColor.r = finalValue.r;
						foundColor.g = finalValue.g;
						foundColor.b = finalValue.b;
						foundColor.a = finalValue.a;
					}

					text.value.updateColor(foundColor);
				}
			}

			if (domNode.value && domNode.value?.children.length) {
				TextTools.resetChildrenStyle(StyleProperties.color, domNode);
			}

			updateLinkUnderline();

			return true;
		}

		return false;
	};

	/**
	 * Obtenemos los SPAN de los nodos finales después de la selección y
	 * le aplicamos el estilo y su valor junto a un dataset para vincular con el clon del stroke
	 * @param value Color
	 */
	const applyColorFinalNodesStyle = (value: Color) => {
		let isWholeTextSelected;

		let selectionNode;

		// Comprobamos si el nodo seleccionado no pertenece al texto para seleccionar el texto completo
		if (selection.value) {
			selectionNode =
				selection.value.selection?.anchorNode?.nodeType === 1
					? (selection.value.selection?.anchorNode as HTMLElement)
					: (selection.value.selection?.anchorNode?.parentElement as HTMLElement);
		}

		// Comprobamos si la selección completa de los textos se ha hecho con el puntero
		if (
			(selection.value?.selection?.isCollapsed && !domNode.value?.children.length) ||
			!selection.value ||
			(selectionNode && !selectionNode.closest(`[id$="${text.value.id}"]`)) ||
			(domNode.value &&
				selection.value.selection instanceof Selection &&
				TextSelectionTools.detectFullRange(selection.value.selection, domNode.value))
		) {
			isWholeTextSelected = true;
		}

		const finalSelectedNodes = TextTools.getSelectedNodes();

		let finalNodes;

		// Comprobamos si la selección ha encontrado algún nodo
		if (finalSelectedNodes && finalSelectedNodes.length > 0) {
			finalNodes = finalSelectedNodes;
		}

		// Si no es una selección completa, y uno de los nodos seleccionados no tiene ninguna letra seleccionada, lo eliminamos de la selección
		if (
			finalNodes &&
			!isWholeTextSelected &&
			!selection.value?.selection?.isCollapsed &&
			selection.value?.selection?.anchorNode &&
			selection.value.selection.focusNode
		) {
			TextSelectionTools.fixFinalnodesWhereHasEmptyNodes(finalNodes, selection);
		}

		// En caso de haber seleccionado el texto completo o no haber encontrado nodos, obtenemos los hijos del nodo raíz
		if (isWholeTextSelected || ((!finalNodes || (finalNodes && finalNodes?.length < 1)) && domNode.value)) {
			finalNodes = TextTools.getNodesFromRootNode(domNode.value as HTMLElement);
		}

		if (finalNodes) {
			const nodesToStyle = TextTools.getNodesToStyle(finalNodes);

			// ? Clonamos el color con un nuevo id para asegurarnos de que el color del nodo mantiene otra referencia y otro ID,
			// ? ya que el color puede venir copiado desde los colores del documento
			const finalColor = value.clone();

			const foundColor = text.value.colors.find((c) => c.toCssString() === finalColor.toCssString());

			// finalmente, aplicamos los estilos a los nodos correspondientes
			nodesToStyle.forEach((el) => {
				replaceElementColor(el, finalColor, foundColor);
			});

			// Si no tiene nodos de texto a los que aplicarle el color y solo tiene un div vacío, le aplicamos el color al div
			if (
				!nodesToStyle.length &&
				finalNodes.length === 1 &&
				finalNodes[0] instanceof HTMLDivElement &&
				finalNodes[0].textContent === ''
			) {
				replaceElementColor(finalNodes[0], finalColor, foundColor);
			}
		}
	};

	/**
	 * Reemplaza el color de un elemento
	 * @param element Elemento
	 * @param finalColor Color final
	 * @param foundColor Color encontrado
	 * @returns
	 */
	const replaceElementColor = (element: HTMLElement, finalColor: Color, foundColor: Color | undefined) => {
		if (!domNode.value) return;

		if (!foundColor) {
			// Añadimos el nuevo color
			text.value.colors.push(finalColor);

			// Aplicamos el color al nodo
			if (finalColor.isGradient()) {
				element.style.webkitTextFillColor = 'transparent';
				element.style.backgroundImage = `var(--${finalColor.id})`;
				element.style.backgroundClip = 'text';
				element.style.color = '';
			} else {
				element.style.color = `var(--${finalColor.id})`;
				element.style.webkitTextFillColor = '';
				element.style.backgroundImage = '';
				element.style.backgroundClip = 'text';
			}
		} else {
			// Eliminamos los colores que pudiese tener anteriormente
			Object.values(domNode.value.style).forEach((style) => {
				if (style === `--${foundColor?.id}`) {
					domNode.value?.style.removeProperty(style);
				}
			});

			// Modificamos el color existente
			if (foundColor instanceof GradientColor && finalColor instanceof GradientColor) {
				foundColor.stops = finalColor.stops;
				foundColor.type = finalColor.type;
				foundColor.rotation = finalColor.rotation;
			} else if (foundColor instanceof SolidColor && finalColor instanceof SolidColor) {
				foundColor.r = finalColor.r;
				foundColor.g = finalColor.g;
				foundColor.b = finalColor.b;
				foundColor.a = finalColor.a;
			}

			// Aplicamos el color
			if (finalColor.isGradient()) {
				element.style.webkitTextFillColor = 'transparent';
				element.style.backgroundImage = `var(--${foundColor.id})`;
				element.style.backgroundClip = 'text';
				element.style.color = '';
			} else {
				element.style.color = `var(--${foundColor.id})`;
				element.style.webkitTextFillColor = '';
				element.style.backgroundImage = '';
				element.style.backgroundClip = 'text';
			}
		}
	};

	/**
	 * Obtiene el color actual del texto seleccionado
	 * @returns Devuelve un array de colores
	 */
	const getCurrentColor = () => {
		let result: Color[] = [];

		if (selection.value?.selection && selection.value?.selection.anchorNode && domNode.value) {
			const fullRange = TextSelectionTools.detectFullRange(selection.value?.selection, domNode.value);
			const nodes = TextTools.getRangeSelectedNodes(selection.value?.selection.getRangeAt(0));

			// Si está todo el texto seleccionado retornamos los colores
			if (fullRange) {
				result = [...text.value.colors];

				return result.sort();
			}

			nodes.forEach((node) => {
				if (domNode.value?.id) {
					const colorId = TextSelectionTools.getColorFromNode(node, domNode.value.id, node);
					const finalColor = text.value.colors.find((color) => color.id === colorId);

					if (finalColor && !result.find((c) => c.id === finalColor.id)) {
						result.push(finalColor);
					}
				}
			});
		}

		if (!result.length) colors.value.forEach((c) => result.push(c));

		return Array.from(new Set(result.sort().map((color) => color.toCssString()))).map((color) =>
			color.includes('-gradient') ? GradientColor.fromString(color) : SolidColor.fromString(color)
		);
	};

	return {
		colors,
		selectedColor,
		removeUnusedColors,
		updateColor,
	};
};
