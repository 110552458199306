import { computed, Ref, ref } from 'vue';

import { Text } from '@/elements/texts/text/classes/Text';
import { useTextSelection } from '@/elements/texts/text/composables/useTextSelection';
import TextSelectionTools from '@/elements/texts/text/utils/TextSelectionTools';

export const useTextUnderline = (text: Ref<Text>) => {
	const { domNode, selection, selectedNodes } = useTextSelection();

	const updatedUnderline = ref(false);

	const hasUnderline = computed(() => {
		updatedUnderline.value;

		// Si no existe domNode
		if (!domNode.value) return false;

		// Si no existe selección y retornamos si el domNode tiene el estilo underline
		if (!selection.value?.selection) {
			const rootUnderline = getComputedStyle(domNode.value).textDecoration.includes('underline');
			const childrenUnderline = Array.from(domNode.value.querySelectorAll<HTMLElement>('*')).some((el) =>
				getComputedStyle(el).textDecoration.includes('underline')
			);

			return rootUnderline || childrenUnderline;
		}

		// Si existe selección y es una selección completa, retornamos si el domNode o alguno de sus hijos tiene el estilo underline
		if (selection.value?.selection && TextSelectionTools.detectFullRange(selection.value.selection, domNode.value)) {
			const mainStyles = getComputedStyle(domNode.value).textDecoration.includes('underline');
			const childStyles = Array.from(domNode.value.querySelectorAll<HTMLElement>('*')).some((el) =>
				getComputedStyle(el).textDecoration.includes('underline')
			);

			return mainStyles || childStyles;
		}

		// Si existe selección, retornamos si alguno de los nodos seleccionados tiene el estilo underline
		if (selection.value?.selection) {
			return selectedNodes.value.some((node) => {
				if (node instanceof HTMLElement && getComputedStyle(node).textDecoration.includes('underline')) return true;
				if (
					node.nodeType === Node.TEXT_NODE &&
					node.parentElement &&
					getComputedStyle(node.parentElement).textDecoration.includes('underline')
				)
					return true;

				return false;
			});
		}

		return false;
	});

	const updateUnderline = () => {
		if (!domNode.value) return;

		TextSelectionTools.fixTextSelection(domNode);

		let isWholeTextSelected = false;

		if (!selection.value?.selection && domNode.value) {
			isWholeTextSelected = true;
		}

		if (selection.value?.selection && domNode.value) {
			isWholeTextSelected = TextSelectionTools.detectFullRange(selection.value?.selection, domNode.value);
		}

		if (isWholeTextSelected) {
			applyUnderlineToRootNode();
			return;
		}

		document.execCommand('underline');
	};

	const applyUnderlineToRootNode = () => {
		if (!domNode.value) return;

		// Si el domNode seleccionado no es el texto editable,
		// necesitamos añadirle el atributo contenteditable al texto original para poder crear la selección y aplicarle el underline
		let needContentEditable = false;

		if (!selection.value?.selection && !domNode.value.closest('[id^="editable-')) {
			needContentEditable = true;

			domNode.value.setAttribute('contenteditable', 'true');
		}

		TextSelectionTools.selectAllText(domNode);

		document.execCommand('underline');

		// Si hemos añadido el atributo contenteditable, lo eliminamos
		if (needContentEditable) domNode.value.removeAttribute('contenteditable');

		text.value.content = domNode.value.innerHTML;

		updatedUnderline.value = !updatedUnderline.value;

		return;
	};

	return {
		hasUnderline,
		updateUnderline,
	};
};
