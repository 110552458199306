import { createSharedComposable } from '@vueuse/core';

import { useEditorApiFetch } from '@/api/composables/useEditorApiFetch';
import { ApiClient } from '@/api/utils';
import { useEnvSettings } from '@/common/composables/useEnvSettings';
import { useTracksJob } from '@/common/composables/useTracksJob';
import { useEditorMode } from '@/editor/composables/useEditorMode';

export const useCustomImagesActions = createSharedComposable(() => {
	const getCustomImages = () => {
		const { onFetchResponse, onFetchError, isFetching, get } = useEditorApiFetch('user-uploads', {
			immediate: false,
		}).json();

		const getImages = () => get().execute();

		return { onFetchResponse, onFetchError, isFetching, getImages };
	};

	const uploadImage = (source = 'editor') => {
		const { isAdminMode } = useEditorMode();
		const { onFetchResponse, onFetchError, isFetching, post, data, statusCode } = useEditorApiFetch('user-uploads', {
			immediate: false,
		}).json();

		const uploadImageToServer = async (type: string, provider: string, image: string, uuid?: string) => {
			return await post({
				type,
				provider,
				upload: image,
				uuid,
				source,
				admin_upload: isAdminMode.value,
			}).execute();
		};

		return { onFetchResponse, onFetchError, isFetching, uploadImageToServer, data, statusCode };
	};

	const { APP_API_PATH } = useEnvSettings();
	const { trackJob } = useTracksJob();

	const upscale = async (uuid: string, multiplier: number) => {
		const response = await ApiClient.request(`${APP_API_PATH}user-uploads/${uuid}/upscale`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
			},
			body: {
				// @ts-ignore
				ai_image: uuid,
				multiplier,
			},
		});

		const result = await trackJob(response.uuid as string, 40000, 10000);

		return result.url;
	};

	const deleteUpload = async (uploadId: string | number) =>
		await useEditorApiFetch(`user-uploads/${uploadId}`).delete().json();

	return {
		getCustomImages,
		uploadImage,
		deleteUpload,
		upscale,
	};
});
