import Bugsnag from '@bugsnag/js';
import { Ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import Element from '@/elements/element/classes/Element';
import { useTransform } from '@/elements/element/composables/useTransform';
import { Anchor, Size } from '@/Types/types';

export const useElementTransform = (element: Ref<Element>) => {
	const usingTransform = useTransform(element);

	const flipAxis = (axis: 'x' | 'y'): void => {
		if (element.value.locked) return;
		usingTransform.flipAxis(axis);

		GAnalytics.track('click', 'Button', `image-flip-${axis}`, null);
		Bugsnag.leaveBreadcrumb(`flip ${axis} axis to ${element.value.type}-${element.value.id}`);
	};

	const move = (x: number, y: number, relative = true): void => {
		if (element.value.locked) return;
		usingTransform.move(x, y, relative);
	};

	const scale = (scale: number) => {
		if (element.value.locked) return;
		usingTransform.scale(scale);
	};

	const resize = (width: number, height: number) => {
		if (element.value.locked) return;
		usingTransform.resize(width, height);
	};

	const rotate = (angle: number, relative = true) => {
		if (element.value.locked) return;
		usingTransform.rotate(angle, relative);
	};

	const align = (position: Anchor): void => {
		if (element.value.locked) return;
		usingTransform.align(position);

		GAnalytics.track('click', 'Button', `position-${position}`, null);
		Bugsnag.leaveBreadcrumb(`Align ${element.value.type}-${element.value.id} to ${position}`);
	};

	const adjustToHeight = (height: number): void => {
		if (element.value.locked) return;
		usingTransform.adjustTo('height', height);
	};

	const adjustToWidth = (width: number): void => {
		if (element.value.locked) return;
		usingTransform.adjustTo('width', width);
	};

	const adjustTo = (side: keyof Size, val: number) => {
		if (side === 'width') {
			adjustToWidth(val);
		}

		if (side === 'height') {
			adjustToHeight(val);
		}
	};

	return {
		...usingTransform,
		flipAxis,
		move,
		scale,
		resize,
		rotate,
		adjustTo,
		align,
	};
};
