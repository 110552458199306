import { createSharedComposable } from '@vueuse/core';
import { computed, nextTick, Ref, ref } from 'vue';

import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { useTransform } from '@/elements/element/composables/useTransform';
import BaseImage from '@/elements/medias/images/base-image/classes/BaseImage';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import { useForeground } from '@/elements/medias/images/foreground/composables/useForeground';
import Image from '@/elements/medias/images/image/classes/Image';
import Page from '@/page/classes/Page';
import { useProjectStore } from '@/project/stores/project';
import { Anchor, Size } from '@/Types/types';

export const usePhotoMode = createSharedComposable(() => {
	const project = useProjectStore();
	const { isPhotoMode } = useEditorMode();
	const temporalRef = ref(ForegroundImage.create());
	const { imageBackground } = useForeground(temporalRef as Ref<ForegroundImage>);
	const { isIOS } = useDeviceInfo();
	const store = useMainStore();

	const photoModeImage = computed(() => {
		const page = project.pages[0] as Page;
		if (!isPhotoMode.value || !project.pages.length || !page.backgroundImageId) return undefined;
		return page.elements.get(page.backgroundImageId);
	});

	const tempRef = ref(Image.create());
	const { align } = useTransform(tempRef);

	const checkIsPhotoModeImage = (image: BaseImage): boolean => {
		if (!photoModeImage.value) return false;

		if (image instanceof ForegroundImage) {
			temporalRef.value = image;
			return imageBackground.value?.id === photoModeImage.value?.id;
		}

		return image.id === photoModeImage.value.id;
	};

	/**
	 * ajustamos la imagen principal a los cambios de artboard, teniendo en  cuenta
	 * la rotación que tiene aplicada la imagen y los valores del crop
	 * (los valores que llegan del input se conmutan entre width y height, y es dependiendo de la rotación que tenga la imagen
	 * como sabemos aplicar uno u otro valor que nos llega del input, este comportamiento es exclusivo del modo foto)
	 *
	 * @param size
	 */
	const fitPhotoModeImageOnChangeArtboard = async (size: Size) => {
		const sizeReescaled: Size = {
			width: isIOS.value ? size.width * store.scaleMaxAllowedSize : size.width,
			height: isIOS.value ? size.height * store.scaleMaxAllowedSize : size.height,
		};

		// Make larger or smaller proportianlly regarding custom size
		if (photoModeImage.value && photoModeImage.value instanceof Image) {
			const swapSize = photoModeImage.value.rotation % 90 === 0 && photoModeImage.value.rotation % 180 !== 0;
			const factor = swapSize
				? photoModeImage.value.size.width / sizeReescaled.height
				: photoModeImage.value.size.width / sizeReescaled.width;

			photoModeImage.value.size = {
				width: swapSize ? sizeReescaled.height : sizeReescaled.width,
				height: swapSize ? sizeReescaled.width : sizeReescaled.height,
			};

			photoModeImage.value.crop = {
				position: {
					x: photoModeImage.value.crop.position.x / factor,
					y: photoModeImage.value.crop.position.y / factor,
				},
				size: {
					width: photoModeImage.value.crop.size.width / factor,
					height: photoModeImage.value.crop.size.height / factor,
				},
			};

			// Si no se espera ese tick, al watch del useTransform no le da tiempo de actualizar la referencia
			// del TransformRepository (que es quien calcula los valores para el align) y lo hace sobre el tempRef inicial,
			// que es la Image.default usada solo para inicializar el useTransform aquí.
			tempRef.value = photoModeImage.value;
			await nextTick();
			align(Anchor.topLeft);
		}
	};

	const clearElementsFromPhoto = () => {
		store.activePage!.elements.forEach((el) => {
			if (el.id === store.activePage?.backgroundImageId) return;
			store.activePage?.elements.delete(el.id);
		});
	};

	return {
		photoModeImage,
		checkIsPhotoModeImage,
		fitPhotoModeImageOnChangeArtboard,
		clearElementsFromPhoto,
	};
});
