import { cloneDeep } from 'lodash-es';
import { defineStore } from 'pinia';
import { nextTick, ref } from 'vue';

import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useNamedDebounce } from '@/common/composables/useNamedDebounce';
import { useZoom } from '@/editor/composables/useZoom';
import { useMainStore } from '@/editor/stores/store';
import { useTextEditing } from '@/elements/texts/text/composables/useTextEditing';
import { HistoryState } from '@/history/classes/HistoryState';
import { useSelection } from '@/interactions/composables/useSelection';
import { useProjectStore } from '@/project/stores/project';

export interface HistoryStore {
	states: HistoryState[];
	activeState: HistoryState | null;
}

export const useHistoryStore = defineStore('history', () => {
	const store = useMainStore();
	const project = useProjectStore();

	const { isIOS } = useDeviceInfo();
	const { fitZoomScale } = useZoom();
	const { textEditing } = useTextEditing();
	const { selectionId } = useSelection();

	const states = ref<HistoryState[]>([]);
	const activeState = ref<HistoryState>();

	const historyHandler = (dir: 'forward' | 'rollback') => {
		if (project.isHistoryBlocked) return;

		const name = dir === 'forward' ? 'hforward' : 'hrollback';

		const lastState = states.value[states.value.length - 1];

		useNamedDebounce(name, 100, async () => {
			const isArtboardChanged =
				project.prevState.size.width !== project.size.width ||
				project.prevState.size.height !== project.size.height ||
				project.prevState.unit !== project.unit ||
				lastState?.diff.find((d) => d.path === '/unit') !== undefined;

			await setState(dir);

			if (isArtboardChanged) {
				fitZoomScale();
			}
		});
	};

	const forward = () => {
		const isLastState = activeState.value === states.value[states.value.length - 1];
		if (isLastState) return;

		historyHandler('forward');
	};

	const rollback = () => {
		const isInitialState = activeState.value?.index === 0;
		if (isInitialState) return;

		historyHandler('rollback');
	};

	const setInitialState = (forceSync = false) => {
		const h = new HistoryState(
			// @ts-ignore
			project.$state,
			cloneDeep(project.$state),
			Object.keys(states.value).length,
			store.scaleMaxAllowedSize
		);
		activeState.value = h;
		states.value.push(h);

		// @ts-ignore
		project.prevState = cloneDeep(project.$state);

		project.initSync?.(forceSync);
	};

	const setState = async (dir: 'forward' | 'rollback') => {
		if (!activeState.value) return;

		const activePageIndex = project.pages.findIndex((p) => p.id === store.activePage?.id);

		window.moving = true;

		if (dir === 'forward') activeState.value = states.value[activeState.value.index + 1];

		// Si estamos en iOS vamos actualizando la escala aplicada al navegar por el historial
		if (isIOS.value)
			store.scaleMaxAllowedSize =
				dir === 'forward' ? activeState.value.scaleIOS : states.value[activeState.value.index - 1].scaleIOS;

		// @ts-ignore
		activeState.value.applyDiff(project.$state, dir);

		// @ts-ignore
		project.prevState = cloneDeep(project.$state);

		store.$patch(() => {
			textEditing.value = store.croppingId = null;
			selectionId.value = [];
			const page = project.pages[activePageIndex];
			if (page?.id !== store.activePageId) {
				store.activePageId = project.pages[Math.min(activePageIndex, project.pages.length - 1)].id;
			}
		});

		// Esperamos a que los watchers terminen de trabajar ya que si no, crearan nuevos estados si se hace un cambio
		// (afecta al foreground image y a los textos curvos, debido al mutationObserver)
		await nextTick();
		await nextTick();
		if (window.moving) delete window.moving;

		if (dir === 'rollback') activeState.value = states.value[activeState.value.index - 1];

		project.triggerSync?.();
	};

	return {
		activeState,
		states,
		forward,
		rollback,
		setInitialState,
		setState,
	};
});
