import { useCookies } from '@vueuse/integrations/useCookies';

interface FeatureFlagMethod {
	isActive: (flag: string) => boolean;
}

class CookiesMethod implements FeatureFlagMethod {
	static instance: CookiesMethod;
	static getInstance() {
		if (!CookiesMethod.instance) {
			CookiesMethod.instance = new CookiesMethod();
		}
		return CookiesMethod.instance;
	}

	private useCookies;
	constructor() {
		this.useCookies = useCookies;
	}

	isActive(flag: string) {
		return !!this.useCookies().get(flag);
	}
}
export const useCookiesMethod = CookiesMethod.getInstance;

class QueryParamsMethod implements FeatureFlagMethod {
	static instance: QueryParamsMethod;
	static getInstance() {
		if (!QueryParamsMethod.instance) {
			QueryParamsMethod.instance = new QueryParamsMethod();
		}
		return QueryParamsMethod.instance;
	}
	isActive(flag: string) {
		const urlParams = new URLSearchParams(window.location.search);
		return !!urlParams.get(flag);
	}
}
export const useQueryParamsMethod = QueryParamsMethod.getInstance;

class FeatureFlag {
	static instance: FeatureFlag;
	static getInstance(method: FeatureFlagMethod = useCookiesMethod()) {
		if (!FeatureFlag.instance) {
			FeatureFlag.instance = new FeatureFlag();
		}
		FeatureFlag.instance.setMethod(method);
		return FeatureFlag.instance;
	}

	private method: FeatureFlagMethod;
	constructor() {
		this.method = useCookiesMethod();
	}

	isActive(flag: string) {
		return this.method.isActive(flag);
	}

	private setMethod(method: FeatureFlagMethod) {
		this.method = method;
	}
}

export const useFeatureFlag = FeatureFlag.getInstance;
