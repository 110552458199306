<script setup lang="ts">
import { ref } from 'vue';

import { useMockupStore } from '@/apps/mockup/mockupStore';
import { MockupStoreSettings } from '@/apps/mockup/schemas';
import SvgIcon from '@/common/components/SvgIcon.vue';

const mockup = useMockupStore();
const emit = defineEmits(['reloadInteractiveZone']);
const settingsPanel = ref();

const switchInteractive = () => {
	emit('reloadInteractiveZone', false);
	setTimeout(() => {
		emit('reloadInteractiveZone', true);
	}, 200);
};
const closePanel = () => {
	window.mockupDebugOptions = false;
};

const changeSettingValue = (setting: {
	model: boolean;
	key: MockupStoreSettings;
	label: string;
	detail: string | null;
}) => {
	console.log(setting);
	mockup.settings.set(setting.key, setting.model);
	if (setting.key == MockupStoreSettings.MixHoverEffect) {
		switchInteractive();
		return;
	}
};
const mockupmatorSettings = ref([
	{
		model: mockup.settings.get(MockupStoreSettings.MixHoverEffect),
		key: MockupStoreSettings.MixHoverEffect,
		label: 'Disable "halo" for Solid Colors regions',
		testing: false,
		detail: null,
	},
	{
		model: mockup.settings.get(MockupStoreSettings.ReferencePoints),
		key: MockupStoreSettings.ReferencePoints,
		label: 'Enable floating reference points',
		testing: false,
		detail: null,
	},
	{
		model: mockup.settings.get(MockupStoreSettings.SelectCustomizableInitialLoad),
		key: MockupStoreSettings.SelectCustomizableInitialLoad,
		label: 'Select the first customizable at initial load',
		testing: false,
		detail: null,
	},
	{
		model: mockup.settings.get(MockupStoreSettings.OpenCustomInFirstEdition),
		key: MockupStoreSettings.OpenCustomInFirstEdition,
		label: 'Open interactive canvas after choosing the image file',
		testing: false,
		detail: null,
	},
	{
		model: mockup.settings.get(MockupStoreSettings.SetBgOpacityForFirstColorChange),
		key: MockupStoreSettings.SetBgOpacityForFirstColorChange,
		label: 'Show Background animation color when first change',
		testing: false,
		detail: null,
	},
	{
		model: mockup.settings.get(MockupStoreSettings.DebugMode),
		key: MockupStoreSettings.DebugMode,
		label: 'Debug Mode',
		testing: true,
		detail: 'Only dev',
	},
	{
		model: mockup.settings.get(MockupStoreSettings.UseDomToImage),
		key: MockupStoreSettings.UseDomToImage,
		label: 'Use dom to image',
		testing: true,
		detail: null,
	},
]);
</script>

<template>
	<div ref="settingsPanel" class="fixed left-4 top-4 z-50 h-fit w-96 rounded-lg bg-gray-800/80 p-4 backdrop-blur-xl">
		<div class="flex w-full flex-row items-center justify-between pb-2">
			<div class="text-xs font-bold uppercase text-white opacity-75">{{ 'Settings' }}</div>
			<button class="text-gray-100 hover:text-white" @click="closePanel">
				<SvgIcon name="cross" class="h-4 w-4" />
			</button>
		</div>
		<div class="flex w-full flex-col justify-center gap-1 text-xs text-gray-100">
			<div
				v-for="(setting, index) in mockupmatorSettings"
				:key="index"
				class="flex flex-row items-start gap-1 leading-3"
			>
				<input
					:id="index + 'mockupSetting'"
					v-model="setting.model"
					type="checkbox"
					class="mr-2 h-3 w-3 rounded-lg border-gray-300 text-indigo-600 focus:ring-indigo-500"
					@change="changeSettingValue(setting)"
				/>
				<label :for="index + 'mockupSetting'">
					<span class="text-white">
						{{ setting.label }}
					</span>
					<span v-if="setting.testing" class="text-red-600">{{ ' [Beta]' }}</span>
					<template v-if="setting.detail">
						<br />
						<span class="text-2xs text-gray-300">{{ setting.detail }}</span>
					</template>
				</label>
			</div>
		</div>
	</div>
</template>
