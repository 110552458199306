import Bugsnag from '@bugsnag/js';
import { useDebounceFn } from '@vueuse/core';
import { Ref } from 'vue';

import { GradientColor } from '@/color/classes/GradientColor';
import Element from '@/elements/element/classes/Element';
import { Color, StopGradient } from '@/Types/colorsTypes';

type elementAttrs = 'size' | 'rotation';

export const useBugsnag = (element?: Ref<Element>) => {
	const breadScrumbWithDebounce = useDebounceFn(
		(attrKey: elementAttrs | Color | StopGradient | string, msg?: string) => {
			if (element?.value) {
				const values = getValues(attrKey as elementAttrs);
				if (values) {
					Bugsnag.leaveBreadcrumb(
						`${msg || ''} ${element.value.type}-${element.value.id} ${attrKey} values: ${values}`
					);
					return;
				}
			}
			if (attrKey.constructor.name === 'GradientColor' && attrKey instanceof GradientColor) {
				const gradientColor = attrKey;
				const toCss = gradientColor.toCssString();
				Bugsnag.leaveBreadcrumb(`Apply ${toCss} `);
				return;
			}

			Bugsnag.leaveBreadcrumb(`Set ${attrKey}`);
		},
		1000
	);

	const bugsnagMsgWithDebounce = useDebounceFn((msg: string) => {
		Bugsnag.leaveBreadcrumb(msg);
	}, 500);

	const getValues = (attrKey: elementAttrs) => {
		if (!element?.value) return;
		return typeof element.value[attrKey] === 'object'
			? Object.values(element.value[attrKey]).map((val) => val)
			: element.value[attrKey];
	};

	return {
		breadScrumbWithDebounce,
		bugsnagMsgWithDebounce,
	};
};
