import {defineStore} from "pinia";
import {MigrationStore} from "@/apps/mockup/migration/Types/store";
import {MigrationDialogType} from "@/apps/mockup/migration/Types/common";


export const useMockupMigrationStore = defineStore('migration', {
	state: (): MigrationStore => ({
		showMasks: false,
		generatedMasks: new Map(),
		newCustomizables: new Map(),
		viewMode: 'no-mask',
		showDialogs: {
			info: {
				status: false,
				message: ''
			},
			rejected: {
				status: false,
				message: ''
			}
		}, // This property is used to show the content of the migration
		canvasMockup: document.createElement('canvas'),
		canvasMasks: document.createElement('canvas'),
		baseColorsMask: [
			{ r: 243, g: 208, b: 207, hex: '#F3D0CF', isActive: false }, // Rosa pastel
			{ r: 207, g: 243, b: 222, hex: '#CFEFDE', isActive: false }, // Verde pastel
			{ r: 229, g: 208, b: 243, hex: '#E5D0F3', isActive: false }, // Morado pastel
			{ r: 243, g: 230, b: 207, hex: '#F3E6CF', isActive: false }, // Amarillo pastel
			{ r: 207, g: 235, b: 243, hex: '#CFEBF3', isActive: false }, // Azul pastel
			{ r: 243, g: 207, b: 230, hex: '#F3CFE6', isActive: false }, // Lila pastel
			{ r: 208, g: 243, b: 242, hex: '#D0F3F2', isActive: false }, // Celeste pastel
			{ r: 243, g: 237, b: 207, hex: '#F3EDCF', isActive: false }, // Naranja pastel
			{ r: 220, g: 207, b: 243, hex: '#DCCFF3', isActive: false }, // Lavanda pastel
			{ r: 215, g: 243, b: 207, hex: '#D7F3CF', isActive: false }  // Verde claro pastel
		],
		customizableSelected: null,
		categories: [],
		backupManifest: null,
		providerInfo: null
	}),
	actions: {
		setCustomizableSelected(customizable: string | null) {
			this.customizableSelected = customizable;
		},
		setMessageDialog(type: MigrationDialogType, message: string) {
			this.showDialogs[type].message = message;
		},
		setStatusDialog(type: MigrationDialogType, status: boolean) {
			this.showDialogs[type].status = status;
		},
		findStatusDialog(type: MigrationDialogType): boolean {
			return this.showDialogs[type].status;
		}
	},
	getters: {
		isAnyDialogOpen(state): boolean {
			return Object.values(state.showDialogs).some(dialog => dialog.status);
		}
	}
});
