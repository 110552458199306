import {
	createEventHook,
	createSharedComposable,
	promiseTimeout,
	useEventListener,
	useMutationObserver,
	useTimeoutFn,
} from '@vueuse/core';
import { debounce } from 'lodash';
import { Ref, ref } from 'vue';

import { useBugsnag } from '@/analytics/bugsnag/composables/useBugsnag';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useMainStore } from '@/editor/stores/store';
import { usePageElement } from '@/elements/element/composables/usePageElement';
import { useGroup } from '@/elements/group/composables/useGroup';
import { useGroupTransform } from '@/elements/group/composables/useGroupTransform';
import { useCircleTypeInfo } from '@/elements/texts/curved/composables/useCircleTypeInfo';
import { Text } from '@/elements/texts/text/classes/Text';
import { useTextSelection } from '@/elements/texts/text/composables/useTextSelection';
import { useTextTransform } from '@/elements/texts/text/composables/useTextTransform';
import TextSelectionTools from '@/elements/texts/text/utils/TextSelectionTools';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { useMoveable } from '@/interactions/composables/useMoveable';
import { useActivePage } from '@/page/composables/useActivePage';
import { Position } from '@/Types/types';
import MathTools from '@/utils/classes/MathTools';

export const useTextEditing = createSharedComposable(() => {
	const editable = ref();
	const textEditing = ref();
	const editingTextWithKeyboard = ref(false);
	const mouseSelectionFlag = ref(false);
	const temporalRef = ref(Text.create());
	const finishEditing = createEventHook();
	const onFinishEditingText = finishEditing.on;
	const { isPartiallyOutside } = useTextTransform(temporalRef as Ref<Text>);
	const tempText = ref(Text.create());
	const { isGrouped, group } = useGroup(tempText);
	const { groupIsPartiallyOutside } = useGroupTransform(group);
	const { bugsnagMsgWithDebounce } = useBugsnag();
	const { page } = usePageElement(textEditing);
	const { removeElement } = useActivePage();
	const store = useMainStore();
	const { previousInputSelection, selection, getWordSelectionFromCaret } = useTextSelection();
	const textEditingContent = ref('');
	const { isCircleText } = useCircleTypeInfo(tempText);
	const { isIOS, isMobile, isTouch, isWebview, isAndroid } = useDeviceInfo();
	const { moveable } = useMoveable();
	const scrollArea = ref();
	const interactiveCanvas = ref();

	const initialScroll = ref({ x: 0, y: 0 });

	const touchTimeStamp = ref(Date.now());

	const updateTextContent = () => {
		editable.value = document.querySelector(`#editable-${textEditing.value ? textEditing.value.id : undefined}`);

		if (!textEditing.value || !editable.value) return;

		textEditing.value.updateContent(editable.value.innerHTML);
	};

	/**
	 * Debounce para eliminar el fondo de los hijos del elemento editable
	 */
	const debounceRemoveChildrenBackground = debounce(
		() => {
			TextTools.removeChildrenBackground(editable);
		},
		400,
		{ leading: true }
	);

	const initListeners = () => {
		// Mutation para actualizar el elemento outlined
		useMutationObserver(
			editable,
			(e: MutationRecord[]) => {
				const el = document.querySelector('.editable-outlined-text');

				// Cuando se escribe un caracter, actualizamos el flag de actualización de contenido del elemento
				const isWritingSomeCharacter = e.some(
					(mutation) => mutation.type === 'childList' || mutation.type === 'characterData'
				);
				// Cuando se cambia el estilo, actualizamos el flag de actualización de contenido del elemento
				const isChangingStyle = e.some(
					(mutation) => mutation.type === 'attributes' && mutation.attributeName === 'style'
				);

				// ! Eliminamos el fondo de los hijos del elemento editable, porque cuando se escribe sobre un elemento vacío, se le añade un fondo
				debounceRemoveChildrenBackground();

				if (isChangingStyle || isWritingSomeCharacter) {
					textEditingContent.value = editable.value.innerHTML;
				}

				if (el) {
					el.innerHTML = TextTools.fixTextStrokeColorStyles(editable.value.innerHTML);
					updateBox();
				}
			},
			{
				childList: true,
				subtree: true,
				characterData: true,
				attributeFilter: ['style'],
			}
		);

		useEventListener(editable.value, 'copy', async (e) => {
			const text = selection.value?.selection?.getRangeAt(0).toString();

			if (text?.length) {
				await window.navigator.clipboard.writeText(text);
			}
		});

		// En caso de estar seleccionando parte del texto, deshabilitamos los handlers
		useEventListener(editable.value, 'mousedown', async (e) => {
			if (mouseSelectionFlag.value) return;

			// Si el ratón se pulsa dentro del los límites del elemento, deshabilitamos los handlers
			mouseSelectionFlag.value = true;
			document.querySelectorAll<HTMLElement>('.moveable-control').forEach((el) => (el.style.pointerEvents = 'none'));
		});

		const canvas = editable.value.closest('[id^="canvas-"]');

		// Si levantamos el ratón, rehabilitamos los handlers
		useEventListener(canvas, 'mouseup', async (e) => {
			if (!mouseSelectionFlag.value) return;

			document.querySelectorAll<HTMLElement>('.moveable-control').forEach((el) => (el.style.pointerEvents = 'auto'));
			mouseSelectionFlag.value = false;
		});

		useEventListener(editable.value, 'paste', async (e) => {
			e.preventDefault();

			const dataList = e.clipboardData?.items;
			let finalString = '';

			// Si tenemos uno o varios tipos de texto, damos prioridad al HTML
			if (dataList && dataList.length > 1 && Array.from(dataList).find((el) => el.type === 'text/html')) {
				const el = Array.from(dataList).find((el) => el.type === 'text/html');
				if (el) {
					await el.getAsString((str) => {
						if (str.length) {
							const div = document.createElement('div');
							div.innerHTML = str;
							finalString = Array.from(div.childNodes)
								.map((node) => {
									// admitimos nodos de tipo texto y otros nodos para acceder a su textContent
									// menos los no permitidos
									if (
										node.nodeType === 3 ||
										(node.nodeType === 1 && TextTools.HTML_WHITELIST.includes(node.nodeName))
									) {
										return node.textContent?.trim();
									}
									return '';
								})
								.filter((content) => !!content)
								.join('');

							if (finalString.length) {
								document.execCommand('insertText', false, finalString);

								temporalRef.value = textEditing.value;
							}
						}
					});
				}
				// Si no tenemos HTML, buscamos el texto plano
			} else if (dataList && Array.from(dataList).find((el) => el.type === 'text/plain')) {
				const wholeTextSelected = TextSelectionTools.detectFullRange(
					selection.value?.selection as Selection,
					editable.value
				);

				const plainText = e.clipboardData.getData('text');
				//  si lo que tenemos en el clipboard es un element o iframe, salimos
				const contentCantBePasted = plainText.startsWith('wepik|') || plainText.includes('<iframe');
				// Si no tenemos texto plano o no puede ser pegado por ser un elemento o un iframe, salimos
				if (!plainText.length || contentCantBePasted) {
					return;
				}

				// Si tenemos todo el texto seleccionado, lo reemplazamos por completo
				if (wholeTextSelected && !selection.value?.selection?.isCollapsed) {
					editable.value.innerHTML = plainText;
					return;
				}

				document.execCommand('insertText', false, plainText);
			}
		});

		// ? Se inician los listeners a partir del segundo click/touch, por lo que, en mobile empezaremos a
		// ? estar a la escucha del nodo editable a partir del tercer click, es entonces donde podremos colocar el cursor
		if (isMobile.value && isTouch.value) {
			useEventListener(editable.value, 'touchstart', (e) => {
				e.preventDefault();
				setCursorPosition(e);
			});
		}
	};

	const setInitialScrollValue = () => {
		scrollArea.value = document.querySelector('#scroll-area');

		initialScroll.value = { x: scrollArea.value?.scrollLeft || 0, y: scrollArea.value?.scrollTop || 0 };
	};

	const updateBox = () => {
		bugsnagMsgWithDebounce(`Typing in ${editable.value.id}: ${editable.value.textContent}`);
		temporalRef.value = textEditing.value;

		requestAnimationFrame(() => {
			tempText.value = textEditing.value;

			if (!tempText.value || !temporalRef.value) {
				return;
			}

			// En caso de que la altura de la caja de texto sobrepase los límites del canvas
			// o sea un texto curvo (ya que los textos curvos con saltos de linea se desbordan)
			// fixeamos el scroll
			const isOutSide = isGrouped.value ? groupIsPartiallyOutside.value : isPartiallyOutside.value;
			if (isOutSide || isCircleText.value) {
				fixScrollOnTyping();
			}
		});
	};

	const fixScrollOnTyping = () => {
		// los navegadores tratan de hacer scroll para que sea visible el texto que editamos...
		// asi que tras actualizar la caja, forzamos el scroll hacia arriba y hacia la izquierda del navegador
		const node = page.value?.domNode();
		if (!node) return;
		const itemsContainer = node.querySelector('[data-elements-container]') as HTMLElement;
		const toolbar = document.querySelector('.toolbar') as HTMLElement;

		if (!toolbar || !itemsContainer) return;
		const toolbarComputedStyles = getComputedStyle(toolbar);
		const matrix = new DOMMatrix(toolbarComputedStyles.transform) as DOMMatrix;
		const toolbarPosX = matrix.m41 as number;
		const TOOLBAR_OFFSET = 12;
		const editableElement = textEditing.value.domNode().getBoundingClientRect();
		let toolbarPosYFixed = 0;

		if (!isMobile.value) {
			// Corregimos la posición del toolbar antes de forzar la corrección del scroll para evitar el flickering, tomando como referencia el texto editable
			toolbarPosYFixed = Math.trunc(
				editableElement.y -
					toolbar.getBoundingClientRect().height -
					parseInt(toolbarComputedStyles.marginBottom) -
					TOOLBAR_OFFSET
			);
			//  en caso de que se detecte valor de scroll en el eje Y , añadimos el desfase que este provoca
			toolbarPosYFixed = Math.trunc(toolbarPosYFixed + itemsContainer.scrollTop * store.scale);

			toolbar.style.transform = `translate(${toolbarPosX}px, ${toolbarPosYFixed}px`;
		}

		node.scrollTop = 0;
		itemsContainer.scrollTop = 0;
		node.scrollLeft = 0;
		itemsContainer.scrollLeft = 0;

		requestAnimationFrame(() => {
			if (!isMobile.value) toolbar.style.transform = `translate(${toolbarPosX}px, ${toolbarPosYFixed}px`;

			node.scrollTop = 0;
			itemsContainer.scrollTop = 0;
			node.scrollLeft = 0;
			itemsContainer.scrollLeft = 0;
		});
	};

	const handleBlur = (mouse: Position, forceClose = false) => {
		if (!textEditing.value) return;

		const elementUnderMouse = document.elementFromPoint(mouse.x, mouse.y);
		const fontPicker = elementUnderMouse?.closest('[data-font-picker]');
		const textInput = elementUnderMouse?.closest('[data-text-input]');

		// Si es un elemento input o fontPicker guardamos la selección previa
		if (fontPicker || textInput) {
			if (!selection.value?.selection) return;

			const { anchorNode, anchorOffset, focusNode, focusOffset, isCollapsed } = selection.value.selection;

			if (anchorNode && focusNode) {
				const temporalSel = selection.value?.selection;
				const isWholeTextSelected = TextSelectionTools.detectFullRange(temporalSel, editable.value);

				// Si tenemos todo el texto seleccionado, lo reemplazamos por completo para limpiar la selección
				if (isWholeTextSelected) {
					temporalSel.removeAllRanges();

					const range = document.createRange();
					range.selectNodeContents(editable.value);
					temporalSel.addRange(range);

					previousInputSelection.value = {
						anchorNode: editable.value,
						anchorOffset,
						focusNode: editable.value,
						focusOffset,
						isCollapsed,
						wholeTextSelected: isWholeTextSelected,
					};

					// Eliminamos la selección para que el texto no se quede con el foco
					temporalSel.removeAllRanges();

					return;
				}

				// Guardamos la selección previa si no es una selección de todo el texto
				if (anchorNode.parentElement?.closest(`[id$='${textEditing.value?.id}']`)) {
					previousInputSelection.value = {
						anchorNode,
						anchorOffset,
						focusNode,
						focusOffset,
						isCollapsed,
						wholeTextSelected: isWholeTextSelected,
					};
				}

				return;
			}
		}

		const keepTextSelection = elementUnderMouse?.closest('[data-keep-text-selection]');

		if (!textInput && !fontPicker && keepTextSelection && !forceClose) {
			// si el blur se va a un componente que permite mantener la seleccion
			// guardamos la seleccion y dejamos en modo edicion
			const editingText = document.querySelector<HTMLElement>(`#editable-${textEditing.value.id}`);
			editingText && editingText.focus();
			return;
		}

		previousInputSelection.value = null;
		updateTextContent();

		handleContent(mouse);
		finishEditing.trigger(textEditing.value);
	};

	/**
	 * Se inicia a partir del segundo click o toque, al tercer click se coloca el cursor (en desktop se controla nativamente, en mobile lo controlamos mediante el evento Touch)
	 *
	 * @param {TouchEvent} e  Parámetro opcional que se usa para establecer posteriormente la posición del caret en la edición (Responsive)
	 * @returns
	 */
	const setCursorPosition = (e?: TouchEvent): void => {
		const temporalSelection = document.getSelection();
		if (!temporalSelection) return;

		// ? Colocamos el cursor en el punto donde hagamos touch
		if (e && isMobile.value && isTouch.value) {
			const currentTimeStamp = Date.now();
			const touch = e.touches[0];
			e.preventDefault();
			const caretRange = document.caretRangeFromPoint(touch.clientX, touch.clientY);

			if (!caretRange) return;
			// Si existe una diferencia menor de 300 ms entre el último tap y el actual, consideramos
			// que el usuario ha hecho doble tap, por lo que debemos de establecer como rango
			//  de selección la palabra, en caso contrario, establecemos solo el caret
			const shouldSetCaret = currentTimeStamp - touchTimeStamp.value >= 300;
			const newRange = shouldSetCaret ? caretRange : getWordSelectionFromCaret(caretRange);

			temporalSelection.removeAllRanges();

			if (newRange) temporalSelection.addRange(newRange);

			touchTimeStamp.value = Date.now();
			return;
		}

		// Si se está editando un texto, al montarse el componente se selecciona el texto completo
		const range = document.createRange();
		range.selectNodeContents(editable.value);
		temporalSelection.removeAllRanges();
		temporalSelection.addRange(range);

		selection.value = { text: temporalSelection?.toString(), selection: temporalSelection };
	};

	const handleContent = (mouse?: Position) => {
		const elementUnderMouse = mouse ? document.elementFromPoint(mouse.x, mouse.y) : undefined;
		const isAiWriterButton = elementUnderMouse?.closest('button.ai-writer-excluded');
		const editableDiv = elementUnderMouse?.hasAttribute('contenteditable') || false;

		const onlySpacesOrLineBreaks =
			textEditing.value?.content?.replace(/(&nbsp;|<div><br><\/div>)/g, '').trim().length === 0;

		if (isAiWriterButton) return;

		const emptyText =
			(textEditing.value && !textEditing.value?.content && !textEditing.value?.parentBox()) || onlySpacesOrLineBreaks;

		if (emptyText) {
			removeElement(textEditing.value);
		}

		if (!editableDiv || emptyText) {
			exitTextEditing();
		}
	};

	const exitTextEditing = () => {
		// si no estamos editando texto, salimos
		if (!textEditing.value) return;

		if (textEditing.value) {
			if (textEditing.value.content !== editable.value?.innerHTML) {
				updateTextContent();
			}
		}

		textEditing.value = null;

		if (isAndroid) {
			useTimeoutFn(() => scrollArea.value.scrollTo(initialScroll.value.x, initialScroll.value.y), 300);
		}

		// si estamos en el webview deshacemos el valor del transform que hayamos aplicado sobre al interactiveCanvas al editar el texto
		if (isWebview.value) {
			interactiveCanvas.value.style.transform = '';
		}
	};

	const scrollIntoViewOnTextEditing = async () => {
		//  ? Al desplegarse el teclado virtual en IOS  se realiza un desplazamiento de manera nativa que deja ver el texto en edición
		if (isIOS.value) return;

		if (editable.value && editable.value.getBoundingClientRect().y) {
			const OFFSET = 50;
			const { y: editableTextPositionY, height: editableTextHeight } = editable.value.getBoundingClientRect();
			const canvasBounding = editable.value.closest('[id^=canvas]').getBoundingClientRect();
			const viewPortHeightSize = visualViewport?.height || innerHeight;
			interactiveCanvas.value = editable.value.closest('[id^=interactive-canvas]');

			const relocatedScrollY = editableTextPositionY + scrollArea.value.scrollTop - viewPortHeightSize / 4;
			const initiallyHasScroll = !!scrollArea.value.scrollTop;

			const { y: scrollAreaY, height: scrollAreaHeight } = scrollArea.value.getBoundingClientRect();

			//  Comprobamos si el texto se encuentra en la parte inferior del scrollArea, si es así, necesitamos corregir la posición
			//  en el modo webview
			const elementIsAtBottom =
				MathTools.ruleOfThree(
					canvasBounding.height + canvasBounding.y,
					100,
					editableTextPositionY + editableTextHeight / 2
				) > 90 && MathTools.ruleOfThree(scrollAreaHeight, 100, editableTextPositionY) > 90;

			scrollArea.value.scrollTo(scrollArea.value.scrollLeft, relocatedScrollY);

			if (isWebview.value && !initiallyHasScroll && elementIsAtBottom) {
				// necesitamos esperar unos milisegundos a para que se  aplique el desplazamiento del scroll
				//  una vez desplazado, hacemos un translate del interactive canvas
				await promiseTimeout(100);
				if (elementIsAtBottom) {
					interactiveCanvas.value.style.transform = `translateY(-${OFFSET}px)`;
				}

				moveable.value?.updateRect();
			}
		}
	};

	return {
		textEditing,
		textEditingContent,
		handleContent,
		fixScrollOnTyping,
		editable,
		handleBlur,
		scrollIntoViewOnTextEditing,
		updateTextContent,
		updateBox,
		setInitialScrollValue,
		setCursorPosition,
		onFinishEditingText,
		initListeners,
		exitTextEditing,
		editingTextWithKeyboard,
	};
});
