import {
	CompositingSize, CustomizableKind, MockupElement, MockupElement2DTexture,
	MockupElementColor,
	MockupElementTexture, MockupElementTextures
} from "@/apps/mockup/schemas";
import {BLEEDING_VALUE, ColorDataHandler} from "@/apps/mockup/utils/CompositionTools";
import {ColorData, EditingMode, FillType} from "@/apps/mockup/Types/basicTypes";
import {SolidColor} from "@/color/classes/SolidColor";
import {LayerUtils} from "@/apps/mockup/facades/LayerUtils";
import {Size} from "@/Types/types";
import {useMockupStore} from "@/apps/mockup/mockupStore";

export class CustomizableUtils {

	/**
	 * Normalize the customizable object
	 * @param customizable - Customizable object
	 * @returns The normalized customizable object
	 */
	static normalize(customizable:any): MockupElement2DTexture | MockupElementTexture | MockupElementColor {
		const kind = customizable.kind as CustomizableKind;

		// Dictionary to normalize the customizable object
		const dictionaryNormalize = {
			color: CustomizableUtils.normalizeCustomizableColor,
			'2dTexture': CustomizableUtils.normaliceCustomizable2DTexture,
			texture: CustomizableUtils.normaliceCustomizableTexture
		};

		return dictionaryNormalize[kind](customizable);
	}

	/**
	 * Normalize the customizable color object
	 * @param customColor - Customizable color object
	 * @returns The normalized customizable color object
	 */
	static normalizeCustomizableColor(customColor: any): MockupElementColor {
		const color = ColorDataHandler(customColor.color as ColorData);
		return {
			id: customColor.id,
			relatedLayerId: customColor.relatedLayerId,
			name: customColor.name,
			kind: customColor.kind,
			color: new SolidColor(color.r, color.g, color.b, color.a),
			position: {
				x: customColor.position ? customColor.position.x : 0,
				y: customColor.position ? customColor.position.y : 0,
			},
			appliedChanges: {
				[CompositingSize.PREVIEW]: false, //Preview
				[CompositingSize.POSTER]: false, //Poster
				[CompositingSize.DOWNLOAD]: false, //Download
			},
			interactiveZone: customColor.interactiveZone,
			renderImages: {},
			visibilityMode: customColor.visibilityMode ?? [EditingMode.standard],
		};
	}

	/**
	 * Normalize the customizable 2D texture object
	 * @param customTexture - Customizable 2D texture object
	 * @returns The normalized customizable 2D texture object
	 */
	static normaliceCustomizable2DTexture(customTexture: any): MockupElement2DTexture {
		const MockupStore = useMockupStore();
		const smartObjectInstances = LayerUtils.findsmartObjectInstances(customTexture.relatedLayerId, MockupStore.getLayers);
		return {
			id: customTexture.id,
			relatedLayerId: smartObjectInstances,
			name: customTexture.name,
			canvas: null,
			kind: customTexture.kind,
			height: customTexture.size.height,
			width: customTexture.size.width,
			firstImageWasPlaced: false,
			size: customTexture.size,
			resolution: customTexture.resolution, // Resolucion maxima
			contents: customTexture.contents ?? undefined,
			position: {
				x: customTexture.position ? customTexture.position.x : 0,
				y: customTexture.position ? customTexture.position.y : 0,
			},
			appliedChanges: {
				[CompositingSize.PREVIEW]: false, //Preview
				[CompositingSize.POSTER]: false, //Poster
				[CompositingSize.DOWNLOAD]: false, //Download
			},
			bgAlreadyHadChanges: false,
			interactiveZone: customTexture.interactiveZone,
			fillType: customTexture.fillType ?? FillType.fit,
			renderImages: {},
			visibilityMode: customTexture.visibilityMode ?? [EditingMode.standard],
			customWarp: customTexture.customWarp,
			warpInfo: customTexture.warpInfo,
			smartObjectInstances: smartObjectInstances,
		};
	}

	/**
	 * Normalize the customizable texture object
	 * @param customTexture - Customizable texture object
	 * @returns The normalized customizable texture object
	 */
	static normaliceCustomizableTexture(customTexture: any): MockupElementTexture {
		const sizeWithBleeding = CustomizableUtils.generateBleeding(customTexture.frame.width, customTexture.frame.height);
		return  {
			id: customTexture.id,
			relatedLayerId: [customTexture.relatedLayerId],
			materialName: customTexture.materialName,
			name: customTexture.name,
			canvas: null,
			kind: customTexture.kind,
			height: sizeWithBleeding.height,
			width: sizeWithBleeding.width,
			firstImageWasPlaced: false,
			showGuide: true,
			resolution: customTexture.resolution, // Resolucion maxima
			frame: customTexture.frame,
			guide: customTexture.guide ?? undefined,
			contents: customTexture.contents ?? undefined,
			position: {
				x: customTexture.position ? customTexture.position.x : 0,
				y: customTexture.position ? customTexture.position.y : 0,
			},
			appliedChanges: {
				[CompositingSize.PREVIEW]: false, //Preview
				[CompositingSize.POSTER]: false, //Poster
				[CompositingSize.DOWNLOAD]: false, //Download
			},
			bgAlreadyHadChanges: false,
			interactiveZone: customTexture.interactiveZone,
			fillType: customTexture.fillType ?? FillType.fit,
			renderImages: {},
			//Dummy Data
			customFillTypeFrame: {
				height: 0.3,
				width: 0.4,
				x: 0.1,
				y: 0.3,
			},
			visibilityMode: customTexture.visibilityMode ?? [EditingMode.standard],
		};
	};

	static generateBleeding = (width: number, height: number): Size => {
		const MockupStore = useMockupStore();
		const resolutionToWork = 1000;
		if (MockupStore.getIsStandardMode) {
			return {
				width: resolutionToWork * width,
				height: resolutionToWork * height,
			};
		}
		const maxDimension = Math.max(width, height);
		return {
			width: resolutionToWork * width + (width / maxDimension) * (BLEEDING_VALUE / 2),
			height: resolutionToWork * height + (height / maxDimension) * (BLEEDING_VALUE / 2),
		};
	};

	static updateElements = (renderData: HTMLCanvasElement, width: number, mockupElement: MockupElementTextures) => {
		const mockup= useMockupStore();
		mockup.addRenderMockupElement(renderData, width, mockupElement.id);
	};
}
