import { cloneDeep } from 'lodash';
import { Ref, ref } from 'vue';

import { useMainStore } from '@/editor/stores/store';
import Element from '@/elements/element/classes/Element';
import { useElementTransformOrchestrator } from '@/elements/element/composables/useElementTransformOrchestrator';
import { useGroup } from '@/elements/group/composables/useGroup';
import { useGroupTransform } from '@/elements/group/composables/useGroupTransform';
import { useHistoryStore } from '@/history/stores/history';
import { useSelection } from '@/interactions/composables/useSelection';
import { useArtboard } from '@/project/composables/useArtboard';
import { Position } from '@/Types/types';

const PASTE_OFFSET = 40;

// Pass this optional previewElement param if element is Text, to apply the curved properties to the element
export const useInsertablePosition = (el: Ref<Element>) => {
	const store = useMainStore();
	const { selection } = useSelection();

	const history = useHistoryStore();
	const { artboardSizeInPx } = useArtboard();
	const { group } = useGroup(el);
	const { move: moveGroup } = useGroupTransform(group);

	const lastPosition = ref<Position | undefined>();

	const usingTransformOrchestrator = useElementTransformOrchestrator(el);

	// Reseteamos la posición anterior si se ha eliminado un elemento o los elementos de la página son los mismos que en el estado anterior
	const resetLastPosition = () => {
		// Si el elemento es un texto, lo seleccionamos
		if (history.states.length > 1 && store.activePage?.id) {
			if (history.activeState?.diff.some((diff) => diff.op === 'add' && diff.path.includes('/elements/'))) return;
			lastPosition.value = undefined;
		}
	};

	// Si hay una posición anterior es porque se ha añadido un elemento, se desplaza el elemento 40px a la derecha y 40px hacia abajo
	const offsetInsertedElement = () => {
		if (lastPosition.value) {
			const positionX =
				lastPosition.value.x + PASTE_OFFSET > artboardSizeInPx.value.width - PASTE_OFFSET
					? artboardSizeInPx.value.width - PASTE_OFFSET
					: lastPosition.value.x + PASTE_OFFSET;
			const positionY =
				lastPosition.value.y + PASTE_OFFSET > artboardSizeInPx.value.height - PASTE_OFFSET
					? lastPosition.value.y - PASTE_OFFSET
					: lastPosition.value.y + PASTE_OFFSET;

			// Si hay un grupo seleccionado, movemos el grupo
			if (selection.value.length > 1) {
				moveGroup(positionX, positionY);
			} else {
				usingTransformOrchestrator.value.move(positionX, positionY, false);
			}
		}

		lastPosition.value = cloneDeep(el.value.position);
	};

	return {
		resetLastPosition,
		offsetInsertedElement,
	};
};
