import { Ref, ref } from 'vue';

import Element from '@/elements/element/classes/Element';
import MathTools from '@/utils/classes/MathTools';
export class TransformRepository {
	private element: Ref<Element>;

	constructor(element: Ref<Element>) {
		this.element = ref(element.value);
	}

	setElement(element: Element) {
		this.element.value = element;
	}

	get rotationInRadians() {
		return MathTools.angleToRadians(this.element.value.rotation);
	}

	get widthWithRotation() {
		return (
			this.element.value.size.width * Math.abs(Math.cos(this.rotationInRadians)) +
			this.element.value.size.height * Math.abs(Math.sin(this.rotationInRadians))
		);
	}

	get heightWithRotation() {
		return (
			this.element.value.size.height * Math.abs(Math.cos(this.rotationInRadians)) +
			this.element.value.size.width * Math.abs(Math.sin(this.rotationInRadians))
		);
	}

	get sizeWithRotation() {
		return {
			width: this.widthWithRotation,
			height: this.heightWithRotation,
		};
	}

	calculateOffsetBetweenBoxes() {
		return {
			x: (this.widthWithRotation - this.element.value.size.width) / 2,
			y: (this.heightWithRotation - this.element.value.size.height) / 2,
		};
	}

	get boxWithRotation() {
		const offsets = this.calculateOffsetBetweenBoxes();
		return {
			top: this.element.value.position.y - offsets.y,
			right: this.element.value.position.x - offsets.x + this.widthWithRotation,
			bottom: this.element.value.position.y - offsets.y + this.heightWithRotation,
			left: this.element.value.position.x - offsets.x,
			center: this.element.value.position.x - offsets.x + this.widthWithRotation / 2,
			middle: this.element.value.position.y - offsets.y + this.heightWithRotation / 2,
		};
	}
}
