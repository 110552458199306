import * as THREE from 'three';
import { ParametricGeometry } from 'three/examples/jsm/geometries/ParametricGeometry';

export default class Mockup2DUtils {
	static createCustomWarpObject(horizontalPoints: number[], verticalPoints: number[]): THREE.Mesh {
		// Create a custom warp object
		const points: THREE.Vector3[] = [];
		const material = new THREE.MeshBasicMaterial({
			color: 0xffffff,
			side: THREE.DoubleSide,
		});
		for (let i = 0; i < horizontalPoints.length; i++) {
			points.push(new THREE.Vector3(horizontalPoints[i], verticalPoints[i], 0));
		}

		/**
		 * Calculates the value of the Bernstein polynomial.
		 * @param {number} i - The i parameter.
		 * @param {number} n - The n parameter.
		 * @param {number} t - The t parameter.
		 * @returns {number} The value of the Bernstein polynomial.
		 */
		function bernsteinPolynomial(i: number, n: number, t: number) {
			return (factorial(n) / (factorial(i) * factorial(n - i))) * Math.pow(t, i) * Math.pow(1 - t, n - i);
		}

		/**
		 * Calculates the factorial of a number.
		 * @param {number} n - The number.
		 * @returns {number} The factorial of the number.
		 */
		function factorial(n: number) {
			let result = 1;
			for (let i = 2; i <= n; i++) {
				result *= i;
			}
			return result;
		}

		/**
		 * Calculates a point on the Bézier surface based on the parameters u and v.
		 * @param {number} u - The u parameter.
		 * @param {number} v - The v parameter.
		 * @param {THREE.Vector3} target - The target vector to store the result.
		 */
		function bezierSurface(u: number, v: number, target: THREE.Vector3) {
			let x = 0,
				y = 0,
				z = 0;
			const degree = 3; // For a 4x4 control point grid, the degree is 3
			for (let i = 0; i <= degree; i++) {
				for (let j = 0; j <= degree; j++) {
					// Calculate the Bernstein polynomials for u and v
					const bernsteinU = bernsteinPolynomial(i, degree, u);
					const bernsteinV = bernsteinPolynomial(j, degree, v);
					// Calculate the contribution of the current control point
					const point = points[i * (degree + 1) + j];
					x += point.x * bernsteinU * bernsteinV;
					y += point.y * bernsteinU * bernsteinV;
					z += point.z * bernsteinU * bernsteinV;
				}
			}
			target.set(x, y, z);
		}

		const geometry = new ParametricGeometry(bezierSurface, 50, 50);
		/**
		 * The mesh representing the Bézier surface.
		 * @type {THREE.Mesh}
		 */
		return new THREE.Mesh(geometry, material);
	}
}
