import { useFeatureFlagEnabled } from '@/apps/mockup/composable/useFeatureFlagEnabled';
import { Customizable } from '@/apps/mockup/schemas';
import { useFeatureFlag, useQueryParamsMethod } from '@/apps/mockup/strategies/FeatureFlag';
import { MockupErrorType } from '@/apps/mockup/Types/errorTypes';
import { MockupStatus } from '@/apps/mockup/Types/mockupTypes';
import Settings from '@/apps/mockup/utils/Settings';
export enum StatusMockup {
	PreDraft = 'pre-draft',
	Draft = 'draft',
}

export class ApiMockup {
	public urlBase: string;

	constructor() {
		this.urlBase = Settings.serviceURL;
	}

	/**
	 * Method to load manifest information
	 * @param sku
	 * @param key
	 * @param status
	 */
	public async loadMockup(sku: string, key = '', status?: string) {
		const urlParams = new URLSearchParams(window.location.search);
		const provider = urlParams.get('provider');
		sku = urlParams.get('sku') ?? sku;

		let urlEndpoint = `${this.urlBase}mockup/${sku}/${key}`;
		if (status) urlEndpoint += this.queryStatusMockup(status);
		if (provider) urlEndpoint += `?provider=${provider}`;

		const response = await fetch(urlEndpoint);
		if (response.status == 402) {
			throw new Error(MockupErrorType.mockupNotFound);
		}
		const jsonResponse = (await response.json()) as any;

		if (provider) {
			const newSku = jsonResponse.data.sku;
			const newUrl = window.location.pathname + `?sku=${newSku}`;
			history.replaceState(null, '', newUrl);
		}

		return jsonResponse;
	}

	private queryStatusMockup(path: string) {
		const dictionaryStatus = {
			migration: StatusMockup.PreDraft,
			draft: StatusMockup.Draft,
		};
		const { isPublishInMigration } = useFeatureFlagEnabled();

		if (!(path in dictionaryStatus) || isPublishInMigration.value) return '';

		return `?${dictionaryStatus[path]}=true`;
	}

	/**
	 * Method to send new download to Mockup
	 * @param payload
	 * @param payload.sku Mockup SKU
	 * @param payload.customizables Customizables to update
	 * @param payload.alreadyExists Customizables already exists
	 * @param payload.canvas Canvas to update
	 * @returns {Response}
	 */
	public static async updateCustomizableMigration(payload: {
		sku: string;
		customizables: Customizable[];
		alreadyExists: string[];
		masks: [string, Blob][];
		categories: number[];
	}) {
		const { sku, customizables, alreadyExists, masks, categories } = payload;

		const multipart = new FormData();
		multipart.append(
			'customizables',
			JSON.stringify({
				customizables,
				alreadyExists,
				...(categories.length != 0 ? { categories } : {}),
			})
		);

		multipart.append('name', 'Migration Mockup');

		for (const [key, mask] of masks) {
			multipart.append(key, mask as Blob, `${key}.webp`); // Add mask to multipart
		};

		return await fetch(`${Settings.serviceURL}mockup/${sku}/customizable`, {
			method: 'POST',
			body: multipart,
		});
	}

	/**
	 * Generate embeding.npy from image with SAM model
	 * @param {HTMLCanvasElement} canvas
	 * @returns {Blob} File embedding npy url
	 */
	public static async getEmbeddingimage(canvas: HTMLCanvasElement): Promise<string> {
		const multipart = new FormData();
		const imageblob = await new Promise((res) => {
			canvas.toBlob((blob) => {
				res(blob);
			});
		});
		multipart.append('image', imageblob as Blob);
		const response = await fetch('https://masking.anubisapps.com/embedding', {
			method: 'POST',
			body: multipart,
		});
		const blobFile = await response.blob();
		const fileURL = URL.createObjectURL(blobFile);
		return fileURL;
	}

	/**
	 * Generate mask from image with SAM model
	 * @param {HTMLCanvasElement} canvas - Canvas to generate mask
	 * @returns {Blob} File mask url
	 */
	public static async cleanMask(canvas: HTMLCanvasElement): Promise<Blob> {
		const multipart = new FormData();
		const imageBlob = await new Promise((res) => {
			canvas.toBlob((blob) => {
				res(blob);
			});
		});
		multipart.append('image', imageBlob as Blob);
		const response = await fetch('https://masking.anubisapps.com/mask_cleaning', {
			method: 'POST',
			body: multipart,
		});
		return await response.blob();
	}

	/**
	 * Update mockup status to Draft
	 * @param sku Mockup SKU
	 * @returns {Response}
	 */
	public static async updateStatusMockup(sku: string, status: MockupStatus, rejectedTags?: number[]) {
		const response = await fetch(`${Settings.serviceURL}mockup/${sku}/${status}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ rejectedTags }),
		});
		return response;
	}

	public static async getCategoriesMigration() {
		const response = await fetch(`${Settings.serviceURL}category`);
		const jsonResponse = (await response.json()) as any;
		return jsonResponse.categories;
	}

	public static async getSearchCategories() {
		const response = await fetch(`${Settings.serviceURL}category/main`);
		return await response.json();
	}

	public static async getRejectedTags() {
		const response = await fetch(`${Settings.serviceURL}tags/rejection`);
		const jsonResponse = (await response.json()) as any;
		return jsonResponse.tags;
	}
}
