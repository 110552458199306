import { createSharedComposable } from '@vueuse/core';

export const useEnvSettings = createSharedComposable(() => {
	let APP_API_PATH = import.meta.env.VITE_APP_API_PATH;
	let APP_BASE = import.meta.env.VITE_APP_BASE || import.meta.env.VITE_APP_BASE_URL;

	if (window.location.hostname.includes('freepik')) {
		if (window.location.pathname.includes('/photo-editor')) {
			APP_API_PATH = `https://${window.location.host}/photo-editor/api/`;
			APP_BASE = `https://${window.location.host}/photo-editor/`;
		}
		if (window.location.pathname.includes('/ia/generador-de-imagenes')) {
			APP_API_PATH = `https://${window.location.host}/ia/generador-de-imagenes/api/`;
			APP_BASE = `https://${window.location.host}/ia/generador-de-imagenes/`;
		}
		if (window.location.pathname.includes('/ai/image-generator')) {
			APP_API_PATH = `https://${window.location.host}/ai/image-generator/api/`;
			APP_BASE = `https://${window.location.host}/ai/image-generator/`;
		}

		if (window.location.pathname.includes('/ai-images-editor')) {
			// deprecated
			APP_API_PATH = `https://${window.location.host}/ai-images-editor/api/`;
			APP_BASE = `https://${window.location.host}/ai-images-editor/`;
		}
		if (window.location.pathname.includes('/ai/image-editor')) {
			APP_API_PATH = `https://${window.location.host}/ai/image-editor/api/`;
			APP_BASE = `https://${window.location.host}/ai/image-editor/`;
		}
		if (window.location.pathname.includes('/pikaso')) {
			APP_API_PATH = `https://${window.location.host}/pikaso/api/`;
			APP_BASE = `https://${window.location.host}/pikaso/`;
		}
		if (window.location.pathname.includes('/designer/edit')) {
			APP_API_PATH = `https://${window.location.host}/designer/edit/api/`;
			APP_BASE = `https://${window.location.host}/designer/edit/`;
		}
		if (window.location.pathname.includes('/mockup-editor')) {
			APP_API_PATH = `https://${window.location.host}/mockup-editor/api/`;
			APP_BASE = `https://${window.location.host}/mockup-editor/`;
		}
	}

	if (window.location.hostname.endsWith('slidesgo.com')) {
		APP_API_PATH = `https://${window.location.host}/editor/api/`;
		APP_BASE = `https://${window.location.host}/editor/`;
	}

	return {
		PROD: import.meta.env.PROD,
		DEV: import.meta.env.DEV,
		MODE: import.meta.env.MODE,

		APP_VERSION: import.meta.env.VITE_APP_VERSION,
		APP_BUGSNAG_KEY: import.meta.env.VITE_APP_BUGSNAG_KEY,
		APP_BUGSNAG_RENDER_KEY: import.meta.env.VITE_APP_BUGSNAG_RENDER_KEY,
		APP_GA_ACCOUNT: import.meta.env.VITE_APP_GA_ACCOUNT,
		APP_API_VERSION: import.meta.env.VITE_APP_API_VERSION,
		APP_ABLY_KEY: import.meta.env.VITE_APP_ABLY_KEY,
		APP_GOOGLE_APP_ID: import.meta.env.VITE_APP_GOOGLE_APP_ID,
		APP_GOOGLE_API_KEY: import.meta.env.VITE_APP_GOOGLE_API_KEY,

		APP_FAL_KEY: import.meta.env.VITE_APP_FAL_KEY,

		APP_API_PATH: APP_API_PATH,
		APP_BASE: APP_BASE,
	};
});
