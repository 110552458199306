import { computed, Ref } from 'vue';

import Element from '@/elements/element/classes/Element';
import { usePageElement } from '@/elements/element/composables/usePageElement';
import Image from '@/elements/medias/images/image/classes/Image';
import { useLayersImage } from '@/elements/medias/images/image/composables/useLayersImage';

export const useVirtualGroup = (element: Ref<Element>) => {
	const { page } = usePageElement(element);
	const { foreground, hasForeground } = useLayersImage(element as Ref<Image>);
	const virtualGroupElements = computed(() => {
		if (!element.value.virtualGroup) return [];

		return page.value.elementsAsArray().filter((el) => {
			if (hasForeground.value) {
				return (
					el.id !== element.value.id && el.id !== foreground.value?.id && el.virtualGroup === element.value.virtualGroup
				);
			}
			return el.id !== element.value.id && el.virtualGroup === element.value.virtualGroup;
		});
	});

	return { virtualGroupElements };
};
