import { Settings } from '@/apps/mockup/classes/Settings';
import {
	Customizable,
	Customizable2DTexture,
	CustomizableColor,
	CustomizableTexture,
	MockupManifest,
	Size,
} from '@/apps/mockup/schemas';
import { EditingMode, FillType, Frame } from '@/apps/mockup/Types/basicTypes';
import { GradientColor } from '@/color/classes/GradientColor';
import Page from '@/page/classes/Page';
import { Color } from '@/Types/colorsTypes';

export enum CompositingSize {
	PREVIEW = 'preview',
	POSTER = 'poster',
	DOWNLOAD = 'download',
}

export enum ThumbnailsSize {
	'@2000' = '@2000',
	'@500' = '@500',
}

export const CompositionScaleBySize = {
	[CompositingSize.PREVIEW]: 1000,
	[CompositingSize.POSTER]: 2000,
	[CompositingSize.DOWNLOAD]: 5000,
};

export interface MockupElement extends Customizable {
	/**
	 * Object to set changes when will apply to this MockupElement
	 */
	appliedChanges: {
		[CompositingSize.PREVIEW]: boolean; //Poster
		[CompositingSize.POSTER]: boolean; //Preview
		[CompositingSize.DOWNLOAD]: boolean; //Download
		changeId?: string;
	};
	/**
	 * Property to set a RenderImages generated in 3DRender
	 */
	renderImages: {
		[key: string]: any;
	};
}
export type MockupElementKeys = keyof MockupElement;
export interface MockupElementColor extends CustomizableColor, MockupElement {
	color: Color;
	gradientBackup?: GradientColor;
}

export type MockupElementTextures = MockupElement2DTexture | MockupElementTexture;

export interface MockupElement2DTexture extends Customizable2DTexture, MockupElement {
	canvas: Color | null;
	bgAlreadyHadChanges: boolean;
	firstImageWasPlaced: boolean;
	currentScale?: number;
	fillType: FillType;
	customFillTypeFrame?: Frame;
	width?: number;
	height?: number;
}

export interface MockupElementTexture extends CustomizableTexture, MockupElement {
	canvas: Color | null;
	bgAlreadyHadChanges: boolean;
	firstImageWasPlaced: boolean;
	currentScale?: number;
	fillType: FillType;
	customFillTypeFrame?: Frame;
	width?: number;
	height?: number;
	showGuide?: boolean;
}
export enum MockupType {
	Mockup2D = '2D',
	Mockup3D = '3D',
	Mockup3DBaseLayer = 'layer_3d_based',
}
export interface Mockup {
	image: string;
	elements: MockupElement[];
	originalSize: Size;
}

export interface ManifestsTypes {
	preview: MockupManifest | null;
	poster: MockupManifest | null;
	download: MockupManifest | null;
}

export interface MockupState {
	activeElement: MockupElement | null;
	activeColor: MockupElement | null;
	mockup: Mockup;
	loading: boolean;
	manifests: ManifestsTypes | null;
	manifest: MockupManifest | null;
	title: string | null;
	downloading: boolean;
	error: boolean;
	sku: string | null;
	mockupType: MockupType | null;
	isPremium: boolean | null;

	settings: Settings<MockupStoreSettings>;
	percentageColladaLoaded: number;

	//Actual edition
	editingMode: EditingMode;
	customization: number | null; //customization ID

	//Canvas
	previewCanvas: HTMLCanvasElement | null;
	posterCanvas: HTMLCanvasElement | null;

	downloadCanvas: HTMLCanvasElement | null;

	source: {
		images: {
			url: string;
			opacity: number;
			blendMode: string;
		}[];
	};
	pagePreviousStatus: Page | null;
	sharedLink: string;
	size: Size;
	editor: {
		firstEntry: boolean;
		recentColors: Color[];
	};
	searching: {
		premium: boolean;
	};
}

export enum MockupStoreSettings {
	MixHoverEffect = 'MixHoverEffect',
	ReferencePoints = 'ReferencePoints',
	SelectCustomizableInitialLoad = 'SelectCustomizableInitialLoad',
	IsFirstCustomizableSelectInitialLoading = 'IsFirstCustomizableSelectInitialLoading',
	OpenCustomInFirstEdition = 'OpenCustomInFirstEdition',
	SetBgOpacityForFirstColorChange = 'SetBgOpacityForFirstColorChange',
	DebugMode = 'Debug Mode',
	UseDomToImage = 'UseDomToImage',
	ViewMemory = 'ViewMemory',
	ViewEvents = 'ViewEvents',
}
