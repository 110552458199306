import { ref } from 'vue';
import {CanvasUtils} from "@/apps/mockup/classes/CanvasUtils";

/**
 * This Class is used to handle the preview of all customizable elements
 */
class PreviewPage {
	private _currentPage = '';
	private _previews: Map<string, HTMLCanvasElement> = new Map();
	private _showTexturePicker = false;

	/**
	 *
	 * @param {string} id current page to view its preview
	 * @returns
	 */
	public setCurrentPage(id: string) {
		this._currentPage = id;
		return this;
	}

	/**
	 * Get the current page to be observed
	 */
	get currentPage() {
		return this._currentPage;
	}

	/**
	 * This returns the {HTMLCanvasElement} related to the current page.
	 */
	get preview() {
		return this._previews.get(this._currentPage);
	}

	/**
	 * This function is used to update the preview on any mockup page. The main goal of this function is to avoid
	 * rendenring again the preview. Instead the preview will be hosted here and used when its required.
	 *
	 * @param {string} uuid
	 * @param {HTMLCanvasElement} canvas
	 * @returns
	 */
	public updatePreview(uuid: string, canvas: HTMLCanvasElement) {
		const preview = CanvasUtils.cloneCanvas(canvas, { size: { width: canvas.width / 5, height: canvas.height / 5 },resize: true });
		this._previews.set(uuid, preview);
		return this;
	}

	public getPreview(uuid: string): HTMLCanvasElement | undefined {
		return this._previews.get(uuid);
	}

	public setShowTexturePicker(uuid: string) {
		this.setCurrentPage(uuid);
		this._showTexturePicker = true;
	}

	public refreshCurrent() {
		const bubble = this._currentPage;
		this._currentPage = '';
		this.setCurrentPage(bubble);
		this._showTexturePicker = false;
	}

	get showTexturePicker() {
		return this._showTexturePicker;
	}
}

export const usePreviewPage = ref(new PreviewPage());
