import { v4 as uuidv4 } from 'uuid';

import { SolidColor } from '@/color/classes/SolidColor';
import Element from '@/elements/element/classes/Element';
import { PurifyUnserialize } from '@/elements/element/utils/PurifyUnserialize';
import { Color } from '@/Types/colorsTypes';
import { QRCodeDTO } from '@/Types/elements';
import { SerializedClass, ViewBox } from '@/Types/types';

export class QRCode extends Element {
	type: 'qrcode' = 'qrcode';
	url: string;
	viewbox: string;
	content: string;
	margin: number;
	frontColor: SolidColor;
	bgColor: SolidColor;

	protected constructor(qrCodeDTO: QRCodeDTO) {
		super(qrCodeDTO);

		this.url = qrCodeDTO.url;
		this.viewbox = qrCodeDTO.viewbox;
		this.content = qrCodeDTO.content;
		this.margin = qrCodeDTO.margin;
		this.frontColor = qrCodeDTO.frontColor;
		this.bgColor = qrCodeDTO.bgColor;
	}

	public get viewboxObject(): ViewBox {
		const viewboxData = this.viewbox.split(' ').map(Number);

		return {
			x: viewboxData[0],
			y: viewboxData[1],
			width: viewboxData[2],
			height: viewboxData[3],
		};
	}

	static defaults(): QRCodeDTO {
		return {
			// Element
			...Element.defaults(),
			type: 'qrcode',
			// QRCode
			url: '',
			viewbox: '0 0 100 100',
			content: '',
			margin: 4,
			frontColor: SolidColor.black(),
			bgColor: SolidColor.white(),
		};
	}

	static create(config: Partial<QRCodeDTO> = {}): QRCode {
		const qrCodeDTO = {
			...QRCode.defaults(),
			...config,
		};

		return new QRCode(qrCodeDTO);
	}

	@PurifyUnserialize()
	static unserialize(data: SerializedClass<QRCode>): QRCode {
		const qrCodeDTO = {
			...QRCode.defaults(),
			...data,
		} as QRCodeDTO;

		// Parsed data.subElements when needed
		qrCodeDTO.subElements = new Map<string, Element>();

		if (data.frontColor) {
			qrCodeDTO.frontColor = SolidColor.unserialize(data.frontColor as SolidColor);
		}

		if (data.bgColor) {
			qrCodeDTO.bgColor = SolidColor.unserialize(data.bgColor as SolidColor);
		}

		const elem = new QRCode(qrCodeDTO);

		if (data.id) {
			elem.id = data.id;
		}

		return elem;
	}

	static getInnerSvg(rawSvg: string) {
		const div = document.createElement('div');
		div.insertAdjacentHTML('afterbegin', rawSvg);

		const svg = div.querySelector('svg');

		if (!svg) return '';

		return svg.innerHTML;
	}

	static getViewBoxFromRawSvg(rawSvg: string) {
		const div = document.createElement('div');
		div.insertAdjacentHTML('afterbegin', rawSvg);

		const svg = div.querySelector('svg');

		if (!svg) return QRCode.defaults().viewbox;

		return svg.getAttribute('viewBox') || QRCode.defaults().viewbox;
	}

	static fromSvg(url: string, rawSvg: string): QRCode | undefined {
		const div = document.createElement('div');
		div.insertAdjacentHTML('afterbegin', rawSvg);

		const svg = div.querySelector('svg');

		if (!svg) return;

		const viewbox = svg.getAttribute('viewBox');

		if (!viewbox) return;

		const mappedViewBox = viewbox.split(' ').map((val: string) => parseFloat(val));

		return QRCode.create({
			url,
			viewbox,
			content: svg.innerHTML,
			size: {
				width: mappedViewBox[2],
				height: mappedViewBox[3],
			},
		});
	}

	updateFrontColor(newColor: SolidColor) {
		newColor.id = this.frontColor.id;
		this.frontColor = newColor;
	}

	updateBgColor(newColor: SolidColor) {
		newColor.id = this.bgColor.id;
		this.bgColor = newColor;
	}

	getColors(): Color[] {
		return [this.frontColor, this.bgColor];
	}

	clone(): this {
		const element = super.clone();

		// Modificamos el id al color del background y del foreground
		element.bgColor.id = 'color-' + uuidv4();
		element.frontColor.id = 'color-' + uuidv4();

		return element;
	}

	get colors(): Color[] {
		return [this.frontColor, this.bgColor];
	}
}
