import Echo from '@ably/laravel-echo';
import { createSharedComposable } from '@vueuse/core';
import * as Ably from 'ably';
import { computed, ref } from 'vue';

import { useEnvSettings } from '@/common/composables/useEnvSettings';
import { ImageApi } from '@/Types/apiClient';

enum EventsWebsocket {
	TrackedJobReady = '.tracked-job.ready',
	TrackedJobProgress = '.tracked-job.progress',
	TrackedJobFailed = '.tracked-job.failed',
}

type WithSocket<G> = G & { socket: string | undefined };

export const useEcho = createSharedComposable(() => {
	const { APP_ABLY_KEY, APP_BASE } = useEnvSettings();
	window.Ably = Ably;

	let listenCounter = 0;

	const echoInstance = computed(() => {
		return new Echo({
			broadcaster: 'ably',
			authEndpoint: `${APP_BASE}broadcasting/auth`,
			key: APP_ABLY_KEY,
		});
	});

	const tryToConnect = () => {
		if (echoInstance.value.connector.ably.connection.state === 'closed') {
			echoInstance.value.connect();
		}

		listenCounter++;
	};

	const tryToDisconnect = (channel: string) => {
		listenCounter--;
		echoInstance.value.leave(channel);

		if (listenCounter === 0) {
			echoInstance.value.disconnect();
		}
	};

	const onTrackedJobReady = (uuid: string): Promise<object> => {
		const channelName = `tracked-job.${uuid}`;

		return new Promise((resolve, reject) => {
			tryToConnect();

			echoInstance.value
				.channel(channelName)
				.listen(EventsWebsocket.TrackedJobReady, (data: WithSocket<object>) => {
					resolve(data);
					tryToDisconnect(channelName);
				})
				.listen(EventsWebsocket.TrackedJobFailed, (data: WithSocket<any>) => {
					tryToDisconnect(channelName);
					reject(data);
				});
		});
	};
	const onTrackedJobProgress = (uuid: string, callback: (params: WithSocket<any>) => void) => {
		const channelName = `tracked-job.${uuid}`;
		echoInstance.value.channel(channelName).listen(EventsWebsocket.TrackedJobProgress, (data: WithSocket<any>) => {
			callback(data);
		});
	};

	return {
		onTrackedJobReady,
		onTrackedJobProgress,
	};
});
