import Bugsnag from '@bugsnag/js';
import { sortByIndex } from '@tldraw/indices';
import { createSharedComposable, useClipboard, useIntervalFn, useUrlSearchParams } from '@vueuse/core';
import { until } from '@vueuse/core';
import { isEqual, minBy } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';
import { computed, nextTick, Ref, ref } from 'vue';

import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useMainStore } from '@/editor/stores/store';
import Element from '@/elements/element/classes/Element';
import { useElementTransformOrchestrator } from '@/elements/element/composables/useElementTransformOrchestrator';
import { usePageElement } from '@/elements/element/composables/usePageElement';
import ElementTools from '@/elements/element/utils/ElementTools';
import { TransformTools } from '@/elements/element/utils/TransformTools';
import { useGroupTransform } from '@/elements/group/composables/useGroupTransform';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import { useSyncForeground } from '@/elements/medias/images/foreground/composables/useSyncForeground';
import Image from '@/elements/medias/images/image/classes/Image';
import { useLayersImage } from '@/elements/medias/images/image/composables/useLayersImage';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import { Text } from '@/elements/texts/text/classes/Text';
import { useFonts } from '@/elements/texts/text/composables/useFonts';
import { useTextEditing } from '@/elements/texts/text/composables/useTextEditing';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { useAddInsertableElement } from '@/interactions/composables/useAddInsertableElement';
import { useSelection } from '@/interactions/composables/useSelection';
import TemplateLoader from '@/loader/utils/TemplateLoader';
import { useActivePage } from '@/page/composables/useActivePage';
import { useArtboard } from '@/project/composables/useArtboard';
import { useProjectStore } from '@/project/stores/project';
import { FontWeight } from '@/Types/elements';
import { Anchor, Position } from '@/Types/types';

// Constants
const PASTE_OFFSET = 40;

export const useEditorClipboard = createSharedComposable(() => {
	const store = useMainStore();
	const { selection, setSelection, clearSelection, selectionOrdered } = useSelection();
	const { textEditing } = useTextEditing();
	const project = useProjectStore();
	const temporalCopy = ref();

	// Ref usada para versiones de navegador que no soporten la api de clipboard
	const mobileCopiedElement = ref();
	const currentClipboardData = ref('');

	const lastPaste = ref<any[]>([]);

	const { text, copy, isSupported } = useClipboard();
	const { addElement } = useActivePage();
	const { isWebview, isMobile } = useDeviceInfo();

	const temporalRef = ref<Element | null>();
	const temporalText = ref(Text.create({ fontSize: 24, fontWeight: 400, lineHeight: 1.2 }));
	const temporalImage = ref(Image.create());
	const temporalGroup = ref<Element[]>([]);
	const temporalForeground = ref(ForegroundImage.create());
	const { syncPositionWithBackground, syncSizeWithBackground } = useSyncForeground(
		temporalForeground as Ref<ForegroundImage>
	);
	const focused = computed(() => temporalRef.value || selection.value[0] || Shape.create());

	const { artboardSizeInPx } = useArtboard();
	const { foreground } = useLayersImage(temporalImage as Ref<Image>);
	const usingElementTransform = useElementTransformOrchestrator(focused);
	const { align: alignGroup, resize: resizeGroup, size: groupSize } = useGroupTransform(temporalGroup);
	const { page } = usePageElement(focused);

	const { addInsertableText, addInsertablePasteText, addInsertableShape } = useAddInsertableElement(
		temporalText as Ref<Text>
	);
	const { inUseFonts, getNearestWeight } = useFonts();

	const canBeCopied = computed(
		() =>
			selection.value.length > 0 &&
			focused.value.id !== page.value?.backgroundImageId &&
			!(focused.value instanceof ForegroundImage) &&
			!textEditing.value
	);
	const canBePasted = computed(() => {
		return (hasContent.value && !!store.activePage && !textEditing.value) || mobileCopiedElement.value?.length;
	});

	const hasContent = computed(() => text.value?.startsWith('wepik|'));
	const selectionType = computed(() =>
		selection.value.length > 1 && selection.value[1].group ? 'group' : selection.value.length > 1 ? 'selection' : ''
	);

	const hasNotAllowedTags = computed(() => checkIfHasNotAllowedTags(text.value));

	const checkIfHasNotAllowedTags = (text: string) => {
		const div = document.createElement('div');
		div.innerHTML = text;
		return !text.startsWith('wepik|') && !!div.querySelectorAll('iframe').length;
	};

	const checkNavigatorPermissionsToReadTextFromClipboard = async () => {
		try {
			const text = await navigator.clipboard.readText();
			return true;
		} catch (error) {
			return false;
		}
	};

	/**
	 * Control + c copia las ids de los elementos seleccionados, con un prefijo en el portapapeles para
	 * poder identificarlo al pegar.
	 */
	const copySelection = async (ignoreClipboard?: boolean) => {
		// Copy Element
		temporalRef.value = selection.value[0] as Element;
		// Cancel copy in photo mode image
		if (!canBeCopied.value) return;

		const currentSelection = [...selectionOrdered.value];
		const elements: Element[] = [];

		currentSelection.forEach((el) => {
			if (el instanceof Image) {
				temporalImage.value = el;
				if (foreground.value && !currentSelection.find((elem) => elem === foreground.value)) {
					elements.push(foreground.value);
				}
			}

			elements.push(el);
		});

		temporalRef.value = selection.value[0] as Element;

		const sortedSelection = [...elements.sort(sortByIndex)];
		lastPaste.value = sortedSelection.map((element) => ({
			id: element.id,
			x: element.position.x,
			y: element.position.y,
			size: element.size,
		}));

		const serializedElements = sortedSelection.map((element) => element.toSerialize());

		// Si el navegador soporta clipboard usamos el clipboard nativo, en caso contrario sobreescribimos mobileCopiedElement
		const element = `wepik|${JSON.stringify(serializedElements)}`;
		if (isSupported.value && !isWebview.value && !ignoreClipboard) {
			await copy(element);
		} else {
			mobileCopiedElement.value = element;
		}

		Bugsnag.leaveBreadcrumb(`Copy ${selectionType.value}: ${sortedSelection.map((el) => el.type + '-' + el.id)}`);

		temporalRef.value = null;
	};

	/**
	 * Comprueba si el portapapeles contiene una imagen con el id pasado por parámetro
	 * @param imageId
	 * @returns
	 */
	const hasImageInClipboard = (imageId: string) => text.value.includes(`"metadata":{"uploadId":"${imageId}"`);

	/**
	 * Al pegar comprobamos de si lo que pegamos es una selección del editor
	 * @param fromMouse
	 */
	const pasteSelection = async (pastePos?: Position, ignoreClipboard?: boolean) => {
		if (!canBePasted.value && !currentClipboardData.value.length) return;

		if (hasNotAllowedTags.value) {
			copy('');
			return;
		}

		let pastedElements = JSON.parse(
			(isSupported.value && !isWebview.value && !ignoreClipboard ? text.value : mobileCopiedElement.value).substring(6)
		) as Element[];
		clearSelection();

		let offsetX = 0;
		let offsetY = 0;

		// Si es un un grupo buscamos el elemento que este más arriba a la izquierda
		if (pastedElements.length > 1) {
			const topXElement = minBy(pastedElements, 'position.x') as any;
			const topYElement = minBy(pastedElements, 'position.y') as any;

			offsetX = -topXElement.position.x;
			offsetY = -topYElement.position.y;
		}

		const newVirtualGroupId = uuidv4();
		const clonedElements: Element[] = [];
		let found;

		pastedElements = pastedElements.filter(
			(element, index, elements) => index === elements.findIndex((el) => el.id === element.id)
		);

		// En caso de que el elemento que se pega ya exista en el proyecto, se le asigna un nuevo id
		const unserializedPastedElements = pastedElements.map((element) => TemplateLoader.unserializeElement(element));

		const fixedElements = ElementTools.fixRepeatedElementIds(project.allElements, unserializedPastedElements).sort(
			sortByIndex
		);

		fixedElements.forEach((element: Element, index) => {
			if (element.locked) {
				element.setLocked(false);
			}

			if (element.virtualGroup) {
				element.virtualGroup = newVirtualGroupId;
			}

			temporalRef.value = element;
			// comprobamos tanto los ids como el size de lo último que se copió, si viene con un size distinto, necesitará reescalado
			found = lastPaste.value.find(
				(e: Element) => e.id === pastedElements[index].id && isEqual(e.size, pastedElements[index].size)
			);
			if (found && pastePos) {
				// With mouse
				const positionX = pastedElements.length > 1 ? element.position.x : 0;
				const positionY = pastedElements.length > 1 ? element.position.y : 0;

				usingElementTransform.value.move(positionX + offsetX + pastePos.x, positionY + offsetY + pastePos.y, false);
			} else if (found) {
				// With keyboard
				const moveTo: Position = { x: element.position.x, y: element.position.y };

				if (store.activePage?.elements.has(pastedElements[index].id)) {
					moveTo.x =
						found.x + PASTE_OFFSET > artboardSizeInPx.value.width - 20
							? found.x - PASTE_OFFSET
							: found.x + PASTE_OFFSET;
					moveTo.y =
						found.y + PASTE_OFFSET > artboardSizeInPx.value.height - 20
							? found.y - PASTE_OFFSET
							: found.y + PASTE_OFFSET;
				}

				usingElementTransform.value.move(moveTo.x, moveTo.y, false);

				found.x = element.position.x;
				found.y = element.position.y;
			}

			if (found) addElement(element);
			/**  Guardamos en el array todos los elementos clonados para luego usarlos en el setSelection,
			 en caso de tener establecidos grupos, esperamos a que estos se establezcan */
			clonedElements.push(element);
		});

		if (found) {
			await new Promise((resolve) => {
				const interval = useIntervalFn(() => {
					if (store.activePage?.domNode()?.classList.contains('canvas-rendering-finished')) {
						interval.pause();
						resolve(store.activePage);
					}
				}, 100);
			});
			//  Seleccionamos los elementos clonados evitando el foreground porque el foreground se ajusta al ancho y propio background
			clonedElements.forEach((el) => {
				if (el.type !== 'foregroundImage') return setSelection(el, true);
			});
		}
		removeOpacityToToolbarsAndElements(clonedElements);

		await nextTick();

		if (!found) {
			await pasteElementsToDifferentRatio(clonedElements);
		}

		restoreOpacityToToolbarsAndElements(clonedElements);
		temporalRef.value = null;
		Bugsnag.leaveBreadcrumb(`Paste ${selectionType.value}: ${selection.value.map((el) => el.type + '-' + el.id)}`);

		// En mobile eliminamos el clipboard una vez pegado el contenido
		if (isMobile.value) {
			copy('');
		}
	};

	/**
	 *  agrega al canvas los elementos que teníamos en el clipboard aplicando un escalado.
	 * 	cubre los siguientes casos:
	 * 	 - agregar un elemento del clipboard que viene de otro proyecto
	 *   - agregar un elemento del clipboard que viene del mismo proyecto pero con diferente artboard
	 *
	 * 	@param elements elementos que tenemos en el clipboard
	 */

	const pasteElementsToDifferentRatio = async (elements: Element[]) => {
		if (hasNotAllowedTags.value) {
			copy('');
			return;
		}
		store.isRemoveElementOutsidePaused = true;

		// Si solo hemos pegado un elemento o son 2 elementos y uno de ellos es un foregroundImage
		// calculamos su nuevo tamaño y lo centramos
		if (elements.length === 1 || (elements.length === 2 && elements.find((cE) => cE.type === 'foregroundImage'))) {
			// Si estamos intentando reescalar el foreground image nos salimos
			if (focused.value instanceof ForegroundImage) {
				temporalRef.value = elements.find((cE) => !(cE instanceof ForegroundImage));
			}

			elements.forEach(async (el) => {
				if (el instanceof ForegroundImage) {
					temporalForeground.value = el;
					syncSizeWithBackground();
					await syncPositionWithBackground();
					return;
				}
				const factor = TransformTools.getFactorToFitInNewRatio(artboardSizeInPx.value, el.size);

				let finalWidth = el.size.width;
				let finalHeight = el.size.height;

				if (artboardSizeInPx.value.width < el.size.width || artboardSizeInPx.value.height < el.size.height) {
					finalWidth = finalWidth * factor;
					finalHeight = finalHeight * factor;

					if (focused.value instanceof Text) focused.value.scale = factor;
				}

				await nextTick();
				usingElementTransform.value.resize(finalWidth, finalHeight);
				usingElementTransform.value.align(Anchor.center);
			});

			elements.sort(sortByIndex).forEach((el) => addElement(el));
		} else {
			// Si hemos pegado más de 1 elemento y no es el mismo template (es decir de un template a otro)
			// Calculamos su nuevo tamaño
			temporalGroup.value = elements;

			const factor = TransformTools.getFactorToFitInNewRatio(artboardSizeInPx.value, groupSize.value);

			const finalWidth = groupSize.value.width * factor;

			if (artboardSizeInPx.value.width > artboardSizeInPx.value.height) {
				await resizeGroup(finalWidth / 2);
			} else if (artboardSizeInPx.value.width < artboardSizeInPx.value.height) {
				await resizeGroup(finalWidth);
			} else {
				await resizeGroup(finalWidth);
			}

			// Forzamos el actualizado de la información del grupo porque si no no se realinea bien
			await nextTick();
			await alignGroup(Anchor.center);
			elements.forEach((el) => addElement(el));
			await new Promise((resolve) => {
				const interval = useIntervalFn(() => {
					if (store.activePage?.domNode()?.classList.contains('canvas-rendering-finished')) {
						interval.pause();
						resolve(store.activePage);
					}
				}, 100);
			});

			// Eliminamos el grupo temporal
			temporalGroup.value = [];
		}

		elements.forEach((el) => {
			if (el.type !== 'foregroundImage') return setSelection(el, elements.length > 1);
		});
		// Reanudamos de nuevo el eliminado de elementos fuera del artboard
		store.isRemoveElementOutsidePaused = false;
	};

	const pasteNewText = async (newText: string, pasteOverExistingText?: boolean) => {
		if (hasNotAllowedTags.value || checkIfHasNotAllowedTags(newText)) {
			copy('');
			return;
		}

		temporalText.value = Text.create({ fontSize: 24, fontWeight: 400, lineHeight: 1.2 });

		const textContent = TextTools.removeMetaTags(newText);

		const textStyle = {
			text: textContent,
			fontSize: 24,
			lineHeight: 1.2,
			fontWeight: parseInt(
				getNearestWeight(inUseFonts.value.length > 0 ? inUseFonts.value[0].family : 'Montserrat', '600')
			) as FontWeight,
			fontFamily: inUseFonts.value.length > 0 ? inUseFonts.value[0].family : 'Montserrat',
		};

		// si el newText es muy largo, más de 500 caracteres, reducimos el tamaño de la fuente a 12 para que quepa el texto en el artboard
		if (textContent.length > 500) {
			textStyle.fontSize = 12;
		}

		addInsertableText(textStyle);

		await nextTick();

		if (selection.value[0] instanceof Text) {
			selection.value[0].updateContent(textContent);

			usingElementTransform.value.align(Anchor.center);

			// Si estamos pegando encima de un texto existente lo movemos un poco para que no se solape
			if (pasteOverExistingText) {
				selection.value[0].position.x += 30;
				selection.value[0].position.y += 30;
			}
		}
	};

	const pasteSVG = (svg: string) => {
		addInsertableShape(svg);
	};

	/**
	 *  agrega al canvas el texto que tenemos en el clipboard copiado desde Icebreaker o Exit Ticket.
	 *  agrega un elemento de tipo Text
	 *
	 */
	const pasteTextFromAiTools = async () => {
		const text = await navigator.clipboard.readText();
		const textStyle = {
			text: text,
			fontSize: 10,
			lineHeight: 1.2,
			fontWeight: parseInt(getNearestWeight('Montserrat', '400')) as FontWeight,
			fontFamily: 'Montserrat',
		};
		addInsertablePasteText(textStyle);

		const params = useUrlSearchParams<{
			variant: string | undefined;
			template: string;
			aitool: boolean | undefined;
		}>();

		params.aitool = undefined;
	};

	const removeOpacityToToolbarsAndElements = (elements: Element[]) => {
		elements.forEach((el) => {
			const finalEl = document.querySelector(`#element-${el.id}`) as HTMLElement;
			if (!finalEl) return;
			finalEl.style.opacity = '0';
		});
	};

	const restoreOpacityToToolbarsAndElements = (elements: Element[]) => {
		elements.forEach((el) => {
			const finalEl = document.querySelector(`#element-${el.id}`) as HTMLElement;
			if (!finalEl) return;
			finalEl.style.opacity = '1';
		});
	};

	const hideContentClipboard = () => {
		if (!hasContent.value) return;

		if (!text.value?.startsWith('wepik|')) {
			temporalCopy.value = text.value;
			copy('');
		}
	};

	const restoreContentClipboard = () => {
		if (!temporalCopy.value) return;

		if (!text.value?.startsWith('wepik|')) {
			copy(temporalCopy.value);
		}
	};

	return {
		canBeCopied,
		canBePasted,
		currentClipboardData,
		hasContent,
		pasteSelection,
		pasteNewText,
		pasteTextFromAiTools,
		pasteSVG,
		// Exportamos el text y copy de vueuse para que el text.value sea compartido
		text,
		copy,
		copySelection,
		hideContentClipboard,
		restoreContentClipboard,
		hasImageInClipboard,
		checkNavigatorPermissionsToReadTextFromClipboard,
	};
});
