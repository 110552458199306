export class ToSerialize {
	// Callback function to stringify Maps
	private static callback(key: string, value: any) {
		if (value instanceof Map) {
			return Object.fromEntries(value);
		}

		return value;
	}

	/**
	 * Serialize an object to JSON
	 *
	 * @param item Item to serialize
	 * @param asString Convert to string or not
	 * @returns
	 */
	static do<T extends object>(item: T, asString?: boolean) {
		const serialized = JSON.stringify(item, ToSerialize.callback);

		return asString ? serialized : JSON.parse(serialized);
	}
}
