import { AnyFn, tryOnScopeDispose, tryOnUnmounted } from '@vueuse/core';
import { getCurrentScope, onScopeDispose } from 'vue';

export const tryOnUnmountedAndDispose = (callback: AnyFn) => {
	if (getCurrentScope()) {
		onScopeDispose(callback);
		return;
	}
	tryOnUnmounted(callback);
};
