import { LayerCanvas } from '@/apps/mockup/classes/Layers/Layer';
import compose from '@/apps/mockup/composition/compose';
import { GroupLayerScheme, LayerType } from '@/apps/mockup/schemas';
import {CanvasUtils} from "@/apps/mockup/classes/CanvasUtils";

export class GroupLayer extends LayerCanvas implements GroupLayerScheme {
	children: Array<LayerCanvas>;
	isNecessaryBuild: boolean | undefined;
	processDecision: CallableFunction;

	constructor(Layer: LayerCanvas, children: Array<LayerCanvas>, processDecision: CallableFunction) {
		super(Layer);
		this.children = children;
		this.processDecision = processDecision;
	}

	buildGroup(): HTMLCanvasElement {
		this.canvas.height = this.size.height;
		this.canvas.width = this.size.width;

		const ctxLocal: CanvasRenderingContext2D = this.canvas.getContext('2d') as CanvasRenderingContext2D;
		ctxLocal.globalAlpha = this.getAlpha();
		const temporal = compose().getResult();
		compose().clear();

		// Si algo falla aquí está el error
		CanvasUtils.freedomMethod(this.canvas);

		this.isNecessaryBuild = false;
		for (const [index, child] of this.children.entries()) {
			//Pass through virtual
			if ((child.kind == LayerType.group && !child.mask) || (child.blendMode == 'passThrough' && !child.mask)) {
				const childToIterate: GroupLayer = child as GroupLayer;
				if (childToIterate.children.length !== 0) {
					childToIterate.children.reverse();
					for (const grandchild of childToIterate.children) {
						this.children.splice(index + 1, 0, grandchild);
					}
					//Delete children from child
					childToIterate.children = [];
				}
			} else {
				this.processDecision(child, ctxLocal, true);
				if (child.hadChanges) this.isNecessaryBuild = true;
			}
		}

		if (this.mask) {
			const canvasMask = this.createMask();
			ctxLocal.globalCompositeOperation = 'destination-in';
			compose().run(canvasMask, 'destination-in');
		}
		this.canvas = compose().getResult();
		this.canvas.id = 'group-temporal result';
		compose().clear();
		compose().run(temporal);
		return this.canvas;
	}
}
