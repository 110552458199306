import {GroupLayerScheme, Layer, LayerType, SmartObjectLayerScheme} from "@/apps/mockup/schemas";
import {LayerCanvas} from "@/apps/mockup/classes/Layers/Layer";
import {mockupLayers} from "@/apps/mockup/migration/composable/useMockupMigration";

export class LayerUtils {

	/**
	 * This method is used to find a layer by its id
	 * @param id - The id of the layer to find
	 * @param layers - The layers to search
	 * @returns The layer found or null if it is not found
	 */
	public static findLayerById(id: string, layers: Layer[]): Layer | null {
		for (const layer of layers) {
			if (layer.id == id) return layer;
			if (layer.kind == LayerType.group) {
				const foundLayer = LayerUtils.findLayerById(id, (layer as GroupLayerScheme).children);
				if (foundLayer) return foundLayer;
			}
		}
		return null
	}

	/**
	 * This method is used to find the smart object instances of a layer
	 * @param layerId - The id of the layer to find the smart object instances
	 * @param layers - The layers to search
	 * @returns The smart object instances found
	 */
	public static findsmartObjectInstances( layerId: string, layers: Layer[]): string[] {
		const layer = LayerUtils.findLayerById(layerId, layers);
		if (!layer || layer.kind != LayerType.smartObject) return [] // If the layer is not found return an empty array
		const smartObjectLayer = layer as SmartObjectLayerScheme;

		const smartObjectInstances: string[] = [];
		function recursiveFind(children: any, layers: any[]) {
			for (const child of children) {
				if (child.kind == LayerType.smartObject && child.smartObjectDocumentId == smartObjectLayer.smartObjectDocumentId) {
					smartObjectInstances.push(child.id);
				}
				if (child.kind == LayerType.group) recursiveFind(child.children, layers);
			}
		}
		recursiveFind(mockupLayers.value, layers);

		return smartObjectInstances;
	}
}
