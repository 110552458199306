import {useMockupStore} from "../mockupStore";
import {useProjectStore} from "../../../project/stores/project";
import {useInteractiveZone} from "@/apps/mockup/composable/UseInteractiveZone";
import {SolidColor} from "@/color/classes/SolidColor";
import {MockupElement, MockupElementColor} from "@/apps/mockup/schemas";
import {Color} from "@/Types/colorsTypes";
import {GradientColor} from "@/color/classes/GradientColor";
import {DrawMockup} from "@/apps/mockup/facades/drawMockup";
import Page from "@/page/classes/Page";
import {versioning} from "@/apps/mockup/facades/versioningChanges";
import Element from "@/elements/element/classes/Element";
import {ElementDTO, ImageDTO, TextDTO, BoxDTO, ShapeDTO, StorysetDTO, LineDTO} from "@/Types/elements";
import Image from "@/elements/medias/images/image/classes/Image";
import {Text} from "@/elements/texts/text/classes/Text";
import {Box} from "@/elements/box/classes/Box";
import {Shape} from "@/elements/shapes/shape/classes/Shape";
import Storyset from "@/elements/storyset/classes/Storyset";
import Line from "@/elements/line/classes/Line";
import {Filter} from "@/elements/medias/filter/classes/Filter";

export class DataStructureToJsonAdapter {
	convertToJSON(): any[] {
		const mockups = useMockupStore();
		const project = useProjectStore();

		const elements = mockups.getElements;
		return elements.map((element) => {
			let page: any = project.pages.find((page) => page.id === element.id)

			if (page) {
				const elements = Array.from(page?.elements?.entries() ?? [])
				page = versioning().normalizeData(page)
				page.elements = elements
			}

			return {
				color: element.color,
				id: element.id,
				page: page,
			};
		})
	}

	convertFromJSON(json: any, options: { drawMockup?: boolean, registerChange?: boolean} = { drawMockup: true, registerChange: false } ) {
		const { drawMockup, registerChange } = options;

		const mockups = useMockupStore();
		const project = useProjectStore();
		json.forEach((element: any) => {
			const el = mockups.getElements.find((el) => el.id === element.id);
			// @ts-ignore
			Object.keys(element).forEach((key: keyof MockupElement) => {
				if (!(el) || el[key] == undefined) {
					const temporal = key as string
					if (temporal == 'page') {
						const page = project.getPageById(element.page.id)!
						this.updateBackgroundColor(element.page, page);
						const isChanged = this.handleElement(element.page.elements, page.elements);
						if (isChanged && registerChange) {
							mockups.newTextureChange(page.id);
						}
					}
					return;
				}
				if (
					el[key] instanceof SolidColor ||
					el[key] instanceof GradientColor
				) {
					const newColor = this.validateColor(element[key]);
					const oldColor = el[key] as Color;
					mockups.updateMockupColor(oldColor, newColor);
				} else {
					el[key] = element[key];
				}
			})
		})

		if (drawMockup) DrawMockup.forceDraw();

		return this;
	}

	private validateColor(color: any): GradientColor | SolidColor {
		const newColor = color.stops ? GradientColor.fromObject(color) : SolidColor.fromObject(color);
		newColor.id = color.id;
		return newColor;
	}

	private updateBackgroundColor(newPage: Page, oldPage: Page) {
		oldPage.background = this.validateColor({...newPage.background});
		oldPage.background.id = newPage.background.id;
		oldPage.background.validForVariant = newPage.background.validForVariant;
	}

	/**
	 * Handle the elements of the page
	 * @param newElements - New elements to add
	 * @param oldElements - Old elements to update
	 * @private
	 * @returns {boolean} - If the page has changed
	 */
	private handleElement(newElements: string[][], oldElements: Map<string, Element>): boolean {
		// Delete old elements that are not in the new page
		oldElements.clear()
		if (newElements.length == 0) return false;

		const elementCreators: Record<string, (dto: ElementDTO) => Element> = {
			'image': (dto: ElementDTO) => this.createImage(dto as ImageDTO),
			'text': (dto: ElementDTO) => this.createText(dto as TextDTO),
			'box': (dto: ElementDTO) => this.createBox(dto as BoxDTO),
			'shape': (dto: ElementDTO) => this.createShape(dto as ShapeDTO),
			'storyset': (dto: ElementDTO) => this.createStorySet(dto as StorysetDTO),
			'line': (dto: ElementDTO) => this.createLine(dto as LineDTO),
		};

		// Add and update elements
		for (const [id, el] of newElements) {
			const element = el as unknown as ElementDTO;
			element.subElements = new Map();
			const creator = elementCreators[element.type];
			if (!creator) console.warn('Element type not found');

			const createdElement = creator(element);
			oldElements.set(id, createdElement);
		}
		return true;
	}

	private createLine(element: LineDTO) {
		element.mainColor = this.validateColor(element.mainColor);
		if (element.markerStart) element.markerStart.color = this.validateColor(element.markerStart.color);
		if (element.markerEnd) element.markerEnd.color = this.validateColor(element.markerEnd.color);

		const line = Line.create(element);
		line.id = element.id;
		return line;
	}

	private createStorySet(element: StorysetDTO) {
		element.mainColor = this.validateColor(element.mainColor);
		const storySet = Storyset.create(element);
		storySet.id = element.id;
		return storySet;
	}

	private createShape(element: ShapeDTO) {
		element.colors = element.colors.map((color) => this.validateColor(color));
		const shape = Shape.create(element);
		shape.id = element.id;
		return shape;
	}

	private createBox(element: BoxDTO) {
		element.background = this.validateColor(element.background);
		element.border.color = this.validateColor(element.border.color);
		const box = Box.create(element);
		box.id = element.id;
		return box;
	}

	private createText(element: TextDTO) {
		element.colors = element.colors.map((color) => this.validateColor(color));
		element.outline.color = SolidColor.fromObject(element.outline.color);
		const text = Text.create(element);
		text.id = element.id;
		return text;
	}
	private createImage(element: ImageDTO) {
		if (element.filter) element.filter = new Filter(element.filter);
		const image = Image.create(element);
		image.id = element.id;
		return image;
	}
}
