import jsonpatch from 'fast-json-patch'
import {Operation} from 'fast-json-patch';
import {DrawMockup} from "@/apps/mockup/facades/drawMockup";
import {toRef} from "@vueuse/core";
import {ref, Ref} from "vue";

export class VersioningChanges {

	private static instance: VersioningChanges;

	private history: Ref<any[]> = ref([]);
	private undone: Ref<any[]> = ref([]);
	private currentStatus: any;

	public static getInstance(): VersioningChanges {
		if (!VersioningChanges.instance) {
			VersioningChanges.instance = new VersioningChanges();
		}
		return VersioningChanges.instance;
	}

	private setCurrentStatus(status: any) {
		this.currentStatus = status;
	}

	public normalizeData(data: any) {
		return JSON.parse(JSON.stringify(data));
	}

	public registerChange(change: any) {
		change = this.normalizeData(change);
		if (!this.currentStatus) {
			this.setCurrentStatus(change);
			return;
		}

		const patch = jsonpatch.compare(this.currentStatus, change, true);
		if (patch.length === 0) {
			return;
		}
		this.setCurrentStatus(change);
		this.history.value.push(JSON.parse(JSON.stringify(patch)));

		this.undone.value = [];
	}

	public undo() {
		let patch = this.history.value.pop();

		if (!patch) {
			return;
		}
		this.undone.value.push(
			this.normalizeData(patch)
		);

		patch = patch.map((changes: Operation) => {
			if (changes.op === 'test') {
				return {
					op: this.getInverseOperation(
						patch.find((change: Operation) => change.path == changes.path && change.op != 'test')!.op
					),
					path: changes.path,
					value: changes.value
				}
			}
			if(changes.op === 'add'){
				return {
					op: 'remove',
					path: changes.path
				}
			}
			return {}
		}).filter((change: Operation) => Object.keys(change).length > 0);

		const status = jsonpatch.applyPatch(this.currentStatus, patch, true).newDocument;

		for(const change of patch){
			if(change.path.includes('page')){
				DrawMockup.setRequiresPageUpdate(status[change.path.split('/')[1]].page.id)
			}
		}

		this.setCurrentStatus(status);
		return this.currentStatus;
	}

	public redo() {
		let patch = this.undone.value.pop();
		if (!patch) return;
		if (patch.length == 0) return

		this.history.value.push(
			this.normalizeData(patch)
		);

		patch = patch.filter((changes: Operation) => {
			return changes.op !== 'test'
		})

		const status = jsonpatch.applyPatch(this.currentStatus, patch).newDocument;
		for (const change of patch) {
			if(change.path.includes('page')){
				DrawMockup.setRequiresPageUpdate(status[change.path.split('/')[1]].page.id)
			}
		}
		this.setCurrentStatus(status);
		return this.currentStatus;
	}

	private getInverseOperation(operation: string): string{
		let value = '';
		switch (operation) {
			case 'add':
				value = 'remove';
				break;
			case 'remove':
				value = 'add';
				break;
			case 'replace':
				value = 'replace';
				break;
			default:
				value = '';
				break;
		}
		return value;
	}

	public hasUndo(): boolean {
		return this.history.value.length > 0;
	}

	public hasRedo(): boolean {
		return this.undone.value.length > 0;
	}
}

export const versioning = () => {
	return VersioningChanges.getInstance();
}
