import Bugsnag from '@bugsnag/js';
import { until } from '@vueuse/core';
import sysend from 'sysend';
import { computed, nextTick, ref, watch } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { useEditorApiFetch } from '@/api/composables/useEditorApiFetch';
import { useCustomImagesActions } from '@/api/composables/useUploadImagesActions';
import { checkProvider, getUrlToBigUpload, uploadBigFile } from '@/api/UserApiClient';
import { useAuth } from '@/auth/composables/useAuth';
import { useEnvSettings } from '@/common/composables/useEnvSettings';
import { useToast } from '@/common/composables/useToast';
import { useTracksJob } from '@/common/composables/useTracksJob';
import { FilesTools } from '@/common/utils/FilesTools';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import ImageTools from '@/elements/medias/images/image/utils/ImageTools';
import { VideoTools } from '@/elements/medias/video/utils/VideoTools';
import { useI18n } from '@/i18n/useI18n';
import { useAddInsertableElement } from '@/interactions/composables/useAddInsertableElement';
import { useProjectStore } from '@/project/stores/project';
import { ImageApi, VideoApi } from '@/Types/apiClient';
import { ImageMimeTypesAccepted, Panels, UploadTypes, VideoMimeTypesAccepted } from '@/Types/types';

const userUploads = ref<(ImageApi | VideoApi)[]>([]);
const UPLOAD_MAX_SIZE = 1e9; // (1gb)

export const useUserImageProvider = () => {
	const { isLogged, requireAuth, user } = useAuth();
	const project = useProjectStore();
	const { APP_BASE, APP_GOOGLE_APP_ID, APP_GOOGLE_API_KEY } = useEnvSettings();
	const isInvalidFile = ref();
	const toast = useToast();
	const { trans } = useI18n();
	const oauthWindow = ref();
	const files = ref();
	const toastId = ref();
	const currentUpload = ref();
	const shouldAddImageToCanvas = ref(false);
	const isUploading = ref(false);
	const uploadImageInput = ref();
	const { uploadImage } = useCustomImagesActions();
	const { addInsertableElement } = useAddInsertableElement();

	const { uploadImageToServer, onFetchResponse, onFetchError, data: imageApi, statusCode } = uploadImage();

	const store = useMainStore();

	onFetchResponse(async () => {
		// Si es de tipo video no vamos a tener todavía el vídeo en el backend, ya que se manda a cola para comprimir
		// temporalmente se deja añadir como un blob y cuando el socket responda cambiamos las urls
		if (imageApi.value.type === 'video') {
			const url = URL.createObjectURL(currentUpload.value.file);
			const size = await VideoTools.getRealSize(url);
			const previews = await VideoTools.generateSnapshot(url, size, 1);

			imageApi.value.metadata.temporal = true;
			store.uploads.set(imageApi.value.id, { url, preview: previews[0] });
		}
		userUploads.value.unshift(imageApi.value);

		// Comprobamos si la imagen se ha añadido desde clipboard y la insertamos en la página activa
		if (shouldAddImageToCanvas.value) {
			await addInsertableElement(imageApi.value);
		}

		isUploading.value = false;
		currentUpload.value = undefined;
	});

	onFetchError(() => {
		if (statusCode.value === 429) {
			toast.error(trans('Daily upload limit exceeded'));
		} else {
			toast.error(
				currentUpload.value.name.length
					? trans('Something went wrong with the upload called: {name}', { name: currentUpload.value.name })
					: trans('Something went wrong with the upload')
			);
		}
	});

	// Vigilamos los cambios en files para lanzar las subidas de los archivos
	watch(
		files,
		async (newFiles) => {
			toastId.value = toast.success(trans('Starting upload...'), {
				timeout: false,
				toastClassName: 'custom-spinner-toast bg-darkblue-900',
			});
			let uploadIndex = 0;

			for (const file of newFiles) {
				let compressedFile = file.file;
				if (file.type === 'image' && file.provider === 'local') {
					try {
						compressedFile = await ImageTools.compressImage(file.file);
					} catch (e) {
						isUploading.value = false;
					}
				}
				currentUpload.value = file;

				if (file.type === 'video' && file.provider === 'local') {
					const { data: urlGcs } = await getUrlToBigUpload();
					// En la respuesta siempre pasa por el catch aunque sea un 200 por un problema de cors con GCS
					try {
						await uploadBigFile(urlGcs.value.url, compressedFile);
					} catch (e) {
						await uploadImageToServer(file.type, file.provider, urlGcs.value.id, urlGcs.value.id);
					}

					const { trackJob } = useTracksJob();
					trackJob(imageApi.value.trackedJob, 600000, 10000)
						.then((response) => {
							const index = userUploads.value.findIndex((upload) => upload.id === imageApi.value.id);

							if (index < 0) return;

							const { trackedJob, ...updatedData } = response;

							project.allVideos
								.filter((video) => video.metadata.uploadId === imageApi.value.id)
								.forEach((video) => {
									video.url = updatedData.url;
									video.preview = updatedData.preview;
									if (video.metadata.temporal) delete video.metadata.temporal;
								});
							store.uploads.delete(imageApi.value.id);

							userUploads.value.splice(index, 1, updatedData);

							GAnalytics.trackGA4('upload-video', { link_text: 'Upload video', successfully_uploaded: 'true' });
						})
						.catch(() => {
							const index = userUploads.value.findIndex((upload) => upload.id === imageApi.value.id);

							if (index < 0) return;

							project.allVideos
								.filter((video) => video.metadata.uploadId === imageApi.value.id)
								.forEach((video) => {
									video.toNotFound();
									if (video.metadata.temporal) delete video.metadata.temporal;
								});
							store.uploads.delete(imageApi.value.id);
							userUploads.value.splice(index, 1);
							GAnalytics.trackGA4('upload-video', { link_text: 'Upload video', successfully_uploaded: 'false' });
						});
				} else {
					await uploadImageToServer(file.type, file.provider, compressedFile);
				}

				// Update it later
				toast.update(toastId.value, { content: `Uploading ${uploadIndex + 1} of ${newFiles.length}...` });

				await nextTick();

				uploadIndex++;
			}

			toast.dismiss(toastId.value);

			// Reseteamos el input para permitir subir las mismas imagenes cuando ya se hayan subido todas
			const photoSelectorInput = uploadImageInput.value;

			if (photoSelectorInput) {
				photoSelectorInput.value = '';
			}

			// Comprobamos si la imagen se ha añadido desde clipboard o con el botón de upload y volvemos a ponerlo a false cuando termina
			if (shouldAddImageToCanvas.value) {
				shouldAddImageToCanvas.value = false;
			}
		},
		{ deep: true }
	);

	const checkIsLogged = async () => {
		if (isLogged.value) return;
		requireAuth();
		await until(isLogged).toBeTruthy();
	};

	const uploadImages = (e: Event, shouldAddToCanvas = false) => {
		shouldAddImageToCanvas.value = shouldAddToCanvas;
		isUploading.value = true;
		uploadFromLocal(e);
		GAnalytics.track('click', 'Button', 'upload-image', null);
	};
	// LOCAL ---------------------------------------------------------------------

	const selectFromLocal = async () => {
		await checkIsLogged();

		uploadImageInput.value?.click();
	};

	const emptyUserUploads = () => {
		userUploads.value = [];
	};

	const uploadFromLocal = async (e: any, source = 'editor') => {
		await checkIsLogged();
		const fromDragging = (e.dataTransfer?.files || []).length > 0;
		const selectedFiles = Array.from<File>(e.target?.files || e.dataTransfer?.files).filter((file) => {
			// Recorremos todos los elementos para ver si alguno no tiene tamaño
			// (hasta ahora sólo hemos detectado los archivos comprimidos sin tamaño)
			if (file.size) return true;

			toast.error(`Invalid file ${file.name}`);

			return false;
		});

		if (!selectedFiles.length) return;

		await convertToBase64AndAddToFiles(selectedFiles, fromDragging);

		Bugsnag.leaveBreadcrumb(
			`User upload ${selectedFiles.length} local file: ${Array.from(selectedFiles).map((file) => file.type)}`
		);
	};

	/**
	 * Subida desde el clipboard
	 * */
	const uploadFromClipboard = async (pastedFiles: File[]) => {
		shouldAddImageToCanvas.value = true;
		await checkIsLogged();
		const selectedFiles = pastedFiles;

		if (!selectedFiles) return;

		await convertToBase64AndAddToFiles(selectedFiles);
	};

	const { isPhotoMode, isDisneyMode } = useEditorMode();

	const convertToBase64AndAddToFiles = async (selectedFiles: File[], fromDragging = false) => {
		const bases64 = [];
		const mimeTypesAccepted =
			isPhotoMode.value || isDisneyMode.value
				? [...Object.values(ImageMimeTypesAccepted)]
				: [...Object.values(ImageMimeTypesAccepted), ...Object.values(VideoMimeTypesAccepted)];

		for (const file of selectedFiles) {
			isInvalidFile.value = !mimeTypesAccepted.includes(file.type as ImageMimeTypesAccepted | VideoMimeTypesAccepted);

			if (isInvalidFile.value) {
				toast.error(trans('Invalid file'));
				isUploading.value = false;
				return;
			}

			if (file.size > UPLOAD_MAX_SIZE) {
				toast.error(
					file.name
						? trans('File with name {name} exceed the limit size (1gb)', { name: file.name })
						: trans('File exceed the limit size (1gb)')
				);
				isUploading.value = false;
				return;
			}

			const type = getFileType(file.type as VideoMimeTypesAccepted | ImageMimeTypesAccepted);
			let base64 = '';

			if (type !== 'video') {
				base64 = await FilesTools.blobToBase64(file);
			}

			GAnalytics.trackGA4('upload_file', {
				location: fromDragging ? 'drag_and_drop' : 'side_bar',
				category: type,
			});

			bases64.push({
				type,
				provider: 'local',
				file: type === 'video' ? file : base64,
				name: file.name,
			});
		}

		if (!bases64.length) {
			shouldAddImageToCanvas.value = false;
		}

		files.value = bases64;

		await nextTick();
	};

	const getFileType = (type: ImageMimeTypesAccepted | VideoMimeTypesAccepted): UploadTypes => {
		if (type === ImageMimeTypesAccepted.SVG) return UploadTypes.SVG;
		if (Object.values(VideoMimeTypesAccepted).includes(type as VideoMimeTypesAccepted)) return UploadTypes.Video;

		return UploadTypes.Image;
	};

	const getClipboardFilesAndUploadImages = async (e: ClipboardEvent) => {
		if (!e.clipboardData || e.clipboardData.items.length === 0) {
			return;
		}

		const dataList = Array.from(e.clipboardData?.items).filter(
			(i) => i.type === 'image/png' || i.type === 'image/jpeg' || i.type === 'image/jpg' || i.type === 'image/svg+xml'
		);

		if (!dataList.length) {
			toast.error(trans('Invalid file'));
			return;
		}

		const files: File[] = [];

		for (const image of dataList) {
			const file = await image.getAsFile();

			if (file) {
				files.push(file);
			}
		}

		if (files.length) {
			uploadFromClipboard(files);
		}
	};

	// DROPBOX ---------------------------------------------------------------------
	const authenticatedInDropbox = computed(() => user.value?.tokens.dropbox.status);
	const {
		data: checkDropbox,
		execute: executeCheckDropbox,
		onFetchResponse: onFetchDropboxResponse,
	} = checkProvider('dropbox');

	onFetchDropboxResponse(() => {
		user.value!.tokens.dropbox = checkDropbox.value;
	});

	const registerSysendDropbox = () => {
		sysend.on(`oauth:dropbox`, async () => {
			oauthWindow.value?.close();
			await executeCheckDropbox();

			if (authenticatedInDropbox.value) {
				sysend.off(`oauth:dropbox`);
				toast.success(trans('Dropbox account linked succesfully'));
			}
		});
	};

	const loadDropbox = () => {
		if (document.querySelector('#dropboxjs')) {
			return Promise.resolve();
		}

		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = 'https://www.dropbox.com/static/api/2/dropins.js';
		script.id = 'dropboxjs';
		script.dataset.appKey = 'v8yifh0jz4bs5iu';

		return new Promise((resolve) => {
			document.head.appendChild(script);
			script.addEventListener('load', resolve);
		});
	};

	const selectFromDropbox = async () => {
		await checkIsLogged();

		// Cargamos la librería si no está
		await loadDropbox();

		// Si no estamos autenticados en dropbox hacemos check para comprobar si tenemos el token
		if (!authenticatedInDropbox.value) {
			await executeCheckDropbox();
		}

		// Si no tenemos token lo pedimos
		if (!authenticatedInDropbox.value) {
			oauthWindow.value = window.open(
				`${APP_BASE}auth/provider?provider=dropbox`,
				`Dropbox Auth`,
				'height=800,width=700'
			);

			return;
		}

		// Si tenemos token dejamos elegir fotos
		window.Dropbox.choose({
			// Required. Called when a user selects an item in the Chooser.
			success: (filesSelected: any) => {
				files.value = filesSelected.map((file: any) => ({
					type: 'image',
					provider: 'dropbox',
					file: file.id,
				}));
				if (store.activePanel !== Panels.uploads) store.activePanel = Panels.uploads;
			},
			cancel: () => {
				return false;
			},
			linkType: 'preview', // or "direct"
			multiselect: true, // or true
			extensions: ['images'],
			folderselect: false,
		});
	};

	// GOOGLE DRIVE ---------------------------------------------------------------------
	const authenticatedInGoogle = computed(() => user.value?.tokens.google.status);
	const googleToken = computed(() => user.value?.tokens?.google?.token || checkGoogle.value?.token);
	const { data: checkGoogle, execute: executeCheckGoogle, onFetchResponse: onFetchGoogle } = checkProvider('google');

	onFetchGoogle(() => {
		user.value!.tokens.google = checkGoogle.value;
	});

	const logoutGoogle = async () => {
		await useEditorApiFetch('google/logout').post();
		if (user.value) {
			user.value.tokens.google.status = false;
			user.value.tokens.google.token = null;
		}
	};

	const loadDrive = () => {
		if (document.querySelector('#googlejs')) {
			return Promise.resolve();
		}

		const script = document.createElement('script');
		script.src = 'https://apis.google.com/js/api.js?onload=loadPicker';
		script.id = 'googlejs';

		return new Promise((success) => {
			document.head.appendChild(script);
			window.loadPicker = function () {
				window.gapi.load('picker', { callback: success });
			};
		});
	};

	const selectFromDrive = async () => {
		await checkIsLogged();

		await loadDrive();

		if (!authenticatedInGoogle.value) {
			await executeCheckGoogle();
		}

		// Si no tenemos token lo pedimos
		if (!authenticatedInGoogle.value) {
			oauthWindow.value = window.open(
				`${APP_BASE}auth/provider?provider=google`,
				`Google Auth`,
				'height=800,width=700'
			);

			return;
		}

		const { google } = window;

		const images = new google.picker.View(google.picker.ViewId.DOCS_IMAGES);
		images.setMimeTypes('image/png,image/jpeg,image/jpg');

		const picker = new google.picker.PickerBuilder()
			.enableFeature(google.picker.Feature.SIMPLE_UPLOAD_ENABLED)
			.setAppId(APP_GOOGLE_APP_ID)
			.setOAuthToken(googleToken.value)
			.setMaxItems(3)
			.addView(images)
			.setDeveloperKey(APP_GOOGLE_API_KEY)
			.setCallback((event: any) => {
				if (event.action === 'picked') {
					files.value = event.docs.map((file: any) => ({
						type: 'image',
						provider: 'google',
						file: file.id,
					}));
				}
				if (store.activePanel !== Panels.uploads) store.activePanel = Panels.uploads;
			})
			.build();

		picker.setVisible(true);
	};

	const registerSysendGoogle = () => {
		sysend.on(`oauth:google`, async () => {
			oauthWindow.value?.close();
			await executeCheckGoogle();

			if (authenticatedInGoogle.value) {
				sysend.off(`oauth:google`);
				toast.success(trans('Google account linked succesfully'));
			}
		});
	};

	const selectFromPhotos = async () => {
		await checkIsLogged();

		if (!authenticatedInGoogle.value) {
			await executeCheckGoogle();
		}

		// Si no tenemos token lo pedimos
		if (!authenticatedInGoogle.value) {
			oauthWindow.value = window.open(
				`${APP_BASE}auth/provider?provider=google`,
				`Google Auth`,
				'height=800,width=700'
			);

			return false;
		}

		return true;
	};

	const uploadFromPhotos = async (img: ImageApi) => {
		files.value = [
			{
				type: 'image',
				provider: 'google-photos',
				file: img.id,
			},
		];
	};

	return {
		isUploading,
		uploadImageInput,
		uploadImages,
		uploadFromLocal,
		selectFromLocal,
		selectFromDropbox,
		selectFromDrive,
		selectFromPhotos,
		uploadFromPhotos,
		getClipboardFilesAndUploadImages,
		logoutGoogle,
		registerSysendDropbox,
		registerSysendGoogle,
		files,
		authenticatedInDropbox,
		authenticatedInGoogle,
		onFetchResponse,
		onFetchError,
		isInvalidFile,
		actualUpload: imageApi,
		userUploads,
		emptyUserUploads,
	};
};
