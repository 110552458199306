export class FilesTools {
	static dataURItoBlob = (dataURI: string) => {
		const byteString =
			dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);

		const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

		const ia = new Uint8Array(byteString.length);

		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		return new Blob([ia], { type: mimeString });
	};

	static blobToBase64 = (blob: Blob) => {
		const reader = new FileReader();
		reader.readAsDataURL(blob);

		return new Promise<string>((resolve) => {
			reader.onloadend = () => {
				resolve(reader.result as string);
			};
		});
	};

	static async urlToBase64(blobUrl: string): Promise<string> {
		const response = await fetch(blobUrl);
		const blob = new Blob([await response.arrayBuffer()], { type: response.headers.get('Content-Type') as string });

		return new Promise((resolve) => {
			const reader = new FileReader();

			reader.onloadend = () => resolve(reader.result as string);
			reader.readAsDataURL(blob);
		});
	}
	static async urlToBlob(blobUrl: string): Promise<Blob> {
		const response = await fetch(blobUrl);

		return new Blob([await response.arrayBuffer()], { type: response.headers.get('Content-Type') as string });
	}
}
