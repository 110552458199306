import {LayerCanvas} from '@/apps/mockup/classes/Layers/Layer';
import {ImageLayerScheme, Resource} from '@/apps/mockup/schemas';
import {MockupResources} from "@/apps/mockup/classes/MockupResources";

export class ImageLayer extends LayerCanvas implements ImageLayerScheme {
	image: Resource;

	constructor(Layer: LayerCanvas, image: Resource) {
		super(Layer);
		this.image = image;
	}

	buildImage(): HTMLCanvasElement {
		this.canvas.height = this.size.height;
		this.canvas.width = this.size.width;
		const ctxLocal: CanvasRenderingContext2D = this.canvas.getContext('2d') as CanvasRenderingContext2D;
		ctxLocal.globalAlpha = this.getAlpha();

		// Extract image from MockupImages
		ctxLocal.drawImage(MockupResources.getImage(this.image.id)!.domElement, 0, 0, this.size.width, this.size.height);

		if (this.mask) {
			const canvasMask = this.createMask();
			ctxLocal.globalCompositeOperation = 'destination-in';
			ctxLocal.drawImage(canvasMask, 0, 0, this.size.width, this.size.height);
		}
		return this.canvas;
	}
}
