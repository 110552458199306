import { CanvasUtils } from '@/apps/mockup/classes/CanvasUtils';
import { MockupRender3D } from '@/apps/mockup/classes/Render3D';
import { use3DRender } from '@/apps/mockup/composable/use3DRender';
import { useComposite25D } from '@/apps/mockup/composable/useComposite25D';
import { useErrorMockup } from '@/apps/mockup/composable/useErrorMockup';
import { usePreviewPage } from '@/apps/mockup/composable/usePreviewPage';
import { Customizable2D } from '@/apps/mockup/facades/Customizable2D';
import { CustomizableUtils } from '@/apps/mockup/facades/CustomizablesUtils';
import { useMockupStore } from '@/apps/mockup/mockupStore';
import {
	CompositingSize,
	CompositionScaleBySize,
	CustomizableColor,
	MockupElement,
	MockupElement2DTexture,
	MockupElementColor,
	MockupElementTexture,
	MockupElementTextures,
	MockupManifest,
	MockupStoreSettings,
	Size,
} from '@/apps/mockup/schemas';
import { ColorData } from '@/apps/mockup/Types/basicTypes';
import { MockupErrorType } from '@/apps/mockup/Types/errorTypes';
import { ColorDataHandler } from '@/apps/mockup/utils/CompositionTools';
import Page from '@/page/classes/Page';
import { useProjectStore } from '@/project/stores/project';
import { Color } from '@/Types/colorsTypes';

export const useCompositionMockup = () => {
	const { updateSize } = use3DRender();
	const mockup = useMockupStore();
	const project = useProjectStore();
	const { generateTextureCanvas } = useComposite25D();
	const { registerMockupError } = useErrorMockup();

	/**
	 * Extract, generate texture and render of MockupElementTexture in Specific Size
	 * @param {CompositingSize} compositionSize
	 */
	const composeTexturesBySize = async (compositionSize: CompositingSize): Promise<void> => {
		mockup.loading = true;
		for (const textureElement of mockup.getTextureElements) {
			if (textureElement.appliedChanges[compositionSize]) continue; // Validates if is necessary recompose
			const actualManifest = mockup.manifest!;

			const page = project.pages.find((page) => page.id === textureElement.id) as Page;
			if (!page) {
				console.warn('Page not found', textureElement.id);
				continue;
			}

			// In this validation we need to check if the active element is null, because if it is null,
			// it means that the user is not interacting with any element
			if (!mockup.activeElement)
				project.size = { width: textureElement.width as number, height: textureElement.height as number };

			// In this validation we need to check if the active element is not null and if the textureElement id is equal to the active element id
			// this is because the user is interacting with an element and we need to update the size of the project with the size of the element
			if (mockup.activeElement && textureElement.id === mockup.activeElement.id)
				project.size = { width: textureElement.width as number, height: textureElement.height as number };

			if (textureElement.kind == '2dTexture') {
				await Customizable2D.composeTexture({
					mockupElement: textureElement as MockupElement2DTexture,
					currentManifest: actualManifest,
					page,
					options: {
						useDomToImage: mockup.getSetting(MockupStoreSettings.UseDomToImage),
						compositionSize,
					},
				});
				textureElement.appliedChanges[compositionSize] = true; // Set builded texture in this size
				continue;
			}

			const generatedCanvas = await Customizable2D.extractTexture(textureElement, page, {
				useDomToImage: mockup.getSetting(MockupStoreSettings.UseDomToImage),
				compositionSize,
			});

			usePreviewPage.value.updatePreview(page.id, generatedCanvas);
			usePreviewPage.value.setShowTexturePicker(page.id);

			const canvasTexture = await generateTextureCanvas(
				generatedCanvas,
				textureElement as MockupElementTexture,
				CompositionScaleBySize[compositionSize]
			);
			CanvasUtils.freedomMethod(generatedCanvas);
			await performanceRender(actualManifest, canvasTexture, textureElement as MockupElementTexture);
			CanvasUtils.freedomMethod(canvasTexture);
			textureElement.appliedChanges[compositionSize] = true; // Set builded texture in this size
		}
		mockup.loading = false;
	};

	const updateRenderSize = async (size: Size) => {
		await updateSize(size.width, size.height);
	};

	/**
	 * Validate if render scene with id is created
	 * @returns {boolean}
	 */
	const isRenderCreated = (id: string) => {
		return MockupRender3D.existScene(id);
	};

	/**
	 * Create or update render Three Js instance
	 * @param manifest Selected manifest to use render three js instance
	 * @param canvasTexture
	 * @param mockupElement
	 */
	const performanceRender = async (
		manifest: MockupManifest,
		canvasTexture: HTMLCanvasElement,
		mockupElement: MockupElementTexture | null = null
	) => {
		try {
			const omSceneId = manifest.scene3D; //Use this Key to magae all customizables 3D, because this scene contains All mesh of them.
			if (mockup.activeElement?.kind == 'color') return;
			const currentElement = (mockupElement ?? mockup.activeElement) as MockupElementTexture;
			if (!isRenderCreated(omSceneId)) {
				const itemData = {
					id: omSceneId,
					camera: {
						frameWidth: manifest.size.width,
						frameHeight: manifest.size.height,
						name: 'Camera',
					},
					targetMaterialName: currentElement.materialName,
					sceneFileURL: manifest.scene3D,
					texture: canvasTexture,
				};
				const renderImageData = (await MockupRender3D.newScene('OM', itemData)).renderScene(omSceneId);
				CustomizableUtils.updateElements(renderImageData, manifest.size.width, mockupElement as MockupElementTexture);
				return;
			}

			//When update render is necessary
			MockupRender3D.updateSceneSize(omSceneId, manifest.size).updateScene(
				omSceneId,
				canvasTexture,
				currentElement.materialName
			);
			const renderData = MockupRender3D.renderScene(omSceneId);
			CustomizableUtils.updateElements(renderData, manifest.size.width, currentElement);
		} catch (error) {
			registerMockupError(MockupErrorType.renderingError);
		}
	};

	const resetColor = (elementId: string) => {
		const refLayer = mockup.findElementById(elementId) as MockupElementColor;
		const colorEl = mockup.manifest!.customizables.find((el) => el.id == elementId) as CustomizableColor;
		if (!colorEl || !refLayer) return;

		mockup.updateMockupColor(refLayer.color as Color, ColorDataHandler(colorEl.color as ColorData));
	};

	const resetColors = () => {
		for (const element of mockup.getColorElements) {
			resetColor(element.id);
		}
	};

	return {
		performanceRender,
		composeTexturesBySize,
		updateRenderSize,
		resetColors,
		resetColor,
	};
};
