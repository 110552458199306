import { createSharedComposable } from '@vueuse/core';

import { useAuth } from '@/auth/composables/useAuth';
import { useEnvSettings } from '@/common/composables/useEnvSettings';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { TrackingRequest } from '@/Types/types';

export const useTracking = createSharedComposable(() => {
	const { APP_BASE } = useEnvSettings();
	const { mode, isFreepikContext, inSlidesgoContext } = useEditorMode();

	const { user } = useAuth();

	const parts = window.location.hash.substr(1).split('&');
	const store = useMainStore();

	const hashValues = parts.reduce((prev: any, current: string) => {
		const [key, value] = current.split('=');
		prev[key] = value;
		return prev;
	}, {});

	const track = (event: string, extra: object = {}) => {
		const img = new Image();

		const trackingRequest: TrackingRequest = {
			path: window.location.pathname.substr(1),
			ga_client_id: getGoogleId() || '',
			lang: document.documentElement.lang,
			user_id: user.value?.fpId,
			wepik_user_id: user.value?.id,
			referrer: document.referrer || '',
			page: hashValues.page || 1,
			search: hashValues.search || '',
			referral_source: hashValues.rs || 'editor',
			template_category: store.projectCategory?.name,
			mode: mode.value,
			context: isFreepikContext.value ? 'freepik' : inSlidesgoContext.value ? 'slidesgo' : 'wepik',
			cache_buster: parseInt(String(Math.random() * 100000)),
			...extra,
		};

		// data.content_group = window.slg.viewTag || '';
		// data.tag = window.slg.contentTag || '';

		img.src = `${APP_BASE}track?event=${event}&${objectToQueryString(trackingRequest)}`;
	};

	const objectToQueryString = (obj: any) => {
		const str = [];
		for (const p in obj)
			if (obj[p]) {
				str.push(
					encodeURIComponent(p) + '=' + encodeURIComponent(Array.isArray(obj[p]) ? JSON.stringify(obj[p]) : obj[p])
				);
			}
		return str.join('&');
	};

	const getGoogleId = () => {
		try {
			// @ts-ignore
			return document.cookie.match(new RegExp('(^| )fp_ga=([^;]+)'))[2];
		} catch (error) {}

		return null;
	};

	const forUrl = (params: { [key: string]: string }) => {
		const searchParams = new URL(window.location.href).searchParams;
		return Object.entries({
			search: searchParams.get('q') || null,
			page: searchParams.get('page') || '1',
			rs: searchParams.get('rs') || null,
			...params,
		})
			.filter(([, value]) => value !== null)
			.map(([key, value]) => `${key}=${value}`)
			.join('&');
	};

	return {
		track,
		hashValues,
		forUrl,
	};
});
