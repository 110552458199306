import * as THREE from 'three';
import { OrthographicCamera, PerspectiveCamera, Scene } from 'three';
import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader';
import { reactive, Ref, ref, UnwrapNestedRefs } from 'vue';

import { useLoadMockup } from '@/apps/mockup/composable/UseLoadMockup';
import { useMockupStore } from '@/apps/mockup/mockupStore';
import { MockupStoreSettings } from '@/apps/mockup/schemas';
import { RenderData, Size } from '@/apps/mockup/schemas/renderSchema';

let globalCamera: UnwrapNestedRefs<PerspectiveCamera | OrthographicCamera> = reactive(new PerspectiveCamera());
const globalScene: Scene = new Scene();
const renderSize: Ref<Size> = ref({ width: 0, height: 0 });

export const use3DRender = () => {
	const canvas: HTMLCanvasElement = document.getElementById('render') as HTMLCanvasElement;
	const globalRender = new THREE.WebGLRenderer({
		antialias: true,
		canvas,
		alpha: true,
		preserveDrawingBuffer: true,
		logarithmicDepthBuffer: true,
	});

	const canvas2D: HTMLCanvasElement = document.createElement('canvas');
	const ctx2d: CanvasRenderingContext2D = canvas2D.getContext('2d', {
		willReadFrequently: true,
	}) as CanvasRenderingContext2D;

	/**
	 * This function createScene and 3d context in ThreeJs whit colladaloader, mapping Meshes and material Meshes
	 * @param {RenderData}itemData
	 * @returns Blob url from generated render
	 */
	const render = async (itemData: RenderData): Promise<ImageData> => {
		try {
			const mockup = useMockupStore();
			const { canvasToBlob } = useLoadMockup();

			renderSize.value = {
				width: itemData.camera.frameWidth,
				height: itemData.camera.frameHeight,
			};
			globalRender.setPixelRatio(window.devicePixelRatio);
			globalRender.setSize(renderSize.value.width, renderSize.value.height);
			// globalRender.outputEncoding = THREE.sRGBEncoding;
			globalRender.setClearColor(new THREE.Color(0xff0000), 0);

			const ambientLight = new THREE.AmbientLight(0xffffff);
			globalScene.add(ambientLight);

			if (mockup.settings.get(MockupStoreSettings.DebugMode))
				console.log(`Texture ${itemData.targetMaterialName} =>`, await canvasToBlob(itemData.texture));

			const texture = await createTexture(itemData.texture);

			const daeLoader = new ColladaLoader();

			await new Promise((resolve, reject) => {
				daeLoader.load(
					itemData.sceneFileURL,
					async function (mockups) {
						try {
							const mockup = mockups.scene;
							mockup.traverse(function (node: any) {
								if (node.isMesh) {
									const material = new THREE.MeshBasicMaterial({
										color: '#ffffff',
										name: node.material.name,
										alphaTest: 0.01,
										transparent: true,
									});
									node.material = material;
									//Render by name
									if (node.material.name == itemData.targetMaterialName) {
										node.material.map = texture;
									} else {
										node.visible = false;
									}
								}
							});

							globalScene.add(mockup);
							globalCamera = globalScene.getObjectByName(itemData.camera.name) as
								| PerspectiveCamera
								| OrthographicCamera;

							if (!globalCamera) {
								console.error({ message: 'Camera not found' });
							}

							const aspect = itemData.camera.frameWidth / itemData.camera.frameHeight;
							if (globalCamera.type == 'PerspectiveCamera') {
								globalCamera.aspect = aspect;
							} else {
								globalCamera = globalCamera as OrthographicCamera;
								globalCamera.zoom = (globalScene.getObjectByName('zoom')?.position?.x ?? 0) * 100 || 20;
								globalCamera.left = -aspect;
								globalCamera.right = aspect;
								globalCamera.top = 1;
								globalCamera.bottom = -1;
							}
							globalCamera.updateProjectionMatrix();
							await globalRender.render(globalScene, globalCamera);
							resolve(true);
						} catch (error) {
							reject(error);
						}
					},
					(e: ProgressEvent) => {
						// Use to show progress bar Mockup
						mockup.percentageColladaLoaded = Math.round((e.loaded * 100) / e.total);
					},
					(error) => {
						reject(error);
					}
				);
			});

			return proccessImageData();
		} catch (error: any) {
			throw new Error(error);
		}
	};

	/**
	 * Update 3D render based in material name
	 * @param name Material name
	 * @param textureData Blob
	 * @returns {string} Blob of render updated
	 */
	const updateRender = async (name: string, canvasTexture: HTMLCanvasElement) => {
		const mockup = useMockupStore();
		const { canvasToBlob } = useLoadMockup();

		if (mockup.settings.get(MockupStoreSettings.DebugMode))
			console.log(`Texture ${name} =>`, await canvasToBlob(canvasTexture));

		const texture = await createTexture(canvasTexture);
		for (const child of globalScene.children as Array<any>) {
			if (child.isGroup) {
				for (const node of child.children) {
					if (node.isMesh && node.material.name == name) {
						node.visible = true;
						const material = new THREE.MeshBasicMaterial({
							map: texture,
							color: '#ffffff',
							name: name,
							alphaTest: 0.01,
							transparent: true,
						});

						node.material = material;
					} else {
						node.visible = false;
					}
				}
			}
		}

		generateWebGLRenderer();
		return proccessImageData();
	};

	/**
	 * Generate a new WebGL renderer and configure it with specified settings.
	 * This new WebGL renderer instance is generated because a error with Three Js
	 * Solved this(uniformMatrix4fv: location is not from current program)
	 *
	 * This function creates a new instance of the Three.js WebGLRenderer and configures it,
	 * It also sets the pixel ratio, clear color, and size for the renderer and performs an initial rendering.
	 *
	 * @returns {void}
	 */
	const generateWebGLRenderer = (): void => {
		const newGlobalRender = new THREE.WebGLRenderer({
			antialias: true,
			canvas,
			alpha: true,
			preserveDrawingBuffer: true,
			logarithmicDepthBuffer: true,
		});
		newGlobalRender.setPixelRatio(globalRender.getPixelRatio());
		newGlobalRender.setClearColor(new THREE.Color(0xff0000), 0);
		newGlobalRender.setSize(renderSize.value.width, renderSize.value.height);
		newGlobalRender.render(globalScene, globalCamera);
	};

	/**
	 * Update render Size to generate multiple renders according to resolution
	 * @param width
	 * @param height
	 */
	const updateSize = async (width: number, height: number) => {
		renderSize.value = { width, height };
		if (width == 5000 || height == 5000) {
			globalRender.setPixelRatio(1);
		} else {
			globalRender.setPixelRatio(window.devicePixelRatio);
		}
		//Update camera settings
		const aspect = width / height;
		if (globalCamera.type == 'PerspectiveCamera') {
			globalCamera.aspect = aspect;
		} else {
			globalCamera.left = -aspect;
			globalCamera.right = aspect;
		}
		//Update render settings size
		globalRender.setSize(renderSize.value.width, renderSize.value.height);
	};

	/**
	 * Draw render in Canvas 2D and getImageData
	 * @returns {ImageData} Pixel data of canvas2DContent
	 */
	const proccessImageData = () => {
		// Resize 2D canvas
		canvas2D.width = canvas.width;
		canvas2D.height = canvas.height;
		ctx2d.drawImage(canvas, 0, 0);
		return ctx2d?.getImageData(0, 0, canvas.width, canvas.height);
	};

	/**
	 * Generate a Three texture
	 * @param {HTMLCanvasElement} canvas
	 * @returns {THREE.Texture}
	 */
	const createTexture = (canvas: HTMLCanvasElement): THREE.Texture => {
		return new THREE.CanvasTexture(canvas);
	};

	return {
		render,
		updateRender,
		updateSize,
		proccessImageData,
		createTexture,
		globalScene,
	};
};
