export enum MockupErrorType {
	mockupNotFound = 'Mockup dont exist',
	renderingError = 'Process render failure',
	compositionError = 'composition process error',
	skuDontExist = 'Url corrupt, go Back to Freepik',
	manifestCorrupt = 'Mockup Manifest have corrupt',
	notHasTextureElements = 'Mockup dont have texture Elements in current Mode',
	notWorkspaceMode = 'There is no workspace mode available',
	notWorkspaceValidURL = 'The URL has more than one parameter, is not valid.',
	downloadMockupFecth = 'Unable to count download Mockup',
	missingResource = 'Failed to fetch an image resource',
	noPremiumAccess = 'Mockup is not available for free users',
}

export enum MockupRenderErrorType {
	DONT_FOUND_SCENE = 'Scene not found',
	DONT_FOUND_MESH = 'Mesh not found',
	DONT_FOUND_MESH_WITH_NAME = 'Mesh not found with name',
	DONT_FOUND_CAMERA = 'Camera not found',
}

export enum MockupMigrationErrorType {
	UPDATE_CUSTOMIZABLE = 'Error to update customizables',
}

export enum MockupErrorFlag {
	warning = 'Warning',
	error = 'Error',
}

export interface ErrorParams {
	title: string;
	description: string;
	successButton?: string;
	discardButton?: string;
}
