import Bugsnag from '@bugsnag/js';
import { sortByIndex } from '@tldraw/indices';
import { createSharedComposable } from '@vueuse/core';
import { computed, ref } from 'vue';

import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import Element from '@/elements/element/classes/Element';
import Image from '@/elements/medias/images/image/classes/Image';
import { Video } from '@/elements/medias/video/classes/Video';
import { useCopyStyles } from '@/elements/texts/text/composables/useCopyStyles';

export const useSelection = createSharedComposable(() => {
	const store = useMainStore();
	const { isPhotoMode } = useEditorMode();
	const { clearCopiedStyles } = useCopyStyles();

	const selectionId = ref<string[]>([]);
	const selection = computed(() => selectionId.value.map((id) => store.activePage?.elements.get(id)).filter(Boolean));

	const selectionOrdered = computed(() => [...selection.value].sort(sortByIndex));

	const setSelection = (element: Element | Element[], group = false) => {
		Bugsnag.leaveBreadcrumb('setSelection', { element, group });
		// Si ya está en la selección no hacemos nada
		const toSelectIsArray = Array.isArray(element);
		const elementToSelect = toSelectIsArray ? element[0] : element;
		if (!elementToSelect) {
			return;
		}
		const isInSelection = selectionId.value.some((id) => id === elementToSelect.id);
		const isLockedGroup =
			selection.value.length && selection.value.every((s) => s.group && s.group === selection.value[0].group);

		if (!Array.isArray(element) && isInSelection && (!element.group || !isLockedGroup)) return;

		store.editPanel = null;

		// Grupos y selecciones múltiples
		if (group || toSelectIsArray) {
			// Si se intenta hacer grupo y la selección o el elemento a añadir a la selección es background no hacemos nada
			const selectionIsBg = selection.value.find((el) => {
				if (!(el instanceof Image || el instanceof Video)) return false;

				return store.activePage?.backgroundImageId === el.id;
			});

			if (selectionIsBg) selectionId.value = selectionId.value.filter((id) => selectionIsBg.id !== id);

			const isBg = toSelectIsArray
				? element.some((el) => store.activePage?.backgroundImageId === el.id)
				: store.activePage?.backgroundImageId === element.id;

			if (isBg) return;

			// si en nuestra selección tenemos elementos bloqueados,
			// los sacamos para prevenir añadirlos en las interacciones
			const lockedElementsInSelection = selectionId.value.filter((id) => store.activePage?.elements.get(id)?.locked);
			selectionId.value = selectionId.value.filter((selection) => !lockedElementsInSelection.includes(selection));
		}

		let elementsGroup: string[] = [];
		if (toSelectIsArray && store.activePage) {
			const activePageElements = store.activePage.elementsAsArray();
			elementsGroup = element
				.filter((el) => el.group)
				.flatMap((el) => {
					const elementsGroup = activePageElements
						.filter((elPage) => elPage.group === el.group && elPage.id !== el.id)
						.map((el) => el.id);

					if (!elementsGroup.length) {
						el.group = null;
					}

					return elementsGroup;
				});
		} else if (!toSelectIsArray && element.group && store.activePage) {
			elementsGroup = store.activePage
				.elementsAsArray()
				.filter((el) => el.group === element.group && el.id !== element.id)
				.map((el) => el.id);

			if (!elementsGroup.length) {
				element.group = null;
			}
		}

		store.setEditPanelMobile(elementToSelect);
		const elementsIds = Array.isArray(element) ? element.map((el) => el.id) : [element.id];
		selectionId.value = group
			? [...new Set([...elementsIds, ...selectionId.value, ...elementsGroup])]
			: [...new Set([...elementsIds, ...elementsGroup])];

		clearCopiedStyles();
	};

	const removeFromSelection = (target: Element | Element[]) => {
		// si element es un array, deseleccionamos todos los elementos del array
		if (Array.isArray(target)) {
			const ids = target.map((el) => el.id);
			selectionId.value = selectionId.value.filter((id) => !ids.includes(id));
			return;
		}

		// para el resto de casos
		const isInSelection = selectionId.value.some((idElement) => idElement === target.id);
		if (!isInSelection) {
			return;
		}

		selectionId.value = selectionId.value.filter((id) => id !== target.id);
	};

	const clearSelection = () => {
		store.editPanel = null;

		// En el modo photo la propia foto es la selección por defecto
		if (isPhotoMode.value && store.activePage?.backgroundImageId) {
			const photoModeImage = store.activePage?.elements.get(store.activePage.backgroundImageId);

			if (!photoModeImage) {
				clearCopiedStyles();
				selectionId.value = [];
				return;
			}

			selectionId.value = [photoModeImage.id];
			return;
		}

		clearCopiedStyles();
		selectionId.value = [];
	};

	return { selection, selectionId, selectionOrdered, setSelection, removeFromSelection, clearSelection };
});
