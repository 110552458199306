import { watchDebounced } from '@vueuse/core';
import { Ref } from 'vue';

import { Text } from '@/elements/texts/text/classes/Text';
import { useTextSelection } from '@/elements/texts/text/composables/useTextSelection';
import { useTextUnderline } from '@/elements/texts/text/composables/useTextUnderline';
import { FontStyle, FontWeight } from '@/Types/elements.d';

import { useTextEditing } from './useTextEditing';

/**
 * Composable que contiene funciones para arreglar el texto
 * @param text Texto a arreglar
 * @returns Objeto con las funciones
 */
export const useTextCurate = (text: Ref<Text>) => {
	const { domNode } = useTextSelection();
	const { updateUnderline } = useTextUnderline(text);
	const { textEditing, textEditingContent } = useTextEditing();

	/**
	 *  Observamos que haya cambiado el contenido del texto, y en caso afirmativo, eliminamos los colores que no se estén usando
	 */
	watchDebounced(
		textEditingContent,
		() => {
			if (!textEditing.value || textEditing.value.id !== text.value.id) return;

			removeUnnecessarySpan();
			removeUnusedColors();
		},
		{ debounce: 200 }
	);

	/**
	 * Elimina los span innecesarios en caso de que el mainNode solo tenga un hijo
	 */
	const removeUnnecessarySpan = () => {
		if (!domNode.value) return;

		const childNodes = domNode.value.childNodes;
		const firstChild = domNode.value.childNodes[0];

		if (childNodes.length === 1 && firstChild instanceof HTMLElement && firstChild.nodeName === 'SPAN') {
			const color = firstChild.style.color || firstChild.style.backgroundImage;
			const foundColor = text.value.colors.find((c) => c.id === color.split('var(--')[1]?.split(')')[0]);

			if (foundColor) {
				text.value.updateColor(foundColor);
			}

			const fontSize = firstChild.style.fontSize;
			const fontFamily = firstChild.style.fontFamily;
			const fontWeight = firstChild.style.fontWeight;
			const fontStyle = firstChild.style.fontStyle;
			const letterSpacing = firstChild.style.letterSpacing;
			const textDecoration = firstChild.style.textDecorationLine;

			// Si tiene fontSize, fontFamily, fontWeight, fontStyle, letterSpacing se lo asignamos al texto principal
			if (fontSize) {
				text.value.fontSize = parseFloat(fontSize);
			}
			if (fontFamily) {
				text.value.fontFamily = fontFamily;
			}
			if (fontWeight) {
				text.value.fontWeight = parseInt(fontWeight) as FontWeight;
			}
			if (fontStyle) {
				text.value.fontStyle = fontStyle as FontStyle;
			}
			if (letterSpacing) {
				text.value.letterSpacing = parseFloat(letterSpacing);
			}

			// Actualizamos el contenido del texto principal
			domNode.value.innerHTML = firstChild.innerHTML;

			// Si tiene textDecoration, se lo asignamos al texto principal después del cambio de contenido para poder aplicar el <u> correctamente
			if (textDecoration === 'underline') {
				updateUnderline();
			}
		}
	};

	/**
	 * Elimina los colores que no se estén usando
	 */
	const removeUnusedColors = (textParam?: Ref<Text>, node?: HTMLElement) => {
		const nodeToUse = node || domNode.value;
		const textToUse = textParam || text;

		if (!nodeToUse || !textToUse) return;

		// Buscamos los colores secundarios que se están usando
		const usedColors = Array.from(nodeToUse.querySelectorAll<HTMLElement>('*'))
			.map(
				(el) =>
					el.style.color.split('var(--')[1]?.split(')')[0] || el.style.backgroundImage.split('var(--')[1]?.split(')')[0]
			)
			.filter(Boolean);

		// Añadimos el color principal
		usedColors.push(textToUse.value.color.id);

		// Eliminamos los colores que no se estén usando
		textToUse.value.colors = textToUse.value.colors.filter((color) => usedColors.includes(color.id));
	};

	return {
		removeUnusedColors,
		removeUnnecessarySpan,
	};
};
