import {
	CompositingSize,
	CompositionScaleBySize,
	MockupElement2DTexture, MockupElementTextures,
	MockupManifest, Size,
	WarpObject
} from "@/apps/mockup/schemas";
import {CanvasUtils, Degrees} from "@/apps/mockup/classes/CanvasUtils";
import {MockupRender3D} from "@/apps/mockup/classes/Render3D";
import {CustomizableUtils} from "@/apps/mockup/facades/CustomizablesUtils";
import Page from "@/page/classes/Page";
import {RenderPageInstance} from "@/apps/mockup/classes/RenderPage";
import {usePreviewPage} from "@/apps/mockup/composable/usePreviewPage";

export type PayloadCompose2D = {
	mockupElement: MockupElement2DTexture,
	currentManifest: MockupManifest
	page: Page,
	options: {
		useDomToImage: boolean
		compositionSize: CompositingSize
	}
}

export class Customizable2D {
	 public static async composeTexture (payload: PayloadCompose2D) {
		 const { mockupElement, currentManifest, page, options } = payload;
		 const texture = await Customizable2D.extractTexture(mockupElement, page, options);
		 usePreviewPage.value.updatePreview(page.id, texture);
		 if (mockupElement.customWarp) {
				await Customizable2D.composeRender3D(mockupElement, texture,  currentManifest)
			 return
		 }
		 CustomizableUtils.updateElements(texture, currentManifest.size.width, mockupElement);

	 }

	/**
	 * Compose the 3D render of the mockup element
	 * @param mockupElement - The mockup element to be used in the 3D render
	 * @param texture - The texture to be used in the 3D render
	 * @param currentManifest - The current manifest
	 * @private
	 */
	private static async composeRender3D (mockupElement: MockupElement2DTexture, texture: HTMLCanvasElement, currentManifest: MockupManifest) {
		if (!MockupRender3D.existScene(mockupElement.id)) {
			await Customizable2D.create3DScene(mockupElement);
		}

		const previewRotated = CanvasUtils.rotateContent(texture, Degrees.DEG_270);
		const renderImageData = await MockupRender3D.updateSceneSize(mockupElement.id, currentManifest.size)
			.updateScene(mockupElement.id, previewRotated as HTMLCanvasElement)
			.renderScene(mockupElement.id);
		CustomizableUtils.updateElements(renderImageData, currentManifest.size.width, mockupElement);

	}
	private static async create3DScene (mockupElement: MockupElement2DTexture) {
		const renderSceneData: WarpObject = {
			id: mockupElement.id,
			horizontalPoints: mockupElement.warpInfo?.horizontalPoints as number[],
			verticalPoints: mockupElement.warpInfo?.verticalPoints as number[],
		};
		await MockupRender3D.newScene('WARP', renderSceneData);
	}


	public static formatSizeAccordingToScale (size: Size, scale: number): Size {
		const aspectRatio = size.width / size.height;

		size.width = aspectRatio > 1 ? scale : scale * aspectRatio
		size.height = aspectRatio > 1 ? scale / aspectRatio : scale

		return size

	}

	public static async extractTexture (mockupElement: MockupElementTextures, page: Page, options: { useDomToImage: boolean, compositionSize: CompositingSize }) {
		const scale = CompositionScaleBySize[options.compositionSize]
		const size = Customizable2D.formatSizeAccordingToScale({
			width: mockupElement.width!,
			height: mockupElement.height!
		}, CompositionScaleBySize[CompositingSize.PREVIEW])

		RenderPageInstance.setPage(page)
			.setScale(scale/1000)
			.setSize(size.width, size.height)

		const canvasGenerated = options.useDomToImage ?
			await RenderPageInstance.useDomToImage() :
			RenderPageInstance.FuckDomToImage();

		return canvasGenerated as HTMLCanvasElement
	}
}
