import { Ref } from 'vue';

import { Mask } from '@/elements/medias/mask/classes/Mask';
import { MaskableElement } from '@/elements/medias/mask/types/maskable.type';
import { useInteractions } from '@/interactions/composables/useInteractions';
import { useSelection } from '@/interactions/composables/useSelection';
import { MaskApi } from '@/Types/apiClient';

export const useMaskeableElement = (element: Ref<MaskableElement>) => {
	const { selection } = useSelection();

	const { toggleMiddleHandlers } = useInteractions();

	const applyMask = async (maskApi: MaskApi): Promise<Mask | undefined | Error> => {
		if (!maskApi) return;

		const mask = await Mask.fromApi(maskApi);
		if (!mask) return Promise.reject(new Error('Error loading mask from api'));

		const { crop, position, size } = getImagePositionAndSizeToFitInMask();

		element.value.crop = crop;
		element.value.position = position;
		element.value.size = size;

		element.value.setMask(mask);
		if (element.value.mask && element.value.mask.isPlaceholder) element.value.mask.isPlaceholder = false;

		toggleMiddleHandlers();

		return mask;
	};

	const restoreOriginalSize = () => {
		element.value.size.width = element.value.crop.size.width;
		element.value.size.height = element.value.crop.size.height;
		element.value.position.x += element.value.crop.position.x;
		element.value.position.y += element.value.crop.position.y;
		element.value.crop.position = { x: 0, y: 0 };
		element.value.crop.size = { height: 0, width: 0 };
	};

	const removeMask = () => {
		// si ya no tiene mascara, no hacer nada
		if (!element.value.mask) return;
		restoreOriginalSize();

		element.value.setMask(null);
		if (selection.value.length === 1) {
			toggleMiddleHandlers();
		}
	};

	const getImagePositionAndSizeToFitInMask = () => {
		const setToWidth = element.value.size.width < element.value.size.height;
		const newSize = setToWidth ? element.value.size.width : element.value.size.height;
		const diff = setToWidth
			? element.value.size.height - element.value.size.width
			: element.value.size.width - element.value.size.height;

		return {
			crop: {
				position: {
					x: setToWidth ? element.value.crop.position.x : element.value.crop.position.x - diff / 2,
					y: setToWidth ? element.value.crop.position.y - diff / 2 : element.value.crop.position.y,
				},
				size: {
					width: element.value.hasCrop() ? element.value.crop.size.width : element.value.size.width,
					height: element.value.hasCrop() ? element.value.crop.size.height : element.value.size.height,
				},
			},
			position: {
				x: setToWidth ? element.value.position.x : element.value.position.x + diff / 2,
				y: setToWidth ? element.value.position.y + diff / 2 : element.value.position.y,
			},
			size: {
				width: newSize,
				height: newSize,
			},
		};
	};

	return {
		applyMask,
		removeMask,
		getImagePositionAndSizeToFitInMask,
	};
};
