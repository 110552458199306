import { Element as SvgElement } from '@svgdotjs/svg.js';
import Normalize from 'color-normalize';
import { cloneDeep, countBy, groupBy, max, mean, min, minBy } from 'lodash-es';
import { CSSProperties, Ref, ref } from 'vue';

import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import ElementClass from '@/elements/element/classes/Element';
import ElementTools from '@/elements/element/utils/ElementTools';
import { Text } from '@/elements/texts/text/classes/Text';
import { useFonts } from '@/elements/texts/text/composables/useFonts';
import { useTextCurate } from '@/elements/texts/text/composables/useTextCurate';
import { getTextStyles } from '@/elements/texts/text/composables/useTextStyles';
import FontFamilyVariantsTools from '@/elements/texts/text/utils/FontFamilyVariantsTools';
import Page from '@/page/classes/Page';
import { Color } from '@/Types/colorsTypes';
import { FontStyle, FontWeight, StyleProperties, TextAlign, TextDecoration } from '@/Types/elements.d';
import { Font, FontFaceInfo, SerializedClass, TextShadowProperties, WeightProps } from '@/Types/types';
import MathTools from '@/utils/classes/MathTools';

class TextTools {
	static EM_TO_PX = 16;
	static loadedFontWeights = new Set<string>();
	static HTML_WHITELIST = [
		'ARTICLE',
		'ASIDE',
		'FOOTER',
		'HEADER',
		'H1',
		'H2',
		'H3',
		'H4',
		'H5',
		'H6',
		'MAIN',
		'NAV',
		'SECTION',
		'BLOCKQUOTE',
		'DD',
		'DIV',
		'DL',
		'DT',
		'FIGCAPTION',
		'LI',
		'MENU',
		'OL',
		'P',
		'UL',
		'A',
		'B',
		'CITE',
		'EM',
		'SMALL',
		'SPAN',
		'STRONG',
		'SPAN',
		'FONT',
		'I',
	];

	static createNodeFromString(source: string): HTMLElement {
		const div = document.createElement('DIV');

		div.innerHTML = source;

		if (!div.firstElementChild) {
			return div;
		}

		return <HTMLElement>div.firstElementChild;
	}

	static nextNode(node: Node | ParentNode | ChildNode | null) {
		if (node && node.hasChildNodes()) {
			return node.firstChild;
		}

		let isMainNode = false;
		let secureCounter = 0;

		while (node && !node.nextSibling && !isMainNode && secureCounter < 500) {
			secureCounter++;

			node = node.parentNode;

			// Si el nodo tiene la clase text-element-final no obtenemos el siguiente nodo
			if (node instanceof HTMLElement && node.classList.contains('text-element-final')) {
				isMainNode = true;
			}
		}

		if (isMainNode) {
			return node;
		}

		if (!node) {
			return null;
		}

		return node.nextSibling;
	}

	static getAllColorsFromText = (node: HTMLElement) => {
		const colors: (string | undefined)[] = [];
		const children = Array.from(node.children) as HTMLElement[];

		// Recorremos los hijos y buscamos todos los nodos
		if (children.length) {
			children.forEach((child) => {
				colors.push(...Array.from(new Set(this.getAllColorsFromText(child))));
			});
		}

		// Añadimos el color del propio nodo al array

		return Array.from(
			new Set([...colors, Object.values(node.style).find((childStyle) => childStyle.includes('--color-'))])
		);
	};

	static getRangeSelectedNodes(range: Range): Node[] {
		let node: Node | ParentNode | ChildNode | null = range.startContainer;
		const endNode = range.endContainer;

		// Special case for a range that is contained within a single node
		if (node === endNode) {
			return [node];
		}

		// Iterate nodes until we hit the end container
		const rangeNodes = [];

		const temporalSelection = document.getSelection();
		let secureCounter = 0;
		let isMainNode = false;

		while (node && node !== endNode && secureCounter < 500 && !isMainNode) {
			secureCounter++;

			if (!isMainNode) {
				const nextNode = this.nextNode(node);

				if (nextNode) {
					node = nextNode;

					// Si el nodo tiene la clase text-element-final no obtenemos el siguiente nodo
					if (node instanceof HTMLElement && node.classList.contains('text-element-final')) {
						isMainNode = true;
					} else {
						// Si es un div y no tiene ningún atributo es un div generado por en navegador
						const isBreaklineNode = node instanceof HTMLDivElement && !node.hasAttributes();

						if (temporalSelection && temporalSelection.containsNode(node) && !isBreaklineNode) {
							rangeNodes.push(node);
						}
					}
				}
			}
		}

		// Add partially selected nodes at the start of the range
		node = range.startContainer;

		const { isFirefox } = useDeviceInfo();
		// si es firefox reporta mal el de comienzo
		if (isFirefox) {
			const nextSibling = window.getSelection()?.getRangeAt(0).startContainer.nextSibling;

			if (nextSibling) {
				node = nextSibling;
			}
		}

		while (node && node !== range.commonAncestorContainer) {
			rangeNodes.unshift(node);

			// si el nodo padre ya tiene todo el contenido del nodo actual
			// no hace falta seguir buscando padres
			if (node.parentElement?.textContent === node.textContent) {
				break;
			}

			node = node.parentNode;
		}

		return rangeNodes;
	}

	static getSelectedNodes(): Node[] {
		const sel = window.getSelection();

		if (!sel) {
			return [];
		}

		if (!sel.isCollapsed) {
			return this.getRangeSelectedNodes(sel.getRangeAt(0));
		}

		if (sel.type.toLowerCase() === 'caret' && sel?.anchorNode) {
			return [sel?.anchorNode];
		}

		return [];
	}

	/**
	 * Dado un Nodo root retorna todos los nodos Text hijos
	 * @param root Nodo raíz de texto
	 * @returns Array de nodos Text que se encuentren dentro del root
	 */
	static getNodesFromRootNode(root: HTMLElement): Node[] {
		const getChildren = (node: HTMLElement | Node): ChildNode[] => {
			let children = Array.from(node.childNodes);

			if (children.every((el) => el.nodeType === 3)) {
				return children;
			}

			children.forEach((el) => {
				if (el.nodeType !== 3) {
					children = [...children, ...getChildren(el)];
				}
			});

			return children.filter((el) => el.nodeType === 3);
		};

		return getChildren(root);
	}

	static haveOutlinedText(textStyles: Partial<CSSProperties>) {
		// @ts-ignore
		const textStroke = textStyles.webkitTextStroke;

		if (textStroke) {
			return typeof textStroke === 'string' ? parseFloat(textStroke) > 0 : textStroke > 0;
		}

		return false;
	}
	static getOriginalTextStyles(text: Text, textStyles: Partial<CSSProperties>) {
		let originalTextStyles = cloneDeep(textStyles);
		const hasOutline = text.outline && text.outline.width;

		originalTextStyles = {
			...originalTextStyles,
			[`--${text.color.id}`]: hasOutline ? SolidColor.transparent() : text.color.toCssString(),
		};

		return originalTextStyles;
	}

	static getOutlinedTextStyles(text: Text, textStyles: Partial<CSSProperties>) {
		let outlinedTextStyles = cloneDeep(textStyles);
		outlinedTextStyles = { ...outlinedTextStyles, [`--${text.color.id}`]: text.color.toCssString() };
		// @ts-ignore
		outlinedTextStyles.webkitTextStroke = '0px';
		// Borramos el text shadow del outline, ya que se está aplicando en el texto
		if (outlinedTextStyles.textShadow) delete outlinedTextStyles.textShadow;
		return outlinedTextStyles;
	}

	/**
	 *
	 * Si estamos en IOS y el texto contiene outline, devolvemos los estilos necesarios para el elemento que renderizará las sombras en el componente
	 * TextTemplate
	 *
	 * @param text elemento texto
	 * @param textStyles estilos del texto original
	 * @returns estilos que van a ser aplicados sobre el elemento que renderiza el text shadow en IOS
	 */
	static getTextShadowStylesToIOS(text: Text, textStyles: Partial<CSSProperties>) {
		const hasOutline = text.outline && text.outline.width;
		const { isIOS } = useDeviceInfo();
		if (!isIOS.value && !hasOutline) return;
		let textStylesToIos = cloneDeep(textStyles);

		const textColor = SolidColor.transparent();
		textStylesToIos = {
			...textStylesToIos,
			WebkitTextStroke: undefined,
			color: textColor.toCssString(),
			textShadow: TextTools.textShadowToCssString(text.textShadow),
		};

		return textStylesToIos;
	}

	static styleTextToObj(styleText: string) {
		const styleObj = styleText
			.split(';')
			.map((style) => {
				const styleData = style.split(':');

				if (styleData.length < 2) return null;

				return {
					name: styleData[0].trim(),
					value: styleData[1].trim(),
				};
			})
			.filter((style) => style);

		return styleObj;
	}

	/**
	 * Esta función buscará el peso más cercano de una fuente existente
	 * @param fontFamily Recibe el nombre de una fuente
	 * @param fontWeight Recibe un peso para buscar el siguiente o el más cercano a él
	 * @returns
	 */
	static getNearestWeight(fontFamily: string, fontWeight: number) {
		const { fonts, notAvailableFonts } = useFonts();

		if (!fonts.value[fontFamily]) {
			notAvailableFonts.value.add(fontFamily);
			return {
				isInvalidFont: true,
				weight: fontWeight as FontWeight,
			};
		}

		const weights = fonts.value[fontFamily].weights;

		let result = weights.find((w) => w === `${fontWeight}`);

		if (!result) {
			let flag = false;

			weights.forEach((w) => {
				if (!w.includes('i') && parseInt(w) > fontWeight && !flag) {
					result = w;
					flag = !flag;
				}
			});
		}

		if (!result) {
			result = Math.max(...weights.filter((w) => !w.includes('i')).map((w) => parseInt(w))).toString();
		}

		return {
			isInvalidFont: false,
			weight: parseInt(result) as FontWeight,
		};
	}

	static getFontByName(fontFamily: string) {
		const { fonts } = useFonts();

		const fontWithoutSuffix = this.getFontNameWithoutSuffixVariant(fontFamily);
		const fontWithSpaces = this.getFontNameSeparatedByCapitalLetters(fontFamily);
		const fontWithoutSuffixButWithSpaces = this.getFontNameWithoutSuffixVariant(
			this.getFontNameSeparatedByCapitalLetters(fontFamily)
		);
		const fontName =
			Object.keys(fonts.value).find((font) => font.includes(fontFamily)) ||
			Object.keys(fonts.value).find((font) => font.includes(fontWithoutSuffix)) ||
			Object.keys(fonts.value).find((font) => font.includes(fontWithSpaces)) ||
			Object.keys(fonts.value).find((font) => font.includes(fontWithoutSuffixButWithSpaces));

		return fontName || 'Montserrat';
	}

	static getFontNameSeparatedByCapitalLetters(fontName: string) {
		// Añadimos un espacio antes de cada letra mayúscula que no esté precedida por un guión
		// o cuando un número no esté precedido por una letra
		const possiblesMatchs = [...fontName.matchAll(/[A-Z0-9]/g)];

		const fixedFontName = fontName
			.split('')
			.map((char, index) => {
				const prevChar = index > 0 ? fontName[index - 1] : '';
				const isNumber = !isNaN(parseInt(char));
				const addSpace = isNumber ? isNaN(parseInt(prevChar)) : prevChar !== '-';

				if (prevChar && possiblesMatchs.find((match) => match.index === index) && addSpace) {
					return ` ${char}`;
				}

				return char;
			})
			.join('')
			.trim();

		return fixedFontName;
	}

	static getFontNameWithoutSuffixVariant(fontFamily: string) {
		const weightNames = FontFamilyVariantsTools.getWeightNames();
		let fontNameParsed = fontFamily;

		// Puede venir de 2 formas: "Montserrat-Black" o "Montserrat Black"
		weightNames.forEach((wName) => (fontNameParsed = fontNameParsed.replace(`-${wName}`, '').replace(wName, '')));

		return fontNameParsed.trim();
	}

	static extractFontDataFromText(element: SvgElement) {
		const fontFamilyProp =
			element.css('font-family')?.toString() ||
			element.attr('font-family')?.toString() ||
			element.findOne('[font-family]')?.attr('font-family')?.toString() ||
			'Montserrat';
		const fontFamilySplit = fontFamilyProp.split(', ');
		const fontFamily = this.getFontByName(
			fontFamilySplit[fontFamilySplit.length - 1]?.replaceAll('"', '') || 'Montserrat'
		);
		const fontWeight =
			element.css('font-weight').toString() ||
			element.attr('font-weight')?.toString() ||
			element.findOne('[font-weight]')?.attr('font-weight')?.toString();
		const nearestWeight = this.getNearestWeight(
			fontFamily,
			parseFloat(fontWeight) || FontFamilyVariantsTools.findVariantInName(fontFamilyProp) || (400 as FontWeight)
		);
		const fontStyle =
			((element.css('font-style').toString() || 'normal') as FontStyle) ||
			(element.attr('font-style')?.toString() as FontStyle) ||
			(element.findOne('[font-style]')?.attr('font-style')?.toString() as FontStyle) ||
			'normal';
		const fontSize =
			parseFloat(
				element.css('font-size').toString() ||
					element.attr('font-size')?.toString() ||
					element.findOne('[font-size]')?.attr('font-size')?.toString()
			) || 16;
		const mainLetterSpacing =
			parseFloat(
				element.css('letter-spacing').toString() ||
					element.attr('letter-spacing')?.toString() ||
					element.findOne('[letter-spacing]')?.attr('letter-spacing')?.toString()
			) || 0;
		const otherLetterSpacing = element.find('[style*="letter-spacing"]').map((el) => {
			return parseFloat(el.css('letter-spacing').toString());
		});
		// Buscamos el menor valor de letter-spacing para evitar romper la caja
		const letterSpacing = Math.min(mainLetterSpacing, ...otherLetterSpacing);

		const textShadow = TextTools.getTextShadowFilter(element) || TextTools.getTextShadow(element);
		// Se cambia el tipo de parseo porque en algunos navegadores cuando llega con decimales falla
		const outlineStroke =
			element.css('stroke').toString() ||
			element.attr('stroke')?.toString() ||
			element.findOne('[stroke]')?.attr('stroke')?.toString();
		const outlineWidth = parseInt(
			element.css('stroke-width').toString() ||
				element.attr('stroke-width')?.toString() ||
				element.findOne('[stroke-width]')?.attr('stroke-width')?.toString()
		);
		const outline = {
			color: SolidColor.fromString(outlineStroke) || Text.defaults().outline.color,
			width: outlineWidth || 0,
			unit: 'px',
		};
		const elsWithFill = element.find('[style*="fill:"]') || element.find('[fill]');
		const fills = elsWithFill.length
			? elsWithFill.map((el) => el.css('fill').toString() || el.attr('fill')?.toString())
			: [element.node.style.fill];
		const colors = Array.from(fills).map((rgba) => {
			const [r, g, b, a] = Normalize(rgba as string);
			return new SolidColor(r * 255, g * 255, b * 255, a);
		});
		const color = colors[0] || SolidColor.black();

		return { fontFamily, nearestWeight, fontStyle, fontSize, letterSpacing, textShadow, outline, color, colors };
	}

	static extractTextData(textNode: SvgElement, fontFamily: string, fontWeight: number, fontSize: number) {
		const tspansInText = Array.from(textNode.node.querySelectorAll('tspan'));

		// Agrupamos los tspan por línea según su posición en 'y' dentro del nodo text
		let lines = groupBy(
			tspansInText.filter((tspan) => !tspan.children.length),
			(tspan) => `line_${tspan.getAttribute('y') || 0}`
		);

		if (textNode.node.firstChild && textNode.node.firstChild.nodeType === Node.TEXT_NODE) {
			let line_0 = lines.line_0 || [];
			line_0 = [textNode.node.firstChild as SVGTSpanElement, ...line_0];

			delete lines.line_0;

			lines = {
				line_0,
				...lines,
			};
		}

		// Calculamos el lineHeight en base a cuantas veces se repite esa separación entre líneas
		const positionsY = Object.keys(lines).map((key) => parseInt(key.split('_')[1]));
		const { lineHeight } = TextTools.getLineHeightBasedOnLinesPosY(positionsY, fontSize);

		const theTspanClosestToLeft = minBy(tspansInText, (tspan) => parseFloat(tspan.getAttribute('x') || '0'));

		let minX = 0;

		if (theTspanClosestToLeft) {
			minX = parseFloat(theTspanClosestToLeft.getAttribute('x') || '0');
		}

		// Si tiene un texto sin tspan, asumimos que el min x es 0, si no hay otra linea que lo tenga inferior
		if (textNode.node.firstChild && textNode.node.firstChild.nodeType === Node.TEXT_NODE) {
			minX = Math.min(minX, 0);
		}

		let align = '';

		// Por defecto estamos alineados la izquierda
		if ((minX === 0 && textNode.node.childElementCount === 0) || Object.keys(lines).length === 1) {
			align = 'left';
		} else {
			// Hay que distinguir entre los textos centrados de los alineados
			// a la derecha, podemos saber si el bounding de los textos que
			// estan más a la derecha acaban todos en la misma posicion

			const lastItemsInLine = Object.values(lines).map((tspans) => tspans[tspans.length - 1]);
			const firstItemsInLine = Object.values(lines).map((tspans) => tspans[0]);

			const lastItemsX = lastItemsInLine.map((tspan) => {
				// si es un texto suelto suponemos que ocupa todo el ancho
				if (tspan.nodeType === Node.TEXT_NODE) {
					return (tspan.parentNode as Element).getBoundingClientRect().right;
				}
				return tspan.getBoundingClientRect().right;
			});

			const firstItemsX = firstItemsInLine.map((tspan) => {
				// si es un texto suelto suponemos que ocupa todo el ancho
				if (tspan.nodeType === Node.TEXT_NODE) {
					return (tspan.parentNode as Element).getBoundingClientRect().left;
				}

				return tspan.getBoundingClientRect().left;
			});

			const averageLeftX = mean(firstItemsX);
			const minLeftX = min(firstItemsX) || 0;
			const averageRightX = mean(lastItemsX);
			const maxX = max(lastItemsX) || 0;
			const threshold = fontSize * 0.2;
			const diffLeft = Math.abs(averageLeftX - minLeftX);
			const diffRight = Math.abs(averageRightX - maxX);

			// Definimos un margen de seguridad del tamaño de un caracter, para no confundir
			// para determinar si esta alineado a la derecha ya que no siempre acaba
			// en los mismos pixeles exactos por algún motivo
			if (diffLeft < threshold) {
				align = 'left';
			} else if (diffRight < threshold) {
				align = 'right';
			} else {
				align = 'center';
			}
		}

		// Extraemos el contenido
		let textContent = '';
		const orderedLines = Object.keys(lines);

		orderedLines.forEach((line) => {
			const content = lines[line];
			let lastX = 0;
			let lastW = 0;
			let whiteSpaceWidth = TextTools.getFontAscentAndDescent(fontFamily, fontWeight, fontSize, ' ').width;
			whiteSpaceWidth -= whiteSpaceWidth * 0.08; // Margen de fallo del 8%

			content.forEach((item) => {
				let parentLetterSpacing = 0;
				let currentParent = item.parentElement;

				while (currentParent && currentParent.tagName !== 'text' && !parentLetterSpacing) {
					parentLetterSpacing = parseFloat(currentParent?.style?.letterSpacing || '0');
					currentParent = currentParent.parentElement;
				}

				const itemTextContent = item.textContent || '';
				const letterSpacing = parseFloat(item?.style?.letterSpacing || '0');
				const itemLetterSpacing =
					(letterSpacing !== 0 ? letterSpacing : parentLetterSpacing) * this.EM_TO_PX * (itemTextContent.length - 1);
				const itemX = parseFloat(item.outerHTML ? item.getAttribute('x') || '0' : '0');
				const itemW =
					TextTools.getFontAscentAndDescent(fontFamily, fontWeight, fontSize, item.textContent || '').width +
					itemLetterSpacing;

				// Si el item está más a la izquierda que el anterior
				// añadimos un espacio para separarlos
				const diff = parseFloat((Math.round(itemX) - Math.round(lastW + lastX)).toFixed(3));

				if (
					(diff >= parseFloat(whiteSpaceWidth.toFixed(3)) || lastX === itemX) &&
					textContent[textContent.length - 1] !== ' '
				) {
					textContent += ' ';
				}

				if (itemTextContent === '' && item?.tagName === 'tspan' && textContent[textContent.length - 1] !== ' ') {
					textContent += ' ';
				}

				lastX = itemX;
				lastW = itemW;

				textContent += itemTextContent;
			});

			textContent += ' ';
		});

		// Calculamos el ancho de cada linea y usamos el mayor
		const maxWidth = TextTools.getTextBoxWidth(Object.values(lines), fontFamily, fontWeight, fontSize);

		const { x: xNode, y: yNode, height: hNode } = textNode.node.getBoundingClientRect();

		return {
			height: hNode,
			width: maxWidth,
			x: xNode,
			y: yNode,
			lineHeight,
			textAlign: align as TextAlign,
			content: textContent.trim(),
		};
	}

	static getLineHeightBasedOnLinesPosY(linesPosY: number[], fontSize: number) {
		const positionsY = linesPosY.map((y, index, array) => (!index ? 0 : y - array[index - 1]));
		const positionsYCount = countBy(positionsY);

		const positionY = Object.keys(positionsYCount).reduce((prev, curr) =>
			!prev || positionsYCount[prev] <= positionsYCount[curr] ? curr : prev
		);

		let lineHeight = parseFloat(positionY) / fontSize;
		if (!lineHeight || Math.round(lineHeight) <= 0) lineHeight = 1.2;

		let lineHeightPx = parseFloat(positionY);
		if (!lineHeightPx || Math.round(lineHeightPx) <= 0) lineHeightPx = fontSize * 1.2;

		return { lineHeight, lineHeightPx };
	}

	static getTextBoxWidth(lines: SVGTSpanElement[][], fontFamily: string, fontWeight: number, fontSize: number) {
		const widths = lines.map((line) => {
			const lineLetterSpacing = line
				.map((item) => {
					let parentLetterSpacing = 0;
					let currentParent = item.parentElement;

					while (currentParent && currentParent.tagName !== 'text' && !parentLetterSpacing) {
						parentLetterSpacing = parseFloat(currentParent?.style?.letterSpacing || '0');
						currentParent = currentParent.parentElement;
					}

					const itemTextContent = item.textContent || '';
					const letterSpacing = parseFloat(item?.style?.letterSpacing || '0');
					const itemLetterSpacing =
						(letterSpacing !== 0 ? letterSpacing : parentLetterSpacing) * this.EM_TO_PX * (itemTextContent.length - 1);

					return itemLetterSpacing;
				})
				.reduce((prev, curr) => prev + curr, 0);

			const lineContent = line.map((item) => item.textContent || '').join('');
			const metrics = TextTools.getFontAscentAndDescent(fontFamily, fontWeight, fontSize, lineContent);

			return metrics.width + lineLetterSpacing;
		});
		return Math.max(...widths);
	}

	static getFontAscentAndDescent(fontName: string, fontWeight: number, fontSize: number, textContent: string) {
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
		ctx.font = `${fontWeight} ${fontSize}px '${fontName}'`;
		ctx.textBaseline = 'alphabetic';

		const metrics = ctx.measureText(textContent);
		return metrics;
	}

	static getTextShadowFilter(el: SvgElement): TextShadowProperties | undefined {
		const filterId = el.css('filter') ? ElementTools.getIdFromUrl(el.css('filter')) : null;

		if (!filterId) return;

		const filter = el.root().findOne(filterId);
		const feOffset = filter?.findOne('feOffset');
		const distance = feOffset?.attr('dy');
		const angle = MathTools.getAngle(
			0,
			0,
			parseFloat(feOffset?.attr('dy')) || 0,
			parseFloat(feOffset?.attr('dy')) || 0
		);
		const feGaussianBlur = filter?.findOne('feGaussianBlur');
		const blur = parseFloat(feGaussianBlur?.attr('stdDeviation') || '0');
		const feFlood = filter?.findOne('feFlood');
		const opacity = parseFloat(feFlood?.attr('flood-opacity') || '0');
		const color = feFlood?.attr('flood-color') || '#000000';

		return {
			angle,
			blur,
			color: SolidColor.fromString(color),
			distance,
			opacity,
		};
	}

	static getTextShadow(el: SvgElement): TextShadowProperties[] {
		const textShadowEl = el.node.style.textShadow ? el.node : el.findOne('[style*="text-shadow"]')?.node;

		if (!textShadowEl) return Text.defaults().textShadow;

		const { textShadow } = getComputedStyle(textShadowEl);
		const textShadowArray = textShadow.split('px, ');

		return textShadowArray.map((ts) => {
			// Extraemos el color y asignamos la opacidad a 1, ya que se gestiona a parte
			const color = SolidColor.fromString(ts);
			const opacity = color.a;
			color.a = 1;

			const splittedTextShadow = ts.split(') ')[1].split(' ');
			const blur = parseFloat(splittedTextShadow[2]);
			const x = parseFloat(splittedTextShadow[0]);
			const y = parseFloat(splittedTextShadow[1]);
			const angle = x ? Math.abs(MathTools.radiandsToAngle(Math.atan2(y, x))) : 0;
			const distance = Math.round(Math.sqrt(x ** 2 + y ** 2));
			const unit = 'px';

			return { angle, blur, color, distance, opacity, unit };
		});
	}

	static removeTextsStyles = (element: HTMLElement) => {
		const childNodes = Array.from(element.querySelectorAll('*')) as HTMLElement[];

		if (childNodes.length) {
			for (const child of childNodes) {
				child.removeAttribute('style');
				child.removeAttribute('class');

				// Restauramos el underline de los links
				if (child.tagName.toLowerCase() === 'a') {
					child.style.textDecoration = TextDecoration.underline;
				}
			}
		}
	};

	/**
	 * Genera automáticamente los SPAN o DIV que de forma nativa
	 */
	static generateChildrenSpan() {
		const sel = window.getSelection();
		if (sel?.rangeCount) {
			const range = sel.getRangeAt(0);

			const element = range.commonAncestorContainer instanceof HTMLElement ? range.commonAncestorContainer : null;
			const parentElement = range.commonAncestorContainer.parentElement;

			const finalElement = element || parentElement;

			// Si el elemento padre tiene un background, no hacemos nada
			// Comprobamos que la selección no sea el padre completo
			if (
				finalElement &&
				!(
					finalElement.style.backgroundImage.length &&
					Math.abs(sel.anchorOffset - sel.focusOffset) === finalElement.textContent?.length
				)
			) {
				// Esto fuerza a que el siguiente comando cree un span con la selección
				document.execCommand('styleWithCSS', true, undefined);
				// Ya que ahora mismo no damos soporte a underlines, creamos un underline con lo
				// seleccionado y luego le quitamos el style
				document.execCommand('backColor', false, '#000001');
			}
		}
	}

	/**
	 * Elimina el background de los hijos del nodo seleccionado
	 */
	static removeChildrenBackground(domNode: Ref<HTMLElement | null>) {
		const sel = window.getSelection();

		// Si la selección es un nodo con background, lo eliminamos
		// Asegurandonos de que el nodo no tienen un background-image (Gradiente)
		if (sel?.rangeCount && domNode.value) {
			const selectedNodesAfterCreation: NodeListOf<HTMLElement> =
				domNode.value.querySelectorAll('[style*="background-color"]');

			// Eliminamos el background de los nodos que se han creado
			selectedNodesAfterCreation.forEach((el) => {
				el.style.backgroundColor = '';
				el.style.background = '';
			});
		}
	}

	/**
	 * Obtenemos los nodos span que se han creado al seleccionar texto y sobre los que se va a aplicar el estilo
	 * @param finalNodes Node[]
	 * @returns HTMLElement[]
	 */
	static getNodesToStyle(finalNodes: Node[]) {
		// De toda la selección nos quedamos con los nodos de texto y descartamos los spans.
		const texts = finalNodes.filter((span) => span.nodeType === 3);

		// sacamos los span en los que estan los textos. ¿Por que no seleccionar directamente los spans?
		// por que el finalnodes tambien incluye los spans que incluyen otros spans y esos no queremos tocarlos
		let nodesToStyle = texts.map((el) => el.parentNode as HTMLElement);

		// Para realizar la vinculación con el clon del stroke, generamos un id random que luego consultar
		nodesToStyle = nodesToStyle.map((el) => {
			el.dataset.unique = `span-${Math.round(Math.random() * 100000)}`;
			return el;
		});

		return nodesToStyle;
	}

	static escapeRegExp = (string: string) =>
		string.replace(/[\!\”\\\#\$\%\&\’\(\)\*\+\,\/\:\;\<\=\>\?\@\[\]\^\_\`\{\|\}\~]/g, '\\$&'); // $& means the whole matched string

	/**
	 * Reemplaza todos los carácteres especiales a \specialChar de un texto
	 * @param str texto inicial
	 * @returns texto con carácteres reemplazados
	 */
	static replaceSpecialChars = (str: string) =>
		this.replaceBreakLines(
			str
				.replaceAll('.', '\\.')
				.replaceAll('!', '\\!')
				.replaceAll('”', '\\”')
				.replaceAll('#', '\\#')
				.replaceAll('$', '\\$')
				.replaceAll('%', '\\%')
				.replaceAll('&', '\\&')
				.replaceAll('’', '\\’')
				.replaceAll('(', '\\(')
				.replaceAll(')', '\\)')
				.replaceAll('*', '\\*')
				.replaceAll('+', '\\+')
				.replaceAll(',', '\\,')
				.replaceAll('-', '\\-')
				.replaceAll('/', '\\/')
				.replaceAll(':', '\\:')
				.replaceAll(';', '\\;')
				.replaceAll('<', '\\<')
				.replaceAll('=', '\\=')
				.replaceAll('>', '\\>')
				.replaceAll('?', '\\?')
				.replaceAll('@', '\\@')
				.replaceAll('[', '\\[')
				.replaceAll('\\', '\\')
				.replaceAll(']', '\\]')
				.replaceAll('^', '\\^')
				.replaceAll('_', '\\_')
				.replaceAll('`', '\\`')
				.replaceAll('{', '\\{')
				.replaceAll('|', '\\|')
				.replaceAll('}', '\\}')
				.replaceAll('~', '\\~')
		);

	/**
	 * Reemplaza todos saltos de línea de un texto
	 * @param str texto inicial
	 * @returns texto con saltos de línea reemplazados
	 */
	static replaceBreakLines = (str: string) => str.replace(/[\n\t]/g, '');

	static isValidUrl = (url: string) => {
		let urlObj = null;

		try {
			urlObj = new URL(url);
		} catch (_) {
			return false;
		}

		return urlObj.protocol === 'http:' || urlObj.protocol === 'https:';
	};

	static textShadowToCssString(textShadow: TextShadowProperties[]) {
		return textShadow
			.map((ts: TextShadowProperties) => {
				return `rgba(${ts.color.r}, ${ts.color.g}, ${ts.color.b}, ${ts.opacity}) ${
					Math.cos(MathTools.angleToRadians(ts.angle)) * ts.distance
				}${ts.unit} ${Math.cos(MathTools.angleToRadians(90 - ts.angle)) * ts.distance}${ts.unit} ${ts.blur}${ts.unit}`;
			})
			.join(', ');
	}

	static split(str: string) {
		if (!Intl || !Intl.Segmenter) {
			return [...str];
		}

		const splitWithEmojis = (string: string): string[] =>
			[...new Intl.Segmenter().segment(string)].map((x) => x.segment);

		return splitWithEmojis(str);
	}

	static waitFont(name: string, weight: string) {
		weight = weight.toString();
		const key = name + weight;

		if (weight.includes('i')) {
			weight = 'italic ' + weight.replace('i', '');
		}
		return new Promise((success, reject) => {
			if (TextTools.loadedFontWeights.has(`${key} ${weight}`)) {
				success(`Already loaded ${name} ${weight}`);
				return;
			}
			const interval = setInterval(async () => {
				//  obtenemos las fuentes del docummento y filtramos si es la que se trata de cargar
				const fontSet = document.fonts;
				let currentFontLoaded: FontFace | undefined;

				fontSet.forEach((f: FontFace) => {
					const fortmatWeightToFontFace = weight.includes('italic') ? weight.replace('italic', '').trim() : weight;
					const styleToFontFace = weight.includes('italic') ? 'italic' : 'normal';
					if (
						f &&
						f.family === name &&
						f.weight === fortmatWeightToFontFace &&
						f.style === styleToFontFace &&
						f.status === 'loaded'
					)
						currentFontLoaded = f;
				});

				// comprobamos si la fuente está cargada
				if (document.fonts.check(`${weight} 13px '${name}'`) || currentFontLoaded) {
					clearTimeout(timeout);
					clearInterval(interval);
					TextTools.loadedFontWeights.add(`${key} ${weight}`);
					success(`Loaded ${name} ${weight}`);
				}
			}, 6);

			const timeout = setTimeout(() => {
				clearInterval(interval);
				reject(`Failed waiting for ${name} ${weight}`);
			}, 15000);
		});
	}

	static async preloadFont(font: Font, weight: string) {
		const idFont = font.name.split(' ').join('-');
		if (!document.querySelector(`#preload-${idFont}-${weight}`)) {
			const div = document.createElement('DIV');
			weight = weight.toString();

			div.id = `preload-${idFont}-${weight}`;
			div.style.fontFamily = font.name;
			div.style.fontWeight = weight.replace('i', '');
			div.style.lineHeight = '0';
			div.style.fontSize = '0';
			div.style.fontStyle = weight.includes('i') ? 'italic' : 'normal';
			div.innerText = 'load me';
			document.body.appendChild(div);
		}
		// IMPORTANTE: necesitamos usar el slug para que todas las fuentes sean reconocidas por el navegador
		return await this.waitFont(font.slug, weight);
	}

	static removeTemporalElementToPreloadFont(name: string, weight: string) {
		document.querySelectorAll('[id^=preload]').forEach((preloadFont) => preloadFont.remove());
	}

	static fontFamilySlug(fontName: string) {
		const { fonts } = useFonts();

		return Object.values(fonts.value).find((font) => font.name === fontName)?.slug || fontName;
	}

	static fitEmShadowToFontSize(element: Text) {
		element.textShadow.forEach((ts) => {
			ts.blur /= element.fontSize;
			ts.distance /= element.fontSize;
			ts.unit = 'em';
		});
	}

	static convertShadowToEm(fontSize: number, textShadow: TextShadowProperties): TextShadowProperties {
		const factor = fontSize ? fontSize / 16 : 1;

		return {
			blur: textShadow.blur / 16 / factor,
			distance: textShadow.distance / 16 / factor,
			opacity: textShadow.opacity,
			unit: 'em',
			angle: textShadow.angle,
			color: textShadow.color,
		};
	}

	static capitalizeFirstLetter = (string: string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	};

	static parseTextColorsToCssVars(el: SerializedClass<Text>) {
		const mainDiv = document.createElement('div');
		if (el.content) {
			mainDiv.innerHTML = el.content;

			// Recorremos los elementos y si el elemento tiene un color, lo convertimos a color sólido y lo actualizamos
			Array.from(mainDiv.querySelectorAll<HTMLElement>('[style*="color"]')).forEach((textEl) => {
				// Si el elemento no tiene como color una variable, convertimos a color y asignamos su variable
				if (!textEl.style.color.startsWith('var(--color') && textEl.style.color.length) {
					const color = SolidColor.fromString(textEl.style.color);

					mainDiv.style.setProperty(`--${color.id}`, color.toCssString());
					textEl.style.color = `var(--${color.id})`;

					// Añadimos el nuevo color encontrado al array de colores
					if (el.colors) {
						el.colors.push(color);
					}
				}

				// Si el elemento tiene una variable asignada pero no tiene la variable declarada
				if (
					textEl.style.color.startsWith('var(--color') &&
					!Object.values(textEl.style).find((style) => style.startsWith('--color-'))
				) {
					const foundColor = el.colors?.find((c) => c.id === textEl.style.color.slice(6).split(')')[0]);

					// Si encontramos el color se lo añadimos al nodo
					if (foundColor) {
						mainDiv.style.setProperty(
							`--${foundColor.id}`,
							'rotation' in foundColor
								? GradientColor.fromObject(foundColor).toCssString()
								: SolidColor.fromObject(foundColor).toCssString()
						);
					}
				}
			});
		}

		// Retornamos el contenido del texto
		return mainDiv.innerHTML.toString();
	}

	/*
	Dado un nodo HTML, obtenemos su último nodo Text
	*/
	static getLastTextChild(node: HTMLElement | Node): ChildNode | Node | HTMLElement {
		if (!node.lastChild) return node;

		if (node.lastChild.nodeType === 1) {
			return this.getLastTextChild(node.lastChild as HTMLElement);
		}

		return node.lastChild;
	}

	/*
	Dado un nodo HTML, obtenemos su primer nodo Text
	*/
	static getFirstTextChild(node: HTMLElement | Node): ChildNode | Node | HTMLElement {
		if (!node.firstChild) return node;

		if (node.firstChild.nodeType === 1) {
			return this.getFirstTextChild(node.firstChild as HTMLElement);
		}

		return node.firstChild;
	}

	// Obtener el elemento con menor longitud de weigth de data
	private static getMinWeight(data: WeightProps) {
		let minWeight = data[0].weights.length;
		let minWeightIndex = 0;
		for (let i = 1; i < data.length; i++) {
			if (data[i].weights.length < minWeight) {
				minWeight = data[i].weights.length;
				minWeightIndex = i;
			}
		}
		return minWeightIndex;
	}

	// Find common weight given an array of weights
	private static findCommonWeight(weight: string[], fontArray: WeightProps) {
		const commonWeight = [];

		for (let i = 0; i < weight.length; i++) {
			let count = 0;

			for (let j = 0; j < fontArray.length; j++) {
				if (fontArray[j].weights.includes(weight[i])) {
					count++;
				}
			}

			if (count === fontArray.length) {
				commonWeight.push(weight[i]);
			}
		}

		return [...new Set(commonWeight)];
	}

	static getCommonWeights(weigths: WeightProps) {
		return this.findCommonWeight(weigths[this.getMinWeight(weigths)].weights, weigths);
	}

	/**
	 * Convierte los colores de un texto a variables CSS
	 * @param textNode
	 * @param color
	 * @param colors
	 */
	static applyColorVarToTextNode(textNode: HTMLElement, color: Color, colors?: Color[]) {
		textNode.style.webkitTextFillColor = color.isGradient() ? 'transparent' : `var(--${color.id})`;

		if (color.isGradient()) {
			textNode.style.color = '';

			textNode.style.backgroundImage = `var(--${color.id})`;
			const style = textNode.getAttribute('style');
			textNode.setAttribute('style', `${style} -webkit-background-clip: text;`);
		} else {
			textNode.style.color = `var(--${color.id})`;

			textNode.style.removeProperty('background-image');
			textNode.style.removeProperty('-webkit-background-clip');
		}

		if (colors && !colors.find((c) => c.id === color.id)) {
			colors.push(color);
		}
	}

	static async applyTextFixes(page: Page) {
		const { loadFonts } = useFonts();

		const container = document.createElement('div');
		container.style.position = 'absolute';
		container.style.top = '0px';
		container.style.width = '20000px';
		container.style.height = '20000px';
		container.style.zIndex = '999999';
		container.style.opacity = '0';
		container.style.pointerEvents = 'none';
		container.inert = true;

		document.body.appendChild(container);

		const promises = page
			.elementsAsArray()
			.filter((el): el is Text => el instanceof Text)
			.map(async (text) => {
				// Fix para los textos que tienen un color gradiente con id incorrecto
				if (text.color.id.startsWith('gradient-')) {
					text.color.id = text.color.id.replace('gradient-', 'color-');
				}

				// Fix para los textos que tienen un color gradiente con id incorrecto
				text.colors.forEach((c) => {
					if (c.id.startsWith('gradient-')) {
						c.id = c.id.replace('gradient-', 'color-');
					}
				});

				if (!text.metadata?.autofix) return;

				const childFonts = Array.from(text.htmlInstance().querySelectorAll<HTMLElement>('[style*="font-family"]')).map(
					(el) => ({ family: el.style.fontFamily.replace(/['"]+/g, ''), weight: el.style.fontWeight })
				);
				const fontFaces: FontFaceInfo[] = [
					{
						family: text.fontFamily,
						slug: text.fontFamily,
						weights: [`${text.fontWeight}`],
						preview: '',
					},
				];

				childFonts.forEach((font) => {
					if (fontFaces.find((f) => f.family === font.family && f.weights.includes(font.weight))) return;
					const index = fontFaces.findIndex((f) => f.family === font.family);
					if (index > -1) {
						fontFaces[index].weights.push(font.weight);
						return;
					}
					fontFaces.push({
						family: font.family,
						slug: font.family,
						weights: [font.weight],
						preview: '',
					});
				});

				await loadFonts(fontFaces);

				const textDiv = document.createElement('div');
				const textRef = ref<Text>(text as Text);
				const textStyles = getTextStyles(textRef);

				Object.assign(textDiv.style, textStyles.value);

				if (text.scale > 1.005 || text.scale < 0.995) {
					textDiv.style.fontSize = `${text.fontSize * text.scale}px`;
					textDiv.style.transform = 'none';
					textDiv.style.width = `${text.size.width}px`;
				}

				textDiv.innerHTML = (text as Text).content;
				textDiv.style.pointerEvents = 'none';

				container.appendChild(textDiv);

				const isOnTargetSize = (operation: number) => {
					const h = textDiv.getBoundingClientRect().height;

					// si lo estamos encogiendo
					if (operation === -1) {
						// queremos que el texto se ajuste al espacio actual tanto ancho como alto
						// ya que evitamos romper las lineas
						if (text.metadata.autofix.fitCurrentSpace) {
							return textDiv.clientHeight === textDiv.scrollHeight;
						}

						// si no queremos que el texto se ajuste al alto
						return text.size.height > h;
					}

					if (text.metadata.autofix.keepInCurrentSpace) {
						return true;
					}

					return text.size.height > h;
				};

				if (text.metadata.autofix.fitCurrentSpace || text.metadata.autofix.keepInCurrentSpace) {
					if (!text.size.height || !text.size.width) {
						return;
					}

					const bounding = textDiv.getBoundingClientRect();
					let elementEffectiveHeight = bounding.height;

					if (text.metadata.autofix.fitCurrentSpace) {
						textDiv.style.height = text.size.height + 'px';
						elementEffectiveHeight = textDiv.scrollHeight;
					}

					const operation = Math.round(text.size.height) > elementEffectiveHeight ? 1 : -1;

					if (Math.round(text.size.height) !== Math.round(elementEffectiveHeight)) {
						let ops = 0;

						while (!isOnTargetSize(operation) && ops < 1000) {
							ops++;
							textDiv.style.fontSize = parseFloat(textDiv.style.fontSize) + operation + 'px';
						}

						text.fontSize = parseFloat(textDiv.style.fontSize) / text.scale;
					}
				}

				if (text.metadata.autofix.box) {
					const fontData = TextTools.getFontAscentAndDescent(
						text.fontFamily,
						text.fontWeight,
						text.fontSize,
						textDiv.textContent || ''
					);
					text.position.y += fontData.fontBoundingBoxDescent / 2;
				}

				const bounding = textDiv.getBoundingClientRect();
				text.size.height = bounding.height;

				textDiv.remove();
				delete text.metadata.autofix;

				return true;
			});

		const shouldInvalidate = (await Promise.all(promises)).some((x) => x);

		container.remove();

		return shouldInvalidate;
	}

	static getFontFamiliesFromTextContent(text: Text) {
		const newDiv = document.createElement('div');
		newDiv.innerHTML = text.content;
		const nodes = Array.from(newDiv.querySelectorAll('*')) as HTMLElement[];
		const fontFamiliesFromNodes = nodes.map((node) => node.style.fontFamily).filter((fontFamily) => !!fontFamily);
		if (fontFamiliesFromNodes.length) return fontFamiliesFromNodes;
	}

	// Elimina el estilo de -webkit-text-stroke-color: initial; que se añade nativamente
	static fixTextStrokeColorStyles(content: string) {
		const defaultStroke = '-webkit-text-stroke-color: initial;';

		return content.includes(defaultStroke) ? content.replaceAll(defaultStroke, '') : content;
	}

	// Reemplazamos los -webkit-background-clip que se le modifica tras aplicar nuevos estilos se pierde el prefijo -webkit del background-clip
	static fixBackgroundClip(content: string) {
		return content.replaceAll('background-clip: text;', function (match, offset) {
			if (content[offset - 1] === '-') return match;

			return '-webkit-' + match;
		});
	}

	/**
	 * Añade el estilo -webkit-background-clip: text; a los nodos que no lo tienen y que tienen un color gradiente
	 */
	static fixNotFoundBackgroundClip(el: HTMLElement, color: Color) {
		// Si el nodo no tiene el estilo webkitBackgroundClip y es un span y el color del nodo es un gradiente
		const childStyle = el.getAttribute('style');

		if (!childStyle?.includes('-webkit-background-clip') && color instanceof GradientColor) {
			el.setAttribute('style', `${childStyle} -webkit-background-clip: text;`);
		}
	}

	static fixFontTagInTextContent(content: string) {
		if (!content.includes('<font')) {
			return content;
		}

		const parser = new DOMParser();
		const textContent = parser.parseFromString(content, 'text/html');

		// Hay textos que contienen el tag font y este no es necesario, así que
		// lo eliminamos y nos quedamos con el contenido
		textContent.querySelectorAll<HTMLElement>('font').forEach((font) => {
			font.outerHTML = font.innerHTML;
		});

		return textContent.body.innerHTML;
	}
	static removeMetaTags(content: string) {
		if (!content.includes('meta') && content.includes('iframe') && content.includes('img')) {
			return content;
		}

		const parser = new DOMParser();
		const textContent = parser.parseFromString(content, 'text/html');

		textContent.querySelectorAll<HTMLElement>('meta[http-equiv][content]').forEach((meta) => {
			meta.parentElement?.removeChild(meta);
		});

		textContent.querySelectorAll<HTMLElement>('iframe').forEach((iframe) => {
			iframe.parentElement?.removeChild(iframe);
		});

		textContent.querySelectorAll<HTMLElement>('img').forEach((img) => {
			img.parentElement?.removeChild(img);
		});

		return textContent.body.innerHTML;
	}
	/**
	 *Elimina los elementos span que contienen a su vez elementos span.
	 * Puede usarse después de hacer un reset de los estilos, ya que no interesa mantener nodos span de manera residual,
	 * pero si se ha mantenido el color en el nodo, no eliminamos el nodo
	 * @param ha de pasarse como nodo text-element-final, que es el que contiene los nodos hijos directos
	 */
	static removeChildNodesInSpan = (node: Ref<HTMLElement | null>) => {
		const nodes = Array.from((node.value?.querySelectorAll('*') as NodeListOf<Element>) || []) as HTMLElement[];
		nodes.forEach((node) => {
			if (node.children.length)
				Array.from(node.children).forEach((child) => {
					if (
						child.nodeName === 'SPAN' &&
						child.parentElement?.nodeName === 'SPAN' &&
						child.textContent &&
						!child.style.webkitTextFillColor
					) {
						const textContent = document.createTextNode(child.textContent);
						node.replaceChild(textContent, child);
					}
				});
		});
	};

	static extractSubTexts(el: ElementClass): Text[] {
		const extracted: Text[] = [];
		el.subElements.forEach((subEl: ElementClass) => {
			if (subEl instanceof Text) {
				extracted.push(subEl);
			}
			if (subEl.subElements.size) {
				extracted.push(...TextTools.extractSubTexts(subEl));
			}
		});
		return extracted;
	}

	/**
	 * Recibiendo un nodo, comprobamos su fontSize y si no tiene, recorremos desde el nodo hacia su padre de forma recursiva y obtenemos el primer fontSize que encontremos
	 * @param node Nodo del que queremos obtener el fontSize
	 * @param text Ref<Text> Texto del que queremos obtener el fontSize, en caso de que el nodo sea el nodo principal del texto
	 * @param defaultFontSize Valor por defecto que devolverá si no encuentra ningún fontSize
	 */
	static getFirstFontSizeFromNode(node: HTMLElement | Node, text: Ref<Text> | undefined = undefined): number {
		const nodeInsideText =
			(node instanceof HTMLElement && node.closest('.text-element-final')) ||
			(node.nodeType === 3 && node.parentElement?.closest('.text-element-final'));

		if (!nodeInsideText) return -1;

		if (node instanceof HTMLElement) {
			// Si ha recibido un nodo de texto, retornamos su fontSize
			if (node.classList.contains('text-element-final')) return text?.value.fontSize || parseFloat(node.style.fontSize);

			const fontSize = parseFloat(node.style.fontSize);

			if (fontSize) return fontSize;
		}

		if (node.parentElement) return this.getFirstFontSizeFromNode(node.parentElement, text);
		else return -1;
	}

	/**
	 * Resetea a '' los valores de la propiedad indicada para los hijos del nodo seleccionado
	 * @param style Hará referencia a una propiedad CSS fontSize, color, fontFamily
	 * @param domNode Nodo del que queremos resetear los hijos
	 */
	static resetChildrenStyle = (style: StyleProperties, domNode: Ref<HTMLElement | null>): void => {
		if (!domNode.value) return;

		const nodes = Array.from((domNode.value.querySelectorAll('*') as NodeListOf<Element>) || []) as HTMLElement[];
		// En caso de que no haya elementos span dentro del texto, salimos
		if (!nodes.length) return;
		Array.from(nodes).forEach((n) => {
			// Si el estilo es color, eliminamos también su variable asignada
			if (style === StyleProperties.color) {
				Object.values(n.style).forEach((style) => {
					if (style.includes('--color')) {
						n.style.removeProperty(style);
					}

					if (style === 'background-image') {
						n.style.removeProperty('background-image');
					}

					if (style === '-webkit-background-clip') {
						n.style.removeProperty('-webkit-background-clip');
					}

					if (style === 'background-clip') {
						n.style.removeProperty('background-clip');
					}

					if (style === '-webkit-text-fill-color') {
						n.style.removeProperty('-webkit-text-fill-color');
					}
				});
			}

			n.style[style] = '';
		});
	};

	/**
	 *  Comprueba los colores de los hijos de un nodo y si todos tienen el mismo color
	 * @param text Texto al que se le quiere limpiar los colores
	 * @param node Nodo del que se quiere limpiar los colores
	 * @returns { childrenColors: string[], hasSameColor: boolean }
	 */
	static getNodeChildrenColors(text: Text, node: HTMLElement) {
		const childrenColors = Array.from(node.childNodes).map((child) => {
			if (child instanceof HTMLElement) {
				const childColorId = child.style.color && child.style.color.split('(--')[1].split(')')[0];
				const foundChildColor = text.colors?.find((c) => c.id === childColorId);

				return foundChildColor?.toCssString();
			}

			return '';
		});

		const childrenHasSameColor = childrenColors.every((color, index, array) => {
			if (index === 0) return true;

			return color === array[index - 1];
		});

		return { childrenColors, hasSameColor: childrenHasSameColor };
	}

	static resetChildrenColors(text: Text, parentNode: HTMLElement, childrenColors: (string | undefined)[]) {
		const childColor = text.colors.find((color) => color.toCssString() === childrenColors[0]);

		if (!childrenColors.includes(text.color.toCssString()) && childColor) {
			text.color = childColor;
		}

		(Array.from(parentNode.childNodes) as HTMLElement[]).forEach((child) => {
			child.style.color = '';
		});
	}

	/**
	 * Limpia los colores de los nodos hijos que tengan el mismo color que el padre
	 *
	 * @param text Texto al que se le quiere limpiar los colores
	 * @param node Nodo del que se quiere limpiar los colores
	 * @returns
	 */
	static cleanTextColors(text: Text, node: HTMLElement) {
		const children = node.children;

		// Buscamos si el nodo tiene hijos y si es así, recorremos sus hijos
		// para comprobar si tienen el mismo color que el padre
		if (children.length) {
			(Array.from(children) as HTMLElement[]).forEach((child) => {
				TextTools.cleanTextColors(text, child);
			});
		}

		// Si el nodo NO es el nodo principal del texto
		if (!node.classList.contains('text-element-final')) {
			// Si el nodo tiene un color, comprobamos si es el mismo que el del padre
			if (node.style && node.style.color) {
				const colorStyle = node.style.color;

				// Obtenemos el color del nodo
				const nodeColorId = colorStyle?.split('(--')[1]?.split(')')[0];
				const foundNodeColor = text.colors?.find((c) => c.id === nodeColorId);

				const parent = node.parentElement;

				if (parent) {
					// Comprobamos si todos los hijos tienen el mismo color, según su cadena CSS
					const { childrenColors, hasSameColor } = TextTools.getNodeChildrenColors(text, parent);

					// comprobamos si todos los hijos tienen el mismo color y si es así, asignamos el color al padre y se lo quitamos a los hijos
					if (
						hasSameColor &&
						Array.from(parent.childNodes).filter(Boolean).length &&
						!(parent instanceof HTMLDivElement && !parent.hasAttribute('style'))
					) {
						TextTools.resetChildrenColors(text, parent, childrenColors);
					} else {
						// Comprobamos si el color del nodo es el mismo que el del padre
						// Si es así, eliminamos el color del nodo

						// Obtenemos el color del padre
						const parentColorId = parent.style.color?.split('(--')[1]?.split(')')[0];
						const foundParentColor = text.colors?.find((c) => c.id === parentColorId);

						if (foundNodeColor?.toCssString() === foundParentColor?.toCssString()) {
							node.style.color = '';
						}
					}
				}
			}
		}

		// Si el nodo es el nodo principal del texto
		if (node.classList.contains('text-element-final')) {
			// Si todos los nodos tienen aplicado un color que no es el del Text.color, asignamos el color del primer nodo al Text.color
			const htmlNodes = Array.from(node.querySelectorAll<HTMLElement>('*:not(br)'));

			// Comprobamos si hay parte de texto usando text.color (color principal)
			const isTextFinalApplyingToNode = htmlNodes.some((child) => {
				if (child instanceof HTMLDivElement && !child.hasAttribute('style')) return false;

				const closest = child.closest("[style*='color']");

				return !child.style.color && closest?.classList.contains('text-element-final');
			});

			if (isTextFinalApplyingToNode) {
				const firstColorInChildren =
					node.style.color || node.querySelector<HTMLElement>('[style*="color"]')?.style.color;

				const firstColorInChildrenId = firstColorInChildren?.split('(--')[1]?.split(')')[0];
				const firstChildColorToApply = text.colors?.find((c) => c.id === firstColorInChildrenId);

				if (firstChildColorToApply) {
					text.color = firstChildColorToApply;
				}
			}
		}

		// Comprobamos el contenido actualizado, y si es distinto al contenido del nodo, actualizamos el contenido del nodo
		const mainNode = node.classList.contains('text-element-final') ? node : node.closest('.text-element-final');

		if (mainNode && mainNode.innerHTML !== text.content) {
			text.content = mainNode.innerHTML;
		}
	}

	static purifyTextColors = (text: Text) => {
		const temporalRef = ref(text);
		const { removeUnusedColors } = useTextCurate(temporalRef);

		// Creamos un div con el contenido del texto para poder buscar los colores de los hijos
		const div = document.createElement('div');
		div.style.color = `var(--${temporalRef.value.color.id})`;
		div.classList.add('text-element-final');
		div.innerHTML = temporalRef.value.content;

		// Eliminamos los colores de los hijos de los nodos
		TextTools.cleanTextColors(temporalRef.value, div);

		// Eliminamos los colores que no se estén usando
		removeUnusedColors(temporalRef, div);

		// Eliminamos el div
		div.remove();
	};
}

export default TextTools;
