import Bugsnag from '@bugsnag/js';
import sha256 from 'crypto-js/sha256';
import { diff, jsonPatchPathConverter } from 'just-diff';
import { cloneDeep, findIndex } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import { Filter } from '@/elements/medias/filter/classes/Filter';
import TemplateLoader from '@/loader/utils/TemplateLoader';
import Page from '@/page/classes/Page';
import Project from '@/project/classes/Project';
import { SerializedPageState } from '@/Types/history';
import { ToSerialize } from '@/utils/classes/ToSerialize';

// TODO: revisar tipos
type DiffEntry = { op: string; path: string; value: any; oldValue?: any };
type Diff = Array<DiffEntry>;

export class HistoryState {
	id: string;
	index: number;
	scale: number;
	scaleIOS: number;
	date: number;
	diff: Diff;

	constructor(state: Project, prevState: Project, index: number, scaleIOS: number) {
		this.id = uuidv4();
		this.index = index;
		this.scale = state.scale;
		this.scaleIOS = scaleIOS;
		this.date = Math.round(new Date().getTime() / 1000);
		this.diff = this.getDiff(cloneDeep(state), prevState);
	}

	static generateSyncData(
		serverVersions: { [p: string]: Page },
		pagesAtSync: { [p: string]: Page },
		fullSyncRequests: string[] = [],
		scale: number
	): SerializedPageState[] {
		// Filter pages that arent in the server, or doesnt have the same version hash
		return Object.values(pagesAtSync).map((page): SerializedPageState => {
			// Como podemos estar aplicando una escala al documento para evitar problemas de memoria en IOS, vamos a clonar
			// cada página y vamos a quitarle la escala que tiene aplicada para la sesión "visual" del editor
			const clone = cloneDeep(page);
			try {
				clone.scaleBy(1 / scale);
			} catch (e) {
				Bugsnag.leaveBreadcrumb(`Error scaling page HistoryState.ts`, {
					clone,
				});
				throw e;
			}

			// Serializamos los los Map de JS para evitar problemas
			const elements: any = {};
			clone.elements.forEach((element) => {
				const propertiesNum = Object.keys(element).length;
				elements[element.id] = element.toSerialize();

				if (propertiesNum > Object.keys(elements[element.id]).length) {
					Bugsnag.notify(`Error serializing element HistoryState.ts, ${element.type}`);
				}
			});

			clone.elements = elements;

			const serverVersion = serverVersions[page.id] && cloneDeep(serverVersions[page.id]);
			let serverVersionElement;
			if (serverVersion) {
				serverVersionElement = Object.fromEntries(serverVersions[page.id].elements);
				// @ts-ignore
				serverVersion.elements = serverVersionElement;
			}

			const content = !serverVersions[page.id] || fullSyncRequests.includes(clone.id) ? JSON.stringify(clone) : null;
			const newDiff = !content ? diff(serverVersion, clone, jsonPatchPathConverter) : null;
			const pagePosition = findIndex(Object.values(pagesAtSync), (p) => page.id === p.id);

			return {
				id: page.id,
				hash: HistoryState.hash(page),
				diff: newDiff,
				content,
				position: pagePosition,
			};
		});
	}

	private static hash(page: Page): string {
		let content = `${page.id}`;

		page.elements.forEach((element) => {
			content = `${content}${element.type}-${element.id}`;
		});

		return sha256(content).toString();
	}

	/**
	 *
	 * @param diff
	 * @param state
	 * @returns Sanitezed diff
	 * @description
	 * 	Esta función detecta exclusivamente los cambios de tipo de color (Solid a Gradient y viceversa)
	 *	Cuando cambiamos un SolidColor por otro dejamos que el diff se aplique normalmente.
	 * 	Cuando cambiamos de SolidColor a GradientColor, el diff nos devuelve:
	 *	{ op: 'remove', path: '/background/r', value: 0 }
	 *	{ op: 'remove', path: '/background/g', value: 0 }
	 *	[...]
	 *	{ op: 'add', path: '/background/stops/0/r', value: 255 }
	 *	{ op: 'add', path: '/background/stops/0/g', value: 255 }
	 *	[...]
	 *	En este flujo vamos a unificar las propiedades en instancias de SolidColor y GradientColor
	 *	para añadir al this.diff un único registro de tipo { op: 'replace' } que al aplicar el diff
	 *	nos cambiará no las propiedades por separado sino una instancia de Color por otra
	 */
	private convertColorPropsToColorInstances(diff: Diff, state: Project) {
		const commonPaths: string[] = [];

		diff.forEach((d: DiffEntry) => {
			if (d.op !== 'add') return;

			// Si es un objeto que pudiera ser una instancia de GradientColor o SolidColor
			// lo convertimos a su clase correspondiente.
			if (d.value && !Array.isArray(d.value) && typeof d.value === 'object') {
				const isGradientColor =
					'type' in d.value && 'rotation' in d.value && 'stops' in d.value && 'validForVariant' in d.value;
				if (isGradientColor) {
					const grad = GradientColor.unserialize(d.value);
					d.value = grad;
					return;
				}

				const isSolidColor =
					'r' in d.value && 'g' in d.value && 'b' in d.value && 'a' in d.value && 'validForVariant' in d.value;
				if (isSolidColor) {
					const solid = SolidColor.unserialize(d.value);
					d.value = solid;
					return;
				}

				const isTextShadow =
					'angle' in d.value &&
					'blur' in d.value &&
					'color' in d.value &&
					'distance' in d.value &&
					'opacity' in d.value &&
					'unit' in d.value;
				if (isTextShadow) {
					const solid = SolidColor.unserialize(d.value.color);
					d.value.color = solid;
					return;
				}
			}

			const splittedPath = d.path.split('/');

			// Podemos identificar los registros de los gradientes basándonos en que tienen esas propiedades
			const isGradientColorProp = ['rotation', 'stops', 'type'].includes(splittedPath[splittedPath.length - 1]);

			// Para el caso del SolidColor tenemos que ignorar los registros provocados por cambiar los stops
			// de un gradiente puesto que no son instancias de GradientColor ni SolidColor. En ese caso
			// vamos a dejar que el diff se aplique normalmente.
			const isSolidColorProp =
				!d.path.includes('/stops/') && ['r', 'g', 'b', 'a'].includes(splittedPath[splittedPath.length - 1]);

			if (!isGradientColorProp && !isSolidColorProp) return;

			const commonPath = splittedPath.slice(0, splittedPath.length - 1).join('/');
			if (!commonPaths.includes(commonPath)) commonPaths.push(commonPath);
		});

		if (!commonPaths.length) return diff;

		// Ingore entries in the diff which path matches with a detected color path
		const filteredDiff = diff.filter((d: DiffEntry) => {
			const splittedPath = d.path.split('/');
			const commonPath = splittedPath.slice(0, splittedPath.length - 1).join('/');
			return !commonPaths.includes(commonPath);
		});

		// Create a new 'replace' entry for each detected color
		const diffsWithInstances: Diff[] = [];
		commonPaths.forEach((path) => {
			const foundColor = this.getPropByPath(path, state);
			const color = cloneDeep(foundColor);
			color.id = foundColor.id;

			diffsWithInstances.push({
				op: 'replace',
				path,
				value: color,
			});
		});

		return [...filteredDiff, ...diffsWithInstances];
	}

	private convertElementPropsToElementInstances(diff: Diff) {
		diff.forEach((d) => {
			const { op, value } = d;
			if (op !== 'add') return;
			if (typeof value !== 'object' || !value) return;

			const isElement =
				'type' in value &&
				'metadata' in value &&
				'size' in value &&
				'position' in value &&
				'rotation' in value &&
				'flip' in value &&
				'group' in value &&
				'locked' in value &&
				'keepProportions' in value &&
				'opacity' in value &&
				'virtualGroup' in value &&
				'tags' in value &&
				'index' in value &&
				'parentId' in value &&
				'subElements' in value;

			if (isElement) {
				const element = TemplateLoader.unserializeElement(value);
				d.value = element;
			}
		});

		return diff;
	}

	private convertFilterPropsToFilterInstances(diff: Diff) {
		diff.forEach((d) => {
			const { op, value } = d;
			if (op !== 'replace') return;
			if (typeof value !== 'object' || !value) return;

			const isFilter =
				'name' in value &&
				'brightness' in value &&
				'contrast' in value &&
				'saturate' in value &&
				'sepia' in value &&
				'grayscale' in value &&
				'invert' in value &&
				'hueRotate' in value &&
				'duotone' in value &&
				'blur' in value &&
				'overlay' in value;

			if (isFilter) {
				const filter = Filter.unserialize(value);
				d.value = filter;
			}
		});

		return diff;
	}

	private convertPagePropsToPageInstances(diff: Diff) {
		diff.forEach((d) => {
			const { op, value } = d;
			if (op !== 'add') return;
			if (typeof value !== 'object' || !value) return;

			const isPage =
				'sourceId' in value &&
				'name' in value &&
				'background' in value &&
				'backgroundImageId' in value &&
				'preview' in value &&
				'elements' in value;

			if (isPage) {
				const page = Page.unserialize(value);
				d.value = page;
			}
		});

		return diff;
	}

	private getDiff(state: Project, prevState: Project) {
		// Tenemos que sacar el diff con los elementos serializados para que la librería no tenga
		// problemas con los Map que pueda haber en el árbol. Le pasamos un clon de los estados
		// para seguir manipulando los originales (que tienen las instancias de las clases y demás).
		const diffBeforeFix = this.getDiffFromSerializedPageState(cloneDeep(state), cloneDeep(prevState));

		let fixedDiff = this.convertElementPropsToElementInstances(diffBeforeFix);
		fixedDiff = this.convertFilterPropsToFilterInstances(diffBeforeFix);
		fixedDiff = this.convertPagePropsToPageInstances(diffBeforeFix);

		// Debe ir al final para excluir los registros de GradientColor y SolidColor al cambiar
		fixedDiff = this.convertColorPropsToColorInstances(diffBeforeFix, state);

		return fixedDiff.map((d) => {
			return { ...d, oldValue: this.getPropByPath(d.path, prevState) };
		});
	}

	// Serializamos los los Map de los elementos de las página en su estado previo para poder compararlos
	private getDiffFromSerializedPageState(state: Project, prevState: Project) {
		state.pages.forEach((page) => {
			page.elements = ToSerialize.do(page.elements);
		});
		prevState.pages.forEach((page) => {
			page.elements = ToSerialize.do(page.elements);
		});

		// Sacamos el diff del proyecto y le añadimos a cada registro el valor anterior
		return diff(prevState, state, jsonPatchPathConverter);
	}

	applyDiff(project: Project, dir: 'forward' | 'rollback') {
		// Cuando generamos cambios, el diff se genera secuencialmente, por lo que si queremos
		// deshacer un cambio, tenemos que aplicar el diff en orden inverso.
		let isReversed = false;

		if (dir === 'rollback') {
			isReversed = true;
			this.diff.reverse();
		}

		this.diff.forEach((d: any) => {
			const { op } = d;

			if (op === 'add') {
				// @ts-ignore
				dir === 'rollback'
					? this.deletePropertyByPath(d.path, project)
					: this.addPropertyByPath(d.path, d.value, project);
				return;
			}

			if (op === 'remove') {
				// @ts-ignore
				dir === 'rollback'
					? this.addPropertyByPath(d.path, d.oldValue, project)
					: this.deletePropertyByPath(d.path, project);
				return;
			}

			this.setPropByPath(d.path, dir === 'rollback' ? d.oldValue : d.value, project);
		});

		// Si hemos revertido el diff, lo volvemos a dejar en orden normal.
		if (isReversed) this.diff.reverse();
	}

	private getPropByPath(path: string, source: Project) {
		const parts = path.split('/');
		let current = source;

		for (const part of parts) {
			if (part !== '') {
				// Check if the current property is a Map
				if (current instanceof Map) {
					current = current.get(part);
				} else {
					current = current[part];
				}
			}
		}

		return current;
	}

	private setPropByPath(path: string, value: any, source: object) {
		const parts = path.split('/').filter(Boolean);
		let current = source;

		for (let i = 0; i < parts.length; i++) {
			const part = parts[i];

			if (i === parts.length - 1) {
				// If it's the last part, set the value
				if (current instanceof Map) {
					current.set(part, value);
				} else {
					current[part] = value;
				}
				continue;
			}

			// Navigate to the next level
			if (current instanceof Map) {
				if (!current.has(part) || typeof current.get(part) !== 'object') {
					// Create a new Map if the key doesn't exist or if the existing value is not an object
					current.set(part, new Map());
				}
				current = current.get(part);
			} else {
				if (!current[part] || typeof current[part] !== 'object') {
					// Create a new object if the property doesn't exist or if the existing value is not an object
					current[part] = {};
				}
				current = current[part];
			}
		}
	}

	private deletePropertyByPath(path: string, source: Project) {
		const parts = path.split('/');
		let current = source;

		for (let i = 0; i < parts.length; i++) {
			const part = parts[i];
			if (part === '') continue;
			// If it's the last part, delete the property
			if (i === parts.length - 1) {
				// If it is an array, we are deleting an element
				if (Array.isArray(current)) {
					const index = parseInt(part, 10);
					current.splice(index, 1);
				} else if (current instanceof Map) {
					current.delete(part);
				} else {
					delete current[part];
				}
			} else {
				// Navigate to the next level
				const next = current instanceof Map ? current.get(part) : current[part];
				if (next && typeof next === 'object') {
					current = next;
				} else {
					// Property or key not found, stop the process
					break;
				}
			}
		}
	}

	private addPropertyByPath(path: string, value: any, source: Project) {
		const parts = path.split('/');
		let current = source;

		for (let i = 0; i < parts.length; i++) {
			const part = parts[i];
			if (part === '') continue;

			// If it's the last part, add the property
			if (i === parts.length - 1) {
				if (current instanceof Map) {
					current.set(part, value);
				} else {
					current[part] = value;
				}
			} else {
				// Navigate to the next level
				const next = current instanceof Map ? current.get(part) : current[part];
				if (next && typeof next === 'object') {
					current = next;
				} else {
					// Create a new object or Map if the property or key doesn't exist
					const newObject = current instanceof Map ? new Map() : {};
					current[part] = newObject;
					current = newObject;
				}
			}
		}
	}
}
