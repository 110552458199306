import { UseFetchOptions, UseFetchReturn } from '@vueuse/core';
import { Ref, ref } from 'vue';

import { concatenatedTemplatesResponseMapper, splittedTemplatesResponseMapper } from '@/api/ClientApiMapper';
import { useEditorApiFetch } from '@/api/composables/useEditorApiFetch';
import { MappedApiResponse, MaskApi } from '@/Types/apiClient';

export const getConcatenatedMasks = (
	url: Ref<string>,
	options: UseFetchOptions = {}
): UseFetchReturn<MappedApiResponse<MaskApi[]>> => {
	const concatenatedData = ref<MaskApi[]>([]);

	return useEditorApiFetch(url, {
		...options,
		afterFetch: (ctx) => concatenatedTemplatesResponseMapper(ctx, concatenatedData),
	})
		.get()
		.json();
};

export const getSplittedMasks = (
	url: Ref<string>,
	options: UseFetchOptions = {}
): UseFetchReturn<MappedApiResponse<MaskApi[]>> => {
	return useEditorApiFetch(url, {
		...options,
		afterFetch: (ctx) => splittedTemplatesResponseMapper(ctx),
	})
		.get()
		.json();
};
