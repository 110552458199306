import { cloneDeep } from 'lodash';
import { computed, Ref, ref } from 'vue';

import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import { useCircleTypeInfo } from '@/elements/texts/curved/composables/useCircleTypeInfo';
import { Text } from '@/elements/texts/text/classes/Text';
import { useTextColor } from '@/elements/texts/text/composables/useTextColor';
import { useTextEffects } from '@/elements/texts/text/composables/useTextEffects';
import { useTextStyles } from '@/elements/texts/text/composables/useTextStyles';
import { useProjectStore } from '@/project/stores/project';
import { Color } from '@/Types/colorsTypes';
import { TextEffects } from '@/Types/types';

export const useProjectTextColors = (textRef: Ref<Text>) => {
	const project = useProjectStore();
	const { outline, textShadow } = useTextStyles(textRef);
	const { colors } = useTextColor(textRef);
	const { isCircleText } = useCircleTypeInfo(textRef);

	const { hasColoredEffect, hasEcho, hasNeon, refreshEffect } = useTextEffects(textRef);

	const hasGradient = computed(() => colors.value.some((color: Color) => color instanceof GradientColor));

	const getSelectedColors = (oldColor: Color, newColor: Color) => {
		const selectedColor = colors.value
			.filter((c) => c)
			.find((c) => c.toCssStringWithoutAlpha() === oldColor.toCssStringWithoutAlpha());

		if (selectedColor && selectedColor instanceof SolidColor && newColor instanceof SolidColor) {
			selectedColor.r = newColor.r;
			selectedColor.g = newColor.g;
			selectedColor.b = newColor.b;
			selectedColor.a = newColor.a;
		}

		if (selectedColor && selectedColor instanceof GradientColor && newColor instanceof GradientColor) {
			selectedColor.stops = newColor.stops;
			selectedColor.rotation = newColor.rotation;
			selectedColor.type = newColor.type;
		}

		return selectedColor;
	};

	const updateColor = (oldColor: Color, newColor: Color) => {
		const finalColor = cloneDeep(newColor);

		const selectedColor = getSelectedColors(oldColor, newColor);
		let mainNode = textRef.value.domNode()?.querySelector<HTMLElement>('.text-element-final');
		const multicanvas = project.pages.length > 1;
		let tempNode = <HTMLElement | null>null;
		// si no lo encuentra (por si estuviera en una página diferente a la active) buscamos en el textRef.content y creamos un nodo temporal para buscar dentro
		if (!mainNode && multicanvas) {
			tempNode = document.createElement('div');
			tempNode.innerHTML = textRef.value.content;
			mainNode = tempNode;
		}
		const children = mainNode?.querySelectorAll<HTMLElement>('*');

		// Si no encuntra un color seleccionado o el color no se encuentra en los colores del texto, salimos
		if (!selectedColor) return;

		// Algunos efectos dependen del color del texto y deben actualizarse
		if (hasColoredEffect.value) {
			if (hasEcho.value) refreshEffect(TextEffects.Echo);
			if (hasNeon.value) refreshEffect(TextEffects.Neon);
		}

		finalColor.id = selectedColor.id;

		// Cambiamos el color al root si este tiene el color
		if (textRef.value.color.id === finalColor.id) {
			textRef.value.updateColor(finalColor);
		}

		// Cambiamos el color al hijo si este tiene el color
		if (children) {
			Array.from(children).forEach((child) => {
				Object.values(child.style).forEach((style: any) => {
					if (style.includes(finalColor.id) && !style.includes('var(')) {
						// Hack necesario para que se refresque la cadena del nodo final, es necesario borrar el estilo y volver a añadirselo en lugar de reemplazar su contenido
						child.style.removeProperty(style);

						child.style.webkitTextFillColor = finalColor.isGradient() ? 'transparent' : `var(--${finalColor.id})`;

						if (finalColor instanceof GradientColor) {
							child.style.color = '';

							if (isCircleText.value) {
								finalColor.rotation = 0;
								finalColor.type = 'linear';
							}

							// Debe estar aquí porque si no, no coge bien la propiedad -webkit-background-clip
							child.style.setProperty(`--${finalColor.id}`, finalColor.toCssString());

							child.style.backgroundImage = `var(--${finalColor.id})`;
							const stylesString = child.getAttribute('style');
							if (!stylesString?.includes('-webkit-background-clip')) {
								child.setAttribute('style', `${stylesString} -webkit-background-clip: text;`);
							}
							return;
						}

						child.style.setProperty(`--${finalColor.id}`, finalColor.toCssString());
						child.style.color = `var(--${finalColor.id})`;

						child.style.removeProperty('background-image');
						child.style.removeProperty('-webkit-background-clip');
					}
				});
			});
		}

		// Actualizamos el color dentro del array de colores del texto
		const foundIndex = textRef.value.colors.findIndex((c) => c.id === finalColor.id);

		if (foundIndex >= 0) {
			textRef.value.colors.splice(foundIndex, 1, finalColor);
		}

		// Actualizamos el contenido para forzar el recomputado del texto
		if (mainNode) {
			textRef.value.updateContent(mainNode.innerHTML);
		}
		// elinimaos el nodo temporal tempNode si existe
		if (tempNode) tempNode.remove();
	};

	const updateOutlineColor = (oldColor: Color, newColor: Color) => {
		const selectedColor = outline.value.color.toCssStringWithoutAlpha() === oldColor.toCssStringWithoutAlpha();
		if (!selectedColor) return;

		if (textRef.value.outline.color instanceof SolidColor && newColor instanceof SolidColor) {
			const { r, g, b, a } = newColor;
			const supportColor = SolidColor.fromObject({ r, g, b, a });
			supportColor.id = textRef.value.outline.color.id;
			textRef.value.outline.color = supportColor;
		}
	};

	const updateTextShadowColor = (oldColor: Color, newColor: Color) => {
		const selectedColor = textShadow.value.find(
			(ts) => ts.color.toCssStringWithoutAlpha() === oldColor.toCssStringWithoutAlpha()
		);
		if (!selectedColor) return;

		if (selectedColor.color instanceof SolidColor && newColor instanceof SolidColor) {
			const { r, g, b, a } = newColor;
			const supportColor = SolidColor.fromObject({ r, g, b, a });
			supportColor.id = selectedColor.color.id;
			textRef.value.textShadow[0].color = supportColor;
		}
	};

	return {
		outline,
		colors,
		hasGradient,
		updateColor,
		updateOutlineColor,
		updateTextShadowColor,
	};
};
