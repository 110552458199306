import { Ref } from 'vue';

import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useIsBackground } from '@/elements/element/composables/useIsBackground';
import { useTransform } from '@/elements/element/composables/useTransform';
import { CroppeableElement } from '@/elements/medias/crop/types/croppeable.type';
import { useArtboard } from '@/project/composables/useArtboard';
import { Anchor, Position, Size } from '@/Types/types';

export const useCroppeableTransform = (element: Ref<CroppeableElement>) => {
	const usingTransform = useTransform(element);
	const { artboardSizeInPx } = useArtboard();
	const { isBackground } = useIsBackground(element);
	const { isRenderingContext } = useEditorMode();

	const adjustTo = (side: keyof Size, val: number) => {
		// Save size before update to use it in crop
		const prevSize = {
			width: element.value.size.width,
			height: element.value.size.height,
		};

		usingTransform.adjustTo(side, val);

		adjustCrop(prevSize, element.value.size);
	};

	const adjustCrop = (prevSize: Size, newSize: Size) => {
		const ratio = {
			x: newSize.width / prevSize.width,
			y: newSize.height / prevSize.height,
		};
		element.value.crop.size.width *= ratio.x;
		element.value.crop.size.height *= ratio.y;
		element.value.crop.position.x *= ratio.x;
		element.value.crop.position.y *= ratio.y;
	};

	const adjustToNewArtboard = (zeroPosition: Position, centeredPosition: Position, scale: number) => {
		// ! IMPORTANTE: Si estamos en la descarga y la imagen es background continuamos la ejecución
		// ! ya que tenemos que ajustarla al cambio de unidad (px a mm y viceversa)
		if (isBackground.value) {
			element.value.setLocked(false);
			fitAndCenterInArtboard();
			element.value.setLocked(true);
			return;
		}

		usingTransform.adjustToNewArtboard(zeroPosition, centeredPosition, scale);
	};

	const fitAndCenterInArtboard = () => {
		// Adjust to width or height regarding page orientation
		adjustTo('width', artboardSizeInPx.value.width);

		if (element.value.size.height < artboardSizeInPx.value.height) {
			adjustTo('height', artboardSizeInPx.value.height);
		}

		// Align image to center horizontally and vertically
		usingTransform.align(Anchor.center);
	};

	const flipAxis = (axis: 'x' | 'y'): void => {
		usingTransform.flipAxis(axis);

		if (!element.value.hasCrop()) return;

		if (axis === 'x') {
			let x = 0;
			if (Math.floor(element.value.crop.position.x) > 0) {
				x = Math.abs(element.value.crop.size.width - element.value.size.width) - element.value.crop.position.x;
			} else {
				x = (element.value.crop.size.width - element.value.size.width + element.value.crop.position.x) * -1;
			}
			element.value.crop.position.x = x;
		}

		if (axis === 'y') {
			let y = 0;
			if (Math.floor(element.value.crop.position.y) > 0) {
				y = Math.abs(element.value.crop.size.height - element.value.size.height) - element.value.crop.position.y;
			} else {
				y = (element.value.crop.size.height - element.value.size.height + element.value.crop.position.y) * -1;
			}
			element.value.crop.position.y = y;
		}
	};

	return {
		...usingTransform,
		adjustTo,
		flipAxis,
		fitAndCenterInArtboard,
		adjustToNewArtboard,
	};
};
