import { createSharedComposable } from '@vueuse/core';
import { computed, ref } from 'vue';
import { createI18n } from 'vue-i18n';

import { useEnvSettings } from '@/common/composables/useEnvSettings';
import es from '@/locales/es.json';


const detectLanguage = () => {
	const urlLocale = new URLSearchParams(window.location.search).get('lang') || '';

	if (urlLocale) {
		const allowedLocales = ['en', 'es'];
		const newLocale = allowedLocales.includes(urlLocale) ? urlLocale : 'en';

		document.documentElement.lang = newLocale;
		return newLocale;
	}

	return document.documentElement.lang || 'en';
};

export const i18n = createI18n({
	locale: detectLanguage(),
	fallbackLocale: 'en',
	globalInjection: true,
	formatFallbackMessages: true,
	silentTranslationWarn: true,
	silentFallbackWarn: true,
	missing: (locale, key) => key,
	messages: {
		es,
	},
});

export const useI18n = createSharedComposable(() => {
	const { t, locale } = i18n.global;
	const languages = ['es-ES', 'en-US'];
	const localeFormat = computed(() => {
		const format = languages.find((lang) => lang.includes(locale.value));
		return format;
	});

	const translate = (value, params) => {
		const translation = t(value, params);
		if (translation.trim() === '') {
			return value;
		}

		if (params && translation === value) {
			return value.replace(/\{(\w+)\}/g, (match, key) => {
				return params.hasOwnProperty(key) ? params[key] : match;
			});
		}
		return translation;
	};

	return {
		t,
		trans: translate,
		locale: ref(String(locale.value)),
		i18n,
		localeFormat,
	};
});
