import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader';

import Mockup2DUtils from '@/apps/mockup/classes/Mockup2D/Mockup2DUtils';
import { Mockup3DScene, RenderData, WarpObject } from '@/apps/mockup/schemas/renderSchema';
import { MockupRenderErrorType } from '@/apps/mockup/Types/errorTypes';
import { Size } from '@/Types/types';
import {CanvasUtils} from "@/apps/mockup/classes/CanvasUtils";

/**
 * Represents a 3D renderer for mockup scenes.
 */
class Render3D {
	private static instance: Render3D | null = null;
	public percentageColladaLoaded = 0;

	private canvas = document.createElement('canvas');
	private context2D: CanvasRenderingContext2D = this.canvas.getContext('2d', {
		willReadFrequently: true,
	})!;
	private scenes: Map<string, Mockup3DScene> = new Map();

	private constructor() {
		this.canvas.id = 'renderer on 3D';
	}

	private setContext2D() {
		this.context2D = this.canvas.getContext('2d', {
			willReadFrequently: true,
		}) as CanvasRenderingContext2D;
	}

	/**
	 * Returns the singleton instance of the Render3D class.
	 * If the instance does not exist, it creates a new one.
	 * @returns The singleton instance of the Render3D class.
	 */
	public static getInstance(): Render3D {
		if (!Render3D.instance) {
			Render3D.instance = new Render3D();
		}
		return Render3D.instance;
	}

	/**
	 * Creates a new scene based on the specified type and payload.
	 * @param type The type of the scene ('OM' or 'WARP').
	 * @param payload The payload data for the scene.
	 */
	public async newScene(type: 'OM' | 'WARP', payload: WarpObject | RenderData): Promise<Render3D> {
		if (type === 'WARP') {
			this.createWarpScene(payload as WarpObject);
			return this;
		}
		await this.createOMScene(payload as RenderData);
		return this;
	}

	/**
	 * Updates the scene with the specified ID by applying a new texture to the meshes.
	 * If a name is provided, only the meshes with matching material names will be updated.
	 * Throws an error if the scene or mesh is not found.
	 *
	 * @param id - The ID of the scene to update.
	 * @param canvasTexture - The HTML canvas element containing the new texture.
	 * @param name - Optional. The name of the material to update. Only meshes with matching names will be updated.
	 * @returns void
	 */
	public updateScene(id: string, canvasTexture: HTMLCanvasElement): Render3D; // Warp Case
	public updateScene(id: string, canvasTexture: HTMLCanvasElement, name?: string): Render3D {
		const scene = this.getSceneBydId(id);
		if (!scene) {
			throw Error(MockupRenderErrorType.DONT_FOUND_SCENE);
		}

		const texture = this.createTexture(canvasTexture);
		const material = new THREE.MeshBasicMaterial({
			map: texture,
			color: '#ffffff',
			name: name ?? '2dRender',
			alphaTest: 0.01,
			transparent: true,
		});

		if (scene.type === 'WARP') {
			const mesh = scene.scene.getObjectByName(id) as THREE.Mesh;
			if (!mesh) {
				throw Error(MockupRenderErrorType.DONT_FOUND_MESH);
			}
			material.side = THREE.DoubleSide;
			mesh.material = material;
			return this;
		}

		if (!name) throw Error(MockupRenderErrorType.DONT_FOUND_MESH_WITH_NAME);

		for (const child of scene.scene.children as Array<any>) {
			if (child.isGroup) {
				for (const node of child.children) {
					if (node.isMesh && node.material.name == name) {
						node.visible = true;
						node.material = material;
					} else {
						node.visible = false;
					}
				}
			}
		}
		this.setScene(scene);

		return this;
	}

	/**
	 * Updates the size of the specified scene.
	 * @param {string} id
	 * @param { Size } size
	 * @returns {Render3D}
	 */
	public updateSceneSize(id: string, size: { width: number; height: number }): Render3D {
		const scene = this.getSceneBydId(id);
		if (!scene) {
			throw Error(MockupRenderErrorType.DONT_FOUND_SCENE);
		}
		scene.size = size;
		if (scene.camera.type === 'PerspectiveCamera') {
			scene.camera.aspect = size.width / size.height;
			scene.camera.updateProjectionMatrix();
			this.setScene(scene);
			return this;
		}

		scene.camera.left = -size.width / size.height;
		scene.camera.right = size.width / size.height;
		this.setScene(scene);
		return this;
	}

	/**
	 * Retrieves a Mockup3DScene object by its ID.
	 * @param id - The ID of the scene to retrieve.
	 * @returns The Mockup3DScene object corresponding to the given ID, or undefined if not found.
	 * @throws {Error} Throws an error of type MockupRenderErrorType.DONT_FOUND_SCENE if the scene is not found.
	 */
	private getSceneBydId(id: string): Mockup3DScene | undefined {
		const scene = this.scenes.get(id);
		if (!scene) {
			throw Error(MockupRenderErrorType.DONT_FOUND_SCENE);
		}
		return scene;
	}

	/**
	 * Recreates the WebGL renderer with the specified options.
	 */
	private recreateWebGlRenderer(size: Size): THREE.WebGLRenderer {
		const canvas: HTMLCanvasElement = document.getElementById('render') as HTMLCanvasElement;
		const newRenderer = new THREE.WebGLRenderer({
			antialias: true,
			canvas: canvas,
			alpha: true,
			preserveDrawingBuffer: true,
			logarithmicDepthBuffer: true,
		});
		const pixelRatio = size.width == 5000 || size.height == 5000 ? 1 : window.devicePixelRatio;

		newRenderer.setPixelRatio(pixelRatio);
		newRenderer.setClearColor(new THREE.Color(0xff0000), 0);
		newRenderer.setSize(size.width, size.height);
		return newRenderer;
	}

	public existScene(id: string): boolean {
		return !!this.scenes.get(id);
	}

	/**
	 * Renders the specified scene and returns the rendered image data.
	 * @param id - The ID of the scene to render.
	 * @returns The rendered image data.
	 * @throws {Error} If the specified scene is not found.
	 */
	public renderScene(id: string): HTMLCanvasElement {
		const scene = this.scenes.get(id);
		if (!scene) {
			throw Error(MockupRenderErrorType.DONT_FOUND_SCENE);
		}

		const render = this.recreateWebGlRenderer(scene.size);
		render.render(scene.scene, scene.camera);

		//
		if (this.context2D == null) this.setContext2D();

		this.context2D.canvas.width = render.domElement.width;
		this.context2D.canvas.height = render.domElement.height;

		// Invert the image if the scene is a warp scene
		if (scene.type === 'WARP') {
			this.context2D.translate(render.domElement.width, render.domElement.height);
			this.context2D.rotate(Math.PI);
			this.context2D.translate(render.domElement.width, 0);
			this.context2D.scale(-1, 1);
		}
		this.context2D.drawImage(render.domElement, 0, 0);

		const canvasResult = CanvasUtils.cloneCanvas(this.context2D.canvas);
		this.context2D.canvas.width = 1;
		this.context2D.canvas.height = 1;

		render.domElement.width = 1;
		render.domElement.height = 1;

		return canvasResult
	}

	/**
	 * Sets the scene in the 3D renderer.
	 *
	 * @param scene - The scene to be set.
	 */
	private setScene(scene: Mockup3DScene): void {
		this.scenes.set(scene.id, scene);
	}

	/**
	 * Creates a 3D scene for rendering based on the provided render data.
	 *
	 * @param renderData - The data used to create the 3D scene.
	 * @returns A promise that resolves once the 3D scene is created.
	 */
	private async createOMScene(renderData: RenderData): Promise<void> {
		const scene = new THREE.Scene();
		let camera: THREE.PerspectiveCamera | THREE.OrthographicCamera = new THREE.PerspectiveCamera();

		const ambientLight = new THREE.AmbientLight(0xffffff);
		scene.add(ambientLight);

		const texture = this.createTexture(renderData.texture);

		const daeLoader = new ColladaLoader();

		const renderResult = await new Promise<{
			scene: THREE.Scene;
			camera: THREE.PerspectiveCamera | THREE.OrthographicCamera;
		}>((resolve, reject) => {
			daeLoader.load(
				renderData.sceneFileURL,
				async function (mockups) {
					try {
						const mockup = mockups.scene;
						mockup.traverse(function (node: any) {
							if (node.isMesh) {
								const material = new THREE.MeshBasicMaterial({
									color: '#ffffff',
									name: node.material.name,
									alphaTest: 0.01,
									transparent: true,
								});
								node.material = material;
								//Render by name
								if (node.material.name == renderData.targetMaterialName) {
									node.material.map = texture;
								} else {
									node.visible = false;
								}
							}
						});

						scene.add(mockup);
						camera = scene.getObjectByName(renderData.camera.name) as
							| THREE.PerspectiveCamera
							| THREE.OrthographicCamera;

						if (!camera) {
							throw Error(MockupRenderErrorType.DONT_FOUND_CAMERA);
						}

						const aspect = renderData.camera.frameWidth / renderData.camera.frameHeight;
						if (camera.type == 'PerspectiveCamera') {
							camera.aspect = aspect;
						} else {
							camera = camera as THREE.OrthographicCamera;
							camera.zoom = (scene.getObjectByName('zoom')?.position?.x ?? 0) * 100 || 20;
							camera.left = -aspect;
							camera.right = aspect;
							camera.top = 1;
							camera.bottom = -1;
						}
						camera.updateProjectionMatrix();
						resolve({ scene, camera });
					} catch (error) {
						reject(error);
					}
				},
				(e: ProgressEvent) => {
					// Use to show progress bar Mockup
					this.percentageColladaLoaded = Math.round((e.loaded * 100) / e.total);
				},
				(error) => {
					reject(error);
				}
			);
		});

		this.setScene({
			scene: scene,
			camera: renderResult.camera,
			size: { width: renderData.camera.frameWidth, height: renderData.camera.frameHeight },
			id: renderData.id,
			type: 'OM',
		});
	}
	/**
	 * Creates a warp scene with the given warp object.
	 * @param warpObject - The warp object to create the scene with.
	 */
	private createWarpScene(warpObject: WarpObject): void {
		const scene = new THREE.Scene();
		const bezierMesh = Mockup2DUtils.createCustomWarpObject(warpObject.horizontalPoints, warpObject.verticalPoints);

		bezierMesh.geometry.computeBoundingBox();
		bezierMesh.name = warpObject.id;
		const bbox = bezierMesh.geometry.boundingBox;

		const { camera, width, height } = this.createWarpCamera(bbox as THREE.Box3);

		scene.add(bezierMesh);
		scene.background = null;

		this.setScene({
			scene: scene,
			camera: camera,
			size: { width, height },
			id: warpObject.id,
			type: 'WARP',
		});
	}

	/**
	 * Creates a THREE.Texture object from an HTMLCanvasElement.
	 *
	 * @param canvas - The HTMLCanvasElement used to create the texture.
	 * @returns The created THREE.Texture object.
	 */
	private createTexture(canvas: HTMLCanvasElement): THREE.Texture {
		return new THREE.CanvasTexture(canvas);
	}

	/**
	 * Creates a warp camera based on the given bounding box.
	 * @param bbox The bounding box used to calculate the camera parameters.
	 * @returns An object containing the created camera, width, and height.
	 */
	private createWarpCamera(bbox: THREE.Box3): { camera: THREE.OrthographicCamera; width: number; height: number } {
		const width = bbox.max.x - bbox.min.x;
		const height = bbox.max.y - bbox.min.y;
		const depth = bbox.max.z - bbox.min.z; // En caso de que necesites ajustar también en z

		const center = new THREE.Vector3();
		bbox.getCenter(center);

		const aspectRatio = width / height;
		let cameraHeight = height;
		let cameraWidth = width;

		// Ajusta el tamaño de la cámara basado en el aspect ratio del viewport
		if (cameraWidth / cameraHeight > aspectRatio) {
			// Si la malla es más ancha que el aspect ratio del viewport, ajusta la altura de la cámara
			cameraHeight = cameraWidth / aspectRatio;
		} else {
			// Si la malla es más alta que el aspect ratio del viewport, ajusta el ancho de la cámara
			cameraWidth = cameraHeight * aspectRatio;
		}

		const camera = new THREE.OrthographicCamera(
			cameraWidth / -2,
			cameraWidth / 2,
			cameraHeight / 2,
			cameraHeight / -2,
			1, // Near clipping plane
			1000 + depth // Far clipping plane, ajusta según la profundidad de la malla
		);

		camera.position.set(center.x, center.y, depth + 100); // Ajusta 100 según sea necesario para abarcar toda la malla
		camera.lookAt(center);

		return {
			camera,
			width,
			height,
		};
	}
}
export const MockupRender3D = Render3D.getInstance();
