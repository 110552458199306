import { useToast as usingToast } from 'vue-toastification';

import { useEnvSettings } from '@/common/composables/useEnvSettings';

const { MODE } = useEnvSettings();

export const useToast = () => {
	if (MODE === 'test') {
		return {
			error: () => true,
			success: () => true,
			warning: () => true,
			info: () => true,
			update: () => true,
			dismiss: () => true,
		};
	}

	return usingToast();
};
