import { Ref } from 'vue';

import { useTransform } from '@/elements/element/composables/useTransform';
import Line from '@/elements/line/classes/Line';
import { Size } from '@/Types/types';

export const useLineTransform = (line: Ref<Line>) => {
	const usingTransform = useTransform(line);

	const adjustTo = (side: keyof Size, val: number) => {
		if (side === 'width') {
			line.value.size.width = val;
		}

		if (side === 'height') {
			line.value.size.height = val;
		}
	};

	return {
		...usingTransform,
		adjustTo,
	};
};
