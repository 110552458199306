import { getIndexAbove, getIndexBetween } from '@tldraw/indices';
import { Ref, ref } from 'vue';

import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import { useProjectTextColors } from '@/color/composables/useProjectTextColors';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import { useImagePresets } from '@/elements/medias/images/foreground/composables/useImagePresets';
import Image from '@/elements/medias/images/image/classes/Image';
import { Text } from '@/elements/texts/text/classes/Text';
import TextTools from '@/elements/texts/text/utils/TextTools';
import Page from '@/page/classes/Page';

class LegacyMigratorTools {
	static async legacyRemoveBg(page: Page) {
		let changes = false;
		const temporalRef = ref(Image.create());
		const { removeBg } = useImagePresets(temporalRef as Ref<Image>);
		const imagesRemoveBg = page
			.elementsAsArray()
			.filter(
				(element) =>
					element instanceof Image &&
					element.backgroundMode !== 'original' &&
					!page.elementsAsArray().some((el) => el instanceof ForegroundImage && el.image === element.id)
			) as Image[];

		for (const el of imagesRemoveBg) {
			const foregroundImage = await ForegroundImage.fromImage(el);
			if (el.backgroundMode === 'foreground') {
				temporalRef.value = el;
				removeBg();
			}
			if (el.backgroundMode === 'background') {
				foregroundImage.opacity = 0;
			}

			el.backgroundMode = 'background';

			const pageElements = page.elementsAsArray();
			const index = pageElements.findIndex((e) => e.id === el.id);
			if (index >= 0) {
				foregroundImage.index = getIndexBetween(pageElements[index].index, pageElements[index + 1]?.index);
				page.elements.set(foregroundImage.id, foregroundImage);
			}
			changes = true;
		}
		return changes;
	}

	static legacyCurvedTextWithGradients(page: Page) {
		let changes = false;
		const temporalRef = ref(Text.create());
		const { colors, updateColor } = useProjectTextColors(temporalRef as Ref<Text>);
		const textsCurved = page.elementsAsArray().filter((element) => {
			return element instanceof Text && element.curvedProperties.arc;
		});

		textsCurved.forEach((text) => {
			temporalRef.value = text as Text;

			const gradients = colors.value.filter((c) => c instanceof GradientColor) as GradientColor[];

			gradients.forEach((gradient) => updateColor(gradient, SolidColor.fromObject(gradient.stops[0])));
			if (gradients.length) changes = true;
		});
		return changes;
	}

	static legacyTextColors(page: Page) {
		let changes = false;
		const texts = page.elementsAsArray().filter((element) => {
			return element instanceof Text;
		}) as Text[];

		texts.forEach((text) => {
			const initialColors = text.colors.length;

			const div = document.createElement('div');
			div.innerHTML = text.content;

			// Buscamos los colores de los hijos y eliminamos los colores que hemos encontrado
			const childColors = Array.from(div.querySelectorAll<HTMLElement>('*'))
				.map((el) => {
					const foundColor = Object.values(el.style).find((style) => style.startsWith('--color-'));

					if (foundColor) {
						const colorId = foundColor.replace('--', '');
						const colorString = el.style.getPropertyValue(foundColor);

						const color = colorString.includes('gradient')
							? GradientColor.fromString(colorString)
							: SolidColor.fromString(colorString);

						color.id = colorId;

						el.style.removeProperty(foundColor);

						// Recalculamos el clip del fondo del texto
						TextTools.fixNotFoundBackgroundClip(el, color);

						return color;
					}
				})
				.filter(Boolean);

			// Buscamos los colores de los hijos que hemos encontrado y si no se encuentran en text.colors, los añadimos
			if (childColors.length) {
				childColors.forEach((childColor) => {
					const hasColor = text.colors.some((color) => color.toCssString() === childColor.toCssString());

					if (!hasColor) text.colors.push(childColor);
				});

				text.content = div.innerHTML;
			}

			if (text.colors.length !== initialColors) changes = true;
		});

		return changes;
	}

	static fixLockedElements(page: Page) {
		let changes = false;
		// Si el elemento que tiene como bg no se encuentra en los elementos, le quitamos el bg
		const hasBgLost = page.backgroundImageId && !page.elementsAsArray().some((el) => el.id === page.backgroundImageId);
		if (hasBgLost) {
			page.backgroundImageId = '';
			changes = true;
		}

		// En el caso de que se haya perdido el bg, el elemento que estaba como bg se habrá quedado locked, ponemos todos los elementos unlocked
		// En el caso de Slidesgo no hacemos nada ya que si permite elementos locked fuera del bg
		if (window.EDITOR_MODE === 'SLIDESGO') return;

		return changes;
	}

	static legacyElementsAsArray(page: Page) {
		let changes = false;
		const elements = page.elementsAsArray();
		let prevIndex: string;
		elements.forEach((element, i) => {
			if (!element.index) {
				element.index = getIndexAbove(prevIndex);
				changes = true;
			}
			// Hay plantillas que por un bug tienen elementos con el mismo index o un mix de elementos con index y sin index
			// Por lo que el index que le hemos puesto puede estar repetido, en ese caso, le ponemos el index siguiente
			if (elements.filter((el) => el.id !== element.id).some((el) => el.index === element.index)) {
				const lastWithIndex = [...elements].reverse().find((el) => el.index);
				element.index = getIndexAbove(lastWithIndex?.index as string); // El tipado de la librería espera un string, pero en realidadd es un string | undefined, ya que si pasamos undefined, nos devuelve el primer index, si pasamos '', falla
				changes = true;
			}
			prevIndex = element.index;

			return element;
		});

		return changes;
	}

	static legacyTextsWithoutPadding(page: Page) {
		let changes = false;

		const searchParams = new URLSearchParams(window.location.search);
		page.elements.forEach((el) => {
			if (el instanceof Text && searchParams.get('without-move-y') === '1') {
				el.metadata.fixPosYOnAdded = true;
				el.metadata.fixPaddingScale = true;

				changes = true;
				return;
			}
			if (el instanceof Text && searchParams.get('fix-text-padding') === '1') {
				el.position.y += el.getTextPadding() * 2;
				el.metadata.fixPosYOnAdded = true;
				changes = true;
			}
			if (el instanceof Text && !el.metadata.fixPosYOnAdded) {
				const gradientPadding = el.getTextPadding();
				const paddingDifference = gradientPadding - Math.round(el.getTextPadding());

				// Recalculamos la posición del texto para que vuelva a su posición original (sin padding)
				el.position.y -= gradientPadding - paddingDifference;
				el.size.height += (gradientPadding - paddingDifference) * 2;

				el.metadata.fixPosYOnAdded = true;
				el.metadata.fixPaddingScale = true;

				changes = true;
			}
			if (el instanceof Text && el.metadata.fixPosYOnAdded && !el.metadata.fixPaddingScale) {
				const paddingWithScale = el.getTextPadding() * el.scale;
				const padding = el.getTextPadding();

				const paddingDifference = padding - Math.round(padding);
				const paddingDifferenceWithScale = paddingWithScale - Math.round(paddingWithScale);

				// Teniendo en cuenta que el padding anterior se calculaba teniendo en cuenta la escala del texto
				// Recalculamos la posición del texto para que vuelva a su posición original (sin padding)
				el.position.y -= paddingDifference - paddingDifferenceWithScale;
				el.metadata.fixPaddingScale = true;
				changes = true;
			}
		});

		return changes;
	}

	/**
	 * Comprobamos si los hijos de los textos tienen el mismo color que el texto, en caso afirmativo, eliminamos los colores de los hijos
	 *
	 * @param page
	 * @returns
	 */
	static legacyTextsChildrenColors(page: Page) {
		let changes = false;

		page.elements.forEach((el) => {
			if (el instanceof Text && el.colors.length > 1) {
				TextTools.purifyTextColors(el);

				changes = true;
			}
		});

		return changes;
	}
}

export default LegacyMigratorTools;
