import { Duotone } from '@/elements/medias/filter/classes/FilterPresets';
import FilterConversor from '@/elements/medias/filter/utils/FilterConversor';

class FiltersTools {
	static convert(value: string) {
		let fmatch, amount, unit, properties;
		// None
		fmatch = value.match(/none/i);
		if (fmatch !== null) {
			properties = FilterConversor.none();
		}
		// Grayscale
		fmatch = value.match(/(grayscale)\(\s*([0-9\.]+)(%)*\s*\)/i);
		if (fmatch !== null) {
			amount = parseFloat(fmatch[2]);
			unit = fmatch[3];
			properties = FilterConversor.grayscale(amount, unit);
		}
		// Sepia
		fmatch = value.match(/(sepia)\(\s*([0-9\.]+)(%)*\s*\)/i);
		if (fmatch !== null) {
			amount = parseFloat(fmatch[2]);
			unit = fmatch[3];
			properties = FilterConversor.sepia(amount, unit);
		}
		// Saturate
		fmatch = value.match(/(saturate)\(\s*([0-9\.]+)(%)*\s*\)/i);
		if (fmatch !== null) {
			amount = parseFloat(fmatch[2]);
			unit = fmatch[3];
			properties = FilterConversor.saturate(amount, unit);
		}
		// Hue-rotate
		fmatch = value.match(/(hue\-rotate)\((\s*[0-9\.]+)(deg|grad|rad|turn)\s*\)/i);
		if (fmatch !== null) {
			const angle = parseFloat(fmatch[2]);
			unit = fmatch[3];
			properties = FilterConversor.hueRotate(angle, unit);
		}
		// Duotone
		fmatch = value.match(/(duotone)\(/i);
		if (fmatch !== null) {
			const startIndex = value.indexOf('{');
			const endIndex = value.lastIndexOf('}') + 1;
			const jsonStr = value.slice(startIndex, endIndex);
			const obj = JSON.parse(jsonStr) as Duotone;
			const shadowsDecimal = [obj.shadows.r / 255, obj.shadows.g / 255, obj.shadows.b / 255];
			const highlightsDecimal = [obj.highlights.r / 255, obj.highlights.g / 255, obj.highlights.b / 255];
			properties = FilterConversor.duotone(shadowsDecimal, highlightsDecimal);
		}
		// Invert
		fmatch = value.match(/(invert)\((\s*[0-9\.]+)(%)*\s*\)/i);
		if (fmatch !== null) {
			amount = parseFloat(fmatch[2]);
			unit = fmatch[3];
			properties = FilterConversor.invert(amount, unit);
		}
		// Opacity
		fmatch = value.match(/(opacity)\((\s*[0-9\.]+)(%)*\s*\)/i);
		if (fmatch !== null) {
			amount = parseFloat(fmatch[2]);
			unit = fmatch[3];
			properties = FilterConversor.opacity(amount, unit);
		}
		// Brightness
		fmatch = value.match(/(brightness)\((\s*[0-9\.]+)(%)*\s*\)/i);
		if (fmatch !== null) {
			amount = parseFloat(fmatch[2]);
			unit = fmatch[3];
			properties = FilterConversor.brightness(amount, unit);
		}
		// Contrast
		fmatch = value.match(/(contrast)\((\s*[0-9\.]+)(%)*\s*\)/i);
		if (fmatch !== null) {
			amount = parseFloat(fmatch[2]);
			unit = fmatch[3];
			properties = FilterConversor.contrast(amount, unit);
		}
		// Blur
		fmatch = value.match(/(blur)\((\s*[0-9\.]+)(px|em|rem|)\s*\)/i);
		if (fmatch !== null) {
			amount = parseFloat(fmatch[2]);
			unit = fmatch[3];
			properties = FilterConversor.blur(amount, unit);
		}
		// Drop Shadow
		fmatch = value.match(
			/(drop\-shadow)\((\s*[0-9\.]+)(px|em|rem| )\s*([0-9\.]+)(px|em|rem| )\s*([0-9\.]+)(px|em|rem| )(\s*([0-9\.]+)(px|em|rem| ))?\s*([a-z0-9\#\%\,\.\s\(\)]*)(?=\s*\))/i
		);
		if (fmatch !== null) {
			const offsetX = parseFloat(fmatch[2]),
				unitX = fmatch[3],
				offsetY = parseFloat(fmatch[4]),
				unitY = fmatch[5],
				radius = parseFloat(fmatch[6]),
				unitRadius = fmatch[7],
				spread = parseFloat(fmatch[9]),
				unitSpread = fmatch[10],
				color = fmatch[11].trim();
			properties = FilterConversor.dropShadow(
				offsetX,
				unitX,
				offsetY,
				unitY,
				radius,
				unitRadius,
				spread,
				unitSpread,
				color
			);
		}

		return properties;
	}
}

export default FiltersTools;
