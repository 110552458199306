import { createSharedComposable } from '@vueuse/core';
import { computed, ref } from 'vue';

import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import { useMainStore } from '@/editor/stores/store';
import { Box } from '@/elements/box/classes/Box';
import { Text } from '@/elements/texts/text/classes/Text';
import { useFonts } from '@/elements/texts/text/composables/useFonts';
import { useTextEditing } from '@/elements/texts/text/composables/useTextEditing';
import { useSelection } from '@/interactions/composables/useSelection';
import { EditPanels } from '@/Types/types';

export const useTextInsideBox = createSharedComposable(() => {
	const store = useMainStore();

	const element = ref<Box | null>(null);

	const isTextInsideBoxFocused = computed(
		() =>
			textEditing.value && selection.value[0] instanceof Box && selection.value[0].subElements.get(textEditing.value.id)
	);

	const { isMobile } = useDeviceInfo();
	const { inUseFonts, loadFont } = useFonts();
	const { togglePanel } = usePanelManagement();
	const { selection } = useSelection();
	const { textEditing } = useTextEditing();

	const setupTextInsideBox = async (boxId: string) => {
		element.value = (selection.value.find((el) => el.id === boxId && el instanceof Box) as Box) || null;
		if (!element.value) {
			console.warn('No se pudo encontrar el elemento box');
			return;
		}

		if (textEditing.value) {
			console.warn(
				'No se puede activar el modo edición de texto dentro de un elemento box porque ya hay un texto en modo edición'
			);
			return;
		}

		// Si el elemento tiene texto y no está en modo edición, se activa el modo edición de texto
		if (element.value.firstSubElement instanceof Text) {
			if (!isMobile.value) textEditing.value = element.value.firstSubElement;
			toggleTextPanel();
			return;
		}

		// Load font if needed
		let fontFamily = inUseFonts.value[0];
		if (!fontFamily) {
			const brandkitFont = store.user?.brandkits.find((brandkit) => brandkit.fonts.length)?.fonts[0];
			fontFamily = brandkitFont
				? { ...brandkitFont, family: brandkitFont?.slug }
				: {
						family: 'Montserrat',
						weights: ['400', '600', '800'],
						slug: 'Montserrat',
						preview: '',
				  };
		}
		await loadFont(fontFamily);

		// Si el elemento no tiene texto, se crea un texto dentro del elemento y se activa el modo edición
		element.value.setText();
		// Revisar con Jose
		if (!(element.value.firstSubElement instanceof Text)) {
			console.warn('No se pudo crear el texto dentro del elemento Box');
			return;
		}
		element.value.firstSubElement.size.width = element.value.size.width;
		element.value.firstSubElement.fontSize = element.value.size.width * 0.1;

		if (!isMobile.value) textEditing.value = element.value.firstSubElement;

		toggleTextPanel();
	};

	const toggleTextPanel = () => {
		if (
			element.value instanceof Box &&
			element.value.firstSubElement instanceof Text &&
			(store.editPanel || isMobile.value) &&
			store.editPanel !== EditPanels.Text
		) {
			togglePanel(EditPanels.Text);
		}
	};

	return { isTextInsideBoxFocused, setupTextInsideBox };
});
