/**
 * The `PurifyUnserialize` function is a TypeScript decorator that removes any properties with
 * `undefined` values from the result of a method.
 * @returns The `PurifyUnserialize` function returns a decorator function.
 */
export const PurifyUnserialize = () => {
	return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: any[]) {
			const result = originalMethod.apply(this, args);

			for (const key in result) {
				// Fix para metadata vacio convertido en array en el sync
				if (['metadata', 'subElements'].includes(key) && Array.isArray(result[key]) && !result[key].length) {
					result[key] = {};
				}

				// eslint-disable-next-line no-prototype-builtins
				if (result.hasOwnProperty(key) && result[key] === undefined) {
					delete result[key];
				}
			}

			return result;
		};

		return descriptor;
	};
};
