interface KeyPair<Key> {
	key: Key;
	value: any;
}

export class Settings<KeyType> {
	private _settings: Array<KeyPair<KeyType>>;

	constructor(settings: Array<KeyPair<KeyType>>) {
		this._settings = settings;
	}

	public add(keyPair: KeyPair<KeyType>) {
		this._settings.push(keyPair);
	}

	public get(key: KeyType, _default = null): any {
		return this._settings.find((keyPair) => keyPair.key == key)?.value ?? _default;
	}

	public set(key: KeyType, value: any): any {
		const setting = this._settings.find((keyPair) => keyPair.key == key);
		if (setting) {
			setting.value = value;
		}
		return setting;
	}
}
