import { createSharedComposable } from '@vueuse/core';
import { useRouter } from 'vue-router';

export type MockupRoutes = 'search' | 'mockups';
export const useRouterMockup = createSharedComposable(() => {
	const router = useRouter();

	const getRouteHref = (route: MockupRoutes, queryParams?: URLSearchParams) => {
		if (!queryParams) return router.resolve({ name: route }).href;

		const queryRecord: Record<string, string> = {};
		queryParams.forEach((value, key) => {
			queryRecord[key] = value;
		});

		return router.resolve({ name: route, query: queryRecord }).href;
	};

	const openRoute = (route: string, target = '_blank') => {
		window.open(route, target);
	};

	const redirectRoute = (route: string) => {
		window.history.pushState({}, '', route);
		window.location.reload();
	};

	const nativeRedirect = (route: string) => {
		window.location.href = route;
	};

	return {
		getRouteHref,
		redirectRoute,
		openRoute,
		nativeRedirect,
	};
});
