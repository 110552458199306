import { linkList } from './linkList';
export const useManageLinks = () => {
	const landingLinks = linkList.landing;
	const freepikUserLinks = linkList.freepikUser;
	const freepikLibrary = linkList.library;
	const freepikTools = linkList.tools;
	const [isFreepikContext, isSlidesgoContext] = [
		window.location.host.includes('freepik'),
		window.location.host.includes('slidesgo'),
	];
	const links = () => {
		const commonLinks = { ...freepikLibrary, ...freepikTools };
		const specificLinks = { ...freepikUserLinks, brandkitLink: linkList.brandkitLink, copy: linkList.copy };

		if (isSlidesgoContext) {
			return {
				...commonLinks,
				...specificLinks,
				home: linkList.home.slidesgo,
				support: linkList.support.slidesgo,
				projects: linkList.projects.slidesgo,
				profile: linkList.profile.slidesgo,
				pricing: linkList.pricing.slidesgo,
			};
		}

		if (isFreepikContext) {
			return {
				...commonLinks,
				...specificLinks,
				home: linkList.home.freepik,
				support: linkList.support.freepik,
				projects: linkList.projects.freepik,
				profile: linkList.profile.freepik,
				pricing: linkList.pricing.freepik,
			};
		}

		return {
			...commonLinks,
			...specificLinks,
			home: linkList.home.wepik,
			support: linkList.support.wepik,
			projects: linkList.projects.wepik,
			profile: linkList.profile.wepik,
			pricing: linkList.pricing.wepik,
		};
	};

	return {
		landingLinks,
		links: links(),
	};
};
