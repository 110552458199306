import { watchOnce } from '@vueuse/core';
import { computed, Ref, ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import { Text } from '@/elements/texts/text/classes/Text';
import { TEXT_EFFECTS_PRESETS } from '@/elements/texts/text/utils/TextEffectsPresets';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { TextEffects } from '@/Types/types';

export const useTextEffects = (element: Ref<Text>) => {
	const currentEffect = ref<TextEffects>(TextEffects.None);

	const color = computed(() =>
		element.value.color instanceof GradientColor
			? GradientColor.stopToSolidColor(element.value.color.stops[0])
			: element.value.color
	);

	// Colored effects are main text color depending effects
	const hasColoredEffect = computed(() => hasEcho.value || hasNeon.value);

	const hasCurved = computed(() => currentEffect.value === TextEffects.Curve);

	const hasEcho = computed(
		() =>
			TextTools.textShadowToCssString(TEXT_EFFECTS_PRESETS.ECHO(color.value)) ===
			TextTools.textShadowToCssString(element.value.textShadow)
	);

	const hasEffect = computed(
		() =>
			hasCurved.value ||
			hasEcho.value ||
			hasNeon.value ||
			hasOutline.value ||
			hasGlitch.value ||
			(hasShadow.value && !hasMultiShadow.value) ||
			hasSticker.value
	);

	const hasGlitch = computed(
		() =>
			TextTools.textShadowToCssString(TEXT_EFFECTS_PRESETS.GLITCH()) ===
			TextTools.textShadowToCssString(element.value.textShadow)
	);

	const hasMultiShadow = computed(() => element.value.textShadow.length > 1);

	const hasNeon = computed(
		() =>
			TextTools.textShadowToCssString(TEXT_EFFECTS_PRESETS.NEON(color.value)) ===
			TextTools.textShadowToCssString(element.value.textShadow)
	);

	const hasOutline = computed(() => !!element.value.outline.width);

	const hasPreset = computed(() => hasEcho.value || hasNeon.value || hasGlitch.value || hasSticker.value);

	const hasShadow = computed(() =>
		element.value.textShadow.some((ts) => ts.angle || ts.blur || ts.distance || ts.opacity)
	);

	const hasSticker = computed(() => {
		const { OUTLINE, SHADOWS } = TEXT_EFFECTS_PRESETS.STICKER();

		const isShadowMatch =
			TextTools.textShadowToCssString(SHADOWS) === TextTools.textShadowToCssString(element.value.textShadow);

		const isOutlineMatch =
			OUTLINE.color.toCssString() === element.value.outline.color.toCssString() &&
			OUTLINE.width === element.value.outline.width &&
			OUTLINE.unit === element.value.outline.unit;

		return isShadowMatch && isOutlineMatch;
	});

	const isOutlineEm = computed(() => element.value.outline.unit === 'em');

	const textEffectHandler = async (effect: TextEffects) => {
		// si el efecto que se quiere aplicar es la curvatura de un texto, guardamos el estado del texto
		const resetEffects = [TextEffects.Echo, TextEffects.Glitch, TextEffects.Neon, TextEffects.Sticker].includes(effect);

		// Toggle effect
		currentEffect.value = effect === currentEffect.value ? TextEffects.None : effect;

		if (resetEffects) {
			element.value.outline = Text.defaults().outline;
			element.value.textShadow = Text.defaults().textShadow;
		}

		if (effect === TextEffects.Curve) rememberOrRestoreSizing(resetEffects);

		if ([TextEffects.Echo, TextEffects.Glitch, TextEffects.Neon, TextEffects.Sticker].includes(currentEffect.value)) {
			applyTextEffect(currentEffect.value);
		}

		GAnalytics.track('select', 'button', `style-text-${currentEffect.value}`, null);
	};

	const applyTextEffect = (effect: TextEffects) => {
		if (effect === TextEffects.Echo) applyEchoEffect();
		if (effect === TextEffects.Glitch) applyGlitchEffect();
		if (effect === TextEffects.Neon) applyNeonEffect();
		if (effect === TextEffects.Sticker) applyStickerEffect();
	};

	const applyEchoEffect = () => {
		element.value.textShadow = TEXT_EFFECTS_PRESETS.ECHO(color.value);
	};

	const applyGlitchEffect = () => {
		element.value.textShadow = TEXT_EFFECTS_PRESETS.GLITCH();
	};

	const applyNeonEffect = () => {
		element.value.textShadow = TEXT_EFFECTS_PRESETS.NEON(color.value);
	};

	const applyStickerEffect = () => {
		element.value.outline = TEXT_EFFECTS_PRESETS.STICKER().OUTLINE;
		element.value.textShadow = TEXT_EFFECTS_PRESETS.STICKER().SHADOWS;
	};

	const resetEffect = (effect: TextEffects) => {
		// si el efecto que se quiere quitar es la curvatura de un texto,
		// recuperamos el estado anterior del texto si este no ha sido editado en este estado
		if (effect === TextEffects.Curve) rememberOrRestoreSizing(true);

		currentEffect.value = TextEffects.None;

		if (effect === TextEffects.Curve) {
			element.value.curvedProperties = {
				minArc: 0,
				arc: null,
				transformCurve: 0,
			};
		}

		if (effect === TextEffects.Outline) {
			element.value.outline.width = 0;
			element.value.outline.color = SolidColor.gray();
		}

		if ([TextEffects.Echo, TextEffects.Glitch, TextEffects.Neon, TextEffects.Shadow].includes(effect)) {
			element.value.textShadow = Text.defaults().textShadow;
		}

		if (effect === TextEffects.Sticker) {
			element.value.outline = Text.defaults().outline;
			element.value.textShadow = Text.defaults().textShadow;
		}
	};

	const refreshEffect = (effect: TextEffects) => {
		if (effect === TextEffects.Echo) applyEchoEffect();
		if (effect === TextEffects.Neon) applyNeonEffect();
	};

	/**
	 * Recuperamos el tamaño y la posición del texto curvo al que no se le han modificado estas propiedades.
	 * En caso de haber sido editado, no recuperamos los valores anteriores
	 */
	const rememberOrRestoreSizing = (resetEffect: boolean): void => {
		const clonedText = element.value.clone();

		// guardamos el estado
		if (!element.value.curvedProperties.arc) {
			element.value.metadata.originalText = { ...clonedText, edited: false };

			watchOnce(
				[() => element.value.size, () => element.value.position],
				() => (element.value.metadata.originalText.edited = true)
			);

			return;
		}

		if (!clonedText.metadata.originalText || element.value.metadata.originalText.edited || !resetEffect) return;

		// recuperamos el estado anterior
		element.value.size = clonedText.metadata.originalText.size;
		element.value.position = clonedText.metadata.originalText.position;
	};

	return {
		currentEffect,
		hasColoredEffect,
		hasCurved,
		hasEcho,
		hasEffect,
		hasGlitch,
		hasMultiShadow,
		hasNeon,
		hasOutline,
		hasPreset,
		hasShadow,
		hasSticker,
		isOutlineEm,
		textEffectHandler,
		resetEffect,
		refreshEffect,
	};
};
