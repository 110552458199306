import { createSharedComposable } from '@vueuse/core';
import { useWheel } from '@vueuse/gesture';

import { useChangePreview } from '@/apps/mockup/composable/useChangePreview';
import { useZoom } from '@/editor/composables/useZoom';

export const useEditorMouseEvents = createSharedComposable(() => {
	const { decreaseZoom, increaseZoom } = useZoom();
	const { invertView } = useChangePreview();

	useWheel(
		async (e) => {
			const { event, movement } = e;
			const wheelDeltaY = movement[1];

			let delta = 10;

			delta = Math.round((Math.abs(wheelDeltaY) * 20) / 300);
			if (delta > 20) delta = 20;
			if (delta < 1) delta = 1;

			if (e.ctrlKey) {
				event.preventDefault();
				// The change is made because in preview mode of the mockup it was possible to scroll in the background
				if (invertView.value) return;
				wheelDeltaY > 0 && (await decreaseZoom(event, delta));
				wheelDeltaY < 0 && (await increaseZoom(event, delta));
			}
		},
		{
			domTarget: window,
			eventOptions: { passive: false },
		}
	);
});
