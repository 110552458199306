import { computed, Ref, watch } from 'vue';

export const useChangeUrlEndpoint = (url: Ref<string>, keyword: string, concatenatedData: Ref<any[]>) => {
	const endpoint = computed(() =>
		url.value.includes(keyword) ? url.value.split(`${keyword}/`)[1].split('?')[0] : null
	);

	watch(endpoint, (newVal, oldVal) => {
		// Reset data on modify image provider
		if (newVal && newVal !== oldVal) {
			concatenatedData.value = [];
		}
	});
};
