import Bugsnag from '@bugsnag/js';
import { createSharedComposable, until, useIntervalFn, watchOnce } from '@vueuse/core';
import { isEqual } from 'lodash';
import { keyBy, sortBy } from 'lodash-es';
import { computed, ref, watch } from 'vue';

import { getFonts, getTtfUrls, getUserFonts } from '@/api/DataApiClient';
import { getExternalFonts } from '@/api/DataApiClient';
import { useAuth } from '@/auth/composables/useAuth';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { Text } from '@/elements/texts/text/classes/Text';
import Page from '@/page/classes/Page';
import { useProjectStore } from '@/project/stores/project';
import { Dictionary, Font, FontFaceInfo, FontFileUrl } from '@/Types/types';

export const useFonts = createSharedComposable(() => {
	const mainStore = useMainStore();
	const store = useProjectStore();
	const { isDisneyMode, isSlidesgoMode } = useEditorMode();
	const { user } = useAuth();
	const notAvailableFonts = ref(new Set<string>());
	const failedFonts = ref(new Map<string, FontFaceInfo>());

	const initialDisneyFonts = ref<FontFaceInfo[]>([]);

	const userFonts = computed<Dictionary<Font>>(() => {
		if (!user.value) return {};

		return keyBy(user.value.fonts, 'slug');
	});

	const fonts = ref<Dictionary<Font>>({});

	const allFonts = computed(() => {
		const all = Object.values(fonts.value);
		const user = Object.values(userFonts.value);

		return keyBy([...all, ...user], 'slug');
	});

	const sortedFonts = computed(() => {
		// Descartamos duplicados con el de los usuarios
		const all = Object.values(allFonts.value).filter(
			(value, index, self) =>
				index === self.findIndex((t) => t.name === value.name && t.weights.every((val, i) => val === value.weights[i]))
		);

		return sortBy(all, 'name');
	});

	const recommendedFonts = computed(() => {
		const all = Object.values(fonts.value).filter((f) => !!f.recommended);
		return sortBy(all, 'name');
	});

	// Trae todas las fuentes de Wepik con los datos necesarios para cargarlas (no las carga)
	const preloadWepikFonts = async () => {
		if (window.preloadFonts && window.preloadFonts.length > 0) {
			fonts.value = keyBy(window.preloadFonts, 'slug');
			return;
		}

		const { data } = await getFonts();

		fonts.value = keyBy(data.value, 'slug');
	};

	preloadWepikFonts();

	const inUseFonts = computed(() => {
		// Si estamos en modo Disney y hay fuentes iniciales las devolvemos
		// Salvo que estemos en Slidesgo, donde se tendrán que ir pidiendo las fuentes conforme se vayan agregando páginas
		if (isDisneyMode.value && initialDisneyFonts.value.length > 0 && !isSlidesgoMode.value) {
			return initialDisneyFonts.value;
		}

		const families = store.allTexts.flatMap((text: Text) => {
			const htmlInstance = text.htmlInstance();
			// Buscamos los hijos que tengan un font-weight aplicado sin tener un font-family para incluir ese peso de fuente en las usadas también
			const childWeights = Array.from(
				htmlInstance.querySelectorAll<HTMLElement>('[style*="font-weight"]:not([style*="font-family"])')
			).map((el) => {
				const fontFamily = el.closest<HTMLElement>('[style*="font-family"]')?.style.fontFamily || text.fontFamily;

				return { family: fontFamily.replace(/['"]+/g, ''), weight: el.style.fontWeight };
			});
			// Buscamos los hijos que tengan un font-family distinto al original del texto para incluirlo en las fuentes usadas
			const childFonts = Array.from(htmlInstance.querySelectorAll<HTMLElement>('[style*="font-family"]')).map((el) => {
				return { family: el.style.fontFamily.replace(/['"]+/g, ''), weight: el.style.fontWeight };
			});
			// Texto + hijos con font-family + hijos con font-weight
			return [{ family: text.fontFamily, weight: `${text.fontWeight}` }, ...childFonts, ...childWeights];
		});

		// Eliminamos duplicados
		const familiesWithoutDuplicates = families.filter(
			(font, index, fonts) => index === fonts.findIndex((f) => f.family === font.family && f.weight === font.weight)
		);

		// Agrupamos por family y creamos un array con los pesos de cada una
		const organizedByFamily = familiesWithoutDuplicates.reduce((result: FontFaceInfo[], font) => {
			const existingFont = result.find((item) => item.family === font.family);

			const weight = font.weight;
			if (existingFont) {
				existingFont.weights.push(weight.toString());
			} else {
				result.push(<FontFaceInfo>{
					family: font.family,
					slug: font.family,
					weights: [weight],
					preview: allFonts.value[font.family]?.preview || '',
				});
			}

			return result;
		}, []);

		return organizedByFamily;
	});

	const loadedFonts = ref<Map<string, FontFaceInfo>>(new Map());
	// Map donde se almacenan todas las urls de los archivos ttf de las fuentes
	const fontFileUrls = ref<Map<string, FontFileUrl[]>>(new Map());

	const fontIsLoaded = (fontFamily: string, weight: string | number) =>
		!!loadedFonts.value.get(fontFamily)?.weights.includes(`${weight}`) ||
		!!failedFonts.value.get(fontFamily)?.weights.includes(`${weight}`);

	const untilFontIsLoaded = async (fontFamily: string, weight: string | number) => {
		if (fontIsLoaded(fontFamily, weight)) return;

		return new Promise((resolve) => {
			const { pause } = useIntervalFn(() => {
				if (fontIsLoaded(fontFamily, weight)) {
					pause();
					resolve(true);
				}
			}, 100);
		});
	};

	const addToFailedFont = (fontFace: FontFaceInfo, weights: string[]) => {
		if (!failedFonts.value.has(fontFace.family)) {
			failedFonts.value.set(fontFace.family, {
				family: fontFace.family,
				slug: fontFace.slug,
				weights,
			});
			return;
		}
		const currentFontFace = failedFonts.value.get(fontFace.family);
		if (!currentFontFace) return;
		currentFontFace.weights = [...currentFontFace.weights, ...weights];
	};
	const loadFont = async (fontFace: FontFaceInfo) => {
		await until(fonts).toMatch(() => Object.keys(fonts.value).length > 0);

		const weightsToLoad = fontFace.weights.filter((weight: string) => {
			const isLoaded = fontIsLoaded(fontFace.family, weight);

			return !isLoaded;
		});

		if (!weightsToLoad.length) {
			return;
		}

		// Traemos la fuente de las cargadas previamente
		const dataFont = fontFileUrls.value.get(fontFace.family);
		// Si no está la pedimos al servidor
		if (dataFont === undefined) {
			// Establecemos el array vacío para si se pide en el trascurso de la petición no se hagan mas peticiones de la cuenta
			fontFileUrls.value.set(fontFace.family, []);
			const { data, error } = await getTtfUrls(fontFace.slug);

			if (error.value || !data.value) {
				console.log(`Error on load fonts urls: ${error.value}`);

				addToFailedFont(fontFace, weightsToLoad);
				return;
			}
			// Guardamos las urls de los archivos ttf de la fuente
			fontFileUrls.value.set(fontFace.family, data.value);
		}

		// Nos aseguramos de que se haya cargado la fuente antes de continuar
		if (dataFont?.length === 0) {
			await until(() => fontFileUrls.value.get(fontFace.family)?.length).toBe(true, { timeout: 2000 });
		}

		// Creamos las promesas para cargar todas los pesos de la fuente
		const promises = weightsToLoad.map((weight) => {
			return new Promise((resolve, reject) => {
				const weigthToSearch = weight.includes('i') ? weight.split('i')[0] : weight;
				const style = weight.includes('i') ? 'italic' : 'normal';
				const url = fontFileUrls.value
					.get(fontFace.family)
					?.find((f) => f.weight === weigthToSearch && f.style === style)?.src;
				// Si no tenemos url no podemos cargarla
				if (!url) {
					console.warn(`Font file url not found for ${fontFace.family} ${weight}`);
					addToFailedFont(fontFace, [weight]);
					reject(`Font file url not found for ${fontFace.family} ${weight}`);
					return;
				}
				// El nombre que le pasemos al instanciar el fontFace es el que se deberá de usar en el style del elemento,
				// vamos a usar los slugs para todos ya que si el nombre tiene espacios puede dar problemas
				const font = new FontFace(fontFace.slug, url, {
					weight: `${weigthToSearch}`,
					style: style,
				});
				font
					.load()
					.then((loadedFont) => {
						// Una vez cargada y lista para renderizarse, la añadimos a las fuentes cargadas
						(document as any).fonts.add(loadedFont);
						if (!loadedFonts.value.has(fontFace.family)) {
							loadedFonts.value.set(fontFace.family, fontFace);
						} else {
							loadedFonts.value.get(fontFace.family)?.weights.push(`${weight}`);
						}

						// Comprobamos si la fuente falló al cargar anteriormente si lo hace y ahora a cargado correctamente  sacamos la fuente o el peso de la lista de fuentes fallidas
						if (failedFonts.value.has(fontFace.family)) {
							if (failedFonts.value.get(fontFace.family)?.weights.includes(`${weight}`)) {
								failedFonts.value
									.get(fontFace.family)
									?.weights.splice(failedFonts.value.get(fontFace.family)!.weights.indexOf(`${weight}`), 1);
							}

							if (failedFonts.value.get(fontFace.family)?.weights.length === 0) {
								failedFonts.value.delete(fontFace.family);
							}
						}

						resolve(true);
					})
					.catch((e) => {
						Bugsnag.leaveBreadcrumb(`Font ${fontFace.family} ${weight} failed to load`);
						console.warn(`Font ${fontFace.family} ${weight} failed to load`);
						addToFailedFont(fontFace, [weight]);
						reject(e);
					});
			});
		});

		await Promise.allSettled(promises);
	};

	const loadFonts = async (fonts: FontFaceInfo[]) => {
		const promises = fonts.map((font) => loadFont(font));

		await Promise.allSettled(promises);
	};

	const watchFonts = () => {
		watch(inUseFonts, (newFonts, oldFonts) => {
			if (isEqual(newFonts, oldFonts)) return;
			loadFonts(inUseFonts.value);
		});
	};

	/**
	 * Dado un fontFamily y un peso, devuelve el peso más cercano al pedido
	 * @param fontFamily Nombre de la fuente
	 * @param weight Peso de la fuente
	 * @param above Si es true devuelve el peso más cercano por encima del pedido, si es false devuelve el peso más cercano por debajo
	 */
	const getNearestWeight = (fontFamily: string, weight: string, above = false): string => {
		const font = allFonts.value[fontFamily];
		if (!font) return '400';

		const sortedWeights = font.weights.slice().sort((a, b) => Number(a) - Number(b));

		const closestWeight = sortedWeights.reduce(
			(closest, currentWeight) => {
				if (above) {
					if (currentWeight >= weight) {
						return Math.min(parseInt(currentWeight), closest);
					}
				} else {
					if (currentWeight <= weight) {
						return Math.max(parseInt(currentWeight), closest);
					}
				}
				return closest;
			},
			above ? Infinity : -Infinity
		);

		if ([Infinity, -Infinity].includes(closestWeight)) {
			const weightToUse = above ? sortedWeights[sortedWeights.length - 1] : sortedWeights[0];
			return weightToUse.includes('i') ? weightToUse.split('i')[0] : weightToUse;
		}

		return `${closestWeight}`;
	};

	const getFontWithFallback = (fontName: string) => {
		const font = Object.values(allFonts.value).find(
			(font) => font.name === fontName.replaceAll('"', '') || font.slug === fontName.replaceAll('"', '')
		);
		const lato = Object.values(allFonts.value).find((font) => font.name === 'Lato');

		return font || lato;
	};

	const loadFontsFromPage = async (page: Page) => {
		const fontFacesInpage: FontFaceInfo[] = [];

		const textsInPage = page.elementsAsArray().filter((el): el is Text => el instanceof Text);

		textsInPage.forEach((textEl) => {
			if (!textEl.fontWeight) textEl.fontWeight = 400;

			if (
				!fontFacesInpage.some(
					(f) => f.family === textEl.fontFamily && f.weights.includes(textEl.fontWeight?.toString())
				)
			) {
				const existFamily = fontFacesInpage.findIndex(
					(f) => f.family === textEl.fontFamily && !f.weights.includes(textEl.fontWeight?.toString())
				);
				if (existFamily !== -1) {
					fontFacesInpage[existFamily].weights.push(textEl.fontWeight?.toString());
					return;
				}
				fontFacesInpage.push({
					family: textEl.fontFamily,
					slug: textEl.fontFamily,
					weights: [textEl.fontWeight?.toString()],
				});
			}
			//@ts-ignore
			textEl
				.htmlInstance()
				.querySelectorAll<HTMLElement>('[style*="font-family"]')
				.forEach((el: HTMLElement) => {
					const fontFamily = el.style.fontFamily;
					const fontWeight = el.style.fontWeight || '400';

					if (fontFacesInpage.some((f) => f.family === fontFamily && f.weights.includes(fontWeight))) return;

					const existFamily = fontFacesInpage.findIndex(
						(f) => f.family === fontFamily && !f.weights.includes(fontWeight)
					);

					if (existFamily !== -1) {
						fontFacesInpage[existFamily].weights.push(fontWeight);
						return;
					}

					fontFacesInpage.push({ family: fontFamily, slug: fontFamily, weights: [fontWeight] });
				});
		});

		if (fontFacesInpage.length) await loadFonts(fontFacesInpage);

		// Buscamos si hay alguna fuente en el documento que no tengamos disponibles en las fuentes de wepik o en las fuentes de usuario
		// y las pedimos para tener los datos
		const fontsOtherUser = fontFacesInpage.filter((fontFace) => !fonts.value[fontFace.slug]);

		if (fontsOtherUser.length) {
			const { data } = await getExternalFonts(fontsOtherUser.map((fontFace) => fontFace.slug));

			if (data.value) {
				fonts.value = { ...fonts.value, ...keyBy(data.value, 'slug') };
			}
		}
	};

	const loadUserFonts = async () => {
		if (!user.value) return;
		const { data, error } = await getUserFonts();
		if (error.value || !data.value) return;

		user.value.fonts = data.value;
		loadFonts(
			user.value.fonts.map((font) => {
				return {
					family: font.name,
					slug: font.slug,
					weights: font.weights,
					preview: font.preview,
				};
			})
		);
	};
	/**
	 * buscamos la familia de la fuente entre las fuentes de Wepik y comprobamos si soportamos el peso
	 * correspondiente, en caso de que no lo soportemos, buscamos el peso mas cercano
	 * @param fontFamily
	 * @param weight
	 * @returns {string}
	 */
	const fixWeight = (fontFamily: string, weight: string): string =>
		fonts.value[fontFamily].weights.includes(weight) ? weight : getNearestWeight(fontFamily, weight, true);

	// Esperamos a que se carguen las fuentes de Wepik para cargar las de Disney
	watchOnce(
		() => mainStore.finishedLoading,
		(val) => {
			if (!val || !isDisneyMode.value) return;

			initialDisneyFonts.value = inUseFonts.value;
		}
	);

	return {
		wepikFonts: fonts,
		notAvailableFonts,
		fonts: allFonts,
		recommendedFonts,
		fixWeight,
		sortedFonts,
		inUseFonts,
		loadFontsFromPage,
		loadFont,
		loadFonts,
		watchFonts,
		getNearestWeight,
		fontIsLoaded,
		untilFontIsLoaded,
		getFontWithFallback,
		loadUserFonts,
		loadedFonts,
		failedFonts,
		userFonts,
	};
});
