<script lang="ts" setup>
import { until } from '@vueuse/core';
import { onMounted, ref } from 'vue';

import MockupDebugPanel from '@/apps/mockup/components/MockupDebugPanel.vue';
import ModalError from '@/apps/mockup/components/ModalError.vue';
import { useChangePreview } from '@/apps/mockup/composable/useChangePreview';
import { useErrorMockup } from '@/apps/mockup/composable/useErrorMockup';
import { useAuth } from '@/auth/composables/useAuth';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import useNavigation from '@/editor/composables/useNavigation';
import { useFonts } from '@/elements/texts/text/composables/useFonts';
import TeleportToolbar from '@/interactions/components/TeleportToolbar.vue';
import { useEditorKeyboardEvents } from '@/interactions/composables/useEditorKeyboardEvents';
import { useEditorMouseEvents } from '@/interactions/composables/useEditorMouseEvents';

const { invertView } = useChangePreview();
const syncModal = ref();

const mockupDebugOptions = ref(false);

const { watchFonts } = useFonts();
const { guardAgainstUnsavedChanges } = useNavigation();

const { setFireElement } = useErrorMockup();
const { isLogged, onFetchLogin, isAdmin, requireAuth, checkLoginStatus } = useAuth();
const { detect: detectEditorMode, mode, isAdminMode } = useEditorMode();

guardAgainstUnsavedChanges();
detectEditorMode();
guardAgainstUnsavedChanges();
watchFonts();
useEditorKeyboardEvents();
useEditorMouseEvents();

//METHODS

//Watch in MockupDebugOptions
Object.defineProperty(window, 'mockupDebugOptions', {
	get() {
		return this.mockupDebugOptions;
	},
	set(val) {
		this._debugMode = val;
		mockupDebugOptions.value = val; // Add properties of debugmode en Mockup
	},
});

onMounted(() => {
	setFireElement(syncModal.value);
});

onFetchLogin(() => {
	if (!isAdminMode.value) return;

	mode.value = isAdmin.value ? 'admin' : 'editor';

	// Comprobamos si está logeado para que fuerce una sincronización del template del usuario
	until(isLogged).toBe(true);
});
checkLoginStatus().then(() => requireAuth());
</script>

<template>
	<div class="mode-mockup flex h-screen flex-col overflow-y-hidden">
		<router-view v-slot="{ Component }">
			<transition name="slide">
				<div class="flex-1 transition-all duration-300">
					<component :is="Component" :key="$route.path" />
				</div>
			</transition>
		</router-view>
		<ModalError ref="syncModal" />
		<!-- Hidden components -->
		<MockupDebugPanel v-if="mockupDebugOptions" />
		<TeleportToolbar v-if="!invertView" />
	</div>
</template>

<style>
html {
	overflow-y: hidden;
	position: relative;
}
body {
	overflow-y: hidden;
	font-family: 'inter-mockup', ui-monospace !important;
	position: relative;
}
</style>
<style lang="sass">
.mode-mockup
	*[tooltip-position=right]
		&:hover::before
			animation-delay: 1.5s

	*[tooltip-position=bottom]
		&:hover::before
			animation-delay: 1.5s

	*[tooltip-position=top]
		&:hover::before
			animation-delay: 1.5s

	*[tooltip-position=hover]
		&:hover::before
			animation-delay: 1.5s

//Cambiar diseño de seleccíon
#app
	.mode-mockup
		.moveable-line
			@apply bg-mockupControls w-[2px] h-[2px]
		.moveable-control-box .moveable-control::before
			@apply border-mockupControls
		.moveable-control-box .moveable-rotation
			@apply bg-mockupControls
		.moveable-control-box .moveable-rotation .moveable-rotation-control
			@apply bg-mockupControls
</style>
