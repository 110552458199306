import {Frame} from "@/apps/mockup/Types/basicTypes";

export class CanvasUtils {
	public static cloneCanvas(oldCanvas: HTMLCanvasElement, options:
		{currentCanvas?: HTMLCanvasElement | null, size?: { width: number; height: number } | null; resize: boolean }
		= {currentCanvas: null, size: null, resize: false }
	): HTMLCanvasElement {
		const { currentCanvas, size, resize } = options;
		const newCanvas = currentCanvas ?? document.createElement('canvas');
		newCanvas.id = `clone-${oldCanvas.id}`;
		const ctx = newCanvas.getContext('2d');
		newCanvas.width = size?.width ?? oldCanvas.width;
		newCanvas.height = size?.height ?? oldCanvas.height;
		resize ? ctx?.drawImage(oldCanvas, 0, 0, newCanvas.width, newCanvas.height) :
			ctx?.drawImage(oldCanvas, 0, 0);
		return newCanvas;
	}

	public static async imageToCanvas(url:string, referenceCanvas?: HTMLCanvasElement): Promise<HTMLCanvasElement> {
		return new Promise<HTMLCanvasElement>((res) => {
			const img = new Image();
			img.src = url;
			img.crossOrigin = 'anonymous';

			img.onload = () => {
				const canvas = document.createElement('canvas');
				const ctx = canvas.getContext('2d');
				canvas.width = referenceCanvas ? referenceCanvas.width : img.width;
				canvas.height = referenceCanvas ? referenceCanvas.height : img.height;
				ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);
				res(canvas);
			}
		})
	}

	/**
	 * Rotate and write in canvas
	 * Only used when canvas must be rotated in pivot point of the image coordinates
	 * @param payload
	 * @param payload.canvas - Canvas to write
	 * @param payload.degrees - Degrees to rotate
	 * @param payload.frame - Frame to write
	 * @param payload.resource - Image to write
	 */
	public static rotateAndWriteInCanvas(payload: {canvas: HTMLCanvasElement, degrees: number, frame: Frame, resource: CanvasImageSource}){
		const {canvas, degrees, frame, resource} = payload
		const {x, y, height, width} = frame
		const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
		ctx.save();
		const radiansAngle = degrees * Math.PI / 180;
		const centerX = x ;
		const centerY = y;

		ctx.translate(centerX, centerY);
		ctx.rotate(radiansAngle);
		ctx.drawImage(resource, 0, 0, width, height);
		ctx.restore();
		return;
	}

	public static mergeCanvas(canvasReference: HTMLCanvasElement, canvasCollection: HTMLCanvasElement[]): HTMLCanvasElement {
		const canvas = document.createElement('canvas');
		canvas.width = canvasReference.width;
		canvas.height = canvasReference.height;

		const ctx = canvas.getContext('2d');
		ctx?.drawImage(canvasReference, 0, 0);
		canvasCollection.forEach((c) => {
			ctx?.drawImage(c, 0, 0);
		});
		return canvas

	}

	public static imageDataFromCanvas(canvas: HTMLCanvasElement): ImageData {
		return canvas.getContext('2d')?.getImageData(0, 0, canvas.width, canvas.height) as ImageData;
	}

	public static rotateContent(currentCanvas: HTMLCanvasElement, degrees: Degrees): HTMLCanvasElement {
		const canvas = document.createElement('canvas');
		canvas.width = currentCanvas.height as number;
		canvas.height = currentCanvas.width as number;
		const newCtx = canvas.getContext('2d') as CanvasRenderingContext2D;

		// Rotar el contenido
		const translate = CanvasUtils.getTranslateAccordingToRotation(degrees, canvas);
		newCtx.translate(translate.x, translate.y);
		newCtx.rotate(degrees);
		newCtx.drawImage(currentCanvas, 0, 0, currentCanvas.width, currentCanvas.height);

		return canvas;
	}

	public static async canvasToBlob(canvas: HTMLCanvasElement): Promise<string> {
		return await new Promise((resolve) => {
			canvas.toBlob((blob) => {
				const newUrl = URL.createObjectURL(blob as Blob);
				resolve(newUrl);
			});
		});
	}

	public static getTranslateAccordingToRotation(degrees: Degrees, canvas: HTMLCanvasElement): { x: number; y: number } {
		const dictionary = {
			[Degrees.DEG_0]: { x: 0, y: 0 },
			[Degrees.DEG_90]: { x: canvas.width, y: 0 },
			[Degrees.DEG_180]: { x: canvas.width, y: canvas.height },
			[Degrees.DEG_270]: { x: 0, y: canvas.height },
		};
		return dictionary[degrees];
	}

	public static arrayToImageData(payload: { input: any; width: number; height: number }): ImageData {
		const DEFAULT_COLOR = { r:255, g:255, b:255, a:255 };
		const { input, width, height } = payload;
		const arr = new Uint8ClampedArray(4 * width * height).fill(0);
		for (let i = 0; i < input.length; i++) {
			// Threshold the onnx model mask prediction at 0.0
			// This is equivalent to thresholding the mask using predictor.model.mask_threshold
			// in python
			if (input[i] > 0.0) {
				arr[4 * i + 0] = DEFAULT_COLOR.r;
				arr[4 * i + 1] = DEFAULT_COLOR.g;
				arr[4 * i + 2] = DEFAULT_COLOR.b;
				arr[4 * i + 3] = DEFAULT_COLOR.a;
			}
		}
		return new ImageData(arr, height, width);
	}

	public static fromImageData(imageData: ImageData, canvas?: HTMLCanvasElement): HTMLCanvasElement {
		if (!canvas) canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');
		canvas.width = imageData.width;
		canvas.height = imageData.height;
		ctx?.putImageData(imageData, 0, 0);
		return canvas;
	}

	public static freedomMethod(canvas: HTMLCanvasElement | OffscreenCanvas) {
		canvas.width = 1;
		canvas.height = 1;
	}

	public static createCanvas(width: number, height: number): HTMLCanvasElement {
		const canvas = document.createElement('canvas');
		canvas.width = width;
		canvas.height = height;
		return canvas;

	}
}

export enum Degrees {
	DEG_0 = 0,
	DEG_90 = Math.PI / 2,
	DEG_180 = Math.PI,
	DEG_270 = (Math.PI / 2) * 3,
}
