import { SizeComposition } from '@/apps/mockup/composable/useComposite25D';
import compose from '@/apps/mockup/composition/compose';
import {
	BlendMode,
	BlendRange,
	LayerCanvasScheme,
	LayerType,
	Resource,
	Size,
	WebGLBlendMode,
} from '@/apps/mockup/schemas';
import {MockupResources} from "@/apps/mockup/classes/MockupResources";

export class LayerCanvas implements LayerCanvasScheme {
	id: string;
	kind: LayerType;
	name: string;
	blendMode: string;
	opacity: string;
	canvas: HTMLCanvasElement;
	size: Size;
	visible: boolean;
	mask?: Resource;
	layerCached?: HTMLImageElement | HTMLCanvasElement | null;
	maskCached?: HTMLImageElement | null;
	hadChanges?: boolean | null; //This key is to inform to father Group if layer had changes
	lastChangeIdComposed?: string | undefined; //This key is used to validate when a SmartObject not is necesary recompose and should be used their image cached
	meta: any;
	blendIf: BlendRange[];
	isClippingMask: boolean;

	constructor(layer: LayerCanvas) {
		const { id, kind, name, blendMode, opacity, visible, mask, meta, isClippingMask, blendIf } = layer;
		this.id = id;
		this.kind = kind;
		this.name = name;
		this.blendMode = blendMode;
		this.opacity = opacity;
		this.visible = visible;
		let canvas = MockupResources.getCanvas(`${id}-canvas`)?.domElement ?? undefined;
		if (!canvas) {
			canvas = document.createElement('canvas');
			canvas.id = `${id} - ${name}`;
			MockupResources.setCanvas(`${id}-canvas`, { domElement: canvas, type: 'layer' });
		}
		this.canvas = canvas as HTMLCanvasElement;
		this.size = {
			width: SizeComposition.value.width,
			height: SizeComposition.value.height,
		};
		this.mask = mask;
		this.meta = meta;
		this.blendIf = blendIf;
		this.isClippingMask = isClippingMask;
	}

	/**
	 * Method to generate Mask in Layer
	 * @returns {HTMLImageElement}Image result
	 */
	createMask(): HTMLImageElement {
		return MockupResources.getImage(this.mask!.id)?.domElement as HTMLImageElement;
	}

	getContext(): CanvasRenderingContext2D {
		return this.canvas.getContext('2d') as CanvasRenderingContext2D;
	}

	/**
	 * Method to get Alpha of Layer
	 * In case when load a mockup of Original Mockups, the opacity is a number between 0-1
	 * In case when load a mockup of other author, the opacity is a number between 0-100
	 */
	getAlpha(): number {
		if (!this.meta) return parseFloat(this.opacity);
		return parseFloat(this.opacity)/100;
	}

	/**
	 * Draw resource in context (ctx)
	 * @param {CanvasRenderingContext2D} ctx
	 * @param {CanvasImageSource} resource
	 */
	drawImage(ctx: CanvasRenderingContext2D, resource: CanvasImageSource) {
		const wglbm = WebGLBlendMode[this.blendMode];
		const blendModeType: number | string = typeof wglbm == 'number' && wglbm >= 0 ? wglbm : this.setComposition();

		if (this.blendIf && this.blendIf.length !== 0) {
			const grayBlendIf = this.blendIf.find((b) => b.channel === 'gray')!;
			compose().setBlendIf({
				underlying: {
					blue: new Float32Array([0,0,1,1]),
					gray: new Float32Array([grayBlendIf.destBlackMin / 255, 78 / 255, 255 / 255, grayBlendIf.desaturate / 255]),
					green: new Float32Array([0,0,1,1]),
					red: new Float32Array([0,0,1,1]),
				},
				current: {
					blue: new Float32Array([0,0,1,1]),
					gray: new Float32Array([
						grayBlendIf.srcBlackMin / 255,
						grayBlendIf.srcBlackMax / 255,
						grayBlendIf.srcWhiteMin / 255,
						grayBlendIf.srcWhiteMax / 255,
					]),
					green: new Float32Array([0,0,1,1]),
					red: new Float32Array([0,0,1,1]),
				},
			});
		}

		compose().run(resource, blendModeType, {
			applyClippingMask: this.isClippingMask,
		});
	}

	/**
	 * Manage and Handler BlendMode
	 * @returns {BlendMode}
	 */
	private setComposition() {
		if (!(this.blendMode in BlendMode))
			console.warn(`Layer ${this.name} don't have a valid Blend Mode: ${this.blendMode}`);
		return (<any>BlendMode)[this.blendMode];
	}
}
