import { TimeTools } from '@/common/utils/TimeTools';
import { Size } from '@/Types/types';

interface MetadataVideo {
	size: Size;
	duration: number;
}

export class VideoTools {
	static getRealSize(url: string) {
		const promise = new Promise<Size>((resolve) => {
			const video = document.createElement('video');
			document.body.insertAdjacentElement('beforeend', video);
			video.muted = true;

			video.addEventListener('loadedmetadata', () => {
				const width = video.videoWidth;
				const height = video.videoHeight;
				resolve({ width, height });
			});
			video.src = url;
			video.remove();
		});

		return promise;
	}

	static getMetadata(url: string) {
		const promise = new Promise<MetadataVideo>((resolve) => {
			const video = document.createElement('video');
			video.crossOrigin = 'Anonymous';
			video.preload = 'metadata';
			video.muted = true;

			video.addEventListener('loadedmetadata', () => {
				const width = video.videoWidth;
				const height = video.videoHeight;
				const duration = TimeTools.toMS(video.duration);

				resolve({
					size: { width, height },
					duration,
				});
			});
			video.src = url;
			video.remove();
		});

		return promise;
	}

	static generateSnapshot(url: string, size: Size, seconds?: number) {
		const promise = new Promise<string[]>((resolve) => {
			const images: string[] = [];
			const canvas = document.createElement('canvas');
			const videoDom = document.createElement('video');
			videoDom.src = url;
			videoDom.crossOrigin = 'Anonymous';
			videoDom.autoplay = true;
			videoDom.muted = true;

			videoDom.addEventListener('loadeddata', () => {
				videoDom.currentTime = 0.01;
			});
			const generateImages = () => {
				canvas.width = size.width;
				canvas.height = size.height;
				const ctx = canvas.getContext('2d');
				if (ctx) {
					ctx.drawImage(videoDom, 0, 0, canvas.width, canvas.height);
					canvas.toBlob((blob) => {
						if (!blob) return;

						images.push(URL.createObjectURL(blob));
						if ((seconds || videoDom.duration) > videoDom.currentTime) {
							videoDom.currentTime += 1;
							return;
						}
						videoDom.removeEventListener('seeked', generateImages);
						resolve(images);
					});
				}
			};

			videoDom.addEventListener('seeked', generateImages);
		});

		return promise;
	}

	static allPageVideosLoaded(): Promise<any> {
		return Promise.all(
			Array.from(document.querySelectorAll('video')).map(
				(video) =>
					new Promise((resolve) => {
						// Ready state 4 means that the video is ready to play
						if (video.readyState === 4) return resolve(null);

						video.oncanplaythrough = video.onerror = resolve;
					})
			)
		);
	}
}
