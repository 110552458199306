const findDistance = (point1: number[], point2: number[]) => {
	const x1 = point1[0];
	const y1 = point1[1];
	const x2 = point2[0];
	const y2 = point2[1];
	return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
};
type point = { x: number; y: number };
export type props = {
	width: number;
	height: number;
	points: {
		topLeft: point;
		topRight: point;
		bottomLeft: point;
		bottomRight: point;
	};
	alpha: number;
};

const findIntersectionPoint = (point1: point, point2: point, point3: point, point4: point) => {
	// Extract coordinates from points
	const x1 = point1.x;
	const y1 = point1.y;
	const x2 = point2.x;
	const y2 = point2.y;
	const x3 = point3.x;
	const y3 = point3.y;
	const x4 = point4.x;
	const y4 = point4.y;

	// Calculate slopes
	const slope1 = x2 === x1 ? Infinity : (y2 - y1) / (x2 - x1);
	const slope2 = x4 === x3 ? Infinity : (y4 - y3) / (x4 - x3);

	// Check if slopes are parallel
	if (slope1 === slope2) {
		console.log('Lines are parallel, no intersection point exists.');
		return null;
	}

	// Check if one of the lines is vertical
	if (!isFinite(slope1)) {
		const x = x1;
		const y = slope2 * (x - x3) + y3;
		return [parseFloat(x.toFixed(5)), parseFloat(y.toFixed(5))];
	}

	if (!isFinite(slope2)) {
		const x = x3;
		const y = slope1 * (x - x1) + y1;
		return [parseFloat(x.toFixed(5)), parseFloat(y.toFixed(5))];
	}

	// Calculate intersection point coordinates
	const x = (slope1 * x1 - slope2 * x3 + y3 - y1) / (slope1 - slope2);
	const y = slope1 * (x - x1) + y1;

	return [parseFloat(x.toFixed(5)), parseFloat(y.toFixed(5))];
};
const pointOnLine = (x1: number, y1: number, x2: number, y2: number, distance: number) => {
	// Calculate the vector between the two points
	const vectorX = x2 - x1;
	const vectorY = y2 - y1;
	// Calculate the magnitude of the vector
	const magnitude = Math.sqrt(vectorX * vectorX + vectorY * vectorY);
	// Normalize the vector
	const normalizedVectorX = vectorX / magnitude;
	const normalizedVectorY = vectorY / magnitude;
	// Scale the normalized vector by the distance
	const scaledVectorX = normalizedVectorX * distance;
	const scaledVectorY = normalizedVectorY * distance;
	// Calculate the coordinates of the desired point
	const desiredX = x1 + scaledVectorX;
	const desiredY = y1 + scaledVectorY;

	return [parseFloat(desiredX.toFixed(5)), parseFloat(desiredY.toFixed(5))];
};
const swapKeysAndValues = (originalMap: Map<number[], number>) => {
	const newMap = new Map<number, number[]>();
	originalMap.forEach((value, key) => {
		newMap.set(value, key);
	});
	return newMap;
};
/**
 *
 * @param basePoints The array must have four points, each point is represented by two values, x and y.
 * the values are in the range of -1 to 1. The points are in the following order:
 * bottom-left, top-left, top-right, bottom-right.
 */

const w_segments = 100;
const h_segments = 100;

export default (points: props) => {
	const point1 = [points.points.bottomLeft.x, points.points.bottomLeft.y];
	const point2 = [points.points.topLeft.x, points.points.topLeft.y];
	const point3 = [points.points.topRight.x, points.points.topRight.y];
	const point4 = [points.points.bottomRight.x, points.points.bottomRight.y];

	let distance: number, point: number[];
	const h_top_points = [point2];
	distance = findDistance(point2, point3); // horizontal top
	for (let i = 1; i < w_segments; i++) {
		point = pointOnLine(point2[0], point2[1], point3[0], point3[1], (distance / w_segments) * i);
		h_top_points.push(point);
	}
	h_top_points.push(point3);

	const h_bottom_points = [point1];
	distance = findDistance(point1, point4); // horizontal bottom
	for (let i = 1; i < w_segments; i++) {
		point = pointOnLine(point1[0], point1[1], point4[0], point4[1], (distance / w_segments) * i);
		h_bottom_points.push(point);
	}
	h_bottom_points.push(point4);

	let v_left_points = [point1];
	distance = findDistance(point1, point2); // vertical left
	for (let i = 1; i < h_segments; i++) {
		point = pointOnLine(point1[0], point1[1], point2[0], point2[1], (distance / h_segments) * i);
		v_left_points.push(point);
	}
	v_left_points.push(point2);

	let v_right_points = [point4];
	distance = findDistance(point4, point3); // vertical right
	for (let i = 1; i < h_segments; i++) {
		point = pointOnLine(point4[0], point4[1], point3[0], point3[1], (distance / h_segments) * i);
		v_right_points.push(point);
	}
	v_right_points.push(point3);

	let counter = 0;
	const vertex = new Map<number[], number>();
	const texture = new Map<number, number[]>();
	const quads: any[] = [];
	let point_top_left, point_top_right, point_bottom_left, point_bottom_right;
	let texture_top_left, texture_top_right, texture_bottom_left, texture_bottom_right;
	let quad: any[];

	v_left_points = v_left_points.reverse();
	v_right_points = v_right_points.reverse();

	for (let i = 0; i < h_top_points.length; i++) {
		for (let j = 0; j < v_left_points.length; j++) {
			try {
				point_bottom_left = findIntersectionPoint(
					{ x: h_top_points[i][0], y: h_top_points[i][1] },
					{ x: h_bottom_points[i][0], y: h_bottom_points[i][1] },
					{ x: v_left_points[j][0], y: v_left_points[j][1] },
					{ x: v_right_points[j][0], y: v_right_points[j][1] }
				);
				texture_bottom_left = [i / (h_top_points.length - 1), 1 - j / (v_left_points.length - 1)];

				point_bottom_right = findIntersectionPoint(
					{ x: h_top_points[i + 1][0], y: h_top_points[i + 1][1] },
					{ x: h_bottom_points[i + 1][0], y: h_bottom_points[i + 1][1] },
					{ x: v_left_points[j][0], y: v_left_points[j][1] },
					{ x: v_right_points[j][0], y: v_right_points[j][1] }
				);
				texture_bottom_right = [(i + 1) / (h_top_points.length - 1), 1 - j / (v_left_points.length - 1)];

				point_top_left = findIntersectionPoint(
					{ x: h_top_points[i][0], y: h_top_points[i][1] },
					{ x: h_bottom_points[i][0], y: h_bottom_points[i][1] },
					{ x: v_left_points[j - 1][0], y: v_left_points[j - 1][1] },
					{ x: v_right_points[j - 1][0], y: v_right_points[j - 1][1] }
				);
				texture_top_left = [i / (h_top_points.length - 1), 1 - (j - 1) / (v_left_points.length - 1)];

				point_top_right = findIntersectionPoint(
					{ x: h_top_points[i + 1][0], y: h_top_points[i + 1][1] },
					{ x: h_bottom_points[i + 1][0], y: h_bottom_points[i + 1][1] },
					{ x: v_left_points[j - 1][0], y: v_left_points[j - 1][1] },
					{ x: v_right_points[j - 1][0], y: v_right_points[j - 1][1] }
				);
				texture_top_right = [(i + 1) / (h_top_points.length - 1), 1 - (j - 1) / (v_left_points.length - 1)];
			} catch (e) {
				continue;
			}

			if (!point_top_left || !point_top_right || !point_bottom_left || !point_bottom_right) continue;

			quad = [point_top_left, point_top_right, point_bottom_right, point_bottom_left];

			if (vertex.has(point_top_left)) {
				quad[0] = vertex.get(point_top_left);
			} else {
				texture.set(counter, texture_top_left);
				vertex.set(point_top_left, counter);
				quad[0] = counter;
				counter++;
			}

			if (vertex.has(point_top_right)) {
				quad[1] = vertex.get(point_top_right);
			} else {
				texture.set(counter, texture_top_right);
				vertex.set(point_top_right, counter);
				quad[1] = counter;
				counter++;
			}

			if (vertex.has(point_bottom_right)) {
				quad[2] = vertex.get(point_bottom_right);
			} else {
				texture.set(counter, texture_bottom_right);
				vertex.set(point_bottom_right, counter);
				quad[2] = counter;
				counter++;
			}

			if (vertex.has(point_bottom_left)) {
				quad[3] = vertex.get(point_bottom_left);
			} else {
				texture.set(counter, texture_bottom_left);
				vertex.set(point_bottom_left, counter);
				quad[3] = counter;
				counter++;
			}

			quads.push(quad);
		}
	}

	const finalVertex: Map<number, number[]> = swapKeysAndValues(vertex);

	const canvas = document.createElement('canvas');
	canvas.width = points.width;
	canvas.height = points.height;
	const gl = canvas.getContext('webgl', {
		antialias: true,
		alpha: true,
	})!;
	if (!gl) {
		alert('Your browser does not support WebGL');
	}

	const programInfo: any = {};
	const buffers: any = {};
	let indices = [];

	const buildShader = () => {
		const vertexShader = `#version 100
attribute vec4 aVertexPosition;
attribute vec2 aTextureCoord;
varying highp vec2 vTextureCoord;

void main(void) {
    gl_Position = aVertexPosition;
    vTextureCoord = vec2(aTextureCoord.s, 1.0 - aTextureCoord.t); // Flip Y coordinate
}`;
		const fragmentShader = `
precision highp float;
uniform sampler2D uImage;
uniform float alpha;
varying highp vec2 vTextureCoord;

void main() {
    vec4 color = texture2D(uImage, vTextureCoord);

    gl_FragColor = color * alpha;
}
        `;

		const vertexShaderObject = gl.createShader(gl.VERTEX_SHADER)!;
		gl.shaderSource(vertexShaderObject, vertexShader);
		gl.compileShader(vertexShaderObject);

		if (!gl.getShaderParameter(vertexShaderObject, gl.COMPILE_STATUS)) {
			console.error('[GL ERR]: Vertex shader compilation error:', gl.getShaderInfoLog(vertexShaderObject));
		}

		const fragmentShaderObject = gl.createShader(gl.FRAGMENT_SHADER)!;
		gl.shaderSource(fragmentShaderObject, fragmentShader);
		gl.compileShader(fragmentShaderObject);

		if (!gl.getShaderParameter(fragmentShaderObject, gl.COMPILE_STATUS)) {
			console.error('[GL ERR]: fragment shader compilation error:', gl.getShaderInfoLog(fragmentShaderObject));
		}

		const program = gl.createProgram()!;
		gl.attachShader(program, vertexShaderObject);
		gl.attachShader(program, fragmentShaderObject);
		gl.linkProgram(program);

		if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
			console.error('Unable to initialize the shader program: ' + gl.getProgramInfoLog(program));
			return null;
		}

		programInfo.program = program;
		programInfo.attribLocations = {
			vertexPosition: gl.getAttribLocation(program, 'aVertexPosition'),
			textureCoord: gl.getAttribLocation(program, 'aTextureCoord'),
		};

		programInfo.uniformLocations = {
			vTextureCoord: gl.getUniformLocation(program, 'vTextureCoord')!,
			uImage: gl.getUniformLocation(program, 'uImage')!,
			alpha: gl.getUniformLocation(program, 'alpha')!,
		};

		const textureCoord = [];
		const vertexPosition = [];
		indices = [];

		for (let i = 0; i < quads.length; i++) {
			vertexPosition.push(finalVertex.get(quads[i][0])![0], finalVertex.get(quads[i][0])![1]);
			vertexPosition.push(finalVertex.get(quads[i][1])![0], finalVertex.get(quads[i][1])![1]);
			vertexPosition.push(finalVertex.get(quads[i][2])![0], finalVertex.get(quads[i][2])![1]);
			vertexPosition.push(finalVertex.get(quads[i][3])![0], finalVertex.get(quads[i][3])![1]);

			textureCoord.push(texture.get(quads[i][0])![0], texture.get(quads[i][0])![1]);
			textureCoord.push(texture.get(quads[i][1])![0], texture.get(quads[i][1])![1]);
			textureCoord.push(texture.get(quads[i][2])![0], texture.get(quads[i][2])![1]);
			textureCoord.push(texture.get(quads[i][3])![0], texture.get(quads[i][3])![1]);

			indices.push(quads[i][1], quads[i][2], quads[i][3], quads[i][1], quads[i][0], quads[i][3]);
		}

		const positionBuffer = gl.createBuffer();
		gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
		gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(vertexPosition), gl.STATIC_DRAW);

		const textureCoordBuffer = gl.createBuffer();
		gl.bindBuffer(gl.ARRAY_BUFFER, textureCoordBuffer);

		gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(textureCoord), gl.STATIC_DRAW);

		const indexBuffer = gl.createBuffer();
		gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);
		// indices = [0, 1, 2, 0, 2, 3]
		gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(indices), gl.STATIC_DRAW);
		buffers.position = positionBuffer!;
		buffers.textureCoord = textureCoordBuffer!;
		buffers.indices = indexBuffer!;
	};
	let texture1: WebGLTexture;
	const drawScene = () => {
		gl.clearColor(0.0, 0.0, 0.0, 0.0);
		gl.clearDepth(5.0);
		gl.enable(gl.DEPTH_TEST);
		gl.enable(gl.BLEND);
		gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
		gl.depthFunc(gl.LEQUAL);

		gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

		{
			const numComponents = 2;
			const type = gl.FLOAT;
			const normalize = false;
			const stride = 0;
			const offset = 0;
			gl.bindBuffer(gl.ARRAY_BUFFER, buffers.position!);
			gl.vertexAttribPointer(
				programInfo.attribLocations!.vertexPosition,
				numComponents,
				type,
				normalize,
				stride,
				offset
			);
			gl.enableVertexAttribArray(programInfo.attribLocations!.vertexPosition);
		}
		{
			const numComponents = 2;
			const type = gl.FLOAT;
			const normalize = false;
			const stride = 0;
			const offset = 0;
			gl.bindBuffer(gl.ARRAY_BUFFER, buffers.textureCoord!);
			gl.vertexAttribPointer(programInfo.attribLocations!.textureCoord, numComponents, type, normalize, stride, offset);
			gl.enableVertexAttribArray(programInfo.attribLocations!.textureCoord);
		}

		gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, buffers.indices!);

		// Tell WebGL to use our pair of shaders
		gl.useProgram(programInfo.program!);

		// Set the shader uniforms
		gl.activeTexture(gl.TEXTURE0);
		gl.bindTexture(gl.TEXTURE_2D, texture1);
		gl.uniform1i(programInfo.uniformLocations!.uImage, 0);

		gl.uniform1f(programInfo.uniformLocations!.alpha, points.alpha);

		{
			const vertexCount = indices.length;
			const type = gl.UNSIGNED_SHORT;
			const offset = 0;
			gl.drawElements(gl.TRIANGLES, vertexCount, type, offset);
		}
	};
	const createTexture = (image: HTMLCanvasElement) => {
		const isPowerOf2 = (value: number) => {
			return (value & (value - 1)) === 0;
		};
		const texture = gl.createTexture();
		gl.bindTexture(gl.TEXTURE_2D, texture);

		const level = 0;
		const internalFormat = gl.RGBA;
		const srcFormat = gl.RGBA;
		const srcType = gl.UNSIGNED_BYTE;

		gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, srcFormat, srcType, image);

		if (isPowerOf2(image.width) && isPowerOf2(image.height)) {
			gl.generateMipmap(gl.TEXTURE_2D);
		} else {
			gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
			gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
			gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
			gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
		}

		return texture;
	};

	buildShader();

	return (texture: HTMLCanvasElement) => {
		texture1 = createTexture(texture)!;
		drawScene();
		const response = document.createElement('canvas');
		response.width = points.width;
		response.height = points.height;
		const ctx = response.getContext('2d')!;
		ctx.drawImage(canvas, 0, 0, points.width, points.height);

		return response;
	};
};
