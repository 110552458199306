import PolyBool from 'polybooljs';

import Element from '@/elements/element/classes/Element';
import { Position } from '@/Types/types';

class CollisionTools {
	// This check is lighter than checking with rotation
	static checkCollisionWithBox = (el1: HTMLElement, el2: HTMLElement) => {
		if (!el1 || !el2) return false;

		const box1 = el1.getBoundingClientRect();
		const box2 = el2.getBoundingClientRect();

		return (
			box1.x <= box2.x + box2.width &&
			box1.x + box1.width >= box2.x &&
			box1.y <= box2.y + box2.height &&
			box1.height + box1.y >= box2.y
		);
	};

	static testCollision(el1: Element, el2: Element, domElementPosition?: Position) {
		const el1Coords = el1
			.getCorners(domElementPosition)
			.map((corner: Position) => [parseFloat(corner.x.toFixed(4)), parseFloat(corner.y.toFixed(4))]);
		const el2Coords = el2
			.getCorners(domElementPosition)
			.map((corner: Position) => [parseFloat(corner.x.toFixed(4)), parseFloat(corner.y.toFixed(4))]);

		PolyBool.epsilon(0.00000001);

		try {
			const intersectResult = PolyBool.intersect(
				{
					regions: [el1Coords],
					inverted: false,
				},
				{
					regions: [el2Coords],
					inverted: false,
				}
			);

			return intersectResult.regions.length > 0;
		} catch (e) {
			return false;
		}
	}

	// Used only to check elements are really collisioning
	static checkCollisionWithRotation(el1: Element, el2: Element, domElementPosition?: Position) {
		if (CollisionTools.testCollision(el1, el2, domElementPosition)) return true;
		else if (CollisionTools.testCollision(el2, el1, domElementPosition)) return true;
		return false;
	}
}

export default CollisionTools;
