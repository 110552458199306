import { createSharedComposable, useEventListener, useIntervalFn } from '@vueuse/core';
import { computed, ref, watch } from 'vue';

import { Box } from '@/elements/box/classes/Box';
import { Text } from '@/elements/texts/text/classes/Text';
import TextSelectionTools from '@/elements/texts/text/utils/TextSelectionTools';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { useSelection } from '@/interactions/composables/useSelection';
import { PreviousSelection, SelectionResult } from '@/Types/types';

export const useTextSelection = createSharedComposable(() => {
	const { selection: selectionElements } = useSelection();
	const previousInputSelection = ref<PreviousSelection | null>(null);
	const selection = ref<SelectionResult | null>(null);
	const domNode = ref<HTMLElement | null>(null);

	const untilNodeExist = async (selector: string): Promise<HTMLElement | null> =>
		new Promise((resolve, reject) => {
			let counter = 0;
			const { pause } = useIntervalFn(() => {
				counter >= 1000 && reject();
				const node = document.querySelector<HTMLElement>(selector);
				if (node) {
					pause();
					resolve(node);
				}
				counter += 100;
			}, 100);
		});

	// Watcher para actualizar la selección si se selecciona un texto, o limpiarla si no
	watch(
		() => selectionElements.value[0],
		async (value) => {
			if (!(value instanceof Text)) {
				domNode.value = null;
				return;
			}

			let node;
			try {
				node = await untilNodeExist(`#element-${value.id} .text-element-final`);
			} catch (error) {
				console.error(error);
			}

			if (node) domNode.value = node;
			selection.value = null;
		}
	);

	// Listener para actualizar la selección
	useEventListener(document, 'selectionchange', (e) => {
		const finalSelection = window.getSelection();

		// Obtenemos el nodo inicial de la selección y comprobamos si es un texto
		const isTextElement =
			finalSelection?.anchorNode?.parentElement?.closest('.text-element-final') ||
			(finalSelection?.anchorNode instanceof HTMLElement &&
				finalSelection?.anchorNode.classList.contains('text-element-final'));

		// Si no hay selección, pero el elemento seleccionado es un texto, lo seleccionamos
		// si no, limpiamos la selección
		if (!finalSelection?.anchorNode || !isTextElement) {
			const isText = selectionElements.value[0] instanceof Text;
			const isTextInsideBox =
				selectionElements.value[0] instanceof Box && selectionElements.value[0].firstSubElement instanceof Text;
			if (isText || isTextInsideBox) {
				const textNode = document.querySelector<HTMLElement>(
					`#element-${selectionElements.value[0].id} .text-element-final`
				);

				domNode.value = textNode || null;
				selection.value = null;
				return;
			}

			domNode.value = null;
			selection.value = null;
			return;
		}

		// Si no es un texto, limpiamos la selección
		if (!isTextElement) {
			domNode.value = null;
			selection.value = null;

			return;
		}

		// Si es un texto, seleccionamos el elemento como domNode
		// y guardamos la selección
		domNode.value =
			finalSelection?.anchorNode.parentElement?.closest('.text-element-final') ||
			(finalSelection?.anchorNode as HTMLElement);
		selection.value = {
			text: window.getSelection()?.toString(),
			selection: window.getSelection(),
		};
	});

	const selectedNodes = computed<HTMLElement[] | Node[]>(() => {
		if (!domNode.value) {
			// Si no hay nodo, pero el elemento seleccionado es un texto, lo asignamos a los nodos seleccionados
			if (selectionElements.value[0] instanceof Text) {
				domNode.value =
					document.querySelector<HTMLElement>(`#editable-${selectionElements.value[0].id}`) ||
					document.querySelector<HTMLElement>(`#element-${selectionElements.value[0].id} .text-element-final`);

				return [domNode.value, ...Array.from(domNode.value?.querySelectorAll('*') || [])];
			}

			return;
		}

		if (!selection.value?.selection) {
			const editableText = domNode.value?.querySelector<HTMLDivElement>(':scope > div > div');
			if (editableText) {
				return [...Array.from(editableText.querySelectorAll('*') || [])];
			}
			return [domNode.value, ...Array.from(domNode.value?.querySelectorAll('*') || [])];
		}

		let finalNodes: HTMLElement[] | Node[];

		finalNodes = [domNode.value];

		const anchorNode = selection.value.selection.anchorNode as HTMLElement;

		if (
			anchorNode &&
			((anchorNode.nodeType === 1 && anchorNode.closest('.text-element-final')) ||
				(anchorNode.parentElement && anchorNode.parentElement.closest('.text-element-final')))
		) {
			const range = selection.value.selection.getRangeAt(0);

			// Obtenemos los nodos finales de la selección
			finalNodes = TextTools.getRangeSelectedNodes(range);
		} else {
			const range = new Range();

			range.selectNodeContents(domNode.value as HTMLElement);

			finalNodes = TextTools.getRangeSelectedNodes(range);
		}

		if (finalNodes.length === 1 && finalNodes[0] === domNode.value && domNode.value?.querySelectorAll('*').length) {
			finalNodes = [...finalNodes, ...Array.from(domNode.value?.querySelectorAll('*'))];
		}

		return finalNodes;
	});

	const restorePreviousSelection = () => {
		if (!previousInputSelection.value) return;

		TextSelectionTools.createRange(previousInputSelection.value, domNode);

		const finalSelection = window.getSelection();

		selection.value = {
			text: finalSelection?.toString(),
			selection: finalSelection,
		};

		const newDomNode =
			finalSelection?.anchorNode instanceof HTMLElement
				? finalSelection?.anchorNode.closest<HTMLElement>('.text-element-final')
				: finalSelection?.anchorNode?.parentElement?.closest<HTMLElement>('.text-element-final');

		if (newDomNode) {
			domNode.value = newDomNode;
		}

		previousInputSelection.value = null;
	};

	const getWordSelectionFromCaret = (caretRange: Range) => {
		const textContent = caretRange.startContainer.textContent;
		if (!textContent) return;

		const caretPosition = caretRange.startOffset;

		let wordStart = caretPosition;
		// ? recorremos el índice actual del caret hacia atrás hasta que no haya letra, así sabemos donde empieza
		while (wordStart > 0 && /\S/.test(textContent[wordStart - 1])) {
			wordStart--;
		}

		let wordEnd = caretPosition;
		// ? recorremos el índice actual del caret hacia adelante hasta que no haya letra, así sabemos donde acaba
		while (wordEnd < textContent.length && /\S/.test(textContent[wordEnd])) {
			wordEnd++;
		}

		const wordRange = document.createRange();
		wordRange.setStart(caretRange.startContainer, wordStart);
		wordRange.setEnd(caretRange.startContainer, wordEnd);

		return wordRange;
	};

	return {
		domNode,
		selection,
		selectedNodes,
		getWordSelectionFromCaret,
		previousInputSelection,
		restorePreviousSelection,
	};
});
