import Bugsnag from '@bugsnag/js';
import { createSharedComposable, until } from '@vueuse/core';
import { Ref, ref } from 'vue';
import { useRoute } from 'vue-router';

import { GroupLayer } from '@/apps/mockup/classes/Layers/GroupLayer';
import { ImageLayer } from '@/apps/mockup/classes/Layers/ImageLayer';
import { MockupResources } from '@/apps/mockup/classes/MockupResources';
import { useActiveMockup } from '@/apps/mockup/composable/UseActiveMockup';
import { useErrorMockup } from '@/apps/mockup/composable/useErrorMockup';
import { useMockupMode } from '@/apps/mockup/composable/useMockupMode';
import { CustomizableUtils } from '@/apps/mockup/facades/CustomizablesUtils';
import { useMockupMigrationStore } from '@/apps/mockup/migration/stores/MockupMigationStore';
import { useMockupStore } from '@/apps/mockup/mockupStore';
import {
	CompositingSize,
	CompositionScaleBySize,
	Customizable2DTexture,
	CustomizableColor,
	CustomizableTexture,
	LayerType,
	MockupElement,
	MockupElementTexture,
	MockupManifest,
	ThumbnailsSize,
} from '@/apps/mockup/schemas';
import { ApiMockup } from '@/apps/mockup/services/ApiMockup';
import NormalizeEditorResponse from '@/apps/mockup/services/normalize/NormalizeEditorResponse';
import { NormalizeMigrationResponses } from '@/apps/mockup/services/normalize/NormalizeMigrationResponses';
import { EditingMode } from '@/apps/mockup/Types/basicTypes';
import { MockupErrorType } from '@/apps/mockup/Types/errorTypes';
import mockupHover from '@/assets/patterns/mockup-pattern.png';
import { SolidColor } from '@/color/classes/SolidColor';
import ImageEditor from '@/elements/medias/images/image/classes/Image';
import Page from '@/page/classes/Page';
import { useProjectStore } from '@/project/stores/project';
import { Size } from '@/Types/types';
import { useFeatureFlagEnabled } from '@/apps/mockup/composable/useFeatureFlagEnabled';
import { cloneDeepWith } from 'lodash';

export const useLoadMockup = createSharedComposable(() => {
	const route = useRoute();
	const mockup = useMockupStore();
	const migrationStore = useMockupMigrationStore();
	const project = useProjectStore();
	const { isPublishInMigration } = useFeatureFlagEnabled();
	const { addPlaceHolderDesign: addDesignPlaceholder, adjustImageByOrientation } = useActiveMockup();
	const { isMigrationMode, isDownloadMode } = useMockupMode();
	const { registerMockupError } = useErrorMockup();
	const isChangingMode: Ref<boolean> = ref(false);
	const isPreDraftMockup = isMigrationMode.value && !isPublishInMigration.value;
	/**
	 * Load and reverse any layer mockup manifest
	 * @param size Expected size resolution
	 * @param key Composition type
	 * @param callback optional parameter to control the simultaneous manifest loading
	 */
	const loadManifest = async () => {
		const path = route.name as string;
		const sku = route.query.sku as string;
		const mode = isDownloadMode.value ? CompositingSize.DOWNLOAD : CompositingSize.POSTER;

		if (!sku) {
			mockup.error = true;
			Bugsnag.leaveBreadcrumb('Document dont have sku in Path');
			registerMockupError(MockupErrorType.skuDontExist);
		} else {
			try {
				const firstResponse = await new ApiMockup().loadMockup(sku, mode, path);
				const response = NormalizeEditorResponse.MockupItemResponse(firstResponse);
				const data = response.data;

				mockup.isPremium = data.premium;

				const mockupManifest = response.data.manifest as any;

				// If is in migration mode and pre-draft Mockup, set the provider info
				if (isPreDraftMockup)
					migrationStore.providerInfo = NormalizeMigrationResponses.providerInfo(response.provider_info);

				if (isMigrationMode.value)
					migrationStore.backupManifest = {
						children: cloneDeepWith(mockupManifest.children),
						customizables: [...mockupManifest.customizables],
						sku: mockupManifest.sku,
						size: { ...mockupManifest.size },
						name: mockupManifest.name,
						posterURL: mockupManifest.posterURL,
						preview: mockupManifest.preview,
						scene3D: mockupManifest.scene3D,
					};
				mockup.title = mockupManifest.name;
				mockup.sku = mockupManifest.sku;
				mockup.mockupType = mockupManifest.type;
				mockup.mockup.originalSize = mockupManifest.size;
				mockupManifest.size = getMockupSize(mode, mockupManifest.size as Size);
				mockup.size = mockupManifest.size;

				const amountImagesFound = ref(0);
				const amountImagesLoaded = ref(0);

				const updateImagesFound = () => {
					amountImagesFound.value++;
				};

				const updateImagesLoaded = () => {
					amountImagesLoaded.value++;
				};

				reverseMockup(mockupManifest, updateImagesFound, updateImagesLoaded); // Reverse Mockup children

				mockup.manifest = mockupManifest;

				if (mockup.mockup.elements.length == 0) {
					mapElements(mockupManifest);
				}

				await loadOtherResources();
				await until(amountImagesFound).toBe(amountImagesLoaded);
				return true;
			} catch (e) {
				if (typeof e == typeof MockupErrorType) {
					registerMockupError(e as MockupErrorType);
					return;
				}
				registerMockupError(MockupErrorType.manifestCorrupt);
			}
		}
	};

	const getMockupSize = (mode: CompositingSize, size: Size): Size => {
		const baseSize = CompositionScaleBySize[mode];

		const isPortrait = size.height > size.width;
		const aspectRatio = size.width / size.height;
		return {
			width: isPortrait ? baseSize * aspectRatio : baseSize,
			height: isPortrait ? baseSize : baseSize / aspectRatio,
		};
	};

	const loadOtherResources = async () => {
		await Promise.all([await loadPattern(), await loadInteractiveZones()]);
	};

	const loadInteractiveZones = async () => {
		const amountToLoad = ref(mockup.getElements.length);
		const amountLoaded = ref(0);

		for (const element of mockup.getElements) {
			const resource = new Image();
			resource.crossOrigin = 'anonymous';
			resource.src = element.interactiveZone.path!;
			resource.onload = () => {
				MockupResources.setImage(element.id, { domElement: resource, type: 'interactiveZone' });
				amountLoaded.value++;
			};
		}

		await until(amountToLoad).toBe(amountLoaded);
	};

	const loadPattern = () => {
		return new Promise<boolean>((resolve, reject) => {
			const patternImg = new Image();
			patternImg.crossOrigin = 'anonymous';
			patternImg.src = mockupHover;
			patternImg.onload = () => {
				MockupResources.setImage('pattern', { domElement: patternImg, type: 'pattern' });
				resolve(true);
			};
		});
	};

	/**
	 * Recursive reverse to manifest Mockup
	 *
	 * This action is done because, the layers in the manifiest
	 * are in inversed order to compose in Web
	 * @param Layer
	 *
	 */
	const reverseMockup = (
		Layer: GroupLayer,
		resourceFounded: CallableFunction,
		resourceLoaded: CallableFunction,
		size?: Size
	) => {
		try {
			if (Layer.children) {
				Layer.children.reverse();

				if (!size) size = Layer.size; //Assign size of Manifest

				for (const child of Layer.children) {
					if (child.mask && child.mask.path) {
						if (!child.visible) continue;
						resourceFounded();
						const resource = new Image(size.width, size.height);
						resource.crossOrigin = 'anonymous';
						resource.src = child.mask.path.replace('fake-gcs-server', 'localhost');
						resource.onload = () => {
							MockupResources.setImage(child.mask!.id, { domElement: resource, type: 'layer' });
							resourceLoaded();
						};
					}

					if (child.kind === LayerType.image) {
						if (!child.visible) continue;
						const layerImage = child as ImageLayer;
						if (!layerImage.image.path) continue;
						resourceFounded();
						const resource = new Image(size.width, size.height);
						resource.crossOrigin = 'anonymous';
						resource.src = layerImage.image.path.replace('fake-gcs-server', 'localhost');
						resource.onload = () => {
							MockupResources.setImage(layerImage.image.id, { domElement: resource, type: 'layer' });
							resourceLoaded();
						};

						resource.onerror = () => {
							registerMockupError(MockupErrorType.missingResource);
						};
					}

					if ((child as GroupLayer).children) {
						reverseMockup(child as GroupLayer, resourceFounded, resourceLoaded, size);
					}
				}
			}
		} catch (e: any) {
			registerMockupError(MockupErrorType.manifestCorrupt);
		}
	};

	/**
	 * Map every custom element from manifest
	 * Create all editable Element  such as Texture | Color
	 * @param {MockupManifest} manifest
	 */
	const mapElements = (manifest: MockupManifest) => {
		manifest.customizables
			.filter((custom) => !custom.isDisabled)
			.map((custom: CustomizableTexture | CustomizableColor | Customizable2DTexture) => {
				mockup.mockup.elements.push(CustomizableUtils.normalize(custom));
			});
	};

	/**
	 * Update current Customizable Elements this path of guides
	 * @param {MockupManifest} manifest
	 */
	const updateSvgGuides = async (manifest: MockupManifest) => {
		mockup.mockup.elements = mockup.getAllElements.map((el) => {
			if (el.kind == 'texture') {
				const texture = el as MockupElementTexture;
				if (texture.guide)
					texture.guide.path = (manifest.customizables.find((c) => c.id == el.id) as CustomizableTexture).guide
						?.path as string;
			}
			return el;
		});
	};
	/**
	 * This method get new Mode and manage this change
	 * Updating Pages
	 * @param {EditingMode} mode
	 */
	const changeModeMockup = () => {
		project.pages = [];
		loadPages();
	};

	const loadPages = async (flag?: Ref<boolean>) => {
		try {
			const mockupTextureElements = mockup.getTextureElements;
			if (mockupTextureElements.length == 0) registerMockupError(MockupErrorType.notHasTextureElements);

			project.pages = mockupTextureElements.map((element: MockupElement) => {
				return Page.create({
					id: element.id,
					name: element.name,
					background: SolidColor.transparent(),
					elements: new Map(),
				});
			});

			// Load default designs in Page
			for (const element of mockup.getTextureElements) {
				if (!(element as MockupElementTexture).showGuide && element.kind == 'texture') {
					const designImage = await addDesignPlaceholder(element as MockupElementTexture, project.size); //Create Image to push in page
					designImage.locked = true;
					project.pages.map((page) => {
						if (page.id == element.id) {
							page.elements.set(designImage.id, designImage);
						}
					});
				} else {
					//Set to true applied change because it not necessary a first render
					element.appliedChanges = {
						[CompositingSize.PREVIEW]: true,
						[CompositingSize.POSTER]: true,
						[CompositingSize.DOWNLOAD]: true,
					};
				}
			}
			await useQueryParamsMockup();

			if (flag) flag.value = true;
		} catch (error) {
			console.log(error);
		}
	};

	/**
	 * Load image from query params
	 * If query params is not present, return
	 * @returns
	 */
	const useQueryParamsMockup = async () => {
		const { query } = route;
		const firstTextureElement = mockup.getTextureElements[0];
		const page = project.getPageById(firstTextureElement.id);

		if (!query.image || !page) return;

		// Handler to if Image not load correctly or not found, return to avoid errors
		const img = new Image();
		img.src = query.image as string;
		img.crossOrigin = 'anonymous';
		img.onerror = (e) => {
			return;
		};

		project.size = { width: firstTextureElement.width as number, height: firstTextureElement.height as number };
		const image = await ImageEditor.fromUrl(query.image as string);

		await adjustImageByOrientation(image, project.size, firstTextureElement);
		page.elements.set(image.id, image);
		mockup.newTextureChange(firstTextureElement.id as string);
	};

	return {
		loadManifest,
		loadPages,
		changeModeMockup,
		isChangingMode,
	};
});
