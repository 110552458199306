import { createSharedComposable } from '@vueuse/core';
import Moveable from 'moveable';
import { ref } from 'vue';

import { InteractionAction } from '@/Types/types';

export const useMoveable = createSharedComposable(() => {
	const action = ref<InteractionAction>(InteractionAction.Idle);
	const moveable = ref<Moveable | null>();
	const isCreatingElement = ref(false);
	const isMiddleHandler = ref(false);

	return {
		action,
		isCreatingElement,
		isMiddleHandler,
		moveable,
	};
});
