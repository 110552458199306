import { createEventHook, createSharedComposable, useEventListener } from '@vueuse/core';
import { computed, ref, watch } from 'vue';

import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { useProject } from '@/project/composables/useProject';
import { useProjectStore } from '@/project/stores/project';

export const useBottomBarVisibility = createSharedComposable(() => {
	const ADJUSTED_WIDTH = 'w-[calc(100vw-750px)]';
	const { isSlidesgoMode } = useEditorMode();
	const { projectIsPresentation } = useProject();
	const project = useProjectStore();
	const { isMobile } = useDeviceInfo();
	const transitionStart = createEventHook();
	const transitionFinish = createEventHook();
	const store = useMainStore();
	const canvasNavigation = ref<HTMLElement>();
	const canvasNavigationIsOpen = ref(
		isSlidesgoMode.value || projectIsPresentation.value || (project.pages.length > 1 && !isMobile.value)
	);

	const calculatedWidth = computed(() => (store.editPanel ? ADJUSTED_WIDTH : 'w-full'));

	watch(
		() => project.pages.length,
		() => {
			if ((project.pages.length > 1 || isSlidesgoMode.value) && !isMobile.value) {
				canvasNavigationIsOpen.value = true;
			}
		},
		{ immediate: true }
	);

	//  Mostramos el navigation en caso de que el proyecto sea una presentación
	watch(projectIsPresentation, () => {
		if (projectIsPresentation.value) canvasNavigationIsOpen.value = true;
	});

	watch(canvasNavigationIsOpen, () => {
		transitionStart.trigger(canvasNavigationIsOpen);

		useEventListener(
			canvasNavigation.value,
			'transitionend',
			() => {
				transitionFinish.trigger(canvasNavigationIsOpen);
			},
			{ once: true }
		);
	});

	const toggleCanvasNavigation = () => {
		canvasNavigationIsOpen.value = !canvasNavigationIsOpen.value;
	};

	return {
		canvasNavigation,
		canvasNavigationIsOpen,
		toggleCanvasNavigation,
		transitionStart,
		transitionFinish,
		calculatedWidth,
	};
});
