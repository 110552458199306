import { ColorKeysType, RGB, RGBA } from '@/apps/mockup/schemas';
import { ColorData } from '@/apps/mockup/Types/basicTypes';
import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';

export const BLEEDING_VALUE = 40;
export const ColorDataHandler = (color: ColorData) => {
	const keys: ColorKeysType[] = Object.keys(color) as ColorKeysType[];

	if (keys.length != 1) console.warn(`Color format corrupted: ${color}`);

	const colorKey = keys[0];
	switch (colorKey) {
		case ColorKeysType.hex:
			return SolidColor.fromString(color[colorKey] as string);
			break;

		case ColorKeysType.rgb:
			return SolidColorFromRGB(color[colorKey] as RGB);
			break;

		case ColorKeysType.rgba:
			return SolidColorFromRGBA(color[colorKey] as RGBA);
			break;
	}
};

const SolidColorFromRGB = (objectData: RGB) =>
	new SolidColor(objectData.r * 255, objectData.g * 255, objectData.b * 255, 1);

const SolidColorFromRGBA = (objectData: RGBA) =>
	new SolidColor(objectData.r * 255, objectData.g * 255, objectData.b * 255, objectData.a);

export const buildLinearGradientWith = (
	gradientColor: GradientColor,
	inCtx: CanvasRenderingContext2D
): CanvasGradient => {
	const originalCanvas = inCtx.canvas;

	const rotationRadians = (gradientColor.rotation * Math.PI) / 180;
	const x1 = originalCanvas.width / 2 - (originalCanvas.width / 2) * Math.cos(rotationRadians);
	const y1 = originalCanvas.height / 2 - (originalCanvas.width / 2) * Math.sin(rotationRadians);

	const x2 = originalCanvas.width / 2 + (originalCanvas.width / 2) * Math.cos(rotationRadians);
	const y2 = originalCanvas.height / 2 + (originalCanvas.width / 2) * Math.sin(rotationRadians);
	const linearGradient = inCtx.createLinearGradient(x1, y1, x2, y2);

	for (const stop of gradientColor.stops) {
		linearGradient.addColorStop(stop.offset / 100, `rgb(${stop.r},${stop.g},${stop.b},${stop.a} )`);
	}
	return linearGradient;
};

export const buildRadialGradientWith = (
	gradientColor: GradientColor,
	inCtx: CanvasRenderingContext2D
): CanvasGradient => {
	const originalCanvas = inCtx.canvas;
	const linearGradient = inCtx.createRadialGradient(
		originalCanvas.width / 2,
		originalCanvas.height / 2,
		0,
		originalCanvas.width / 2,
		originalCanvas.height / 2,
		originalCanvas.width
	);

	for (const stop of gradientColor.stops) {
		linearGradient.addColorStop(stop.offset / 100, `rgb(${stop.r},${stop.g},${stop.b},${stop.a} )`);
	}

	return linearGradient;
};
