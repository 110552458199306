<script setup lang="ts">
import { Ref, ref } from 'vue';

import { mockupError, useErrorMockup } from '@/apps/mockup/composable/useErrorMockup';
import { MockupErrorFlag } from '@/apps/mockup/Types/errorTypes';
import SvgIcon from '@/common/components/SvgIcon.vue';
import Modal from '@/common/components/Modal.vue';
import {useRouterMockup} from "@/apps/mockup/composable/useRouterMockup";

export interface ModalError {
	show: CallableFunction;
}

const modal: Ref<any> = ref({});
const useError = useErrorMockup();
const {getRouteHref, redirectRoute} = useRouterMockup();

const secondaryButtonAction = () => {
	if (mockupError.type == MockupErrorFlag.error) window.location.reload();
};

const primaryButtonAction = () => {
	if (mockupError.type == MockupErrorFlag.error) {
		redirectRoute(getRouteHref('search'))
	}
};

const show = () => {
	mockupError.visible = true;
	return new Promise((res, rej) => {
		modal.value.close = () => {
			let response = {
				success: false,
			};
			res(response);
		};
		modal.value.accept = () => {
			let response = {
				success: true,
			};
			res(response);
		};
	});
};

defineExpose({
	show,
});

</script>

<template>
		<Modal v-if="mockupError.visible" content-classes="w-screen" open class="flex items-center justify-center" @close="false">
			<div class="self-center rounded-xl bg-white backdrop-blur-xl w-96 p-6">
				<div class="flex h-full w-full gap-2 justify-center items-center">
					<SvgIcon name="alert" class="h-9 w-9 duration-200" />
					<h3 class="w-full text-2xl font-bold text-black">
						{{ mockupError.title }}
					</h3>
				</div>
				<div class="flex w-full justify-start flex-col">
					<div class="w-full pt-2 pb-4 text-sm font-light text-black">
						{{ mockupError.description }}
					</div>
					<div class="flex w-full justify-between gap-4 pt-6">
						<div
							class="w-auto cursor-pointer rounded bg-white border border-neutral-200 px-4 py-1 text-center text-black duration-200 hover:bg-neutral-100"
							@click="primaryButtonAction"
						>
							{{ mockupError.successButton }}
						</div>
						<div
							class="w-auto cursor-pointer rounded bg-pikiBlue px-4 py-1 text-center text-white duration-200 hover:bg-pikiBlue/80"
							@click="secondaryButtonAction"
						>
							{{ mockupError.discardButton }}
						</div>
					</div>
				</div>
			</div>
		</Modal>
</template>

