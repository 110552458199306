import { UseFetchOptions, UseFetchReturn } from '@vueuse/core';
import { Ref, ref } from 'vue';

import { concatenatedFlaticonResponseMapper, splittedFlaticonResponseMapper } from '@/api/ClientApiMapper';
import { useEditorApiFetch } from '@/api/composables/useEditorApiFetch';
import { useEmptyDataOnUrlParamChange } from '@/api/composables/useEmptyDataOnUrlParamChange';
import { FlaticonElementApi, MappedApiResponse } from '@/Types/apiClient';

export const getConcatenatedFlaticon = (
	url: Ref<string>,
	options: UseFetchOptions = {}
): UseFetchReturn<MappedApiResponse<FlaticonElementApi[]>> => {
	const concatenatedData = ref<FlaticonElementApi[]>([]);

	useEmptyDataOnUrlParamChange(url, concatenatedData);

	return useEditorApiFetch(url, {
		...options,
		afterFetch: (ctx) => concatenatedFlaticonResponseMapper(ctx, concatenatedData, url),
	})
		.get()
		.json();
};

export const getSplittedFlaticon = (
	url: Ref<string>,
	options: UseFetchOptions = {}
): UseFetchReturn<MappedApiResponse<FlaticonElementApi[]>> => {
	return useEditorApiFetch(url, {
		...options,
		afterFetch: (ctx) => splittedFlaticonResponseMapper(ctx, url),
	})
		.get()
		.json();
};
