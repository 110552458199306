import { AfterFetchContext } from '@vueuse/core';
import { Ref } from 'vue';

import { useSetNextPageInURL } from '@/api/composables/useSetNextPageInURL';
import { useEnvMockupSettings } from '@/apps/mockup/composable/useEnvMockupSettings';
import { useEnvSettings } from '@/common/composables/useEnvSettings';
import {
	BasicShapeApi,
	CommonRawResponseApi,
	FlaticonCategoryApi,
	FlaticonElementApi,
	FreepikRawResponseApi,
	ImageApi,
	MappedApiResponse,
	MappedFreepikApiResponse,
	MaskApi,
	MockupApi,
	StickersCategoryApi,
	StickersElementApi,
	StorysetApi,
	TemplateApiData,
} from '@/Types/apiClient';

export const concatenatedUserCreationsResponseMapper = (ctx: AfterFetchContext, concatenatedData: Ref<any[]>) => {
	const { APP_API_PATH } = useEnvSettings();
	if (ctx.data.links.next) {
		ctx.data.links.next = ctx.data.links.next.split('api/')[1];
	}

	const nextPage = parseFloat(new URLSearchParams((ctx.data.links.next || '').split('?')[1]).get('page') || '2');

	// Si estamos en la página 1 y hay contenido lo eliminamos
	if (nextPage === 2 && ctx.data.length) {
		concatenatedData.value = [];
	}

	// Concatenate data from api for InfiniteLoading
	concatenatedData.value = concatenatedData.value.concat(ctx.data.data);

	// Fixed api context
	ctx.data.data = concatenatedData.value;

	return ctx;
};

export const concatenatedTemplatesResponseMapper = (
	ctx: AfterFetchContext,
	concatenatedData: Ref<(TemplateApiData | BasicShapeApi | MaskApi | StickersCategoryApi)[]>
): AfterFetchContext<MappedApiResponse<TemplateApiData | BasicShapeApi | MaskApi | StickersCategoryApi>> => {
	const { APP_API_PATH } = useEnvSettings();

	if (ctx.data.data?.length) {
		concatenatedData.value = concatenatedData.value.concat(ctx.data.data);
	}
	ctx.data.data = concatenatedData.value;
	if (ctx.data.links?.next) {
		ctx.data.links.next = ctx.data.links.next.replace(APP_API_PATH, '');
	}

	return ctx;
};

export const splittedTemplatesResponseMapper = (
	ctx: AfterFetchContext
): AfterFetchContext<MappedApiResponse<BasicShapeApi | MaskApi>> => {
	const { APP_API_PATH } = useEnvSettings();
	// Fixed api context
	if (ctx.data.links.next) {
		ctx.data.links.next = ctx.data.links.next.replace(APP_API_PATH, '');
	}
	return ctx;
};

export const concatenatedFlaticonResponseMapper = (
	ctx: AfterFetchContext,
	concatenatedData: Ref<(FlaticonCategoryApi | FlaticonElementApi | StickersElementApi)[]>,
	url: Ref<string>
): AfterFetchContext<MappedApiResponse<FlaticonCategoryApi | FlaticonElementApi | StickersElementApi>> => {
	// Concatenate data from api for InfiniteLoading
	concatenatedData.value = concatenatedData.value.concat(ctx.data);

	// Set pagination regarding page param in url
	ctx.data = {
		data: concatenatedData.value,
		links: {
			next: useSetNextPageInURL<FlaticonElementApi>(url, ctx.data).value,
		},
	};

	// Fixed api context
	return ctx;
};

export const splittedFlaticonResponseMapper = (ctx: AfterFetchContext, url: Ref<string>) => {
	// Set pagination regarding page param in url
	ctx.data = {
		data: ctx.data,
		links: {
			next: useSetNextPageInURL<FlaticonElementApi>(url, ctx.data).value,
		},
	};

	// Fixed api context
	return ctx;
};

export const imageResponseMapper = (
	ctx: AfterFetchContext<CommonRawResponseApi<ImageApi> | MappedApiResponse<ImageApi>>,
	concatenatedData: Ref<ImageApi[]>,
	url: Ref<string>
): AfterFetchContext<MappedApiResponse<ImageApi>> => {
	const { APP_API_PATH } = useEnvSettings();

	if (ctx.data && 'data' in ctx.data) {
		if (ctx.data?.links?.next) {
			ctx.data.links.next = ctx.data.links.next.replace(APP_API_PATH, '');
		}

		if (ctx.data.data) {
			concatenatedData.value = concatenatedData.value.concat(ctx.data.data);
		}

		ctx.data.data = concatenatedData.value;
	}

	if (ctx.data && 'images' in ctx.data) {
		// Concatenate data from api for InfiniteLoading
		if (ctx.data.images && Array.isArray(ctx.data.images)) {
			concatenatedData.value = concatenatedData.value.concat(ctx.data.images);
		}

		let next = '';
		if (ctx.data.images?.length) {
			next = useSetNextPageInURL<ImageApi>(url, ctx.data?.images).value;
		}

		// Images api returns nextPageId:number instead of links[prev, next] as the other cases
		// so we need to set it correctly
		ctx.data = {
			data: concatenatedData.value,
			links: {
				next,
			},
		};
	}

	// Fixed api context
	return ctx as AfterFetchContext<MappedApiResponse<ImageApi>>;
};

/**
 * Maps the response from the API to the desired format for MockupApi.
 * @param ctx - The context object containing the response data.
 * @param concatenatedData - The reference to the concatenated data array.
 * @param url - The reference to the URL.
 * @returns The mapped response object.
 */
export const mockupResponseMapper = (
	ctx: AfterFetchContext<FreepikRawResponseApi<MockupApi> | MappedFreepikApiResponse<MockupApi>>,
	concatenatedData: Ref<MockupApi[]>,
	url: Ref<string>
): AfterFetchContext<MappedFreepikApiResponse<MockupApi>> => {
	const { APP_API_MOCKUPS_PATH } = useEnvMockupSettings();

	if (ctx.data && 'data' in ctx.data) {
		if (ctx.data?.links?.next) {
			ctx.data.links.next = ctx.data.links.next.replace(APP_API_MOCKUPS_PATH, '');
		}

		ctx.data.data && ctx.data.links?.prev
			? (concatenatedData.value = concatenatedData.value.concat(ctx.data.data))
			: (concatenatedData.value = ctx.data.data as MockupApi[]);

		ctx.data.data = concatenatedData.value;
	}

	if (ctx.data && 'mockups' in ctx.data) {
		// Concatenate data from api for InfiniteLoading
		if (ctx.data.mockups && Array.isArray(ctx.data.mockups)) {
			concatenatedData.value = concatenatedData.value.concat(ctx.data.mockups as MockupApi[]);
		}

		let next = '';
		if (ctx.data.mockups?.length) {
			next = useSetNextPageInURL<MockupApi>(url, ctx.data?.mockups).value;
		}

		ctx.data = {
			data: concatenatedData.value,
			links: {
				next,
			},
		};
	}

	// Fixed api context
	return ctx as AfterFetchContext<MappedFreepikApiResponse<MockupApi>>;
};

export const concatenatedStorysetsResponseMapper = (
	ctx: AfterFetchContext,
	concatenatedData: Ref<StorysetApi[]>,
	url: Ref<string>
): AfterFetchContext<MappedApiResponse<StorysetApi>> => {
	// Concatenate data from api for InfiniteLoading
	concatenatedData.value = concatenatedData.value.concat(ctx.data.data);
	ctx.data.data = concatenatedData.value;

	// Set pagination regarding page param in url
	let next = '';
	if (ctx.data.data?.length) {
		next = useSetNextPageInURL<StorysetApi>(url, ctx.data.data).value;
	}
	ctx.data.links.next = next;

	// Fixed api context
	return ctx;
};

export const splittedStorysetsResponseMapper = (
	ctx: AfterFetchContext,
	url: Ref<string>
): AfterFetchContext<MappedApiResponse<StorysetApi>> => {
	if (ctx.data.data.length === 0) {
		ctx.data.links.next = '';
		return ctx;
	}

	// Set pagination regarding page param in url
	let next = '';
	if (ctx.data.data?.length) {
		next = useSetNextPageInURL<StorysetApi>(url, ctx.data.data).value;
	}
	ctx.data.links.next = next;

	// ctx.data.links.next = getNextUrl(url.value);

	// Fixed api context
	return ctx;
};
