import { MockupItemResponse, MockupSearchItem } from '@/apps/mockup/Types/SearchMockups';
import { IFilterCategories } from '@/apps/mockup/Types/filtersSearch';

export default class NormalizeEditorResponse {
	public static MockupItemSearchResponse(data: any): MockupSearchItem {
		return {
			sku: data.sku,
			name: data.name,
			type: data.type,
			rating: data.rating,
			mediaPath: data.media_path,
			isPremium: data.premium,
		};
	}

	public static MockupItemResponse(response: any): MockupItemResponse {
		return {
			data: response.data,
			provider_info: response.provider_info,
			status: response.status,
		};
	}

	public static MockupSearchCategory(data: any): IFilterCategories[] {
		const finalData: IFilterCategories[] = [];
		data.categories.map((category: IFilterCategories) => {
			finalData.push({ id: category.id, name: category.name });
		});

		return finalData;
	}
}
