import { Element as SvgElement, SVG } from '@svgdotjs/svg.js';
import Normalize from 'color-normalize';
import { v4 as uuidv4 } from 'uuid';

import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import Element from '@/elements/element/classes/Element';
import ElementTools from '@/elements/element/utils/ElementTools';
import { PurifyUnserialize } from '@/elements/element/utils/PurifyUnserialize';
import { Color } from '@/Types/colorsTypes';
import { LineDTO } from '@/Types/elements';
import { Linecap, Marker, SerializedClass } from '@/Types/types';
import MathTools from '@/utils/classes/MathTools';

class Line extends Element {
	type: 'line' = 'line';
	mainColor: Color;
	linecap: Linecap;
	dasharray: number[];
	markerStart: Marker | null;
	markerEnd: Marker | null;

	protected constructor(lineDTO: LineDTO) {
		super(lineDTO);

		this.mainColor = lineDTO.mainColor;
		this.linecap = lineDTO.linecap;
		this.dasharray = lineDTO.dasharray;
		this.markerStart = lineDTO.markerStart;
		this.markerEnd = lineDTO.markerEnd;
	}

	@PurifyUnserialize()
	static unserialize(data: SerializedClass<Line>): Line {
		const lineDTO = {
			...Line.defaults(),
			...data,
		} as LineDTO;

		// Parsed data.subElements when needed
		lineDTO.subElements = new Map<string, Element>();

		if (data.mainColor) {
			lineDTO.mainColor =
				'stops' in data.mainColor
					? GradientColor.unserialize(data.mainColor as GradientColor)
					: SolidColor.unserialize(data.mainColor as SolidColor);
		}

		if (data.markerStart && data.markerStart.color) {
			lineDTO.markerStart = {
				...data.markerStart,
				color:
					'stops' in data.markerStart.color
						? GradientColor.unserialize(data.markerStart.color)
						: SolidColor.unserialize(data.markerStart.color),
			};
		}

		if (
			data.markerEnd &&
			(!Array.isArray(data.markerEnd) || (Array.isArray(data.markerEnd) && data.markerEnd.length))
		) {
			lineDTO.markerEnd = {
				...data.markerEnd,
				color:
					'stops' in data.markerEnd.color
						? GradientColor.unserialize(data.markerEnd.color)
						: SolidColor.unserialize(data.markerEnd.color),
			};
		}

		const elem = new Line(lineDTO);

		if (data.id) {
			elem.id = data.id;
		}

		return elem;
	}

	static defaults(): LineDTO {
		return {
			// Element
			...Element.defaults(),
			type: 'line',
			// Line
			mainColor: SolidColor.black(),
			linecap: 'butt' as Linecap,
			dasharray: [0],
			markerStart: null,
			markerEnd: null,
		};
	}

	static create(data: Partial<LineDTO> = {}) {
		const lineDTO = {
			...Line.defaults(),
			...data,
		};

		return new Line(lineDTO);
	}

	updateStrokeColor(newColor: Color) {
		newColor.id = this.mainColor.id;
		this.mainColor = newColor;
	}

	updateMarkerStartColor(newColor: Color) {
		if (this.markerStart) {
			newColor.id = this.markerStart.color.id;
			this.markerStart.color = newColor;
		}
	}

	updateMarkerEndColor(newColor: Color) {
		if (this.markerEnd) {
			newColor.id = this.markerEnd.color.id;
			this.markerEnd.color = newColor;
		}
	}

	dasharrayToString() {
		const dashArray = Array.isArray(this.dasharray) ? this.dasharray : Object.values(this.dasharray);
		return dashArray.join(',');
	}

	static fromSvg(rawSvg: string) {
		rawSvg = rawSvg.substring(rawSvg.indexOf('<svg'));

		const el = SVG(rawSvg);

		const realLine = el.findOne('line') as SvgElement;

		const x1 = parseFloat((realLine.attr('x1') || '0').toString());
		const y1 = parseFloat((realLine.attr('y1') || '0').toString());
		const x2 = parseFloat((realLine.attr('x2') || '0').toString());
		const y2 = parseFloat((realLine.attr('y2') || '0').toString());

		const [r, g, b] = Normalize(realLine.css('stroke'));
		const strokeOpacity = parseFloat(realLine.css('opacity') || '1');
		const height = parseFloat(realLine.css('stroke-width').toString() || '1');
		const width = MathTools.getDistanceBetween2Points(x1, y1, x2, y2);
		const rotation = MathTools.getAngle(x1, y1, x2, y2);
		const linecap = (realLine.css('stroke-linecap').toString() || 'butt') as Linecap;
		const dasharrayParsed = realLine.css('stroke-dasharray').toString().replaceAll(' ', '');
		let dasharray =
			dasharrayParsed.length > 0 ? dasharrayParsed.split(',').map((dash) => parseFloat(dash) / height) : [0];

		dasharray = ElementTools.fixDashArray(dasharray);

		const mainColor = SolidColor.fromObject({
			r: r * 255,
			g: g * 255,
			b: b * 255,
			a: strokeOpacity,
		});

		// Calculamos la posición en base a la rotación
		const origin = {
			x: x1 + width / 2,
			y: y1 + height / 2,
		};
		const rotatePosition = MathTools.rotatePoint(origin.x, origin.y, x1, y1, rotation);
		const diff = {
			x: rotatePosition.x - x1,
			y: rotatePosition.y - y1,
		};

		// Buscamos los markers de inicio y fin
		let markerStart = null;
		let markerEnd = null;

		el.find('[id]').forEach((element) => element.id(element.id().toString().toLocaleLowerCase()));

		el.find('[id*="sticky"]').forEach((sticky) => {
			const stickyX = parseFloat(sticky.x().toString());
			const stickyHeight = parseFloat(sticky.height().toString());
			const stickyWidth = parseFloat(sticky.width().toString());
			const rotation = parseFloat(sticky.transform('rotate').toString());

			const fill = el.css('fill') || '#000000';
			const opacity = parseFloat(el.css('opacity') || '1');
			const [r, g, b] = Normalize(fill);
			const color = SolidColor.fromObject({
				r: r * 255,
				g: g * 255,
				b: b * 255,
				a: opacity,
			});

			// Escalamos y posicionamos el marker para que cuadre con next-gen
			sticky.x(0);
			sticky.y(0);
			sticky.height(stickyHeight / height);
			sticky.width(stickyWidth / height);
			sticky.attr('rx', sticky.attr('rx') / height);
			sticky.id('');

			// Determinamos que marker es el del principio y cual es el del final,
			// también ajustamos su posición para que cuadre con al línea
			if (stickyX < parseFloat(realLine.width().toString()) / 2) {
				sticky.css('fill', 'var(--start-sticky-color)');
				sticky.transform({
					translateX: -(stickyWidth / height / 2),
					translateY: -(stickyHeight / height / 2),
					rotate: rotation,
				});

				markerStart = {
					element: sticky.node.outerHTML.toString(),
					color,
				};
			}

			if (stickyX >= parseFloat(realLine.width().toString()) / 2) {
				sticky.css('fill', 'var(--end-sticky-color)');
				sticky.transform({
					translateX: -(stickyWidth / height / 2),
					translateY: -(stickyHeight / height / 2),
					rotate: rotation,
				});

				markerEnd = {
					element: sticky.node.outerHTML.toString(),
					color,
				};
			}
		});

		return Line.create({
			size: {
				height,
				width,
			},
			position: {
				x: x1 - diff.x,
				y: y1 - diff.y,
			},
			rotation,
			mainColor,
			linecap,
			dasharray,
			markerStart,
			markerEnd,
			opacity: 1,
		});
	}

	static defaultDasharrays() {
		return [[0], [6, 3], [6, 6], [1, 4]];
	}

	getColors(): Color[] {
		const result = [this.mainColor];

		if (this.markerStart) {
			result.push(this.markerStart.color);
		}

		if (this.markerEnd) {
			result.push(this.markerEnd.color);
		}

		return result;
	}

	get colors(): Color[] {
		const colors = [this.mainColor];

		if (this.markerStart) {
			colors.push(this.markerStart.color);
		}

		if (this.markerEnd) {
			colors.push(this.markerEnd.color);
		}

		return colors;
	}

	clone(): this {
		const element = super.clone();

		// modificamos los ids a los colores de la copia
		element.mainColor.id = 'color-' + uuidv4();

		if (element.markerStart) {
			element.markerStart.color.id = 'color-' + uuidv4();
		}

		if (element.markerEnd) {
			element.markerEnd.color.id = 'color-' + uuidv4();
		}

		return element;
	}
}

export default Line;
