
export type MockupImageType = 'layer' | 'editorImage' | 'pattern' | 'interactiveZone'
export type MockupImage = {
	domElement: HTMLImageElement | HTMLCanvasElement;
	type: MockupImageType
}
class MockupResourcesSingleton {
	private static instance: MockupResourcesSingleton | null = null;

	private _images: Map<string, MockupImage> = new Map()
	private _canvas: Map<string, MockupImage> = new Map()

	public static getInstance(): MockupResourcesSingleton {
		if (!MockupResourcesSingleton.instance) {
			MockupResourcesSingleton.instance = new MockupResourcesSingleton();
		}
		return MockupResourcesSingleton.instance;
	}

	public setImage(id: string,image: MockupImage) {
		this._images.set(id,image)
	}
	public getImage(id: string): MockupImage | undefined {
		if (!this._images.has(id))
			console.warn(`Image with id ${id} not found`)
		return this._images.get(id)
	}

	public setCanvas(id: string, canvas: MockupImage) {
		this._canvas.set(id,canvas)
	}

	public getCanvas(id: string): MockupImage | undefined {
		return this._canvas.get(id)
	}

	get canvas(): Map<string, MockupImage> {
		return this._canvas;
	}

	get images(): Map<string, MockupImage> {
		return this._images;
	}

	public deleteImage(id: string): void {
		if (!this._images.has(id)) return
		this._images.delete(id)
	}
}
export const MockupResources = MockupResourcesSingleton.getInstance();
