import { SolidColor } from '@/color/classes/SolidColor';
import { Duotone } from '@/elements/medias/filter/classes/FilterPresets';
import { GradientOverlay, Overlay, SolidOverlay } from '@/elements/medias/filter/classes/Overlay';
import FiltersTools from '@/elements/medias/filter/utils/FiltersTools';
import { SerializableClass } from '@/utils/classes/SerializableClass';

interface FilterDTO {
	contrast?: number | null;
	brightness?: number | null;
	saturate?: number | null;
	sepia?: number | null;
	grayscale?: number | null;
	invert?: number | null;
	hueRotate?: number | null;
	duotone?: Duotone | null;
	blur?: number | null;
	overlay?: GradientOverlay | SolidOverlay | null;
}

export class Filter extends SerializableClass {
	name: string | null;
	brightness: number | null;
	contrast: number | null;
	saturate: number | null;
	sepia: number | null;
	grayscale: number | null;
	invert: number | null;
	hueRotate: number | null;
	duotone: Duotone | null;
	blur: number | null;
	overlay: GradientOverlay | SolidOverlay | null;

	constructor(filterDTO?: FilterDTO) {
		super();

		this.name = '';
		this.brightness = filterDTO?.brightness || null;
		this.contrast = filterDTO?.contrast || null;
		this.saturate = filterDTO?.saturate || null;
		this.sepia = filterDTO?.sepia || null;
		this.grayscale = filterDTO?.grayscale || null;
		this.invert = filterDTO?.invert || null;
		this.hueRotate = filterDTO?.hueRotate || null;
		this.blur = filterDTO?.blur || null;
		this.duotone = filterDTO?.duotone || null;
		this.overlay = filterDTO?.overlay || null;
	}

	toCssFilter(): string[] {
		const filters: string[] = [];

		if (this.contrast && this.contrast !== 100) filters.push(`contrast(${this.contrast}%)`);
		if (this.brightness && this.brightness !== 100) filters.push(`brightness(${this.brightness}%)`);
		if (this.saturate && this.saturate !== 100) filters.push(`saturate(${this.saturate}%)`);
		if (this.sepia) filters.push(`sepia(${this.sepia}%)`);
		if (this.grayscale) filters.push(`grayscale(${this.grayscale}%)`);
		if (this.invert) filters.push(`invert(${this.invert}%)`);
		if (this.hueRotate) filters.push(`hue-rotate(${this.hueRotate}deg)`);
		if (this.duotone) {
			const duotoneString = JSON.stringify(this.duotone);
			filters.push(`duotone(${duotoneString})`);
		}
		if (this.blur) filters.push(`blur(${this.blur}px)`);

		return filters;
	}

	toSvgFilter(): string {
		const filters = this.toCssFilter().map((f) => FiltersTools.convert(f)?.filtersSVG?.join(''));

		if (filters.length === 0) {
			return '';
		}

		return filters.join('\n');
	}

	scaleBy(scale: number) {
		if (this.blur) this.blur *= scale;
		if (this.overlay) this.overlay.scaleBy(scale);
	}

	static unserialize(data: FilterDTO): Filter {
		//  ! fix temporal
		const overlayAsEmptyArray = Array.isArray(data.overlay) && !data.overlay.length;

		const filterDTO = {
			...data,
			overlay: !overlayAsEmptyArray && data.overlay ? Overlay.unserialize(data.overlay) : undefined,
			duotone: data.duotone
				? {
						shadows: SolidColor.unserialize(data.duotone.shadows as SolidColor),
						highlights: SolidColor.unserialize(data.duotone.highlights as SolidColor),
				  }
				: undefined,
		} as FilterDTO;

		return new Filter(filterDTO);
	}
}
