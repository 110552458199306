import { ref } from 'vue';

const toLoad = ref(0);

export const ImagesLoader = (src: string) => {
	const image = new Image();
	image.src = src;
	toLoad.value++;
	image.crossOrigin = 'Anonymous';
	image.onload = () => {
		toLoad.value--;
	};
	image.onerror = () => {
		console.error('Error loading image: ', src)
		toLoad.value--;
	}
}
export const ImagesToLoad = toLoad;
