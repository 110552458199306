//	3D Render
export interface RenderData {
	id: string;
	camera: CameraData;
	sceneFileURL: string;
	texture: HTMLCanvasElement;
	targetMaterialName: string;
}

export interface CameraData {
	frameWidth: number;
	frameHeight: number;
	name: string;
}

export interface WarpObject {
	id: string;
	horizontalPoints: number[];
	verticalPoints: number[];
}

export interface Mockup3DScene {
	id: string;
	scene: THREE.Scene;
	camera: THREE.PerspectiveCamera | THREE.OrthographicCamera;
	type: 'OM' | 'WARP';
	size: { width: number; height: number };
}
export type BlendRange = {
	channel: 'gray' | 'red' | 'green' | 'blue';
	desaturate: number;
	destBlackMax: number;
	destBlackMin: number;
	destWhiteMin: number;
	srcBlackMax: number;
	srcBlackMin: number;
	srcWhiteMax: number;
	srcWhiteMin: number;
};
//Compostion types
export enum LayerType {
	solidColor = 'solidColor',
	group = 'group',
	image = 'image',
	smartObject = 'smartObject',
}
export interface Resource {
	id: string;
	path: string;
}

export interface Color {
	hex: string;
	rgb: RGB;
}

export interface RGB {
	r: number;
	g: number;
	b: number;
}

export interface RGBA extends RGB {
	a: number;
}

/**
 * Dictionary to parse Mockupmator Studio to Mockupmator
 * Left side is a value received and Right value is the Global Composite Operation mode
 */

export enum WebGLBlendMode {
	linearBurn,
	multiply,
	screen,
	darken,
	lighten,
	colorDodge,
	colorBurn,
	hardLight,
	softLight,
	difference,
	exclusion,
	hue,
	saturation,
	color,
	luminosity,
	linearDodge,
}

export enum BlendMode {
	normal = 'source-over',
	linearBurn = 'linearBurn',
	colorBurn = 'color-burn',
	passThrough = 'source-over',
	screen = 'screen',
	multiply = 'multiply',
	'source-in' = 'source-in',
	'source-over' = 'source-over',
	'source-out' = 'source-out',
	'source-atop' = 'source-atop',
	'destination-over' = 'destination-over',
	'destination-in' = 'destination-in',
	'destination-out' = 'destination-out',
	'destination-atop' = 'destination-atop',
	lighter = 'lighter',
	copy = 'destination-lighter',
	xor = 'xor',
	overlay = 'overlay',
	darken = 'darken',
	lighten = 'lighten',
	'color-dodge' = 'color-dodge',
	'hard-light' = 'hard-light',
	'soft-light' = 'soft-light',
	difference = 'difference',
	exclusion = 'exclusion',
	hue = 'hue',
	saturation = 'saturation',
	color = 'color',
	luminosity = 'luminosity',
}

export interface GPUBlendModes {
	linearBurn: VoidFunction;
	normal: VoidFunction;
	add: VoidFunction;
	multiply: VoidFunction;
	subtract: VoidFunction;
	darken: VoidFunction;
	lighten: VoidFunction;
	divide: VoidFunction;
	screen: VoidFunction;
	overlay: VoidFunction;
	hardLight: VoidFunction;
	colorBurn: VoidFunction;
	colorDodge: VoidFunction;
	softLight: VoidFunction;
	difference: VoidFunction;
	exclusion: VoidFunction;
	hardMix: VoidFunction;
	lighterColor: VoidFunction;
	darkerColor: VoidFunction;
	pinLight: VoidFunction;
	vividLight: VoidFunction;
	linearLight: VoidFunction;
	hue: VoidFunction;
	saturation: VoidFunction;
	color: VoidFunction;
	luminosity: VoidFunction;
}

export interface Size {
	width: number;
	height: number;
}

export interface NewCustomizations {
	customization_name: string;
	customizations: Array<{
		uuid: string;
		type: 'texture' | 'color';
		value: any;
	}>;
}

export interface LastCustomization {
	uuid_manifest: string;
	value: string;
	type: 'color' | 'texture';
}
