import { createFetch } from '@vueuse/core';

import { useEnvSettings } from '@/common/composables/useEnvSettings';

const { APP_BASE, APP_API_VERSION } = useEnvSettings();
export const useEditorWebFetch = createFetch({
	baseUrl: APP_BASE,
	options: {
		beforeFetch({ options }) {
			const customHeaders = options.headers ? new Headers(options.headers) : new Headers();
			customHeaders.append('X-Requested-With', APP_API_VERSION);

			const xsrf = decodeURIComponent(`; ${document.cookie}`.split(`; XSRF-TOKEN=`).pop()?.split(';')[0] || '');
			// Session Token
			if (xsrf) {
				customHeaders.append('X-XSRF-TOKEN', xsrf);
			}

			options.credentials = 'include';

			options.headers = customHeaders;

			return { options };
		},
	},
});
