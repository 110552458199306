import Element from '@/elements/element/classes/Element';
import { Croppeable } from '@/elements/medias/crop/types/croppeable.type';
import { Crop } from '@/elements/medias/crop/types/croppeable.type';
import { Filter } from '@/elements/medias/filter/classes/Filter';
import { BaseImageDTO } from '@/Types/elements';

abstract class BaseImage extends Element implements Croppeable {
	type: 'image' | 'foregroundImage' = 'image';
	url: string;
	preview: string | null;
	urlBackgroundRemoved: string | null;
	filter: Filter | null;
	crop: Crop;

	protected constructor(baseImageDTO: BaseImageDTO) {
		super(baseImageDTO);

		this.url = baseImageDTO.url;
		this.preview = baseImageDTO.preview;
		this.filter = baseImageDTO.filter;
		this.crop = baseImageDTO.crop;
		this.urlBackgroundRemoved = baseImageDTO.urlBackgroundRemoved;
	}

	static defaults(): BaseImageDTO {
		return {
			// Element
			...Element.defaults(),
			type: 'image',
			keepProportions: true,
			// Image
			url: 'https://wepik.com/svg/mask-placeholder.svg',
			preview: null,
			filter: null,
			crop: {
				size: {
					width: 0,
					height: 0,
				},
				position: {
					x: 0,
					y: 0,
				},
			},
			urlBackgroundRemoved: null,
		};
	}

	public hasCrop() {
		return this.crop?.size.width !== 0 && this.crop?.size.height !== 0;
	}

	public scaleBy(scale: number) {
		super.scaleBy(scale);
		this.crop.size.width *= scale;
		this.crop.size.height *= scale;
		this.crop.position.x *= scale;
		this.crop.position.y *= scale;
	}

	public setFilter(value: Filter | null) {
		this.filter = value;
	}
}

export default BaseImage;
