import { Element as SvgElement, SVG } from '@svgdotjs/svg.js';
import Normalize from 'color-normalize';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Ref } from 'vue';

import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import { Box } from '@/elements/box/classes/Box';
import Element from '@/elements/element/classes/Element';
import Line from '@/elements/line/classes/Line';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import { Color, StopGradient } from '@/Types/colorsTypes';

class ElementTools {
	static get defsTypes() {
		return ['linearGradient', 'radialGradient', 'filter', 'clipPath', 'mask'];
	}

	static getElementType(el: SvgElement) {
		if (el.data('stories')) return 'storyset';
		if (el.data('isline')) return 'line';
		if (el.data('type') && el.type !== 'rect') return el.data('type');
		if ((el.id().toString().includes('image') && el.find('image').length) || el.data('old-crop')) return 'image';
		if (el.type === 'g' && el.first().type === 'foreignObject') return 'g-text';
		if (el.type === 'rect') return 'box';

		return `native-${el.type}`;
	}

	static getIdFromUrl(attr: string): string {
		return attr.replace('url(', '').replace(')', '').replaceAll('"', '');
	}

	static getTransformValues(transform: string): number[] {
		// Obtenemos los números que hay en el attr
		return Array.from(transform.matchAll(/-?(\d+\.?\d*)/g), (coord) => parseFloat(coord[0]));
	}

	static svgGradientToObject(gradient: SvgElement): GradientColor {
		if (!gradient) {
			return GradientColor.defaultColor();
		}

		let deg;

		// Si existen coordenadas X e Y, priorizamos el ángulo de rotación obtenido de ellas y obtenemos los grados
		if (
			gradient.attr('x1') !== undefined &&
			gradient.attr('x2') !== undefined &&
			gradient.attr('y1') !== undefined &&
			gradient.attr('y2') !== undefined
		) {
			const scaleX = gradient.transform().a;
			const scaleY = gradient.transform().d;
			const deltaX = (gradient.attr('x2') - gradient.attr('x1')) * (scaleX ? scaleX : 1);
			const deltaY = (gradient.attr('y2') - gradient.attr('y1')) * (scaleY ? scaleY : 1);

			const rad = Math.atan2(deltaY, deltaX); // Radians
			deg = rad * (180 / Math.PI);
		} else {
			deg = gradient.transform().rotate || 0;
		}

		// Se suman 90 para que el grado 0 de SVG sea igual al grado 0 de CSS
		// OJO: Si se eliminan los 90 grados, el color preview y el elemento tendrán angulos distintos
		// Se redondeo porque para el parser de Slidesgo se están creando muchos gradientes por ser 359,9, 360, etc
		let rotation = Math.round(deg) + 90;

		// Para evitar valores negativos
		if (rotation < 0) {
			rotation += 360;
		}
		// Para que el ángulo 360 y el 0 sean iguales
		if (rotation >= 360) {
			rotation = rotation % 360;
		}

		const stops = Array.from(gradient.find('stop')).map((stop) => {
			const color = stop.css('stop-color') || stop.attr('stop-color');
			const opacity = stop.css('stop-opacity') || stop.attr('stop-opacity');

			const [r, g, b] = Normalize(color.toString());
			const offset = parseFloat(stop.attr('offset')) * 100;

			return {
				r: r * 255,
				g: g * 255,
				b: b * 255,
				a: opacity,
				offset,
			} as any as StopGradient;
		});

		return GradientColor.fromObject({
			type: gradient.type.includes('linear') ? 'linear' : 'radial',
			rotation,
			stops,
		});
	}

	static checkIfIsDefsElement(el: SvgElement) {
		const isDefs = el.type === 'defs' || el.node.closest('defs');
		const isGradient =
			el.type === 'linearGradient' ||
			el.type === 'radialGradient' ||
			el.type === 'stop' ||
			el.node.closest('linearGradient') ||
			el.node.closest('radialGradient');

		return !!isDefs || !!isGradient;
	}

	static fixDefsPosition(el: SvgElement) {
		const mainDefs = el.defs();
		el.id('main-defs');

		el.find(this.defsTypes.join(', ')).forEach((defs) => {
			defs.addTo(mainDefs);
		});

		el.find('defs').forEach((defs) => defs.remove());

		el.add(mainDefs);
	}

	static changeGradientsReferencesByCloneStops(el: SvgElement) {
		el.find('linearGradient, radialGradient').forEach((gradient) => {
			const gradientReferenceId = gradient.attr('xlink:href');
			if (!gradientReferenceId || gradientReferenceId === '#') return;

			const gradientReference = el.findOne(gradientReferenceId);

			if (!gradientReference || !gradientReference.children().length) {
				return;
			}

			gradientReference.children().forEach((stop) => {
				const clone = stop.clone();
				gradient.add(clone);
			});
		});
	}

	static fixDashArray(dasharray: number[]): number[] {
		const defaultDasharrays = Line.defaultDasharrays();
		const isValidDashArray = defaultDasharrays.find((el) => el.toString() === dasharray.toString());

		if (isValidDashArray) {
			return dasharray;
		}

		// Buscamos el valor más cercano a uno de los valores por defecto
		const diffs = defaultDasharrays.map((da) => {
			const diff1 = da[0] - (dasharray[0] || 0);
			const diff2 = da[1] - (dasharray[1] || 0);

			return diff1 + diff2;
		});
		const value = diffs.reduce(function (prev, curr) {
			return Math.abs(curr) < Math.abs(prev) ? curr : prev;
		}, Infinity);
		const index = diffs.findIndex((el) => el === value);

		return defaultDasharrays[index];
	}

	static getClassStyles(styleSheet: string, classAttr: any) {
		const classesName = classAttr.split(' ') as string[];

		// Regex: Pillamos cada clase del style ejemplo: .nombre1 { contenido } | .nombre2 { contenido }
		// Pillamos los 2 de forma separada
		const classesInStyle = Array.from(styleSheet.matchAll(/\.-?[_a-zA-Z]+[_a-zA-Z0-9-]*\s*\{.+?\}/g)).map((value) =>
			value.toString()
		);

		// Buscamos las clases que queremos
		const classesInUse = classesInStyle
			.filter((c) => classesName.some((cn) => c.includes(cn)))
			.map((c) => {
				const className = classesName.find((cn) => c.includes(cn));
				return {
					className,
					content: c.replace(`.${className}\{`, '').replace('}', ''),
				};
			});

		// Separamos con ; para pillar cada prop y después con : para distinguir
		// el nombre y el valor de la prop
		const classProps = classesInUse.flatMap((c) => {
			return c.content
				.split(';')
				.filter((el) => el.length)
				.map((prop) => {
					const [key, value] = prop.split(':');

					return {
						class: c.className,
						key: key.trim() as CSSStyleName,
						value: value?.trim().replaceAll('"', '').replaceAll("'", ''),
					};
				})
				.filter((prop) => prop.class && prop.key && prop.value);
		});

		return classProps;
	}

	/**
	 * Aplica el nuevo color global manteniendo la opacidad original en la medida
	 * de lo posible
	 * @param {Color} oldColor - Color original
	 * @param {Color} newColor - Color nuevo
	 * @returns Color nuevo adaptado
	 */
	static getFixedColor(oldColor: Color, newColor: Color): Color {
		if (newColor instanceof SolidColor && oldColor instanceof SolidColor) {
			const fixedColor = cloneDeep(newColor);
			fixedColor.a = oldColor.a;

			return fixedColor;
		}

		if (newColor instanceof GradientColor && oldColor instanceof GradientColor) {
			const fixedColor = cloneDeep(newColor);

			fixedColor.stops.forEach((newStop) => {
				// Buscamos el stop que tenga el mismo offset
				let originalStop = oldColor.stops.find((oldStop) => oldStop.offset === newStop.offset);

				// Si no lo encontramos buscamos el offset que ha variado
				if (!originalStop) {
					originalStop = oldColor.stops.find(
						(oldStop) => !fixedColor.stops.find((nStop) => nStop.offset === oldStop.offset)
					);
				}

				// Si no lo encontramos pasamos de el, ya que es un stop nuevo
				if (originalStop) {
					newStop.a = originalStop.a;
				}
			});

			return fixedColor;
		}

		return newColor;
	}

	/**
	 * Corrige los ids de los elementos repetidos
	 * @param {Element[]} elementCollection - Elementos de la colección
	 * @param {Element[]} comparableElements - Elementos a comparar
	 * @returns Elementos con los ids corregidos
	 * @memberof Utils
	 * @static
	 * @method fixRepeatedElementIds
	 * @returns {Element[]}
	 * @example
	 * const elements = Utils.fixRepeatedElementIds(elements, elements);
	 */
	static fixRepeatedElementIds(elementCollection: Element[], comparableElements: Element[]) {
		let result: Element[] = [];
		const elementsDictionary = new Map();
		const imagesDictionary = new Map();

		// Obtenemos los elementos repetidos
		const repeatedElements: Element[] = comparableElements.filter((el) => {
			return elementCollection.some((e) => e.id === el.id);
		});
		if (repeatedElements.length) {
			// Recorremos elementos sin los elementos que sean de tipo foreground y almacenamos el antiguo id y el nuevo id en un diccionario
			const elementsWithoutForeground: Element[] = repeatedElements.filter((el) => el.type !== 'foregroundImage');
			elementsWithoutForeground.forEach((el) => {
				const oldId = el.id;

				// Asignamos un nuevo id a cada elemento
				const newEl = el.clone();
				elementsDictionary.set(oldId, newEl.id);

				// Si es una imagen y tiene foreground, actualizamos el id de la imagen a la que hace referencia
				if (newEl.type === 'image') {
					imagesDictionary.set(oldId, newEl.id);
				}

				result.push(newEl);
			});

			// Recorremos elementos que sean de tipo foreground y almacenamos el antiguo id y el nuevo id en un diccionario
			let elementsForeground: ForegroundImage[] = [];
			if (elementsWithoutForeground.length < repeatedElements.length) {
				elementsForeground = repeatedElements.filter((el): el is ForegroundImage => el instanceof ForegroundImage);

				// Recorremos los foreground
				elementsForeground.forEach((el) => {
					const oldId = el.id;

					// Asignamos un nuevo id a cada elemento
					const newEl = el.clone();
					elementsDictionary.set(oldId, newEl.id);

					// Actualizamos el id de la imagen a la que hace referencia
					for (const [key, value] of imagesDictionary) {
						if (key === newEl.image) {
							newEl.image = value;
						}
					}

					result.push(newEl);
				});
			}

			// Ordenar un array por el id de los elementos que corresponden con un valor del diccionario elementsDictionary y la clave es un id del elemento comparable
			result = result.sort((a, b) => {
				const aIndex = comparableElements.findIndex((el) => elementsDictionary.get(el.id) === a.id);
				const bIndex = comparableElements.findIndex((el) => elementsDictionary.get(el.id) === b.id);

				return aIndex - bIndex;
			});

			// Actualizamos result para tener los mismos elementos que comparableElements pero los elementos que coincidan con el nuevo id de result lo reemplazamos por el elemento de result
			result = comparableElements.map((el) => {
				const newId = elementsDictionary.get(el.id);

				if (newId) {
					const newEl = result.find((e) => e.id === newId);

					if (newEl) {
						return newEl;
					}
				}

				return el;
			});
		}

		// Actualizamos los grupos de los elementos
		this.fixGroups(result);

		// Si no hay elementos repetidos devolvemos los comparableElements
		return result.length ? result : comparableElements;
	}

	// Actualiza los grupos de los elementos
	static fixGroups(elements: Element[]) {
		const updatedGroups: string[] = [];

		// Recorremos todos los elementos y filtramos los grupos
		// Si el elemento tiene grupo, buscamos todos los elementos con el mismo grupo
		// Si ha encontrado elementos con el mismo grupo y no son uno de los que ya se ha actualizado previamente
		// actualizamos el grupo de todos los elementos
		elements.forEach((el) => {
			if (el.group) {
				const groupElements: Element[] = elements.filter((e) => e.group === el.group);

				if (groupElements.length > 1 && !updatedGroups.includes(el.group)) {
					const newGroupId = uuidv4();

					groupElements.forEach((e) => {
						e.group = newGroupId;
					});

					updatedGroups.push(newGroupId);
				}
			}
		});
	}

	/**
	 * Comprobamos que el svg contenga solo un rect y ningún elemento más.
	 */
	static isBox = (svgString: string) => {
		if (svgString.indexOf('<svg') < 0) {
			svgString = `<svg>${svgString}</svg>`;
		}

		// Nos quedamos solo el SVG para quitarnos comentarios y otros elementos que no nos interesen
		svgString = svgString.substring(svgString.indexOf('<svg'));
		const svgElement = SVG(svgString);

		//Sacamos todos los defs a la raíz para evitar casos extraños en el filtro de elementos
		svgElement.find('defs').forEach((def) => def.addTo(svgElement));

		const filteredElements = svgElement?.children().filter((child) => {
			if (child.type === 'defs') {
				return false;
			}
			if (child.type === 'g' && !child.children().length) {
				return false;
			}
			return true;
		});

		return filteredElements.length === 1 && filteredElements[0].type === 'rect';
	};

	static invalidShapeSvgStyles = (element: Shape) => {
		const svg = element.svgInstance();
		const INVALID_SVG_STYLES = ['clip-path', 'mask'];

		const invalidStylesFound = INVALID_SVG_STYLES.map(
			(style) => svg.findOne(`[style*="${style}"]`)?.node.style[style as keyof CSSStyleDeclaration]
		).filter((v) => !!v);

		return invalidStylesFound;
	};
}

export default ElementTools;
