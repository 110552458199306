import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const Mockups = () => import('@/apps/mockup/pages/Mockup.vue');
type envTypes = 'wepik.com' | 'freepik.com' | 'freepik.es' | 'originalmockups.freepiklabs.com';
const isInEnvironment = (env: envTypes): boolean => window.location.href.includes(env);

const validateHost = (): string => {
	if (
		isInEnvironment('freepik.com') ||
		isInEnvironment('freepik.es') ||
		isInEnvironment('originalmockups.freepiklabs.com')
	)
		return 'mockup-editor';
	if (isInEnvironment('wepik.com')) return 'mockup';
	return '';
};

const routes: RouteRecordRaw[] = [
	{
		path: '/',
		name: 'mockups',
		component: Mockups,
	},
	{
		path: '/migration',
		name: 'migration',
		component: () => import('@/apps/mockup/pages/MockupMigration.vue'),
	},
	{
		path: '/search',
		name: 'search',
		component: () => import('@/apps/mockup/pages/MockupSearch.vue'),
	},
	{
		path: '/migration-stats',
		name: 'migrationStats',
		component: () => import('@/apps/mockup/pages/MockupMigrationStats.vue'),
	},
	{ path: '/:pathMatch(.*)*', redirect: '/search' },
];

const router = createRouter({
	history: createWebHistory(validateHost()),
	routes,
});

router.beforeEach((to, from, next) => {
	if (isInEnvironment('originalmockups.freepiklabs.com') && to.name === 'mockups') {
		next('/search');
		return;
	}
	// This change is to redirect to search page when
	// the user enters the app without any query params
	if (to.path === '/' && Object.keys(to.query).length === 0) {
		next('/search');
	} else {
		next();
	}
});

export default router;
