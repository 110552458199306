// Bugsnag
import Bugsnag from '@bugsnag/js';
import Moveable, { OnDrag, OnResize } from 'moveable';
import { ComputedRef, nextTick, Ref, ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useMainStore } from '@/editor/stores/store';
import { TransformTools } from '@/elements/element/utils/TransformTools';
import { CroppeableElement } from '@/elements/medias/crop/types/croppeable.type';
import { MaskableElement } from '@/elements/medias/mask/types/maskable.type';
import { useInteractions } from '@/interactions/composables/useInteractions';
import { useSelection } from '@/interactions/composables/useSelection';
import { useProjectStore } from '@/project/stores/project';
import { EditPanels, Position, Size } from '@/Types/types';

const ghostMoveable = ref<Moveable | null>();

const initialSize = ref<Size>({ width: 0, height: 0 });
const initialPosition = ref<Position>({ x: 0, y: 0 });
const initialCropSize = ref<Size>({ width: 0, height: 0 });
const initialCropPosition = ref<Position>({ x: 0, y: 0 });
const initialDirections = ref<boolean | string[] | undefined>([]);
const fromApplyCrop = ref(false);
const imagePanelWasOpen = ref(false);

export const useCrop = (
	element: Ref<CroppeableElement | MaskableElement> | ComputedRef<CroppeableElement | MaskableElement>
) => {
	const store = useMainStore();
	const { selectionId, clearSelection } = useSelection();

	const project = useProjectStore();

	const temporalSize = ref<Size | null>(null);

	const { isMobile } = useDeviceInfo();
	const { moveable, isCropping } = useInteractions();

	const initCrop = async () => {
		project.pauseAutoSave?.();

		// Cerramos el panel de imágenes en caso de estar abierto
		if (store.editPanel === EditPanels.Image && !isMobile.value) {
			store.editPanel = null;
			imagePanelWasOpen.value = true;
		}
		// Guardamos los datos iniciales para restaurarlos en caso de cancelar el crop
		initialSize.value = {
			width: element.value.size.width,
			height: element.value.size.height,
		};

		initialPosition.value = {
			x: element.value.position.x,
			y: element.value.position.y,
		};

		initialCropSize.value = {
			width: element.value.crop.size.width,
			height: element.value.crop.size.height,
		};

		initialCropPosition.value = {
			x: element.value.crop.position.x,
			y: element.value.crop.position.y,
		};

		// Si no tiene crop le seteamos el tamaño de crop al original
		if (!element.value.hasCrop()) {
			element.value.crop.size = {
				width: element.value.size.width,
				height: element.value.size.height,
			};
		}

		await nextTick();
		setMoveableConfigForCrop();
		await nextTick();

		ghostMoveable.value = createGhostMoveable();
		registerGhostMoveableEvents();
		GAnalytics.track('click', 'Button', `crop`, null);
	};

	const stopCrop = () => {
		unsetMoveableConfigForCrop();
		store.croppingId = null;
		ghostMoveable.value?.destroy();
		ghostMoveable.value = null;
		project.resumeAutoSave?.();
	};

	const cancelCrop = () => {
		// Ignoramos el autosave al cancelar el crop, ya que recuperamos su estado previo
		project.ignoreAutoSave?.(() => {
			element.value.size = {
				width: initialSize.value.width,
				height: initialSize.value.height,
			};

			element.value.position = {
				x: initialPosition.value.x,
				y: initialPosition.value.y,
			};

			element.value.crop.size = {
				width: initialCropSize.value.width,
				height: initialCropSize.value.height,
			};

			element.value.crop.position = {
				x: initialCropPosition.value.x,
				y: initialCropPosition.value.y,
			};
		});
		Bugsnag.leaveBreadcrumb(`Cancel crop to ${element.value.id}`);

		stopCrop();
	};

	const applyCrop = async () => {
		if (!element.value) {
			throw new Error('Not element to crop');
		}
		Bugsnag.leaveBreadcrumb(`Apply crop to ${element.value.id}`);

		fromApplyCrop.value = true;

		await nextTick();
		stopCrop();
		project.saveState?.();
	};

	const setMoveableConfigForCrop = () => {
		if (!moveable.value) {
			throw new Error('Moveable error');
		}

		initialDirections.value = moveable.value.renderDirections;

		if ('mask' in element.value) {
			moveable.value!.keepRatio = element.value.mask?.keepRatio;
			moveable.value.renderDirections = element.value.mask?.keepRatio
				? ['nw', 'ne', 'sw', 'se']
				: ['nw', 'n', 'ne', 'w', 'e', 'sw', 's', 'se'];
		}

		moveable.value.draggable = false;
		moveable.value.rotationPosition = 'none';
		moveable.value.snappable = false;
		moveable.value.target = document.querySelector('.main-image') as HTMLElement;
	};

	const createGhostMoveable = () => {
		const canvas = document.querySelector('[id^="canvas-"]') as HTMLElement;
		const moveableContainer = document.getElementById('moveable-container');

		if (!canvas || !moveableContainer) {
			console.error('Canvas or moveable container not found!');
			return;
		}

		return new Moveable(moveableContainer, {
			target: document.querySelector('.ghost-image') as HTMLElement,
			renderDirections: ['nw', 'ne', 'sw', 'se'],
			snappable: false,
			draggable: true,
			resizable: true,
			rotatable: false,
			pinchable: false,
			origin: false,
			keepRatio: true,
			edge: false,
			checkInput: true,
			className: 'moveable-ghost',
			container: canvas,
		});
	};

	const registerGhostMoveableEvents = () => {
		ghostMoveable.value!.on('drag', dragHandler).on('resize', resizeHandler);
	};

	const dragHandler = (ev: OnDrag) => {
		const pos = { x: element.value.crop.position.x + ev.delta[0], y: element.value.crop.position.y + ev.delta[1] };
		element.value.crop.position = pos;
		ev.target.style.transform = `translate(${pos.x}px, ${pos.y}px)`;
	};

	const resizeHandler = (ev: OnResize) => {
		if (!element.value.hasCrop()) return;

		const { drag, target, width, height } = ev;
		if (width <= 0 || height <= 0) return;
		const { translate } = drag;

		const position = {
			x: translate[0],
			y: translate[1],
		};

		const prevSize =
			initialCropSize.value.width && initialCropSize.value.height
				? {
						width: initialCropSize.value.width,
						height: initialCropSize.value.height,
				  }
				: {
						width: initialSize.value.width,
						height: initialSize.value.height,
				  };
		const size = { width, height: width / (prevSize.width / prevSize.height) };

		store.$patch(() => {
			element.value.crop.size = size;
			element.value.crop.position = position;
		});

		// Es necesario para evitar flickeo al redimensionar
		target!.style.width = `${size.width}px`;
		target!.style.height = `${size.height}px`;
		target!.style.transform = `translate(${position.x}px, ${position.y}px)`;
	};

	const unsetMoveableConfigForCrop = async () => {
		const selection = selectionId.value;
		await nextTick();
		clearSelection();
		await nextTick();
		selectionId.value = selection;
		// Si previamente teníamos el panel de imágenes abierto, lo recuperamos
		if (imagePanelWasOpen.value && !isMobile.value) {
			store.editPanel = EditPanels.Image;
			imagePanelWasOpen.value = false;
		}

		// En versión mobile restauramos el panel de imagen siempre después de un crop
		restoreImagePanelMobile();
	};

	const restoreImagePanelMobile = () => {
		if (store.editPanel !== EditPanels.Image && isMobile.value) {
			store.editPanel = EditPanels.Image;
		}
	};

	// Methods | PreCrop
	const preCropHandler = (delta: number[], dir: number[], size: Size) => {
		const isLeftHandler = dir[0] === -1;
		const isRightHandler = dir[0] === 1;
		const isTopHandler = dir[1] === -1;
		const isBottomHandler = dir[1] === 1;

		const gettingBiggerWidth = delta[0] > 0;
		const gettingSmallWidth = delta[0] < 0;
		const gettingBiggerHeight = delta[1] > 0;
		const gettingSmallHeight = delta[1] < 0;

		const horizontalLimitContract = temporalSize.value && temporalSize.value.width < element.value.crop.size.width;
		const verticalLimitContract = temporalSize.value && temporalSize.value.height < element.value.crop.size.height;

		if (!element.value.hasCrop()) {
			element.value.crop.size.width = element.value.size.width;
			element.value.crop.size.height = element.value.size.height;
		}

		if (isRightHandler) {
			const shouldExpandWidth =
				gettingBiggerWidth && element.value.crop.size.width - size.width + element.value.crop.position.x <= 0;
			const shouldContractWidth = gettingSmallWidth && verticalLimitContract && horizontalLimitContract;

			if (!isCropping.value && (shouldExpandWidth || shouldContractWidth)) {
				setWidthAndKeepPosition(delta[0]);
			}
		}

		if (isLeftHandler) {
			const shouldExpandWidth = gettingBiggerWidth && element.value.crop.position.x >= 0;
			const shouldContractWidth = gettingSmallWidth && horizontalLimitContract && verticalLimitContract;

			if (!isCropping.value && (shouldExpandWidth || shouldContractWidth)) {
				setWidthAndKeepPosition(delta[0], true);
			} else {
				element.value.crop.position.x += delta[0];
			}
		}

		if (isBottomHandler) {
			const shouldExpandHeight =
				gettingBiggerHeight && element.value.crop.size.height - size.height + element.value.crop.position.y <= 0;
			const shouldContractHeight = gettingSmallHeight && verticalLimitContract && horizontalLimitContract;

			if (!isCropping.value && (shouldExpandHeight || shouldContractHeight)) {
				setHeightAndKeepPosition(delta[1]);
			}
		}

		if (isTopHandler) {
			const shouldExpandHeight = gettingBiggerHeight && element.value.crop.position.y >= 0;
			const shouldContractHeight = gettingSmallHeight && horizontalLimitContract && verticalLimitContract;

			if (!isCropping.value && (shouldExpandHeight || shouldContractHeight)) {
				setHeightAndKeepPosition(delta[1], true);
			} else {
				element.value.crop.position.y += delta[1];
			}
		}
	};

	const setWidthAndKeepPosition = (deltaX: number, resetTranslate = false) => {
		const prevHeight = element.value.crop.size.height;
		// Actualizamos el size del crop al nuevo width manteniendo el aspect ratio
		element.value.crop.size = TransformTools.getSizeKeepingAspectRatioByWidth(
			element.value.crop.size,
			element.value.crop.size.width < element.value.size.width
				? element.value.size.width + deltaX
				: element.value.crop.size.width + deltaX
		);
		if (deltaX < 0 && element.value.crop.size.height < element.value.size.height) {
			element.value.crop.size = TransformTools.getSizeKeepingAspectRatioByHeight(
				element.value.crop.size,
				element.value.size.height
			);
		}
		const diffHeight = element.value.crop.size.height - prevHeight;
		// Cuando tiras de los handlers top o left y el movimiento es muy brusco se produce un offset en la caja
		// reseteamos el position para evitar este efecto
		if (resetTranslate && element.value.crop.position.x > 0) {
			element.value.crop.position.x = 0;
		}
		element.value.crop.position.y -= diffHeight / 2;
	};

	const setHeightAndKeepPosition = (deltaY: number, resetTranslate = false) => {
		const prevWidth = element.value.crop.size.width;
		// Actualizamos el size del crop al nuevo height manteniendo el aspect ratio
		element.value.crop.size = TransformTools.getSizeKeepingAspectRatioByHeight(
			element.value.crop.size,
			element.value.crop.size.height < element.value.size.height
				? element.value.size.height + deltaY
				: element.value.crop.size.height + deltaY
		);
		if (deltaY < 0 && element.value.crop.size.width < element.value.size.width) {
			element.value.crop.size = TransformTools.getSizeKeepingAspectRatioByWidth(
				element.value.crop.size,
				element.value.size.width
			);
		}
		const diffWidth = element.value.crop.size.width - prevWidth;
		// Cuando tiras de los handlers top o left y el movimiento es muy brusco se produce un offset en la caja
		// reseteamos el position para evitar este efecto
		if (resetTranslate && element.value.crop.position.y > 0) {
			element.value.crop.position.y = 0;
		}
		element.value.crop.position.x -= diffWidth / 2;
	};

	const fitCroppedMediaOnResize = (element: CroppeableElement, size: Size) => {
		if (!element.hasCrop()) return;

		element.crop.size.width *= size.width / element.size.width;
		element.crop.size.height *= size.height / element.size.height;
		element.crop.position.x *= size.width / element.size.width;
		element.crop.position.y *= size.height / element.size.height;
	};

	const resizeCropByCornerHandler = (element: CroppeableElement, delta: number[], dir: number[]) => {
		if (!element.hasCrop()) return;

		const isLeftTopHandler = dir[0] === -1 && dir[1] === -1;
		const isRightTopHandler = dir[0] === 1 && dir[1] === -1;
		const isLeftBottomHandler = dir[0] === -1 && dir[1] === 1;

		if (isLeftTopHandler) {
			element.crop.position.x += delta[0];
			element.crop.position.y += delta[1];
		}

		if (isLeftBottomHandler) {
			element.crop.position.x += delta[0];
		}

		if (isRightTopHandler) {
			element.crop.position.y += delta[1];
		}
	};

	const resizeCropByMiddleHandler = (element: CroppeableElement, delta: number[], dir: number[]) => {
		if (!element.hasCrop()) return;

		const isLeftHandler = dir[0] === -1;
		const isTopHandler = dir[1] === -1;

		if (isLeftHandler) {
			// element.crop.position.x = limits.left && delta[0] >= 0 ? 0 : element.crop.position.x + delta[0];
			element.crop.position.x += delta[0];
		}

		if (isTopHandler) {
			// element.crop.position.y = limits.top && delta[1] >= 0 ? 0 : element.crop.position.y + delta[1];
			element.crop.position.y += delta[1];
		}
	};

	return {
		initCrop,
		applyCrop,
		cancelCrop,
		preCropHandler,
		fitCroppedMediaOnResize,
		resizeCropByCornerHandler,
		resizeCropByMiddleHandler,
		initialCropPosition,
		initialCropSize,
		initialPosition,
		initialSize,
		fromApplyCrop,
		temporalSize,
	};
};

export const useGhostMoveable = () => ({ ghostMoveable });
