import { getIndexAbove } from '@tldraw/indices';
import { computed, ComputedRef, Ref, ref } from 'vue';

import { useBugsnag } from '@/analytics/bugsnag/composables/useBugsnag';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import Element from '@/elements/element/classes/Element';
import { useElementTransformOrchestrator } from '@/elements/element/composables/useElementTransformOrchestrator';
import { TransformTools } from '@/elements/element/utils/TransformTools';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import Image from '@/elements/medias/images/image/classes/Image';
import { useLayersImage } from '@/elements/medias/images/image/composables/useLayersImage';
import { usePhotoMode } from '@/elements/medias/images/image/composables/usePhotoMode';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import { Text } from '@/elements/texts/text/classes/Text';
import { useSelection } from '@/interactions/composables/useSelection';
import Page from '@/page/classes/Page';
import { useProjectStore } from '@/project/stores/project';
import { Color } from '@/Types/colorsTypes';
import { Size } from '@/Types/types';

const { bugsnagMsgWithDebounce } = useBugsnag();

export const usePage = (page: Ref<Page> | ComputedRef<Page>) => {
	const project = useProjectStore();
	const store = useMainStore();
	const { selection, clearSelection, removeFromSelection } = useSelection();
	const { photoModeImage } = usePhotoMode();

	const { isPhotoMode, isIllustratorContext, isRenderingContext } = useEditorMode();

	const temporalRef = ref<Element>(Shape.create());
	const { hasForeground, foreground } = useLayersImage(temporalRef as Ref<Image>);
	const usingElementTransformOrchestrator = useElementTransformOrchestrator(temporalRef);

	const backgroundImage = computed(() =>
		page.value.backgroundImageId ? getElementById(page.value.backgroundImageId) : null
	);

	const position = computed(() => project.pages.findIndex((p) => page.value.id === p.id));

	const adjustContent = (newArtboard: Size, oldArtboard: Size) => {
		const { height: newHeight, width: newWidth } = newArtboard;
		const { height: oldHeight, width: oldWidth } = oldArtboard;

		// Algunas veces, por culpa de algún decimal, los ratios no son exactamente iguales
		const newRatio = newWidth / newHeight;
		const oldRatio = oldWidth / oldHeight;
		const sameRatio = Math.abs(newRatio - oldRatio).toFixed(2) === '0.00';

		// Calculamos el tamaño que ocupa el contenido respecto al canvas
		const { x, y } = page.value.contentSize;
		let { width: contentWidth, height: contentHeight } = page.value.contentSize;

		// Limitamos el tamaño al del propio canvas y tener solo en cuenta la parte visible
		contentWidth = Math.min(contentWidth, oldWidth);
		contentHeight = Math.min(contentHeight, oldHeight);

		// Obtenemos la escala en función de los artboards
		const scale = TransformTools.getFactorToFitInNewRatio(newArtboard, oldArtboard);
		// Ajustamos el contenido a la escala
		const newContentHeight = contentHeight * scale;
		const newContentWidth = contentWidth * scale;

		const centeredPosition = {
			x: newContentWidth !== newWidth ? (newWidth - newContentWidth) / 2 : 0,
			y: newContentHeight !== newHeight ? (newHeight - newContentHeight) / 2 : 0,
		};

		page.value.elements.forEach((el: Element) => {
			// Si es la imagen del modo foto prevenimos que esta se ajuste al nuevo artboard, ya que pasa
			// por una lógica distinta en la que se tiene en cuenta la rotación y el crop de la imagen
			// debido a un comportamiento exclusivo del modo foto, donde la imagen va a tener rotación
			//  y aunque la rotemos, siempre debe de estar ajustada a las dimensiones del artboard
			const isPhotoModeImage = el.id === photoModeImage.value?.id;
			if (isPhotoModeImage) return;

			// El foreground siempre se sincroniza automáticamente con su imagen excepto en el render
			if (el instanceof ForegroundImage && !isRenderingContext) return;

			temporalRef.value = el;
			const { adjustToNewArtboard } = usingElementTransformOrchestrator.value;

			// Cuando el redimensionado se hace sin cambiar el aspect ratio
			// del artboard no es necesario centrar los elementos.
			// El adjustToNewArtboard -> scaleBy ya se encargará que cambiar
			// proporcionalmente la posición para que se queden "en el mismo sitio".
			adjustToNewArtboard(
				{ x: sameRatio ? 0 : x, y: sameRatio ? 0 : y },
				{ x: sameRatio ? 0 : centeredPosition.x, y: sameRatio ? 0 : centeredPosition.y },
				scale
			);
		});
	};

	const setBackground = (background: Color) => {
		page.value.updateBackgroundColor(background);

		// Al inicio del modo foto se ejecuta, evitamos que aparezca en Bugsnag para no generar ruído
		if (!isPhotoMode) bugsnagMsgWithDebounce(`Modify background color`);
	};

	const addElement = (element: Element) => {
		const elementsAsArray = page.value.elementsAsArray();
		const lastIndex = elementsAsArray[elementsAsArray.length - 1]?.index || undefined;
		element.index = getIndexAbove(lastIndex);
		page.value.elements.set(element.id, element);
	};

	const removeElement = (element: Element) => {
		// Si es un text dentro de una caja, se borra la referencia del texto en la caja
		const parentBox = element instanceof Text && element.parentBox();
		if (parentBox) {
			clearSelection();
			store.editPanel = null;
			parentBox.subElements.delete(element.id);
			return;
		}

		// Remove is not allow if cropping or if element is photo mode image
		if (store.croppingId || (isPhotoMode.value && backgroundImage.value?.id === element.id) || !page.value) return;

		if (element instanceof Image) {
			temporalRef.value = element;

			if (hasForeground.value && foreground.value) {
				removeElement(foreground.value);
			}
		}
		// borramos el elemento y lo quitamos de la selección
		page.value.elements.delete(element.id);
		removeFromSelection(element);

		// Si era la imagen que estaba como background la borramos del page
		if (page.value.backgroundImageId === element.id) {
			page.value.backgroundImageId = null;
		}

		// Lo restauramos en el modo illustrator
		if (isIllustratorContext.value) {
			element.metadata.illustratorLinks.forEach((illustratorLink: string) => {
				store.illustratorElementsMoved.delete(illustratorLink);
			});
		}

		// Si está agrupado y solo queda un elemento le eliminamos el grupo
		const elementsFromGroup = getElementsFromGroup(element.group);
		if (elementsFromGroup.length === 1) {
			const [remainingElementGrouped] = elementsFromGroup;
			remainingElementGrouped.group = null;
		}

		if (!selection.value.length) {
			clearSelection();
		}
	};

	const getElementById = (id: string): Element | undefined => {
		return page.value.elements.get(id);
	};

	const getElementFromDom = (element: HTMLElement): Element | undefined => {
		const id = element.id.substring(8);
		return getElementById(id);
	};

	const getElementsFromGroup = (group: string | null): Element[] => {
		if (!group) return [];
		return page.value.elementsAsArray().filter((el) => el.group === group);
	};

	const getGroupIdsInPage = (): string[] => {
		if (!page.value) return [];

		return page.value
			.elementsAsArray()
			.map((el) => el.group)
			.filter((g) => !!g)
			.filter((g, i, self) => self.indexOf(g) === i);
	};

	return {
		backgroundImage,
		position,
		addElement,
		adjustContent,
		getElementById,
		getElementFromDom,
		getGroupIdsInPage,
		removeElement,
		setBackground,
	};
};
