import { ref } from 'vue';

export const useVariantsColors = () => {
	// TODO REFACTOR: Traer toda lógica de cambio de variantes de color de los templates a este composable

	// Flag para controlar cuando se está aplicando un cambio de color desde las variantes del template
	const isApplyingVariantColors = ref(false);

	const toggleApplyingVariantColors = () => (isApplyingVariantColors.value = !isApplyingVariantColors.value);

	return {
		isApplyingVariantColors,
		toggleApplyingVariantColors,
	};
};
