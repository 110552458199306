import { UseFetchOptions, UseFetchReturn } from '@vueuse/core';
import { Ref, ref } from 'vue';

import {
	concatenatedFlaticonResponseMapper,
	concatenatedTemplatesResponseMapper,
	splittedFlaticonResponseMapper,
} from '@/api/ClientApiMapper';
import { useEditorApiFetch } from '@/api/composables/useEditorApiFetch';
import { useEmptyDataOnUrlParamChange } from '@/api/composables/useEmptyDataOnUrlParamChange';
import { MappedApiResponse, StickersCategoryApi, StickersElementApi } from '@/Types/apiClient';
import { UseApiFetchOptions } from '@/Types/types';

// Categories
export const getConcatenatedStickersCategories = (
	url: Ref<string>,
	options: UseFetchOptions = {}
): UseFetchReturn<MappedApiResponse<StickersCategoryApi[]>> => {
	const concatenatedData = ref<StickersCategoryApi[]>([]);

	useEmptyDataOnUrlParamChange(url, concatenatedData);

	return useEditorApiFetch(url, {
		...options,
		afterFetch: (ctx) => concatenatedTemplatesResponseMapper(ctx, concatenatedData),
	})
		.get()
		.json();
};

// Elements
export const getStickersElements = (
	url: Ref<string>,
	options: UseApiFetchOptions = {}
): UseFetchReturn<MappedApiResponse<StickersElementApi[]>> => {
	if (options.concat) {
		return getConcatenatedStickersElements(url, options);
	}

	return getSplittedStickersElements(url, options);
};

const getConcatenatedStickersElements = (
	url: Ref<string>,
	options: UseFetchOptions = {}
): UseFetchReturn<MappedApiResponse<StickersElementApi[]>> => {
	const concatenatedData = ref<StickersElementApi[]>([]);

	useEmptyDataOnUrlParamChange(url, concatenatedData);

	return useEditorApiFetch(url, {
		...options,
		afterFetch: (ctx) => concatenatedFlaticonResponseMapper(ctx, concatenatedData, url),
	})
		.get()
		.json();
};

const getSplittedStickersElements = (
	url: Ref<string>,
	options: UseFetchOptions = {}
): UseFetchReturn<MappedApiResponse<StickersElementApi[]>> => {
	return useEditorApiFetch(url, {
		...options,
		afterFetch: (ctx) => splittedFlaticonResponseMapper(ctx, url),
	})
		.get()
		.json();
};
