import Bugsnag from '@bugsnag/js';
import { createSharedComposable, until } from '@vueuse/core';
import { cloneDeep } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';
import { computed, ref } from 'vue';

import { useTracking } from '@/api/composables/useTracking';
import { UserApiClient } from '@/api/utils';
import { useAuth } from '@/auth/composables/useAuth';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useEnvSettings } from '@/common/composables/useEnvSettings';
import { useTracksJob } from '@/common/composables/useTracksJob';
import { FilesTools } from '@/common/utils/FilesTools';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { useCrop } from '@/elements/medias/crop/composables/useCrop';
import { useCropPhotoMode } from '@/elements/medias/crop/composables/useCropPhotoMode';
import BaseImage from '@/elements/medias/images/base-image/classes/BaseImage';
import Image from '@/elements/medias/images/image/classes/Image';
import { Video } from '@/elements/medias/video/classes/Video';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import EventTools from '@/interactions/classes/EventTools';
import Page from '@/page/classes/Page';
import { useProjectControls } from '@/project/composables/useProjectControls';
import { useProjectStore } from '@/project/stores/project';
import { DownloadFormat, DownloadInfo, Unit } from '@/Types/types';

interface DownloadRequest {
	format: DownloadFormat;
	width: number;
	height: number;
	unit: Unit;
	pages: string[];
	userVectorId: string | null;
	vectorId: number;
	content: Page[] | null;
	transparentBackground: boolean;
	scale: number;
}

const triggerDownload = (downloadUrl: string) => {
	const link = document.createElement('a');

	link.setAttribute('href', downloadUrl);
	link.download = '';

	document.body.appendChild(link);

	link.click();
	document.body.removeChild(link);
};

const parseToBase64 = async (element: BaseImage) => {
	if (element.url?.startsWith('blob:')) {
		element.url = await FilesTools.urlToBase64(element.url);
	}

	if (
		element.urlBackgroundRemoved &&
		!Array.isArray(element.urlBackgroundRemoved) &&
		element.urlBackgroundRemoved.startsWith('blob:')
	) {
		element.urlBackgroundRemoved = await FilesTools.urlToBase64(element.urlBackgroundRemoved);
	}
};

const preparePagesForDownload = async (sourcePages: Page[], scaleToapply?: number) => {
	const pages = cloneDeep(sourcePages);
	// buscamos las imagenes que esten como blob y las pasamos a base64
	const promises = pages.flatMap((p) => {
		return p.elementsAsArray().map(async (e) => {
			if (scaleToapply) e.scaleBy(1 / scaleToapply);

			if (e instanceof BaseImage) {
				await parseToBase64(e);
			}
			Promise.resolve();
		});
	});

	await Promise.all(promises);

	const elements: any = {};

	pages.forEach((p) => {
		p.elements.forEach((element) => {
			elements[element.id] = element.toSerialize();
		});
		p.elements = elements;
	});

	return pages;
};

export const useDownloadsProject = createSharedComposable(function () {
	const store = useMainStore();
	const project = useProjectStore();
	const { isIOS } = useDeviceInfo();
	const downloads = ref<Map<string, DownloadInfo>>(new Map());
	const downloadFormat = ref(window.renderData?.format || DownloadFormat.png);
	const multiplyFactor = ref(1);
	const { trackJob } = useTracksJob();
	const { isLogged } = useAuth();
	const { isWebview, webviewVersion, isFreepikApp } = useDeviceInfo();
	const { isEmbeddedContext } = useEditorMode();
	const { overLimitToDownload, duration } = useProjectControls();
	const currentSelectedPageIds = ref(project.pages.map((p) => p.id));
	const { APP_API_PATH } = useEnvSettings();
	const { isPhotoMode, isSlidesgoMode, isDisneyMode, isMockupMode, isFreepikContext } = useEditorMode();
	const temporalRef = ref<Image>(Image.create());
	const { cancelCrop } = useCrop(temporalRef);
	const { cancelCrop: cancelCropPhotoMode } = useCropPhotoMode();
	const downloading = computed(() => Array.from(downloads.value.values()).some((d) => d.status === 'progress'));

	const downloadOptions = computed(() => {
		if (isDisneyMode.value) return [DownloadFormat.jpg, DownloadFormat.pdf];
		if (isSlidesgoMode.value) return [DownloadFormat.jpg, DownloadFormat.pdf, DownloadFormat.mp4, DownloadFormat.pptx];
		if (isPhotoMode.value) return [DownloadFormat.jpg, DownloadFormat.pdf, DownloadFormat.png];
		if (isMockupMode.value) return [DownloadFormat.jpg, DownloadFormat.png];
		return [DownloadFormat.jpg, DownloadFormat.png, DownloadFormat.tpng, DownloadFormat.pdf, DownloadFormat.mp4];
	});

	const downloadPrefix = computed(() => {
		if (isSlidesgoMode.value) return 'slidesgo-';
		if (isFreepikContext.value || isPhotoMode.value) return 'freepik-';
		return 'wepik-';
	});

	const handleDownload = async (uuid: string, selectedPages: Page[], format: DownloadFormat) => {
		const downloadUrl = `${APP_API_PATH}download/${uuid}`;

		const extension =
			selectedPages.length === 1 || ['mp4', 'pdf', 'pptx'].includes(format) ? downloadFormat.value : 'zip';
		const date = new Date().toISOString().replace(/[-:.]/g, '').replace('T', '').slice(0, 14);

		downloads.value.set(uuid, {
			url: downloadUrl,
			format,
			name: `${downloadPrefix.value}${project.name?.replace(' ', '-')}-${date}.${extension}`,
			status: 'done',
			duration: format === DownloadFormat.mp4 ? duration.value : selectedPages.length,
		});

		store.disableBeforeUnload = true;

		if (isEmbeddedContext.value) {
			EventTools.emitUrlToIframe({
				eventName: 'publish',
				url: downloadUrl,
				templateId: store.userVector?.uuid || '',
			});
		} else {
			triggerDownload(downloadUrl);
		}

		store.disableBeforeUnload = false;
	};

	const getRequestData = async (format: DownloadFormat, pages: Page[]) => {
		// Si estamos en ios estaremos aplicando una escala al tamaño de los elementos
		// le pasamos la escala para convierta todos los elementos al tamaño original
		const scaleToApply = isIOS.value ? store.scaleMaxAllowedSize : undefined;
		const pagesToDownload = await preparePagesForDownload(pages, scaleToApply);
		const transparentBackground = format === DownloadFormat.tpng;

		if (transparentBackground) {
			format = DownloadFormat.png;
		}

		const downloadData: DownloadRequest = {
			content: pagesToDownload,
			format,
			pages: pagesToDownload.map((p) => p.id),
			unit: project.unit,
			vectorId: project.sourceVectorId,
			height: project.size.height / store.scaleMaxAllowedSize,
			width: project.size.width / store.scaleMaxAllowedSize,
			userVectorId: null,
			transparentBackground,
			scale: multiplyFactor.value,
		};

		Bugsnag.leaveBreadcrumb('Download request data', downloadData);

		if (store.userVector && isLogged.value) {
			downloadData.userVectorId = store.userVector.uuid;
			downloadData.content = null;
		}

		return downloadData;
	};

	const { track } = useTracking();
	const startDownload = async (format: DownloadFormat, pages: Page[]) => {
		const allElements = pages.flatMap((p) => p.elementsAsArray()).filter(Boolean);
		const [images, icons, videos] = [
			allElements.filter((e) => e instanceof Image),
			allElements.filter((e) => e instanceof Shape),
			allElements.filter((e) => e instanceof Video),
		];

		track('download', {
			funnel: 'downloads',
			user_type: isLogged.value ? 'registered' : 'anonymous',
			vector_id: project.sourceVectorId,
			user_vector_id: store.userVector?.uuid,
			format,
			pages: pages.length,
			images: images
				.map((e) => (e.metadata?.imageApi?.origin === 'freepik' ? e.metadata?.imageApi?.id : null))
				.filter(Boolean),
			icons: icons
				.map((e) => (e.metadata?.dataApi?.origin === 'flaticon' ? e.metadata?.dataApi?.id : null))
				.filter(Boolean),

			videos: videos
				.map((e) => (e.metadata?.imageApi?.origin === 'freepik' ? e.metadata?.imageApi?.id : null))
				.filter(Boolean),
		});

		const downloadData = await getRequestData(format, pages);

		// Si estamos en el webview de la app vieja enviamos los datos
		// necesarios para que esta haga la petición de descarga. La app
		// de freepik no sigue este flujo sino que intercepta la llamada
		// del flujo normal
		if (isWebview.value && !isFreepikApp.value && webviewVersion.value < 3) {
			const data = {
				downloadData,
				vectorName: project.name,
			};
			const appMessage =
				webviewVersion.value >= 2
					? {
							action: 'download',
							data,
					  }
					: data;
			window.ReactNativeWebView.postMessage(JSON.stringify(appMessage));
			return;
		}

		// Nueva app, ahora con sockets
		if (isWebview.value && !isFreepikApp.value && webviewVersion.value >= 3) {
			const appMessage = {
				action: 'download',
				data: {
					downloadData,
					vectorName: project.name,
					canDownloadVideo: project.allVideos.length > 0 && !overLimitToDownload.value,
					duration: duration.value,
				},
			};
			window.ReactNativeWebView.postMessage(JSON.stringify(appMessage));
			return;
		}

		let uuid;

		try {
			const response = await UserApiClient.exportProjectPages(downloadData);
			uuid = response.uuid;
		} catch (error) {
			if (error instanceof Error) {
				Bugsnag.notify(error);
			}
			console.error(error);
			downloads.value.set(uuidv4(), {
				url: '',
				format,
				name: `${downloadPrefix.value}${project.name || 'download'}.${format}`,
				status: 'error',
				duration: format === DownloadFormat.mp4 ? duration.value : pages.length,
			});

			throw error;
		}

		if (!uuid) return;

		try {
			downloads.value.set(uuid, {
				url: '',
				format,
				name: `${downloadPrefix.value}${project.name || 'download'}.${format}`,
				status: 'progress',
				duration: format === DownloadFormat.mp4 ? duration.value : pages.length,
			});

			const interval = format === DownloadFormat.mp4 ? 35000 : 19000;
			let timeout = 60000 * 2;

			switch (format) {
				case DownloadFormat.mp4:
					timeout = 60000 * 10;
					break;

				case DownloadFormat.pptx:
				case DownloadFormat.pdf:
					timeout = 60000 * 5;
					break;
			}
			await trackJob(uuid, timeout, interval);

			if (format === DownloadFormat.tpng) {
				format = DownloadFormat.png;
			}

			await handleDownload(uuid, pages, format);
		} catch (error) {
			if (error instanceof Error) {
				Bugsnag.notify(error);
			}
			console.error(error);
			downloads.value.set(uuid, {
				url: '',
				format,
				name: `${project.name || 'download'}.${format}`,
				status: 'error',
				duration: format === DownloadFormat.mp4 ? duration.value : pages.length,
			});
			throw error;
		}
	};
	const prepareDownloadOrShare = async (format: DownloadFormat, selectedPages: string[] = [], share = false) => {
		// Si no hay página seleccionadas, asumimos todas
		const pages: Page[] =
			selectedPages.length > 0
				? (selectedPages.map((id) => project.pages.find((page) => page.id === id)) as Page[]).filter((item) => !!item)
				: (project.pages as Page[]);

		if (share) {
			store.sharing = true;
		} else {
			downloadFormat.value = ['pdf', 'mp4'].includes(format) ? downloadFormat.value : format;
		}

		await until(() => project.pendingSync).toBe(false, { timeout: 15000, throwOnTimeout: true });
		await until(() => project.syncing).toBe(false, { timeout: 15000, throwOnTimeout: true });

		return pages;
	};

	const download = async (format: DownloadFormat, selectedPages: string[] = []) => {
		Bugsnag.leaveBreadcrumb(`Download template: ${format}`);

		const pages = await prepareDownloadOrShare(format, selectedPages);
		await until(() => project.pendingSync).toBe(false, { timeout: 15000, throwOnTimeout: true });
		await until(() => project.syncing).toBe(false, { timeout: 15000, throwOnTimeout: true });
		await startDownload(format, pages);
	};

	const share = async (socialNetwork: string, options: object = {}, selectedPages: string[] = []) => {
		const pages = await prepareDownloadOrShare(DownloadFormat.png, selectedPages, true);
		Bugsnag.leaveBreadcrumb(`Share template in ${socialNetwork}: ${pages.length} pages`);

		switch (socialNetwork) {
			case 'facebook':
				await shareInFacebook(pages, options);
				break;

			case 'instagram':
				await shareInInstagram(pages, options);
				break;

			case 'pinterest':
				await shareInPinterest(pages, options);
				break;

			case 'twitter':
				await shareInTwitter(pages, options);
				break;

			case 'email':
				await shareInEmail(pages, options);
				break;

			default:
				break;
		}
	};

	const shareInFacebook = async (pages: Page[], options: object) => {
		const downloadData = await getRequestData(DownloadFormat.jpg, pages);

		try {
			await UserApiClient.sendToSocialNetwork('facebook', {
				...downloadData,
				...options,
			});
		} catch (error) {
			if (error instanceof Error) {
				Bugsnag.notify(error);
				store.sharing = false;
				throw error;
			}
		}

		store.sharing = false;
	};

	const shareInInstagram = async (pages: Page[], options: object) => {
		const downloadData = await getRequestData(DownloadFormat.jpg, pages);

		try {
			await UserApiClient.sendToSocialNetwork('instagram', {
				...downloadData,
				...options,
			});
		} catch (error) {
			if (error instanceof Error) {
				Bugsnag.notify(error);
				store.sharing = false;
				throw error;
			}
		}

		store.sharing = false;
	};

	const shareInPinterest = async (pages: Page[], options: object) => {
		const downloadData = await getRequestData(DownloadFormat.jpg, pages);

		try {
			await UserApiClient.sendToSocialNetwork('pinterest', {
				...downloadData,
				...options,
			});
		} catch (error) {
			if (error instanceof Error) {
				Bugsnag.notify(error);
				store.sharing = false;
				throw error;
			}
		}

		store.sharing = false;
	};

	const shareInTwitter = async (pages: Page[], options: object) => {
		const downloadData = await getRequestData(DownloadFormat.jpg, pages);

		try {
			await UserApiClient.sendToSocialNetwork('twitter', {
				...downloadData,
				...options,
			});
		} catch (error) {
			if (error instanceof Error) {
				Bugsnag.notify(error);
				store.sharing = false;
				throw error;
			}
		}

		store.sharing = false;
	};

	const shareInEmail = async (pages: Page[], options: object) => {
		const downloadData = await getRequestData(DownloadFormat.png, pages);

		try {
			await UserApiClient.sendToEmails({
				...downloadData,
				...options,
			});
		} catch (error) {
			if (error instanceof Error) {
				Bugsnag.notify(error);
				store.sharing = false;
				throw error;
			}
		}

		store.sharing = false;
	};

	const updateSelectedPages = (selectedPages: string[] = []) => {
		currentSelectedPageIds.value =
			selectedPages.length > 0
				? selectedPages
				: (project.pages.map((p) => p.id).slice(0, project.pages.length) as string[]);
	};

	const selectedPages = computed(() => {
		return project.pages.filter((item) => currentSelectedPageIds.value.includes(item.id)) as Page[];
	});

	const setupForDownload = () => {
		if (!isPhotoMode.value) {
			if (store.croppingId) {
				cancelCrop();
			}

			if (isLogged.value) {
				if (!store.userVector && !project.pendingSync) {
					project.triggerSync?.();
				}
			}
		} else {
			if (store.croppingId) {
				cancelCropPhotoMode();
			}
		}
	};

	/**
	 * El usuario tiene un vector y todos los cambios del proyecto están guardados.
	 * El usuario no está registrado y se ha terminado de cargar la tienda.
	 * El programa está en modo de foto.
	 */
	const canDownload = computed(() => {
		if (store.croppingId) {
			return false;
		}

		if (project.allVideos.some((video) => video.metadata.temporal)) {
			return false;
		}

		if (store.userVector && project.allChangesSaved) {
			return true;
		}

		if (!isLogged.value && store.finishedLoading) {
			return true;
		}

		if (isPhotoMode.value) {
			return true;
		}

		return false;
	});

	return {
		download,
		share,
		updateSelectedPages,
		downloadOptions,
		selectedPages,
		currentSelectedPageIds,
		downloading,
		downloads,
		downloadFormat,
		multiplyFactor,
		setupForDownload,
		canDownload,
	};
});
