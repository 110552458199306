import { createSharedComposable } from '@vueuse/core';
import { cloneDeep } from 'lodash';
import { computed, ref } from 'vue';

import { Box } from '@/elements/box/classes/Box';
import { useRounding } from '@/elements/box/composables/useRounding';
import { Text } from '@/elements/texts/text/classes/Text';
import { BoxDTO, TextDTO } from '@/Types/elements';
import { BorderRadiusType } from '@/Types/types';
import MathTools from '@/utils/classes/MathTools';

export const useCopyStyles = createSharedComposable(() => {
	const copiedStyles = ref<Partial<TextDTO | BoxDTO>>({});

	// Used to store element ids after pasting styles to prevent doing it to same element
	const pastedElements = ref<string[]>([]);

	const isPasteStylesMode = computed(() => Object.keys(copiedStyles.value).length);

	const tempRefBox = ref<Box>(Box.create());
	const { maxValue } = useRounding(tempRefBox);

	const clearCopiedStyles = () => {
		copiedStyles.value = {};
		const scrollArea = document.querySelector('#scroll-area');
		if (scrollArea) scrollArea.classList.remove('paint-roll-active');
	};

	const copyStyles = (element: Box | Text) => {
		if (!(element instanceof Box) && !(element instanceof Text)) return;

		pastedElements.value = [];

		if (element instanceof Box) {
			copyBoxStyles(element);
		}

		if (element instanceof Text) {
			copyTextStyles(element);
		}

		const scrollArea = document.querySelector('#scroll-area');
		if (scrollArea) scrollArea.classList.add('paint-roll-active');
	};

	const copyBoxStyles = (element: Box) => {
		tempRefBox.value = element;
		const radiusAsPercent = element.border.radius.map((r) => MathTools.ruleOfThree(maxValue.value, 100, r));

		copiedStyles.value = {
			type: element.type,
			border: {
				...cloneDeep(element.border),
				color: element.border.color.copy(),
				radius: radiusAsPercent as BorderRadiusType,
			},
			background: element.background.copy(),
		};
	};

	const copyTextStyles = (element: Text) => {
		copiedStyles.value = {
			type: element.type,
			fontFamily: element.fontFamily,
			fontWeight: element.fontWeight,
			fontStyle: element.fontStyle,
			fontSize: element.fontSize,
			lineHeight: element.lineHeight,
			letterSpacing: element.letterSpacing,
			textAlign: element.textAlign,
			outline: {
				...element.outline,
				color: element.outline.color.copy(),
			},
			colors: element.colors.map((color) => color.copy()),
			textTransform: element.textTransform,
			scale: element.scale,
			textShadow: element.textShadow.map((shadow) => ({ ...cloneDeep(shadow), color: shadow.color.copy() })),
			curvedProperties: cloneDeep(element.curvedProperties),
		};
	};

	const pasteStyles = (element: Box | Text) => {
		if (!isPasteStylesMode.value || (!(element instanceof Box) && !(element instanceof Text))) return;
		if (copiedStyles.value.type !== element.type) {
			clearCopiedStyles();
			return;
		}
		// Block pasting styles to same element
		if (pastedElements.value.includes(element.id)) return;

		pastedElements.value.push(element.id);

		if (element instanceof Box) {
			pasteBoxStyles(element);
			return;
		}

		if (element instanceof Text) {
			pasteTextStyles(element);
		}
	};

	const pasteBoxStyles = (element: Box) => {
		if (!('border' in copiedStyles.value) || !copiedStyles.value.border) return;

		// Check if entry radius is higher than max value
		tempRefBox.value = element;
		const fixedBorder = copiedStyles.value.border.radius.map((r) => {
			return MathTools.clamp((r / 100) * maxValue.value, 0, maxValue.value);
		}) as BorderRadiusType;

		// clonamos los colores que se van a pegar para generar un nuevo id cada vez que se realice la acción de pegar
		if (copiedStyles.value.background) copiedStyles.value.background = copiedStyles.value.background.clone();
		if (copiedStyles.value.border.color) copiedStyles.value.border.color = copiedStyles.value.border.color.clone();

		Object.assign(element, { ...copiedStyles.value, border: { ...copiedStyles.value.border, radius: fixedBorder } });
	};

	const pasteTextStyles = (element: Text) => {
		const domNode = element.domNode();

		if (!domNode) return;

		// Remove style attribute in inner nodes inside the main text element to avoid multistyle issue
		Array.from(domNode.querySelectorAll('[style]'))
			.filter((node) => {
				return !node.classList.contains('text-element-final') && !node.closest('#clone-curved');
			})
			.forEach((node) => node.removeAttribute('style'));

		// ! clonamos los colores que se van a pegar para generar un nuevo id cada vez que se realice la acción de pegar
		if ('colors' in copiedStyles.value && copiedStyles.value.colors) {
			copiedStyles.value.colors = copiedStyles.value.colors.map((c) => c.clone());
		}

		if ('outline' in copiedStyles.value && copiedStyles.value.outline) {
			copiedStyles.value.outline = cloneDeep(copiedStyles.value.outline);
			copiedStyles.value.outline.color = copiedStyles.value.outline.color.clone();
		}

		const realTextNode = domNode.querySelector('.text-element-final') as HTMLElement;

		if (realTextNode?.textContent) element.updateContent(realTextNode.textContent);

		Object.assign(element, copiedStyles.value);
	};

	return {
		isPasteStylesMode,
		clearCopiedStyles,
		copyStyles,
		pasteStyles,
	};
});
