import { AnyFn } from '@vueuse/core';
import { computed, EffectScope, effectScope, onUnmounted } from 'vue';
import { ComputedRef } from 'vue';

import { tryOnUnmountedAndDispose } from '@/common/utils/tryOnUnmountedAndDispose';

export function createOrchestrator<Fn extends AnyFn>(callback: Fn): ComputedRef {
	let scope: EffectScope;
	tryOnUnmountedAndDispose(() => scope?.stop());
	return computed(() => {
		if (scope) {
			scope.stop();
		}
		scope = effectScope();
		return scope.run(callback);
	});
}
