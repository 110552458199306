import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import { SerializableClass } from '@/utils/classes/SerializableClass';

type OverlayType = 'solid' | 'gradient';
type BlendMode =
	| 'overlay'
	| 'normal'
	| 'multiply'
	| 'screen'
	| 'darken'
	| 'lighten'
	| 'color-dodge'
	| 'color-burn'
	| 'hard-light'
	| 'soft-light'
	| 'difference'
	| 'exclusion'
	| 'hue'
	| 'saturation'
	| 'color'
	| 'luminosity';

interface OverlayPattern {
	url: string;
	size: number;
	opacity: number;
}

interface OverlayDTO {
	type: OverlayType;
	blendMode: BlendMode;
	opacity?: number;
	pattern?: OverlayPattern | null;
}

interface SolidOverlayDTO extends Omit<OverlayDTO, 'type'> {
	color: SolidColor;
}

interface GradientOverlayDTO extends Omit<OverlayDTO, 'type'> {
	color: GradientColor;
}

export abstract class Overlay extends SerializableClass {
	type: OverlayType;
	blendMode: BlendMode;
	opacity: number;
	pattern: OverlayPattern | null;

	protected constructor(overlayDTO: OverlayDTO) {
		super();

		this.type = overlayDTO.type;
		this.blendMode = overlayDTO.blendMode;
		this.opacity = overlayDTO.opacity || 1;
		this.pattern = overlayDTO.pattern || null;
	}

	abstract toCssBackground(): string;

	static unserialize(data: (SolidOverlayDTO | GradientOverlayDTO) & OverlayDTO): Overlay {
		const patternAsEmptyArray = Array.isArray(data.pattern) && !data.pattern.length;

		if (data.type === 'solid') {
			return new SolidOverlay({
				color: SolidColor.fromObject((data as SolidOverlayDTO).color),
				blendMode: data.blendMode,
				opacity: data.opacity,
				// ! fix temporal;
				pattern: !patternAsEmptyArray ? data.pattern : null,
			});
		}

		return new GradientOverlay({
			color: GradientColor.fromObject((data as GradientOverlayDTO).color),
			blendMode: data.blendMode,
			opacity: data.opacity,
			// ! fix temporal;
			pattern: !patternAsEmptyArray ? data.pattern : null,
		});
	}

	scaleBy(scale: number) {
		if (this.pattern) this.pattern.size *= scale;
	}
}

export class SolidOverlay extends Overlay {
	color: SolidColor;

	constructor(solidOverlayDTO: SolidOverlayDTO) {
		super({
			...solidOverlayDTO,
			type: 'solid',
		});

		this.color = solidOverlayDTO.color;
	}

	toCssBackground(): string {
		return this.color.toCssString();
	}

	toGradientOverlay(color: GradientColor): GradientOverlay {
		return new GradientOverlay({
			color,
			blendMode: this.blendMode,
			opacity: this.opacity,
			pattern: Array.isArray(this.pattern) ? null : this.pattern,
		});
	}
}

export class GradientOverlay extends Overlay {
	color: GradientColor;

	constructor(gradientOverlayDTO: GradientOverlayDTO) {
		super({
			...gradientOverlayDTO,
			type: 'gradient',
		});

		this.color = gradientOverlayDTO.color;
	}

	toCssBackground(): string {
		return this.color.toCssString();
	}

	toSolidOverlay(color: SolidColor): SolidOverlay {
		return new SolidOverlay({
			color,
			blendMode: this.blendMode,
			opacity: this.opacity,
			pattern: this.pattern,
		});
	}
}
