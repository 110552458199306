import Bugsnag from '@bugsnag/js';
import { Box as BoxSVG, ClipPath, Dom, Element as SvgElementJS, SVG, Svg } from '@svgdotjs/svg.js';
import { useUrlSearchParams } from '@vueuse/core';
import Normalize from 'color-normalize';
import { groupBy } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';
import { ref } from 'vue';

import { getProject, getSvg } from '@/api/DataApiClient';
import { useAuth } from '@/auth/composables/useAuth';
import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import { useEnvSettings } from '@/common/composables/useEnvSettings';
import { useToast } from '@/common/composables/useToast';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { Box } from '@/elements/box/classes/Box';
import Element from '@/elements/element/classes/Element';
import { useElementTransformOrchestrator } from '@/elements/element/composables/useElementTransformOrchestrator';
import ElementTools from '@/elements/element/utils/ElementTools';
import { Illustrator } from '@/elements/illustrator/classes/Illustrator';
import { useIllustrator } from '@/elements/illustrator/composables/useIllustrator';
import IllustratorTools from '@/elements/illustrator/utils/IllustratorTools';
import Line from '@/elements/line/classes/Line';
import { Filter } from '@/elements/medias/filter/classes/Filter';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import Image from '@/elements/medias/images/image/classes/Image';
import ErrorPhotoModeUrl from '@/elements/medias/images/image/exception/ErrorPhotoModeUrl';
import ImageTools from '@/elements/medias/images/image/utils/ImageTools';
import Mask from '@/elements/medias/mask/classes/Mask';
import { Video } from '@/elements/medias/video/classes/Video';
import { QRCode } from '@/elements/qr-code/classes/QRCode';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import Storyset from '@/elements/storyset/classes/Storyset';
import { Text } from '@/elements/texts/text/classes/Text';
import { useFonts } from '@/elements/texts/text/composables/useFonts';
import FontFamilyVariantsTools from '@/elements/texts/text/utils/FontFamilyVariantsTools';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { RenderData, TemplateLoaderData } from '@/loader/types/templateLoaderData';
import LegacyMigratorTools from '@/loader/utils/LegacyMigratorTools';
import Page from '@/page/classes/Page';
import { usePage } from '@/page/composables/usePage';
import Project from '@/project/classes/Project';
import { useArtboard } from '@/project/composables/useArtboard';
import { useProjectPages } from '@/project/composables/useProjectPages';
import { useProjectStore } from '@/project/stores/project';
import { Color } from '@/Types/colorsTypes';
import { FontStyle, FontWeight, TextAlign, TextTransform } from '@/Types/elements';
import { FontFaceInfo, Position } from '@/Types/types';
import MathTools from '@/utils/classes/MathTools';

class TemplateLoader {
	static async fromSlug(slug: string): Promise<{ templateData: TemplateLoaderData; pages: Page[]; isSvg: boolean }> {
		const templateData = (await this.getTemplateData(slug, false)) as TemplateLoaderData;
		const { pages, isSvg } = await this.getPagesOfTemplate(templateData);

		return {
			templateData,
			pages,
			isSvg,
		};
	}

	static async getPagesOfTemplate(templateData: TemplateLoaderData): Promise<{ pages: Page[]; isSvg: boolean }> {
		let isSvg = false;
		const pages = await Promise.all(
			templateData.pages.map(async (page) => {
				const { data, response } = await getSvg(page.svg_url);
				const contentType = response.value?.headers.get('content-type');
				const content = contentType === 'application/json' ? JSON.parse(data.value as string) : data.value;

				isSvg = typeof content === 'string';
				return isSvg ? await this.parseSvg(content as string, templateData) : this.loadFromData(content);
			})
		);
		pages.forEach((page) => TemplateLoader.legacyCodeMigrator(page));
		return { pages, isSvg };
	}

	static fromRender(data: RenderData): Project {
		const store = useMainStore();
		const project = new Project(data.width, data.height, data.unit, -1, 1);
		project.pages = data.pages.map((page) => {
			const newPage = this.loadFromData(page);

			if (data.renderConfig?.transparentBackground) {
				newPage.background = SolidColor.transparent();
			}

			return newPage;
		});

		store.isDisneyTemplate = Boolean(data.is_disney);

		return project;
	}

	static async initPhotoMode(page: Page, templateData: TemplateLoaderData) {
		const params = useUrlSearchParams<{ photo?: string }>();
		let url = '';

		try {
			const result = !params.photo ? Image.create() : await ImageTools.getImageAsBlobUrl(params.photo as string);
			url = result.url;
		} catch (error) {
			throw new ErrorPhotoModeUrl(error);
		}
		const size = await ImageTools.getRealImageSize(url);
		const { maxArtboardSize } = useArtboard();

		const maxAllowedSize = Math.sqrt(maxArtboardSize.value);

		if (size.width * size.height > maxArtboardSize.value) {
			templateData.artboard.width = size.width <= size.height ? 1500 : Math.floor(1500 * (size.width / size.height));
			templateData.artboard.height = size.width <= size.height ? Math.floor(1500 * (size.height / size.width)) : 1500;
		} else if (size.width > maxAllowedSize || size.height > maxAllowedSize) {
			const maxSize = Math.max(size.width, size.height);
			const scaleReduction = maxSize / maxAllowedSize;
			templateData.artboard.width = Math.floor(size.width / scaleReduction);
			templateData.artboard.height = Math.floor(size.height / scaleReduction);
		} else {
			templateData.artboard.width = size.width;
			templateData.artboard.height = size.height;
		}

		templateData.artboard.unit = 'px';

		const { addElement } = usePage(ref(page));
		const image = Image.create({
			url,
		});
		image.setSize(size.width, size.height);
		page.background = SolidColor.transparent();

		addElement(image);
	}

	static initPredefindedTextMode(pages: Page[]) {
		const { setBackground } = usePage(ref(pages[0]));

		setBackground(SolidColor.transparent());
	}

	static initIllustratorMode() {
		const project = useProjectStore();
		const { addPages } = useProjectPages();
		const { moveTexts } = useIllustrator(ref(project.pages[0].elementsAsArray()[0] as Illustrator));

		if (project.pages.length === 1) {
			addPages([Page.createDefault()]);
		}

		const illustratorSvg = (project.pages[0].elementsAsArray()[0] as Illustrator).contentSvg.addTo(
			document.body
		) as Svg;
		const viewbox = illustratorSvg.viewbox();

		illustratorSvg.height(viewbox.height);
		illustratorSvg.width(viewbox.width);
		illustratorSvg.attr('class', 'absolute top-0 left-0 opacity-0 -z-50');

		// Movemos los textos
		const ids = illustratorSvg.find('text').map((text) => text.data('illustrator-link'));
		moveTexts(ids);

		// Aplicamos el mismo fondo
		const illustratorBackground = project.pages[0].background;
		project.pages[1].background = illustratorBackground;

		illustratorSvg.remove();
	}

	static parseIllustrator(svg: string, templateData: TemplateLoaderData) {
		// Necesitamos añadir el svg al DOM para poder extraer los datos de los textos svg
		const doc = SVG(svg).addTo(document.body) as Svg;
		doc.attr('class', 'absolute top-0 left-0 opacity-0 -z-50');

		const viewbox = doc.viewbox();

		templateData.artboard.height = viewbox.height;
		templateData.artboard.width = viewbox.width;
		templateData.artboard.unit = 'px';

		IllustratorTools.findBackground(doc);
		IllustratorTools.removeUnnecessaryClipPaths(doc);
		IllustratorTools.removeGroupTransforms(doc);
		IllustratorTools.simplifyTreeOfElements(doc);
		IllustratorTools.fixTexts(doc);
		IllustratorTools.fixIncompatibleGradients(doc);

		const bgElement = doc.findOne('#illustrator-background') as Dom;
		const bgFill = bgElement.css('fill').toString();
		const bgIsGradient = bgFill.includes('url');
		let bg: GradientColor | SolidColor;

		if (bgIsGradient) {
			const idGradient = ElementTools.getIdFromUrl(bgFill);
			const gradient = doc.findOne(idGradient) as SvgElementJS;

			bg = gradient.type === 'pattern' ? SolidColor.black() : ElementTools.svgGradientToObject(gradient);
		} else {
			bg = SolidColor.fromString(bgFill);
		}

		// Definimos el container y eliminamos el fondo ya que no es necesario
		bgElement.parent()?.id('illustrator-container');
		bgElement.remove();

		const viewboxString = doc.attr('viewBox').toString();
		const content = doc.node.innerHTML.toString();

		const illustratorElement = Illustrator.create({
			viewbox: viewboxString,
			content,
		});
		illustratorElement.setSize(viewbox.width, viewbox.height);

		const newPage = ref(Page.create());

		const { addElement, setBackground } = usePage(newPage);

		addElement(illustratorElement);
		setBackground(bg);

		doc.remove();

		return newPage.value;
	}

	static async getTemplateData(slug: string, checkPreload: boolean): Promise<TemplateLoaderData> {
		const { isAdminMode, isCypressContext, cypressContextData } = useEditorMode();
		const store = useMainStore();

		const preloadData = checkPreload ? window.preloadVector : null;
		let res = preloadData;

		if (!preloadData) {
			const queryParams = new URLSearchParams(window.location.search);
			const { data, error } = await getProject(
				slug,
				queryParams.get('backup') ? { backup: queryParams.get('backup') || '' } : {}
			);

			if (error.value) {
				throw error.value;
			}

			res = data.value;
		}

		// Esto es para poder hacer tests con cypress del template loader, la idea es
		// comparar un json que tenemos en cypress con el se genera en el editor usando
		// el svg de cypress
		if (
			isCypressContext.value &&
			cypressContextData.value.svg &&
			cypressContextData.value.json &&
			cypressContextData.value.data
		) {
			const data = await fetch(cypressContextData.value.data).then((res) => res.json());
			res = data;
		}

		if (!res.media.length) {
			throw new Error('The template does not have any pages');
		}

		const { PROD, APP_BASE } = useEnvSettings();

		if (res.user_vector) {
			// Si no es el propietario de la plantilla redigirimos a copy

			const { onFetchLogin, user } = useAuth();

			onFetchLogin(() => {
				if (
					!store.finishedLoading &&
					user.value?.id !== res.user_id &&
					!user.value?.isAdmin &&
					!window.location.hostname.includes('netlify') &&
					!window.location.hostname.includes('pages.dev') &&
					PROD
				) {
					window.location.href = !res.vector.is_disney ? `${APP_BASE}copy/${slug}` : `${APP_BASE}share/${slug}`;
				}
			});

			return {
				id: res.vector.id,
				name: res.name || '',
				freepik_id: res.vector.freepik_id,
				category_tree: res.vector.category_tree,
				pack: null,
				slug: res.vector.slug,
				slug_en: res.vector.slug_en,
				project: res.project,
				artboard: {
					id: null,
					name: 'Custom',
					height: res.size?.height || res.artboard?.height,
					width: res.size?.width || res.artboard?.width,
					unit: res.size?.unit || res.artboard?.unit,
					dpi: res.size?.dpi || 11.811,
				},
				pages: res.media.map((media: { id: number; url: string }, i: number) => ({
					order: i,
					svg_url: media.url,
					preview: res.previews[i]?.preview,
				})),
				preview: res.preview || '',
				userVectorId: res.uuid,
				gradients: res.gradients || [],
				flaticonSearch: res.flaticon_search,
				scale: res.scale || 1,
				inSchedules: res.in_schedules,
				origin: res.origin,
				generator: res.generator,
				is_parsed: res.is_parsed,
				vector: res.vector || null,
				is_disney: res.is_disney || false,
				is_premium: res.vector.is_premium || false,
				updated_at: res.updated_at.date,
				user_id: res.user_id,
			};
		}

		return {
			id: res.id,
			freepik_id: res.freepik_id,
			category_tree: res.category_tree,
			pack: res.pack,
			slug: res.slug,
			slug_en: res.slug_en,
			name: res.name || '',
			artboard: {
				id: res.artboard?.id || null,
				name: res.artboard?.name || 'Custom',
				height:
					res.size && Object.keys(res.size).length > 0
						? res.size.height
						: res.artboard?.landscape
						? res.artboard?.width || 0
						: res.artboard?.height || 0,
				width:
					res.size && Object.keys(res.size).length > 0
						? res.size.width
						: res.artboard?.landscape
						? res.artboard?.height || 0
						: res.artboard?.width || 0,
				unit: res.size && Object.keys(res.size).length > 0 ? res.size.unit : res.artboard?.unit || 'px',
				dpi: res.size?.dpi || 11.811,
			},
			pages: res.media.map((media: { id: number; url: string }, i: number) => ({
				order: i,
				svg_url: media.url,
				preview: res.previews[i]?.thumb,
			})),
			preview: res.preview || '',
			gradients: res.gradients || [],
			flaticonSearch: res.flaticon_search,
			scale: res.scale || 1,
			colorPalettes: (res.variants || [])
				.filter((variant: any) => variant.active || isAdminMode.value)
				.map((variant: any) => {
					return {
						...variant,
						color_palette: variant.color_palette.map((colorCollection: any) => {
							return {
								...colorCollection,
								color: colorCollection.color.type
									? GradientColor.unserialize(colorCollection.color)
									: SolidColor.unserialize(colorCollection.color),
							};
						}),
					};
				}),
			colorTags: res.color_tags,
			origin: res.origin,
			is_parsed: res.is_parsed,
			vector: res.vector || { previews: res.previews, media: res.media },
			fromFreepik: res.has_freepik_vector || false,
			freepikPreview: res.freepik_preview || null,
			is_disney: res.is_disney || false,
			is_premium: res.is_premium || false,
			updated_at: res.updated_at.date,
		};
	}

	static loadFromData(data: any, isUserVector = false): Page {
		const newPage = Page.unserialize(data);

		// le generamos una nueva id, ya que sino nos dará problemas al sincronizar
		// ya que estaremos envíando siempre la misma id para todos los user vector.
		if (!isUserVector) {
			newPage.id = uuidv4();
		}

		// Cargamos los elementos
		if (data.elements) {
			if (typeof data.elements === 'object') {
				// Por lo que sea el backend a veces lo devuelve como objeto (seguramente el json diff)
				data.elements = Object.values(data.elements);
			}

			const elements = data.elements as Element[];

			newPage.elements = new Map(
				elements.map((element) => {
					const newElement = this.unserializeElement(element);

					// Nos adelantamos a la carga de los canvas y vamos pidiendo las imagenes
					// para que estén listas antes.
					if (newElement instanceof Image) {
						document.createElement('img').src = newElement.preview || newElement.url;
					}

					// Dado que no restauramos los id buscamos que foto estaba asignada de fondo
					if (data.backgroundImageId && element.id === data.backgroundImageId) {
						newPage.backgroundImageId = newElement.id;
					}

					return [newElement.id, newElement];
				})
			);

			// Buscamos si en el page hay foregrounds sin imagen base, en caso de no encontrar su imagen base, no la incluimos
			const foregroundImages = newPage
				.elementsAsArray()
				.filter((el) => el.type === 'foregroundImage') as ForegroundImage[];
			const imagesFromPage = newPage
				.elementsAsArray()
				.filter((el) => el.type === 'image')
				.map((img) => img.id);
			const foregroundWithoutBaseImages = foregroundImages.filter((fg) => !imagesFromPage.includes(fg.image));

			if (foregroundWithoutBaseImages.length) {
				foregroundWithoutBaseImages.forEach((fg) => {
					newPage.elements.delete(fg.id);
				});
			}
		}

		if (newPage.name === 'temporal') {
			newPage.name = 'New Page';
		}

		return newPage;
	}

	static async legacyCodeMigrator(page: Page) {
		return [
			LegacyMigratorTools.legacyElementsAsArray(page),
			await LegacyMigratorTools.legacyRemoveBg(page),
			LegacyMigratorTools.legacyCurvedTextWithGradients(page),
			LegacyMigratorTools.fixLockedElements(page),
			LegacyMigratorTools.legacyTextsWithoutPadding(page),
			LegacyMigratorTools.legacyTextColors(page),
			LegacyMigratorTools.legacyTextsChildrenColors(page),
		].some((changes) => changes);
	}

	static async preloadFonts(doc: Svg) {
		const { loadFonts, fonts, fixWeight } = useFonts();
		const { isAdminMode } = useEditorMode();

		// Buscamos las fuentes tanto de los fObj como de los textos nativos del SVG
		// y lo sustituimos por el slug correspondiente
		const fontsFamilies = [
			...new Set(
				doc.find('foreignObject [style*="font-family"], text').map((el) => {
					const fontFamilySplit = el.css('font-family').toString().split(', ');
					const fontName = fontFamilySplit[fontFamilySplit.length - 1]?.replaceAll('"', '') || 'Montserrat';
					const fontWeightInName = FontFamilyVariantsTools.findVariantInName(fontName);
					const fontWeight = fontWeightInName?.toString() || el.node.style.fontWeight || '400';

					const fontWithoutSuffix = TextTools.getFontNameWithoutSuffixVariant(fontName);
					const fontWithSpaces = TextTools.getFontNameSeparatedByCapitalLetters(fontName);
					const fontWithoutSuffixButWithSpaces = TextTools.getFontNameWithoutSuffixVariant(
						TextTools.getFontNameSeparatedByCapitalLetters(fontName)
					);

					const fontSlug =
						Object.values(fonts.value).find((f) => f.name === fontName)?.slug ||
						Object.values(fonts.value).find((f) => f.name === fontWithoutSuffix)?.slug ||
						Object.values(fonts.value).find((f) => f.name === fontWithSpaces)?.slug ||
						Object.values(fonts.value).find((f) => f.name === fontWithoutSuffixButWithSpaces)?.slug ||
						(isAdminMode.value ? 'Montserrat' : fontName);

					el.node.style.fontFamily = fontSlug;
					el.node.style.fontWeight = fontWeight;

					return {
						slug: fontSlug,
						weight: fontWeight,
					};
				})
			),
		];

		// Borramos los duplicados
		const uniqueFontsFamilies: FontFaceInfo[] = [];
		fontsFamilies.forEach((font) => {
			if (uniqueFontsFamilies.find((f) => f.family === font.slug && f.weights.includes(font.weight))) return;
			if (uniqueFontsFamilies.find((f) => f.family === font.slug)) {
				uniqueFontsFamilies.find((f) => f.family === font.slug)?.weights.push(font.weight);
				return;
			}
			uniqueFontsFamilies.push({ family: font.slug, slug: font.slug, weights: [font.weight] });
		});

		// seteamos las fuentes del documento con los pesos soportados por la familia de fuente
		const fontFamiliesWithFixedWeights = uniqueFontsFamilies.map((font) => {
			const fixedWeights = font.weights
				.map((weight: string) => fixWeight(font.slug, weight))
				.filter((weight): weight is string => typeof weight === 'string');

			return { ...font, weights: fixedWeights };
		});

		await loadFonts(fontFamiliesWithFixedWeights);
	}

	static getElementByType(element: SvgElementJS) {
		const type = ElementTools.getElementType(element);
		// Hay veces que podemos tener un texto dentro de un grupo, con esto lo evitamos
		if (type === 'g-text') {
			element = element.first();
		}

		switch (type) {
			case 'text':
			case 'g-text':
				return this.getText(element);

			case 'native-text':
				return this.getNativeText(element);

			case 'image':
				return this.getImage(element);

			case 'native-image':
				return this.getNativeImage(element);

			case 'storyset':
				return this.getStoryset(element);

			case 'line':
			case 'native-line':
				return this.getLine(element);

			case 'box':
				return this.getBox(element);

			default:
				return this.getShape(element);
		}
	}

	static fixArtboardLandscape(viewbox: BoxSVG, templateData: TemplateLoaderData) {
		if (templateData.artboard.width > templateData.artboard.height !== viewbox.width > viewbox.height) {
			const tempWidth = templateData.artboard.width;

			templateData.artboard.width = templateData.artboard.height;
			templateData.artboard.height = tempWidth;
		}
	}

	static getArtboardScale(width: number, templateData: TemplateLoaderData) {
		if (!templateData.artboard?.width || !templateData.artboard?.height) return 1;
		const { MM_TO_PX } = useArtboard();
		const mmToPx = templateData.artboard.unit === 'mm' ? MM_TO_PX : 1;
		return (templateData.artboard.width * mmToPx) / width;
	}

	static getPageBackgroundColor(svg: Svg, fill: string) {
		const bgIsGradient = fill.includes('url');

		if (bgIsGradient) {
			const idGradient = ElementTools.getIdFromUrl(fill);
			const gradient = svg.findOne(idGradient) as SvgElementJS;
			return gradient.type === 'pattern' ? SolidColor.black() : ElementTools.svgGradientToObject(gradient);
		}

		const [r, g, b, a] = Normalize(fill);
		// Si el background no tiene relleno aplicamos negro
		return fill.length ? new SolidColor(r * 255, g * 255, b * 255, a) : SolidColor.black();
	}

	static async parseSvg(svg: string, templateData: TemplateLoaderData): Promise<Page> {
		if (templateData.userVectorId) {
			templateData.forceSync = true;
		}

		const doc = SVG(svg).addTo(document.body) as Svg;

		const viewbox = doc.viewbox();
		doc.size(viewbox.width, viewbox.height);
		doc.attr('class', 'absolute top-0 left-0 opacity-0 -z-50');
		//doc.attr('class', 'absolute top-0 left-0 z-50');

		const hasArtboardSize = !!(templateData.artboard?.width && templateData.artboard.height);

		// Comprobamos si se respeta el landscape si no pues lo forzamos
		this.fixArtboardLandscape(viewbox, templateData);

		// Si tiene artboard calculamos la escala del svg
		const scaleSvg = this.getArtboardScale(viewbox.width, templateData);

		// Si el artboard no tiene ni width ni height usamos el viewbox del svg
		if (!hasArtboardSize) {
			templateData.artboard.height = viewbox.height;
			templateData.artboard.width = viewbox.width;
			templateData.artboard.unit = 'px';
		}

		this.fixPage(doc);
		this.setupGradients(doc, templateData);

		const docClipPath = this.getClipPathContainer(doc);
		const background = this.getBackground(doc);
		const container = this.getContainer(doc);
		const pageBackground = this.getPageBackgroundColor(doc, background.css('fill'));

		// Desagrupamos los grupos creados y dejamos un data para crear los grupos virtuales
		this.unGroup(doc);

		await this.preloadFonts(doc);

		// Extraemos los elementos
		const elements: Element[] = container
			.find(':scope > *:not([id*="background"]):not(title)')
			.filter((el: SvgElementJS) => {
				// Descartamos grupos vacíos
				const isInvalidG = el.type === 'g' && el.first() === null;
				const isClipContainerElement = el.id().toString().includes('clip-container');
				const isEmptyText = el.type === 'text' && !el.node.textContent?.length;
				const isMetatype = el.type === 'metadata';

				return !isInvalidG && !isClipContainerElement && !isEmptyText && !isMetatype;
			})
			.map((el: SvgElementJS) => TemplateLoader.getElementByType(el));

		const newPage = ref(Page.create());

		const { addElement, setBackground } = usePage(newPage);
		setBackground(pageBackground);

		const temporalRef = ref<Element>(Shape.create());
		const usingElementTransform = useElementTransformOrchestrator(temporalRef);
		elements.forEach((el) => {
			temporalRef.value = el;

			// Guardamos el estado del bloqueo para restaurarlo tras ajustar los elementos
			const currentLocked = el.locked;
			el.locked = false;

			// Ajustamos la posición de los elementos respecto al clip-path container
			if (!(el instanceof Image)) {
				usingElementTransform.value.move(-docClipPath.x, -docClipPath.y);
			}

			// Aplicamos la escala del svg al elemento
			if (scaleSvg !== 0) {
				temporalRef.value.scaleBy(scaleSvg);
			}

			el.locked = currentLocked;

			addElement(el);

			// Establecemos la imagen bloqueada como fondo
			if (el.type === 'image' && el.locked) {
				newPage.value.backgroundImageId = el.id;
			}
		});

		// Habilitar solo para debug
		//if (document.querySelectorAll('#POSTCARD').length === 1) {
		//	setTimeout(() => {
		//		// @ts-ignore
		//		const { x, y, height: h, width: w } = document.querySelector('[id*="canvas-"].canvas')?.getBoundingClientRect();
		//		doc.node.style.left = `${x}px`;
		//		doc.node.style.top = `${y}px`;
		//		doc.node.style.opacity = '0.2';
		//		doc.node.style.pointerEvents = 'none';
		//		doc.node.setAttribute('height', h.toString());
		//		doc.node.setAttribute('width', w.toString());
		//	}, 1000);
		//} else {
		//	doc.node.remove();
		//}

		doc.node.remove();

		return newPage.value;
	}

	private static getBackground(doc: Svg): Dom {
		let background = doc.findOne('[id*="background"]') as Dom;

		if (!background) {
			// Hay plantillas de freepik que simulan multi página usando el id print_1, print_2, etc
			// Nosotros solo buscamos en el primero, a no ser que no encontremos nada
			const printIds = doc
				.find('[id*="print_"]')
				.map((el) => `#${el.id()}`)
				.filter((id) => id !== '#print_1' && !id.includes('front') && !id.includes('back'));
			const elements = doc.find('rect, path').filter((el) => {
				// Ignoramos los elementos que no estén dentro de un print válido o en defs
				return [...printIds, 'defs'].every((id) => !el.node.closest(id));
			});
			const globalElements = doc.find('rect, path').filter((el) => {
				// Ignoramos los que están en defs
				return ['defs'].every((id) => !el.node.closest(id));
			});

			background = elements[0] || globalElements[0];
		}

		if (background) {
			background.id('background');
		}

		return background;
	}

	private static getContainer(doc: Svg) {
		const invalidTags = ['defs', 'title', '#text'];
		let container: any = doc;

		// Buscamos el container que tenga más de 1 hijo ya que normalmente
		// este será el container base
		while (container.children().filter((el: any) => !invalidTags.includes(el.type)).length === 1) {
			container = container.first();

			if (invalidTags.includes(container.type)) {
				container = container.next();
			}
		}

		// Si no encontramos un container válido usamos el clipPathContainer
		if (container.type !== 'g') {
			container = doc.findOne('#runtime-cp');
		}

		// En old-gen algunas plantillas tienen el container duplicado, así que tenemos que comprobar
		// que solo tenga un hijo (slug: cute-animal-pink-birthday-planner-r-1364521112 | id: 3465)
		const oldGenContainer = container.findOne('[id*="clip-container"] > [style*="clip-path"]');

		container = oldGenContainer || container;

		container.data('isContainer', true);

		return container;
	}

	private static fixPage(doc: Svg) {
		// Para simplificar las búsquedas de elementos ponemos todas las id en minúsculas,
		// a excepción de los que estén en defs
		doc.find('[id]').forEach((el) => {
			if (el.node.closest('defs') || ElementTools.defsTypes.includes(el.type)) return;

			el.id(el.id().toString().toLowerCase());
		});

		// Eliminamos el tag font, ya que no se soporta
		doc.find('font').forEach((tag) => tag.remove());

		// Reemplazamos el tag use por el elemento original
		doc.find('use').each((use) => {
			const useRefId = use.attr('xlink:href') || use.attr('href');
			const elRef = doc.findOne(useRefId) as SvgElementJS | null;

			if (!elRef) {
				console.warn('use element not found');
				return;
			}

			const elRefClone = elRef.clone();

			elRefClone.transform(use.transform(), true);

			use.node.parentElement?.insertBefore(elRefClone.node, use.node);

			use.remove();
		});

		// Movemos todos los elementos que deberían de estar en defs a defs
		ElementTools.fixDefsPosition(doc);

		// Mergeamos los estilos de las clases de los elementos
		const styleTags = doc.find('style');
		const styles = styleTags
			.map((elStyle) => elStyle.node.innerHTML)
			.join('')
			.replaceAll('\n', '')
			.replaceAll('\t', '');

		doc.find('[class]').forEach((elWithClass) => {
			const classes = ElementTools.getClassStyles(styles, elWithClass.attr('class'));
			const classesNames = classes.map((cc) => cc.class).filter((cc) => cc);

			// Buscamos las clases sin estilos, estas se usan para buscar elementos
			// durante el parseo
			const classesWithoutStyles = elWithClass
				.classes()
				.filter((c) => !classesNames.includes(c))
				.filter((c) => c.length);

			classes.forEach((classProps) => {
				elWithClass.css(classProps.key, classProps.value);
			});

			elWithClass.attr('class', null);

			// Restauramos las clases del parseo
			if (classesWithoutStyles.length) {
				elWithClass.attr('class', classesWithoutStyles.join(' '));
			}
		});

		styleTags.forEach((tag) => tag.remove());

		// text tspan styles export
		doc.find('text').forEach((text) => {
			const childStyles = text
				.find('tspan')
				.flatMap((tspan) => {
					const style: string | null = tspan.attr('style');

					if (style) {
						const styles = style
							.split(';')
							.map((s) => s.trim())
							.filter((s) => s.length);

						return styles.map((style: any) => {
							const [key, value] = style.split(':');

							return {
								key: key.trim(),
								value: value.trim(),
							};
						});
					}

					return [];
				})
				.filter((s) => s && s.key?.length > 0);

			const groupStyles: any[] = [];

			// Buscamos el estilo más repetido
			childStyles.forEach((style) => {
				const styleIndex = groupStyles.findIndex((s) => s.key === style.key && s.value === style.value);

				if (styleIndex !== -1) {
					groupStyles[styleIndex].count++;
				} else {
					groupStyles.push({ ...style, count: 1 });
				}
			});

			// Buscamos el estilo más repetido y lo aplicamos al text si no está definido
			const groupedStyles = groupBy(groupStyles, 'key');
			const mostRepeatedStyles = Object.keys(groupedStyles).map((key) => {
				const styles = groupedStyles[key];

				return styles.sort((a, b) => b.count - a.count)[0];
			});

			mostRepeatedStyles.forEach((style) => {
				if (!text.css(style.key)) {
					text.css(style.key, style.value);
				}
			});
		});

		// Las plantillas de slidesgo pueden tener el color de fondo como attr y no en el css
		const background = this.getBackground(doc);
		const fillColor =
			background.node.getAttribute('fill-color') || background.node.getAttribute('fill') || background.node.style.fill;
		const fillOpacity = background.attr('fill-opacity') !== undefined ? parseFloat(background.attr('fill-opacity')) : 1;

		if (fillColor && fillColor.includes('url')) {
			background.css('fill', fillColor);
		} else if (fillColor) {
			const [r, g, b] = Normalize(fillColor);
			const rgba = `rgba(${r * 255}, ${g * 255}, ${b * 255}, ${fillOpacity})`;

			background.css('fill', rgba);
		}

		// Hay veces que los grupos están formados de otra forma, usan el id custom-group
		// y tienen otros custom dentro, por lo que hay que hay que ponerles el data-group
		// para que podamos detectarlos
		doc
			.find('[id*="custom-group"]')
			.filter((el) => el.find('[id*="custom"], [data-type], [data-old-crop]').length > 0)
			.forEach((el) => {
				el.id('');
				el.data('group', true);
			});

		// Pueden llegar fObj con un type incorrecto, osea, en vez de tener un data-type="text"
		// tiene un data-type="custom"
		doc.find('foreignObject[data-type="custom"]').forEach((el) => el.data('type', 'text'));

		// Normalizamos los grupos de freepik
		doc.find('g[id="background"], g[id="photos"], g[id="objects"], g[id="texts"]').forEach((el) => {
			const parent = el.parent() as Dom;
			const parentPositionInTree = parent.index(el);
			let childrens = el.children();

			// Los elementos pueden no estar directamente en el grupo, por lo que
			// tenemos que buscarlos recursivamente
			while (childrens.length === 1 && childrens[0].type === 'g') {
				childrens = childrens[0].children();
			}

			Array.from(childrens)
				.reverse()
				.forEach((child) => {
					if (el.id() === 'background') {
						child.id('background');
					}

					child.toParent(parent, parentPositionInTree);
				});

			el.remove();
		});

		// Hay plantillas de freepik que simulan varias páginas usando el id print_1, print_2, etc
		// En estos casos tenemos que mover los elementos al container base para interpretarlo como
		// una sola página
		const hasMultiPrints =
			doc.find('[id*="print_"]').filter((el) => !el.id().includes('front') && !el.id().includes('back')).length > 1;

		if (hasMultiPrints) {
			doc.find('[id*="print_"] > *').forEach((el) => {
				const parent = el.parent();
				const parentBase = parent?.parent();

				if (!parent || !parentBase) return;

				el.toParent(parentBase);
			});
		}

		// Si detectamos multiples backgrounds ignoramos los que sean g
		const hasMultiBg = doc.find('#background').length > 1;

		if (hasMultiBg) {
			doc.find('g#background').forEach((el) => el.id(''));
		}
	}

	private static setupGradients(doc: Svg, templateData: TemplateLoaderData) {
		// Los degradados pueden venir en la API, así que los generamos
		// a partir de los datos que nos llegan
		templateData.gradients?.forEach((gradient) => {
			const newGradient = doc.gradient(gradient.type, (add) => {
				gradient.stops.forEach((stop) => {
					add.stop(stop.offset, stop.color, stop.opacity);
				});
			});

			newGradient.id(gradient.id);
			newGradient.attr('transform', gradient.transform);
		});

		const background = this.getBackground(doc);
		const container = background.parent() as Dom;

		// Comprobamos si el degradado del elemento existe, si no eliminamos el elemento
		container.find('[style*="url("]').forEach((el) => {
			const fill = el.css('fill');

			// Comprobamos que el fill con url existe para evitar búsquedas
			// de elementos con clip-path ya que tembién usan url
			if (!fill || !fill.includes('url')) {
				return;
			}

			const idGradient = ElementTools.getIdFromUrl(fill);
			const gradient = doc.defs().findOne(idGradient);
			const isBackground = el.id().toString().includes('background');

			if (!gradient && !isBackground) {
				el.remove();
			} else if (!gradient && isBackground) {
				el.css('fill', doc.css('background') || '#FFFFFF');
			}
		});
	}

	private static fixTextErrors(div: any) {
		// Ñapa para evitar bug Chrome Mac
		div.style.transform = null;

		if (div.style.lineHeight) {
			div.style.lineHeight = `${Math.round(parseFloat(div.style.lineHeight))}px`;
		}

		const fontSize = Math.round(parseFloat(div.style.fontSize));

		if (fontSize === parseInt(div.style.lineHeight) || !div.style.lineHeight) {
			div.style.lineHeight = `${fontSize}px`;
		}

		// Especificamos un letter spacing sin mucha precision para evitar diferentes letter spacing
		// en base a la precisión del navegador
		if (div.style.letterSpacing) {
			const style = getComputedStyle(div);
			const spacingInPx = parseFloat(style.letterSpacing);
			div.style.letterSpacing = `${spacingInPx.toFixed(2)}px`;
		}

		// Por lo que sea se meten nodos de textos vacios al final, y da problemas con la serializacion
		if (
			div.childNodes.length > 1 &&
			div.lastChild.nodeName === '#text' &&
			div.lastChild.textContent.trim().length === 0
		) {
			div.removeChild(div.lastChild);
		}

		// Corregimos el alto de los textos
		const foreign = div.closest('foreignObject');
		const transform = foreign.getAttribute('transform');

		foreign.setAttribute('transform', '');

		let { height } = div.getBoundingClientRect();

		if (height === 0) {
			const { isAdminMode } = useEditorMode();
			if (isAdminMode.value) {
				const toast = useToast();
				toast.error('Invalid height detected');
				height = 30;
			}
		}

		if (transform) {
			foreign.setAttribute('transform', transform);
		}

		foreign.setAttribute('height', height);
	}

	private static getClipPathContainer(doc: Svg): Position {
		const viewbox = doc.viewbox();
		let clipPathContainer = doc.findOne('g[id*="clip-container"] > g');

		// Si no encontramos el clipPathContainer buscamos respecto al fondo
		if (!clipPathContainer) {
			const bg = doc.findOne('[id="background"]');

			if (bg) {
				const bgParent = bg.parent() as SvgElementJS;
				const hasClipPath = (bgParent.attr('style') || '').includes('clip-path');

				if (hasClipPath) {
					clipPathContainer = bgParent;
				}
			}
		}

		// Tenemos en cuenta que aunque tenga un clip-path asignado este puede no existir
		let clipPathId = clipPathContainer?.node.style.clipPath.toString().replace('url("#', '').replace('")', '');
		let clipRect = doc.findOne(`#${clipPathId} > :not(g)`) as SvgElementJS | null;

		if (!clipPathContainer || !clipRect) {
			const clipParent = doc.group();
			let clipPath = doc.findOne('[id*="svgjsclippath"],[id=runtime-clip-path]');

			clipParent.id('clip-container');
			clipPathContainer = clipParent.group();
			clipPathContainer.id('runtime-cp');

			// Si no tenemos clip-path lo creamos con el tamaño del svg
			if (!clipPath) {
				clipPath = doc.clip();
				clipPath.id('runtime-clip-path');

				const clipPathHeight = parseFloat(viewbox.height.toString());
				const clipPathWidth = parseFloat(viewbox.width.toString());

				const rect = doc.rect(clipPathWidth, clipPathHeight);
				clipPath.add(rect);
			}

			clipPathContainer.node.style.clipPath = `url(#${clipPath.id()})`;
		}

		// Movemos los elementos al interior del clipContainer
		const clipParent = clipPathContainer.parent() as SvgElementJS;

		Array.from(doc.node.children).forEach((node) => {
			if (
				node !== clipParent.node &&
				node !== doc.defs().node &&
				!node.contains((clipPathContainer as SvgElementJS).node)
			) {
				(clipPathContainer as SvgElementJS).node.append(node);
			}
		});

		// Unificamos los defs para que sea más fácil buscar los elementos
		doc
			.find(`defs`)
			.filter((def) => def.node !== doc.defs().node)
			.forEach((def) => {
				Array.from(def.node.children).forEach((i) => doc.defs().node.append(i));
				def.node.remove();
			});

		clipPathId = clipPathContainer.node.style.clipPath.toString().replace('url("#', '').replace('")', '');
		clipRect = doc.findOne(`#${clipPathId} > :not(g)`) as SvgElementJS;
		const clipPath = clipRect.parent() as SvgElementJS;

		let x = 0;
		let y = 0;

		// Obtenemos la posición del clipPath y ajustamos el contenido
		if (clipRect) {
			x = parseFloat(clipPath?.transform().translateX?.toString() || viewbox.x.toString());
			y = parseFloat(clipPath?.transform().translateY?.toString() || viewbox.y.toString());

			x += parseFloat(clipRect.x().toString());
			y += parseFloat(clipRect.y().toString());
		}

		return {
			x,
			y,
		};
	}

	private static fixOldGroups(doc: Svg) {
		const oldGroups = doc.find('[data-group="true"]');

		if (!oldGroups || !oldGroups.length) {
			return;
		}

		oldGroups
			.filter((group) => group.children().length > 1)
			.forEach((group) => {
				const wrapperGroup = doc.group();

				group.children().forEach((element) => {
					element.toParent(wrapperGroup);
				});

				wrapperGroup.toParent(group);
			});
	}

	private static searchFreepikGroups(doc: Svg) {
		// Si detectamos que dentro de un g hay una imagen un texto o una línea
		// lo tomamos como un grupo, lo mismo si solo hay grupos
		doc.find('[data-isContainer] > g').each((g) => {
			const isGroup = g.find('image, text, line').length > 0 || g.children().every((el) => el.type === 'g');
			const isWepikElement = !!g.data('group') || !!g.data('type');

			if (isGroup && !isWepikElement) {
				g.data('freepikGroup', true);
				Array.from(g.find('image, text, line'))
					.reverse()
					.forEach((el) => {
						el.toParent(g);
					});
				g.find('g').forEach((el) => {
					if (!el.node.hasChildNodes()) {
						el.remove();
					}
				});
			}
		});
	}

	private static unifyTextShadows(doc: Svg) {
		doc
			.find('text')
			.filter((text) => {
				const mainParentG = text.parent()?.parent() as SvgElementJS | null;
				const parent = text.parent() as SvgElementJS | null;

				if (!mainParentG || !parent) return;

				// Verificamos que el texto sea uno de los que simulan sombra
				return mainParentG.children().every((el) => {
					return el.type === 'g' && el.children().length === 1 && el.children()[0].type === 'text';
				});
			})
			.map((text) => {
				// Nos quedamos con el grupo padre que contiene ambos textos, el original y el que
				// simulaba la sombra
				return text.parent()?.parent() as SvgElementJS;
			})
			.forEach((g) => {
				const texts = g.find('text').toArray();

				if (texts.length !== 2) return;

				const shadowText = texts[0];
				const mainText = texts[1];

				const textEffectX = shadowText.transform()?.translateX || 0;
				const textEffectY = shadowText.transform()?.translateY || 0;
				const x = mainText.transform()?.translateX || 0;
				const y = mainText.transform()?.translateY || 0;
				const diffX = textEffectX - x;
				const diffY = textEffectY - y;

				const color = SolidColor.fromString(shadowText.css('fill') || '#000000');
				const opacity = parseFloat(shadowText.parent()?.css('opacity') || '1');

				color.a = opacity;

				mainText.node.style.textShadow = `${color.toCssString()} ${diffX}px ${diffY}px 0px`;

				shadowText.remove();
			});
	}

	private static unGroup(doc: Svg) {
		// Si hay grupos antiguos los actualizamos
		this.fixOldGroups(doc);

		// Buscamos los textos que simulan ser sombras de otros para unificarlos
		this.unifyTextShadows(doc);

		// Identificamos los grupos de las plantillas de Freepik
		this.searchFreepikGroups(doc);

		doc.find('[data-group="true"], [data-freepikGroup]').each((group) => {
			const gId = uuidv4();
			let gContainer = group.data('freepikGroup') ? group : group.last();

			// Buscamos el padre de los elementos del grupo, ya que por un
			// bug antiguo hay veces que están anidados en más de 1 grupos
			while (
				gContainer &&
				!gContainer.data('freepikGroup') &&
				gContainer.children().length &&
				!gContainer.find(':scope > [id*="custom"], :scope > [data-type], :scope > [data-old-crop]').length
			) {
				gContainer = gContainer.last();
			}

			gContainer?.children().forEach((element) => {
				const container = group.parent() as Dom;
				let parent = element.parent() as SvgElementJS;

				// Buscamos el padre base y vamos aplicando los transforms según buscamos
				while (parent !== container) {
					element.transform(parent.transform(), true);
					parent = parent.parent() as SvgElementJS;
				}

				// Le asignamos un data para identificar que elementos pertenian al grupo
				element.data('groupId', gId);

				// Lo colocamos en la posición del grupo para que mantenga su z-index
				container.node.insertBefore(element.node, group.node);
			});

			group.remove();
		});
	}

	static getText(el: SvgElementJS): Text {
		const divs = el.children();
		let mainDiv = divs[0].node;
		let borderDiv = divs[divs.length === 1 ? 0 : 1].node;

		// Hay textos que no respetan el orden de los divs, así que buscamos el que tenga el border
		if (mainDiv.style.webkitTextStrokeColor) {
			mainDiv = divs[divs.length === 1 ? 0 : 1].node;
			borderDiv = divs[0].node;
		}

		this.fixTextErrors(mainDiv);

		// Anulamos la rotación para poder calcular la posición correctamente
		const rotation = parseFloat(el.transform('rotate').toString());
		el.transform({ rotate: -rotation }, true);

		const mainDivStyle = getComputedStyle(mainDiv);

		// Actualizamos los line-height del contenido
		mainDiv.querySelectorAll<HTMLElement>('[style*="line-height"]').forEach((line) => {
			const fontSize = parseFloat(line.style.fontSize.toString()) || parseFloat(mainDivStyle.fontSize);
			line.style.lineHeight = (parseFloat(line.style.lineHeight) / fontSize || 1.2).toString();
		});
		const textShadow = TextTools.getTextShadow(el);
		const content = mainDiv.innerHTML;
		const fontFamily = mainDivStyle.fontFamily.replace(/['"]+/g, '');

		let fontWeight = parseFloat(mainDiv.style.fontWeight || '400') as FontWeight;
		const nearestWeight = TextTools.getNearestWeight(fontFamily, fontWeight);
		fontWeight = nearestWeight.weight as FontWeight;

		const fontStyle = mainDivStyle.fontStyle as FontStyle;
		const fontSize = parseFloat(mainDivStyle.fontSize);
		const lineHeight = parseFloat(mainDiv.style.lineHeight) / fontSize || 1.2;
		const letterSpacing = parseFloat(mainDivStyle.letterSpacing) || 0;
		const textAlign = mainDivStyle.textAlign as TextAlign;
		// Se cambia el tipo de parseo porque en algunos navegadores cuando llega con decimales falla
		const outlineWidth = parseInt(borderDiv.style.webkitTextStrokeWidth || '0');
		const outline = {
			color:
				outlineWidth > 0 ? SolidColor.fromString(borderDiv.style.webkitTextStrokeColor) : Text.defaults().outline.color,
			width: outlineWidth,
			unit: 'px',
		};
		const color = SolidColor.fromString(mainDivStyle.color);
		const textTransform = (mainDivStyle.textTransform !== 'none' ? mainDivStyle.textTransform : '') as TextTransform;
		const size = {
			width: parseFloat(el.width().toString()) * parseFloat(el.transform('scaleX').toString()),
			height: parseFloat(el.height().toString()) * parseFloat(el.transform('scaleY').toString()),
		};
		const position = {
			x: parseFloat(el.attr('x')) + parseFloat(el.transform('translateX').toString()),
			y: parseFloat(el.attr('y')) + parseFloat(el.transform('translateY').toString()),
		};
		const group = el.data('groupId') || null;
		const scale = parseFloat(el.transform('scaleX').toString());

		const newText = Text.create({
			content,
			size,
			position,
			rotation,
			group,
			fontFamily,
			fontWeight,
			fontStyle,
			fontSize,
			lineHeight,
			letterSpacing,
			textAlign,
			outline,
			textShadow,
			colors: [color],
			textTransform,
			scale,
		});

		if (nearestWeight.isInvalidFont) {
			newText.addMetadata({
				hasInvalidFont: true,
			});
		}

		return newText;
	}

	static getNativeText(textNode: SvgElementJS): Text {
		// Si el texto tiene textPath usamos este para obtener la posición
		const textPath = textNode.findOne('textPath');
		const textPathId = textPath?.attr('href') || textPath?.attr('xlink:href');
		const textPathElement = textPathId
			? (textNode.root().findOne(`${textPathId}, ${textPathId.toLowerCase()}`) as SvgElementJS | null)
			: null;
		const { x: textPathX, y: textPathY } = textPathElement ? textPathElement.bbox() : { x: 0, y: 0 };

		// Si usa textPath tenemos que eliminar el letter-spacing ya que no se soporta
		textNode.find('textPath').forEach((el) => {
			el.find('[style*="letter-spacing"]').forEach((el) => (el.node.style.letterSpacing = '0'));
			el.node.outerHTML = el.node.innerHTML;
		});

		const { scaleX } = textNode.transform();
		const { fontFamily, nearestWeight, fontStyle, fontSize, letterSpacing, textShadow, outline, color } =
			TextTools.extractFontDataFromText(textNode);
		const fontWeight = nearestWeight.weight as FontWeight;
		const { content, x, y, height, width, textAlign, lineHeight } = TextTools.extractTextData(
			textNode,
			fontFamily,
			fontWeight as number,
			fontSize
		);
		const groupId = textNode.data('groupId') || null;

		const newText = Text.create({
			content,
			group: groupId,
			scale: scaleX,
			fontFamily,
			lineHeight,
			fontWeight,
			fontStyle,
			fontSize,
			letterSpacing,
			textShadow: Array.isArray(textShadow) ? textShadow : [textShadow],
			outline,
			textAlign,
			colors: [color],
			size: {
				width,
				height,
			},
			position: {
				x: textPathX || x,
				y: textPathY || y,
			},
			metadata: {
				autofix: {
					box: true,
				},
			},
		});

		if (nearestWeight.isInvalidFont) {
			newText.addMetadata({
				hasInvalidFont: true,
			});
		}

		return newText;
	}

	static getImage(el: SvgElementJS): Image {
		const mainImage = el.findOne('.main-image') as SvgElementJS;
		const rectHandler = el.findOne('.rect-handler') as SvgElementJS;
		const isNewCropsParser = mainImage && rectHandler;

		// Las imágenes del antiguo crop no tienen filtros así que las ignoramos
		const filter = mainImage ? this.getFilterData(mainImage) : null;

		const { crop, flip, keepProportions, opacity, position, rotation, size, url } = isNewCropsParser
			? this.cropParser(el)
			: this.oldCropParser(el);

		const group = el.data('groupId') || null;
		const locked = el.data('locked') || false;
		const mask = this.maskParser(el);
		const userUpload = mainImage ? mainImage.data('uploadid') : null;

		let metadata = {};

		if (userUpload) {
			metadata = { uploadId: userUpload };
		}

		// Pre-request the image
		document.createElement('img').src = url;

		const newImage = Image.create({
			url,
			size,
			position,
			rotation,
			flip,
			group,
			locked,
			keepProportions,
			opacity,
			crop,
			mask,
			filter,
			metadata,
		});
		newImage.setGroup(group);

		return newImage;
	}

	private static getNativeImage(el: SvgElementJS): Image {
		const url = el.attr('xlink:href') || el.attr('href');
		const size = {
			width: parseFloat(el.width().toString()) * parseFloat(el.transform('scaleX').toString()),
			height: parseFloat(el.height().toString()) * parseFloat(el.transform('scaleY').toString()),
		};
		const position = {
			x:
				parseFloat(el.transform('translateX').toString()) +
				parseFloat(el.x().toString()) * parseFloat(el.transform('scaleX').toString()),
			y:
				parseFloat(el.transform('translateY').toString()) +
				parseFloat(el.y().toString()) * parseFloat(el.transform('scaleY').toString()),
		};
		const rotation = parseFloat(el.transform('rotate').toString());
		const flip = {
			x: parseFloat(el.transform('a').toString()) < 0,
			y: parseFloat(el.transform('d').toString()) < 0,
		};
		const opacity = el.opacity();
		const group = el.data('groupId') || null;

		return Image.create({
			url,
			size,
			position,
			rotation,
			flip,
			opacity,
			group,
		});
	}

	private static getFilterData(el: SvgElementJS) {
		const filterAttr = el.attr('filter');

		if (!filterAttr) return;

		// Buscamos el defs del filtro
		const filterId = ElementTools.getIdFromUrl(filterAttr);
		const filterSvg = el.defs().findOne(filterId);
		if (!filterSvg) return;

		// Extraemos los datos del filtro
		const filterData = el.data('filter') ? el.data('filter').split(' ') : null;

		const brightness = (filterData ? parseFloat(filterData[0]) : 1) * 100;
		const saturate = (filterData ? parseFloat(filterData[1]) : 1) * 100;
		const contrast = (filterData ? parseFloat(filterData[2]) : 1) * 100;
		const hueRotate = (filterData ? parseFloat(filterData[3]) : 0) * 100;
		const blur = (filterData ? parseFloat(filterData[4]) : 0) * 100;
		const filterName = filterData ? filterData[5] : '';
		const grayscale = filterName === 'inkwell' ? 100 : null;

		const filter = new Filter({
			contrast,
			brightness,
			saturate,
			grayscale,
			hueRotate,
			blur,
		});

		return filter;
	}

	private static cropParser(element: SvgElementJS) {
		// Get elements to extract data
		const mainImage = element.findOne('.main-image') as SvgElementJS;

		// Creamos una imagen con las mismas transformaciones que el rectHandler, esto se hace porque el rectHandler
		// al quitarle la rotación se le produce un skew que nos acaba dando datos incorrectos, usamos esta copia para
		// trabajar con los datos

		// @ts-ignore
		const cloneRectImage = element.image('https://wepik.com/svg/mask-placeholder.svg');
		const rectHandler = element.findOne('.rect-handler') as SvgElementJS;
		cloneRectImage.transform(rectHandler.transform());
		cloneRectImage.width(rectHandler.width());
		cloneRectImage.height(rectHandler.height());
		cloneRectImage.x(rectHandler.x());
		cloneRectImage.y(rectHandler.y());

		const clipPathId = element.data('cpmask') || element.data('cp');
		const clipPath = clipPathId && (element.root().findOne(`defs > clipPath[id="${clipPathId}"]`) as SvgElementJS);
		const clipPathRectSizeMaskId =
			element.data('cp-rect-size') || element.data('cp-rectsize') || element.data('cpmask');
		const clipPathRectSizeMask =
			clipPathRectSizeMaskId &&
			(element.root().findOne(`defs > clipPath[id="${clipPathRectSizeMaskId}"]`) as SvgElementJS);
		const isMask = clipPath && clipPath.data('ismask');
		// El clippath de máscaras en safari no nos da width/height usamos el rectsize (clippath auxiliar) para obtenerlo
		const clipPathMainNode = clipPath
			? {
					x: isMask ? clipPath.transform('e') : clipPath.children()[0].x(),
					y: isMask ? clipPath.transform('f') : clipPath.children()[0].y(),
					width: isMask ? clipPathRectSizeMask.children()[0].width() : clipPath.children()[0].width(),
					height: isMask ? clipPathRectSizeMask.children()[0].height() : clipPath.children()[0].height(),
			  }
			: {
					x: 0,
					y: 0,
					width: mainImage.width(),
					height: mainImage.height(),
			  };

		// Data
		const flip = {
			x: parseFloat(mainImage.transform('a').toString()) < 0,
			y: parseFloat(mainImage.transform('d').toString()) < 0,
		};
		const keepProportions = !rectHandler.data('free-ratio') || true;
		const opacity = mainImage.opacity();
		const url = mainImage.attr('href') || mainImage.attr('xlink:href');

		// Get rotation before start parsing crop
		const rotation = parseFloat(rectHandler.transform('rotate').toString());

		mainImage.toRoot();
		mainImage.rotate(-rotation);

		const mainImageScale = {
			x: parseFloat(mainImage.transform('a').toString()),
			y: parseFloat(mainImage.transform('d').toString()),
		};
		const crop = {
			size: {
				width: (mainImage.width() as number) * Math.abs(mainImageScale.x),
				height: (mainImage.height() as number) * Math.abs(mainImageScale.y),
			},
			position: {
				x: (-clipPathMainNode.x || 0) * mainImageScale.x,
				y: (-clipPathMainNode.y || 0) * mainImageScale.y,
			},
		};

		const cloneRotation = cloneRectImage.transform('rotate');
		cloneRectImage.toRoot();
		cloneRectImage.rotate(-cloneRotation);

		const size = {
			width: cloneRectImage.width() * cloneRectImage.transform('a'),
			height: cloneRectImage.height() * cloneRectImage.transform('d'),
		};

		const position = {
			x: cloneRectImage.x() * cloneRectImage.transform('scaleX') + cloneRectImage.transform('e'),
			y: cloneRectImage.y() * cloneRectImage.transform('scaleY') + cloneRectImage.transform('f'),
		};

		if (mainImageScale.x < 0) {
			crop.position.x *= -1;
		}

		if (mainImageScale.y < 0) {
			crop.position.y *= -1;
		}

		return { crop, flip, keepProportions, opacity, position, rotation, size, url };
	}

	private static oldCropParser(element: SvgElementJS) {
		// Get elements to extract data
		const elTransform = element.transform();
		element.attr('transform', null);
		const image = element.find('image')[0];

		// Data
		const flip = {
			x: parseFloat(image.transform('a').toString()) < 0,
			y: parseFloat(image.transform('d').toString()) < 0,
		};
		const keepProportions = true;
		const opacity = element.opacity();
		const url = image.data('rawurl');

		// fix for lazy json
		if (image.data('size-raw') && typeof image.data('size-raw') === 'string') {
			const sizeRaw = JSON.parse(image.data('size-raw').replace(/(['"])?([a-z0-9A-Z_]+)(['"])?:/g, '"$2": '));
			image.data('size-raw', sizeRaw);
		}

		if (!image.data('cropScaleX') && !image.data('crop-scale-x')) {
			image.data('cropScaleX', elTransform.scaleX);
			image.data('cropScaleY', elTransform.scaleY);
		}

		const cropScale = {
			x: image.data('cropScaleX') || image.data('crop-scale-x'),
			y: image.data('cropScaleY') || image.data('crop-scale-y'),
		};

		const scaleDiffX = !image.data('size-raw') ? cropScale.x : (elTransform.scaleX as number) / cropScale.x;
		const scaleDiffY = !image.data('size-raw') ? cropScale.y : (elTransform.scaleY as number) / cropScale.y;

		// setup for old uncropped images
		if (!image.data('size-raw')) {
			image.data('size-raw', {
				width: image.data('cropw'),
				height: image.data('croph'),
				left: 0,
				top: 0,
			});
		}

		// handle escalation after crop was done
		if (scaleDiffX !== 1 || scaleDiffY !== 1) {
			image.data('size-raw', {
				width: image.data('size-raw').width * scaleDiffX,
				height: image.data('size-raw').height * scaleDiffY,
				left: image.data('size-raw').left * scaleDiffX,
				top: image.data('size-raw').top * scaleDiffY,
			});

			image.data('cropw', image.data('cropw') * scaleDiffX);
			image.data('croph', image.data('croph') * scaleDiffY);
		}

		const crop = {
			position: {
				x: -image.data('cropx') + image.data('imagecropx'),
				y: -image.data('cropy') + image.data('imagecropy'),
			},
			size: {
				width: image.data('size-raw').width,
				height: image.data('size-raw').height,
			},
		};
		const position = {
			x: elTransform.e || 0,
			y: elTransform.f || 0,
		};
		const size = {
			width: image.data('cropw'),
			height: image.data('croph'),
		};

		// Remove parents <g> offsets
		const parents = element.parents('svg');
		parents.forEach((parent) => {
			if (parent.type === 'svg') return;
			const transform = parent.transform();
			position.x += transform.e || 0;
			position.y += transform.f || 0;
		});

		// Set parent rotation to children
		const rotation = elTransform.rotate;

		if (rotation) {
			const sign = rotation > 0 ? 1 : -1;
			const abs = Math.abs(rotation);

			let angle = rotation;

			if (abs > 89.0) {
				angle = Math.ceil(abs) * sign;
			}

			if (abs < 0.1) {
				angle = Math.floor(abs) * sign;
			}

			const sin = Math.sin(angle * (Math.PI / 180));

			if (angle > 0) {
				position.x = sin * image.data('croph');
			}

			if (angle < 0) {
				position.y = -sin * image.data('cropw');
			}
		}

		return { crop, flip, keepProportions, opacity, position, rotation, size, url };
	}

	private static maskParser(element: SvgElementJS) {
		const clipPathMaskId = element.data('cpmask');

		if (!clipPathMaskId) return null;

		const clipPathMask = element.root().findOne(`defs > clipPath[id="${clipPathMaskId}"]`) as ClipPath;
		const maskApiId = element.data('mask-selected');

		return Mask.fromTemplate(maskApiId, clipPathMaskId, clipPathMask);
	}

	static getBox(el: SvgElementJS): Box {
		const groupId = el.data('groupId') || null;
		const keepProportions = !el.data('free-ratio');
		const rotation = parseFloat(el.transform('rotate').toString());
		const flip = {
			x: false,
			y: false,
		};
		const opacity = parseFloat(el.css('opacity')) || el.opacity() || 1;
		const viewbox = `${el.x()} ${el.y()} ${el.width()} ${el.height()}`;

		// Anulamos la rotación y el flip para evitar que se tenga en cuenta en el Shape.fromSvg
		el.transform({ rotate: -rotation }, true);

		const newBox = Box.fromSvg(
			`<svg viewBox="${viewbox}"><defs>${el.root().defs().node.innerHTML}</defs>${el.node.outerHTML}</svg>`
		);

		newBox.setRotation(rotation);
		newBox.flip = flip;
		newBox.opacity = opacity;
		newBox.keepProportions = keepProportions;
		newBox.setGroup(groupId);

		return newBox;
	}

	static getShape(el: SvgElementJS): Shape {
		// Para los elementos que no son grupos, por ejemplo los rect que tenemos en algunas plantillas
		const isSingleElement = el.type !== 'g';

		const groupId = el.data('groupId') || null;
		const keepProportions = !el.data('free-ratio');
		const rotation = parseFloat(el.transform('rotate').toString());
		const flip = {
			x: !isSingleElement ? parseFloat(el.first().transform && el.first().transform('scaleX').toString()) < 0 : false,
			y: !isSingleElement ? parseFloat(el.first().transform && el.first().transform('scaleY').toString()) < 0 : false,
		};
		const opacity = isSingleElement ? 1 : parseFloat(el.css('opacity') || '1');
		const viewbox = `${el.x()} ${el.y()} ${el.width()} ${el.height()}`;

		// Anulamos la rotación y el flip para evitar que se tenga en cuenta en el Shape.fromSvg
		el.transform({ rotate: -rotation }, true);

		if (!isSingleElement) {
			if (flip.x) el.first().flip('x');
			if (flip.y) el.first().flip('y');
			el.css('opacity', '');
		}

		const newShape = Shape.fromSvg(
			`<svg viewBox="${viewbox}"><defs>${el.root().defs().node.innerHTML}</defs>${el.node.outerHTML}</svg>`
		);
		newShape.setRotation(rotation);
		newShape.flip = flip;
		newShape.opacity = opacity;
		newShape.keepProportions = keepProportions;
		newShape.setGroup(groupId);

		return newShape;
	}

	private static getStoryset(el: SvgElementJS): Storyset {
		const groupId = el.data('groupId') || null;
		const keepProportions = !el.data('free-ratio');
		const viewbox = `${el.x()} ${el.y()} ${el.width()} ${el.height()}`;
		const size = {
			width: parseFloat(el.width().toString()) * parseFloat(el.transform('scaleX').toString()),
			height: parseFloat(el.height().toString()) * parseFloat(el.transform('scaleY').toString()),
		};
		const position = {
			x:
				parseFloat(el.transform('translateX').toString()) +
				parseFloat(el.x().toString()) * parseFloat(el.transform('scaleX').toString()),
			y:
				parseFloat(el.transform('translateY').toString()) +
				parseFloat(el.y().toString()) * parseFloat(el.transform('scaleY').toString()),
		};
		const rotation = parseFloat(el.transform('rotate').toString());
		const flip = {
			x: parseFloat(el.first().transform('scaleX').toString()) < 0,
			y: parseFloat(el.first().transform('scaleY').toString()) < 0,
		};
		const opacity = parseFloat(el.css('opacity') || '1');

		let mainColor: Color = SolidColor.black();
		let colorSelector = '';

		if (el.data('color').includes('url')) {
			// Buscamos el degradado correcto ya que se genera un temporal
			let gradientId = Array.from(
				el.node.outerHTML
					.toString()
					.replaceAll('&quot;', '"')
					.matchAll(/url\("#(.*?)"\)/g),
				(url) => url[0]
			).find((url) => !url.includes('temporal')) as string;

			gradientId = ElementTools.getIdFromUrl(gradientId);

			// Sustituimos el degrado temporal por el principal
			el.node.innerHTML = el.node.innerHTML.replaceAll('#temporal-gradient', gradientId);

			colorSelector = gradientId;

			const gradientEl = el.defs().findOne(gradientId) as SvgElementJS;

			if (gradientEl.type === 'linearGradient' || gradientEl.type === 'radialGradient') {
				mainColor = ElementTools.svgGradientToObject(gradientEl);
			} else {
				Bugsnag.notify('Storyset main color not found');
			}
		} else {
			mainColor = SolidColor.fromString(el.data('color'));
			colorSelector = mainColor.toRgb();
		}

		// Sustituimos el color principal por un placeholder para evitar conflictos en el comportamiento
		el.find(`[style*="${colorSelector}"]`).forEach((elChild) => {
			const fill = ElementTools.getIdFromUrl(elChild.css('fill')) === colorSelector;

			if (fill) {
				elChild.css('fill', 'var(--main-storyset-color)');
			}

			const stroke = ElementTools.getIdFromUrl(elChild.css('stroke')) === colorSelector;

			if (stroke) {
				elChild.css('stroke', 'var(--main-storyset-color)');
			}
		});

		const storysetSvg = el.first().node.innerHTML;
		const newStoryset = Storyset.fromSvg(`<svg viewBox="${viewbox}">${storysetSvg}</svg>`);

		newStoryset.viewbox = viewbox;
		newStoryset.size = size;
		newStoryset.position = position;
		newStoryset.setRotation(rotation);
		newStoryset.flip = flip;
		newStoryset.opacity = opacity;
		newStoryset.mainColor = mainColor;
		newStoryset.keepProportions = keepProportions;
		newStoryset.setGroup(groupId);

		return newStoryset;
	}

	static getLine(el: SvgElementJS): Line {
		const groupId = el.data('groupId') || null;

		// Hay que tener en cuenta el transform del <g> base
		const scaleX = parseFloat(el.transform('scaleX').toString()) || 1;
		const scaleY = parseFloat(el.transform('scaleY').toString()) || 1;
		const translateX = parseFloat(el.transform('translateX').toString());
		const translateY = parseFloat(el.transform('translateY').toString());

		const realLine = el.type === 'line' ? el : el.first();

		const x1 = translateX + parseFloat((realLine.attr('x1') || '0').toString()) * scaleX;
		const y1 = translateY + parseFloat((realLine.attr('y1') || '0').toString()) * scaleY;
		const x2 = translateX + parseFloat((realLine.attr('x2') || '0').toString()) * scaleX;
		const y2 = translateY + parseFloat((realLine.attr('y2') || '0').toString()) * scaleY;

		const opacity = parseFloat(el.css('opacity') || '1');

		const height = parseFloat(realLine.css('stroke-width').toString() || '1') * scaleY;
		const width = MathTools.getDistanceBetween2Points(x1, y1, x2, y2);
		const rotation = MathTools.getAngle(x1, y1, x2, y2);

		// Calculamos la posición en base a la rotación
		const origin = {
			x: x1 + width / 2,
			y: y1 + height / 2,
		};
		const rotatePosition = MathTools.rotatePoint(origin.x, origin.y, x1, y1, rotation);
		const diff = {
			x: rotatePosition.x - x1,
			y: rotatePosition.y - y1,
		};

		const newLine = Line.fromSvg(`<svg>${realLine.node.outerHTML}</svg>`);
		newLine.setSize(width, height);
		newLine.setGroup(groupId);
		newLine.setPosition(x1 - diff.x, y1 - diff.y);
		newLine.setRotation(rotation);
		newLine.setOpacity(opacity);

		return newLine;
	}

	static async fromPredefinedText(url: string) {
		const { data, response } = await getSvg(url).text();
		const contentType = response.value?.headers?.get('content-type');
		const dataCasted = contentType === 'application/json' ? JSON.parse(data.value as string) : data.value;

		return typeof dataCasted === 'string'
			? await this.parsePredefinedText(dataCasted)
			: this.loadPredefinedTextFromData(dataCasted);
	}

	private static async parsePredefinedText(data: any) {
		const doc = SVG(data).addTo(document.body) as Svg;
		const viewbox = doc.viewbox();

		doc.height(viewbox.height);
		doc.width(viewbox.width);
		doc.attr('class', 'absolute top-0 left-0 opacity-0 -z-50');

		const background = this.getBackground(doc);
		const container = background.parent() as Dom;
		this.unGroup(doc);

		await this.preloadFonts(doc);

		const elements = container
			.find(':scope > *:not([id*="background"])')
			.map((el) => TemplateLoader.getElementByType(el));

		doc.node.remove();

		return elements;
	}

	private static loadPredefinedTextFromData(data: any): Element[] {
		const { fixWeight } = useFonts();

		const idGroup = uuidv4();
		const elements: Text[] = data.elements.map((element: any) => {
			element.group = idGroup;
			const unserializedElement = this.unserializeElement(element);
			// En el unserialize se mantiene persistencia del valor del id, por lo que al añadir un texto predefinido, volvemos a asignar su id
			unserializedElement.id = uuidv4();
			return unserializedElement;
		});

		// seteamos los textos con pesos soportados por la familia de fuente
		const elementsWithFixedTextWeight = elements.map((el): Text => {
			if (el instanceof Text) {
				const fixedWeight = fixWeight(el.fontFamily, el.fontWeight.toString());
				return Text.create({ ...el, fontWeight: parseInt(fixedWeight) as FontWeight });
			}

			return el;
		});

		return elementsWithFixedTextWeight;
	}

	static unserializeElement(element: any): Element {
		switch (element.type) {
			case 'shape': {
				try {
					const parser = new DOMParser();
					const elementContent = parser.parseFromString(element.content, 'text/html');

					const realContent = Object.values(elementContent.querySelectorAll('svg')).filter((item) => {
						return item.firstElementChild?.tagName !== 'svg';
					})[0]?.innerHTML;

					if (realContent) {
						element.content = realContent.replaceAll('NaN', '0');
					}

					const tempContent = `<svg>${element.content}</svg>`;

					if (ElementTools.isBox(tempContent)) {
						return Box.fromShape(element);
					}
				} catch (e) {
					console.error(e);
				}
				return Shape.unserialize(element);
			}

			case 'box': {
				return Box.unserialize(element);
			}

			case 'text': {
				return Text.unserialize(element);
			}

			case 'image': {
				return Image.unserialize(element);
			}

			case 'storyset': {
				return Storyset.unserialize(element);
			}

			case 'line': {
				return Line.unserialize(element);
			}

			case 'qrcode': {
				return QRCode.unserialize(element);
			}

			case 'foregroundImage': {
				return ForegroundImage.unserialize(element);
			}

			case 'video': {
				return Video.unserialize(element);
			}

			default:
				throw new Error('Invalid element type');
		}
	}
}

export default TemplateLoader;
