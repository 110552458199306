import { TimeTools } from '@/common/utils/TimeTools';
import Element from '@/elements/element/classes/Element';
import { PurifyUnserialize } from '@/elements/element/utils/PurifyUnserialize';
import { Croppeable } from '@/elements/medias/crop/types/croppeable.type';
import { Crop } from '@/elements/medias/crop/types/croppeable.type';
import { Filter } from '@/elements/medias/filter/classes/Filter';
import { Filterable } from '@/elements/medias/filter/types/filterable.type';
import { LegacyImageValues } from '@/elements/medias/images/image/classes/Image';
import Mask from '@/elements/medias/mask/classes/Mask';
import { Maskable } from '@/elements/medias/mask/types/maskable.type';
import { VideoTools } from '@/elements/medias/video/utils/VideoTools';
import { UploadApi, VideoApi } from '@/Types/apiClient';
import { VideoDTO } from '@/Types/elements';
import { SerializedClass } from '@/Types/types';

type Milliseconds = number;

interface CropTime {
	start: Milliseconds;
	end: Milliseconds;
}

export class Video extends Element implements Maskable, Croppeable, Filterable {
	type: 'video' = 'video';
	url: string;
	preview: string | null;
	filter: Filter | null;
	crop: Crop;
	mask: Mask | null;
	cropTime: CropTime;
	muted = true;

	protected constructor(videoDTO: VideoDTO) {
		super(videoDTO);
		this.url = videoDTO.url;
		this.preview = videoDTO.preview;
		this.filter = videoDTO.filter;
		this.crop = videoDTO.crop;
		this.mask = videoDTO.mask;
		this.cropTime = videoDTO.cropTime;
		this.muted = videoDTO.muted;
	}

	static defaults(): VideoDTO {
		return {
			// Element
			...Element.defaults(),
			type: 'video',
			// Image
			url: `https://content.wepik.com/statics/tests/60fps.mp4`,
			preview: null,
			filter: null,
			mask: null,
			crop: {
				size: {
					width: 0,
					height: 0,
				},
				position: {
					x: 0,
					y: 0,
				},
			},
			cropTime: {
				start: 0,
				end: 0.5,
			},
			muted: true,
		};
	}

	@PurifyUnserialize()
	static unserialize(data: SerializedClass<Video> & LegacyImageValues): Video {
		const videoDTO = {
			...Video.defaults(),
			...data,
		} as VideoDTO;

		// Parsed data.subElements when needed
		videoDTO.subElements = new Map<string, Element>();

		videoDTO.filter = data.filter ? Filter.unserialize(data.filter) : null;
		videoDTO.mask = data.mask ? Mask.unserialize(data.mask) : null;

		const elem = new Video(videoDTO);

		if (data.id) {
			elem.id = data.id;
		}

		if (elem.metadata.temporal) {
			delete elem.metadata.temporal;
		}

		return elem;
	}

	public setMask(mask: Mask | null) {
		this.mask = mask;
	}

	public setFilter(value: Filter | null) {
		this.filter = value;
	}

	public hasCrop() {
		return this.crop?.size.width !== 0 && this.crop?.size.height !== 0;
	}

	static create(config: Partial<VideoDTO> = {}): Video {
		const videoDTO = {
			...Video.defaults(),
			...config,
		};

		return new Video(videoDTO);
	}

	static async fromApi(data: VideoApi | UploadApi): Promise<Video> {
		const video = data
			? Video.create({
					url: data.url,
					metadata: data.metadata,
					preview: data.preview,
			  })
			: Video.create();

		// Si la api te devuelve la duración y el tamaño del video, se lo asignamos, si no tendremos que esperar a extraer estos datos en el cliente
		if ('duration' in data && data.duration && data.metadata?.size?.width && data.metadata?.size?.height) {
			video.size = data.metadata.size;
			video.cropTime.end = TimeTools.MMSSFormatToMs(data.duration);

			// Igualmente hay que extraer los datos en el cliente porque la api de Freepik no provee segundos y podemos perder precisión
			VideoTools.getMetadata(video.url).then((metadata) => {
				video.cropTime.end = metadata.duration;
			});
		} else {
			const metadata = await VideoTools.getMetadata(video.url);
			video.size = metadata.size;
			video.cropTime.end = metadata.duration;
		}

		return video;
	}

	public scaleBy(scale: number) {
		super.scaleBy(scale);

		this.crop.size.width *= scale;
		this.crop.size.height *= scale;
		this.crop.position.x *= scale;
		this.crop.position.y *= scale;
	}

	public croppedDuration() {
		return this.cropTime.end - this.cropTime.start;
	}

	public toNotFound() {
		this.url = '/video/not-found.mp4';
		this.cropTime = {
			start: 0,
			end: 0,
		};
	}
}
