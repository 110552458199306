import { computed, nextTick, ref } from 'vue';

import { useMockupStore } from '@/apps/mockup/mockupStore';
import {MockupElementTexture, MockupElementTextures} from '@/apps/mockup/schemas';
import { FillType, Frame } from '@/apps/mockup/Types/basicTypes';
import { useMainStore } from '@/editor/stores/store';
import ImageEditor from '@/elements/medias/images/image/classes/Image';
import { useImageTransform } from '@/elements/medias/images/image/composables/useImageTransform';
import { useSelection } from '@/interactions/composables/useSelection';
import Page from '@/page/classes/Page';
import { useProjectStore } from '@/project/stores/project';
import { Size } from '@/Types/types';
import { Anchor } from '@/Types/types';

export const useActiveMockup = () => {
	const mockup = useMockupStore();
	const project = useProjectStore();
	const store = useMainStore();
	const { setSelection } = useSelection();

	const imageRef = ref(ImageEditor.create());
	const { adjustTo: imageAdjustTo, align: imageAlign } = useImageTransform(imageRef);

	const page = computed(() => {
		return project.pages.find((page) => page.id === mockup.activeElement?.id) as Page;
	});

	/**
	 * Load placeholder or default design and convert to ImageEditor
	 * @param {MockupElementTexture} mockupElement
	 * @param {Size} size
	 * @returns {Promise<ImageEditor>}
	 */
	const addPlaceHolderDesign = async (mockupElement: MockupElementTexture, size: Size): Promise<ImageEditor> => {
		const url = URL.createObjectURL(await (await fetch(mockupElement.contents?.custom?.path as string)).blob());
		const placeholderDesing = await ImageEditor.fromUrl(url);

		return await adjustImageByOrientation(placeholderDesing, size, mockupElement);
	};

	/**
	 * Validates if Page have locked elements (Default design)
	 * @param {Page} page
	 * @returns {boolean}
	 */
	const pageNeedFirstDesign = async (page: Page) => {
		return page.elements.size == 0; //Validates if Page have a default design or is empty
	};

	/**
	 * Adjust Image according to his orientation
	 * @param {ImageEditor} design
	 * @param {Size} size
	 * @param mockupElement
	 * @returns {ImageEditor}
	 */
	const adjustImageByOrientation = async (design: ImageEditor, size: Size, mockupElement: MockupElementTextures) => {
		imageRef.value = design;
		const fillType = mockupElement.fillType;
		if (fillType === FillType.fill) {
			const { width, height } = calculateImageDimensions(design, size);
			design.setSize(width, height);
		}

		if (fillType === FillType.fit) {
			const scaleFactor = Math.min(size.width / design.size.width, size.height / design.size.height);
			design.setSize(Math.floor(design.size.width * scaleFactor), Math.floor(design.size.height * scaleFactor));
		}

		await nextTick();

		imageAlign(Anchor.center);

		if (fillType == FillType.custom && mockupElement.customFillTypeFrame) {
			const customFrame: Frame = {
				height: mockupElement.customFillTypeFrame.height * size.height,
				width: mockupElement.customFillTypeFrame.width * size.width,
				x: mockupElement.customFillTypeFrame.x * size.width,
				y: mockupElement.customFillTypeFrame.y * size.height,
			};

			const extraPosition = getCascadePosition();

			design.setSize(customFrame.width, customFrame.height);
			design.setPosition(customFrame.x + extraPosition, customFrame.y + extraPosition);
		}

		return design;
	};

	const getCascadePosition = (): number => {
		if (page.value && page.value.elements.size != 0) {
			return 0;
		} else {
			return 20;
		}
	};

	/**
	 * Method to insert image in active Element
	 * @param {DragEvent}event
	 */
	const insertImage = async (event: DragEvent) => {
		event.preventDefault();
		const files = event.dataTransfer?.files;
		if (!((files?.length ?? 0 > 0) && files![0].type.includes('image'))) return;
		const url = URL.createObjectURL(files![0]);

		const image = await ImageEditor.fromUrl(url);

		const mockupElement = mockup.findElementTextureById(page.value.id);
		const newImage = await adjustImageByOrientation(image, project.size, mockupElement);
		page.value.elements.set(newImage.id, newImage); //Push user design | image

		setSelection(image);
		store.finishedLoading = true;
	};

	/**
	 * Calculates the dimensions of an image based on the design and project sizes.
	 * @param design - The design object representing the image editor.
	 * @param size - The size object representing the project dimensions.
	 * @returns An object containing the calculated width and height of the image.
	 */
	const calculateImageDimensions = (design: ImageEditor, size: Size) => {
		const designIsPortrait = design.size.width < design.size.height;
		const projectIsPortrait = size.width < size.height;

		let width, height;

		if ((designIsPortrait && projectIsPortrait) || (!designIsPortrait && !projectIsPortrait)) {
			height = size.height;
			width = (size.height / design.size.height) * design.size.width;

			if (width < size.width) {
				width = size.width;
				height = (size.width / design.size.width) * design.size.height;
			}
		} else if (!designIsPortrait && projectIsPortrait) {
			height = size.height;
			width = (size.height / design.size.height) * design.size.width;
		} else {
			width = size.width;
			height = (size.width / design.size.width) * design.size.height;
		}
		return { width, height };
	};

	return {
		pageNeedFirstDesign,
		addPlaceHolderDesign,
		adjustImageByOrientation,
		calculateImageDimensions,
		insertImage,
	};
};
