import { createSharedComposable } from '@vueuse/core';
import { computed, Ref, ref } from 'vue';

import { getArtboards } from '@/api/DataApiClient';
import { useAuth } from '@/auth/composables/useAuth';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useUrlParams } from '@/common/composables/useUrlParams';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useZoom } from '@/editor/composables/useZoom';
import { useMainStore } from '@/editor/stores/store';
import ElementTools from '@/elements/element/utils/ElementTools';
import { useHistoryStore } from '@/history/stores/history';
import TemplateLoader from '@/loader/utils/TemplateLoader';
import Page from '@/page/classes/Page';
import { usePage } from '@/page/composables/usePage';
import { useArtboard } from '@/project/composables/useArtboard';
import { useProjectPages } from '@/project/composables/useProjectPages';
import { useProjectStore } from '@/project/stores/project';
import { HistoryBackupsApi } from '@/Types/apiClient';
import { Unit } from '@/Types/types';

export const useProject = createSharedComposable(() => {
	const store = useMainStore();

	const { isFreepikContext } = useEditorMode();
	const { addPages, removeAllPages } = useProjectPages();
	const history = useHistoryStore();
	const project = useProjectStore();
	const { isIOS } = useDeviceInfo();
	const MAX_PAGES = 85;
	const temporalRefPage = ref(Page.createDefault());
	const { isEmbeddedContext, embeddedContextData } = useEditorMode();

	const artboard = useArtboard();
	const { fitZoomScale } = useZoom();
	const { isLogged } = useAuth();
	const { adjustContent } = usePage(temporalRefPage as Ref<Page>);
	const userChangedName = ref(false);
	const { generateNewPathWithParams, INVALID_PARAMS_IN_SYNC_CASES, CATEGORY_TEMPLATE_PARAMS } = useUrlParams();
	const appendTemplate = async (slug: string, scaleElements?: number) => {
		const { pages, templateData, isSvg } = await TemplateLoader.fromSlug(slug);
		const { width, height, unit, dpi } = templateData.artboard;
		const size = { width, height };

		pages.forEach((page) => {
			page.elements = new Map(
				ElementTools.fixRepeatedElementIds(project.allElements, page.elementsAsArray()).map((el) => [el.id, el])
			);

			if (scaleElements) {
				page.elements.forEach((el) => el.scaleBy(scaleElements));
			}

			const applyDpiFix = !isSvg && unit === 'mm' && dpi !== artboard.MM_TO_PX;

			// Fix para el cambio de dpi
			if (applyDpiFix) {
				temporalRefPage.value = page;

				const currentSize = project.unit === 'mm' ? artboard.convertMmToPx(size.width, size.height) : size;

				adjustContent(currentSize, {
					width: Math.round(width * dpi),
					height: Math.round(height * dpi),
				});
			}

			// Si es iOS aplicamos la escala calculada para esta sesión a todos los elementos
			if (isIOS.value && !applyDpiFix) {
				page.scaleBy(store.scaleMaxAllowedSize);
			}
		});

		if (pages.length) {
			addPages(pages, store.activePage as Page);
			store.setActivePage(pages[pages.length - 1], true);
		}
	};

	const replaceTemplate = async (slug: string) => {
		store.finishedLoading = false;
		const { pages, templateData, isSvg } = await TemplateLoader.fromSlug(slug);

		const { width, height, unit, dpi } = templateData.artboard;
		let selectedPages = pages.filter((page, key) => !isEmbeddedContext.value || key === 0);
		const size = { width, height };

		if (templateData.category_tree.length) {
			store.projectCategory = templateData.category_tree.reverse()[0];
		}

		if (templateData.pack) {
			store.pack = templateData.pack;
		}

		// ? Si es una presentación solo cargamos la primera página, y a esta se le asignará el sourceId con el valor del id de
		// ? lo que venga en media, para detectar en su respectivo panel las páginas que han sido agregadas
		if (projectIsPresentation.value) {
			selectedPages = [selectedPages[0]];
			selectedPages[0].sourceId = templateData.vector.media[0].id;
		}

		if (!isLogged.value && store.userVector) {
			store.userVector = null;
		}
		store.isDisneyTemplate = templateData.is_disney;

		// Si no estamos en una plantilla del usuario cambiamos la url o
		//  si la plantilla es una presentación generada por IA también necesitamos cambiar la url, pero manteniendo
		//  el uuid de la url
		if ((!store.userVector && !isEmbeddedContext.value) || (isAiPresentation.value && store.userVector)) {
			generateNewPathWithParams(slug);

			// ya que la plantilla deja de ser una presentación generada por IA, actualizamos el valor en la store
			if (templateData.generator !== 'ai-presentations') store.generator = '';
		}

		project.$patch(() => {
			project.flaticonSearch = templateData.flaticonSearch;
			project.sourceVectorId = templateData.id;
			project.slug = templateData.slug_en || templateData.slug;
			project.scale = 1;
			project.category = store.projectCategory?.name as string;

			//El nombre del proyecto se cambia cuando el nombre actual es igual al de la plantilla original o cuando no
			//existe dicho nombre original.
			const userVector = store.userVector;
			const originalNameExists = userVector != null && userVector.originalName != null;
			const vectorNameIsModified = originalNameExists && project.name !== userVector.originalName;

			if ((!vectorNameIsModified && !userChangedName.value) || (!userChangedName.value && !originalNameExists)) {
				project.name = templateData.name;
			}

			removeAllPages();

			artboard.setArtboardSize(width, height, unit);
			selectedPages.forEach((page, index) => {
				// Si el proyecto trae escala del backend vamos a reescalar todos los elementos al tamaño original
				// ya que en el backend ahora siempre vamos a tener los elementos a su tamaño original, esto deshace
				// la anterior versión del fix de ios que guardaba los elementons escalados en el backend
				if (templateData.scale !== 1) {
					page.scaleBy(1 / templateData.scale);
				}

				const applyDpiFix = !isSvg && unit === 'mm' && dpi !== artboard.MM_TO_PX;

				// Fix para el cambio de dpi
				if (applyDpiFix) {
					temporalRefPage.value = page;

					const currentSize = project.unit === 'mm' ? artboard.convertMmToPx(size.width, size.height) : size;

					adjustContent(currentSize, {
						width: Math.round(width * dpi),
						height: Math.round(height * dpi),
					});
				}

				// En el modo para terceros siempre debemos de mantener el tamaño del proyecto base
				if (isEmbeddedContext.value && embeddedContextData.value.width && embeddedContextData.value.height) {
					temporalRefPage.value = page;

					const currentSize =
						project.unit === 'mm' ? artboard.convertMmToPx(project.size.width, project.size.height) : project.size;
					const newSize = artboard.convertMmToPx(embeddedContextData.value.width, embeddedContextData.value.height);

					artboard.setArtboardSize(embeddedContextData.value.width, embeddedContextData.value.height, 'mm');
					adjustContent(newSize, currentSize);
				}

				// Si es iOS aplicamos la escala calculada para esta sesión a todos los elementos
				if (isIOS.value && !applyDpiFix) {
					page.scaleBy(store.scaleMaxAllowedSize);
				}

				// En la página se esta guardado la preview que puede estar desfasada
				// Usamos la que hay en la respuesta de la api del vector si esta disponible
				page.preview = templateData.pages[index].preview || page.preview;
			});

			addPages(selectedPages);
		});

		fitZoomScale();

		store.$patch(() => {
			store.colorPalettes = templateData.colorPalettes || [];
			store.activePageId = selectedPages[0].id;
			store.finishedLoading = true;
		});

		// si no había cambios y era plantilla nueva, empezamos de 0
		// el autoguardado ya se encarga de crear el estado inicial de nuevo
		if (history.states.length === 1 && !store.userVector) {
			history.states = [];
		}
	};

	const replaceBackupTemplate = async (backup: HistoryBackupsApi & { media: Page[] }) => {
		store.finishedLoading = false;

		const { width, height, unit, dpi, scale } = backup.properties;
		let selectedPages = backup.media.filter((page, key) => !isEmbeddedContext.value || key === 0);
		const size = { width, height };

		const isPresentation = store.projectCategory && store.projectCategory.id === 1870;

		selectedPages = isPresentation
			? [selectedPages[0]]
			: await selectedPages.map((page) => TemplateLoader.loadFromData(page));

		project.$patch(() => {
			project.scale = 1;
			removeAllPages();
			artboard.setArtboardSize(width, height, unit);
			selectedPages.forEach((page, index) => {
				if (scale !== 1) {
					page.scaleBy(1 / scale);
				}

				const applyDpiFix = unit === 'mm' && dpi !== artboard.MM_TO_PX;

				// Fix para el cambio de dpi
				if (applyDpiFix) {
					temporalRefPage.value = page;

					const currentSize = project.unit === 'mm' ? artboard.convertMmToPx(size.width, size.height) : size;

					adjustContent(currentSize, {
						width: Math.round(width * dpi),
						height: Math.round(height * dpi),
					});
				}

				// Si es iOS aplicamos la escala calculada para esta sesión a todos los elementos
				if (isIOS.value && !applyDpiFix) {
					page.scaleBy(store.scaleMaxAllowedSize);
				}
				// Usamos la que hay en la respuesta de la api del vector si esta disponible
				page.preview = backup.media[index].preview || page.preview;
			});
			addPages(selectedPages);
		});

		fitZoomScale();

		store.$patch(() => {
			store.colorPalettes = [];
			store.activePageId = selectedPages[0].id;
			store.finishedLoading = true;
		});

		// si no había cambios y era plantilla nueva, empezamos de 0
		// el autoguardado ya se encarga de crear el estado inicial de nuevo
		if (history.states.length === 1 && !store.userVector) {
			history.states = [];
		}
	};

	const openUserTemplate = async (slug: string, params?: string) => {
		store.finishedLoading = false;
		store.inSchedules = false;
		let pages: Page[] = [];
		let templateData: any = null;

		try {
			const res = await TemplateLoader.fromSlug(slug);
			pages = res.pages;
			templateData = res.templateData;

			const { width, height, unit, dpi } = templateData.artboard;
			const size = { width, height };

			pages.forEach((page: Page) => {
				// Fix para el cambio de dpi
				if (!res.isSvg && unit === 'mm' && dpi !== artboard.MM_TO_PX) {
					temporalRefPage.value = page;

					const currentSize = unit === 'mm' ? artboard.convertMmToPx(size.width, size.height) : size;

					adjustContent(currentSize, {
						width: Math.round(width * dpi),
						height: Math.round(height * dpi),
					});
					project.invalidateServerVersion?.(temporalRefPage.value);
				}
			});
		} catch (error) {
			store.finishedLoading = true;
			return Promise.reject();
		}

		const { width, height, unit } = templateData.artboard;

		if (templateData.category_tree.length) {
			store.projectCategory = templateData.category_tree.reverse()[0];
		}

		if (templateData.pack) {
			store.pack = templateData.pack;
		}

		if (templateData.inSchedules) {
			store.inSchedules = templateData.inSchedules;
		}

		if (!isLogged.value && store.userVector) {
			store.userVector = null;
		}

		const fixedParams = new URLSearchParams(params);

		if (isFreepikContext.value) {
			fixedParams.append('template', slug);
		}

		generateNewPathWithParams(slug, [...INVALID_PARAMS_IN_SYNC_CASES, ...CATEGORY_TEMPLATE_PARAMS]);

		project.$patch(() => {
			project.flaticonSearch = templateData.flaticonSearch;
			project.sourceVectorId = templateData.id;
			project.scale = templateData.scale;
			project.id = templateData.userVectorId as string;
			project.name = templateData.name;
			project.slug = templateData.slug_en || templateData.slug;
			removeAllPages();
			artboard.setArtboardSize(width, height, unit);
			addPages(pages);
		});

		fitZoomScale();

		store.$patch(() => {
			store.colorPalettes = templateData.colorPalettes || [];
			store.activePageId = pages[0].id;
			store.userVector = {
				uuid: templateData.userVectorId,
				project: templateData.project || 'wepik',
				preview: templateData.preview || '',
				user_id: templateData.user_id || -1,
				originalName: templateData.vector.name,
				originalNumPages: templateData.vector.media.length,
			};
			store.finishedLoading = true;
		});

		history.states = [];
	};

	const setArtboardFromUrl = async () => {
		const isNewArtboard = location.href.includes('new-artboard');

		if (!isNewArtboard) {
			return false;
		}

		const params = new URLSearchParams(location.href.split('?')[1]);
		const hasCustomSizeInUrl = params.get('width') && params.get('height') && params.get('unit');
		const hasCustomArtboardInUrl = params.get('size');

		let height = null;
		let width = null;
		let unit = null;

		if (hasCustomSizeInUrl) {
			width = !Number.isNaN(Number(params.get('width'))) ? Number(params.get('width')) : 500;
			height = !Number.isNaN(Number(params.get('height'))) ? Number(params.get('height')) : 500;
			unit = ['px', 'mm'].includes(params.get('unit') as string) ? (params.get('unit') as Unit) : 'px';
		} else if (hasCustomArtboardInUrl) {
			const artboards = await getArtboards();
			const artboardName = params.get('size');

			if (!artboards.length) return false;

			const artboardData = artboards.find((artboard) => artboard.name === artboardName);

			height = artboardData?.height;
			width = artboardData?.width;
			unit = artboardData?.unit;
		}

		if (!height || !width || !unit) {
			return false;
		}

		artboard.setArtboardSize(width, height, unit);

		return true;
	};

	const isAiPresentation = computed(() => {
		return store.generator === 'ai-presentations';
	});

	const projectIsPresentation = computed(() => {
		const ID_PRESENTATION_CATEGORY = 1870;
		return store.projectCategory?.id === ID_PRESENTATION_CATEGORY;
	});

	return {
		...artboard,
		appendTemplate,
		replaceTemplate,
		replaceBackupTemplate,
		openUserTemplate,
		MAX_PAGES,
		setArtboardFromUrl,
		isAiPresentation,
		projectIsPresentation,
		userChangedName,
	};
});
