import Bugsnag from '@bugsnag/js';
import { until, useDebounceFn, useTimeoutFn } from '@vueuse/core';
import { cloneDeep, debounce, keyBy } from 'lodash-es';
import { PiniaPluginContext } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { computed, readonly, ref, watch } from 'vue';

import { saveProject as apiSaveProject, syncProject } from '@/api/UserApiClient';
import { useAuth } from '@/auth/composables/useAuth';
import { useToast } from '@/common/composables/useToast';
import { useUrlParams } from '@/common/composables/useUrlParams';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { MainState, useMainStore } from '@/editor/stores/store';
import { useDownloadsProject } from '@/export/download/composables/useDownloadsProject';
import { HistoryState } from '@/history/classes/HistoryState';
import { useHistoryStore } from '@/history/stores/history';
import Page from '@/page/classes/Page';
import { useArtboard } from '@/project/composables/useArtboard';
import { SyncData } from '@/Types/history';
import { ToSerialize } from '@/utils/classes/ToSerialize';

/**
 * Plugin para el store que gestiona el historial y el auto guardado
 * @param context
 */
export function historyPlugin(context: PiniaPluginContext<'project', MainState>) {
	// Este plugin solo es para el store principal
	if (context.store.$id !== 'project') {
		return;
	}

	const mainStore = useMainStore();
	const history = useHistoryStore();

	const { isEditorMode, isSlidesgoMode, embeddedContextData, isFreepikContext } = useEditorMode();
	const { downloads, downloading } = useDownloadsProject();
	const { MM_TO_PX } = useArtboard();
	const triedToSaveWhilePaused = ref(false);
	const firstPageSerialized = ref();
	const ready = ref(false);
	const toast = useToast();
	const lastSyncDate = ref(new Date());
	const lastStateDate = ref(new Date());
	const syncError = ref(false);
	const { refreshCsrfToken, user, requireAuth, isLogged, checkLoginStatus, onFetchLogin } = useAuth();
	const { generateNewPathWithParams } = useUrlParams();

	const blockingTasks = ref(0);
	const isPaused = computed(() => blockingTasks.value > 0);
	const isHistoryBlocked = readonly(isPaused);

	const prevState = ref(cloneDeep(context.store.$state));

	/**
	 * Crea un nuevo HistoryState y lo añade al store con los cambios
	 */
	const saveState = debounce(async () => {
		// Autosave must be stopped in some cases to avoid extra states in history
		if (ready.value === false) {
			triedToSaveWhilePaused.value = isPaused.value;
			return;
		}

		if (isPaused.value) {
			triedToSaveWhilePaused.value = true;
			return;
		}

		triedToSaveWhilePaused.value = false;
		history.$patch(() => {
			// Si no estamos en el último estado, descartamos todos los que estén por detrás
			if (history.activeState && history.activeState !== history.states[history.states.length - 1]) {
				history.states.splice(history.activeState.index + 1);
			}
			lastStateDate.value = new Date();

			const h = new HistoryState(
				// @ts-ignore
				context.store.$state,
				prevState.value,
				Object.keys(history.states).length,
				mainStore.scaleMaxAllowedSize
			);
			history.states.push(h);
			history.activeState = h;

			prevState.value = cloneDeep(context.store.$state);

			// Si hay descarga en curso marcamos como que tenemos sync pendiente, pero no sincronizamos
			// en el resto de casos hacemos el sync
			if (downloading.value) {
				pendingSync.value = true;
				return;
			}

			triggerSync();
		});
	}, 500);

	// Vigilamos cuando han terminado todas las descargas para sincronizar el estado si se han producido cambios durante ella
	watch(
		() => Array.from(downloads.value.values()).every((d) => d.status !== 'progress'),
		(allFinished) => {
			if (allFinished && pendingSync.value) {
				performSyncWithStateInUse();
			}
		}
	);

	/**
	 * Nos suscribemos a los cambios del store y filtramos por los que nos interesa crear un nuevo estado,
	 * es decir, los cambios en los templates.
	 */
	context.store.$subscribe(
		() => {
			if (window.fromHistory) {
				window.fromHistory = false;
				return;
			}
			// @ts-ignore
			if (window.moving) {
				return;
			}

			// si no tenemos un estado inicial, no nos interesan los cambios
			if (!history.states.length) {
				// Como se carga la primera página y se deja interactuar con ella mientras carga el resto
				// puede ser que el usuario haga cambios mientras aún no se ha inicializado el historial/sync
				if (!mainStore.finishedLoading && context.store.pages.length) {
					// Vamos a ir guardando una copia serializada para buscar cambios unicamente en esta página mientras
					// no se ha terminado de cargar
					const currentPageSerialized = ToSerialize.do(context.store.pages[0], true);
					// Si hay cambios en la copia serializada y en la nueva que llega vamos a actualizar
					// además vamos a marcar con el triedToSaveWhilePaused que hay que hacer una sincronización cuando se
					// inicie el history/sync
					if (currentPageSerialized !== firstPageSerialized.value) {
						if (firstPageSerialized.value) {
							triedToSaveWhilePaused.value = true;
						}
						firstPageSerialized.value = currentPageSerialized;
					}
				}
				return;
			}

			saveState();
		},
		{ immediate: false }
	);

	const syncing = ref(false);
	const pendingSync = ref(false);
	const internalTriggerSync = useDebounceFn(() => {
		pendingSync.value = false;
		sync(0);
	}, 3000);

	const triggerSync = async () => {
		if (!isEditorMode.value && !isSlidesgoMode.value) {
			return;
		}
		pendingSync.value = true;
		syncError.value = false;
		internalTriggerSync();
	};

	/**
	 * Crea el vector del usuario
	 */
	const saveProject = async () => {
		let project = 'wepik';
		if (isSlidesgoMode.value) {
			project = 'slidesgo';
		}

		if (isFreepikContext.value) {
			project = 'freepik';
		}
		const body: SyncData = {
			width: context.store.size.width / mainStore.scaleMaxAllowedSize,
			height: context.store.size.height / mainStore.scaleMaxAllowedSize,
			unit: context.store.unit,
			dpi: MM_TO_PX,
			name: context.store.name,
			vector_id: context.store.sourceVectorId,
			id: context.store.id,
			editorVersion: 'next-gen',
			project,
			scale: context.store.scale,
			api_key: embeddedContextData.value?.apiKey,
		};

		const { data, error } = await apiSaveProject(body).json();
		if (error.value) {
			throw new Error('Error while creating user vector');
		}
		mainStore.userVector = data.value;

		generateNewPathWithParams(data.value.uuid);
	};

	const serverVersions: { [id: string]: Page } = {};
	const invalidatedServerVersions: string[] = [];

	/**
	 * Negocia la sincronización con el servidor
	 */
	const performSync = async (fullSync: string[] = [], pages: Page[] = [], attempt = 0) => {
		if (!mainStore.userVector || mainStore.userVector.user_id !== mainStore.user?.id) {
			return;
		}

		// Guardamos una copia de la página más reciente antes de lanzar el request.
		// Vamos a guardar ese copia como la versión que tenemos en el servidor
		// Para que los diffs se hagan respecto a esa
		const pagesAtSync = keyBy<Page>(cloneDeep(pages.length > 0 ? pages : context.store.pages) as Page[], 'id');
		const syncedData = HistoryState.generateSyncData(
			serverVersions,
			pagesAtSync,
			fullSync,
			mainStore.scaleMaxAllowedSize
		);

		if (syncedData.length === 0) {
			return;
		}
		let project = 'wepik';
		if (isSlidesgoMode.value) {
			project = 'slidesgo';
		}

		if (isFreepikContext.value) {
			project = 'freepik';
		}
		const body: SyncData = {
			width: context.store.size.width / mainStore.scaleMaxAllowedSize,
			height: context.store.size.height / mainStore.scaleMaxAllowedSize,
			unit: context.store.unit,
			dpi: MM_TO_PX,
			name: context.store.name,
			vector_id: context.store.sourceVectorId,
			uuid: context.store.id,
			editorVersion: 'next-gen',
			project,
			scale: 1,
			api_key: embeddedContextData.value?.apiKey,
		};

		Bugsnag.leaveBreadcrumb('Sync request data', { ...body, syncedData });

		const historyLength = JSON.stringify(syncedData).length;

		const megabyte = 1024 * 1024;
		const timeout = (historyLength > megabyte ? 60000 : 25000) + 5000 * attempt;

		Bugsnag.leaveBreadcrumb(`Using a timeout of ${timeout}ms (${historyLength / 1024} Kilobytes))`);

		const { data, error } = await syncProject(
			{
				...body,
				history: syncedData,
			},
			timeout,
			fullSync.length > 0
		);

		if (!data.value || Array.isArray(data.value) === false) {
			throw error.value;
		}

		Bugsnag.leaveBreadcrumb('Sync success', data.value);

		// Update the server version for in ok syncs
		data.value.filter((result) => result.success).forEach(({ id }) => (serverVersions[id] = pagesAtSync[id]));

		const fullSyncRequests = data.value.filter((item) => !item.success).map((item) => item.id);

		if (fullSync.length > 0 && fullSyncRequests.length > 0) {
			throw new Error("Can't sync all pages");
		}

		if (fullSyncRequests.length > 0) {
			console.warn('Full sync requested', fullSyncRequests);

			const fullSync = data.value.filter((result) => !result.success).map(({ id }) => id);
			await performSync(fullSync);
		}

		lastSyncDate.value = new Date();
	};

	/**
	 * Lanza un sync con todo el contenido que tenemos en el estado actual
	 */
	const performSyncWithStateInUse = async () => {
		await until(syncing).not.toBeTruthy();
		syncing.value = true;
		try {
			await performSync([], history.activeState?.pages as Page[]);
			pendingSync.value = false;
		} catch (e) {
			console.warn('sync failed', e);
		} finally {
			syncing.value = false;
		}
	};

	/**
	 * Inicial el flujo de sincronizacion y controla reintentos.
	 * @param attempt
	 */
	const sync = async (attempt: number) => {
		if (!mainStore.user) {
			return;
		}

		if (history.states.length < 1) {
			return;
		}

		// si ya estabamos en proceso se sincronizar, lo volvemos a lanzar
		// para cuando termine
		if (syncing.value && !attempt) {
			return await triggerSync();
		}

		syncing.value = true;

		if (attempt > 5) {
			syncError.value = true;
			syncing.value = false;
			pendingSync.value = true;
			toast.error('Sync error, your changes cannot be saved');
			Bugsnag.notify(`Sync failed after few attempts`);
			return;
		}

		if (!mainStore.userVector) {
			try {
				await saveProject();
				syncError.value = false;
			} catch (e: any) {
				syncError.value = true;
				syncing.value = false;
				Bugsnag.notify(`Error on create user vector: ${e.message}, `);

				await checkLoginStatus();
				requireAuth();

				onFetchLogin(() => {
					if (user.value && isLogged.value) Bugsnag.notify(`User just logged in`);
				});
				// si falla, volvemos a intentarlo
				useTimeoutFn(() => sync(attempt + 1), 5000 + 1000 * attempt);
				return;
			}
		}

		try {
			await performSync();
			syncError.value = false;
		} catch (e: any) {
			Bugsnag.leaveBreadcrumb(`Sync attempt ${attempt} failed`, { error: e });
			if (e && [403, 401].includes(e.status)) {
				await checkLoginStatus();
				requireAuth();

				onFetchLogin(() => {
					if (user.value && isLogged.value) {
						Bugsnag.notify(`User just logged in`);
						sync(attempt + 1);
					}
				});
				syncing.value = false;

				return;
			}

			// si es que ha caducado el csrf...
			if (e && 419 === e.status) {
				await refreshCsrfToken();
			}
			// si falla, volvemos a intentarlo

			return await new Promise((resolve) => {
				setTimeout(() => sync(attempt + 1).then(resolve), 1000 * attempt);
			}).then(() => {
				syncing.value = false;
			});
		}

		syncing.value = false;
	};

	const initSync = async (forceSave = false) => {
		if (forceSave || triedToSaveWhilePaused.value) {
			triedToSaveWhilePaused.value = false;
			await performSync();
		}
		if (mainStore.userVector && Object.keys(serverVersions).length === 0) {
			// Marcamos como ID de estado inicial el estado anterior del primer vector de usuario
			history.states[0].id = uuidv4();
		}

		const now = new Date();
		lastSyncDate.value = now;
		lastStateDate.value = now;
		ready.value = true;
	};

	const allChangesSaved = computed(() => {
		return !syncing.value && !pendingSync.value && lastSyncDate.value >= lastStateDate.value;
	});

	/**
	 * Establece como estado en el servidor el page para hacerle diffs contra el estado actual
	 * @param page
	 */
	const setServerVersion = (page: Page) => {
		if (!mainStore.userVector || invalidatedServerVersions.includes(page.id)) return;
		serverVersions[page.id] = cloneDeep(page);
	};

	const pauseAutoSave = () => {
		blockingTasks.value++;
	};

	const resumeAutoSave = (ignoreSaveAttempts = false) => {
		if (triedToSaveWhilePaused.value && !ignoreSaveAttempts) {
			saveState();
		}
		triedToSaveWhilePaused.value = false;

		blockingTasks.value--;

		if (blockingTasks.value < 0) {
			console.warn('Tasks history manager below 0!');
			blockingTasks.value = 0;
		}
	};

	const ignoreAutoSave = async (callback: any) => {
		pauseAutoSave();
		await callback();
		resumeAutoSave();
	};

	const invalidateServerVersion = (page: Page) => {
		delete serverVersions[page.id];
		invalidatedServerVersions.push(page.id);
		if (invalidatedServerVersions.length === 1) {
			until(() => mainStore.finishedLoading)
				.toBeTruthy()
				.then(() => {
					performSync();
				});
		}
	};

	return {
		allChangesSaved,
		isHistoryBlocked,
		pendingSync,
		saveState,
		syncing,
		ignoreAutoSave,
		initSync,
		pauseAutoSave,
		performSyncWithStateInUse,
		resumeAutoSave,
		triggerSync,
		saveProject,
		triedToSaveWhilePaused,
		setServerVersion,
		invalidateServerVersion,
		syncError,
		isPaused,
		lastSyncDate,
		lastStateDate,
		prevState,
	};
}

declare module 'pinia' {
	interface PiniaCustomProperties {
		isPaused?: boolean;
		triedToSaveWhilePaused?: boolean;
		pendingSync?: boolean;
		syncing?: boolean;
		unsyncChanges?: boolean;
		syncError?: boolean;
		allChangesSaved?: boolean;
		isHistoryBlocked?: boolean;
		triggerSync?(): void;
		initSync?(force: boolean): void;
		saveState?(): void;
		performSyncWithStateInUse?(): void;
		saveProject?(): void;
		pauseAutoSave?(): void;
		resumeAutoSave?(ignoreSaveAttempts?: boolean): void;
		ignoreAutoSave?(cb: any): void;
		invalidateServerVersion?(page: Page): void;
		setServerVersion?(page: Page): void;
		lastSyncDate?: Date;
		lastStateDate?: Date;
		prevState: Store<'project', MainState, _GettersTree<MainState>, _ActionsTree>;
	}
}
