import { Environment, Settings } from '@/apps/mockup/Types/settingsSchema';

// Esta variable es la única que se debe cambiar para cambiar el entorno de
const environment: Environment = 'prod';

const variables: Settings = {
	local: {
		serviceURL: 'http://localhost:4503/api/v1/',
		landingPage: '/mockup-generator',
	},
	prod: {
		serviceURL: 'https://originalmockups.freepiklabs.com/api/v1/',
		landingPage: '/mockup-generator',
	},
};

export default variables[environment];
