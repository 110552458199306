import { CanvasUtils } from '@/apps/mockup/classes/CanvasUtils';

export type Resource = CanvasImageSource;
export interface Size {
	width: number;
	height: number;
}

export interface BasicBlendIf {
	gray: Float32Array;
	red: Float32Array;
	green: Float32Array;
	blue: Float32Array;
}

export interface RunCompositionOptions {
	applyClippingMask?: boolean;
	underlyingBlendIf?: BasicBlendIf;
	currentBlendIf?: BasicBlendIf;
}

const defaultBlendIf = [0, 0, 1, 1];
const defaultBlendIfObject = {
	gray: new Float32Array([...defaultBlendIf]),
	red: new Float32Array([...defaultBlendIf]),
	green: new Float32Array([...defaultBlendIf]),
	blue: new Float32Array([...defaultBlendIf]),
};

class ComposeResources {
	private _bottom: Resource | undefined;
	private _top: Resource | undefined;
	private readonly _mixer: WebGLRenderingContext;
	private _canvas: HTMLCanvasElement;
	private underlyingBlendIf: BasicBlendIf = { ...defaultBlendIfObject };
	private currentBlendIf: BasicBlendIf = { ...defaultBlendIfObject };
	private _counter = 0;

	private _canvasHelper: HTMLCanvasElement = document.createElement('canvas');
	private _ctxHelper: CanvasRenderingContext2D = this._canvasHelper.getContext('2d', { willReadFrequently: true })!;

	private _clippingMask: HTMLCanvasElement = document.createElement('canvas');

	private _buffers: {
		position: WebGLBuffer | null;
		textureCoord: WebGLBuffer | null;
		indices: WebGLBuffer | null;
	} = {
		position: null,
		textureCoord: null,
		indices: null,
	};
	private _programInfo: {
		program: WebGLProgram | null;
		attribLocations: {
			vertexPosition: number;
			textureCoord: number;
		};
		uniformLocations: {
			uSampler1: WebGLUniformLocation | null;
			uSampler2: WebGLUniformLocation | null;
			uBlendMode: WebGLUniformLocation | null;
			uBlendIfGrayCurrent: WebGLUniformLocation | null;
			uBlendIfGrayUnderlying: WebGLUniformLocation | null;
			uBlendIfRedCurrent: WebGLUniformLocation | null;
			uBlendIfRedUnderlying: WebGLUniformLocation | null;
			uBlendIfGreenCurrent: WebGLUniformLocation | null;
			uBlendIfGreenUnderlying: WebGLUniformLocation | null;
			uBlendIfBlueCurrent: WebGLUniformLocation | null;
			uBlendIfBlueUnderlying: WebGLUniformLocation | null;
		};
	} = {
		program: null,
		attribLocations: {
			vertexPosition: 0,
			textureCoord: 0,
		},
		uniformLocations: {
			uSampler1: null,
			uSampler2: null,
			uBlendMode: null,
			uBlendIfGrayCurrent: null,
			uBlendIfGrayUnderlying: null,
			uBlendIfRedCurrent: null,
			uBlendIfRedUnderlying: null,
			uBlendIfGreenCurrent: null,
			uBlendIfGreenUnderlying: null,
			uBlendIfBlueCurrent: null,
			uBlendIfBlueUnderlying: null,
		},
	};
	private _size: Size = {
		width: 1,
		height: 1,
	};

	private static compose: ComposeResources;

	constructor() {
		this._canvas = new OffscreenCanvas(this._size.width, this._size.height);
		this._canvas.id = 'offscreen-canvas-compose';
		this.setCanvasSize();
		const gl = this._canvas.getContext('webgl', { antialias: true })!;
		this._mixer = gl;

		this._canvasHelper.id = 'canvas-helper-compose';
		this._clippingMask.id = 'clipping-mask-compose';
	}

	public static getInstance() {
		if (!ComposeResources.compose) {
			ComposeResources.compose = new ComposeResources();
			ComposeResources.compose.buildShader({});
		}
		return ComposeResources.compose;
	}

	public clear() {
		this._bottom = undefined;
		this._top = undefined;
		this._counter = 0;
		this.resetBlendIf();
	}

	public getResult(): HTMLCanvasElement {
		if (typeof this._bottom == 'undefined') {
			const canvas = document.createElement('canvas');
			canvas.id = 'fake-bottom';
			canvas.width = this._size.width;
			canvas.height = this._size.height;
			return canvas;
		}
		return this._bottom as HTMLCanvasElement;
	}

	public setSize(size: Size) {
		this._size = { ...size };
		this.setCanvasSize();
		this._mixer.viewport(0, 0, size.width, size.height);
	}

	private setCanvasSize() {
		this._canvas.width = this._size.width;
		this._canvas.height = this._size.height;
		this._canvasHelper.width = this._size.width;
		this._canvasHelper.height = this._size.height;
	}

	private resetBlendIf() {
		this.currentBlendIf = {
			gray: new Float32Array([...defaultBlendIf]),
			red: new Float32Array([...defaultBlendIf]),
			green: new Float32Array([...defaultBlendIf]),
			blue: new Float32Array([...defaultBlendIf]),
		};
		this.underlyingBlendIf = {
			gray: new Float32Array([...defaultBlendIf]),
			red: new Float32Array([...defaultBlendIf]),
			green: new Float32Array([...defaultBlendIf]),
			blue: new Float32Array([...defaultBlendIf]),
		};
	}

	private resetLayers(bottom: Resource) {
		this._bottom = CanvasUtils.cloneCanvas(bottom as HTMLCanvasElement);
	}

	private buildShader(info: { width?: number; height?: number }) {
		if (info.height) this._size.height = info.height;
		if (info.width) this._size.width = info.width;

		const vsSource = `
attribute vec4 aVertexPosition;
attribute vec2 aTextureCoord;
varying highp vec2 vTextureCoord;

void main(void) {
    gl_Position = aVertexPosition;
    vTextureCoord = vec2(aTextureCoord.s, 1.0 - aTextureCoord.t); // Flip Y coordinate
}
        `;
		const fsSource = `
precision highp float;

varying highp vec2 vTextureCoord;
uniform sampler2D uSampler1;
uniform sampler2D uSampler2;
uniform int uBlendMode;
uniform vec4 uBlendIfGrayCurrent;
uniform vec4 uBlendIfGrayUnderlying;
uniform vec4 uBlendIfRedCurrent;
uniform vec4 uBlendIfRedUnderlying;
uniform vec4 uBlendIfGreenCurrent;
uniform vec4 uBlendIfGreenUnderlying;
uniform vec4 uBlendIfBlueCurrent;
uniform vec4 uBlendIfBlueUnderlying;


vec3 rgbToHsl(float r, float g, float b){
    float _max = max(max(r,g),b);
    float _min = min(min(r,g),b);
    float h = (_max + _min)/2.0;
    float s = (_max + _min)/2.0;
    float l = (_max + _min)/2.0;

    if(_max == _min){
        h = 0.0;
        s = 0.0;
    }else{
        float d = _max - _min;
        if(l > 0.5){
            s = d / (2.0 - _max - _min);
        }else{
            s = d / (_max + _min);
            if(_max == r){
                h = (g-b)/d + (g<b? 6.0: 0.0);
            }else if(_max == g){
                h = (b-r)/d + 2.0;
            }else if(_max == b){
                h = (r-g)/d + 4.0;
            }
            h = h / 6.0;
        }
    }

    return vec3(h,s,l);
}

float hue2rgb(float p, float q, float t){
    if(t<0.0) t += 1.0;
    if(t>1.0) t -= 1.0;
    if(t<1.0/6.0) return p + (q - p) * 6.0 * t;
    if(t<1.0/2.0) return q;
    if(t<2.0/3.0) return p + (q - p) * (2.0 / 3.0 - t) * 6.0;
    return p;
}

vec3 hslToRgb(float h, float s, float l){
    float r, g, b;

    if(s == 0.0){
        r = g = b = l;
    } else {
        float q = l < 0.5 ? l * (1.0 + s) : l + s - l * s;
        float p = 2.0 * l - q;
        r = hue2rgb(p, q, h + 1.0 / 3.0);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1.0 / 3.0);
    }

    return vec3(r, g, b);
}

float blendIfCondition(float luminosity, vec4 thresholds){
    if(luminosity < thresholds[0] || luminosity > thresholds[3]){
        // Luminosity is outside the range
        return 0.0;
    }else if(luminosity >= thresholds[1] && luminosity <= thresholds[2]){
        return 1.0;
    }else if(luminosity < thresholds[1]){
        return (luminosity - thresholds[0]) / (thresholds[1] - thresholds[0]);
    }else{
        return (thresholds[3] - luminosity) / (thresholds[3] - thresholds[2]);
    }
}

float linearBurnChannel(float bottom, float top){
    return max(0.0, bottom + top - 1.0);
}

vec3 linearBurnBlend(vec4 bottom, vec4 top){

    float r = min(1.0,linearBurnChannel(bottom.r, top.r));
    float g = min(1.0,linearBurnChannel(bottom.g, top.g));
    float b = min(1.0,linearBurnChannel(bottom.b, top.b));

    return vec3(r,g,b);
}

void main(void) {
    vec4 tex1 = texture2D(uSampler1, vTextureCoord);
    vec4 tex2 = texture2D(uSampler2, vTextureCoord);

    vec3 blended;
    float alpha = 1.0;

    float luminosityTop = (tex2.r + tex2.g + tex2.b) / 3.0;
    float luminosityBottom = (tex1.r + tex1.g + tex1.b) / 3.0;

    float red_top_alpha = blendIfCondition(tex2.r, uBlendIfRedCurrent);
	float red_bottom_alpha = blendIfCondition(tex1.r, uBlendIfRedUnderlying);

	float green_top_alpha = blendIfCondition(tex2.g, uBlendIfGreenCurrent);
	float green_bottom_alpha = blendIfCondition(tex1.g, uBlendIfGreenUnderlying);

	float blue_top_alpha = blendIfCondition(tex2.b, uBlendIfBlueCurrent);
	float blue_bottom_alpha = blendIfCondition(tex1.b, uBlendIfBlueUnderlying);

    float blendIfTopAlpha = blendIfCondition(luminosityTop, uBlendIfGrayCurrent);
    float blendIfBottomAlpha = blendIfCondition(luminosityBottom, uBlendIfGrayUnderlying);

    tex2.a = blendIfBottomAlpha * blendIfTopAlpha * tex2.a;
    tex2.a *= red_top_alpha * red_bottom_alpha;
    tex2.a *= green_top_alpha * green_bottom_alpha;
	tex2.a *= blue_top_alpha * blue_bottom_alpha;

    if(uBlendMode == 0){
        // LinearBurn
        blended = linearBurnBlend(tex1, tex2);
    }else if(uBlendMode == 1){
        // Multiply
        blended = tex1.rgb * tex2.rgb;
    }else if(uBlendMode == 2){
        // Screen
        blended = 1.0 - (1.0 - tex1.rgb) * (1.0 - tex2.rgb);
    }else if(uBlendMode == 3){
        // Darken
        blended = min(tex2.rgb, tex1.rgb);
    }else if(uBlendMode == 4){
        // Lighten
        blended = max(tex2.rgb, tex1.rgb);
    }else if(uBlendMode == 5){
        // colorDodge

        vec3 colorDodge;
        for(int i = 0; i < 3; i++) {
            if (tex2[i] == 1.0) {
                colorDodge[i] = 1.0;
            } else {
                colorDodge[i] = min(1.0, tex1[i] / (1.0 - tex2[i]));
            }
        }
        blended = colorDodge;
    }else if(uBlendMode == 6){
        // colorBurn

        vec3 colorBurn;
        for(int i = 0; i < 3; i++) {
            if (tex2[i] == 0.0) {
                colorBurn[i] = 0.0;
            } else {
                colorBurn[i] = max(0.0, 1.0 - (1.0 - tex1[i])/tex2[i] );
            }
        }
        blended = colorBurn;
    }else if(uBlendMode == 7){
        // hardLight

        vec3 hardLight;
        for(int i = 0; i < 3; i++) {
            if (tex2[i] < 0.5) {
                hardLight[i] = 2.0 * tex1[i] * tex2[i];
            } else {
                hardLight[i] = 1.0 - 2.0 * (1.0 - tex1[i]) * (1.0 - tex2[i]);
            }
        }
        blended = hardLight;
    }else if(uBlendMode == 8){
        // softLight

        vec3 hardLight;
        for(int i = 0; i < 3; i++) {
            if (tex2[i] < 0.5) {
                hardLight[i] = tex1[i] * (tex2[i] + 1.0);
            } else {
                hardLight[i] = sqrt(tex1[i]) * (2.0 * tex2[i] - 1.0) + 1.0;
            }
        }
        blended = hardLight;
    }else if(uBlendMode == 9){
        // difference
        blended = abs(tex1.rgb - tex2.rgb);
    }else if(uBlendMode == 10){
        // exclusion
        blended = tex1.rgb + tex2.rgb - 2.0 * tex1.rgb * tex2.rgb;
    }else if(uBlendMode == 11){
        // hue
        vec3 hslBottom = rgbToHsl(tex1.r, tex1.g, tex1.b);
        vec3 hslTop = rgbToHsl(tex2.r, tex2.g, tex2.b);
        blended = vec3(hslToRgb(hslTop.x, hslBottom.y, hslBottom.z));
    }else if(uBlendMode == 12){
        // saturation
        vec3 hslBottom = rgbToHsl(tex1.r, tex1.g, tex1.b);
        vec3 hslTop = rgbToHsl(tex2.r, tex2.g, tex2.b);
        blended = vec3(hslToRgb(hslBottom.x, hslTop.y, hslBottom.z));
    }else if(uBlendMode == 13){
        // color
        vec3 hslBottom = rgbToHsl(tex1.r, tex1.g, tex1.b);
        vec3 hslTop = rgbToHsl(tex2.r, tex2.g, tex2.b);
        blended = vec3(hslToRgb(hslBottom.x, hslBottom.y, hslTop.z));
    }else if(uBlendMode == 14){
        // luminosity
        vec3 hslBottom = rgbToHsl(tex1.r, tex1.g, tex1.b);
        vec3 hslTop = rgbToHsl(tex2.r, tex2.g, tex2.b);
        blended = vec3(hslToRgb(hslTop.x, hslTop.y, hslBottom.z));
    }else if(uBlendMode == 15){
        // Linear Dodge
        blended = clamp(tex1.rgb + tex2.rgb, 0.0, 1.0);
    }
    else if(uBlendMode == 16){
        // normal
        blended = vec3(tex2.rgb);
    }

    alpha = tex2.a + tex1.a * (1.0 - tex2.a);
    blended = tex2.a * tex1.a * blended + (1.0 - tex2.a) * tex1.rgb * tex1.a + (1.0  - tex1.a) * tex2.rgb * tex2.a;

    gl_FragColor = vec4(blended, alpha);
}
            `;

		const shaderProgram = this.initShaderProgram(this._mixer, vsSource, fsSource)!;
		this._programInfo = {
			program: shaderProgram,
			attribLocations: {
				vertexPosition: this._mixer.getAttribLocation(shaderProgram, 'aVertexPosition'),
				textureCoord: this._mixer.getAttribLocation(shaderProgram, 'aTextureCoord'),
			},
			uniformLocations: {
				uSampler1: this._mixer.getUniformLocation(shaderProgram, 'uSampler1'),
				uSampler2: this._mixer.getUniformLocation(shaderProgram, 'uSampler2'),
				uBlendMode: this._mixer.getUniformLocation(shaderProgram, 'uBlendMode'),
				uBlendIfGrayCurrent: this._mixer.getUniformLocation(shaderProgram, 'uBlendIfGrayCurrent'),
				uBlendIfGrayUnderlying: this._mixer.getUniformLocation(shaderProgram, 'uBlendIfGrayUnderlying'),
				uBlendIfRedCurrent: this._mixer.getUniformLocation(shaderProgram, 'uBlendIfRedCurrent'),
				uBlendIfRedUnderlying: this._mixer.getUniformLocation(shaderProgram, 'uBlendIfRedUnderlying'),
				uBlendIfGreenCurrent: this._mixer.getUniformLocation(shaderProgram, 'uBlendIfGreenCurrent'),
				uBlendIfGreenUnderlying: this._mixer.getUniformLocation(shaderProgram, 'uBlendIfGreenUnderlying'),
				uBlendIfBlueCurrent: this._mixer.getUniformLocation(shaderProgram, 'uBlendIfBlueCurrent'),
				uBlendIfBlueUnderlying: this._mixer.getUniformLocation(shaderProgram, 'uBlendIfBlueUnderlying'),
			},
		};

		this._buffers = this.initBuffers(this._mixer);
	}

	private loadTexture(gl: WebGLRenderingContext, image: HTMLCanvasElement | HTMLImageElement) {
		const texture = gl.createTexture();
		gl.bindTexture(gl.TEXTURE_2D, texture);

		const level = 0;
		const internalFormat = gl.RGBA;
		const srcFormat = gl.RGBA;
		const srcType = gl.UNSIGNED_BYTE;

		gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, srcFormat, srcType, image);

		if (this.isPowerOf2(image.width) && this.isPowerOf2(image.height)) {
			gl.generateMipmap(gl.TEXTURE_2D);
		} else {
			gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
			gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
			gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
			gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
		}

		return texture;
	}

	private initBuffers(gl: WebGLRenderingContext) {
		// Create a buffer for the square's positions.
		const positionBuffer = gl.createBuffer();
		gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);

		// Now create an array of positions for the square.
		const positions = [1.0, 1.0, -1.0, 1.0, 1.0, -1.0, -1.0, -1.0];

		// Pass the list of positions into WebGL to build the shape.
		gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.STATIC_DRAW);

		// Set up the texture coordinates for the faces.
		const textureCoordBuffer = gl.createBuffer();
		gl.bindBuffer(gl.ARRAY_BUFFER, textureCoordBuffer);

		const textureCoordinates = [1.0, 1.0, 0.0, 1.0, 1.0, 0.0, 0.0, 0.0];

		gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(textureCoordinates), gl.STATIC_DRAW);

		// Build the element array buffer; this specifies the indices
		// into the vertex arrays for each face's vertices.
		const indexBuffer = gl.createBuffer();
		gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);

		// This array defines each face as two triangles, using the
		// indices into the vertex array to specify each triangle's
		// position.
		const indices = [0, 1, 2, 1, 2, 3];

		// Now send the element array to GL
		gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(indices), gl.STATIC_DRAW);

		return {
			position: positionBuffer,
			textureCoord: textureCoordBuffer,
			indices: indexBuffer,
		};
	}

	private isPowerOf2(value: number) {
		return (value & (value - 1)) === 0;
	}

	private initShaderProgram(gl: WebGLRenderingContext, vsSource: any, fsSource: any) {
		const vertexShader = this.loadShader(gl, gl.VERTEX_SHADER, vsSource)!;
		const fragmentShader = this.loadShader(gl, gl.FRAGMENT_SHADER, fsSource)!;

		// Create the shader program
		const shaderProgram = gl.createProgram()!;
		gl.attachShader(shaderProgram, vertexShader);
		gl.attachShader(shaderProgram, fragmentShader);
		gl.linkProgram(shaderProgram);

		// If creating the shader program failed, alert
		if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
			alert('Unable to initialize the shader program: ' + gl.getProgramInfoLog(shaderProgram));
			return null;
		}

		return shaderProgram;
	}

	private loadShader(gl: WebGLRenderingContext, type: any, source: any) {
		const shader = gl.createShader(type)!;
		gl.shaderSource(shader, source);
		gl.compileShader(shader);

		if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
			alert('An error occurred compiling the shaders: ' + gl.getShaderInfoLog(shader));
			gl.deleteShader(shader);
			return null;
		}

		return shader;
	}

	private clearContext2D() {
		this._ctxHelper.clearRect(0, 0, this._size.width, this._size.height);
	}
	private runContext2D(layer: Resource, blendMode = 'source-over') {
		const ctx = this._ctxHelper;
		ctx.globalCompositeOperation = blendMode as GlobalCompositeOperation;
		ctx.drawImage(layer, 0, 0, this._size.width, this._size.height);
		this._bottom = document.createElement('canvas');
		this._bottom.width = this._size.width;
		this._bottom.height = this._size.height;
		const ctx2 = this._bottom.getContext('2d')!;
		ctx2.drawImage(this._canvasHelper, 0, 0, this._size.width, this._size.height);

		return this._bottom;
	}

	public setBlendIf({ underlying, current }: { underlying?: BasicBlendIf; current?: BasicBlendIf }) {
		this.underlyingBlendIf = underlying ?? { ...defaultBlendIfObject };
		this.currentBlendIf = current ?? { ...defaultBlendIfObject };
	}

	public setClippingMask() {
		if (this._clippingMask.width > 1 && this._clippingMask.height > 1) {
			return;
		}
		this._clippingMask.width = this._size.width;
		this._clippingMask.height = this._size.height;
		const ctx = this._clippingMask.getContext('2d')!;
		ctx.clearRect(0, 0, this._size.width, this._size.height);
		ctx.drawImage(this._top as HTMLCanvasElement, 0, 0, this._size.width, this._size.height);
	}

	private applyClippingMask(layer: Resource) {
		const canvas = document.createElement('canvas');
		canvas.id = 'clipping-masked-canvas-compose';
		canvas.width = this._size.width;
		canvas.height = this._size.height;
		const ctx = canvas.getContext('2d')!;
		ctx.clearRect(0, 0, this._size.width, this._size.height);

		ctx.drawImage(layer, 0, 0, this._size.width, this._size.height);
		ctx.globalCompositeOperation = 'destination-in';
		ctx.drawImage(this._clippingMask, 0, 0, this._size.width, this._size.height);

		return canvas;
	}

	public release() {
		this._top = undefined;
		this.resetBlendIf();
		this._counter = 0;
		CanvasUtils.freedomMethod(this._canvas);
		CanvasUtils.freedomMethod(this._canvasHelper);
	}

	public releaseClippingMask() {
		CanvasUtils.freedomMethod(this._clippingMask);
	}
	/**
	 *
	 * @param layer
	 * @param blendMode
	 * @param options {RunCompositionOptions}
	 * @returns HTMLCanvasElement
	 */
	public run(layer: Resource, blendMode: number | string = -1, options?: RunCompositionOptions) {
		this._counter++;
		if (!this._bottom) {
			this._top = layer;
			if (typeof blendMode == 'string') {
				this.clearContext2D();
				return this.runContext2D(layer, blendMode);
			} else {
				this.resetLayers(layer);
				return this._bottom;
			}
		}

		if (options?.applyClippingMask) {
			this.setClippingMask();
			layer = this.applyClippingMask(layer);
		} else {
			this.releaseClippingMask();
		}
		this._top = layer;

		if (typeof blendMode == 'string') {
			this.clearContext2D();
			this.runContext2D(this._bottom);
			this.runContext2D(this._top, blendMode);
			this.resetBlendIf();
			return this._bottom as HTMLCanvasElement;
		}

		const gl = this._mixer;
		const texture1 = this.loadTexture(gl, this._bottom as HTMLCanvasElement);
		const texture2 = this.loadTexture(gl, this._top as HTMLCanvasElement);

		const render = () => {
			gl.clearColor(0.0, 0.0, 0.0, 0.0);
			gl.clearDepth(5.0);
			gl.enable(gl.DEPTH_TEST);
			gl.enable(gl.BLEND);
			gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
			gl.depthFunc(gl.LEQUAL);

			gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

			{
				const numComponents = 2;
				const type = gl.FLOAT;
				const normalize = false;
				const stride = 0;
				const offset = 0;
				gl.bindBuffer(gl.ARRAY_BUFFER, this._buffers.position);
				gl.vertexAttribPointer(
					this._programInfo.attribLocations.vertexPosition,
					numComponents,
					type,
					normalize,
					stride,
					offset
				);
				gl.enableVertexAttribArray(this._programInfo.attribLocations.vertexPosition);
			}
			{
				const numComponents = 2;
				const type = gl.FLOAT;
				const normalize = false;
				const stride = 0;
				const offset = 0;
				gl.bindBuffer(gl.ARRAY_BUFFER, this._buffers.textureCoord);
				gl.vertexAttribPointer(
					this._programInfo.attribLocations.textureCoord,
					numComponents,
					type,
					normalize,
					stride,
					offset
				);
				gl.enableVertexAttribArray(this._programInfo.attribLocations.textureCoord);
			}

			gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this._buffers.indices);

			// Tell WebGL to use our pair of shaders
			gl.useProgram(this._programInfo.program);

			// Set the shader uniforms
			gl.activeTexture(gl.TEXTURE0);
			gl.bindTexture(gl.TEXTURE_2D, texture1);
			gl.uniform1i(this._programInfo.uniformLocations.uSampler1, 0);

			gl.activeTexture(gl.TEXTURE1);
			gl.bindTexture(gl.TEXTURE_2D, texture2);
			gl.uniform1i(this._programInfo.uniformLocations.uSampler2, 1);

			gl.uniform1i(this._programInfo.uniformLocations.uBlendMode, blendMode);

			gl.uniform4f(
				this._programInfo.uniformLocations.uBlendIfGrayCurrent,
				this.currentBlendIf.gray[0],
				this.currentBlendIf.gray[1],
				this.currentBlendIf.gray[2],
				this.currentBlendIf.gray[3]
			);
			gl.uniform4f(
				this._programInfo.uniformLocations.uBlendIfGrayUnderlying,
				this.underlyingBlendIf.gray[0],
				this.underlyingBlendIf.gray[1],
				this.underlyingBlendIf.gray[2],
				this.underlyingBlendIf.gray[3]
			);

			gl.uniform4f(
				this._programInfo.uniformLocations.uBlendIfRedCurrent,
				this.currentBlendIf.red[0],
				this.currentBlendIf.red[1],
				this.currentBlendIf.red[2],
				this.currentBlendIf.red[3]
			);
			gl.uniform4f(
				this._programInfo.uniformLocations.uBlendIfRedUnderlying,
				this.underlyingBlendIf.red[0],
				this.underlyingBlendIf.red[1],
				this.underlyingBlendIf.red[2],
				this.underlyingBlendIf.red[3]
			);

			gl.uniform4f(
				this._programInfo.uniformLocations.uBlendIfGreenCurrent,
				this.currentBlendIf.green[0],
				this.currentBlendIf.green[1],
				this.currentBlendIf.green[2],
				this.currentBlendIf.green[3]
			);
			gl.uniform4f(
				this._programInfo.uniformLocations.uBlendIfGreenUnderlying,
				this.underlyingBlendIf.green[0],
				this.underlyingBlendIf.green[1],
				this.underlyingBlendIf.green[2],
				this.underlyingBlendIf.green[3]
			);

			gl.uniform4f(
				this._programInfo.uniformLocations.uBlendIfBlueCurrent,
				this.currentBlendIf.blue[0],
				this.currentBlendIf.blue[1],
				this.currentBlendIf.blue[2],
				this.currentBlendIf.blue[3]
			);
			gl.uniform4f(
				this._programInfo.uniformLocations.uBlendIfBlueUnderlying,
				this.underlyingBlendIf.blue[0],
				this.underlyingBlendIf.blue[1],
				this.underlyingBlendIf.blue[2],
				this.underlyingBlendIf.blue[3]
			);

			{
				const vertexCount = 6;
				const type = gl.UNSIGNED_SHORT;
				const offset = 0;
				gl.drawElements(gl.TRIANGLES, vertexCount, type, offset);
			}
		};

		render();

		gl.deleteTexture(texture1);
		gl.deleteTexture(texture2);

		const canvas = document.createElement('canvas');
		canvas.width = this._size.width;
		canvas.height = this._size.height;
		const ctx = canvas.getContext('2d')!;
		ctx.clearRect(0, 0, this._size.width, this._size.height);
		ctx.drawImage(this._mixer.canvas, 0, 0, this._size.width, this._size.height);

		if (this._bottom?.id !== undefined) this._bottom.id = 'bottom-composed';
		this._bottom = canvas;
		this.resetBlendIf();

		return this._mixer.canvas as HTMLCanvasElement;
	}

	public get counter() {
		return this._counter;
	}
}

export default () => {
	return ComposeResources.getInstance();
};
