import { useMemoize } from '@vueuse/core';
import Normalize from 'color-normalize';
import { colord, extend } from 'colord';
import labPlugin from 'colord/plugins/lab';
import mixPlugin from 'colord/plugins/mix';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { SolidColor } from '@/color/classes/SolidColor';
import {
	ColorInterface,
	GradientColorDTO,
	GradientType,
	InterpolatedStopGradient,
	StopGradient,
} from '@/Types/colorsTypes';
import { CanvasPreviewName } from '@/Types/types';
import MathTools from '@/utils/classes/MathTools';
import { SerializableClass } from '@/utils/classes/SerializableClass';

extend([labPlugin, mixPlugin]);

const interpolateCached = useMemoize((gradient: GradientColor) => {
	return gradient.interpolateStops(5);
});

export class GradientColor extends SerializableClass implements ColorInterface {
	id: string;
	type: GradientType;
	rotation: number;
	stops: StopGradient[];
	validForVariant: boolean;

	constructor(type: GradientType, rotation: number, stops: StopGradient[], id?: string) {
		super();
		this.id = id || 'color-' + uuidv4();
		this.type = type;
		this.rotation = rotation;
		this.stops = stops;
		this.validForVariant = true;
	}

	static unique(colors: GradientColor[]): GradientColor[] {
		return colors.filter(
			(color, index, colors) => index === colors.findIndex((c) => c.toCssString() === color.toCssString())
		);
	}

	static create(type: GradientType, stops: StopGradient[], config?: any): GradientColor {
		const rotation = config?.rotation | 0;

		return new GradientColor(type, rotation, stops);
	}

	static defaultColor(): GradientColor {
		return this.create('linear', [
			{
				r: 94,
				g: 178,
				b: 252,
				a: 1,
				offset: 0,
			},
			{
				r: 18,
				g: 115,
				b: 235,
				a: 1,
				offset: 100,
			},
		]);
	}

	toCssString(): string {
		if (this.type === 'radial') {
			return `${this.type}-gradient(farthest-side, ${this.stopsToString()})`;
		}

		return `${this.type}-gradient(${this.rotation}deg, ${this.stopsToString()})`;
	}

	stopsToString(): string {
		const newStops = interpolateCached(this);
		return `${newStops.map((stop) => this.stopToString(stop)).join()}`;
	}

	toElementReference(previewName = ''): string {
		return `url(#${this.id}${previewName ? `-preview-${previewName}` : CanvasPreviewName.none})`;
	}

	stopToString(stop: StopGradient | InterpolatedStopGradient): string {
		return 'hex8' in stop
			? `${stop.hex8} ${stop.offset}%`
			: `rgba(${stop.r}, ${stop.g}, ${stop.b}, ${stop.a}) ${MathTools.toFixedOrInt(stop.offset, 2)}%`;
	}

	convertToSolidColor(): SolidColor {
		const { r, g, b, a } = this.stops[0];
		return new SolidColor(r, g, b, a);
	}

	updateStop(stop: StopGradient, newStop: StopGradient) {
		const stopIndex = this.stops.findIndex((s) => this.stopToString(s) === this.stopToString(stop));
		this.stops[stopIndex] = newStop;
		this.stops.sort((stopA: StopGradient, stopB: StopGradient) => stopA.offset - stopB.offset);
	}

	toObject(): GradientColorDTO {
		return {
			type: this.type,
			rotation: this.rotation,
			stops: this.stops,
		};
	}

	static fromObject(gradient: GradientColorDTO): GradientColor {
		const { id, type, rotation, stops } = gradient;

		const newGradient = new GradientColor(type, rotation, stops);

		if (id) {
			newGradient.id = id;
		}

		return newGradient;
	}

	isGradient(): boolean {
		return true;
	}

	isSolid(): boolean {
		return false;
	}

	copy() {
		return new GradientColor(this.type, this.rotation, this.stops);
	}

	toCssStringWithoutAlpha(): string {
		return this.withoutAlpha().toCssString();
	}

	toCssStringWithoutRotation(): string {
		return this.withoutRotation().toCssString();
	}

	withoutAlpha(): GradientColor {
		const stops = this.stops.map((stop) => ({ ...stop, a: 1 }));
		return new GradientColor(this.type, this.rotation, stops, this.id);
	}

	withoutRotation(): GradientColor {
		const stops = this.stops.map((stop) => ({ ...stop }));
		return new GradientColor(this.type, 0, stops, this.id);
	}

	static unserialize(data: GradientColor): GradientColor {
		const { type, rotation, stops, id } = data;
		const fixedRotation = rotation < 0 ? rotation + 360 : rotation;
		let fixedStops: StopGradient[] = Object.values(stops);

		if (Array.isArray(stops)) {
			fixedStops = stops.map((stop) => {
				const { r, g, b, a, offset } = stop;
				return { r, g, b, a, offset: MathTools.toFixedOrInt(offset, 2) };
			});
		}

		return new GradientColor(type, fixedRotation, fixedStops, id);
	}

	toRegex(): RegExp {
		return new RegExp(this.toElementReference().replaceAll('(', '\\(').replaceAll(')', '\\)'), 'g');
	}

	interpolateStops(steps: number) {
		return this.stops.reduce((acc, stop) => {
			if (acc.length === 0) {
				return [stop];
			}
			const prev = acc[acc.length - 1];

			const interpolatedColors = [];
			// Si prev es de tipo InterpolatedStopGradient, significa que ya se ha interpolado
			if (!('hex8' in prev)) {
				const start = colord({ r: prev.r, g: prev.g, b: prev.b, a: prev.a });
				const end = colord({ r: stop.r, g: stop.g, b: stop.b, a: stop.a });

				for (let i = 0; i < steps; i++) {
					const step = (i + 1) / (steps + 1);
					const color = start.mix(end, step);

					const rgb = color.toRgb();
					const hex8 = SolidColor.fromString(`rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`).toHex(true);

					const midStop: InterpolatedStopGradient = {
						hex8,
						offset: MathTools.toFixedOrInt(MathTools.interpolate(prev.offset, stop.offset, step), 2),
					};
					interpolatedColors.push(midStop);
				}
			}

			return [...acc, ...interpolatedColors, stop];
		}, <(StopGradient | InterpolatedStopGradient)[]>[]);
	}

	clone() {
		const newGradientColor = cloneDeep(this);
		newGradientColor.id = 'color-' + uuidv4();

		return newGradientColor;
	}

	static stopToSolidColor(stop: StopGradient): SolidColor {
		const { r, g, b, a } = stop;

		return SolidColor.fromObject({ r, g, b, a });
	}

	static fromString(color: string): GradientColor {
		const type = color.split('-gradient(')[0] as GradientType;

		const deg =
			type === 'radial' ? color.split('gradient(')[1].split(',')[0] : color.split('gradient(')[1].split('deg')[0];

		const stops = color
			.split('%')
			.filter((c) => c.includes('rgba') || c.includes('rgb'))
			.map((c) => {
				const finalColor = `rgb${c.split('rgb')[1].split(')')[0]})`;

				const offset = parseFloat(c.split(') ')[1]);

				const [r, g, b, a] = Normalize(finalColor);

				return { r: r * 255, g: g * 255, b: b * 255, a, offset };
			});

		return GradientColor.create(type, stops, { rotation: deg });
	}
}
