import { Ref } from 'vue';

import { useCollisionBackgroundableInfo } from '@/collision/composables/useCollisionBackgroundableInfo';
import { useCollisionForegroundInfo } from '@/collision/composables/useCollisionForegroundInfo';
import { useCollisionImageInfo } from '@/collision/composables/useCollisionImageInfo';
import { useCollisionInfo } from '@/collision/composables/useCollisionInfo';
import { createOrchestrator } from '@/composables/createOrchestrator';
import Element from '@/elements/element/classes/Element';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import Image from '@/elements/medias/images/image/classes/Image';
import { Video } from '@/elements/medias/video/classes/Video';

export const useCollisionInfoOrchestator = (element: Ref<Element>) => {
	return createOrchestrator(() => {
		if (element.value instanceof Image) {
			return useCollisionImageInfo(element as Ref<Image>);
		}

		if (element.value instanceof ForegroundImage) {
			return useCollisionForegroundInfo(element as Ref<ForegroundImage>);
		}

		if (element.value instanceof Video) {
			return useCollisionBackgroundableInfo(element as Ref<Video>);
		}

		return useCollisionInfo(element);
	});
};
