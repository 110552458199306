import Bugsnag from '@bugsnag/js';
import { until } from '@vueuse/core';
import { nextTick, Ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useMainStore } from '@/editor/stores/store';
import Element from '@/elements/element/classes/Element';
import { useElementTransform } from '@/elements/element/composables/useElementTransform';
import { useGroup } from '@/elements/group/composables/useGroup';
import { useGroupTransform } from '@/elements/group/composables/useGroupTransform';
import { Text } from '@/elements/texts/text/classes/Text';
import { useAiWriter } from '@/elements/texts/text/composables/useAiWriter';
import { useFonts } from '@/elements/texts/text/composables/useFonts';
import { useTextColor } from '@/elements/texts/text/composables/useTextColor';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { useInsertablePosition } from '@/interactions/composables/useInsertablePosition';
import { useMoveable } from '@/interactions/composables/useMoveable';
import { useSelection } from '@/interactions/composables/useSelection';
import TemplateLoader from '@/loader/utils/TemplateLoader';
import { useActivePage } from '@/page/composables/useActivePage';
import { useArtboard } from '@/project/composables/useArtboard';
import { PredefinedTextApi } from '@/Types/apiClient';
import { FontWeight } from '@/Types/elements';
import { Anchor, EditPanels, TextWithValue } from '@/Types/types';

const DEFAULT_SCREEN_SIZE = 500;

export const useAddInsertableText = (text: Ref<Text>, previewText: Ref<Text>) => {
	const store = useMainStore();
	const { selection, setSelection, clearSelection } = useSelection();

	const { resize, move, centerInVisibleZone } = useElementTransform(text);
	const { updateColor } = useTextColor(text);
	const { addElement } = useActivePage();
	const { artboardSizeInPx } = useArtboard();

	const { runOnMobile } = useDeviceInfo();
	const { inUseFonts, fonts, getNearestWeight } = useFonts();
	const { isCreatingElement } = useMoveable();
	const { generateContent, content, openAIWriter, textAI } = useAiWriter();

	const { group } = useGroup(text);
	const { align: groupAlign, resize: resizeGroup } = useGroupTransform(group);
	const { resetLastPosition, offsetInsertedElement } = useInsertablePosition(text);

	const addInsertableText = async (style: TextWithValue, useStyleFontFamily?: boolean) => {
		text.value = Text.create({
			...style,
			content: style.text,
		});

		Bugsnag.leaveBreadcrumb(` ${style.text} text to canvas`);
		GAnalytics.track('click', 'Button', 'add-new-text', null);

		// Conversión de tamaño de fuente para adaptarse al ancho de las pantallas
		text.value.fontSize = (artboardSizeInPx.value.width * text.value.fontSize) / DEFAULT_SCREEN_SIZE;

		if (style.outline) {
			text.value.outline.width = text.value.fontSize / 10;
		}

		if (style.curvedProperties) {
			// Le ponemos un arc inferior al minArc para que al añadirlo se le ponga le default
			// Poner el arc a 1.0 es para indicar que es un texto nuevo
			text.value.curvedProperties = { ...previewText.value.curvedProperties, arc: 1.0 };
		}

		// Cambio de fontFamily para que tenga el mismo que en la plantilla
		text.value.fontFamily =
			inUseFonts.value.length > 0 && !useStyleFontFamily ? inUseFonts.value[0].family : text.value.fontFamily;

		// Obtenemos el peso del estilo o le aplicamos uno por defecto
		text.value.fontWeight =
			style.fontWeight || (Number(getNearestWeight(text.value.fontFamily, '700', true)) as FontWeight);
		if (!store.activePage) return;

		text.value.addMetadata({ fixPosYOnAdded: true, fixPaddingScale: true });

		addElement(text.value);

		resetLastPosition();

		await centerTextElement(text.value, style);
		GAnalytics.trackGA4('add_to_canvas', { category: 'text' });

		runOnMobile(() => (store.activePanel = null));
	};

	const addInsertablePasteText = async (style: TextWithValue, useStyleFontFamily?: boolean) => {
		text.value = Text.create({
			...style,
			content: style.text,
		});

		// Conversión de tamaño de fuente para adaptarse al ancho de las pantallas
		text.value.fontSize = (artboardSizeInPx.value.width * text.value.fontSize) / DEFAULT_SCREEN_SIZE;

		// Obtenemos el peso del estilo
		text.value.fontWeight = style.fontWeight as FontWeight;

		text.value.addMetadata({ fixPosYOnAdded: true, fixPaddingScale: true });

		await positionPasteTextElement(text.value);

		addElement(text.value);
	};

	const addInsertablePredefinedText = async (element: PredefinedTextApi) => {
		if (!store.activePage) return;

		let elements: (Element | Text)[] = [];
		try {
			elements = await TemplateLoader.fromPredefinedText(element.svg);
		} catch (error) {
			throw new Error('Something wrong on fetch predefined text');
		}
		if (!elements?.length) return;

		// Eliminamos la selección para que no se reemplace el texto predefinido encima de otro texto seleccionado
		if (selection.value.length) clearSelection();

		// Migramos las fuentes a sus slugs
		elements.forEach((newElement: Element | Text) => {
			addElement(newElement);

			// Hay textos predefinidos que contienen elementos que no son de tipo texto,
			// si el elemento no es de tipo texto, salimos
			if (!(newElement instanceof Text)) return;

			const fontSlug = getFontSlug(newElement.fontFamily);
			newElement.fontFamily = fontSlug as string;

			const content = newElement.htmlInstance();

			Array.from(content.querySelectorAll('[style*="font-family"]'))
				.map((el) => el as HTMLElement)
				.forEach((el: HTMLElement) => {
					const fontFamily = el.style.fontFamily;
					const fontSlug = getFontSlug(fontFamily);

					el.style.fontFamily = fontSlug as string;
				});

			if (content.body.firstElementChild) newElement.content = content.body.firstElementChild.innerHTML.toString();
		});

		resetLastPosition();

		const allTexts: Text[] = elements.filter((el): el is Text => el instanceof Text);

		// Si se trata de un grupo de elementos dejamos seleccionado el elmento posicionado más arriba respecto al eje Y.
		const firstElement = allTexts.filter(
			(el) => el.position.y === Math.min(...allTexts.map((i) => i.position.y))
		)[0] as Text;

		text.value = firstElement;
		// If ungrouped elements, resize the element and align it
		if (elements.length === 1) {
			await resizeElementAndCenter(firstElement);
		}

		// If grouped elements
		if (elements.length > 1) {
			isCreatingElement.value = true;

			await resizeGroup(artboardSizeInPx.value.width / 2);
			await groupAlign(Anchor.center);

			offsetInsertedElement();

			// ? esperamos a que se ejecute el fitheight antes de seleccionar el elemento, para evitar flasheos
			await Promise.all(
				allTexts.map(async (el) => {
					text.value = el;
					await until(() => text.value.size.height).changed({ timeout: 200 });
				})
			);

			setSelection(firstElement);

			isCreatingElement.value = false;
		}

		// si nos llega las sombras de los textos con unidades diferentes a em, ajustamos su valor
		// al tamaño de la fuente que esté usando y establecemos la unidad a em
		elements
			.filter((el) => el instanceof Text && el.textShadow.length)
			.filter((text) => (text as Text).textShadow.some((shadow) => !shadow.unit || shadow.unit !== 'em'))
			.forEach((text) => {
				TextTools.fitEmShadowToFontSize(text as Text);
			});

		Bugsnag.leaveBreadcrumb(
			`Add the follow predefined text to canvas: ${elements.map((el) => 'text-' + el.id).join('; ')}`
		);

		GAnalytics.trackGA4('add_to_canvas', { category: 'predefined-text' });
		runOnMobile(() => (store.activePanel = null));
	};

	const addInsertableAIText = async (style: TextWithValue, visible = false) => {
		const newText = Text.create({
			...style,
			content: '',
		});

		Bugsnag.leaveBreadcrumb(` AI text to canvas`);
		GAnalytics.track('click', 'Button', 'add-new-ai-text', null);
		text.value = newText;
		openAIWriter(text.value);
		generateContent(visible);
		// Conversión de tamaño de fuente para adaptarse al ancho de las pantallas
		newText.fontSize = (artboardSizeInPx.value.width * newText.fontSize) / DEFAULT_SCREEN_SIZE;

		if (style.outline) {
			newText.outline.width = newText.fontSize / 10;
		}

		if (style.curvedProperties) {
			// Le ponemos un arc inferior al minArc para que al añadirlo se le ponga le default
			// Poner el arc a 1.0 es para indicar que es un texto nuevo
			newText.curvedProperties = { ...previewText.value.curvedProperties, arc: 1.0 };
		}

		// Cambio de fontFamily para que tenga el mismo que en la plantilla
		newText.fontFamily = inUseFonts.value.length > 0 ? inUseFonts.value[0].family : newText.fontFamily;

		// Obtenemos el peso del estilo o le aplicamos uno por defecto
		newText.fontWeight = style.fontWeight || (Number(getNearestWeight(newText.fontFamily, '700', true)) as FontWeight);

		if (!store.activePage) return;

		await until(content).toBeTruthy();
		addElement(newText);

		resetLastPosition();

		await centerTextElement(newText, style);

		runOnMobile(() => {
			store.activePanel = null;
			store.editPanel = EditPanels.WriterAI;
		});

		textAI.value = text.value;
	};

	const getFontSlug = (fontFamily: CSSStyleDeclaration | string) => {
		return (
			Object.values(fonts.value).find((f) => f.name === fontFamily)?.slug ||
			Object.values(fonts.value).find((f) => f.slug === fontFamily)?.slug ||
			Object.values(fonts.value).find((f) => f.name === 'Montserrat')?.slug
		);
	};

	const resizeElementAndCenter = async (element: Text, style?: TextWithValue) => {
		const elementScale = artboardSizeInPx.value.width / 2 / DEFAULT_SCREEN_SIZE;
		element.setScale(element.scale * elementScale);

		await centerTextElement(element, style);
	};

	const centerTextElement = async (element: Text, style?: TextWithValue) => {
		resize(artboardSizeInPx.value.width / 2, text.value.size.height);
		await nextTick();
		centerInVisibleZone(element);

		// ? esperamos a que se ejecuta fitHeight y establezca el alto final al texto
		await until(() => text.value.size.height).changed({ timeout: 200 });

		if (style?.color) updateColor(style.color);

		setSelection(element);
		offsetInsertedElement();
	};

	const positionPasteTextElement = async (element: Text) => {
		resize(artboardSizeInPx.value.width - 20, text.value.size.height);
		await nextTick();
		move(10, 10);

		// ? esperamos a que se ejecuta fitHeight y establezca el alto final al texto
		await until(() => text.value.size.height).changed({ timeout: 200 });

		setSelection(element);
		offsetInsertedElement();
	};

	return {
		addInsertableText,
		addInsertablePredefinedText,
		addInsertableAIText,
		addInsertablePasteText,
	};
};
