import { useEditorMode } from '@/editor/composables/useEditorMode';

export const useUrlParams = () => {
	const { isFreepikContext } = useEditorMode();
	const PATH = isFreepikContext.value ? `/designer/edit` : '/edit/';
	const INVALID_PARAMS_IN_SYNC_CASES: string[] = [
		'width',
		'height',
		'unit',
		'size',
		'ai-writer',
		'remove-bg',
		'template',
	];

	const CATEGORY_TEMPLATE_PARAMS = ['category_name', 'category_id'];

	const handleQueryParams = (templateUuid: string, specificParams: string[] = []): string => {
		const searchParam = new URLSearchParams(window.location.search);

		// Si hay establecido algún parámetro en la url recorremos los parámetros
		//  y eliminamos los que coincidan con la lista
		const paramsToDelete = specificParams.length ? specificParams : INVALID_PARAMS_IN_SYNC_CASES;

		if (searchParam.size) {
			const keysFromSearchParams = Array.from(searchParam.keys());
			keysFromSearchParams.forEach((key) => {
				if (paramsToDelete.includes(key)) searchParam.delete(key);
			});
		}

		// Si estamos en el contexto de freepik, el slug del template se añade como param y no en el path como en wepik
		if (isFreepikContext.value && !searchParam.get('template')) {
			searchParam.append('template', templateUuid);
		}

		const queryParams = searchParam.toString();

		return queryParams;
	};

	const generateNewPathWithParams = (templateUuid: string, specificParams: string[] = [], openInNewTab = false) => {
		const params = handleQueryParams(templateUuid, specificParams);

		const finalParams = params.toString() ? `?${params.toString()}` : '';

		if (openInNewTab) {
			return isFreepikContext.value
				? `${window.location.origin}${PATH}${finalParams}`
				: `${window.location.origin}${PATH}${templateUuid || ''}${finalParams}`;
		}

		window.history.replaceState(
			'',
			'',
			isFreepikContext.value ? `${PATH}${finalParams}` : `${PATH}${templateUuid}${finalParams}`
		);
	};

	return {
		handleQueryParams,
		generateNewPathWithParams,
		INVALID_PARAMS_IN_SYNC_CASES,
		CATEGORY_TEMPLATE_PARAMS,
	};
};
