window.fontRender = function (fontFamily: string, fontWeight: number, fontSize: number, text: string) {
	const canvas = document.createElement('canvas');
	canvas.style.position = 'absolute';
	canvas.style.top = '0';
	canvas.style.left = '0';
	canvas.style.zIndex = '999999';
	canvas.height = 500;
	canvas.width = 500;
	document.body.appendChild(canvas);
	const ctx = canvas.getContext('2d');

	const baselinesAboveAlphabetic = [
		'fontBoundingBoxAscent',
		//"actualBoundingBoxAscent",
	];
	const baselinesBelowAlphabetic = [
		//'actualBoundingBoxDescent',
		'fontBoundingBoxDescent',
	];
	const baselines = [...baselinesAboveAlphabetic, ...baselinesBelowAlphabetic];

	if (!ctx) return;

	ctx.font = `${fontWeight} ${fontSize}px '${fontFamily}'`;
	ctx.strokeStyle = 'red';
	ctx.textBaseline = 'alphabetic';

	baselines.forEach((baseline, index) => {
		const textMetrics = ctx.measureText(text) as any;
		const y = 200 + index * 50;

		ctx.beginPath();
		ctx.fillText(text, 0, y);

		let lineY = y - Math.abs(textMetrics[baseline]);
		if (baselinesBelowAlphabetic.includes(baseline)) {
			lineY = y + Math.abs(textMetrics[baseline]);
		}

		ctx.moveTo(0, lineY);
		ctx.lineTo(1000, lineY);
		ctx.stroke();
	});
};
