import { FontWeight } from '@/Types/elements';

interface WeightNames {
	[key: string]: {
		simple?: string[];
		complex?: string[];
	};
}

class FontFamilyVariantsTools {
	// Sufijos que pueden ir junto al nombre de la fuente tras
	// exportar la plantilla desde illustrator
	static get weights(): WeightNames {
		return {
			'100': {
				simple: ['Hairline', 'Thin'],
			},
			'200': {
				complex: ['Extralight', 'ExtraLight', 'Extra-Light'],
			},
			'300': {
				complex: ['Ultralight', 'UltraLight', 'Ultra-Light'],
			},
			'400': {
				simple: ['Light', 'Book', 'Normal', 'Regular', 'Rg', 'Italic'],
			},
			'500': {
				simple: ['Medium'],
			},
			'600': {
				simple: ['Demi'],
				complex: ['Semibold', 'SemiBold', 'Semi-Bold', 'Demibold', 'DemiBold', 'Demi-Bold'],
			},
			'700': {
				simple: ['Bold'],
			},
			'800': {
				complex: ['Extrabold', 'ExtraBold', 'Extra-Bold', 'Ultrabold', 'UltraBold', 'Ultra-Bold'],
			},
			'900': {
				simple: ['Black', 'Heavy', 'Fat'],
				complex: ['Extrablack', 'ExtraBlack', 'Extra-Black', 'Ultrablack', 'UltraBlack', 'Ultra-Black'],
			},
		};
	}

	static getWeightNames() {
		const simpleNames: string[] = [];
		const complexNames: string[] = [];

		Object.values(this.weights).forEach((weight) => {
			if (weight.simple) {
				simpleNames.push(...weight.simple);
			}

			if (weight.complex) {
				complexNames.push(...weight.complex);
			}
		});

		return [...complexNames, ...simpleNames];
	}

	static findVariantInName(fontFamily: string) {
		const fontFamilySplit = fontFamily.split(', ');
		const fontFamilyNameWithWeight = fontFamilySplit.find((name) => name.includes('-'));

		if (!fontFamilyNameWithWeight) {
			return null;
		}

		const fontFamilyWeight = fontFamilyNameWithWeight.split('-')[1];
		let weight = null;

		Object.keys(this.weights).forEach((weightKey) => {
			const weightValues = this.weights[weightKey];

			if (weightValues.simple && weightValues.simple.includes(fontFamilyWeight)) {
				weight = weightKey;
			}

			if (weightValues.complex && weightValues.complex.includes(fontFamilyWeight)) {
				weight = weightKey;
			}
		});

		return Number(weight) as FontWeight;
	}
}

export default FontFamilyVariantsTools;
