import { RGB, RGBA } from '@/apps/mockup/schemas';

export interface Frame {
	height: number;
	width: number;
	x: number;
	y: number;
}

export type ColorData = {
	hex?: string;
	rgb?: RGB;
	rgba?: RGBA;
};

export enum FillType {
	fill = 'fill',
	fit = 'fit',
	custom = 'custom',
}

export interface MockupBitmap {
	id: string;
	path: string | null;
}

export type MockupSize = {
	width: number;
	height: number;
};

export enum EditingMode {
	design = 'design',
	standard = 'standard',
}

export type Bitmap = {
	id: string;
	path: string | null;
};
