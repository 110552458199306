import {computed, Ref, ref} from "vue";
import {Customizable, Customizable2DTexture, CustomizableColor, LayerType} from "@/apps/mockup/schemas";
import {useMockupStore} from "@/apps/mockup/mockupStore";
import {LayerCanvas} from "@/apps/mockup/classes/Layers/Layer";
import {SAMModelInstance} from "@/apps/mockup/classes/IA/SAMModel";
import {CanvasUtils} from "@/apps/mockup/classes/CanvasUtils";
import {SAMMaskGenerated} from "@/apps/mockup/migration/Types/common";
import {until} from "@vueuse/core";

const baseColors = [
	{ r: 243, g: 208, b: 207, hex: '#F3D0CF', isActive: false }, // Rosa pastel
	{ r: 207, g: 243, b: 222, hex: '#CFEFDE', isActive: false }, // Verde pastel
	{ r: 229, g: 208, b: 243, hex: '#E5D0F3', isActive: false }, // Morado pastel
	{ r: 243, g: 230, b: 207, hex: '#F3E6CF', isActive: false }, // Amarillo pastel
	{ r: 207, g: 235, b: 243, hex: '#CFEBF3', isActive: false }, // Azul pastel
	{ r: 243, g: 207, b: 230, hex: '#F3CFE6', isActive: false }, // Lila pastel
	{ r: 208, g: 243, b: 242, hex: '#D0F3F2', isActive: false }, // Celeste pastel
	{ r: 243, g: 237, b: 207, hex: '#F3EDCF', isActive: false }, // Naranja pastel
	{ r: 220, g: 207, b: 243, hex: '#DCCFF3', isActive: false }, // Lavanda pastel
	{ r: 215, g: 243, b: 207, hex: '#D7F3CF', isActive: false }, // Verde claro pastel
	{ r: 230, g: 230, b: 243, hex: '#E6E6F3', isActive: false }, // Azul claro pastel
	{ r: 250, g: 240, b: 230, hex: '#FAF0E6', isActive: false }, // Lino pastel
	{ r: 240, g: 255, b: 240, hex: '#F0FFF0', isActive: false }, // Menta pastel
	{ r: 255, g: 245, b: 238, hex: '#FFF5EE', isActive: false }, // Papaya pastel
	{ r: 230, g: 230, b: 250, hex: '#E6E6FA', isActive: false }, // Lavanda clara pastel
	{ r: 245, g: 245, b: 220, hex: '#F5F5DC', isActive: false }, // Beige pastel
	{ r: 255, g: 228, b: 225, hex: '#FFE4E1', isActive: false }, // Rosado claro pastel
	{ r: 244, g: 164, b: 96, hex: '#F4A460', isActive: false }, // Naranja claro pastel
	{ r: 218, g: 112, b: 214, hex: '#DA70D6', isActive: false }, // Orquídea pastel
	{ r: 192, g: 192, b: 192, hex: '#C0C0C0', isActive: false }, // Plata pastel
	{ r: 255, g: 160, b: 122, hex: '#FFA07A', isActive: false },  // Salmón pastel
	{ r: 255, g: 192, b: 203, hex: '#FFC0CB', isActive: false }, // Rosa pastel
	{ r: 89, g: 180, b: 156, hex: '#59b49c', isActive: false }, // Verde pastel
	{ r: 255, g: 99, b: 71, hex: '#FF6347', isActive: false }, // Rojo pastel
	{ r: 255, g: 140, b: 0, hex: '#FF8C00', isActive: false }, // Naranja pastel
	{ r: 255, g: 215, b: 0, hex: '#FFD700', isActive: false }, // Dorado pastel
	{ r: 255, g: 255, b: 0, hex: '#FFFF00', isActive: false }, // Amarillo pastel
	{ r: 0, g: 128, b: 0, hex: '#008000', isActive: false }, // Verde pastel
	{ r: 0, g: 255, b: 0, hex: '#00FF00', isActive: false }, // Verde pastel

];

export const generatedMasks = ref<Map<string, SAMMaskGenerated>>(new Map());
export const newCustomizables = ref<Map<string, Customizable>>(new Map());
export const mockupLayers = computed(() => {
	const mockup = useMockupStore();
	return mockup.manifest?.children;
});
/**
 * This method is used to find the layers of the mockup
 * @param {LayerType} type - The type of the layer to find
 */
const findRecursiveLayers = (type: LayerType) => {
	const layers: any[] = [];
	if (!mockupLayers.value) return layers;


	let clippedMask:LayerCanvas | null = null;
	function recursiveFind(children: any, layers: any[]) {
		for (const child of children) {
			if (!child.isClippingMask) clippedMask = child;
			if (child.kind == type) {
				layers.push({
					...child,
					// Set the name of the layer with the name of the mask if is a clipping mask
					name : child.isClippingMask ? `${child.name} - Clip:${clippedMask?.name}` : child.name
				});
			}
			if (child.kind == LayerType.group) recursiveFind(child.children, layers);
		}
	}

	recursiveFind(mockupLayers.value, layers);
	return layers;
}

/**
 * This method is used to find the layers of the mockup
 * @param {LayerType} type - The type of the layer to find
 */
export const colorLayers = computed(() => findRecursiveLayers(LayerType.solidColor));

/**
 * This method is used to find the layers of the mockup
 * @param {LayerType} type - The type of the layer to find
 */
export const smartObjectslayers = computed(() => findRecursiveLayers(LayerType.smartObject));

/**
 * This method is used to find the layers of the mockup
 * @param canvasMigration
 * @param canvasGeneratedMasks
 */
export const useMockupMigration = (canvasMigration: HTMLCanvasElement | null, canvasGeneratedMasks: HTMLCanvasElement | null) => {

	const mockup = useMockupStore();

	const getLastColor = () => {
		const lastColor = baseColors.find((color) => !color.isActive);
		if (lastColor) lastColor.isActive = true;
		return lastColor!;
	}

	/**
	 * This method is used to generate the mask for the new customizable
	 * @param {string} customizableRelatedLayerId - The id of the layer to generate the mask
	 */
	const generateCustomizableMask = async (customizableRelatedLayerId: string) => {
		try {
			const generatedMask = generatedMasks.value.get(customizableRelatedLayerId);

			const colorMask =  generatedMask  ? generatedMask.color : getLastColor();

			const customElement = newCustomizables.value.get(customizableRelatedLayerId) as
				| Customizable2DTexture
				| CustomizableColor;
			const point = {
				x: customElement.position.x * canvasMigration!.width,
				y: customElement.position.y * canvasMigration!.height,
			};
			await SAMModelInstance.runModel(point);
			// Set the mask in the generatedMasks map
			generatedMasks.value.set(customizableRelatedLayerId, {
				...generatedMask,
				canvas: CanvasUtils.cloneCanvas(SAMModelInstance.maskCanvas),
				color: colorMask,
				beforeCreated: false,
				extraPoint: generatedMask?.extraPoint || false, // Set the extra point if the mask has an extra point
			});
			drawMasks()
		} catch (e) {
			console.log(e);

		}
	};

	const generateExtraPointMask = async (customizableRelatedLayerId: string) => {
		const generatedMask = generatedMasks.value.get(customizableRelatedLayerId);
		if (!generatedMask || !generatedMask.extraPointInfo) return;
		const point = {
			x: generatedMask.extraPointInfo.x * canvasMigration!.width,
			y: generatedMask.extraPointInfo.y * canvasMigration!.height,
		};
		await SAMModelInstance.runModel(point);
		generatedMasks.value.set(customizableRelatedLayerId, {
			...generatedMask,
			extraPointCanvas: CanvasUtils.cloneCanvas(SAMModelInstance.maskCanvas),
		});
		drawMasks();
	}

	const deleteCustomizable = (customizableRelatedLayerId: string) => {
		newCustomizables.value.delete(customizableRelatedLayerId);
		generatedMasks.value.delete(customizableRelatedLayerId);
		drawMasks();
	};

	/**
	 * This method is used to draw the mask inside the canvas
	 * @param {SAMMaskGenerated} mask
	 * @returns {HTMLCanvasElement} - The canvas with the mask
	 */
	const drawInsideCanvas = (mask: SAMMaskGenerated): HTMLCanvasElement => {
		// Draw the mask in the canvas
		const canvasMask = document.createElement('canvas');
		canvasMask.width = canvasMigration!.width;
		canvasMask.height = canvasMigration!.height;
		const ctxMask = canvasMask.getContext('2d') as CanvasRenderingContext2D;

		ctxMask.fillStyle = mask.color.hex;
		ctxMask.fillRect(0, 0, canvasMigration!.width, canvasMigration!.height);
		ctxMask.globalCompositeOperation = 'destination-in'

		if (!mask.extraPointCanvas) {
			ctxMask.drawImage(mask.canvas, 0, 0);
			return canvasMask
		}

		// Draw the mask with extraPoint in the canvas
		const canvasExtraPoint = document.createElement('canvas');
		canvasExtraPoint.width = canvasMigration!.width;
		canvasExtraPoint.height = canvasMigration!.height;
		const ctxExtraPoint = canvasExtraPoint.getContext('2d') as CanvasRenderingContext2D;
		ctxExtraPoint.drawImage(mask.extraPointCanvas, 0, 0); // Draw the extraPointMask in the canvas
		ctxExtraPoint.drawImage(mask.canvas, 0, 0); // Draw the mask in the canvas
		ctxMask.drawImage(canvasExtraPoint, 0, 0); // Draw the mask with extraPoint in the canvas

		return canvasMask;
	}

	/**
	 * This method is used to draw the masks in the canvas
	 */
	const drawMasks = () => {
		canvasGeneratedMasks!.width = canvasMigration!.width;
		canvasGeneratedMasks!.height = canvasMigration!.height;

		const ctx = canvasGeneratedMasks!.getContext('2d') as CanvasRenderingContext2D;
		ctx.globalAlpha = 0.6;

		for (const mask of generatedMasks.value.values()) {
			ctx?.drawImage(drawInsideCanvas(mask), 0, 0);
		}
	};

	/**
	 * This method is used to set the existent customizables
	 */
	const setExistentCustomizables = async() => {
		if (!mockup.manifest?.customizables) return;
		const customizables = mockup.manifest.customizables;

		const maskCount = ref(customizables?.length)
		const maskLoaded = ref(0)

		const addMaskLoaded = () => { maskLoaded.value++}

		customizables.map((custom) => {
			newCustomizables.value.set(custom.relatedLayerId, custom);
			setMaskExistentCustomizable(custom, addMaskLoaded)
		});
		await until(maskLoaded).toBe(maskCount)
		drawMasks()
	};

	const setMaskExistentCustomizable = async (customizable: Customizable, loadNewMask: CallableFunction) => {
		if (!customizable.interactiveZone.path || !canvasMigration) {
			console.warn('Customizable before created dont have interactiveZone')
			return;
		}
		const canvasMask = await CanvasUtils.imageToCanvas(customizable.interactiveZone.path as string, canvasMigration as HTMLCanvasElement)
		generatedMasks.value.set(customizable.relatedLayerId, {
			canvas: canvasMask,
			color: getLastColor(),
			beforeCreated: true,
			extraPoint: false,
		});
		loadNewMask()
	}

	/**
	 * This method is used to get the created mask
	 * @param {string} layerId - The id of the layer to get the mask
	 * @returns {Mask | undefined} - The mask created
	 */
	const getCreatedMask = (layerId: string) => {
		return generatedMasks.value.get(layerId);
	};

	/**
	 * This method is used to check if the layer is already created
	 * @param {string} layerId - The id of the layer to check if is already created
	 */
	const alreadyCreated = (layerId: string) => {
		return newCustomizables.value.has(layerId);
	};

	return {
		generateCustomizableMask,
		setExistentCustomizables,
		deleteCustomizable,
		drawMasks,
		getCreatedMask,
		alreadyCreated,
		generateExtraPointMask,
		getLastColor
	};
}

