import { useDebounceFn } from '@vueuse/core';
import { event, set } from 'vue-gtag';

import { useTemplateLoader } from '@/loader/composables/useTemplateLoader';
import { User } from '@/Types/types';

class GAnalytics {
	static track(action: string, category: string, label: string, value: string | null = null) {
		event(action, {
			event_category: category,
			event_label: label,
			value: value,
		});
	}

	static trackGA4(eventName: string, options: { [key: string]: string | object | number } = {}) {
		const finalLabel = { event: eventName };

		const originalTemplateIdentifier = {
			original_resource_id: window.originalTemplate?.vector?.id || 0,
			original_resource_name: window.originalTemplate?.vector?.slug || '',
		};
		window.dataLayer?.push({ ...finalLabel, ...originalTemplateIdentifier, ...options });
	}

	static trackDebounceEditBarGA4 = useDebounceFn((options: { [key: string]: string } = {}) => {
		const finalLabel = { event: 'edit_bar' };

		window.dataLayer?.push({ ...finalLabel, ...options });
	}, 500);

	static trackUser(
		freepikUserId: string,
		userId: string,
		userLogged: 'logged' | 'not-logged',
		userType: 'registered' | 'anonymous' | 'internal-staff'
	) {
		set({
			user_id: freepikUserId,
			internal_user_id: userId,
			user_login_status: userLogged,
			user_type: userType,
		});
	}

	static trackPageView(user: User | null, sourceId: string, userVectorName: string) {
		const fromView =
			document.location.href.split('from_view=')[1]?.split('&')[0] ||
			document.location.href.split('rs=')[1]?.split('&')[0] ||
			'';

		const userId = user?.id || '';
		let userType = 'anonymous';

		if (user) {
			userType = user.isAdmin ? 'internal-staff' : 'registered';
		}

		const pageViewParams = {
			page_location: window.location.href,
			page_title: document.title,
			page_en: window.location.href,
			location_href: document.location.href,
			content_group: 'editor',
			content_group2: 'na',
			content_type: 'images',
			country: window.countryCode || '',
			page_code: 200,
			page_lang: document.documentElement.lang,
			from_view: fromView,
			user_id: userId,
			user_type: userType,
			resource_name: userVectorName || '',
			resource_id: sourceId || '',
		};

		this.trackGA4('page_view_global', pageViewParams);
	}
	static setUserDimension(userType: 'registered' | 'anonymous' | 'internal-staff') {
		set({
			dimension1: userType,
		});
	}
}

export default GAnalytics;
