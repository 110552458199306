import { computed, Ref, ref } from 'vue';

import Element from '@/elements/element/classes/Element';
import { usePageElement } from '@/elements/element/composables/usePageElement';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import Page from '@/page/classes/Page';

export const useIsBackground = (element: Ref<Element>, page?: Ref<Page>) => {
	let pageToUse = ref(page);
	if (!pageToUse.value) {
		const { page } = usePageElement(element);
		pageToUse = page;
	}

	const isBackground = computed(() => {
		if (!element.value) return false;

		return element.value?.locked && element.value instanceof ForegroundImage
			? element.value.image === pageToUse.value?.backgroundImageId
			: element.value.id === pageToUse.value?.backgroundImageId;
	});

	return { isBackground };
};
